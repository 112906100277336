import { useQueryClient } from "@tanstack/react-query";
import {
  IContractGenerationHook,
  IContractGenerationSubject,
} from "./useContractGeneration";
import useGetSubjectParties from "./useGetSubjectContractParties";
import useGetSubjectSignatories from "./useGetSubjectSignatories";
import useGetSubjectSignatures from "./useGetSubjectSignatures";

const useIsSubjectSignNowGenerating = (
  subject: IContractGenerationSubject,
  contractGenerationHook: IContractGenerationHook
) => {
  const parties = useGetSubjectParties(subject, contractGenerationHook);
  const signatories = useGetSubjectSignatories(subject, contractGenerationHook);
  const signatures = useGetSubjectSignatures(subject, contractGenerationHook);
  const queryClient = useQueryClient();

  const areSignatoriesCreating =
    queryClient.isMutating({
      mutationKey: ["createSignatory"],
      exact: true,
      predicate: (mutation) => {
        return (
          parties?.some(
            (party) =>
              party.id === mutation.options.variables["signatory"]?.["party"]
          ) ?? false
        );
      },
    }) > 0;

  const areSignaturesCreating =
    queryClient.isMutating({
      mutationKey: ["createSignNowSignature"],
      exact: true,
      predicate: (mutation) => {
        return (
          signatories?.some(
            (signatory) =>
              signatory.id ===
              mutation.options.variables["signature"]?.["signatory"]
          ) ?? false
        );
      },
    }) > 0;

  const areSignaturesUpdating =
    queryClient.isMutating({
      mutationKey: ["updateSignNowSignature"],
      exact: true,
      predicate: (mutation) => {
        return (
          signatures?.some(
            (signature) =>
              signature.id === mutation.options.variables["signature"]?.["id"]
          ) ?? false
        );
      },
    }) > 0;

  return (
    areSignatoriesCreating || areSignaturesCreating || areSignaturesUpdating
  );
};

export default useIsSubjectSignNowGenerating;
