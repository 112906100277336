import { useMutation, useQueryClient } from "@tanstack/react-query";
import IContract from "@interfaces/IContract";
import ContractService from "@services/ContractService";

export const useContractUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (contract: IContract) => ContractService.updateContract(contract),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["contracts"]);
      },
    }
  );
};
