import { toCurrencyString } from "@helpers/currencyUtils";
import { useTranslation } from "react-i18next";
import BillTableSubItem from "@components/BillTable/BillTableItem/BillTableSubItem/BillTableSubItem";
import "./BillTableItem.scss";
import IXchange from "@interfaces/IXchange";
import { getXpertXchangeSubDomains } from "@helpers/xchange-utils";
import { Virtuoso } from "react-virtuoso";
import { useBills } from "@hooks/bill/useBills";
import { IPageParams } from "@interfaces/IPage";
import { IGetBillsParams } from "@services/BillService";
import { useMemo } from "react";
import IBillGroup from "@interfaces/IBillGroup";
import { Spinner } from "react-bootstrap";
import IXpert from "@interfaces/IXpert";

const BillTableItem = ({
  billGroup,
  xchange,
  xpert,
  extraFixedGetBillsParams,
  isExpanded = true,
  toggleExpansion,
}: {
  billGroup: IBillGroup;
  xchange?: IXchange;
  xpert?: IXpert;
  extraFixedGetBillsParams?: IGetBillsParams;
  isExpanded?: boolean;
  toggleExpansion?: () => void;
}) => {
  const { t } = useTranslation();

  const useBillsParams = useMemo(() => {
    const params: IGetBillsParams & IPageParams = {
      ...extraFixedGetBillsParams,
      page_size: 6,
    };

    if (billGroup.xpert) {
      params.xpert__in = billGroup.xpert.id!.toString();
      params.populate_xchange = true;
      if (xchange) {
        params.xchange__in = xchange.id!.toString();
      }
    }

    if (billGroup.xchange) {
      params.xchange__in = billGroup.xchange.id!.toString();
      params.populate_xpert = true;
      if (xpert) {
        params.xpert__in = xpert.id!.toString();
      }
    }

    return params;
  }, [
    billGroup.xchange,
    billGroup.xpert,
    extraFixedGetBillsParams,
    xchange,
    xpert,
  ]);

  const {
    data: billsInfiniteData,
    isFetching: areBillsFetching,
    fetchNextPage,
    hasNextPage,
  } = useBills(
    {
      ...useBillsParams,
    },
    {
      enabled: !!billGroup && isExpanded,
      refetchOnWindowFocus: false,
    }
  );
  const bills = billsInfiniteData
    ? billsInfiniteData.pages.flatMap((p) => p.results)
    : [];

  const groupTitle = (() => {
    if (billGroup.xpert) {
      const domains = xchange
        ? getXpertXchangeSubDomains(xchange, billGroup.xpert!.id!)
        : [];

      return domains
        ? `${domains} -- ${billGroup.xpert!.name}`
        : billGroup.xpert!.name!;
    }

    if (billGroup.xchange) {
      return billGroup.xchange!.name!;
    }
  })();

  return (
    <>
      <tbody>
        <tr className="Bill-Table-Item" onClick={toggleExpansion}>
          <td colSpan={3} className="table--disabled">
            <i
              className={`fa-regular ${
                isExpanded ? "fa-chevron-up" : "fa-chevron-down"
              } me-3`}
            />
            {groupTitle}
            <span className="Bill-Table-Item__General__Description">
              {`${billGroup.bills} ${t("billing.history.bills", {
                count: billGroup.bills,
              })} • ${t("billing.history.total_net_cost")} `}
              {billGroup.net_totals?.map((net_total) => {
                return (
                  <span
                    className="me-1"
                    key={`net_cost_in_${net_total.currency}`}
                  >
                    {`${net_total.currency} ${toCurrencyString(
                      net_total.net_total,
                      "USD",
                      2
                    )}`}
                  </span>
                );
              })}
            </span>
          </td>
          <td className="table-actions table--disabled"></td>
        </tr>
      </tbody>
      {isExpanded && (
        <tbody className="Bill-Table-Item__Content">
          <tr>
            <td colSpan={4} className="border-0 p-0">
              <Virtuoso
                className="Bill-Table-Item__Content__Virtuoso"
                data={bills}
                atBottomThreshold={100}
                atBottomStateChange={(isAtBottom) => {
                  if (isAtBottom && hasNextPage) {
                    fetchNextPage();
                  }
                }}
                components={{
                  Footer: () => {
                    return (
                      <table>
                        <tbody>
                          <tr>
                            <td
                              colSpan={4}
                              className="p-4 small text-muted text-center"
                            >
                              {areBillsFetching && (
                                <Spinner
                                  animation="border"
                                  variant="primary"
                                  size="sm"
                                />
                              )}
                              {!areBillsFetching &&
                                !hasNextPage &&
                                t("filters.end_reached")}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    );
                  },
                }}
                itemContent={(_index, bill) => (
                  <BillTableSubItem key={`bill_${bill.id}`} bill={bill} />
                )}
              />
            </td>
          </tr>
        </tbody>
      )}
    </>
  );
};

export default BillTableItem;
