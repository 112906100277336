import ISignatory from "@interfaces/ISignatory";
import Service from "@services/Service";

export interface IGetSignatoriesParams {
  party?: string;
  party__in?: string;
  person?: number | string;
}

export default class SignatoryService extends Service {
  public static async getSignatories(params: IGetSignatoriesParams = {}) {
    const response = await this.axios.get<ISignatory[]>("/signatories", {
      params,
    });
    return response.data;
  }

  public static async createSignatory(signatory: ISignatory) {
    const response = await this.axios.post<ISignatory>(
      "/signatories",
      signatory
    );
    return response.data;
  }

  public static async deleteSignatory(signatory: ISignatory) {
    const response = await this.axios.delete<ISignatory>(
      `/signatories/${signatory.id}`
    );
    return response.data;
  }
}
