import { Button, Col, Form, Stack } from "react-bootstrap";
import { FieldErrorsImpl, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Overview } from "@components/GenericOverview/Overview/Overview";
import { OverviewSection } from "@components/GenericOverview/OverviewSection/OverviewSection";
import { isFieldRequired } from "@helpers/joi-utils";
import Joi from "joi";
import {
  formatDateToString,
  resetHours,
  subtractDays,
} from "@helpers/date-utils";
import IInvoice from "@interfaces/IInvoice";
import { IXeroInvoiceAttachment } from "@interfaces/IXeroInvoice";
import CustomRangeDatePicker from "@components/CustomRangeDatePicker/CustomRangeDatePicker";
import CustomDateInput from "@components/CustomDateInput/CustomDateInput";
import { toCurrencyString } from "@helpers/currencyUtils";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import { joiResolver } from "@hookform/resolvers/joi";
import { useCallback, useEffect, useState } from "react";
import { useInvoiceUpdate } from "@hooks/invoice/useInvoiceUpdate";
import { debounce, isEqual, set } from "lodash";
import {
  calcInvoiceGrandTotal,
  getInvoiceDefaultDueDate,
  processInvoiceForOverviewForm,
} from "@helpers/invoice-utils";
import IBill from "@interfaces/IBill";
import DocumentCard from "@components/DocumentCard/DocumentCard";
import IXchange from "@interfaces/IXchange";
import "./XchangeInvoiceOverview.scss";
import XchangeInvoiceExportModal from "./XchangeInvoiceExportModal/XchangeInvoiceExportModal";
import { useInvoiceExport } from "@hooks/invoice/useInvoiceExport";
import {
  donwloadBillAttachment,
  openBillAttachment,
} from "@helpers/bill-utilts";
import { useNavigate } from "react-router-dom";
import CustomSelect from "@components/CustomSelect/CustomSelect";
import InvoiceStatusBadge from "@components/InvoiceStatusBadge/InvoiceStatusBadge";

export type XchangeInvoiceOverviewFormValues = {
  due_date?: Date;
  was_due_date_set_by_user?: boolean;
  billing_period_start?: Date;
  billing_period_end?: Date;
  currency?: string;
};

const validationSchema = Joi.object({
  due_date: Joi.date().min(subtractDays(new Date(), 1)).required(),
  was_due_date_set_by_user: Joi.boolean().default(false),
  billing_period_start: Joi.date().required(),
  billing_period_end: Joi.date().required(),
  currency: Joi.string(),
});

export default function InvoiceInvoiceOverview({
  xchange,
  invoice,
  bills,
}: {
  xchange: IXchange;
  invoice: IInvoice;
  bills: IBill[];
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const updateInvoiceMutation = useInvoiceUpdate();

  const getAllBillAttachments = () => {
    return bills
      .map((bill) => {
        return (bill.xero_invoice?.attachments || []).map(
          (attachment: IXeroInvoiceAttachment) => {
            return {
              ...attachment,
              bill: bill.id,
            };
          }
        );
      })
      .flat();
  };

  const [attachmentCurrentlyDownloading, setAttachmentCurrentlyDownloading] =
    useState<string[]>([]);

  const openAttachment = async (
    billId: number,
    attachment: IXeroInvoiceAttachment
  ) => {
    setAttachmentCurrentlyDownloading([
      ...attachmentCurrentlyDownloading,
      attachment.attachment_id!,
    ]);

    await openBillAttachment(billId, attachment);

    setAttachmentCurrentlyDownloading(
      attachmentCurrentlyDownloading.filter(
        (id) => id !== attachment.attachment_id
      )
    );
  };

  const donwloadAttachment = async (
    billId: number,
    attachment: IXeroInvoiceAttachment
  ) => {
    setAttachmentCurrentlyDownloading([
      ...attachmentCurrentlyDownloading,
      attachment.attachment_id!,
    ]);

    await donwloadBillAttachment(billId, attachment);

    setAttachmentCurrentlyDownloading(
      attachmentCurrentlyDownloading.filter(
        (id) => id !== attachment.attachment_id
      )
    );
  };

  const getDefaultBillingPeriodStartDate = useCallback((): Date | undefined => {
    if (invoice.billing_period_start) {
      return resetHours(new Date(invoice.billing_period_start));
    } else {
      const earliestBill = bills.reduce((acc, bill) => {
        if (
          !acc.billing_period_start ||
          (bill.billing_period_start &&
            bill.billing_period_start < acc.billing_period_start)
        ) {
          return bill;
        }
        return acc;
      }, bills[0]);

      if (earliestBill && earliestBill.billing_period_start) {
        return resetHours(new Date(earliestBill.billing_period_start));
      } else {
        return undefined;
      }
    }
  }, [bills, invoice.billing_period_start]);

  const getDefaultBillingPeriodEndDate = useCallback((): Date | undefined => {
    if (invoice.billing_period_end) {
      return resetHours(new Date(invoice.billing_period_end));
    } else {
      const latestBill = bills.reduce((acc, bill) => {
        if (
          !acc.billing_period_end ||
          (bill.billing_period_end &&
            bill.billing_period_end > acc.billing_period_end)
        ) {
          return bill;
        }
        return acc;
      }, bills[0]);

      if (latestBill && latestBill.billing_period_end) {
        return resetHours(new Date(latestBill.billing_period_end));
      } else {
        return undefined;
      }
    }
  }, [bills, invoice.billing_period_end]);

  const formMethods = useForm<XchangeInvoiceOverviewFormValues>({
    mode: "onChange",
    resolver: joiResolver(validationSchema),
    defaultValues: {
      ...processInvoiceForOverviewForm(invoice),
      due_date: getInvoiceDefaultDueDate(invoice),
      currency: invoice.currency_code,
    },
  });

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = formMethods;

  const [pastData, setPastData] = useState<XchangeInvoiceOverviewFormValues>(
    processInvoiceForOverviewForm(invoice)
  );

  const handleSubmitDebounce = debounce(
    (
      callback?: (
        errors: Partial<FieldErrorsImpl<XchangeInvoiceOverviewFormValues>>
      ) => void
    ) => {
      handleSubmit(
        (data) => {
          const changedValues = Object.keys(data).reduce((acc, key) => {
            if (
              !isEqual(
                data[key as keyof XchangeInvoiceOverviewFormValues],
                pastData?.[key as keyof XchangeInvoiceOverviewFormValues]
              )
            ) {
              set(
                acc,
                key,
                data[key as keyof XchangeInvoiceOverviewFormValues]
              );
            }
            return acc;
          }, {} as XchangeInvoiceOverviewFormValues);

          if (Object.keys(changedValues).length > 0) {
            updateInvoiceMutation.mutate({
              invoiceId: invoice.id!,
              data: {
                due_date: resetHours(data.due_date!)?.toISOString(),
                was_due_date_set_by_user: data.was_due_date_set_by_user,
                billing_period_start: data.billing_period_start
                  ? resetHours(data.billing_period_start)?.toISOString()
                  : undefined,
                billing_period_end: data.billing_period_end
                  ? resetHours(data.billing_period_end)?.toISOString()
                  : undefined,
              },
            });
          }

          setPastData(data);
          callback?.({});
        },
        (errors) => {
          callback?.(errors);
        }
      )();
    },
    100
  );

  useEffect(() => {
    const bp_start = getDefaultBillingPeriodStartDate();
    if (bp_start && !getValues("billing_period_start")) {
      setValue("billing_period_start", bp_start);
    }
    const bp_end = getDefaultBillingPeriodEndDate();
    if (bp_end && !getValues("billing_period_end")) {
      setValue("billing_period_end", bp_end);
    }
  }, [
    bills,
    getDefaultBillingPeriodEndDate,
    getDefaultBillingPeriodStartDate,
    getValues,
    setValue,
  ]);

  useEffect(() => {
    const subscription = watch(() => {
      handleSubmitDebounce();
    });
    return () => subscription.unsubscribe();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showExportInvoiceModal, setShowExportInvoiceModal] = useState(false);

  const exportInvoiceMutation = useInvoiceExport();
  const exportInvoice = (type: "simple" | "detailed", callback: Function) => {
    exportInvoiceMutation.mutate(
      {
        invoiceId: invoice.id!,
        type,
      },
      {
        onSuccess: () => {
          callback();
        },
      }
    );
  };

  return (
    <Overview
      goBackText={`${t("form.xchange_edit.tabs.billing")} | ${xchange.name}`}
      goBackUri={`/xchanges/${xchange.id}/billing`}
      className="px-2"
    >
      <FormProvider {...formMethods}>
        <Form.Group className="mb-5">
          <OverviewSection>
            <div className="Xchange-Invoice-Overview__Header">
              <div className="Xchange-Invoice-Overview__Header__Name">
                Invoice Details
              </div>
              <div className="Xchange-Invoice-Overview__Header__Info">
                <InvoiceStatusBadge
                  invoice={invoice.xero_invoice!}
                  className="me-1"
                />
                <div className="Xchange-Invoice-Overview__Project-Code">
                  {`${t(
                    "billing.invoice.overview.created"
                  )} ${formatDateToString(new Date(invoice.created_at!))}`}
                </div>
              </div>
            </div>
          </OverviewSection>

          <OverviewSection>
            <Form.Label>{t("billing.invoice.overview.xchange")}</Form.Label>
            <DocumentCard
              name={xchange.name!}
              url={`/xchanges/${xchange.id}`}
              subName={xchange.client?.name}
              variant="secondary"
            />
          </OverviewSection>
          <OverviewSection>
            <Form.Label>
              {t("billing.invoice.overview.original_bills")}
            </Form.Label>
            <div className="Xchange-Invoice-Overview__Documents">
              {getAllBillAttachments().map((attachment) => {
                return (
                  <DocumentCard
                    key={attachment.attachment_id!}
                    variant="secondary"
                    name={attachment.file_name!}
                    buttons={[
                      {
                        label: t("actions.open"),
                        iconClasses: "fa-solid fa-external-link-alt ",
                        disabled: attachmentCurrentlyDownloading.length > 0,
                        isLoading: attachmentCurrentlyDownloading.includes(
                          attachment.attachment_id!
                        ),
                        onClick: () => {
                          openAttachment(attachment.bill!, attachment);
                        },
                      },
                      {
                        label: t("actions.download"),
                        iconClasses: "fa-solid fa-file-download",
                        disabled: attachmentCurrentlyDownloading.length > 0,
                        isLoading: attachmentCurrentlyDownloading.includes(
                          attachment.attachment_id!
                        ),
                        onClick: () => {
                          donwloadAttachment(attachment.bill!, attachment);
                        },
                      },
                    ]}
                  />
                );
              })}
            </div>
          </OverviewSection>
          <OverviewSection>
            <Stack direction="horizontal" className="align-items-start">
              <Col className="pe-2">
                <Form.Label>
                  {t("billing.invoice.overview.total_due")}
                </Form.Label>
                <div className="fs-5 mt-2">
                  {toCurrencyString(calcInvoiceGrandTotal(invoice), "USD", 2)}
                </div>
              </Col>
              <Col className="ps-2">
                <Form.Label>
                  {t("billing.invoice.overview.currency")}
                </Form.Label>
                <div className="mt-2">
                  <CustomSelect
                    name="currency"
                    control={control}
                    options={[
                      {
                        label: "USD",
                        value: "USD",
                      },
                      {
                        label: "CAD",
                        value: "CAD",
                      },
                    ]}
                    isDisabled={true}
                  />
                </div>
              </Col>
            </Stack>
          </OverviewSection>
          <OverviewSection>
            <Form.Label
              aria-required={isFieldRequired(
                validationSchema,
                "billing_period_start"
              )}
            >
              {t("billing.invoice.overview.billing_period")}
            </Form.Label>
            <CustomRangeDatePicker
              startDateName={"billing_period_start"}
              endDateName={"billing_period_end"}
              placeholder={t("billing.invoice.overview.billing_period")}
              isClearable={false}
              className="d-flex justify-content-between"
              disabled={!!invoice.xero_invoice}
            />
            <FieldErrorMessage
              field={errors.billing_period_start || errors.billing_period_end}
              fieldName={t("billing.invoice.overview.billing_period")}
            />

            <Form.Label
              aria-required={isFieldRequired(validationSchema, "due_date")}
              className="mt-4"
            >
              {t("billing.invoice.overview.due_date")}
            </Form.Label>
            <CustomDateInput
              name="due_date"
              placeholderText={t(
                "billing.invoice.overview.due_date_placeholder"
              )}
              minDate={new Date()}
              isInvalid={!!errors.due_date}
              onAfterChange={() => {
                setValue("was_due_date_set_by_user", true);
              }}
              disabled={!!invoice.xero_invoice}
            />
            <FieldErrorMessage
              field={errors.due_date}
              fieldName={t("billing.invoice.overview.due_date")}
            />
          </OverviewSection>
          <>
            <OverviewSection>
              <Button
                type="button"
                className="w-100 mt-2"
                disabled={
                  !xchange || !invoice || !bills || !!invoice.xero_invoice
                }
                onClick={() => {
                  handleSubmitDebounce((errors) => {
                    if (Object.keys(errors).length === 0) {
                      setShowExportInvoiceModal(true);
                    }
                  });
                }}
              >
                <i className="fa-solid fa-file-export me-2"></i>
                {t("billing.invoice.overview.export")}
              </Button>
            </OverviewSection>
            {showExportInvoiceModal && (
              <XchangeInvoiceExportModal
                show={showExportInvoiceModal}
                xchange={xchange}
                invoice={invoice}
                onClose={() => setShowExportInvoiceModal(false)}
                onFinish={() => {
                  navigate(`/xchanges/${invoice?.xchange}/billing`);
                }}
                onTypeSelectionFinish={exportInvoice}
                isFinishLoading={exportInvoiceMutation.isLoading}
                axiosError={exportInvoiceMutation.error}
              />
            )}
          </>
        </Form.Group>
      </FormProvider>
    </Overview>
  );
}
