import { useMutation, useQueryClient } from "@tanstack/react-query";
import IReviewer from "@interfaces/IReviewer";
import ReviewerService from "@services/ReviewerService";

export const useReviewerCreate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ["createReviewer"],
    (reviewer: IReviewer) => ReviewerService.createReviewer(reviewer),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["reviewers"]);
      },
    }
  );
};
