import { createContext, ReactNode, useEffect, useMemo, useState } from "react";
import { Breadcrumb, Nav, Stack, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./Page.scss";
import ErrorBoundary from "@components/ErrorBoundary";

export const ActiveKeyContext = createContext<string>("");

const Page = ({
  children,
  aboveHeaderContent,
  rightHeaderContent,
  title,
  browserTabTitle,
  subTitle,
  variant = "light",
  breadcrumbs = [],
  tabs = [],
  defaultTabKey,
  onTabChange,
  sideBarContent,
  showHeader = true,
  showTabs = false,
}: {
  children: any;
  aboveHeaderContent?: ReactNode;
  rightHeaderContent?: ReactNode;
  title?: string;
  browserTabTitle?: string;
  subTitle?: string;

  variant?: "light" | "primary";
  breadcrumbs?: {
    label: string;
    path: string;
  }[];
  tabs?: {
    tabKey: string;
    label: string;
    description?: string;
    creatable?: boolean;
    createText?: string;
    onCreate?: () => void;
    isLoading?: boolean;
    disabled?: boolean;
  }[];
  defaultTabKey?: string;
  onTabChange?: (tabKey: string) => void;
  sideBarContent?: ReactNode;
  showHeader?: boolean;
  showTabs?: boolean;
}) => {
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState<string>(
    defaultTabKey || tabs?.[0]?.tabKey
  );

  useEffect(() => {
    setActiveKey(defaultTabKey || tabs?.[0]?.tabKey);
  }, [defaultTabKey, tabs]);

  const currentTab = useMemo(() => {
    return tabs.find((tab) => tab.tabKey === activeKey);
  }, [tabs, activeKey]);

  return (
    <ErrorBoundary>
      <HelmetProvider>
        <Helmet>
          <title>{`${browserTabTitle || title || currentTab?.label} | ${t(
            "app_title"
          )}`}</title>
        </Helmet>
      </HelmetProvider>

      <div className="Page">
        {sideBarContent && (
          <div className="Page__SideBar">
            <div className="Page__SideBar__Content">{sideBarContent}</div>
          </div>
        )}

        <div
          className={`Page__Content ${
            sideBarContent && "Page__Content--with-sidebar"
          }`}
        >
          <Tab.Container defaultActiveKey="first">
            {aboveHeaderContent && (
              <div className="mt-5">{aboveHeaderContent}</div>
            )}

            {showHeader && (
              <div
                className={`Page__Header p-4 mb-3 ${
                  variant === "primary" && "Page__Header--primary"
                }`}
              >
                <Stack direction="horizontal">
                  <div>
                    <div
                      className={`Page__Header__Title ${
                        breadcrumbs.length === 0 &&
                        variant === "primary" &&
                        "mb-6"
                      }`}
                    >
                      {title || currentTab?.label}
                    </div>

                    {subTitle && (
                      <div
                        className={`Page__Header__SubTitle ${
                          breadcrumbs.length === 0 &&
                          variant === "primary" &&
                          "mb-6"
                        }`}
                      >
                        {subTitle}
                      </div>
                    )}
                  </div>

                  {rightHeaderContent && (
                    <div className="ms-auto">{rightHeaderContent}</div>
                  )}
                </Stack>

                {breadcrumbs.length > 0 && (
                  <Breadcrumb
                    className={`${
                      variant === "primary" && "Page__Breadcrumb--primary"
                    } mt-4 ${tabs.length === 0 && "mb-6"}`}
                  >
                    {breadcrumbs.map((item, index) => {
                      // Weird condition due to .map limitations in typescript: https://github.com/microsoft/TypeScript/issues/9619
                      const isLastItem = breadcrumbs
                        ? index === breadcrumbs.length - 1
                        : false;

                      return (
                        <Breadcrumb.Item
                          key={index}
                          active={isLastItem}
                          linkAs={Link}
                          linkProps={isLastItem ? undefined : { to: item.path }}
                        >
                          {item.label}
                        </Breadcrumb.Item>
                      );
                    })}
                  </Breadcrumb>
                )}
                {showTabs && tabs.length > 0 && (
                  <Nav className="Page__Tabs mt-6" activeKey={activeKey}>
                    {tabs.map((tab) => (
                      <Nav.Item
                        key={tab.tabKey}
                        className={`Page__Tabs__Item ${
                          activeKey === tab.tabKey && "Page__Tabs__Item--active"
                        } ${tab.creatable && "Page__Tabs__Item--creatable"} ${
                          tab.disabled && "Page__Tabs__Item--disabled"
                        }`}
                        onClick={() => {
                          if (!tab.isLoading && !tab.disabled) {
                            if (tab.creatable) {
                              tab.onCreate?.();
                            } else {
                              setActiveKey(tab.tabKey);
                              onTabChange?.(tab.tabKey);
                            }
                          }
                        }}
                      >
                        <Nav.Link disabled={tab.disabled}>
                          {tab.isLoading ? (
                            <div className="Page__Tabs__Item__Add-Label">
                              <i className="fa-regular fa-spinner fa-spin me-2" />
                              {t("loading")}
                            </div>
                          ) : tab.creatable ? (
                            <div className="Page__Tabs__Item__Add-Label">
                              <i className="fa-regular fa-plus me-2" />
                              {tab.createText}
                            </div>
                          ) : (
                            <>
                              <div
                                className={`Page__Tabs__Item__Label ${
                                  activeKey === tab.tabKey &&
                                  "Page__Tabs__Item__Label--active"
                                }`}
                              >
                                {tab.label}
                              </div>
                              <div
                                className={`Page__Tabs__Item__Desc ${
                                  activeKey === tab.tabKey &&
                                  "Page__Tabs__Item__Desc--active"
                                }`}
                              >
                                {tab.description}
                              </div>
                            </>
                          )}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                )}
              </div>
            )}
            <div>
              <ActiveKeyContext.Provider value={activeKey}>
                <Tab.Content>{children}</Tab.Content>
              </ActiveKeyContext.Provider>
            </div>
          </Tab.Container>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default Page;
