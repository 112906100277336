import OnboardingInvitationService from "@services/OnboardingInvitationService";
import { AxiosError } from "axios";
import Joi, { ExternalHelpers } from "joi";

const isOnboardingInvitationPersonEmailUnique = async (email: string) => {
  try {
    const token = JSON.parse(localStorage.getItem("onboarding") || "")?.[
      "token"
    ];

    if (!token) {
      throw new Error();
    }

    await OnboardingInvitationService.validateOnboardingInvitationData(token, {
      email,
    });
  } catch (e) {
    throw new Joi.ValidationError(
      "custom.onboarding_email_not_unique",
      [
        {
          message: "custom.onboarding_email_not_unique",
          path: ["email"],
          type: "custom.onboarding_email_not_unique",
        },
      ],
      email
    );
  }
};

const isOnboardingInvitationCompanyNameUnique = async (
  company_name: string
) => {
  try {
    const token = JSON.parse(localStorage.getItem("onboarding") || "")?.[
      "token"
    ];

    if (!token) {
      throw new Error();
    }

    await OnboardingInvitationService.validateOnboardingInvitationData(token, {
      company_name,
    });
  } catch (e) {
    throw new Joi.ValidationError(
      "custom.company_name_not_unique",
      [
        {
          message: "custom.company_name_not_unique",
          path: ["company_name"],
          type: "custom.company_name_not_unique",
        },
      ],
      company_name
    );
  }
};

export const XpertOnboardingPersonStepFields = [
  "first_name",
  "last_name",
  "title",
  "email",
  "email_consent",
];

export const XpertOnboardingCompanyStepFields = [
  "company_name",
  "company_website_url",
  "company_size",
  "country",
];

export const XpertOnboardingDomainsStepFields = ["sub_domains"];

export const XpertOnboardingCommitmentStepFields = ["agree_to_commitments"];

export interface XpertOnboardingPersonStepFormValues {
  first_name?: string;
  last_name?: string;
  title?: string;
  email?: string;
  email_consent?: boolean;
}

export interface XpertOnboardingCompanyStepFormValues {
  company_name?: string;
  company_website_url?: string;
  company_size?: string;
  country?: string;
}

export interface XpertOnboardingDomainsStepFormValues {
  sub_domains?: number[];
}

export interface XpertOnboardingCommitmentStepFormValues {
  agree_to_commitments?: boolean;
}

export interface XpertOnboardingFormValues
  extends XpertOnboardingPersonStepFormValues,
    XpertOnboardingCompanyStepFormValues,
    XpertOnboardingDomainsStepFormValues,
    XpertOnboardingCommitmentStepFormValues {}

export const XpertOnboardingPersonStepValidationSchema = Joi.object({
  first_name: Joi.string().required(),
  last_name: Joi.string().required(),
  title: Joi.string().required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .external(isOnboardingInvitationPersonEmailUnique),

  email_consent: Joi.boolean().required().valid(true),
}).messages({
  "any.only": "errors.custom.email_consent_required",
});

export const XpertOnboardingCompanyStepValidationSchema = Joi.object({
  company_name: Joi.string()
    .required()
    .external(isOnboardingInvitationCompanyNameUnique),
  company_website_url: Joi.string().uri().allow(""),
  company_size: Joi.string().required(),
  country: Joi.string().required(),
});

export const XpertOnboardingDomainsStepValidationSchema = Joi.object({
  sub_domains: Joi.array().items(Joi.number()).required(),
});

export const XpertOnboardingCommitmentStepValidationSchema = Joi.object({
  agree_to_commitments: Joi.boolean().required().valid(true),
});

export const XpertOnboardingValidationSchema =
  XpertOnboardingPersonStepValidationSchema.concat(
    XpertOnboardingCompanyStepValidationSchema
  )
    .concat(XpertOnboardingDomainsStepValidationSchema)
    .concat(XpertOnboardingCommitmentStepValidationSchema);

const isPasswordStrong = async (password: string, helpers: ExternalHelpers) => {
  const isPasswordValid = helpers.prefs.context?.isPasswordValid;
  if (!isPasswordValid) {
    try {
      const token = JSON.parse(localStorage.getItem("onboarding") || "")?.[
        "token"
      ];

      if (!token) {
        throw new Error();
      }

      await OnboardingInvitationService.validateOnboardingInvitationData(
        token,
        {
          password,
        }
      );
    } catch (e) {
      if (e instanceof AxiosError) {
        const code = e.response?.data.code;

        throw new Joi.ValidationError(
          `custom.${code}`,
          [
            {
              message: e.response?.data.message ?? `custom.${code}`,
              path: ["password"],
              type: `custom.${code}`,
            },
          ],
          password
        );
      }
    }
  }
};

export interface XpertOnboardingAccountCreationFormValues {
  password?: string;
  password_confirmation?: string;
  accepted_terms?: boolean;
  with_password?: boolean;
}

export const XpertOnboardingAccountCreationValidationSchema = Joi.object({
  password: Joi.string().required().external(isPasswordStrong),
  password_confirmation: Joi.when("$isPasswordValid", {
    is: Joi.boolean().valid(true).required(),
    then: Joi.string().required().valid(Joi.ref("password")).messages({
      "any.only": "errors.custom.password_confirmation_mismatch",
    }),
    otherwise: Joi.string().valid(""),
  }),
  accepted_terms: Joi.when("with_password", {
    is: Joi.boolean().valid(true),
    then: Joi.when("$isPasswordValid", {
      is: Joi.boolean().valid(true).required(),
      then: Joi.boolean().valid(true).required().messages({
        "any.only": "errors.custom.terms_acknowledgement_required",
      }),
      otherwise: Joi.boolean(),
    }),
    otherwise: Joi.boolean().valid(true),
  }),
  with_password: Joi.boolean().optional(),
});
