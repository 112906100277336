import { useMutation, useQueryClient } from "@tanstack/react-query";
import XpertTermService from "@services/XpertTermService";

interface IMutationVars {
  xpertId: number;
  xpertTermId: number;
}

export const useXpertTermDelete = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ xpertTermId }: IMutationVars) =>
      XpertTermService.deleteXpertTerm(xpertTermId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["xpert-terms"]);
      },
    }
  );
};
