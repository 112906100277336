import { useState } from "react";
import CollapseButton from "@components/CollapseButton/CollapseButton";
import { Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  IContractGenerationHook,
  IContractGenerationSubject,
} from "@hooks/contract/useContractGeneration";
import SignNowGenerationButton from "@components/SignNowGenerationButton/SignNowGenerationButton";
import ContractSigneeListItem from "./ContractSigneeListItem/ContractSigneeListItem";
import "./ContractSigneeList.scss";
import { getSubjectName } from "@helpers/party-utils";
import { getSubjectObjectHook } from "@helpers/contract-utils";
import { ContractStatus } from "@interfaces/IContract";
import useAreAllSubjectSignatureInvitesSent from "@hooks/contract/useAreAllSubjectSignatureInvitesSent";
import useGetSubjectContract from "@hooks/contract/useGetSubjectContract";
import useGetSubjectSignatories from "@hooks/contract/useGetSubjectSignatories";
import useGetSubjectSignatures from "@hooks/contract/useGetSubjectSignatures";
import ContractList from "@components/ContractDashboard/ContractList/ContractList";
import useGetSubjectTitle from "@hooks/contract/useGetSubjectTitle";

const ContractSigneeList = ({
  subject,
  contractGenerationHook,
  contractTranslationRootKey = "contracts",
  displayDetails = false,
}: {
  subject: IContractGenerationSubject;
  contractGenerationHook: IContractGenerationHook;
  contractTranslationRootKey?: string;
  displayDetails?: boolean;
}) => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const { parties } = contractGenerationHook;

  const { data: subjectObject } = getSubjectObjectHook(subject)(
    subject.subjectId,
    {
      enabled: !!subject && displayDetails,
    }
  );

  const subjectTitle = useGetSubjectTitle(subject);

  const contract = useGetSubjectContract(subject, contractGenerationHook, true);
  const signatories = useGetSubjectSignatories(subject, contractGenerationHook);
  const signatures = useGetSubjectSignatures(subject, contractGenerationHook);
  const areAllInvitesSent = useAreAllSubjectSignatureInvitesSent(
    subject,
    contractGenerationHook
  );

  const isCollapsable = !!contract && areAllInvitesSent;

  const showDocuments =
    displayDetails &&
    contract &&
    contract.status === ContractStatus.SIGNED &&
    contract.signed_document_id &&
    !isCollapsed;

  return (
    <div
      className={`Contract-Signee-List ${
        displayDetails && "Contract-Signee-List--subject"
      } ${showDocuments && "Contract-Signee-List--with-documents"}`}
    >
      <Stack direction="horizontal" gap={2}>
        <div className="Contract-Signee-List__Title">
          {!displayDetails &&
            t(`${contractTranslationRootKey}.dashboard.signnow.data`)}
          {displayDetails && subjectObject && (
            <>
              {contract?.status === ContractStatus.SIGNED && (
                <i className="fa-solid fa-circle-check me-2 text-success-default" />
              )}
              {getSubjectName(subjectObject)}

              {subjectTitle && (
                <span className="Contract-Signee-List__Title__Subtitle">
                  | {subjectTitle}
                </span>
              )}
            </>
          )}
        </div>

        <div className="Contract-Signee-List__Actions ms-auto">
          <SignNowGenerationButton
            subject={subject}
            contractGenerationHook={contractGenerationHook}
            contractTranslationRootKey={contractTranslationRootKey}
          />

          {isCollapsable && (
            <CollapseButton
              isCollapsed={isCollapsed}
              onClick={() => setIsCollapsed(!isCollapsed)}
            />
          )}
        </div>
      </Stack>

      {!!signatories && !isCollapsed && (
        <div className={`${showDocuments && "mt-4"}`}>
          {signatories?.map((signatory) => (
            <ContractSigneeListItem
              key={signatory.id}
              signatory={signatory}
              party={parties?.find((party) => party.id === signatory.party)}
              signature={signatures?.find(
                (signature) => signature.signatory === signatory.id
              )}
              contractTranslationRootKey={contractTranslationRootKey}
            />
          ))}
        </div>
      )}

      {showDocuments && (
        <>
          <div className="mt-5 mb-4">
            {t(`${contractTranslationRootKey}.dashboard.signnow.documents`)}
          </div>

          <div className="Contract-Signee-List__Documents">
            <ContractList contracts={[contract]} showSignedVersion={true} />
          </div>
        </>
      )}
    </div>
  );
};

export default ContractSigneeList;
