import StepCard from "@components/StepCard/StepCard";
import ICompany from "@interfaces/ICompany";
import CompanyProfileDetails from "@pages/Company/CompanyEditPage/CompanyProfile/CompanyProfileDetails/CompanyProfileDetails";
import CompanyProfileSocials from "@pages/Company/CompanyEditPage/CompanyProfile/CompanyProfileSocials/CompanyProfileSocials";
import { useTranslation } from "react-i18next";

const CompanyProfile = ({ company }: { company?: ICompany }) => {
  const { t } = useTranslation();

  return (
    <>
      <StepCard title={t("companies.profile.details.title")}>
        <CompanyProfileDetails company={company} />
      </StepCard>

      <StepCard title={t("companies.profile.socials.title")}>
        <CompanyProfileSocials company={company} />
      </StepCard>
    </>
  );
};

export default CompanyProfile;
