import { usePeople } from "@hooks/person/usePeople";
import { usePersonCreate } from "@hooks/person/usePersonCreation";
import { usePersonUpdate } from "@hooks/person/usePersonUpdate";

import { useClientTeamMemberCreate } from "@hooks/client/useClientTeamMemberCreate";
import { useClientTeamMemberUpdate } from "@hooks/client/useClientTeamMemberUpdate";
import { useClientUpdate } from "@hooks/client/useClientUpdate";
import IPerson from "@interfaces/IPerson";
import IClient from "@interfaces/IClient";
import MemberModal, {
  MemberFormValues,
} from "@pages/Company/CompanyEditPage/MemberModal/MemberModal";
import { useAddPeopleToClientTeam } from "@hooks/client/useAddPeopleToClientTeam";

const ClientMemberModal = ({
  client,
  memberToEdit,
  show,
  onClose,
}: {
  client: IClient;
  memberToEdit?: {
    person?: IPerson;
    clientMemberId?: number;
  };
  show: boolean;
  onClose: () => void;
}) => {
  const { data: people } = usePeople(
    {
      client_team_members__client__not__in: client.id!.toString(),
    },
    { enabled: show }
  );

  const createClientTeamMemberMutation = useClientTeamMemberCreate();
  const personCreateMutation = usePersonCreate();
  const personUpdateMutation = usePersonUpdate();
  const updateClientMutation = useClientUpdate();
  const updateClientMemberMutation = useClientTeamMemberUpdate();

  const saveWithMember = ({
    roles,
    rate,
    currency,
    ...member
  }: MemberFormValues) => {
    if (memberToEdit?.person) {
      personUpdateMutation.mutate(
        {
          id: memberToEdit.person.id,
          context: client.company_person,
          ...member,
        },
        {
          onSuccess(person) {
            const primaryContactChanged =
              member.primary_contact !==
              (person?.id === client?.primary_contact);

            const financeContactChanged =
              member.finance_contact !==
              (person?.id === client?.finance_contact);

            if (primaryContactChanged || financeContactChanged) {
              updateClientMutation.mutate({
                updatedClient: {
                  id: client.id!,
                  ...(primaryContactChanged && {
                    primary_contact: member.primary_contact ? person.id! : null,
                  }),
                  ...(financeContactChanged && {
                    finance_contact: member.finance_contact ? person.id! : null,
                  }),
                },
              });
            }
            onModalClose();
          },
        }
      );

      updateClientMemberMutation.mutate({
        clientId: client.id!,
        clientTeamMember: {
          id: memberToEdit.clientMemberId,
          rate: rate,
          currency: currency,
          roles: roles,
        },
      });
    } else {
      personCreateMutation.mutate(
        {
          rate: rate,
          currency: currency,
          roles: roles,
          ...member,
        },
        {
          onSuccess(person) {
            createClientTeamMemberMutation.mutate({
              clientId: client.id!,
              clientTeamMember: {
                person: person.id,
                client: client.id,
                rate: person.rate,
                currency: person.currency,
                roles: person.roles,
              },
            });
            updateClientMutation.mutate({
              updatedClient: {
                id: client.id!,
                primary_contact: member.primary_contact
                  ? person.id!
                  : client.primary_contact,
                finance_contact: member.finance_contact
                  ? person.id!
                  : client.finance_contact,
              },
            });
            onModalClose();
          },
        }
      );
    }
  };

  const addPeopleToClientTeamMutation = useAddPeopleToClientTeam(client!.id!);
  const saveWithExistingMember = async (selectedPeople: number[]) => {
    const peopleToAdd = people!.filter((p) => selectedPeople.includes(p.id!));

    await addPeopleToClientTeamMutation.mutate(peopleToAdd);
    onModalClose();
  };

  const isSaving = () => {
    return (
      personCreateMutation.isLoading ||
      createClientTeamMemberMutation.isLoading ||
      personUpdateMutation.isLoading ||
      updateClientMutation.isLoading ||
      updateClientMemberMutation.isLoading
    );
  };

  const getSavingError = () => {
    return memberToEdit
      ? updateClientMutation.error ||
          personUpdateMutation.error ||
          updateClientMemberMutation.error
      : updateClientMutation.error ||
          personCreateMutation.error ||
          createClientTeamMemberMutation.error;
  };

  const onModalClose = () => {
    personCreateMutation.reset();
    createClientTeamMemberMutation.reset();
    personUpdateMutation.reset();
    updateClientMutation.reset();
    updateClientMemberMutation.reset();
    onClose();
  };

  return (
    <>
      <MemberModal
        people={people}
        member={
          memberToEdit
            ? {
                ...memberToEdit.person,
                primary_contact:
                  memberToEdit.person?.id === client?.primary_contact,
                finance_contact:
                  memberToEdit.person?.id === client?.finance_contact,
              }
            : undefined
        }
        show={show}
        showPrimaryContactToggle
        showFinanceContactToggle
        onClose={onModalClose}
        onSaveExisting={saveWithExistingMember}
        onSaveNew={saveWithMember}
        isFinishLoading={isSaving()}
        axiosError={getSavingError()}
      />
    </>
  );
};

export default ClientMemberModal;
