import IClient from "@interfaces/IClient";
import ICompany from "@interfaces/ICompany";
import IPerson from "@interfaces/IPerson";
import IXpert from "@interfaces/IXpert";

export const getSubjectName = (
  subject: IXpert | IClient | ICompany | IPerson
) => {
  if ("name" in subject) {
    return subject.name;
  }

  if ("first_name" in subject && "last_name" in subject) {
    return `${subject.first_name} ${subject.last_name}`;
  }

  return "";
};
