import { joiResolver } from "@hookform/resolvers/joi";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AxiosErrorAlert from "@components/AxiosErrorAlert/AxiosErrorAlert";
import CatalogTermForm, {
  CatalogTermFormValues,
  CatalogTermSchema,
} from "@components/CatalogTerm/CatalogTermForm/CatalogTermForm";
import GenericModal from "@components/GenericModal/GenericModal";
import { useCatalogTermCreate } from "@hooks/term/useCatalogTermCreate";
import ICatalogTerm from "@interfaces/terms/ICatalogTerm";

const CatalogTermModal = ({
  show,
  onClose,
  onFinish,
}: {
  show: boolean;
  onClose: () => void;
  onFinish: (catalogTerm: ICatalogTerm) => void;
}) => {
  const { t } = useTranslation();

  const createTermMutation = useCatalogTermCreate();

  const formMethods = useForm<CatalogTermFormValues>({
    resolver: joiResolver(CatalogTermSchema),
    mode: "onChange",
  });

  const { handleSubmit, reset } = formMethods;

  const saveCatalogTerm = (catalogTerm: CatalogTermFormValues) => {
    createTermMutation.mutate(catalogTerm, {
      onSuccess: (res) => {
        handleClose();
        onFinish(res.data);
      },
    });
  };

  const handleClose = () => {
    createTermMutation.reset();
    reset();
    onClose();
  };

  const handleFinish = () => {
    handleSubmit(saveCatalogTerm)();
  };

  return (
    <GenericModal
      title={t("companies.details.xpert.terms.add_new_term.title")}
      show={show}
      onClose={handleClose}
      onFinish={handleFinish}
      closeText={t("companies.details.xpert.terms.add_new_term.cancel")}
      finishText={t("companies.details.xpert.terms.add_new_term.finish")}
    >
      <FormProvider {...formMethods}>
        <h3 className="pb-6 pt-5 mb-2 ps-0">
          {t("companies.details.xpert.terms.add_new_term.title")}
        </h3>
        <CatalogTermForm />
      </FormProvider>
      <AxiosErrorAlert
        response={createTermMutation.error}
        translationPrefix="companies.details.xpert.terms.fields."
      />
    </GenericModal>
  );
};

export default CatalogTermModal;
