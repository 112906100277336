import { useState } from "react";
import { Button, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ActionsDropdown from "@components/ListOptionsDropdown/ActionsDropdown";
import IXpert from "@interfaces/IXpert";
import ICatalogTerm from "@interfaces/terms/ICatalogTerm";
import IXpertTerm from "@interfaces/terms/IXpertTerm";
import CatalogTermModal from "./CatalogTermModal";
import TermTable from "./TermTable/TermTable";
import XpertTermModal from "./XpertTermModal";

const XpertTerms = ({ xpert }: { xpert: IXpert }) => {
  const { t } = useTranslation();
  const [showXpertTermModal, setShowXpertTermModal] = useState(false);
  const [showNewTermModal, setShowNewTermModal] = useState(false);
  const [xpertTermToEdit, setXpertTermToEdit] = useState<IXpertTerm>();
  const [catalogTermToAdd, setCatalogTermToAdd] = useState<ICatalogTerm>();
  return (
    <>
      <div className="p-3">
        <Stack direction="horizontal" gap={3} className="mb-5">
          <h5 className="mb-0">
            {" "}
            {t("companies.details.xpert.terms.subtitle")}
          </h5>
          <div className="ms-auto">
            <ActionsDropdown
              actions={[
                {
                  label: t(
                    "companies.details.xpert.terms.existing_term.action_label"
                  ),
                  onClick: () => setShowXpertTermModal(true),
                },
                {
                  label: t(
                    "companies.details.xpert.terms.add_new_term.action_label"
                  ),
                  onClick: () => setShowNewTermModal(true),
                },
              ]}
            >
              <Button size="sm" onClick={() => {}}>
                {t("companies.details.xpert.terms.add_term")}
                <i className="fa-regular fa-chevron-down ms-2" />
              </Button>
            </ActionsDropdown>
          </div>
        </Stack>
        <TermTable
          xpertId={xpert.id!}
          onEdit={(xpertTerm) => {
            setXpertTermToEdit(xpertTerm);
            setShowXpertTermModal(true);
          }}
        />
      </div>
      <XpertTermModal
        show={showXpertTermModal}
        xpertId={xpert.id!}
        catalogTermToAdd={catalogTermToAdd}
        xpertTermToEdit={xpertTermToEdit}
        onClose={() => {
          setShowXpertTermModal(false);
          setCatalogTermToAdd(undefined);
          setXpertTermToEdit(undefined);
        }}
      />
      <CatalogTermModal
        show={showNewTermModal}
        onClose={() => setShowNewTermModal(false)}
        onFinish={(catalogTerm) => {
          setCatalogTermToAdd(catalogTerm);
          setShowXpertTermModal(true);
        }}
      />
    </>
  );
};

export default XpertTerms;
