import { joiResolver } from "@hookform/resolvers/joi";
import { useCallback, useEffect } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AxiosErrorAlert from "@components/AxiosErrorAlert/AxiosErrorAlert";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import WizardModal from "@components/WizardModal/WizardModal";
import CatalogTermSelectionForm from "@components/CatalogTermSelectionForm/CatalogTermSelectionForm";
import { useCatalogTerms } from "@hooks/term/useCatalogTerms";
import IXchangeTerm from "@interfaces/terms/IXchangeTerm";
import { useXchangeTermCreate } from "@hooks/term/useXchangeTermCreate";
import { useXchangeTermUpdate } from "@hooks/term/useXchangeTermUpdate";
import XchangeTermDetailsForm, {
  TermFormValues,
  xchangeTermSchema,
} from "@pages/Xchange/XchangeEdit/XchangeDeliverables/XchangeTermDetailsForm";
import Joi from "joi";
import IXchange from "@interfaces/IXchange";
import ICatalogTerm from "@interfaces/terms/ICatalogTerm";
import IEpic from "@interfaces/IEpic";
import { getXchangeXpertStats } from "@helpers/xchange-utils";

export interface MultipleXchangeTermsFormValues {
  catalog_terms: number[];
  terms: TermFormValues[];
}

export const MultipleXchangeTermsSchema = Joi.object({
  catalog_terms: Joi.array().items(Joi.number()).required(),
  terms: Joi.array().items(xchangeTermSchema).required(),
});

const XchangeTermModal = ({
  show,
  xchange,
  xchangeTermToEdit,
  epics,
  onClose,
}: {
  show: boolean;
  xchange?: IXchange;
  xchangeTermToEdit?: IXchangeTerm;
  epics?: IEpic[];
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  const createXchangeTermMutation = useXchangeTermCreate();
  const updateXchangeTermMutation = useXchangeTermUpdate();

  const formMethods = useForm<MultipleXchangeTermsFormValues>({
    resolver: joiResolver(MultipleXchangeTermsSchema),
    mode: "onChange",
  });

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = formMethods;

  const {
    fields,
    remove: removeFromXchangeTerms,
    append: appendToXchangeTerms,
    replace: replaceXchangeTerms,
  } = useFieldArray({
    control,
    name: "terms",
  });

  const saveXpertTerm = (data: MultipleXchangeTermsFormValues) => {
    if (xchangeTermToEdit) {
      const xchangeTerm = data.terms[0];
      updateXchangeTermMutation.mutate(
        {
          xchangeId: xchangeTermToEdit.xchange?.id!,
          xchangeTerm: {
            ...xchangeTerm,
            xpert_involved: xchangeTerm.xpert_involved
              ? xchangeTerm.xpert_involved
              : undefined,
            start_date: xchangeTerm.start_date
              ? new Date(xchangeTerm.start_date).toISOString()
              : undefined,
          },
        },
        {
          onSuccess: () => {
            handleClose();
          },
        }
      );
    } else {
      Promise.all(
        data.terms.map((xchange_term) => {
          return createXchangeTermMutation.mutate({
            xchangeTerm: {
              ...xchange_term,
              xchange: xchange?.id!,
              xpert_involved: xchange_term.xpert_involved ?? undefined,
              start_date: xchange_term.start_date
                ? new Date(xchange_term.start_date).toISOString()
                : undefined,
            },
          });
        })
      );

      handleClose();
    }
  };

  const handleClose = () => {
    createXchangeTermMutation.reset();
    reset();
    onClose();
  };

  const handleFinish = () => {
    handleSubmit(saveXpertTerm)();
  };

  const { data: catalogTermInfiniteData } = useCatalogTerms(
    {
      id__in: (getValues("terms") || [])
        .map((xchangeTerm) => xchangeTerm.catalog_term)
        .join(","),
      page_size: (getValues("terms") || []).length,
    },
    {
      enabled:
        (getValues("terms") || []).map(
          (xchangeTerm) => xchangeTerm.catalog_term
        ).length > 0,
    }
  );
  const catalogTerms = catalogTermInfiniteData
    ? catalogTermInfiniteData.pages.flatMap((p) => p.results)
    : [];

  const resetForm = useCallback(() => {
    reset({
      terms: [],
      catalog_terms: [],
    });
  }, [reset]);

  useEffect(() => {
    if (show) resetForm();
    if (xchangeTermToEdit) {
      setValue("catalog_terms", [xchangeTermToEdit.catalog_term!]);
      replaceXchangeTerms([
        {
          id: xchangeTermToEdit.id,
          catalog_term: xchangeTermToEdit.catalog_term,
          xpert_term: xchangeTermToEdit.xpert_term,
          start_date: new Date(xchangeTermToEdit.start_date!),
          multiplier: +xchangeTermToEdit.multiplier!,
          duration: xchangeTermToEdit.duration,
          cost_per_sprint: xchangeTermToEdit.cost_per_sprint,
          currency: xchangeTermToEdit.currency,
          sub_domain: xchangeTermToEdit.sub_domain?.id,
          stages: (xchangeTermToEdit.stages || []).map((stage) => stage.id!),
          title: xchangeTermToEdit.title,
          xpert_involved: xchangeTermToEdit.xpert_involved,
          description: xchangeTermToEdit.description,
        },
      ]);
    }
  }, [show, resetForm, setValue, xchangeTermToEdit, replaceXchangeTerms]);

  const updateXchangeTerms = (catalog_terms: ICatalogTerm[]) => {
    const xchangeTermsToRemove = getValues("terms").reduce(
      (acc, curr, index) => {
        if (!catalog_terms.map((ct) => ct.id).includes(curr.catalog_term!)) {
          acc.push(index);
        }
        return acc;
      },
      [] as number[]
    );

    for (const xchangeTermToRemove of xchangeTermsToRemove) {
      removeFromXchangeTerms(xchangeTermToRemove);
    }

    const catalogTermsToAdd = catalog_terms.filter((catalog_term) => {
      return !getValues("terms").some(
        (xchange_term) => xchange_term.catalog_term === catalog_term.id
      );
    });

    for (const catalogTermToAdd of catalogTermsToAdd) {
      appendToXchangeTerms({
        id: undefined,
        catalog_term: catalogTermToAdd.id,
        xpert_term: undefined,
        start_date: undefined,
        multiplier: 1,
        duration: undefined,
        cost_per_sprint: undefined,
        currency: "",
        sub_domain: catalogTermToAdd.sub_domain?.id,
        stages: [],
        title: "",
        xpert_involved: null,
      });
    }
  };

  return (
    <FormProvider {...formMethods}>
      <WizardModal
        show={show}
        startingStep={xchangeTermToEdit ? 1 : 0}
        onClose={handleClose}
        onFinish={handleFinish}
        steps={[
          {
            title: t(
              `form.deliverables.term.${
                xchangeTermToEdit ? "edit" : "add"
              }.title`
            ),
            content: (
              <>
                <h3 className="mb-6">
                  {t(
                    `form.deliverables.term.${
                      xchangeTermToEdit ? "edit" : "add"
                    }.header`
                  )}
                </h3>

                <CatalogTermSelectionForm
                  name="catalog_terms"
                  control={control}
                  fixedGetCatalogTermsParams={{
                    xpert_terms__gte: 1,
                  }}
                  onChange={(catalog_terms) => {
                    updateXchangeTerms(catalog_terms);
                  }}
                />
                <FieldErrorMessage
                  field={errors?.catalog_terms}
                  className="mt-6"
                  fieldName={t("form.deliverables.term.fields.term")}
                />
              </>
            ),
            isCompleted:
              (getValues("terms") || []).length > 0 && !errors.catalog_terms,
          },
          {
            title: t(
              `form.deliverables.term.${
                xchangeTermToEdit ? "edit" : "add"
              }.title`
            ),
            content: (
              <>
                {fields.map((xchangeTerm, index) => {
                  const catalogTerm = catalogTerms.find(
                    (catalogTerm) => catalogTerm.id === xchangeTerm.catalog_term
                  );

                  return (
                    <div key={`xchange_term_for_${catalogTerm?.id}`}>
                      <h3 className={`mb-6 ${index > 0 && "mt-6"}`}>
                        {catalogTerm?.title}
                      </h3>

                      <XchangeTermDetailsForm
                        catalogTerm={catalogTerm}
                        editingXchangeTerm={xchangeTermToEdit}
                        field_index={index}
                        minStartDate={xchange?.start_date ?? ""}
                        disableXpertInvolved={
                          xchangeTermToEdit &&
                          epics?.some((epic) =>
                            epic.xperts?.includes(
                              xchangeTermToEdit.xpert_involved!
                            )
                          ) &&
                          getXchangeXpertStats(
                            xchange!,
                            xchangeTermToEdit.xpert_involved!
                          ).totalInvolvementCount === 1
                        }
                      />
                    </div>
                  );
                })}

                <AxiosErrorAlert
                  response={
                    createXchangeTermMutation.error ||
                    updateXchangeTermMutation.error
                  }
                  translationPrefix="form.deliverables.term.fields."
                />
              </>
            ),
            hideStepNumber: !!xchangeTermToEdit,
            disableGoBack: !!xchangeTermToEdit,
            isCompleted: true,
          },
        ]}
      />
    </FormProvider>
  );
};

export default XchangeTermModal;
