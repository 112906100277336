import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IUserWrite } from "@interfaces/IUser";
import UserService from "@services/UserService";

export const useUserUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation((user: IUserWrite) => UserService.updateUser(user), {
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries(["users", { id }]);
      queryClient.invalidateQueries(["login-status"]);
    },
  });
};
