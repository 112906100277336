import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import InfoInput from "@components/InfoInput/InfoInput";
import { useClientUpdate } from "@hooks/client/useClientUpdate";
import IClient from "@interfaces/IClient";

export interface ClientFinanceFormValues {
  invoice_email?: string;
}

export const clientFinanceValidationSchema = Joi.object({
  invoice_email: Joi.string()
    .email({ tlds: { allow: false } })
    .pattern(new RegExp("^[^A-Z]+$"))
    .messages({
      "string.pattern.base": "errors.custom.email_capitalized",
    })
    .allow(""),
});

const ClientFinanceForm = ({ client }: { client: IClient }) => {
  const { t } = useTranslation();
  const updateClientMutation = useClientUpdate();

  const formMethods = useForm<ClientFinanceFormValues>({
    mode: "onChange",
    resolver: joiResolver(clientFinanceValidationSchema),
    defaultValues: {
      invoice_email: client.invoice_email,
    },
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = formMethods;

  const formSubmit = (data: ClientFinanceFormValues) => {
    updateClientMutation.mutate({
      updatedClient: {
        id: client.id!,
        ...data,
      },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <Form onBlur={handleSubmit(formSubmit)} className="ps-4 pe-4 pb-4">
        <h5 className="mb-5">{t("companies.details.client.finance.title")}</h5>

        <Form.Group className="mb-3">
          <Form.Label>
            {t("companies.details.client.finance.invoice_recipient")}
          </Form.Label>
          <InfoInput type="text" {...register("invoice_email")} />
          <FieldErrorMessage
            field={errors.invoice_email}
            fieldName={t("companies.details.client.finance.invoice_recipient")}
          />
        </Form.Group>
      </Form>
    </FormProvider>
  );
};

export default ClientFinanceForm;
