import WizardModal from "@components/WizardModal/WizardModal";
import { useTranslation } from "react-i18next";
import {
  IContractGenerationHook,
  IContractGenerationSubject,
} from "@hooks/contract/useContractGeneration";
import { useSignNowRevoke } from "@hooks/signature/useSignNowRevoke";
import useGetSubjectContract from "@hooks/contract/useGetSubjectContract";

const SignNowRevokeWizard = ({
  show,
  contractGenerationHook,
  subject,
  onClose,
  onFinish,
  contractTranslationRootKey = "contracts",
}: {
  show: boolean;
  contractGenerationHook: IContractGenerationHook;
  subject: IContractGenerationSubject;
  onClose: () => void;
  onFinish: () => void;
  contractTranslationRootKey?: string;
}) => {
  const { t } = useTranslation();

  const contract = useGetSubjectContract(subject, contractGenerationHook, true);
  const revokeSignNowMutation = useSignNowRevoke();

  const revoke = async (callback: Function) => {
    if (contract) {
      await revokeSignNowMutation.mutateAsync(contract.id!);
    }
    callback();
  };

  return (
    <WizardModal
      show={show}
      onClose={() => onClose()}
      onCloseText={t("actions.cancel")}
      onFinish={() => onFinish()}
      onFinishText={t("actions.close")}
      steps={[
        {
          title: t(
            `${contractTranslationRootKey}.dashboard.signnow.revoke_wizard.title`
          ),
          content: (
            <>
              <h3 className="mb-6">
                {t(
                  `${contractTranslationRootKey}.dashboard.signnow.revoke_wizard.title`
                )}
              </h3>
              <p>
                {t(
                  `${contractTranslationRootKey}.dashboard.signnow.revoke_wizard.disclaimer`
                )}
              </p>
            </>
          ),

          onFinishText: t(
            `${contractTranslationRootKey}.dashboard.signnow.revoke_wizard.confirm`
          ),
          onFinish: (callback) => {
            revoke(callback!);
          },
          isLoading: revokeSignNowMutation.isLoading,
          isCompleted: true,
        },
        {
          title: t(
            `${contractTranslationRootKey}.dashboard.signnow.revoke_wizard.title`
          ),
          hideStepNumber: true,
          disableGoBack: true,
          isCompleted: true,
          content: (
            <>
              <h3 className="mt-4 mb-6">
                <i className="fa-solid fa-circle-check me-3 text-success-default" />
                {t(
                  `${contractTranslationRootKey}.dashboard.signnow.revoke_wizard.revoked`
                )}
              </h3>
              <p>
                {t(
                  `${contractTranslationRootKey}.dashboard.signnow.revoke_wizard.revoked_help`
                )}
              </p>
            </>
          ),
        },
      ]}
    />
  );
};

export default SignNowRevokeWizard;
