import {
  IContractGenerationHook,
  IContractGenerationSubject,
} from "./useContractGeneration";
import useGetSubjectSignatories from "./useGetSubjectSignatories";
import useGetSubjectSignatures from "./useGetSubjectSignatures";

const useAreAllSubjectSignatureInvitesSent = (
  subject: IContractGenerationSubject,
  contractGenerationHook: IContractGenerationHook
) => {
  const signatories = useGetSubjectSignatories(subject, contractGenerationHook);
  const signatures = useGetSubjectSignatures(subject, contractGenerationHook);

  return (
    signatories &&
    signatories.length > 0 &&
    signatures &&
    signatures.length > 0 &&
    signatories.length === signatures.length &&
    signatures.every((signature) => signature.invitation_date !== null)
  );
};

export default useAreAllSubjectSignatureInvitesSent;
