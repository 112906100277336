import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  IClientTeamMember,
  IClientTeamMemberCreateUpdate,
} from "@interfaces/IClient";
import ClientService from "@services/ClientService";

interface IMutationVars {
  clientId: number;
  clientTeamMember: IClientTeamMemberCreateUpdate;
}

export const useClientTeamMemberCreate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ clientId, clientTeamMember }: IMutationVars) =>
      ClientService.createClientTeamMember(clientId, clientTeamMember),
    {
      onSuccess: (data: IClientTeamMember) => {
        queryClient.invalidateQueries([
          "clients",
          { clientId: data.client },
          "teams",
        ]);
      },
    }
  );
};
