import { useMutation, useQueryClient } from "@tanstack/react-query";
import ISignNowSignature from "@interfaces/ISignNowSignature";
import SignNowService from "@services/SignNowService";

interface IMutationVars {
  signature: ISignNowSignature;
}

export const useCreateSignNowSignature = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ["createSignNowSignature"],
    ({ signature }: IMutationVars) => SignNowService.createSignature(signature),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["signatures"]);
        queryClient.invalidateQueries(["contracts"]);
      },
    }
  );
};
