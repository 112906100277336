import { Spinner, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AxiosErrorAlert from "@components/AxiosErrorAlert/AxiosErrorAlert";
import CardPlaceholder from "@components/CardPlaceholder/CardPlaceholder";
import { useCatalogTerms } from "@hooks/term/useCatalogTerms";
import { useCatalogTermUpdate } from "@hooks/term/useCatalogTermUpdate";
import ICatalogProduct from "@interfaces/products/ICatalogProduct";
import ICatalogTerm from "@interfaces/terms/ICatalogTerm";
import ProductTermRow from "./ProductTermRow/ProductTermRow";
import "./ProductTermsTable.scss";

const ProductTermsTable = ({ product }: { product?: ICatalogProduct }) => {
  const { t } = useTranslation();

  const {
    data: catalogTermInfiniteData,
    isLoading: areTermsLoading,
    error: catalogTermsError,
  } = useCatalogTerms(
    {
      products: product?.id,
      page_size: 1000,
    },
    {
      enabled: !!product,
    }
  );
  const terms = catalogTermInfiniteData
    ? catalogTermInfiniteData.pages.flatMap((p) => p.results)
    : [];

  const updateCatalogTermMutation = useCatalogTermUpdate();
  const removeCatalogTermFromProduct = (catalogTerm: ICatalogTerm) => {
    updateCatalogTermMutation.mutate({
      id: catalogTerm.id!,
      term: {
        products: (catalogTerm.products || []).filter((p) => p !== product!.id),
      },
    });
  };

  return !product || (!areTermsLoading && (terms ?? []).length === 0) ? (
    <CardPlaceholder
      iconClassName={"fa-solid fa-rectangle-vertical-history"}
      title={t("product.details.terms.empty")}
      description={t("product.details.terms.empty_desc")}
    />
  ) : areTermsLoading ? (
    <div className="d-flex justify-content-center mb-4">
      <Spinner animation="border" variant="primary" />
    </div>
  ) : catalogTermsError ? (
    <AxiosErrorAlert response={catalogTermsError} />
  ) : (
    <Table className="Product-Terms-Table">
      <thead>
        <tr>
          <th className="Product-Terms-Table__Icons"></th>
          <th className="align-top">{t("product.details.terms.table.term")}</th>
          <th>{t("product.details.terms.table.domain")}</th>
          <th>{t("product.details.terms.table.cost_range")}</th>
          <th>{t("product.details.terms.table.duration")}</th>
          <th className="table-actions Product-Terms-Table__Actions"></th>
        </tr>
      </thead>
      <tbody>
        {terms?.map((term) => (
          <ProductTermRow
            key={term.id!}
            term={term}
            onRemove={() => removeCatalogTermFromProduct(term)}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default ProductTermsTable;
