import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import ICatalogTerm from "@interfaces/terms/ICatalogTerm";
import CatalogTermService from "@services/CatalogTermService";

export const useCatalogTerm = (
  id: number,
  options?: Pick<
    UseQueryOptions<ICatalogTerm, Error>,
    "enabled" | "onSuccess" | "retry"
  >
) =>
  useQuery(
    ["terms", { id }],
    () => CatalogTermService.getCatalogTerm(id),
    options
  );
