import ICatalogTerm from "@interfaces/terms/ICatalogTerm";

export const hasNullCostRanges = (terms?: ICatalogTerm[] | undefined) => {
    if (terms) {
      return terms.some((term) =>
        term.cost_ranges?.every(
          (costRange) => costRange.min === null && costRange.max === null
        )
      );
    }
    return false;
  };