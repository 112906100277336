import { useState } from "react";
import { Button, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ICatalogProduct from "@interfaces/products/ICatalogProduct";
import ProductTermModal from "./ProductTermModal/ProductTermModal";
import ProductTermsTable from "./ProductTermsTable/ProductTermsTable";

const ProductTerms = ({ product }: { product?: ICatalogProduct }) => {
  const { t } = useTranslation();

  const [showAddCatalogTermModal, setShowAddCatalogTermModal] = useState(false);

  return (
    <>
      <div className="p-3">
        <Stack direction="horizontal" gap={3} className="mb-5">
          <h5 className="mb-0">{t("product.details.terms.subtitle")}</h5>
          <div className="ms-auto">
            <Button
              size="sm"
              onClick={() => {
                setShowAddCatalogTermModal(true);
              }}
              disabled={!product}
            >
              <i className="fa-regular fa-plus me-2" />
              {t("product.details.terms.add_term")}
            </Button>
          </div>
        </Stack>
        <ProductTermsTable product={product} />
      </div>

      {showAddCatalogTermModal && product && (
        <ProductTermModal
          product={product}
          show={showAddCatalogTermModal}
          onClose={() => {
            setShowAddCatalogTermModal(false);
          }}
        />
      )}
    </>
  );
};

export default ProductTerms;
