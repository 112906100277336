import { Modify } from "@helpers/typing";

export enum PartySubjectType {
  Xpert = "xpert",
  Client = "client",
  Company = "company",
  Person = "person",
}

interface IParty {
  id?: number;
  subject_id?: number;
  subject_type?: PartySubjectType;
  subject_name?: string;
  contract?: number;
  main?: boolean;
}

export default IParty;

export type IPartyWrite = Modify<
  IParty,
  {
    subject: string;
  }
>;
