import { googleLogout } from "@react-oauth/google";
import Service from "@services/Service";
import UserService from "@services/UserService";
import IUser from "@interfaces/IUser";
import { AxiosError } from "axios";

export default class AuthService extends Service {
  protected static AUTH_ENDPOINT = `/auth`;

  public static isUserLoggedIn(): boolean {
    return !!localStorage.getItem("user");
  }

  public static async isSessionValid(id?: number): Promise<boolean> {
    let isSessionValid = true;

    if (id) {
      try {
        const user = await UserService.getUser(id);
        isSessionValid = user.is_self === true;

        if (isSessionValid) {
          localStorage.setItem("user", JSON.stringify(user));
        }
      } catch (error) {
        if (
          error instanceof AxiosError &&
          error.response &&
          [401, 403].includes(error.response.status)
        ) {
          isSessionValid = false;
        }
      }
    }

    if (!isSessionValid) {
      localStorage.removeItem("user");
      googleLogout();
    }

    window.dispatchEvent(new Event("storage"));
    return isSessionValid;
  }

  public static async googleLogin(authorization_token: string): Promise<void> {
    const data = { authorization_token: authorization_token };
    const res = await this.axios.post<IUser>(
      `${this.AUTH_ENDPOINT}/google-login`,
      data
    );
    localStorage.setItem("user", JSON.stringify(res.data));
    localStorage.removeItem("onboarding");
    window.dispatchEvent(new Event("storage"));
  }

  public static async logout(): Promise<void> {
    localStorage.removeItem("user");
    await this.axios.post(`${this.AUTH_ENDPOINT}/logout`);
    googleLogout();
    window.dispatchEvent(new Event("storage"));
  }
}
