import { useController, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import IInvoice from "@interfaces/IInvoice";
import IXchange from "@interfaces/IXchange";
import AxiosErrorAlert from "@components/AxiosErrorAlert/AxiosErrorAlert";
import ListItemCheckbox from "@components/ListItemCheckbox/ListItemCheckbox";
import WizardModal from "@components/WizardModal/WizardModal";
import { Button } from "react-bootstrap";

const XchangeInvoiceExportModal = ({
  show,
  xchange,
  invoice,
  onClose,
  onFinish,
  onTypeSelectionFinish,
  isFinishLoading,
  axiosError,
}: {
  show: boolean;
  xchange?: IXchange;
  invoice?: IInvoice;
  onClose: () => void;
  onFinish: () => void;
  onTypeSelectionFinish: (
    type: "simple" | "detailed",
    callback: Function
  ) => void;
  isFinishLoading: boolean;
  axiosError?: any;
}) => {
  const { t } = useTranslation();

  const { control } = useForm<{
    type: "simple" | "detailed";
  }>();

  const { field: type } = useController({
    name: "type",
    control,
  });

  return (
    <WizardModal
      show={show}
      onClose={onClose}
      onCloseText={t("actions.cancel")}
      onFinish={onFinish}
      onFinishText={t("actions.close")}
      steps={[
        {
          title: t("billing.invoice.export.title"),
          content: (
            <>
              <h3 className="pb-6 pt-5 mb-2 ps-0">
                {t("billing.invoice.export.header")}
              </h3>

              <ListItemCheckbox
                title={t("billing.invoice.export.simple")}
                description={t("billing.invoice.export.simple_desc")}
                type="radio"
                onClick={() => {
                  type.onChange("simple");
                }}
                checked={type.value === "simple"}
                disabled={isFinishLoading}
              />
              <ListItemCheckbox
                title={t("billing.invoice.export.detailed")}
                description={t("billing.invoice.export.detailed_desc")}
                type="radio"
                onClick={() => {
                  type.onChange("detailed");
                }}
                checked={type.value === "detailed"}
                disabled={isFinishLoading}
              />

              <AxiosErrorAlert
                response={axiosError}
                translationPrefix="errors.codes."
              />
            </>
          ),
          onFinishText: t("billing.invoice.export.finish"),
          onFinish: (callback) => {
            onTypeSelectionFinish(type.value, callback!);
          },
          isLoading: isFinishLoading,
          isCompleted: type.value !== undefined,
        },
        {
          title: t("billing.invoice.export.title"),
          hideStepNumber: true,
          disableGoBack: true,
          isCompleted: true,
          onCloseShouldBeOnFinish: true,
          extraButtons: invoice?.online_invoice_url ? (
            <a href={invoice?.online_invoice_url} target="_bank">
              <Button variant="outline-light">
                {t("billing.invoice.export.exported_link")}
                <i className="fa-solid fa-external-link-alt ms-2"></i>
              </Button>
            </a>
          ) : (
            <Button variant="outline-light" disabled={true}>
              <i className="fa-regular fa-spinner-third fa-spin"></i>
            </Button>
          ),
          content: (
            <>
              <h3 className="mt-4 mb-6">
                <i className="fa-solid fa-circle-check me-3 text-success-default" />
                {t("billing.invoice.export.exported")}
              </h3>
              {t("billing.invoice.export.exported_desc")}
            </>
          ),
        },
      ]}
    />
  );
};

export default XchangeInvoiceExportModal;
