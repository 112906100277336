import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IXchangeCreateUpdate } from "@interfaces/IXchange";
import XchangeService from "@services/XchangeService";

export const useXchangeCreate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (xchange: IXchangeCreateUpdate) => XchangeService.createXchange(xchange),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["xchanges"]);
      },
    }
  );
};
