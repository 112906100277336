import { useMutation, useQueryClient } from "@tanstack/react-query";
import IXpert from "@interfaces/IXpert";
import XpertService from "@services/XpertService";

interface IMutationVars {
  updatedXpert: IXpert;
}

export const useXpertUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ["updateXpert"],
    ({ updatedXpert }: IMutationVars) => XpertService.updateXpert(updatedXpert),
    {
      onSuccess: (data, { updatedXpert }) => {
        queryClient.invalidateQueries(["xperts"]);
      },
    }
  );
};
