import React from "react";
import IDocument from "@interfaces/IDocument";
import "./DocumentList.scss";
import DocumentListItem from "./DocumentListItem/DocumentListItem";

const DocumentList = ({ documents }: { documents: IDocument[] }) => {
  return (
    <div className="Document-List">
      {documents?.map((document) => (
        <DocumentListItem key={document.id} document={document} />
      ))}
    </div>
  );
};

export default DocumentList;
