import { toCostRangeString } from "@helpers/currencyUtils";
import ICostRange from "@interfaces/ICostRange";
import "./CostRange.scss";

const CostRange = ({
  costRange,
  className,
}: {
  costRange: ICostRange;
  className?: string;
}) => {
  return !!costRange.min && !!costRange.max ? (
    <div className={className}>
      {toCostRangeString(costRange)}
      <span className="Cost-Range__Currency ms-1">{costRange.currency}</span>
    </div>
  ) : (
    <></>
  );
};

export default CostRange;
