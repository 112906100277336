import { useMutation, useQueryClient } from "@tanstack/react-query";
import ClientService from "@services/ClientService";

interface IMutationVars {
  clientId: number;
  clientTeamMemberId: number;
}

export const useClientTeamMemberDelete = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ clientId, clientTeamMemberId }: IMutationVars) =>
      ClientService.deleteClientTeamMember(clientId, clientTeamMemberId),
    {
      onSuccess: (_, { clientId }) => {
        queryClient.invalidateQueries(["clients", { clientId }, "teams"]);
      },
    }
  );
};
