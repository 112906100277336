import { pull } from "lodash";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import StepCard from "@components/StepCard/StepCard";
import { useClient } from "@hooks/client/useClient";
import { useClientUpdate } from "@hooks/client/useClientUpdate";
import XchangeTable from "@pages/Company/CompanyEditPage/XchangeTable/XchangeTable";
import ClientDetailsForm from "./ClientDetailsForm";
import ClientMembers from "./ClientMembers/ClientMembers";
import ICompany from "@interfaces/ICompany";

const CompanyClientDetails = ({
  clientId,
  xpertId,
  company,
}: {
  clientId?: number;
  xpertId?: number;
  company?: ICompany;
}) => {
  const { t } = useTranslation();

  const { isLoading: isClientLoading, data: client } = useClient(clientId!, {
    enabled: !!clientId,
  });

  const updateClientMutation = useClientUpdate();
  const toggleCompletedSection = (isCompleted: boolean, section: string) => {
    const updatedComplatedSections = client?.completed_sections || [];
    if (isCompleted) {
      updatedComplatedSections.push(section);
    } else {
      pull(updatedComplatedSections, section);
    }

    updateClientMutation.mutate({
      updatedClient: {
        id: client?.id!,
        completed_sections: updatedComplatedSections,
      },
    });
  };

  return (
    <>
      {isClientLoading ? (
        <div className="d-flex justify-content-center mb-4">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          <StepCard title={t("companies.details.client.xchanges")}>
            <XchangeTable
              clientId={clientId}
              xpertId={xpertId}
              fetchXpertXchanges={false}
            />
          </StepCard>

          <StepCard
            title={t("companies.details.client.details.title")}
            defaultMarkedAsCompleted={
              !!client?.completed_sections?.includes("details")
            }
            onCompletionStatusChange={(isCompleted) =>
              toggleCompletedSection(isCompleted, "details")
            }
          >
            {client && <ClientDetailsForm client={client} />}
          </StepCard>

          <StepCard title={t("companies.details.client.members.title")}>
            {client && <ClientMembers client={client} />}
          </StepCard>
        </>
      )}
    </>
  );
};

export default CompanyClientDetails;
