import { useTranslation } from "react-i18next";
import ContractReviewerList from "./ContractReviewerList/ContractReviewerList";

import {
  IContractGenerationHook,
  IContractGenerationSubject,
} from "@hooks/contract/useContractGeneration";
import { Alert } from "react-bootstrap";
import "./ContractReviewDashboard.scss";
import useGetSubjectContract from "@hooks/contract/useGetSubjectContract";
import {
  filterSubjectsByContractType,
  getContractTypeTranslationRootKey,
  getContractTypes,
  isContractTypeGroupBySubject,
} from "@helpers/contract-utils";
import ContractList from "@components/ContractDashboard/ContractList/ContractList";
import StepCard from "@components/StepCard/StepCard";

const ContractReviewDashboard = ({
  subjects,
  contractGenerationHook,
  viewContractDisabled = false,
  contractTranslationRootKey = "contracts",
}: {
  subjects: IContractGenerationSubject[];
  contractGenerationHook: IContractGenerationHook;
  viewContractDisabled?: boolean;
  contractTranslationRootKey?: string;
}) => {
  const { t } = useTranslation();

  const { expectedContractTypes, areContractsLoading } = contractGenerationHook;

  const contract = useGetSubjectContract(
    subjects[0],
    contractGenerationHook,
    true
  );

  const showDocuments =
    subjects &&
    subjects.length === 1 &&
    contract?.last_generation_is_preview === false &&
    !areContractsLoading;

  return (
    <StepCard
      title={t(`${contractTranslationRootKey}.dashboard.contract.title`)}
      footerContent={
        showDocuments && (
          <ContractList contracts={contract ? [contract] : undefined} />
        )
      }
      bodyClassName="px-6"
      footerClassName="px-6"
    >
      {(expectedContractTypes || getContractTypes(subjects)).map(
        (contractType, index) => {
          const subjectsWithContractType = filterSubjectsByContractType(
            subjects,
            contractType
          );

          const groupBySubject = isContractTypeGroupBySubject(contractType);

          const showEmptyAlert = groupBySubject
            ? subjectsWithContractType.length === 0
            : showDocuments === false;

          return (
            <div key={`contract_reviewer_list_${contractType}`}>
              {groupBySubject && (
                <div
                  className={`Contract-Review-Dashboard__Title ${
                    index === 0 ? "mt-1" : "mt-6"
                  } mb-3`}
                >
                  {t(
                    `${getContractTypeTranslationRootKey(
                      contractType
                    )}.dashboard.contract.data`
                  )}
                </div>
              )}

              {subjectsWithContractType.map((subject) => (
                <ContractReviewerList
                  key={`contract_reviewer_list_${contractType}_${subject.subjectId}`}
                  subject={subject}
                  contractGenerationHook={contractGenerationHook}
                  viewContractDisabled={viewContractDisabled}
                  displayDetails={groupBySubject}
                  contractTranslationRootKey={getContractTypeTranslationRootKey(
                    contractType
                  )}
                />
              ))}

              {showEmptyAlert && (
                <Alert variant="info" className="mb-0">
                  <i className="fa-solid fa-circle-info me-2"></i>
                  {t(
                    `${getContractTypeTranslationRootKey(
                      contractType
                    )}.dashboard.contract.empty_info`
                  )}
                </Alert>
              )}
            </div>
          );
        }
      )}

      {showDocuments && (
        <div className="Contract-Review-Dashboard__Step__Subtitle mt-6">
          {t(`${contractTranslationRootKey}.dashboard.contract.documents`)}
        </div>
      )}
    </StepCard>
  );
};

export default ContractReviewDashboard;
