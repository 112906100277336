import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IXchangeProductCreateUpdate } from "@interfaces/products/IXchangeProduct";
import XchangeProductService from "@services/XchangeProductService";

interface IMutationVars {
  xchangeId: number;
  xchangeProduct: IXchangeProductCreateUpdate;
}

export const useXchangeProductUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ xchangeId, xchangeProduct }: IMutationVars) =>
      XchangeProductService.updateXchangeProduct(xchangeId, xchangeProduct),
    {
      onSuccess: (_, { xchangeId }) => {
        queryClient.invalidateQueries([
          "xchanges",
          { id: xchangeId },
          "products",
        ]);
      },
    }
  );
};
