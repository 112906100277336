import { Modify } from "@helpers/typing";
import { ContractStatus } from "@interfaces/IContract";
import ISubDomain from "@interfaces/ISubDomain";
import IPerson from "@interfaces/IPerson";

export enum ProtectedCompaniesCodes {
  Xchange = "xc",
  PXW = "px",
}

export enum LegalEntityType {
  CORPORATION = "corporation",
  PARTNERSHIP = "partnership",
  SOLE_PROPRIETORSHIP = "sole_proprietorship",
  UPWORK = "upwork",
}

export enum CompanyStatuses {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export enum CompanyTypes {
  XPERT = "xpert",
  CLIENT = "client",
}

export enum CompanySizes {
  SELF_EMPLOYED = "0",
  ONE_TO_TEN = "10",
  ELEVEN_TO_FIFTY = "50",
  FIFTY_ONE_TO_TWO_HUNDRED = "200",
  TWO_HUNDRED_ONE_TO_FIVE_HUNDRED = "500",
  FIVE_HUNDRED_ONE_TO_ONE_THOUSAND = "1000",
  ONE_THOUSAND_ONE_TO_FIVE_THOUSAND = "5000",
  FIVE_THOUSAND_ONE_TO_TEN_THOUSAND = "10000",
  TEN_THOUSAND_ONE_AND_MORE = "10001",
}

export interface ICompanyXchangeStats {
  active: number;
  completed: number;
}

export default interface ICompany {
  id?: number;
  company_name?: string;
  company_legal_name?: string;
  legal_entity_type?: LegalEntityType;
  legal_entity_jurisdiction?: string;
  country?: string;
  region?: string;
  city?: string;
  street?: string;
  unit_number?: string;
  postal_code?: string;
  sub_domains?: ISubDomain[];
  company_logo?: string;
  company_phone?: string;
  company_website_url?: string;
  company_description?: string;
  company_size?: CompanySizes;
  company_key_contact?: number;
  company_contacts?: IPerson[];
  connected_by?: string;
  completed_sections?: string[];
  status?: CompanyStatuses;
  types?: string[];
  created_at?: Date;
  updated_at?: Date;
  is_protected?: boolean;
  code?: string;
  has_xero_integration?: boolean;
  xchange_stats?: ICompanyXchangeStats;
  xpert_msa_status?: ContractStatus;
  client_msa_status?: ContractStatus;
  xpert_id?: number;
  client_id?: number;
  onboarding_completed_steps?: string[];
}

export type ICompanyCreateUpdate = Modify<
  ICompany,
  {
    company_key_contact?: number | null;
    sub_domains?: number[];
    company_contacts?: number[];
    company_logo?: string | null;
  }
>;
