import { Image } from "react-bootstrap";
import "./XchangeSplashScreen.scss";

const XchangeSplashScreen = ({
  callback,
  className,
}: {
  callback?: () => void;
  className?: string;
}) => {
  setTimeout(() => {
    callback?.();
  }, 750);

  return (
    <div className={`Xchange-Splash-Screen ${className}`}>
      <div className="Xchange-Splash-Screen__Mask" />
      <Image src="/logo_x.svg" className="Xchange-Splash-Screen__Logo" />
    </div>
  );
};

export default XchangeSplashScreen;
