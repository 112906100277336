import { useEffect, useState } from "react";
import { isEqual } from "lodash";

export const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [isDebouncing, setIsDebouncing] = useState(false);

  useEffect(() => {
    if (!isEqual(value, debouncedValue)) {
      setIsDebouncing(true);
      const handler = setTimeout(() => {
        setDebouncedValue(value);
        setIsDebouncing(false);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [value, delay, debouncedValue]);

  return {
    debouncedValue,
    isDebouncing,
  };
};
