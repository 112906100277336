import IUser, { IUserWrite } from "@interfaces/IUser";
import Service from "@services/Service";

export default class UserService extends Service {
  public static async getUser(id: number) {
    const response = await this.axios.get<IUser>(`/users/${id}`);
    return response.data;
  }

  public static async updateUser(user: IUserWrite) {
    const response = await this.axios.patch<IUserWrite>(
      `/users/${user.id}`,
      user
    );
    return response.data;
  }
}
