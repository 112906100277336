import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import IXchangeTerm from "@interfaces/terms/IXchangeTerm";
import XchangeTermService, {
  IGetXchangeTermsParams,
} from "@services/XchangeTermService";

export const useXchangeTerms = (
  params?: IGetXchangeTermsParams,
  options?: Pick<
    UseQueryOptions<IXchangeTerm[], Error>,
    "enabled" | "onSuccess"
  >
) =>
  useQuery(
    ["xchange-terms", { params }],
    () => XchangeTermService.getXchangeTerms(params),
    options
  );
