import { useMutation, useQueryClient } from "@tanstack/react-query";
import XchangeService from "@services/XchangeService";

interface IMutationVars {
  xchangeId: number;
  epicId: number;
}

export const useEpicDelete = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ["updateXchange"],
    ({ xchangeId, epicId }: IMutationVars) =>
      XchangeService.deleteEpic(xchangeId, epicId),
    {
      onSuccess: (_, { xchangeId }) => {
        queryClient.invalidateQueries(["xchanges", { xchangeId }, "epics"]);
      },
    }
  );
};
