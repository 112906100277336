import React from "react";
import { Modal, Button, Container, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const GenericModal = (props: {
  children: React.ReactNode;
  title: string;
  show: boolean;
  onClose: Function;
  onFinish: Function;
  canBeFinished?: boolean;
  finishText?: string;
  isFinishLoading?: boolean;
  closeText?: string;
  extraButtons?: React.ReactNode;
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={props.show} size="lg" centered enforceFocus={false}>
      <Modal.Header className="text-uppercase">
        {props.title}
        <Button
          variant="outline-light"
          size="sm"
          onClick={() => props.onClose()}
        >
          <i className="fa-regular fa-times" />
        </Button>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>
        <Container className="gx-0">
          <Stack direction="horizontal" gap={3}>
            <Button onClick={() => props.onClose()} variant="outline-light">
              {props.closeText ?? t("wizard.close")}
            </Button>

            <div className="ms-auto">
              {props.extraButtons}
              <Button
                onClick={() => props.onFinish()}
                disabled={
                  props.canBeFinished !== undefined
                    ? !props.canBeFinished
                    : false || props.isFinishLoading
                }
                className="ms-3"
              >
                {props.isFinishLoading ? (
                  <i className="fa-solid fa-spinner-third fa-spin" />
                ) : (
                  props.finishText ?? t("wizard.finish")
                )}
              </Button>
            </div>
          </Stack>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};

export default GenericModal;
