import IXpert from "@interfaces/IXpert";
import { ProtectedContractTypeCodes } from "@interfaces/IContractType";
import useContractGeneration from "@hooks/contract/useContractGeneration";
import ContractDashboard from "@components/ContractDashboard/ContractDashboard";

const CompanyXpertMSA = ({ xpert }: { xpert?: IXpert }) => {
  const contractSubject = {
    subjectId: xpert?.id!,
    contractType: ProtectedContractTypeCodes.XPERT_MSA,
  };
  const contractGenerationHook = useContractGeneration([contractSubject]);

  return xpert ? (
    <ContractDashboard
      contractGenerationHook={contractGenerationHook}
      contractTranslationRootKey="contracts.msa"
    />
  ) : null;
};

export default CompanyXpertMSA;
