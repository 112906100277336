import { useState } from "react";
import ContractReviewerListItem from "./ContractReviewerListItem/ContractReviewerListItem";
import CollapseButton from "@components/CollapseButton/CollapseButton";
import { Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./ContractReviewerList.scss";
import {
  IContractGenerationHook,
  IContractGenerationSubject,
} from "@hooks/contract/useContractGeneration";
import ContractGenerationButtons from "@components/ContractGenerationButtons/ContractGenerationButtons";
import { getSubjectName } from "@helpers/party-utils";
import ContractList from "@components/ContractDashboard/ContractList/ContractList";
import { getSubjectObjectHook } from "@helpers/contract-utils";
import useGetSubjectContract from "@hooks/contract/useGetSubjectContract";
import useGetSubjectTitle from "@hooks/contract/useGetSubjectTitle";
import useGetSubjectParty from "@hooks/contract/useGetSubjectParty";
import useGetSubjectReviewers from "@hooks/contract/useGetSubjectReviewers";
import { ContractStatus } from "@interfaces/IContract";

const ContractReviewerList = ({
  subject,
  contractGenerationHook,
  viewContractDisabled = false,
  displayDetails = false,
  contractTranslationRootKey = "contracts",
}: {
  subject: IContractGenerationSubject;
  contractGenerationHook: IContractGenerationHook;
  viewContractDisabled?: boolean;
  displayDetails?: boolean;
  contractTranslationRootKey?: string;
}) => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const contract = useGetSubjectContract(subject, contractGenerationHook, true);
  const isCollapsable = !!contract && !contract?.last_generation_is_preview;
  const subjectParty = useGetSubjectParty(subject, contractGenerationHook);
  const subjectTitle = useGetSubjectTitle(subject);
  const subjectReviewers = useGetSubjectReviewers(
    subject,
    contractGenerationHook
  );

  const { data: subjectObject } = getSubjectObjectHook(subject)(
    subject.subjectId!,
    {
      enabled: !!subject && displayDetails,
    }
  );

  const showDocuments =
    displayDetails &&
    contract &&
    !contract?.last_generation_is_preview &&
    !isCollapsed;

  return (
    <div
      className={`Contract-Reviewer-List ${
        displayDetails && "Contract-Reviewer-List--subject"
      } ${showDocuments && "Contract-Reviewer-List--with-documents"}`}
    >
      <Stack direction="horizontal" gap={1}>
        <div className="Contract-Reviewer-List__Title">
          {!displayDetails &&
            t(`${contractTranslationRootKey}.dashboard.contract.data`)}
          {displayDetails && subjectObject && getSubjectName(subjectObject)}

          {displayDetails && subjectTitle && (
            <span className="Contract-Reviewer-List__Title__Subtitle ms-1">
              | {subjectTitle}
            </span>
          )}
        </div>

        <div className="Contract-Reviewer-List__Actions ms-auto">
          <ContractGenerationButtons
            subject={subject}
            contractGenerationHook={contractGenerationHook}
            viewContractDisabled={viewContractDisabled}
            contractTranslationRootKey={contractTranslationRootKey}
          />
          {isCollapsable && (
            <CollapseButton
              isCollapsed={isCollapsed}
              onClick={() => setIsCollapsed(!isCollapsed)}
            />
          )}
        </div>
      </Stack>

      {!!contract && !isCollapsed && (
        <div className={`${showDocuments && "mt-4"}`}>
          {subjectReviewers?.map((reviewer) => (
            <ContractReviewerListItem
              key={reviewer.id}
              reviewer={reviewer}
              party={subjectParty}
            />
          ))}

          {contract?.status! >= ContractStatus.SENT_FOR_REVIEW &&
            subjectReviewers?.length === 0 && (
              <div className="small text-muted text-center mt-4">
                {t(
                  `${contractTranslationRootKey}.dashboard.contract.no_reviewer`
                )}
              </div>
            )}
        </div>
      )}

      {showDocuments && (
        <>
          <div className="mt-5 mb-4">
            {t(`${contractTranslationRootKey}.dashboard.contract.documents`)}
          </div>

          <div className="Contract-Reviewer-List__Documents">
            <ContractList contracts={contract ? [contract] : undefined} />
          </div>
        </>
      )}
    </div>
  );
};

export default ContractReviewerList;
