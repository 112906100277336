import ICompany from "@interfaces/ICompany";
import { allCountries } from "country-region-data";

export const getCompanyAddress = (company: ICompany) => {
  return [
    company.street,
    company.unit_number,
    company.city,
    company.region,
    company.country,
    company.postal_code,
  ]
    .filter((item) => !!item)
    .join(", ");
};

export const getCompanyLegalJurisdictionWithCountry = (company: ICompany) => {
  const country = allCountries.find(
    (country) =>
      !!country[2].find(
        (region) => region[0] === company.legal_entity_jurisdiction
      )
  )?.[0];

  return `${company.legal_entity_jurisdiction}${country ? `, ${country}` : ""}`;
};
