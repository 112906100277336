import { useTranslation } from "react-i18next";
import {
  IContractGenerationHook,
  IContractGenerationSubject,
} from "@hooks/contract/useContractGeneration";
import { Alert } from "react-bootstrap";
import ContractSigneeList from "./ContractSigneeList/ContractSigneeList";
import "./ContractSignatureDashboard.scss";
import useGetSubjectContract from "@hooks/contract/useGetSubjectContract";
import useGetSubjectSignatures from "@hooks/contract/useGetSubjectSignatures";
import ContractList from "@components/ContractDashboard/ContractList/ContractList";
import {
  filterSubjectsByContractType,
  getContractTypeTranslationRootKey,
  getContractTypes,
  isContractTypeGroupBySubject,
} from "@helpers/contract-utils";
import { ContractStatus } from "@interfaces/IContract";
import StepCard from "@components/StepCard/StepCard";

const ContractSignatureDashboard = ({
  subjects,
  contractGenerationHook,
  contractTranslationRootKey = "contracts",
}: {
  subjects: IContractGenerationSubject[];
  contractGenerationHook: IContractGenerationHook;
  contractTranslationRootKey?: string;
}) => {
  const { t } = useTranslation();

  const { expectedContractTypes, areContractsLoading } = contractGenerationHook;

  const contract = useGetSubjectContract(
    subjects[0],
    contractGenerationHook,
    true
  );
  const signatures = useGetSubjectSignatures(
    subjects[0],
    contractGenerationHook
  );

  const showDocuments =
    subjects &&
    subjects.length === 1 &&
    contract &&
    contract.status === ContractStatus.SIGNED &&
    contract.signed_document_id &&
    !areContractsLoading;

  return (
    <StepCard
      title={t(`${contractTranslationRootKey}.dashboard.signnow.title`)}
      footerContent={
        showDocuments && (
          <ContractList
            contracts={contract ? [contract] : undefined}
            showSignedVersion={true}
          />
        )
      }
      bodyClassName="px-6"
      footerClassName="px-6"
    >
      {(expectedContractTypes || getContractTypes(subjects)).map(
        (contractType, index) => {
          const subjectsWithContractType = filterSubjectsByContractType(
            subjects,
            contractType
          );

          const groupBySubject = isContractTypeGroupBySubject(contractType);

          const showEmptyAlert =
            ((!signatures || signatures.length === 0) && !groupBySubject) ||
            subjectsWithContractType.length === 0;

          return (
            <div key={`contract_signature_list_${contractType}`}>
              {groupBySubject && (
                <div
                  className={`Contract-Signee-Dashboard__Title ${
                    index === 0 ? "mt-1" : "mt-6"
                  } mb-3`}
                >
                  {t(
                    `${getContractTypeTranslationRootKey(
                      contractType
                    )}.dashboard.signnow.data`
                  )}
                </div>
              )}

              {subjectsWithContractType.map((subject) => (
                <ContractSigneeList
                  key={`contract_signature_list_${contractType}_${subject.subjectId}`}
                  subject={subject}
                  contractGenerationHook={contractGenerationHook}
                  displayDetails={groupBySubject}
                  contractTranslationRootKey={getContractTypeTranslationRootKey(
                    contractType
                  )}
                />
              ))}

              {showEmptyAlert && (
                <Alert variant="info" className="mb-0">
                  <i className="fa-solid fa-circle-info me-2"></i>
                  {t(
                    `${getContractTypeTranslationRootKey(
                      contractType
                    )}.dashboard.signnow.empty_info`
                  )}
                </Alert>
              )}
            </div>
          );
        }
      )}

      {showDocuments && (
        <div className="mt-6">
          {t(`${contractTranslationRootKey}.dashboard.signnow.documents`)}
        </div>
      )}
    </StepCard>
  );
};

export default ContractSignatureDashboard;
