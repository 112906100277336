import { joiResolver } from "@hookform/resolvers/joi";
import { isEqual } from "lodash";
import { useState } from "react";
import { Form, Stack } from "react-bootstrap";
import { FieldError, FormProvider, set, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CustomSelect from "@components/CustomSelect/CustomSelect";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import FileDropInput from "@components/FileDropInput/FileDropInput";
import { useCompanyCreate } from "@hooks/company/useCompanyCreate";
import { useCompanyLogoUpdate } from "@hooks/company/useCompanyLogoUpdate";
import { useCompanyUpdate } from "@hooks/company/useCompanyUpdate";
import { useSubDomains } from "@hooks/subdomain/useSubDomains";
import ICompany, {
  CompanyStatuses,
  LegalEntityType,
} from "@interfaces/ICompany";
import {
  CompanyDetailsFormValues,
  companyDetailsValidationSchema,
} from "@pages/Company/CompanyEditPage/CompanyEditConfig";
import { debounce } from "lodash";
import InfoCard from "@components/InfoCard/InfoCard";
import InfoCardSection from "@components/InfoCard/InfoCardSection/InfoCardSection";
import CompanyLocationForm from "@pages/Company/CompanyEditPage/CompanyGeneralOverview/CompanyDetailsForm/CompanyLocationForm/CompanyLocationForm";
import { useIsFetching } from "@tanstack/react-query";

const CompanyDetailsForm = ({ company }: { company: ICompany | undefined }) => {
  const formMethods = useForm<CompanyDetailsFormValues>({
    mode: "onChange",
    resolver: joiResolver(companyDetailsValidationSchema),
    context: {
      id: company?.id,
      default_company_name: company?.company_name,
      default_company_legal_name: company?.company_legal_name,
    },
    defaultValues: {
      legal_entity_type: company?.legal_entity_type,
      company_name: company?.company_name || "",
      company_legal_name: company?.company_legal_name || "",
      sub_domains: (company?.sub_domains ?? []).map((sd) => sd.id) || [],
      status: company?.status,
    },
  });
  const {
    control,
    register,
    getValues,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = formMethods;

  const debouncedTrigger = debounce((field: keyof CompanyDetailsFormValues) => {
    trigger(field);
  }, 500);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: subDomains } = useSubDomains();
  const updateCompanyMutation = useCompanyUpdate();
  const createCompanyMutation = useCompanyCreate();

  const [isLogoFileTooBig, setIsLogoFileTooBig] = useState<boolean>(false);
  const [pastOnBlurData, setPastOnBlurData] =
    useState<CompanyDetailsFormValues>(getValues());

  const formSubmit = (data: CompanyDetailsFormValues) => {
    if (!company) {
      createCompanyMutation.mutate(
        {
          company_name: data.company_name,
        },
        {
          onSuccess: (newCompany) => {
            navigate(`/companies/${newCompany.id}/overview`, {
              replace: true,
            });
          },
        }
      );
    } else {
      const changedValues = Object.keys(data).reduce((acc, key) => {
        if (
          !isEqual(
            data[key as keyof CompanyDetailsFormValues],
            pastOnBlurData[key as keyof CompanyDetailsFormValues]
          )
        ) {
          set(acc, key, data[key as keyof CompanyDetailsFormValues]);
        }
        return acc;
      }, {} as CompanyDetailsFormValues);

      if (Object.keys(changedValues).length > 0) {
        updateCompanyMutation.mutate({
          updatedCompany: {
            id: company?.id!,
            ...changedValues,
          },
        });
      }
    }

    setPastOnBlurData(data);
  };

  const updateCompanyLogoMutation = useCompanyLogoUpdate();
  const handleImageChange = (image: File) => {
    if (image?.size > 200000) {
      setIsLogoFileTooBig(true);
      return;
    }
    setIsLogoFileTooBig(false);
    if (image) {
      updateCompanyLogoMutation.mutate({
        companyId: company?.id!,
        companyLogo: image,
      });
    }
  };

  const isCompanyLoading =
    useIsFetching({ queryKey: ["companies", { id: company?.id }] }) > 0;
  const removeLogo = () => {
    updateCompanyMutation.mutate({
      updatedCompany: {
        id: company?.id!,
        company_logo: null,
      },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <Stack gap={4} className="pe-4 pb-5 ps-4">
        <InfoCard id="company-general">
          <InfoCardSection
            direction="row"
            title={t(
              "companies.details.overview.general_details.labels.company_logo"
            )}
          >
            <FileDropInput
              maxFiles={1}
              onChange={(files) => {
                handleImageChange(files[0]);
              }}
              variant="image"
              imageSrc={company?.company_logo}
              disabled={!company}
              removeBtnLabel={t(
                "companies.details.overview.general_details.remove_logo"
              )}
              removeBtnDisabled={
                !company ||
                !company?.company_logo ||
                updateCompanyMutation.isLoading ||
                isCompanyLoading
              }
              removeBtnOnClick={removeLogo}
            />
            <FieldErrorMessage
              field={
                isLogoFileTooBig
                  ? ({ type: "custom.logo_file_too_big" } as FieldError)
                  : undefined
              }
              fieldName={t(
                "companies.details.overview.general_details.labels.company_logo"
              )}
            />
          </InfoCardSection>

          <InfoCardSection
            direction="row"
            title={t(
              "companies.details.overview.general_details.labels.company_legal_name"
            )}
          >
            <Form.Control
              type="text"
              {...register("company_legal_name")}
              isInvalid={!!errors?.company_legal_name}
              disabled={!company}
              onChange={(e) => {
                setValue("company_legal_name", e.target.value);
                debouncedTrigger("company_legal_name");
              }}
              onBlur={handleSubmit(formSubmit)}
            />
            <FieldErrorMessage
              field={errors.company_legal_name}
              fieldName={t(
                "companies.details.overview.general_details.labels.company_legal_name"
              )}
            />
          </InfoCardSection>

          <InfoCardSection
            direction="row"
            title={t(
              "companies.details.overview.general_details.labels.company_name"
            )}
          >
            <Form.Control
              type="text"
              {...register("company_name")}
              isInvalid={!!errors.company_name}
              onChange={(e) => {
                setValue("company_name", e.target.value);
                debouncedTrigger("company_name");
              }}
              onBlur={handleSubmit(formSubmit)}
            />
            <FieldErrorMessage
              field={errors.company_name}
              fieldName={t(
                "companies.details.overview.general_details.labels.company_name"
              )}
            />
          </InfoCardSection>

          <InfoCardSection
            direction="row"
            title={t(
              "companies.details.overview.general_details.labels.legal_entity_type"
            )}
          >
            <CustomSelect
              control={control}
              name="legal_entity_type"
              options={Object.values(LegalEntityType).map((type) => ({
                value: type,
                label: t(`companies.legal_entity_types.${type}`) as string,
              }))}
              onAfterChange={() => {
                handleSubmit(formSubmit)();
              }}
              isDisabled={!company}
              isClearable
            />
            <FieldErrorMessage
              field={errors.legal_entity_type}
              fieldName={t(
                "companies.details.overview.general_details.labels.legal_entity_type"
              )}
            />
          </InfoCardSection>

          <InfoCardSection
            direction="row"
            title={t("companies.overview.status")}
          >
            <CustomSelect
              name="status"
              control={control}
              options={Object.values(CompanyStatuses).map((status) => ({
                value: status,
                label: t(`companies.statuses.${status}`),
              }))}
              onAfterChange={() => {
                handleSubmit(formSubmit)();
              }}
              isDisabled={!company}
            />
            <FieldErrorMessage
              field={errors.status}
              fieldName={t("companies.overview.status")}
            />
          </InfoCardSection>
        </InfoCard>

        <InfoCard
          title={t(
            "companies.details.overview.general_details.labels.sub_domains"
          )}
        >
          <InfoCardSection
            direction="row"
            title={t(
              "companies.details.overview.general_details.labels.sub_domains"
            )}
          >
            <CustomSelect
              name="sub_domains"
              control={control}
              isClearable={false}
              options={
                subDomains?.map((sd) => ({
                  value: sd.id,
                  label: sd.name,
                })) ?? []
              }
              onAfterChange={() => {
                handleSubmit(formSubmit)();
              }}
              isMulti={true}
              isDisabled={!company}
            />
            <FieldErrorMessage
              field={errors.sub_domains}
              fieldName={t(
                "companies.details.overview.general_details.labels.sub_domains"
              )}
            />
          </InfoCardSection>
        </InfoCard>

        <CompanyLocationForm company={company} />
      </Stack>
    </FormProvider>
  );
};

export default CompanyDetailsForm;
