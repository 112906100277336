import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import SubDomainService, {
  IGetSubDomainsParams,
} from "@services/SubDomainService";
import ISubDomain from "@interfaces/ISubDomain";

export const useSubDomains = (
  params?: IGetSubDomainsParams,
  options?: Pick<
    UseQueryOptions<ISubDomain[], Error>,
    "enabled" | "onSuccess"
  > & {
    refetchOnWindowFocus?: boolean;
  }
) =>
  useQuery(
    ["sub-domains", { params }],
    () => SubDomainService.getSubDomains(params),
    options
  );
