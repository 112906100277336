import "./CardPlaceholder.scss";

const CardPlaceholder = ({
  iconClassName,
  title,
  description,
  className,
}: {
  iconClassName: string;
  title: string;
  description?: string;
  className?: string;
}) => {
  return (
    <div
      className={`Card-Placeholder d-flex flex-column justify-content-center align-items-center text-center pt-9 pb-9 ps-7 pe-7 ${className}`}
    >
      <div className="Card-Placeholder__Circle mb-5">
        <i className={iconClassName} />
      </div>
      <div className="Card-Placeholder__Title mb-2">{title}</div>
      <div className="Card-Placeholder__Description">{description}</div>
    </div>
  );
};

export default CardPlaceholder;
