import React, { useEffect, useState } from "react";
import { Button, Card, Form, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./StepCard.scss";

const StepCard = ({
  id,
  title,
  titleMuted,
  subtitle,
  children,
  isCompletionStatusDisabled = false,
  defaultMarkedAsCompleted,
  onCompletionStatusChange,
  isCollapsible = true,
  footerContent,
  bodyClassName,
  footerClassName,

  readView,
  writeView,
  defaultView,
  onViewChange,
  disableViewChange,
}: {
  id?: string;
  title: string;
  titleMuted?: string;
  subtitle?: string;
  children?: any;
  isCompletionStatusDisabled?: boolean;
  defaultMarkedAsCompleted?: boolean;
  onCompletionStatusChange?: (isDone: boolean) => void;
  isCollapsible?: boolean;
  footerContent?: React.ReactNode;
  bodyClassName?: string;
  footerClassName?: string;

  readView?: React.ReactNode;
  writeView?: React.ReactNode;
  defaultView?: "read" | "write";
  onViewChange?: (view: "read" | "write") => void;
  disableViewChange?: boolean;
}) => {
  const { t } = useTranslation();

  const [currentView, setCurrentView] = useState<"read" | "write" | null>(
    defaultView || null
  );

  useEffect(() => {
    setCurrentView(defaultView || null);
  }, [defaultView]);

  const handleViewChange = (view: "read" | "write") => {
    setCurrentView(view);
    onViewChange?.(view);
  };

  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [markedAsCompleted, setMarkedAsCompleted] = useState(
    defaultMarkedAsCompleted
  );

  return (
    <Card id={id} className="Step-Card mb-6">
      <Card.Header>
        <Stack direction="horizontal" gap={3}>
          <div className="Step-Card__Label">
            {subtitle && (
              <div className="Step-Card__Label__Subtitle mb-2">{subtitle}</div>
            )}
            <div className="Step-Card__Label__Title">
              {title}
              <span className="text-muted ms-2">{titleMuted}</span>
            </div>
          </div>

          <Stack direction="horizontal" gap={3} className="ms-auto">
            {currentView === "read" && (
              <Button
                variant="outline-light"
                onClick={() => {
                  handleViewChange("write");
                }}
                disabled={disableViewChange}
              >
                <i className="fa-solid fa-pen me-2" />
                {t("actions.edit")}
              </Button>
            )}

            {currentView === "write" && (
              <Button
                onClick={() => {
                  handleViewChange("read");
                }}
                disabled={disableViewChange}
              >
                <i className="fa-regular fa-check me-2" />
                {t("actions.done")}
              </Button>
            )}

            {markedAsCompleted !== undefined && (
              <div className="Step-Card__Label__Checkbox pt-2 pb-2 ps-3 pe-3">
                <Form.Check
                  type="checkbox"
                  checked={markedAsCompleted}
                  onChange={(e) => {
                    setMarkedAsCompleted(e.target.checked);
                    onCompletionStatusChange?.(e.target.checked);
                  }}
                  label={t("form.header.mark_complete")}
                  disabled={isCompletionStatusDisabled}
                />
              </div>
            )}
            {isCollapsible && (
              <Button
                variant="outline-light"
                onClick={() => setIsCollapsed(!isCollapsed)}
              >
                {isCollapsed ? (
                  <i className="fa-regular fa-chevron-down" />
                ) : (
                  <i className="fa-regular fa-chevron-up" />
                )}
              </Button>
            )}
          </Stack>
        </Stack>
      </Card.Header>
      {!isCollapsed && (
        <Card.Body className={bodyClassName}>
          {currentView === "read" && readView}
          {currentView === "write" && writeView}
          {currentView === null && children}
        </Card.Body>
      )}
      {footerContent && !isCollapsed && (
        <Card.Footer className={`Step-Card__Footer ${footerClassName}`}>
          {footerContent}
        </Card.Footer>
      )}
    </Card>
  );
};

export default StepCard;
