import { Spinner, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CardPlaceholder from "@components/CardPlaceholder/CardPlaceholder";
import InfoTooltip from "@components/InfoTooltip/InfoTooltip";
import { useXpertTermDelete } from "@hooks/term/useXpertTermDelete";
import { useXpertTerms } from "@hooks/term/useXpertTerms";
import IXpertTerm from "@interfaces/terms/IXpertTerm";
import TermTableRow from "@pages/Company/CompanyEditPage/CompanyXpertDetails/XpertTerms/TermTableRow";
import "./TermTable.scss";

const TermTable = ({
  xpertId,
  onEdit,
}: {
  xpertId: number;
  onEdit?: (xpertTerm: IXpertTerm) => void;
}) => {
  const { t } = useTranslation();

  const { data: xpertTerms, isLoading: areXpertTermsLoading } = useXpertTerms({
    xpert_involved: xpertId,
  });

  const deleteXpertTermMutation = useXpertTermDelete();
  const deleteXpertTerm = (xpertTerm: IXpertTerm) => {
    deleteXpertTermMutation.mutate({
      xpertId: xpertId,
      xpertTermId: xpertTerm.id!,
    });
  };

  return areXpertTermsLoading ? (
    <div className="d-flex justify-content-center mb-4">
      <Spinner animation="border" variant="primary" />
    </div>
  ) : (xpertTerms ?? []).length === 0 ? (
    <CardPlaceholder
      iconClassName={"fa-solid fa-rectangle-vertical-history"}
      title={t("companies.details.xpert.terms.empty")}
      description={t("companies.details.xpert.terms.empty_desc")}
    />
  ) : (
    <Table className="Term-Table">
      <thead>
        <tr>
          <th className="align-top">
            {t("companies.details.xpert.terms.fields.term")}
          </th>
          <th>{t("companies.details.xpert.terms.fields.sub_domain")}</th>
          <th>
            {t("companies.details.xpert.terms.fields.cost")}
            <InfoTooltip text={t("companies.details.xpert.terms.info.cost")} />
          </th>
          <th>
            {t("companies.details.xpert.terms.fields.duration")}
            <InfoTooltip
              text={t("companies.details.xpert.terms.info.duration")}
            />
          </th>
          <th>
            {t("companies.details.xpert.terms.fields.stages")}
            <InfoTooltip
              text={t("companies.details.xpert.terms.info.stages")}
            />
          </th>
          <th className="table-actions"></th>
        </tr>
      </thead>
      <tbody>
        {xpertTerms?.map((xpertTerm: IXpertTerm) => (
          <TermTableRow
            key={xpertTerm.id}
            xpertTerm={xpertTerm}
            onEdit={onEdit}
            onDelete={deleteXpertTerm}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default TermTable;
