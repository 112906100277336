import { Badge, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { usePartyPersonTitles } from "@hooks/party/usePartyPersonTitles";
import { formatDateToString } from "@helpers/date-utils";
import IParty from "@interfaces/IParty";
import ISignNowSignature from "@interfaces/ISignNowSignature";
import ISignatory from "@interfaces/ISignatory";
import PersonCard from "@components/PersonCard/PersonCard";
import "./ContractSigneeListItem.scss";
import SignatureStatusBadge from "@components/SignatureStatusBadge/SignatureStatusBadge";
import { useResendSignNowSignature } from "@hooks/signature/useResendSignNowSignature";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ContractSigneeListItem = ({
  signatory,
  party,
  signature,
  contractTranslationRootKey = "contracts",
}: {
  signatory: ISignatory;
  party?: IParty;
  signature?: ISignNowSignature;
  contractTranslationRootKey?: string;
}) => {
  const { t } = useTranslation();
  const { getPersonTitles } = usePartyPersonTitles(party, true);

  const latestStatusChangeDate =
    signature &&
    (signature.completion_date ||
      signature.seen_date ||
      signature.invitation_date)
      ? new Date(
          (signature.completion_date ??
            signature.seen_date ??
            signature.invitation_date)!
        )
      : undefined;

  const resentSignNowSignatureMutation = useResendSignNowSignature();

  const [wasInviteResent, setWasInviteResent] = useState<boolean>(false);

  const resentSignatureInvite = (signature: ISignNowSignature) => {
    if (!wasInviteResent) {
      resentSignNowSignatureMutation.mutate(signature, {
        onSuccess: () => {
          setWasInviteResent(true);

          setTimeout(() => {
            setWasInviteResent(false);
          }, 5000);
        },
      });
    }
  };

  return (
    <PersonCard
      personId={signatory.person! as number}
      title={getPersonTitles(signatory.person! as number)}
      isDisabled={true}
    >
      {signature && latestStatusChangeDate && (
        <>
          <OverlayTrigger
            overlay={
              <Tooltip>{formatDateToString(latestStatusChangeDate)}</Tooltip>
            }
          >
            <Badge
              pill
              bg="light"
              text="info"
              className="Contract-Signee-List-Item__Badge--date me-2 border"
            >
              {formatDateToString(latestStatusChangeDate, {
                dateStyle: "medium",
              })}
            </Badge>
          </OverlayTrigger>

          <SignatureStatusBadge signature={signature} />

          {!signature.is_completed && (
            <OverlayTrigger
              overlay={
                <Tooltip>
                  {t(
                    `${contractTranslationRootKey}.dashboard.signnow.resend_invite`
                  )}
                </Tooltip>
              }
            >
              <Button
                className="ms-2"
                variant="outline-light"
                size="sm"
                onClick={() => {
                  resentSignatureInvite(signature);
                }}
                disabled={resentSignNowSignatureMutation.isLoading}
              >
                {wasInviteResent ? (
                  <i
                    className="fa-solid fa-check fa-beat text-success-dark"
                    style={
                      {
                        "--fa-animation-iteration-count": 1,
                      } as React.CSSProperties
                    }
                  />
                ) : (
                  <i
                    className={`fa-solid fa-arrows-rotate ${
                      resentSignNowSignatureMutation.isLoading && "fa-spin"
                    }`}
                  />
                )}
              </Button>
            </OverlayTrigger>
          )}
        </>
      )}
    </PersonCard>
  );
};

export default ContractSigneeListItem;
