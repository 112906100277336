import IEpic from "@interfaces/IEpic";
import IXchange, { IXchangeCreateUpdate } from "@interfaces/IXchange";
import Service from "@services/Service";
import {
  IXchangeTeamMember,
  IXchangeTeamMemberCreateUpdate,
} from "@interfaces/IXchangeTeamMember";
import IPage, { IPageParams } from "@interfaces/IPage";

export interface IGetXchangesParams {
  search?: string;
  owner?: number;
  client?: number;
  status?: string;
  type?: string;
  start_date__gte?: string;
  start_date__lte?: string;
  goal_end_date__gte?: string;
  goal_end_date__lte?: string;
  occurs_during_start?: string;
  occurs_during_end?: string;
  ordering?: string;
  xchange_terms__xpert_involved?: number;
  xchange_products__terms__xpert_involved?: number;
  company?: number;
}

export interface IGetXchangeBillsSummary {
  xpert: number;
  total_spend: number;
  name: string;
  currency_code: string;
}

export default class XchangeService extends Service {
  public static async getXchanges(
    params?: IGetXchangesParams & IPageParams,
    signal?: AbortSignal
  ) {
    const response = await this.axios.get<IPage<IXchange>>("/xchanges", {
      params,
      signal,
    });
    return response.data;
  }

  public static async getXchange(id: number) {
    const response = await this.axios.get<IXchange>(`/xchanges/${id}`);
    return response.data;
  }

  public static async createXchange(xchange: IXchangeCreateUpdate) {
    const response = await this.axios.post<IXchange>("/xchanges", xchange);
    return response.data;
  }

  public static async updateXchange(xchange: IXchangeCreateUpdate) {
    const response = await this.axios.patch<IXchange>(
      `/xchanges/${xchange.id}`,
      xchange
    );
    return response.data;
  }

  public static async getEpics(xchangeId: number) {
    const response = await this.axios.get<IEpic[]>(
      `/xchanges/${xchangeId}/epics`
    );
    return response.data;
  }

  public static async createEpic(xchangeId: number, epic: IEpic) {
    const response = await this.axios.post<IEpic>(
      `/xchanges/${xchangeId}/epics`,
      epic
    );
    return response.data;
  }

  public static async updateEpic(xchangeId: number, epic: IEpic) {
    const response = await this.axios.patch<IEpic>(
      `/xchanges/${xchangeId}/epics/${epic.id}`,
      epic
    );
    return response.data;
  }

  public static async deleteEpic(xchangeId: number, epicId: number) {
    const response = await this.axios.delete<IEpic>(
      `/xchanges/${xchangeId}/epics/${epicId}`
    );
    return response.data;
  }

  public static async getXchangeBillsSummary(xchangeId: number) {
    const response = await this.axios.get<IGetXchangeBillsSummary[]>(
      `/xchanges/${xchangeId}/bills/summary`
    );
    return response.data;
  }

  public static async getXchangeTeamMembers(
    xchangeId: number,
    params?: IPageParams
  ) {
    const response = await this.axios.get<IPage<IXchangeTeamMember>>(
      `/xchanges/${xchangeId}/team-members`,
      {
        params,
      }
    );
    return response.data;
  }

  public static async addXchangeTeamMember(
    teamMember: IXchangeTeamMemberCreateUpdate
  ) {
    const response = await this.axios.post<IXchangeTeamMember>(
      `/xchanges/${teamMember.xchange}/team-members`,
      teamMember
    );
    return response.data;
  }

  public static async updateXchangeTeamMember(
    teamMember: IXchangeTeamMemberCreateUpdate
  ) {
    const response = await this.axios.patch<IXchangeTeamMember>(
      `/xchanges/${teamMember.xchange}/team-members/${teamMember.id}`,
      teamMember
    );
    return response.data;
  }

  public static async deleteXchangeTeamMember(teamMember: IXchangeTeamMember) {
    const response = await this.axios.delete<{}>(
      `/xchanges/${teamMember.xchange}/team-members/${teamMember.id}`
    );
    return response.data;
  }
}
