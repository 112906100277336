import { Form, Stack } from "react-bootstrap";
import CustomSelect, {
  ICustomSelectOption,
} from "@components/CustomSelect/CustomSelect";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "./BillFilters.scss";
import SearchFilter from "@components/SearchFilter/SearchFilter";
import { useMemo, useState } from "react";
import { IGetXpertsParams } from "@services/XpertService";
import IXchange from "@interfaces/IXchange";
import { useDebounce } from "@hooks/useDebounce";
import { useXperts } from "@hooks/xpert/useXperts";
import { XeroInvoiceStatus } from "@interfaces/IXeroInvoice";
import { doesXchangeHaveXperts, getXchangeTerms } from "@helpers/xchange-utils";
import CustomRangeDatePicker from "@components/CustomRangeDatePicker/CustomRangeDatePicker";
import { IGetBillsParams } from "@services/BillService";

interface IBillFilters {
  filters: any[];
  ordering: "xpert__name" | "-xpert__name" | "bill_status" | "-bill_status";
  search: string;
  dateType: string;
  startDate: Date | null;
  endDate: Date | null;
  range: Date[];
}

const BillFilters = ({
  xchange,
  handleFilterChange,
  className,
  disabled = false,
  availableStatuses = [
    XeroInvoiceStatus.DRAFT,
    XeroInvoiceStatus.SUBMITTED,
    XeroInvoiceStatus.DELETED,
    XeroInvoiceStatus.AUTHORISED,
    XeroInvoiceStatus.PAID,
    XeroInvoiceStatus.VOIDED,
  ],
}: {
  xchange?: IXchange;
  handleFilterChange: (data: IGetBillsParams) => void;
  className?: string;
  disabled?: boolean;
  availableStatuses?: XeroInvoiceStatus[];
}) => {
  const { t } = useTranslation();

  const formMethods = useForm<IBillFilters>({
    defaultValues: {
      dateType: "billing_period_start",
    },
  });
  const { control, handleSubmit } = formMethods;

  const fixedGetXpertsParams = {
    id__in:
      xchange &&
      getXchangeTerms(xchange)
        ?.map((xchange_term) => xchange_term.xpert_involved)
        .join(","),
  };

  const [getXpertsParams, setGetXpertsParams] = useState<IGetXpertsParams>({});
  const { debouncedValue: debouncedGetXpertsParams } = useDebounce(
    getXpertsParams,
    400
  );
  const { isLoading: areXpertsLoading, data: xperts } = useXperts(
    {
      ...fixedGetXpertsParams,
      ...debouncedGetXpertsParams,
    },
    {
      enabled: !!xchange && doesXchangeHaveXperts(xchange),
    }
  );

  const filterOptions = (() => {
    const options: ICustomSelectOption | ICustomSelectOption[] = [
      {
        label: t("billing.xero.bill.status"),
        value: availableStatuses.map((status) => ({
          label: t(`billing.xero.bill.statuses.${status}`),
          value: { status: status },
        })),
      },
    ];

    if (!!xchange) {
      options.unshift({
        label: t("billing.history.filters.xpert"),
        value: (xperts || []).map((xpert) => ({
          label: xpert.name!,
          value: { xpert: xpert.id! },
        })),
        defaultSearchValue: getXpertsParams.search,
        onSearch: (search: string) => {
          if (search.length > 0) {
            setGetXpertsParams({
              ...getXpertsParams,
              search,
            });
          } else {
            setGetXpertsParams({
              ...getXpertsParams,
              search: undefined,
            });
          }
        },
        isSearchLoading: areXpertsLoading && doesXchangeHaveXperts(xchange),
      });
    }

    return options;
  })();

  const orderingOptions = useMemo(() => {
    const options = [
      {
        label: t("billing.history.ordering.bill_status.name"),
        value: [
          {
            label: t("billing.history.ordering.bill_status.asc"),
            value: "bill_status",
          },
          {
            label: t("billing.history.ordering.bill_status.desc"),
            value: "-bill_status",
          },
        ],
      },
    ];

    if (!!xchange) {
      options.unshift({
        label: t("billing.history.ordering.xpert.name"),
        value: [
          {
            label: t("billing.history.ordering.xpert.asc"),
            value: "xpert__name",
          },
          {
            label: t("billing.history.ordering.xpert.desc"),
            value: "-xpert__name",
          },
        ],
      });
    }

    return options;
  }, [t, xchange]);

  const handleFormChange = (data: IBillFilters) => {
    const filters: IGetBillsParams = {
      ordering: data.ordering,
      search: data.search,
    };

    if (data.filters) {
      filters.xpert__in = data.filters
        .filter((filter) => filter.xpert)
        .map((filter) => filter.xpert)
        .join(",");

      filters.status__in = data.filters
        .filter((filter) => filter.status)
        .map((filter) => filter.status)
        .join(",");
    }

    switch (data.dateType) {
      case "billing_period_start":
        if (data.startDate) {
          filters.billing_period_start__gte = data.startDate.toISOString();
        }
        if (data.endDate) {
          filters.billing_period_start__lte = data.endDate.toISOString();
        }
        break;
      case "billing_period_end":
        if (data.startDate) {
          filters.billing_period_end__gte = data.startDate.toISOString();
        }
        if (data.endDate) {
          filters.billing_period_end__lte = data.endDate.toISOString();
        }
        break;
      case "billing_period":
        if (data.startDate) {
          filters.billing_period__occurs_during_start =
            data.startDate.toISOString();
        }
        if (data.endDate) {
          filters.billing_period__occurs_during_end =
            data.endDate.toISOString();
        }
    }

    handleFilterChange(filters);
  };

  return (
    <FormProvider {...formMethods}>
      <Form onChange={handleSubmit(handleFormChange)}>
        <Stack
          direction="horizontal"
          className={`Bill-Filters ${className}`}
          gap={4}
        >
          <CustomSelect
            control={control}
            name="filters"
            options={filterOptions}
            onAfterChange={() => {
              handleSubmit(handleFormChange)();
            }}
            placeholder={t("billing.history.filters.placeholder")}
            isMulti
            isDisabled={disabled}
          />
          <CustomSelect
            control={control}
            name="ordering"
            options={orderingOptions}
            onAfterChange={() => {
              handleSubmit(handleFormChange)();
            }}
            placeholder={t("billing.history.ordering.placeholder")}
            isClearable
            isDisabled={disabled}
          />
          <CustomRangeDatePicker
            startDateName={"startDate"}
            endDateName={"endDate"}
            typeName={"dateType"}
            className="d-flex justify-content-between"
            typeOptions={[
              {
                label: t("filters.begins_on"),
                value: "billing_period_start",
              },
              {
                label: t("filters.occurs_during"),
                value: "billing_period",
              },
              {
                label: t("filters.ends_on"),
                value: "billing_period_end",
              },
            ]}
            onAfterChange={() => {
              handleSubmit(handleFormChange)();
            }}
            placeholder={t("billing.history.billing_period")}
            disabled={disabled}
          />
          <SearchFilter
            name="search"
            placeholder={t("filters.search")}
            disabled={disabled}
          />
        </Stack>
      </Form>
    </FormProvider>
  );
};

export default BillFilters;
