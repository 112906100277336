import React from "react";
import {
  Button,
  Form,
  FormControlProps,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import classNames from "classnames";
import "./InfoInput.scss";

type Props = {
  className?: string;
  infoText?: string;
} & FormControlProps;

const InfoInput = React.forwardRef(
  ({ className, infoText, ...props }: Props, ref) => {
    return (
      <div className={`Info-Input d-flex ${className ?? ""}`}>
        <Form.Control
          {...props}
          ref={ref}
          className={classNames(className, "Info-Input__Input")}
        />
        <OverlayTrigger
          overlay={infoText ? <Tooltip>{infoText}</Tooltip> : <></>}
        >
          <Button
            className={`Info-Input__Button text-center p-0 ${
              props.isInvalid && "invalid"
            }`}
          >
            {props.isInvalid ? (
              <i className="fa-regular fa-circle-xmark"></i>
            ) : (
              <i className="fa-regular fa-circle-info"></i>
            )}
          </Button>
        </OverlayTrigger>
      </div>
    );
  }
);

export default InfoInput;
