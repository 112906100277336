import ISubDomain from "@interfaces/ISubDomain";
import Service from "@services/Service";

export interface IGetSubDomainsParams {
  id__in?: string;
  domain__in?: string;
  search?: string;
}

export default class SubDomainService extends Service {
  public static async getSubDomains(params?: IGetSubDomainsParams) {
    const response = await this.axios.get<ISubDomain[]>("/sub-domains", {
      params,
    });
    return response.data;
  }
}
