import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import ISignatory from "@interfaces/ISignatory";
import SignatoryService, {
  IGetSignatoriesParams,
} from "@services/SignatoryService";

export const useSignatories = (
  params?: IGetSignatoriesParams,
  options?: Pick<
    UseQueryOptions<ISignatory[], Error>,
    "enabled" | "onSuccess" | "staleTime"
  >
) =>
  useQuery(
    ["signatories", { params }],
    () => SignatoryService.getSignatories(params),
    options
  );
