import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDocumentDelete } from "@hooks/document/useDocumentDelete";
import { useDocumentType } from "@hooks/document/useDocumentType";
import IDocument from "@interfaces/IDocument";
import UploadDocumentModal from "@components/Documents/UploadDocumentModal/UploadDocumentModal";
import DocumentCard from "@components/DocumentCard/DocumentCard";

const DocumentListItem = ({ document }: { document: IDocument }) => {
  const { t } = useTranslation();

  const { data: documentType } = useDocumentType(document.type!);

  const deleteDocumentMutation = useDocumentDelete();
  const deleteDocument = () => {
    deleteDocumentMutation.mutate(document.id!);
  };

  const [showEditModal, setShowEditModal] = useState(false);

  return (
    <>
      <DocumentCard
        name={document.name!}
        url={document.url!}
        type={documentType?.name}
        actions={[
          {
            label: t("actions.open"),
            onClick: () => {
              window.open(document.url, "_blank");
            },
          },
          {
            label: t("actions.edit"),
            onClick: () => {
              setShowEditModal(true);
            },
          },
          {
            label: t("actions.delete"),
            onClick: () => {
              deleteDocument();
            },
          },
        ]}
      />

      {showEditModal && (
        <UploadDocumentModal
          document={document}
          show={true}
          onClose={() => setShowEditModal(false)}
        />
      )}
    </>
  );
};

export default DocumentListItem;
