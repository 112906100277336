import {
  IContractGenerationHook,
  IContractGenerationSubject,
} from "./useContractGeneration";

const useGetSubjectContract = (
  subject: IContractGenerationSubject,
  contractGenerationHook: IContractGenerationHook,
  onlyNonPreview: boolean = false
) => {
  const { contracts } = contractGenerationHook;

  const subjectContract = contracts?.find(
    (contract) =>
      contract.subject_id === subject.subjectId &&
      contract.contract_type__code === subject.contractType &&
      contract.last_generation_is_preview === !onlyNonPreview
  );

  return subjectContract;
};

export default useGetSubjectContract;
