import IProduct from "@interfaces/products/IProduct";

export const hasNullCostRanges = (product: IProduct) => {
  return (
    !!product?.cost_ranges &&
    product.cost_ranges.every(
      (costRange) => costRange.min === null && costRange.max === null
    )
  );
};
