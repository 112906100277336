import { useTranslation } from "react-i18next";
import "./Overview.scss";
import { Link } from "react-router-dom";

export const Overview = ({
  goBackText,
  goBackUri,
  children,
  className,
}: {
  goBackText?: string;
  goBackUri?: string;
  children: React.ReactNode;
  className?: string;
}) => {
  const { t } = useTranslation();

  return (
    <div className="Overview">
      {goBackUri && (
        <Link to={goBackUri}>
          <div className="Overview__Go-Back">
            <i className="fa-regular fa-arrow-left me-2" />
            <span className="Overview__Go-Back__Label">
              {goBackText ?? t("wizard.back")}
            </span>
          </div>
        </Link>
      )}
      <div className={className}>{children}</div>
    </div>
  );
};
