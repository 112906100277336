import { Badge, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ActionsDropdown from "@components/ListOptionsDropdown/ActionsDropdown";
import { toCurrencyString } from "@helpers/currencyUtils";
import { useRoles } from "@hooks/role/useRoles";
import IPerson from "@interfaces/IPerson";
import "./MemberRow.scss";

const MemberRow = ({
  member,
  isPrimary = false,
  isFinance = false,
  onEdit = () => {},
  onDelete = () => {},
}: {
  member: IPerson;
  isPrimary?: boolean;
  isFinance?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
}) => {
  const { t } = useTranslation();

  const { data: roles } = useRoles(
    {
      id__in: member.roles?.join(","),
    },
    {
      enabled: !!member.roles?.length,
    }
  );

  return (
    <tr key={member.id}>
      <td className="Member-Row__Roles">
        {roles?.map((role) => (
          <Badge
            key={role.id}
            pill
            className="me-2 mb-2 border Member-Row__Roles__Role"
            bg="light"
          >
            {role.name}
          </Badge>
        ))}
      </td>
      <td>
        <div className="Member-Row__Name">
          {isPrimary && (
            <i className="fa-solid fa-star me-1 Member-Row__Name--primary"></i>
          )}
          {member.first_name} {member.last_name}
        </div>
        <p className="helper-text mb-0">
          {isPrimary || isFinance
            ? `(${[
                ...(isPrimary
                  ? [t("companies.details.overview.people.primary_contact")]
                  : []),
                ...(isFinance
                  ? [t("companies.details.overview.people.finance_contact")]
                  : []),
              ].join(", ")}) `
            : ""}
          {member.title}
        </p>
      </td>
      <td>{member.email}</td>
      <td>{toCurrencyString(member.rate, member.currency || "USD")}</td>
      <td className="table-actions">
        <ActionsDropdown
          actions={[
            {
              label: t("actions.edit"),
              onClick: () => onEdit(),
            },
            {
              label: t("actions.delete"),
              onClick: () => onDelete(),
            },
          ]}
        >
          <Button variant="outline-light" className="p-2 pt-1 pb-1">
            <i className="fa-regular fa-ellipsis-vertical"></i>
          </Button>
        </ActionsDropdown>
      </td>
    </tr>
  );
};

export default MemberRow;
