import IStaticMedia from "@interfaces/IStaticMedia";
import Service from "@services/Service";

export interface IGetStaticMediasParams {
  code?: string;
  code__in?: string;
}

export default class StaticMediaService extends Service {
  public static async getStaticMedias(params?: IGetStaticMediasParams) {
    const response = await this.axios.get<IStaticMedia[]>("/static-medias", {
      params,
    });
    return response.data;
  }
}
