import IClient, {
  IClientCreateUpdate,
  IClientTeamMember,
  IClientTeamMemberCreateUpdate,
} from "@interfaces/IClient";
import Service from "@services/Service";

export interface IGetClientsParams {
  search?: string;
  person__id?: number;
  company__id?: number;
  status?: string;
  status__in?: string;
}

export default class ClientService extends Service {
  public static async getClients(params?: IGetClientsParams) {
    const response = await this.axios.get<IClient[]>("/clients", { params });
    return response.data;
  }

  public static async getClient(id: number) {
    const response = await this.axios.get<IClient>(`/clients/${id}`);
    return response.data;
  }

  public static async createClient(client: IClientCreateUpdate) {
    const response = await this.axios.post<IClient>("/clients", client);
    return response.data;
  }

  public static async updateClient(client: IClientCreateUpdate) {
    const response = await this.axios.patch<IClient>(
      `/clients/${client.id}`,
      client
    );
    return response.data;
  }

  public static async getClientTeamMembers(clientId: number) {
    const response = await this.axios.get<IClient>(
      `/clients/${clientId}/team-members`
    );
    return response.data;
  }

  public static async createClientTeamMember(
    clientId: number,
    clientTeamMember: IClientTeamMemberCreateUpdate
  ) {
    const response = await this.axios.post<IClientTeamMember>(
      `/clients/${clientId}/team-members`,
      clientTeamMember
    );
    return response.data;
  }

  public static async updateClientTeamMember(
    clientId: number,
    clientTeamMember: IClientTeamMemberCreateUpdate
  ) {
    const response = await this.axios.patch<IClientTeamMember>(
      `/clients/${clientId}/team-members/${clientTeamMember.id}`,
      clientTeamMember
    );
    return response.data;
  }

  public static async deleteClientTeamMember(
    clientId: number,
    clientTeamMemberId: number
  ) {
    const response = await this.axios.delete<{}>(
      `/clients/${clientId}/team-members/${clientTeamMemberId}`
    );
    return response.data;
  }
}
