import axios from "axios";

export default class Service {
  protected static axios = axios.create({
    baseURL: process.env.REACT_APP_API_URL_BASE,
    withCredentials: true,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
  });
}
