import { Button, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useXchangeTeamMembers } from "@hooks/xchange/useXchangeTeamMembers";
import IXchange from "@interfaces/IXchange";
import CardPlaceholder from "@components/CardPlaceholder/CardPlaceholder";
import AlertWithIcon from "@components/AlertWithIcon/AlertWithIcon";
import { doesXchangeHaveXperts } from "@helpers/xchange-utils";
import XchangeMember from "@pages/Xchange/XchangeEdit/XchangeMembers/XchangeMember/XchangeMember";
import XchangeMemberModal from "@pages/Xchange/XchangeEdit/XchangeMembers/XchangeMemberModal/XchangeMemberModal";
import { useState } from "react";
import { IXchangeTeamMember } from "@interfaces/IXchangeTeamMember";
import { useXchangeTeamMemberDelete } from "@hooks/xchange/useXchangeTeamMemberDelete";
import PaginatedTable from "@components/PaginatedTable/PaginatedTable";
import "./XchangeMembers.scss";

const XchangeMembers = ({ xchange }: { xchange?: IXchange }) => {
  const { t } = useTranslation();

  const {
    data: xchangeTeamMembersInfiniteData,
    isFetching: areXchangeTeamMembersFetching,
    fetchNextPage,
    hasNextPage,
  } = useXchangeTeamMembers(xchange?.id!, {}, { enabled: !!xchange });
  const xchangeTeamMembers = xchangeTeamMembersInfiniteData
    ? xchangeTeamMembersInfiniteData.pages.flatMap((p) => p.results)
    : [];

  const [memberToEdit, setMemberToEdit] = useState<IXchangeTeamMember>();
  const [showMemberModal, setShowMemberModal] = useState(false);

  const editMember = (member: IXchangeTeamMember) => {
    setMemberToEdit(member);
    setShowMemberModal(true);
  };

  const deleteXchangeTeamMemberMutation = useXchangeTeamMemberDelete();
  const deleteMember = (member: IXchangeTeamMember) => {
    deleteXchangeTeamMemberMutation.mutate(member);
  };

  return (
    <>
      <div className="p-3">
        {xchange && !doesXchangeHaveXperts(xchange) && (
          <AlertWithIcon iconClasses="fa-solid fa-circle-info" className="mb-6">
            {t("xchanges.team.cannot_add_member")}
          </AlertWithIcon>
        )}

        <Stack direction="horizontal" gap={3} className="mb-5">
          <h5 className="mb-0">{t("xchanges.team.subtitle")}</h5>
          <Button
            className="ms-auto"
            size="sm"
            disabled={!xchange || !doesXchangeHaveXperts(xchange)}
            onClick={() => {
              setMemberToEdit(undefined);
              setShowMemberModal(true);
            }}
          >
            <i className="fa-regular fa-plus me-2"></i>
            {t("teams.add_member")}
          </Button>
        </Stack>

        {xchangeTeamMembers.length === 0 ? (
          <CardPlaceholder
            iconClassName={"fa-solid fa-rectangle-vertical-history"}
            title="This Xchange has no team members"
            description="Complete Xchange Details to get started"
          />
        ) : (
          <PaginatedTable
            id="xchange-members-table"
            data={xchangeTeamMembers}
            fixedItemHeight={72}
            header={
              <>
                <th>{t("xchanges.team.table.company")}</th>
                <th>{t("xchanges.team.table.roles")}</th>
                <th>{t("xchanges.team.table.name")}</th>
                <th className="Xchange-Members__Email-Column">
                  {t("xchanges.team.table.email")}
                </th>
                <th className="table-actions"></th>
              </>
            }
            renderRow={(xchangeTeamMember: IXchangeTeamMember) => (
              <XchangeMember
                xchangeTeamMember={xchangeTeamMember}
                onEdit={() => editMember(xchangeTeamMember)}
                onDelete={() => deleteMember(xchangeTeamMember)}
              />
            )}
            hasNextPage={hasNextPage}
            isDataLoading={areXchangeTeamMembersFetching}
            fetchNextPage={fetchNextPage}
          />
        )}

        {xchange && (
          <XchangeMemberModal
            xchange={xchange}
            memberToEdit={memberToEdit}
            currentMembers={xchangeTeamMembers}
            show={showMemberModal}
            onClose={() => {
              setShowMemberModal(false);
            }}
          />
        )}
      </div>
    </>
  );
};

export default XchangeMembers;
