import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import { isFieldRequired } from "@helpers/joi-utils";
import { useDocumentTypes } from "@hooks/document/useDocumentTypes";
import IDocumentType from "@interfaces/IDocumentType";
import {
  DocumentLinkFormValues,
  DocumentLinkValidationSchema,
} from "@components/Documents/UploadDocumentModal/UploadDocumentModal";
import CustomSelect from "@components/CustomSelect/CustomSelect";

const AddLinkDocumentForm = ({ disabled = false }: { disabled?: boolean }) => {
  const { t } = useTranslation();

  const [documentTypeOptions, setDocumentTypeOptions] = useState<
    { value: number; label: string }[]
  >([]);
  const { isLoading: areDocumentTypesLoading } = useDocumentTypes({
    onSuccess: (documentTypes: IDocumentType[]) => {
      setDocumentTypeOptions(
        documentTypes.map((documentType) => ({
          value: documentType.id!,
          label: documentType.name!,
        }))
      );
    },
  });

  const formMethods = useFormContext<DocumentLinkFormValues>();
  const {
    register,
    formState: { errors },
    control,
    getValues,
  } = formMethods;

  return (
    <Form>
      <Form.Group className="mb-4">
        <Form.Label
          aria-required={isFieldRequired(DocumentLinkValidationSchema, "name")}
        >
          {t("companies.documents.fields.name")}
        </Form.Label>
        <Form.Control
          type="text"
          {...register("name")}
          isInvalid={!!errors.name}
          disabled={disabled}
        />
        <FieldErrorMessage
          field={errors.name}
          fieldName={t("companies.documents.fields.name")}
        />
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label
          aria-required={isFieldRequired(DocumentLinkValidationSchema, "type")}
        >
          {t("companies.documents.fields.type")}
        </Form.Label>
        <CustomSelect
          name="type"
          control={control}
          options={areDocumentTypesLoading ? [] : documentTypeOptions}
          isDisabled={disabled}
        />
        <FieldErrorMessage
          field={errors.type}
          fieldName={t("companies.documents.fields.type")}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label
          aria-required={isFieldRequired(DocumentLinkValidationSchema, "url")}
        >
          {t("companies.documents.fields.url")}
        </Form.Label>
        <Form.Control
          type="text"
          {...register("url")}
          isInvalid={!!errors.url}
          disabled={getValues("is_cloud_file") || disabled}
        />
        <FieldErrorMessage
          field={errors.url}
          fieldName={t("companies.documents.fields.url")}
        />
      </Form.Group>
    </Form>
  );
};

export default AddLinkDocumentForm;
