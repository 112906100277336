import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import "./XchangeListPage.scss";
import Page from "@components/Page/Page";
import { Link } from "react-router-dom";
import { useXchanges } from "@hooks/xchange/useXchanges";
import { IGetXchangesParams } from "@services/XchangeService";
import { useDebounce } from "@hooks/useDebounce";
import { useClients } from "@hooks/client/useClients";
import XchangeCard from "@components/XchangeCard/XchangeCard";
import { XchangeStatuses } from "@interfaces/IXchange";
import { FormProvider, useForm } from "react-hook-form";
import CustomSelect from "@components/CustomSelect/CustomSelect";
import SearchFilter from "@components/SearchFilter/SearchFilter";
import CustomRangeDatePicker from "@components/CustomRangeDatePicker/CustomRangeDatePicker";
import { Virtuoso } from "react-virtuoso";
import OwnerSelect from "@components/OwnerSelect/OwnerSelect";

interface XchangeListFilters {
  client: number;
  status: string;
  owner: number;
  search: string;
  start_date: Date | null;
  end_date: Date | null;
  dateType: string;
}

const XchangeListPage = () => {
  const { t } = useTranslation();
  const formMethods = useForm<XchangeListFilters>({
    defaultValues: {
      dateType: "beginsOn",
    },
  });
  const { control, watch } = formMethods;

  const [getXchangesParams, setGetXchangesParams] =
    useState<IGetXchangesParams>({
      ordering: "name",
    });
  const {
    debouncedValue: debouncedGetXchangesParams,
    isDebouncing: isGetXchangesParamsDeboucing,
  } = useDebounce(getXchangesParams, 500);
  const {
    isLoading: areXchangesLoading,
    data: xchangesInfiniteData,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useXchanges(debouncedGetXchangesParams);
  const xchanges = xchangesInfiniteData
    ? xchangesInfiniteData.pages.flatMap((p) => p.results)
    : [];

  const [getClientsParams, setGetClientsParams] = useState<IGetXchangesParams>(
    {}
  );
  const {
    debouncedValue: debouncedGetClientsParams,
    isDebouncing: isGetClientsParamsDebouncing,
  } = useDebounce(getClientsParams, 400);
  const { isLoading: areClientsLoading, data: clients } = useClients(
    debouncedGetClientsParams
  );
  const [clientOptions, setClientOptions] = useState<
    { value: number; label: string }[]
  >([]);
  useEffect(() => {
    if (clients) {
      setClientOptions(
        clients.map((client) => ({
          value: client.id!,
          label: client.name!,
        }))
      );
    }
  }, [clients]);

  useEffect(() => {
    const subscription = watch((data) => {
      const filters: IGetXchangesParams = {
        ordering: "name",
        search: data.search,
        client: data.client,
        status: data.status,
        owner: data.owner,
      };

      switch (data.dateType) {
        case "beginsOn":
          if (data.start_date) {
            filters.start_date__gte = data.start_date.toISOString();
          }
          if (data.end_date) {
            filters.start_date__lte = data.end_date.toISOString();
          }
          break;
        case "endsOn":
          if (data.start_date) {
            filters.goal_end_date__gte = data.start_date.toISOString();
          }
          if (data.end_date) {
            filters.goal_end_date__lte = data.end_date.toISOString();
          }
          break;
        case "occursDuring":
          if (data.start_date) {
            filters.occurs_during_start = data.start_date.toISOString();
          }
          if (data.end_date) {
            filters.occurs_during_end = data.end_date.toISOString();
          }
          break;
      }
      setGetXchangesParams(filters);
    });
    return () => subscription.unsubscribe();
  }, [getXchangesParams, setGetXchangesParams, watch]);

  return (
    <Page
      title={t("xchanges.list.title")}
      subTitle={t("xchanges.list.subtitle")}
    >
      <Card>
        <Card.Header className="Xchange-List-Page__Header">
          <FormProvider {...formMethods}>
            <Row>
              <Col lg="2">
                <Link to={"/xchanges/create"}>
                  <Button className="fw-bold">{t("xchanges.list.new")}</Button>
                </Link>
              </Col>
              <Col
                lg="10"
                className="Xchange-List-Page__Header__Filters d-flex align-items-center justify-content-end"
              >
                <CustomSelect
                  name="client"
                  control={control}
                  className="ps-3"
                  placeholder={t("form.xchange_edit.form_labels.client")}
                  isClearable
                  isLoading={areClientsLoading || isGetClientsParamsDebouncing}
                  options={clientOptions}
                  onInputChange={(inputValue) => {
                    if (inputValue) {
                      setGetClientsParams({
                        search: inputValue,
                      });
                    }
                  }}
                />
                <CustomSelect
                  name="status"
                  control={control}
                  className="ps-3"
                  placeholder={t("form.xchange_edit.form_labels.status")}
                  isClearable
                  options={Object.values(XchangeStatuses).map((status) => ({
                    value: status,
                    label: t(`form.xchange_edit.statuses.${status}`),
                  }))}
                />
                <OwnerSelect
                  className="ps-3 pe-4"
                  placeholder={t("form.xchange_edit.form_labels.owner")}
                  isClearable
                />
                <div className="Xchange-List-Page__Header__Filters__Date ps-4 me-4">
                  <CustomRangeDatePicker
                    startDateName={"start_date"}
                    endDateName={"end_date"}
                    typeName={"dateType"}
                    className="d-flex justify-content-between Xchange-List-Page__Header__Filters__Date__Datepicker"
                    typeOptions={[
                      {
                        label: t("filters.begins_on"),
                        value: "beginsOn",
                      },
                      {
                        label: t("filters.ends_on"),
                        value: "endsOn",
                      },
                      {
                        label: t("filters.occurs_during"),
                        value: "occursDuring",
                      },
                    ]}
                    placeholder={t("form.xchange_edit.sub_titles.timeline")}
                  />
                </div>

                <SearchFilter
                  name="search"
                  className="Xchange-List-Page__Header__Filters__Search ps-4"
                  placeholder={t("filters.search")}
                  variant="primary"
                />
              </Col>
            </Row>
          </FormProvider>
        </Card.Header>
        <Card.Body className="pb-0">
          {areXchangesLoading || isGetXchangesParamsDeboucing ? (
            <div className="text-center mt-4 mb-4">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <Virtuoso
              className="Xchange-List-Page__List"
              data={xchanges}
              atBottomThreshold={420}
              atBottomStateChange={(isAtBottom) => {
                if (isAtBottom && hasNextPage) {
                  fetchNextPage();
                }
              }}
              components={{
                Footer: () => {
                  return (
                    <div className="d-flex justify-content-center">
                      {isFetchingNextPage && (
                        <Spinner
                          animation="border"
                          variant="primary"
                          size="sm"
                        />
                      )}
                    </div>
                  );
                },
              }}
              itemContent={(index, xchange) => (
                <div key={xchange.id}>
                  {index !== 0 && <hr />}
                  <XchangeCard xchange={xchange} />
                </div>
              )}
            />
          )}
        </Card.Body>
      </Card>
    </Page>
  );
};

export default XchangeListPage;
