import { useMutation, useQueryClient } from "@tanstack/react-query";
import XpertService from "@services/XpertService";

interface IMutationVars {
  xpertId: number;
  xpertTeamMemberId: number;
}

export const useXpertTeamMemberDelete = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ xpertId, xpertTeamMemberId }: IMutationVars) =>
      XpertService.deleteXpertTeamMember(xpertId, xpertTeamMemberId),
    {
      onSuccess: (_, { xpertId }) => {
        queryClient.invalidateQueries(["xperts", { xpertId }, "teams"]);
      },
    }
  );
};
