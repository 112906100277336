import { useQuery } from "@tanstack/react-query";
import ContractService from "@services/ContractService";

export const useContractPreviewDownload = (
  contractId: number,
  enabled = true
) =>
  useQuery(
    ["contracts", { id: contractId }, "download"],
    () => {
      return ContractService.downloadContractPreview(contractId);
    },
    {
      enabled: contractId !== undefined && enabled,
      refetchOnWindowFocus: false,
    }
  );
