import { useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import IXchange, { XchangeStatuses } from "@interfaces/IXchange";
import "./XchangeOverview.scss";
import { Overview } from "@components/GenericOverview/Overview/Overview";
import { OverviewSection } from "@components/GenericOverview/OverviewSection/OverviewSection";
import CardPlaceholder from "@components/CardPlaceholder/CardPlaceholder";
import Joi from "joi";
import { getXchangeContractSubjects } from "@helpers/xchange-utils";
import useContractGeneration from "@hooks/contract/useContractGeneration";
import { ProtectedContractTypeCodes } from "@interfaces/IContractType";
import useXchangeSowStatus from "@hooks/xchange/useXchangeSowStatus";
import XchangeStatusBadge from "@components/XchangeStatusBadge/XchangeStatusBadge";
import OverviewMenuGroup from "@components/GenericOverview/OverviewMenuGroup/OverviewMenuGroup";
import OverviewMenuItem from "@components/GenericOverview/OverviewMenuItem/OverviewMenuItem";
import { useNavigate } from "react-router-dom";
import XchangeSowStatusBadge from "@components/XchangeSowStatusBadge/XchangeSowStatusBadge";

export type FormValues = {
  status?: XchangeStatuses;
};

export const validationSchema = Joi.object({
  status: Joi.string().required(),
});

type Props = {
  xchange: IXchange | undefined;
  tab?: string;
  isLoading?: boolean;
};

export default function XchangeOverview({
  xchange,
  tab,
  isLoading = false,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const contractGenerationHook = useContractGeneration(
    getXchangeContractSubjects(xchange),
    [
      ProtectedContractTypeCodes.XPERT_SOW,
      ProtectedContractTypeCodes.CLIENT_SOW,
    ]
  );

  const xchangeSowStatus = useXchangeSowStatus(contractGenerationHook);

  const [projectCodeCopied, setProjectCodeCopied] = useState(false);
  const copyProjectCodeToClipboard = () => {
    if (xchange?.project_code) {
      navigator.clipboard.writeText(xchange.project_code);

      setProjectCodeCopied(true);

      setTimeout(() => {
        setProjectCodeCopied(false);
      }, 5000);
    }
  };

  return (
    <Overview
      goBackText={t("xchanges.list.alternative_title")}
      goBackUri="/xchanges"
    >
      {!xchange ? (
        <OverviewSection>
          <CardPlaceholder
            iconClassName={"fa-solid fa-rectangle-vertical-history"}
            title={t("form.xchange_edit.sub_titles.overview_placeholder_title")}
            description={t("form.xchange_edit.sub_titles.overview_placeholder")}
            className="Xchange-Edit__Overview__Placeholder"
          />
        </OverviewSection>
      ) : (
        <>
          <OverviewSection>
            <div className="Xchange-Edit__Overview__Header">
              <div className="Xchange-Edit__Overview__Header__Name">
                {xchange?.name}
              </div>
              <div className="Xchange-Edit__Overview__Header__Info">
                <Badge bg="info" text="info" className="me-1">
                  {t(`form.xchange_edit.types.${xchange.type}`)}
                </Badge>
                <XchangeStatusBadge
                  status={xchange.status}
                  size="small"
                  className="me-1"
                />
                <div className="Xchange-Edit__Overview__Project-Code__Wrapper">
                  <div
                    className="Xchange-Edit__Overview__Project-Code"
                    onClick={copyProjectCodeToClipboard}
                  >
                    {xchange.project_code}
                    <Button
                      size="sm"
                      variant="outline-light"
                      className="Xchange-Edit__Overview__Project-Code__Copy-Button"
                    >
                      {projectCodeCopied ? (
                        <>
                          <i
                            className="fa-solid fa-check fa-beat text-success-dark"
                            style={
                              {
                                "--fa-animation-iteration-count": 1,
                              } as React.CSSProperties
                            }
                          />
                        </>
                      ) : (
                        <>
                          <i className="fa-regular fa-copy me-1"></i>
                          {t("actions.copy")}
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </OverviewSection>

          <OverviewSection>
            <OverviewMenuGroup title={t("nav.xchange.details.title")}>
              <OverviewMenuItem
                title={t("nav.xchange.details.overview")}
                iconClasses="fa-regular fa-rectangle-list"
                active={tab === "overview"}
                onClick={() => {
                  navigate(`/xchanges/${xchange.id}/overview`);
                }}
              />

              <OverviewMenuItem
                title={t("nav.xchange.details.billing")}
                iconClasses="fa-regular fa-receipt"
                active={tab === "billing"}
                onClick={() => {
                  navigate(`/xchanges/${xchange.id}/billing`);
                }}
              />

              <OverviewMenuItem
                title={t("nav.xchange.details.vibe_check")}
                iconClasses="fa-regular fa-gauge"
                disabled
              />
            </OverviewMenuGroup>
          </OverviewSection>

          <OverviewSection>
            <OverviewMenuGroup title={t("nav.xchange.admin.title")}>
              <OverviewMenuItem
                title={t("nav.xchange.admin.project_setup")}
                iconClasses="fa-regular fa-book"
                disabled
              />

              <OverviewMenuItem
                title={t("nav.xchange.admin.sow")}
                iconClasses="fa-regular fa-file-contract"
                active={tab === "sow"}
                onClick={() => {
                  navigate(`/xchanges/${xchange.id}/sow`);
                }}
              >
                <XchangeSowStatusBadge status={xchangeSowStatus} showIcon />
              </OverviewMenuItem>
            </OverviewMenuGroup>
          </OverviewSection>
        </>
      )}
    </Overview>
  );
}
