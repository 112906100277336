import { useQuery } from "@tanstack/react-query";
import IXpert from "@interfaces/IXpert";
import XpertService, { IGetXpertsParams } from "@services/XpertService";

export const useXperts = (
  params?: IGetXpertsParams,
  options?: {
    enabled?: boolean;
    onSuccess?: (data: IXpert[]) => void;
    refetchOnWindowFocus?: boolean;
  }
) =>
  useQuery(
    ["xperts", { params }],
    ({ signal }) => XpertService.getXperts(params, signal),
    {
      enabled: options?.enabled,
      onSuccess: options?.onSuccess,
      refetchOnWindowFocus: options?.refetchOnWindowFocus,
    }
  );
