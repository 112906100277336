import IXchangeTerm, {
  IXchangeTermCreateUpdate,
} from "@interfaces/terms/IXchangeTerm";
import Service from "@services/Service";

export interface IGetXchangeTermsParams {
  xchange?: number;
  catalog_term?: number;
}

export default class XchangeTermService extends Service {
  public static async getXchangeTerms(params?: IGetXchangeTermsParams) {
    const response = await this.axios.get<IXchangeTerm[]>(`/xchange-terms`, {
      params,
    });
    return response.data;
  }

  public static async createXchangeTerm(term: IXchangeTermCreateUpdate) {
    const response = await this.axios.post<IXchangeTerm>(
      `/xchange-terms`,
      term
    );
    return response;
  }

  public static async updateXchangeTerm(
    xchangeId: number,
    term: IXchangeTermCreateUpdate
  ) {
    const response = await this.axios.patch<IXchangeTerm>(
      `/xchanges/${xchangeId}/terms/${term.id}`,
      term
    );
    return response;
  }

  public static async deleteXchangeTerm(xchangeId: number, termId: number) {
    const response = await this.axios.delete<IXchangeTerm>(
      `/xchanges/${xchangeId}/terms/${termId}`
    );
    return response;
  }
}
