import { useMutation, useQueryClient } from "@tanstack/react-query";
import OnboardingInvitationService from "@services/OnboardingInvitationService";
import {
  XpertOnboardingAccountCreationFormValues,
  XpertOnboardingFormValues,
} from "@pages/Onboardings/XpertOnboardingPage/XpertOnboardingConfigs";

export const useOnboardingInvitationUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ["updateOnboardingInvitation"],
    ({
      token,
      data,
    }: {
      token: string;
      data:
        | XpertOnboardingFormValues
        | XpertOnboardingAccountCreationFormValues;
      options?: {
        invalidateInvitationQuery: boolean;
      };
    }) => OnboardingInvitationService.updateOnboardingInvitation(token, data),
    {
      onSuccess: (_, { token, options }) => {
        if (options?.invalidateInvitationQuery) {
          queryClient.invalidateQueries(["onboarding-invitation", { token }]);
        }
      },
    }
  );
};
