import { useMutation, useQueryClient } from "@tanstack/react-query";
import XchangeProductService from "@services/XchangeProductService";

interface IMutationVars {
  xchangeId: number;
  xchangeProductId: number;
}

export const useXchangeProductDelete = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ xchangeId, xchangeProductId }: IMutationVars) =>
      XchangeProductService.deleteXchangeProduct(xchangeId, xchangeProductId),
    {
      onSuccess: (_, { xchangeId }) => {
        queryClient.invalidateQueries(["xchanges", { id: xchangeId }]);
      },
    }
  );
};
