export enum XeroInvoiceStatus {
  DRAFT = "DRAFT",
  SUBMITTED = "SUBMITTED",
  DELETED = "DELETED",
  AUTHORISED = "AUTHORISED",
  PAID = "PAID",
  VOIDED = "VOIDED",
}

export interface IXeroInvoiceAttachment {
  attachment_id?: string;
  file_name?: string;
  content_type?: string;
}

export interface IXeroInvoiceLineItem {
  line_item_id?: string;
  description?: string;
  quantity?: number;
  unit_amount?: number;
  item_code?: string;
  account_code?: number;
  account_id?: string;
  tax_type?: string;
  tax_amount?: number;
  line_amount?: number;
  discount_rate?: number;
  discount_amount?: number;
}

export default interface IXeroInvoice {
  contact?: {
    name?: string;
    first_name?: string;
    last_name?: string;
    email_address?: string;
  };
  line_items?: IXeroInvoiceLineItem[];
  attachments?: IXeroInvoiceAttachment[];
  type?: string;
  date?: string;
  due_date?: string;
  line_amount_types?: string;
  invoice_number?: string;
  reference?: string;
  currency_code?: string;
  currency_rate?: number;
  status?: XeroInvoiceStatus;
  sent_to_contact?: boolean;
  expected_payment_date?: string;
  planned_payment_date?: string;
  cis_deduction?: number;
  cis_rate?: number;
  sub_total?: number;
  total_tax?: number;
  total?: number;
  has_attachments?: boolean;
  total_discount?: number;
  is_discounted?: boolean;
  amount_due?: number;
  amount_paid?: number;
  fully_paid_on_date?: string;
  amount_credited?: number;
}
