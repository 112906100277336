import {
  IContractGenerationHook,
  IContractGenerationSubject,
} from "./useContractGeneration";
import useGetSubjectParties from "./useGetSubjectContractParties";

const useGetSubjectSignatories = (
  subject: IContractGenerationSubject,
  contractGenerationHook: IContractGenerationHook
) => {
  const { signatories } = contractGenerationHook;
  const parties = useGetSubjectParties(subject, contractGenerationHook);
  return signatories?.filter((signatory) =>
    parties?.some((party) => party.id === signatory.party)
  );
};

export default useGetSubjectSignatories;
