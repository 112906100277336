import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { IXpertTeamMember } from "@interfaces/IXpert";
import XpertService, {
  IGetXpertTeamMembersParams,
} from "@services/XpertService";

export const useXpertTeamMembers = (
  xpertId: number,
  params?: IGetXpertTeamMembersParams,
  options?: Pick<
    UseQueryOptions<IXpertTeamMember[], Error>,
    "enabled" | "onSuccess"
  > & {
    refetchOnWindowFocus?: boolean;
  }
) =>
  useQuery(
    ["xperts", { xpertId }, "teams"],
    () => XpertService.getXpertTeamMembers(xpertId, params),
    options
  );
