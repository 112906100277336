import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const InfoTooltip = ({ text }: { text: string }) => {
  const { t } = useTranslation();

  return (
    <div className="helper-text fw-normal">
      <OverlayTrigger overlay={<Tooltip>{text}</Tooltip>}>
        <i className="fa-regular fa-circle-info me-1"></i>
      </OverlayTrigger>
      {t("info")}
    </div>
  );
};

export default InfoTooltip;
