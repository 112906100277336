import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Page from "@components/Page/Page";
import StepCard from "@components/StepCard/StepCard";
import NotFoundPage from "@pages/NotFoundPage";
import { useCatalogTerm } from "@hooks/term/useCatalogTerm";
import TermDetailsForm from "./TermDetailsForm/TermDetailsForm";
import XpertTable from "@pages/Term/TermEditPage/XpertTable/XpertTable";
import XchangeTermTable from "@pages/Term/TermEditPage/XchangeTermTable/XchangeTermTable";

const TermEditPage = () => {
  const { t } = useTranslation();
  const params = useParams<{ id: string }>();
  const { id: termId } = params;
  const createMode = termId === "create";

  const {
    isLoading: isTermLoading,
    data: term,
    isError: termError,
  } = useCatalogTerm(parseInt(termId!), {
    enabled: !!termId && !createMode,
    retry: false,
  });

  return termError ? (
    <NotFoundPage />
  ) : (
    <Page variant="primary" title={term?.title || t("term.details.create")}>
      {(!createMode && isTermLoading) || (!term && !createMode) ? (
        <div className="text-center mt-4 mb-4">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          <StepCard title={t("term.details.details.title")}>
            <TermDetailsForm term={term!} />
          </StepCard>

          <StepCard title={t("term.details.xperts.title")}>
            <XpertTable catalogTerm={term} />
          </StepCard>

          <StepCard title={t("term.details.xchanges.title")}>
            <XchangeTermTable catalogTerm={term} />
          </StepCard>
        </>
      )}
    </Page>
  );
};

export default TermEditPage;
