export const getInitials = (name: string) => {
  const names = name.split(" ");
  let initials = "";
  for (const name of names) {
    initials += name.charAt(0).toUpperCase();
  }
  return initials;
};

export const cleanRichText = (text: string) => {
  return text
    .replace(/(<([^>]+)>)/gi, " ")
    .replace(/\s\s+/g, " ")
    .trim();
};

export const shortenString = (text: string, maxLength: number) => {
  if (text.length <= maxLength) {
    return text;
  }

  return text.slice(0, maxLength) + "...";
};
