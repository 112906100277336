import { Button, Stack } from "react-bootstrap";
import ActionsDropdown from "@components/ListOptionsDropdown/ActionsDropdown";
import InvoiceBillLineItem from "./InvoiceBillLineItem/InvoiceBillLineItem";
import { useFieldArray, useFormContext } from "react-hook-form";
import { toCurrencyString } from "@helpers/currencyUtils";
import { useTranslation } from "react-i18next";
import { InvoiceFormValues } from "@pages/Xchange/XchangeInvoicePage/XchangeInvoicePage";
import "./InvoiceBill.scss";
import { useInvoiceLineItemCreate } from "@hooks/invoice/useInvoiceLineItemCreate";
import { processInvoiceLineItemForForm } from "@helpers/invoice-utils";
import { useParams } from "react-router-dom";
import { useInvoiceLineItemDelete } from "@hooks/invoice/useInvoiceLineItemDelete";

const InvoiceBill = ({
  index,
  onInvoiceChange,
  disabled = false,
}: {
  index: number;
  onInvoiceChange: (data: InvoiceFormValues) => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const params = useParams<{
    invoiceId: string;
  }>();
  const { invoiceId } = params;

  const createInvoiceLineItemMutation = useInvoiceLineItemCreate();
  const deleteInvoiceLineItemMutation = useInvoiceLineItemDelete();

  const { control, handleSubmit, getValues } =
    useFormContext<InvoiceFormValues>();

  const {
    fields: lineItems,
    append: appendToLineItems,
    remove: removeFromLineItems,
  } = useFieldArray({
    control,
    name: `bills.${index}.line_items`,
  });

  const onAddLineItem = () => {
    if (!invoiceId) return;

    createInvoiceLineItemMutation.mutate(
      {
        invoiceId: parseInt(invoiceId),
        external_bill: getValues(`bills.${index}.external_bills.${0}`),
        data: {
          quantity: 1,
          rate: 0,
        },
      },
      {
        onSuccess: (data) => {
          appendToLineItems(processInvoiceLineItemForForm(data!));
        },
      }
    );
  };

  const onRemoveLineItem = (lineItemIndex: number) => {
    if (!invoiceId) return;

    deleteInvoiceLineItemMutation.mutate(
      {
        invoiceId: parseInt(invoiceId),
        lineItemId: getValues(`bills.${index}.line_items.${lineItemIndex}.id`)!,
      },
      {
        onSuccess: () => {
          removeFromLineItems(lineItemIndex);
          handleSubmit(onInvoiceChange)();
        },
      }
    );
  };

  return (
    <div className="Invoice-Bill">
      <Stack direction="horizontal" className="Invoice-Bill__Header">
        <div className="Invoice-Bill__Header__Title">
          {getValues(`bills.${index}.xpert`).name}
        </div>

        <div className="ms-auto">
          <ActionsDropdown
            actions={[
              {
                label: "Remove from Invoice",
                onClick: () => {},
                disabled: disabled,
              },
            ]}
          >
            <Button
              variant="outline-light"
              className="p-3 pt-1 pb-1"
              disabled={disabled}
            >
              <i className="fa-regular fa-ellipsis-vertical"></i>
            </Button>
          </ActionsDropdown>
        </div>
      </Stack>

      {lineItems.map((lineItem, lineItemIndex) => (
        <InvoiceBillLineItem
          key={lineItem.id}
          billIndex={index}
          lineItemIndex={lineItemIndex}
          onInvoiceChange={onInvoiceChange}
          onRemove={() => {
            onRemoveLineItem(lineItemIndex);
          }}
          disabled={disabled}
        />
      ))}

      <Stack direction="horizontal" className="mb-5">
        <Button
          className="ms-auto"
          onClick={() => {
            onAddLineItem();
          }}
          disabled={disabled}
        >
          <i className="fa-regular fa-plus me-2" />
          {t("billing.invoice.line_item.add")}
        </Button>
      </Stack>

      <div className="Invoice-Bill__Summary">
        <Stack direction="horizontal">
          <div className="Invoice-Bill__Summary__Item">
            {t("billing.invoice.subtotal")}
          </div>
          <div className="Invoice-Bill__Summary__Amount ms-auto">
            {toCurrencyString(getValues(`bills.${index}.subTotal`), "USD", 2)}
          </div>
        </Stack>
        <Stack direction="horizontal">
          <div className="Invoice-Bill__Summary__Item">
            {t("billing.invoice.discount")}
          </div>
          <div className="Invoice-Bill__Summary__Amount ms-auto">
            {toCurrencyString(
              -getValues(`bills.${index}.totalDiscount`),
              "USD",
              2
            )}
          </div>
        </Stack>
        <Stack direction="horizontal" className="align-items-start">
          <div className="Invoice-Bill__Summary__Item">
            {t("billing.invoice.markup")}
            <div className="Invoice-Bill__Summary__Item__Description">
              {t("billing.invoice.markup_desc")}
            </div>
          </div>
          <div className="Invoice-Bill__Summary__Amount ms-auto">
            {toCurrencyString(
              getValues(`bills.${index}.totalMarkup`),
              "USD",
              2
            )}
          </div>
        </Stack>
        <Stack direction="horizontal">
          <div className="Invoice-Bill__Summary__Item">
            {t("billing.invoice.tax")}
            <div className="Invoice-Bill__Summary__Item__Description">
              {t("billing.invoice.tax_desc")}
            </div>
          </div>
          <div className="Invoice-Bill__Summary__Amount ms-auto">-</div>
        </Stack>
      </div>
      <div className="Invoice-Bill__Summary Invoice-Bill__Summary--total">
        <Stack direction="horizontal">
          <div className="Invoice-Bill__Summary__Item">
            {t("billing.invoice.estimate")}
            <div className="Invoice-Bill__Summary__Item__Description">
              {t("billing.invoice.estimate_desc")}
            </div>
          </div>
          <div className="Invoice-Bill__Summary__Amount ms-auto">
            {toCurrencyString(getValues(`bills.${index}.total`), "USD", 2)}
          </div>
        </Stack>
      </div>
    </div>
  );
};

export default InvoiceBill;
