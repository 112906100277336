import { useTranslation } from "react-i18next";
import { Overview } from "@components/GenericOverview/Overview/Overview";
import { OverviewSection } from "@components/GenericOverview/OverviewSection/OverviewSection";
import ICompany from "@interfaces/ICompany";
import "./CompanyOverview.scss";
import OverviewMenuGroup from "@components/GenericOverview/OverviewMenuGroup/OverviewMenuGroup";
import OverviewMenuItem from "@components/GenericOverview/OverviewMenuItem/OverviewMenuItem";
import { useNavigate } from "react-router-dom";
import CompanyStatusbadge from "@components/CompanyStatusBadge/CompanyStatusBadge";
import { useState } from "react";
import { useXpertCreate } from "@hooks/xpert/useXpertCreate";
import { useClientCreate } from "@hooks/client/useClientCreate";
import GenericModal from "@components/GenericModal/GenericModal";
import { XpertStatus } from "@interfaces/IXpert";
import { ClientStatus } from "@interfaces/IClient";
import useContractGeneration from "@hooks/contract/useContractGeneration";
import { ProtectedContractTypeCodes } from "@interfaces/IContractType";
import useGetSubjectContract from "@hooks/contract/useGetSubjectContract";
import ContractStatusBadge from "@components/ContractStatusBadge/ContractStatusBadge";

const CompanyOverview = ({
  company,
  tab,
  createMode = false,
}: {
  company?: ICompany;
  tab?: string;
  createMode?: boolean;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showXpertCreationConfirmation, setShowXpertCreationConfirmation] =
    useState(false);
  const [showClientCreationConfirmation, setShowClientCreationConfirmation] =
    useState(false);
  const createXpertMutation = useXpertCreate();
  const createClientMutation = useClientCreate();

  const xpertContractSubject = {
    subjectId: company?.xpert_id!,
    contractType: ProtectedContractTypeCodes.XPERT_MSA,
  };

  const clientContractSubject = {
    subjectId: company?.client_id!,
    contractType: ProtectedContractTypeCodes.CLIENT_MSA,
  };

  const contractSubjects = (() => {
    const subjects = [];

    if (company?.xpert_id) {
      subjects.push(xpertContractSubject);
    }

    if (company?.client_id) {
      subjects.push(clientContractSubject);
    }

    return subjects;
  })();

  const contractGenerationHook = useContractGeneration(contractSubjects);
  const xpertMSA = useGetSubjectContract(
    xpertContractSubject,
    contractGenerationHook,
    true
  );
  const clientMSA = useGetSubjectContract(
    clientContractSubject,
    contractGenerationHook,
    true
  );

  return (
    <Overview
      goBackText={t("companies.list.alternative_title")}
      goBackUri="/companies"
    >
      <OverviewSection>
        <div className="Company-Overview__Header">
          <img
            className="Company-Overview__Header__Logo"
            src={company?.company_logo ?? "/logo-light.png"}
            alt={company?.company_name}
          />

          <div>
            <span className="Company-Overview__Header__Name">
              {company?.company_name || t("companies.details.new")}
            </span>
            {company && (
              <div className="Company-Overview__Header__Info">
                <CompanyStatusbadge status={company.status!} />
              </div>
            )}
          </div>
        </div>
      </OverviewSection>

      <OverviewSection>
        <OverviewMenuGroup title={t("nav.company.title")}>
          <OverviewMenuItem
            title={t("nav.company.overview")}
            iconClasses="fa-regular fa-building"
            active={tab === "overview"}
            onClick={() => {
              navigate(
                `/companies/${createMode ? "create" : company?.id}/overview`
              );
            }}
          />

          <OverviewMenuItem
            title={t("nav.company.profile")}
            iconClasses="fa-regular fa-circle-user"
            active={tab === "profile"}
            onClick={() => {
              navigate(`/companies/${company?.id}/profile`);
            }}
            disabled={createMode}
          />
        </OverviewMenuGroup>
      </OverviewSection>

      <OverviewSection>
        <OverviewMenuGroup title={t("nav.company.xpert.title")}>
          {company?.xpert_id ? (
            <>
              <OverviewMenuItem
                title={t("nav.company.xpert.overview")}
                iconClasses="fa-regular fa-house-user"
                active={tab === "xpert"}
                onClick={() => {
                  navigate(`/companies/${company?.id}/xpert`);
                }}
              />

              <OverviewMenuItem
                title={t("nav.company.xpert.billing")}
                iconClasses="fa-regular fa-receipt"
                active={tab === "xpert-billing"}
                onClick={() => {
                  navigate(`/companies/${company?.id}/xpert-billing`);
                }}
              />

              <OverviewMenuItem
                title={t("nav.company.xpert.msa")}
                iconClasses="fa-regular fa-file-contract"
                active={tab === "xpert-msa"}
                onClick={() => {
                  navigate(`/companies/${company?.id}/xpert-msa`);
                }}
              >
                {xpertMSA && (
                  <ContractStatusBadge status={xpertMSA?.status} showIcon />
                )}
              </OverviewMenuItem>
            </>
          ) : (
            <div
              className={`Company-Overview__Create-Box ${
                createMode && "Company-Overview__Create-Box--disabled"
              }`}
              onClick={() => {
                setShowXpertCreationConfirmation(true);
              }}
            >
              {createXpertMutation.isLoading ? (
                <>
                  <i className="fa-regular fa-spinner fa-spin me-2" />
                  {t("loading")}
                </>
              ) : (
                <>
                  <i className="fa-regular fa-plus me-2" />
                  {t("companies.details.tab.xpert_add")}
                </>
              )}
            </div>
          )}
        </OverviewMenuGroup>
      </OverviewSection>

      <OverviewSection>
        <OverviewMenuGroup title={t("nav.company.client.title")}>
          {company?.client_id ? (
            <>
              <OverviewMenuItem
                title={t("nav.company.client.overview")}
                iconClasses="fa-regular fa-user-tie"
                active={tab === "client"}
                onClick={() => {
                  navigate(`/companies/${company?.id}/client`);
                }}
              />

              <OverviewMenuItem
                title={t("nav.company.client.billing")}
                iconClasses="fa-regular fa-receipt"
                active={tab === "client-billing"}
                onClick={() => {
                  navigate(`/companies/${company?.id}/client-billing`);
                }}
              />

              <OverviewMenuItem
                title={t("nav.company.client.msa")}
                iconClasses="fa-regular fa-file-contract"
                active={tab === "client-msa"}
                onClick={() => {
                  navigate(`/companies/${company?.id}/client-msa`);
                }}
              >
                {clientMSA && (
                  <ContractStatusBadge status={clientMSA?.status} showIcon />
                )}
              </OverviewMenuItem>
            </>
          ) : (
            <div
              className={`Company-Overview__Create-Box ${
                createMode && "Company-Overview__Create-Box--disabled"
              }`}
              onClick={() => {
                setShowClientCreationConfirmation(true);
              }}
            >
              {createClientMutation.isLoading ? (
                <>
                  <i className="fa-regular fa-spinner fa-spin me-2" />
                  {t("loading")}
                </>
              ) : (
                <>
                  <i className="fa-regular fa-plus me-2" />
                  {t("companies.details.tab.client_add")}
                </>
              )}
            </div>
          )}
        </OverviewMenuGroup>
      </OverviewSection>

      {company && (
        <>
          <GenericModal
            title={t("companies.details.xpert.creation.title")}
            show={showXpertCreationConfirmation}
            onClose={() => {
              setShowXpertCreationConfirmation(false);
            }}
            onFinish={() => {
              setShowXpertCreationConfirmation(false);
              createXpertMutation.mutate(
                {
                  xpert: {
                    company_person: `/companies/${company.id!}`,
                    status: XpertStatus.PROSPECT,
                  },
                },
                {
                  onSuccess: (xpert) => {
                    navigate(`/companies/${company.id!}/xpert`);
                  },
                }
              );
            }}
            closeText={t("actions.cancel")}
            finishText={t("actions.confirm")}
            canBeFinished={!createXpertMutation.isLoading}
          >
            <h2 className="mb-6">
              {t("companies.details.xpert.creation.subtitle")}
            </h2>
            {t("companies.details.xpert.creation.desc", {
              company_name: company.company_name!,
            })}
          </GenericModal>

          <GenericModal
            title={t("companies.details.client.creation.title")}
            show={showClientCreationConfirmation}
            onClose={() => {
              setShowClientCreationConfirmation(false);
            }}
            onFinish={() => {
              setShowClientCreationConfirmation(false);
              createClientMutation.mutate(
                {
                  client: {
                    company_person: `/companies/${company.id!}`,
                    status: ClientStatus.PROSPECT,
                  },
                },
                {
                  onSuccess: () => {
                    navigate(`/companies/${company.id!}/client`);
                  },
                }
              );
            }}
            closeText={t("actions.cancel")}
            finishText={t("actions.confirm")}
            canBeFinished={!createClientMutation.isLoading}
          >
            <h2 className="mb-6">
              {t("companies.details.client.creation.subtitle")}
            </h2>
            {t("companies.details.client.creation.desc", {
              company_name: company.company_name!,
            })}
          </GenericModal>
        </>
      )}
    </Overview>
  );
};

export default CompanyOverview;
