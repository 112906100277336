import { useMutation, useQueryClient } from "@tanstack/react-query";
import IContract from "@interfaces/IContract";
import ContractService from "@services/ContractService";

interface IMutationVars {
  contract: IContract;
}

export const useContractCreate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ contract }: IMutationVars) => ContractService.createContract(contract),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["contracts"]);
      },
    }
  );
};
