import Joi from "joi";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isFieldRequired } from "@helpers/joi-utils";
import { allCountries } from "country-region-data";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import CustomSelect from "@components/CustomSelect/CustomSelect";

const RegionSelect = ({
  FormValidationSchema,
  disabled = false,
  isClearable = false,
}: {
  FormValidationSchema: Joi.ObjectSchema;
  disabled?: boolean;
  isClearable?: boolean;
}) => {
  const { t } = useTranslation();

  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Form.Label
        aria-required={isFieldRequired(FormValidationSchema, "region")}
      >
        {t("form.fields.region")}
      </Form.Label>
      <CustomSelect
        control={control}
        name="region"
        options={allCountries
          .find((c) => c[0] === getValues("country"))?.[2]
          .map((region) => ({
            value: region[0],
            label: region[0],
          }))
          .filter((region) => !region.label.includes("Armed Forces"))}
        isDisabled={disabled}
        isClearable={isClearable}
      />
      <FieldErrorMessage
        field={errors.region}
        fieldName={t("form.fields.region")}
      />
    </>
  );
};

export default RegionSelect;
