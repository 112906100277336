import { Badge, Form, FormCheckProps } from "react-bootstrap";
import "./ListItemCheckbox.scss";
import { ReactNode } from "react";
import { shortenString } from "@helpers/string-utils";

const ListItemCheckbox = (
  props: {
    title: string;
    subtitle?: string;
    description?: string;
    src?: string;
    badges?: string[];
    facts?: {
      label: string;
      prefix?: string;
      suffix?: string;
    }[];
    statistics?: {
      label: string;
      value: string;
      badge?: ReactNode;
    }[];
  } & FormCheckProps
) => {
  const mainClassName = `List-Item-Checkbox ${
    props.checked && "List-Item-Checkbox--checked"
  } ${props.disabled && "List-Item-Checkbox--disabled"}`;

  const getIconClasses = () => {
    if (props.type === "radio") {
      if (props.checked) {
        return "fa-solid fa-circle-dot";
      } else {
        return "fa-light fa-circle";
      }
    } else {
      if (props.checked) {
        return "fa-solid fa-square-check";
      } else {
        return "fa-regular fa-square";
      }
    }
  };

  return (
    <div
      className={`${mainClassName} ${props.className}`}
      onClick={!props.disabled ? props.onClick : undefined}
    >
      <Form.Check type="checkbox" {...props} className="d-none" readOnly />
      <div className="List-Item-Checkbox__Contenant">
        <div className="List-Item-Checkbox__Contenant__Checkbox-Zone">
          <i className={getIconClasses()} />
        </div>

        <div className="List-Item-Checkbox__Contenant__Details">
          {props.src && (
            <div className="d-flex align-items-center h-100">
              <img
                className="List-Item-Checkbox__Contenant__Details__Image"
                src={props.src}
                alt=""
              />
            </div>
          )}

          <div className="List-Item-Checkbox__Contenant__Details__General">
            <div className="d-flex align-items-center">
              <span className="List-Item-Checkbox__Contenant__Details__General__Title">
                {props.title}
              </span>
              {props.subtitle && (
                <span className="List-Item-Checkbox__Contenant__Details__General__Subtitle">
                  {props.subtitle}
                </span>
              )}
            </div>
            {props.description && (
              <div className="List-Item-Checkbox__Contenant__Details__General__Description">
                {shortenString(props.description, 140)}
              </div>
            )}
          </div>

          {props.statistics &&
            props.statistics?.map((statistic, index) => (
              <div
                key={`${index}-${statistic.label}`}
                className={`List-Item-Checkbox__Contenant__Details__Statistic ${
                  index === 0 ? "ms-auto" : ""
                }`}
              >
                <div className="List-Item-Checkbox__Contenant__Details__Statistic__Label">
                  <span className="List-Item-Checkbox__Contenant__Details__Statistic__Label__Name">
                    {statistic.label}
                  </span>

                  {statistic.badge && (
                    <span className="List-Item-Checkbox__Contenant__Details__Statistic__Label__Badge">
                      {statistic.badge}
                    </span>
                  )}
                </div>
                <div className="List-Item-Checkbox__Contenant__Details__Statistic__Value">
                  {statistic.value}
                </div>
              </div>
            ))}

          <div
            className={`d-flex align-items-start ${
              !props.statistics ? "ms-auto" : ""
            }`}
          >
            {props.badges && (
              <div className="List-Item-Checkbox__Contenant__Details__Badges">
                {props.badges?.map(
                  (badge, index) =>
                    index < 2 && (
                      <Badge
                        key={`${index}-${badge}`}
                        pill
                        bg="secondary"
                        text="secondary"
                        className="List-Item-Checkbox__Contenant__Details__Badges__Badge mb-2 me-2"
                      >
                        {badge}
                      </Badge>
                    )
                )}
                {props.badges.length > 2 && (
                  <small className="mb-2 me-2">
                    {"+ " + (props.badges.length - 2)}
                  </small>
                )}
              </div>
            )}

            <div className="List-Item-Checkbox__Contenant__Details__Facts">
              {props.facts?.map((fact, index) => (
                <div
                  key={`fact_${index}`}
                  className="List-Item-Checkbox__Contenant__Details__Facts__Fact"
                >
                  {fact.prefix && (
                    <span className="List-Item-Checkbox__Contenant__Details__Facts__Fact--prefix-suffix">
                      {`${fact.prefix} `}
                    </span>
                  )}
                  {fact.label}
                  {fact.suffix && (
                    <span className="List-Item-Checkbox__Contenant__Details__Facts__Fact--prefix-suffix">
                      {` ${fact.suffix}`}
                    </span>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListItemCheckbox;
