import IXchangeProduct, {
  IXchangeProductCreateUpdate,
} from "@interfaces/products/IXchangeProduct";
import Service from "@services/Service";

export default class XchangeProductService extends Service {
  public static async getXchangeProducts(xchangeId: number) {
    const response = await this.axios.get<IXchangeProduct[]>(
      `/xchanges/${xchangeId}/products`
    );
    return response.data;
  }

  public static async createXchangeProduct(
    xchangeId: number,
    product: IXchangeProductCreateUpdate
  ) {
    const response = await this.axios.post<IXchangeProduct>(
      `/xchanges/${xchangeId}/products`,
      product
    );
    return response;
  }

  public static async updateXchangeProduct(
    xchangeId: number,
    product: IXchangeProductCreateUpdate
  ) {
    const response = await this.axios.put<IXchangeProduct>(
      `/xchanges/${xchangeId}/products/${product.id}`,
      product
    );
    return response;
  }

  public static async deleteXchangeProduct(
    xchangeId: number,
    productId: number
  ) {
    const response = await this.axios.delete<IXchangeProduct>(
      `/xchanges/${xchangeId}/products/${productId}`
    );
    return response;
  }
}
