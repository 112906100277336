import { RefAttributes, useRef, useState } from "react";
import { Control, useController } from "react-hook-form";
import SelectComponent, {
  ActionMeta,
  components,
  DropdownIndicatorProps,
  OptionProps,
  OptionsOrGroups,
} from "react-select";
import Select from "react-select/dist/declarations/src/Select";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";
import "./CustomSelect.scss";
import { FormControl, Stack } from "react-bootstrap";
import { isEqual } from "lodash";
import { useTranslation } from "react-i18next";
import useOnClickOutside from "@hooks/useOnClickOutside";

export interface ICustomSelectOption {
  value: any;
  label: string;
  isFixed?: boolean;
  defaultSearchValue?: string;
  onSearch?: (search: string) => void;
  isSearchLoading?: boolean;
}

const DropdownIndicator = (props: DropdownIndicatorProps<any, any, any>) => {
  return (
    <components.DropdownIndicator className="me-2" {...props}>
      {props.selectProps.menuIsOpen ? (
        <i className="fa-solid fa-caret-up"></i>
      ) : (
        <i className="fa-solid fa-caret-down"></i>
      )}
    </components.DropdownIndicator>
  );
};

const Option = (props: OptionProps<any, any, any>) => {
  const { t } = useTranslation();

  const isValueSelected = (value: any): boolean => {
    if (Array.isArray(props.selectProps.value)) {
      return props.selectProps.value.some((option) => option.value === value);
    } else {
      return props.selectProps.value?.value === value;
    }
  };

  return (
    <>
      <div>
        <components.Option
          {...props}
          className={`Custom-Select__Option${
            isValueSelected(props.data.value) ? "--selected" : ""
          }`}
        >
          {Array.isArray(props.data.value) && (
            <div className="Custom-Select__Option__Sub-Options-Menu">
              <div className="Custom-Select__Option__Sub-Options-Menu__List">
                {props.data.onSearch && (
                  <div>
                    <div className="Custom-Select__Option__Sub-Options-Menu__List__Search__Icon">
                      {props.data.isSearchLoading ? (
                        <i className="fa-solid fa-spinner-third fa-spin" />
                      ) : (
                        <i className="fa-solid fa-search" />
                      )}
                    </div>
                    <FormControl
                      className="Custom-Select__Option__Sub-Options-Menu__List__Search"
                      placeholder={t("filters.search")}
                      defaultValue={props.data.defaultSearchValue}
                      onMouseDown={(event) => {
                        event.stopPropagation();
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        (event.target as HTMLInputElement).focus();
                      }}
                      onKeyDown={(event) => {
                        event.stopPropagation();
                      }}
                      onChange={(event) => {
                        props.data.onSearch(event.target.value);
                      }}
                    />
                  </div>
                )}

                {props.data.value.length === 0 && (
                  <div className="text-muted small text-center p-4">
                    {t("filters.no_results")}
                  </div>
                )}

                {props.data.value.map((option: ICustomSelectOption) => {
                  return (
                    <div
                      key={`sub_option_${JSON.stringify(option.value)}`}
                      className={`Custom-Select__Option__Sub-Options-Menu__List__Option ${
                        isValueSelected(option.value) &&
                        "Custom-Select__Option__Sub-Options-Menu__List__Option--selected"
                      }`}
                      onClick={(event) => {
                        event.stopPropagation();

                        props.selectProps.onChange(
                          props.selectProps.isMulti
                            ? props.selectProps.value
                            : option,
                          {
                            action: isValueSelected(option.value)
                              ? "deselect-option"
                              : "select-option",
                            name: props.selectProps.name,
                            option,
                          } as ActionMeta<any>
                        );

                        props.selectProps.onMenuClose();
                      }}
                    >
                      {props.isMulti && (
                        <input
                          className="me-4"
                          type="checkbox"
                          checked={isValueSelected(option.value)}
                          disabled={isValueSelected(option.isFixed)}
                          onChange={() => null}
                        />
                      )}

                      {option.label}
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          <div
            className={`Custom-Select__Option__Label ${
              !Array.isArray(props.data.value) &&
              !props.data.isFixed &&
              "Custom-Select__Option__Label--Selectable"
            }`}
            onClick={(event) => {
              if (Array.isArray(props.data.value)) {
                event.stopPropagation();
              }
            }}
          >
            <Stack direction="horizontal">
              {props.isMulti && !Array.isArray(props.data.value) && (
                <input
                  className="me-4"
                  type="checkbox"
                  checked={props.isSelected}
                  disabled={props.data.isFixed}
                  onChange={() => null}
                />
              )}

              {props.label}

              {Array.isArray(props.data.value) && (
                <div className="ms-auto">
                  <i className="fa-regular fa-chevron-right"></i>
                </div>
              )}
            </Stack>
          </div>
        </components.Option>
      </div>
    </>
  );
};

const CustomSelect = <IsMulti extends boolean = false>(
  props: StateManagerProps<ICustomSelectOption, IsMulti, any> &
    RefAttributes<Select<ICustomSelectOption, IsMulti, any>> & {
      control: Control<any, any>;
      name: string;
    } & {
      onBeforeChange?: (
        options: ICustomSelectOption | ICustomSelectOption[] | null,
        actionMeta: any
      ) => void;
      onAfterChange?: (
        options: ICustomSelectOption | ICustomSelectOption[] | null,
        actionMeta: any
      ) => void;
    }
) => {
  const {
    field,
    formState: { errors },
  } = useController({
    name: props.name,
    control: props.control,
  });

  const hasSubOptions = (() => {
    for (const option of props.options || []) {
      if (Array.isArray(option.value)) {
        return true;
      }
    }
    return false;
  })();

  let flattenOptions: OptionsOrGroups<ICustomSelectOption, any> = [];
  for (const option of props.options || []) {
    if (Array.isArray(option.value)) {
      flattenOptions = flattenOptions.concat(option.value);
    } else {
      flattenOptions = flattenOptions.concat(option);
    }
  }

  const [selectedOptions, setSelectedOptions] = useState<ICustomSelectOption[]>(
    []
  );

  if (props.isMulti) {
    for (const selectedOption of selectedOptions) {
      if (!flattenOptions?.some((value) => isEqual(value, selectedOption))) {
        flattenOptions = flattenOptions.concat(selectedOption);
      }
    }
  }

  const value = props.isMulti
    ? flattenOptions?.filter((option) =>
        field.value?.some((value: any) => isEqual(value, option.value))
      )
    : flattenOptions?.find((option) => isEqual(field.value, option.value)) ||
      null;

  if (props.isMulti) {
    value?.sort((a: ICustomSelectOption, b: ICustomSelectOption) => {
      if (a.label < b.label) {
        return -1;
      } else if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
  }

  const areAllSelectedOptionFixed =
    props.isMulti &&
    (value as ICustomSelectOption[])?.every((option) => option.isFixed);

  const fieldLevels = field.name.split(".");

  const getError = () => {
    let error = errors as any;
    for (const level of fieldLevels) {
      if (error[level]) {
        error = error[level];
      } else {
        return undefined;
      }
    }
    return error;
  };

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const ref = useRef(null);
  useOnClickOutside(ref, () => setMenuIsOpen(false));

  return (
    <div
      ref={ref}
      onClick={() => {
        if (!props.isDisabled) {
          setMenuIsOpen(true);
        }
      }}
      onFocus={() => {
        if (!props.isDisabled) {
          setMenuIsOpen(true);
        }
      }}
    >
      <SelectComponent
        {...props}
        menuIsOpen={menuIsOpen}
        className={`Custom-Select ${props.className ?? ""}`}
        hideSelectedOptions={false}
        components={{ DropdownIndicator, Option }}
        classNames={{
          placeholder: () => "Custom-Select__Placeholder",
          control: () =>
            `Custom-Select__Control ${
              props.isDisabled && "Custom-Select__Control--disabled"
            } ${!!getError() && "Custom-Select__Control--invalid"}`,
          indicatorSeparator: () => "d-none",
          dropdownIndicator: () =>
            `Custom-Select__Dropdown-Indicator p-0 me-1 text-center justify-content-center align-items-center ${
              !!getError() && "Custom-Select__Dropdown-Indicator--invalid"
            }
          `,
          menu: () =>
            `Custom-Select__Menu ${
              hasSubOptions && "Custom-Select__Menu--small"
            }`,
          menuList: () =>
            hasSubOptions ? "Custom-Select__Menu-List--with-sub-options" : "",
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "#62629c",
            primary25: "#f2f2fa",
          },
        })}
        styles={{
          multiValueLabel: (base, state) => {
            return state.data.isFixed
              ? {
                  ...base,
                  paddingRight: 6,
                }
              : base;
          },
          multiValueRemove: (base, state) => {
            return state.data.isFixed ? { ...base, display: "none" } : base;
          },
          placeholder: (provided) => ({
            ...provided,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }),
        }}
        isClearable={!areAllSelectedOptionFixed && props.isClearable}
        onChange={(options, actionMeta) => {
          props.onBeforeChange?.(options, actionMeta);
          if (options) {
            if (!Array.isArray(options.value)) {
              if (props.isMulti) {
                switch (actionMeta.action) {
                  case "select-option":
                    if (Array.isArray(actionMeta.option.value)) {
                      return;
                    } else {
                      if (!options.includes(actionMeta.option)) {
                        options = options.concat(actionMeta.option);
                      }
                    }
                    break;
                  case "remove-value":
                  case "pop-value":
                  case "deselect-option":
                    if (
                      actionMeta.removedValue?.isFixed ||
                      actionMeta.option?.isFixed
                    ) {
                      return;
                    }
                    if (
                      actionMeta.option &&
                      !Array.isArray(actionMeta.option.value)
                    ) {
                      if (options.includes(actionMeta.option)) {
                        options = options.filter(
                          (option: ICustomSelectOption) =>
                            option.value !== actionMeta.option.value
                        );
                      }
                    }
                    break;
                  case "clear":
                    options = (props.options as ICustomSelectOption[]).filter(
                      (option) => option.isFixed
                    );
                }
              }

              setSelectedOptions(options);
              field.onChange(
                props.isMulti
                  ? options.map((option: ICustomSelectOption) => option.value)
                  : options.value
              );

              if (!props.isMulti) {
                // Instruction has to be in a seperated event, due to React 18 Automatic Batching
                setTimeout(() => {
                  setMenuIsOpen(false);
                }, 1);
              }
            }
          } else {
            field.onChange(null);
          }

          props.onAfterChange?.(options, actionMeta);
        }}
        value={value}
        name={field.name}
      />
    </div>
  );
};

export default CustomSelect;
