import "./CustomRangeDatePicker.scss";
import CustomDatePicker, {
  CustomDatePickerType,
} from "@components/CustomDatePicker/CustomDatePicker";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { createRef, useEffect } from "react";
import { formatDateToString, toRangeString } from "@helpers/date-utils";

const CustomRangeDatePicker = ({
  startDateName,
  endDateName,
  placeholder,
  isClearable = true,
  disabled = false,
  className = "",
  onAfterChange,
  typeName,
  typeOptions,
}: {
  startDateName: string;
  endDateName: string;
  placeholder?: string;
  isClearable?: boolean;
  disabled?: boolean;
  className?: string;
  onAfterChange?: () => void;
  typeName?: string;
  typeOptions?: CustomDatePickerType[];
}) => {
  const {
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext();

  const localFormMethods = useForm<{
    range: (Date | null)[];
    type?: string;
  }>({
    defaultValues: {
      range: [getValues(startDateName) || null, getValues(endDateName) || null],
      type: typeName && getValues(typeName),
    },
  });
  const {
    getValues: localGetValues,
    watch: localWatch,
    setValue: setLocalValue,
  } = localFormMethods;

  useEffect(() => {
    setLocalValue("range", [
      getValues(startDateName) || null,
      getValues(endDateName) || null,
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch(startDateName), watch(endDateName)]);

  const clear = () => {
    setLocalValue("range", []);
    setValue(startDateName, null);
    setValue(endDateName, null);
    onAfterChange?.();
  };

  const customRangeDatePicker = createRef<HTMLDivElement>();

  const getInputText = (): string => {
    if (localGetValues("range.0") && !localGetValues("range.1")) {
      return formatDateToString(localGetValues("range.0")!, {
        dateStyle: "medium",
      });
    }

    if (localGetValues("range.0") && localGetValues("range.1")) {
      return toRangeString(
        localGetValues("range.0")!,
        localGetValues("range.1")!,
        true
      );
    }

    return "";
  };

  return (
    <FormProvider {...localFormMethods}>
      <div
        ref={customRangeDatePicker}
        className={`Custom-Range-DatePicker ${
          disabled && "Custom-Range-DatePicker--disabled"
        } ${
          (errors[startDateName] || errors[endDateName]) &&
          "Custom-Range-DatePicker--invalid"
        } ${className}`}
        onClick={(event) => {
          if (
            !(event.target as Element).className.includes(
              "react-datepicker__day"
            ) &&
            !(event.target as Element).className.includes(
              "Custom-Range-DatePicker__Clear"
            )
          ) {
            const input = customRangeDatePicker.current?.querySelector(
              `input.Custom-DatePicker`
            ) as HTMLInputElement;
            if (input) {
              input.focus();
            }
          }
        }}
      >
        <div className="d-flex">
          <CustomDatePicker
            name={"range"}
            selectsRange={true}
            startDate={localWatch("range.0")}
            endDate={localWatch("range.1")}
            popperModifiers={[
              {
                name: "offset",
                options: {
                  offset: [-9, -1],
                },
              },
              {
                name: "flip",
                options: {
                  fallbackPlacements: ["bottom"],
                },
              },
            ]}
            onAfterChange={(date) => {
              if (Array.isArray(date)) {
                setValue(startDateName, date[0] || null);
                setValue(endDateName, date[1] || null);
              }
              onAfterChange?.();
            }}
            hideInput={true}
            disabled={disabled}
            monthsShown={2}
            typeName={"type"}
            typeOptions={typeOptions}
            onAfterTypeChange={(type) => {
              if (typeName) {
                setValue(typeName, type);
              }
              onAfterChange?.();
            }}
          />

          <input
            type="text"
            readOnly
            value={getInputText()}
            placeholder={placeholder}
            className="border-0"
          />
        </div>

        <div>
          {isClearable && (localWatch("range.0") || localWatch("range.1")) && (
            <i
              className="fa-regular fa-times Custom-Range-DatePicker__Clear me-1"
              onClick={() => clear()}
            ></i>
          )}

          <div className="Custom-Range-DatePicker__Icon">
            <i className="fa-regular fa-calendar"></i>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default CustomRangeDatePicker;
