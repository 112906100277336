import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import IDomain from "@interfaces/IDomain";
import DomainService, { IGetDomainsParams } from "@services/DomainService";

export const useDomains = (
  params?: IGetDomainsParams,
  options?: Pick<UseQueryOptions<IDomain[], Error>, "enabled" | "onSuccess"> & {
    refetchOnWindowFocus?: boolean;
  }
) =>
  useQuery(
    ["domains", { params }],
    () => DomainService.getDomains(params),
    options
  );
