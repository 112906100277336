import { Modify } from "@helpers/typing";
import ICompanyPersonType from "@interfaces/ICompanyPersonType";
import IPerson from "@interfaces/IPerson";
import IStage from "@interfaces/IStage";

export enum ClientStatus {
  PROSPECT = "prospect",
  ACTIVE = "active",
  INACTIVE = "inactive",
  DISENGAGED = "disengaged",
}

interface IClient extends ICompanyPersonType {
  id?: number;
  name?: string;
  company_logo?: string;
  stage_number?: IStage;
  finance_contact?: number | null;
  invoice_email?: string;
  client_feedback?: string;
  folder_id?: string;
  status?: ClientStatus;
  primary_contact?: number | null;
  completed_sections?: string[];
  markup?: number;
  msa_effective_date?: string;
  created_at?: string;
  updated_at?: string;
}

export type IClientCreateUpdate = Modify<
  IClient,
  {
    stage_number?: number;
  }
>;

export interface IClientTeamMember {
  id?: number;
  client?: number;
  person?: IPerson;
  rate?: string;
  currency?: string;
  roles?: number[];
}

export type IClientTeamMemberCreateUpdate = Modify<
  IClientTeamMember,
  {
    person?: number;
  }
>;

export default IClient;
