import "./DottedProgressBar.scss";

const DottedProgressBar = ({
  currentStep,
  steps,
  className,
}: {
  currentStep: number;
  steps: string[];
  className?: string;
}) => {
  const progressPourcentage = (currentStep / (steps.length - 1)) * 100;

  return (
    <div className={`Dotted-Progress-Bar ${className}`}>
      <div className="Dotted-Progress-Bar__Steps">
        {steps.map((step, index) => (
          <div key={`step_${index}`} className="Dotted-Progress-Bar__Step">
            <div
              className={`Dotted-Progress-Bar__Step__Dot ${
                currentStep >= index && "Dotted-Progress-Bar__Step__Dot--active"
              }`}
            />

            {currentStep === index && (
              <div className="Dotted-Progress-Bar__Step__Name">{step}</div>
            )}
          </div>
        ))}
      </div>
      <div className="Dotted-Progress-Bar__Bar">
        <div
          className="Dotted-Progress-Bar__Bar__Progress"
          style={{
            width: `${progressPourcentage}%`,
            transition: "width 0.375s",
          }}
        />
      </div>
    </div>
  );
};

export default DottedProgressBar;
