import { Button } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AxiosErrorAlert from "@components/AxiosErrorAlert/AxiosErrorAlert";
import GenericModal from "@components/GenericModal/GenericModal";
import IXchange from "@interfaces/IXchange";
import BillsSelectionForm from "./BillsSelectionForm/BillsSelectionForm";

const BillsSelectionModal = ({
  show,
  xchange,
  onClose,
  onBillsSelected,
  isFinishLoading,
  axiosError,
}: {
  show: boolean;
  xchange?: IXchange;
  onClose: () => void;
  onBillsSelected: (bills: number[]) => void;
  isFinishLoading: boolean;
  axiosError?: any;
}) => {
  const { t } = useTranslation();

  const formMethods = useForm<{
    bills: number[];
  }>({
    defaultValues: {
      bills: [],
    },
  });

  const { getValues, setValue, watch } = formMethods;

  return (
    <GenericModal
      title={t("billing.invoice.generate")}
      show={show}
      onClose={onClose}
      onFinish={() => {
        onBillsSelected(getValues("bills"));
      }}
      finishText={`${t("actions.continue")} ->`}
      closeText={t("actions.cancel")}
      isFinishLoading={isFinishLoading}
      canBeFinished={watch("bills").length > 0 && !isFinishLoading}
      extraButtons={
        <>
          <Button
            variant="outline-light"
            onClick={() => {
              setValue("bills", []);
            }}
            disabled={watch("bills").length === 0 || isFinishLoading}
          >
            {t("actions.clear_all")}
          </Button>
        </>
      }
    >
      <h3 className="pb-6 pt-5 mb-2 ps-0">
        {t("billing.invoice.add_payables")}
      </h3>
      <FormProvider {...formMethods}>
        <BillsSelectionForm xchange={xchange} disabled={isFinishLoading} />
      </FormProvider>

      <AxiosErrorAlert response={axiosError} />
    </GenericModal>
  );
};

export default BillsSelectionModal;
