import ProductStatuses from "@enum/ProductStatuses";
import IPage, { IPageParams } from "@interfaces/IPage";
import ICatalogProduct, {
  ICatalogProductCreateUpdate,
} from "@interfaces/products/ICatalogProduct";
import Service from "@services/Service";

export interface IGetCatalogProductsParams {
  status?: ProductStatuses;
  sub_domains__in?: string;
  terms__in?: string;
  terms__gte?: number;
  search?: string;
  ordering?: string;
}

export default class CatalogProductService extends Service {
  public static async getCatalogProduct(id: number) {
    const response = await this.axios.get<ICatalogProduct>(`/products/${id}`);
    return response.data;
  }

  public static async getCatalogProducts(
    params?: IGetCatalogProductsParams & IPageParams
  ) {
    const response = await this.axios.get<IPage<ICatalogProduct>>("/products", {
      params,
    });
    return response.data;
  }

  public static async createCatalogProduct(
    catalogProduct: ICatalogProductCreateUpdate
  ): Promise<ICatalogProduct> {
    const response = await this.axios.post<ICatalogProduct>(
      "/products",
      catalogProduct
    );
    return response.data;
  }

  public static async updateCatalogProduct(
    id: number,
    catalogProduct: ICatalogProductCreateUpdate
  ): Promise<ICatalogProduct> {
    const response = await this.axios.patch<ICatalogProduct>(
      `/products/${id}`,
      catalogProduct
    );
    return response.data;
  }
}
