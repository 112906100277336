import { Badge, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./DocumentCard.scss";
import { getBrandingIconFromURL } from "@helpers/brands-utils";
import ActionsDropdown, {
  IActionsDropdownAction,
} from "@components/ListOptionsDropdown/ActionsDropdown";

export interface IDocumentCardButton {
  label: string;
  iconClasses: string;
  disabled?: boolean;
  isLoading?: boolean;
  onClick: () => void;
}

const DocumentCard = ({
  name,
  subName,
  url,
  info,
  type,
  buttons,
  actions,
  variant = "light",
}: {
  name: string;
  subName?: string;
  url?: string;
  info?: string;
  type?: string;
  buttons?: IDocumentCardButton[];
  actions?: IActionsDropdownAction[];
  variant?: "light" | "secondary";
}) => {
  const branding = url
    ? getBrandingIconFromURL(url)
    : getBrandingIconFromURL(name);

  const getIconNode = () => {
    if (branding) {
      if (branding.iconClasses) {
        return (
          <i
            className={`${branding?.iconClasses}`}
            style={
              branding?.color
                ? {
                    color: branding?.color,
                  }
                : {}
            }
          ></i>
        );
      } else if (branding.iconURL) {
        return <img src={branding.iconURL} alt={branding.name} />;
      }
    }

    return <i className="fa-regular fa-link"></i>;
  };

  return (
    <div
      className={`Document-Card ${
        variant === "secondary" && "Document-Card--secondary"
      }`}
    >
      <div className="Document-Card__Title-Wrapper">
        <div
          className="Document-Card__Icon"
          style={{
            backgroundColor: branding?.backgroundColor,
          }}
        >
          {getIconNode()}
        </div>

        <div className="Document-Card__Title-Wrapper__Title">
          {url ? (
            <a href={url} target="_blank" rel="noreferrer">
              {name}
            </a>
          ) : (
            name
          )}

          <div className="Document-Card__Title-Wrapper__Title__Sub-Name">
            {subName}
          </div>
        </div>
      </div>

      {type && (
        <div className="Document-Card__Type">
          <Badge bg="success" text="success">
            {type}
          </Badge>
        </div>
      )}

      {info && <div className="Document-Card__Info">{info}</div>}

      {buttons && (
        <div>
          {buttons.map((button, index) => (
            <OverlayTrigger
              key={`document-card_button_${index}`}
              overlay={<Tooltip>{button.label}</Tooltip>}
            >
              <Button
                variant="light"
                size="sm"
                onClick={button.onClick}
                disabled={button.disabled || button.isLoading}
                className="Document-Card__Button"
              >
                {button.isLoading ? (
                  <i className="fa-regular fa-spinner-third fa-spin" />
                ) : (
                  <i className={button.iconClasses} />
                )}
              </Button>
            </OverlayTrigger>
          ))}
        </div>
      )}

      {actions && (
        <div className="Document-Card__Actions">
          <ActionsDropdown actions={actions}>
            <i className="fa-regular fa-ellipsis"></i>
          </ActionsDropdown>
        </div>
      )}
    </div>
  );
};

export default DocumentCard;
