import { useTranslation } from "react-i18next";
import IXpert, { XpertStatus } from "@interfaces/IXpert";
import Documents from "@components/Documents/Documents";
import { Form } from "react-bootstrap";
import CustomSelect from "@components/CustomSelect/CustomSelect";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { useXpertUpdate } from "@hooks/xpert/useXpertUpdate";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";

const XpertDetailsForm = ({ xpert }: { xpert: IXpert }) => {
  const { t } = useTranslation();

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<{
    status: XpertStatus;
  }>();
  useEffect(() => {
    setValue("status", xpert?.status!);
  }, [xpert?.status, setValue]);

  const updateXpertMutation = useXpertUpdate();
  useEffect(() => {
    const subscription = watch((data) => {
      updateXpertMutation.mutate({
        updatedXpert: {
          id: xpert?.id!,
          ...data,
        },
      });
    });

    return () => subscription.unsubscribe();
  }, [xpert?.id, updateXpertMutation, watch]);

  return (
    <>
      <div className="ps-4 pe-4 pb-4">
        <Form.Group className="mb-5">
          <Form.Label>{t("companies.overview.status")}</Form.Label>
          <CustomSelect
            name="status"
            control={control}
            options={Object.values(XpertStatus).map((status) => ({
              value: status,
              label: t(`form.xperts_edit.statuses.${status}`),
            }))}
            isDisabled={!xpert}
          />
          <FieldErrorMessage
            field={errors.status}
            fieldName={t("companies.overview.status")}
          />
        </Form.Group>

        <div className="fw-bold mb-5">{t("companies.documents.title")}</div>
        <Documents xpert={xpert} />
      </div>
    </>
  );
};

export default XpertDetailsForm;
