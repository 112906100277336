import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IPartyWrite } from "@interfaces/IParty";
import PartyService from "@services/PartyService";

interface IMutationVars {
  party: IPartyWrite;
}

export const usePartyCreate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ["createParty"],
    ({ party }: IMutationVars) => PartyService.createParty(party),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["parties"]);
        queryClient.invalidateQueries(["contracts"]);
      },
    }
  );
};
