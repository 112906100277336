import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CardPlaceholder from "@components/CardPlaceholder/CardPlaceholder";
import IPerson from "@interfaces/IPerson";
import MemberRow from "./MemberRow/MemberRow";

const MembersTable = ({
  members,
  onEdit = () => {},
  onDelete = () => {},
}: {
  members?: { person: IPerson; isPrimary?: boolean; isFinance?: boolean }[];
  onEdit?: (person: IPerson) => void;
  onDelete?: (person: IPerson) => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {!members?.length ? (
        <CardPlaceholder
          iconClassName={"fa-solid fa-rectangle-vertical-history"}
          title={t("companies.details.overview.people.empty")}
          description={t("companies.details.overview.people.empty_desc")}
        />
      ) : (
        <Table>
          <thead>
            <tr>
              <th>{t("companies.details.overview.people.role")}</th>
              <th>{t("companies.details.overview.people.name")}</th>
              <th>{t("companies.details.overview.people.email")}</th>
              <th>{t("companies.details.overview.people.rate")}</th>
              <th className="table-actions"></th>
            </tr>
          </thead>
          <tbody>
            {members?.map((member) => (
              <MemberRow
                key={member.person.id}
                member={member.person}
                isPrimary={member.isPrimary}
                isFinance={member.isFinance}
                onEdit={() => onEdit(member.person)}
                onDelete={() => onDelete(member.person)}
              ></MemberRow>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default MembersTable;
