import { Button, Form, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import {
  XpertOnboardingCommitmentStepFields,
  XpertOnboardingCommitmentStepValidationSchema,
} from "@pages/Onboardings/XpertOnboardingPage/XpertOnboardingConfigs";
import IconCard from "@components/IconCard/IconCard";
import "./CommitmentStep.scss";
import AlertWithIcon from "@components/AlertWithIcon/AlertWithIcon";

const CommitmentStep = ({
  back,
  submit,
  isSubmitting,
}: {
  back: () => void;
  submit: () => void;
  isSubmitting: boolean;
}) => {
  const { t } = useTranslation();

  const {
    watch,
    getValues,
    trigger,
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="Commitment-Step fade-in">
      <h3 className="mb-2">{t("onboardings.xpert.commitment.title")}</h3>
      <p>{t("onboardings.xpert.commitment.description")}</p>

      <IconCard
        iconClasses="fa-regular fa-seedling"
        iconColor="#993B79"
        title={t("onboardings.xpert.commitment.growth")}
        className="mb-2"
      >
        {t("onboardings.xpert.commitment.growth_description")}
      </IconCard>

      <IconCard
        iconClasses="fa-regular fa-chart-network"
        iconColor="#009990"
        title={t("onboardings.xpert.commitment.adaptability")}
        className="mb-2"
      >
        {t("onboardings.xpert.commitment.adaptability_description")}
      </IconCard>

      <IconCard
        iconClasses="fa-regular fa-handshake"
        iconColor="#AE8804"
        title={t("onboardings.xpert.commitment.generosity")}
        className="mb-2"
      >
        {t("onboardings.xpert.commitment.generosity_description")}
      </IconCard>

      <IconCard
        iconClasses="fa-regular fa-crown"
        iconColor="#430099"
        title={t("onboardings.xpert.commitment.ownership")}
        className="mb-2"
      >
        {t("onboardings.xpert.commitment.ownership_description")}
      </IconCard>

      <div className="mt-6 mb-6">
        <Stack direction="horizontal" gap={2}>
          <Form.Check
            id="agree_to_commitments"
            type="checkbox"
            {...register("agree_to_commitments")}
            isInvalid={!!errors.agree_to_commitments}
          />
          <label htmlFor="agree_to_commitments">
            {t("onboardings.xpert.commitment.agreement")}
          </label>
        </Stack>
      </div>

      <Stack direction="horizontal" gap={2} className="mt-5 mb-5">
        <Button variant="outline-light" className="w-100" onClick={back}>
          {t("wizard.back")}
        </Button>
        <Button
          className="w-100"
          disabled={isSubmitting || watch("agree_to_commitments") !== true}
          onClick={() => {
            trigger(XpertOnboardingCommitmentStepFields);
            const { error } =
              XpertOnboardingCommitmentStepValidationSchema.validate(
                getValues(),
                {
                  allowUnknown: true,
                }
              );

            if (!error) {
              submit();
            }
          }}
        >
          {isSubmitting ? (
            <i className="fa-regular fa-spinner-third fa-spin" />
          ) : (
            t("actions.submit")
          )}
        </Button>
      </Stack>

      {!!errors.agree_to_commitments && (
        <AlertWithIcon
          variant="danger"
          iconClasses="fa-solid fa-triangle-exclamation"
        >
          {t("onboardings.xpert.commitment.agreement_required")}
        </AlertWithIcon>
      )}
    </div>
  );
};

export default CommitmentStep;
