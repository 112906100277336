import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ActionsDropdown from "@components/ListOptionsDropdown/ActionsDropdown";
import CostRange from "@components/ProductCard/CostRange/CostRange";
import { toDurationRangeString } from "@helpers/date-utils";
import ICatalogTerm from "@interfaces/terms/ICatalogTerm";
import "./ProductTermRow.scss";

type Props = {
  term: ICatalogTerm;
  onRemove?: () => void;
};

const ProductTermRow = ({ term, onRemove }: Props) => {
  const { t } = useTranslation();

  return (
    <tr key={term.id} className="Product-Term-Row">
      <td className="Product-Term-Row__Icon text-center align-middle">
        <i className="fa-regular fa-circle" />
      </td>
      <td>
        <div className="Product-Term-Row__Name" title={term.title}>
          {term.title}
        </div>
      </td>
      <td>{term.sub_domain?.name}</td>
      <td>
        {term.cost_ranges?.map((costRange, index) => (
          <CostRange
            key={`${index}-${costRange.currency}`}
            costRange={costRange}
            className={
              term.cost_ranges?.length && index !== term.cost_ranges.length - 1
                ? "mb-1"
                : ""
            }
          />
        ))}
      </td>
      <td>
        {term.duration_range?.min && toDurationRangeString(term.duration_range)}
      </td>
      <td className="table-actions">
        <ActionsDropdown
          actions={[
            {
              label: t("product.details.terms.remove_from_product"),
              onClick: () => {
                onRemove?.();
              },
            },
          ]}
        >
          <Button variant="outline-light" className="p-2 pt-1 pb-1">
            <i className="fa-regular fa-ellipsis-vertical"></i>
          </Button>
        </ActionsDropdown>
      </td>
    </tr>
  );
};

export default ProductTermRow;
