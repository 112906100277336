import StatBanner from "@components/StatBanner/StatBanner";
import "./XchangeStats.scss";
import IXchange from "@interfaces/IXchange";
import { useTranslation } from "react-i18next";
import { useEpics } from "@hooks/xchange/useEpics";
import { formatDateToString } from "@helpers/date-utils";
import {
  calcXchangeTotalBudget,
  calcXchangeTotalRelativeSpending,
  getXchangeCurrenciesString,
  getXchangeGoalEndDateStat,
} from "@helpers/xchange-utils";
import { toCurrencyString } from "@helpers/currencyUtils";
import { Badge } from "react-bootstrap";
import { useMemo } from "react";
import { useXchangeBillsSummary } from "@hooks/xchange/useXchangeBillsSummary";

const XchangeStats = ({
  xchange,
  className,
}: {
  xchange?: IXchange;
  className?: string;
}) => {
  const { t } = useTranslation();

  const { data: epics } = useEpics(xchange?.id!, {
    enabled: !!xchange && !!xchange.id,
  });

  const xchangeGoalEndDateStats = useMemo(() => {
    if (xchange) {
      return getXchangeGoalEndDateStat(xchange);
    }
  }, [xchange]);

  const { data: spendings } = useXchangeBillsSummary(xchange?.id!, {
    enabled: !!xchange && !!xchange.id,
  });
  const xchangeSpendingStats = useMemo(() => {
    if (xchange && spendings) {
      return calcXchangeTotalRelativeSpending(xchange, spendings);
    }
  }, [spendings, xchange]);

  const xchangeTotalBudget = useMemo(() => {
    return xchange ? calcXchangeTotalBudget(xchange) : 0;
  }, [xchange]);

  return (
    <div className={`Xchange-Stats ${className}`}>
      <StatBanner
        label={t("form.xchange_edit.form_labels.epic_count")}
        value={`${epics?.length || "-"}`}
      />
      <StatBanner
        label={t("form.xchange_edit.form_labels.start_date")}
        value={
          xchange?.start_date
            ? formatDateToString(new Date(xchange.start_date), {
                dateStyle: "medium",
              })
            : "-"
        }
      />
      <StatBanner
        label={t("form.xchange_edit.form_labels.end_date_goal")}
        value={
          xchange?.goal_end_date
            ? formatDateToString(new Date(xchange.goal_end_date), {
                dateStyle: "medium",
              })
            : "-"
        }
      >
        {xchangeGoalEndDateStats && (
          <Badge
            bg={xchangeGoalEndDateStats.isLate ? "danger" : "success"}
            text={xchangeGoalEndDateStats.isLate ? "danger" : "success"}
          >
            <i
              className={`fa-solid ${
                xchangeGoalEndDateStats.isLate
                  ? "fa-calendar-exclamation"
                  : "fa-calendar"
              } me-1`}
            />
            {`${Math.abs(xchangeGoalEndDateStats.days)} `}
            {t("durations.days", { count: xchangeGoalEndDateStats.days })}
          </Badge>
        )}
      </StatBanner>
      <StatBanner
        label={`${t("form.xchange_edit.form_labels.budget")} ${
          xchange && getXchangeCurrenciesString(xchange)
            ? `(${getXchangeCurrenciesString(xchange)})`
            : ""
        }`}
        value={
          xchange && xchangeTotalBudget > 0
            ? toCurrencyString(xchangeTotalBudget)
            : "-"
        }
      >
        {xchangeSpendingStats && (
          <Badge
            bg={
              xchangeSpendingStats.isOver
                ? "danger"
                : xchangeSpendingStats.percentage === 0
                ? "light"
                : "success"
            }
            text={
              xchangeSpendingStats.isOver
                ? "danger"
                : xchangeSpendingStats.percentage === 0
                ? "light"
                : "success"
            }
          >
            <i
              className={`fa-regular ${
                xchangeSpendingStats.isOver
                  ? "fa-arrow-up"
                  : xchangeSpendingStats.percentage === 0
                  ? "fa-dash"
                  : "fa-arrow-down"
              } me-1`}
            />
            {Math.abs(xchangeSpendingStats.percentage)}%
          </Badge>
        )}
      </StatBanner>
    </div>
  );
};

export default XchangeStats;
