import CardPlaceholder from "@components/CardPlaceholder/CardPlaceholder";
import GenericModal from "@components/GenericModal/GenericModal";
import { useXpertTermDelete } from "@hooks/term/useXpertTermDelete";
import { useXpertTerms } from "@hooks/term/useXpertTerms";
import ICatalogTerm from "@interfaces/terms/ICatalogTerm";
import IXpertTerm from "@interfaces/terms/IXpertTerm";
import XpertModal from "@pages/Term/TermEditPage/XpertTable/XpertModal/XpertModal";
import XpertTableRow from "@pages/Term/TermEditPage/XpertTable/XpertTableRow/XpertTableRow";
import { useState } from "react";
import { Button, Stack, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const XpertTable = ({ catalogTerm }: { catalogTerm?: ICatalogTerm }) => {
  const { t } = useTranslation();

  const { data: xpertTerms } = useXpertTerms(
    {
      catalog_term: catalogTerm?.id!.toString(),
    },
    {
      enabled: catalogTerm !== undefined,
    }
  );

  const [showDeleteXpertTermModal, setShowDeleteXpertTermModal] =
    useState(false);
  const [xpertTermToDelete, setXpertTermToDelete] = useState<IXpertTerm>();
  const deleteXpertTermMutation = useXpertTermDelete();

  const [showXpertModal, setShowXpertModal] = useState(false);
  const [xpertTermToEdit, setXpertTermToEdit] = useState<IXpertTerm>();

  return (
    <div className="ps-4 pe-4">
      <Stack direction="horizontal" className="mb-5">
        <h5 className="mb-0">{t("term.details.xperts.table.title")}</h5>

        <Button
          className="ms-auto"
          size="sm"
          onClick={() => {
            setXpertTermToEdit(undefined);
            setShowXpertModal(true);
          }}
          disabled={!catalogTerm}
        >
          <i className="fa-regular fa-plus me-2" />
          {t("term.details.xperts.add.button")}
        </Button>
      </Stack>

      {xpertTerms && xpertTerms.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <th>{t("term.details.xperts.table.xpert")}</th>
              <th>{t("term.details.xperts.table.duration")}</th>
              <th>{t("term.details.xperts.table.cost")}</th>
              <th className="table-actions"></th>
            </tr>
          </thead>

          <tbody>
            {xpertTerms?.map((xpertTerm) => (
              <XpertTableRow
                key={xpertTerm.id}
                xpertTerm={xpertTerm}
                onEdit={() => {
                  setXpertTermToEdit(xpertTerm);
                  setShowXpertModal(true);
                }}
                onDelete={(xpertTerm) => {
                  setXpertTermToDelete(xpertTerm);
                  setShowDeleteXpertTermModal(true);
                }}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <CardPlaceholder
          iconClassName={"fa-solid fa-rectangle-vertical-history"}
          title={t("term.details.xperts.empty")}
          description={t("term.details.xperts.empty_desc")}
        />
      )}

      {catalogTerm && (
        <XpertModal
          show={showXpertModal}
          catalogTerm={catalogTerm}
          xpertTermToEdit={xpertTermToEdit}
          onClose={() => {
            setShowXpertModal(false);
          }}
          onFinish={() => {
            setShowXpertModal(false);
          }}
        />
      )}

      <GenericModal
        title={t("term.details.xperts.delete.title")}
        show={showDeleteXpertTermModal}
        onClose={() => {
          setShowDeleteXpertTermModal(false);
        }}
        onFinish={() => {
          deleteXpertTermMutation.mutate({
            xpertId: xpertTermToDelete!.xpert_involved!,
            xpertTermId: xpertTermToDelete!.id!,
          });
          setShowDeleteXpertTermModal(false);
          setXpertTermToDelete(undefined);
        }}
        closeText={t("actions.cancel")}
        finishText={t("term.details.xperts.delete.button")}
      >
        <h2 className="mb-6">{t("term.details.xperts.delete.subtitle")}</h2>
        {t("term.details.xperts.delete.help")}
      </GenericModal>
    </div>
  );
};

export default XpertTable;
