import { useMutation, useQueryClient } from "@tanstack/react-query";
import IRole from "@interfaces/IRole";
import RoleService from "@services/RoleService";

export const useRoleCreate = () => {
  const queryClient = useQueryClient();
  return useMutation((role: IRole) => RoleService.createRole(role), {
    onSuccess: () => {
      queryClient.invalidateQueries(["roles"]);
    },
  });
};
