import {
  InfiniteData,
  UseQueryOptions,
  useInfiniteQuery,
} from "@tanstack/react-query";
import IPage, { IPageParams } from "@interfaces/IPage";
import BillService, { IGetBillsParams } from "@services/BillService";
import IBill from "@interfaces/IBill";

export const useBills = (
  params?: IGetBillsParams & IPageParams,
  options?: Pick<
    UseQueryOptions<InfiniteData<IPage<IBill>>, Error>,
    "enabled" | "onSuccess"
  > & {
    refetchOnWindowFocus?: boolean;
  }
) =>
  useInfiniteQuery(
    ["bills", { params }],
    (ctx) => {
      return BillService.getBills({
        page: ctx.pageParam ? ctx.pageParam : undefined,
        ...params,
      });
    },
    {
      getPreviousPageParam: (firstGroup) => firstGroup.previous,
      getNextPageParam: (lastGroup) => lastGroup.next,
      ...options,
    }
  );
