import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./PersonStep.scss";
import InfoSnippet from "@components/InfoSnippet/InfoSnippet";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import { useForm, useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  XpertOnboardingPersonStepFields,
  XpertOnboardingPersonStepFormValues,
  XpertOnboardingPersonStepValidationSchema,
} from "@pages/Onboardings/XpertOnboardingPage/XpertOnboardingConfigs";
import { joiResolver } from "@hookform/resolvers/joi";
import { useCallback, useEffect } from "react";
import { debounce } from "lodash";

const PersonStep = ({ back, next }: { back: () => void; next: () => void }) => {
  const { t } = useTranslation();

  const {
    trigger,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  // This step instanciate its own form, to be able to run external validations
  // without requirement the main form to be completely valid.
  const {
    watch: watchLocal,
    register: registerLocal,
    handleSubmit: handleLocalSubmit,
    setValue: setValueLocal,
    trigger: triggerLocal,
    formState: {
      errors: localErrors,
      isDirty: isDirtyLocal,
      submitCount: submitCountLocal,
    },
  } = useForm<XpertOnboardingPersonStepFormValues>({
    mode: "onChange",
    resolver: joiResolver(XpertOnboardingPersonStepValidationSchema),
    defaultValues: XpertOnboardingPersonStepFields.reduce(
      (
        acc: {
          [key: string]: any;
        },
        key
      ) => {
        acc[key] = getValues(key);
        return acc;
      },
      {}
    ),
  });

  const debouncedTriggerLocal = debounce(
    (field: keyof XpertOnboardingPersonStepFormValues) => {
      triggerLocal(field);
    },
    500
  );

  const getFieldError = (field: keyof XpertOnboardingPersonStepFormValues) => {
    return submitCountLocal > 0 || isDirtyLocal
      ? localErrors[field]
      : errors[field];
  };

  const setMainFormValues = useCallback(
    (data: XpertOnboardingPersonStepFormValues) => {
      Object.keys(data).forEach((key) => {
        setValue(
          key as keyof XpertOnboardingPersonStepFormValues,
          data[key as keyof XpertOnboardingPersonStepFormValues]
        );
      });
    },
    [setValue]
  );

  useEffect(() => {
    const subscription = watchLocal((data) => {
      setMainFormValues(data);
    });
    return () => subscription.unsubscribe();
  }, [setMainFormValues, trigger, watchLocal]);

  return (
    <div className="Person-Step fade-in">
      <h3 className="mb-2">{t("onboardings.xpert.person.title")}</h3>
      <p>{t("onboardings.xpert.person.description")}</p>

      <InfoSnippet iconClasses="fa-regular fa-address-book">
        <b>{t("onboardings.xpert.person.disclamer_part_one")}</b>
        {t("onboardings.xpert.person.disclamer_part_two")}
      </InfoSnippet>

      <Row>
        <Form.Group className="mb-4" as={Col} xs={12} xl={6}>
          <Form.Label>
            {t("onboardings.xpert.person.fields.first_name")}
          </Form.Label>
          <Form.Control
            type="text"
            {...registerLocal("first_name")}
            isInvalid={!!getFieldError("first_name")}
          />
          <FieldErrorMessage
            field={getFieldError("first_name")}
            fieldName={t("onboardings.xpert.person.fields.first_name")}
          />
        </Form.Group>

        <Form.Group className="mb-4" as={Col} xs={12} xl={6}>
          <Form.Label>
            {t("onboardings.xpert.person.fields.last_name")}
          </Form.Label>
          <Form.Control
            type="text"
            {...registerLocal("last_name")}
            isInvalid={!!getFieldError("last_name")}
          />
          <FieldErrorMessage
            field={getFieldError("last_name")}
            fieldName={t("onboardings.xpert.person.fields.last_name")}
          />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group className="mb-4" as={Col} xs={12}>
          <Form.Label>{t("onboardings.xpert.person.fields.title")}</Form.Label>
          <Form.Control
            type="text"
            {...registerLocal("title")}
            isInvalid={!!getFieldError("title")}
          />
          <FieldErrorMessage
            field={getFieldError("title")}
            fieldName={t("onboardings.xpert.person.fields.title")}
          />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group className="mb-4" as={Col} xs={12}>
          <Form.Label>{t("onboardings.xpert.person.fields.email")}</Form.Label>
          <Form.Control
            type="text"
            {...registerLocal("email")}
            isInvalid={!!getFieldError("email")}
            onChange={(e) => {
              setValueLocal("email", e.target.value, {
                shouldDirty: true,
              });
              debouncedTriggerLocal("email");
            }}
          />
          <FieldErrorMessage
            field={getFieldError("email")}
            fieldName={t("onboardings.xpert.person.fields.email")}
          />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group className="mt-2 mb-4" as={Col} xs={12}>
          <Stack direction="horizontal" gap={2}>
            <Form.Check
              id="email_consent"
              type="checkbox"
              {...registerLocal("email_consent")}
              isInvalid={!!getFieldError("email_consent")}
            />
            <label htmlFor="email_consent">
              {t("onboardings.xpert.person.fields.email_consent")}
            </label>
            <Link to="/">
              {t("onboardings.xpert.person.fields.email_consent_help")}
            </Link>
          </Stack>
          <FieldErrorMessage
            field={getFieldError("email_consent")}
            fieldName={t("onboardings.xpert.person.fields.email_consent_short")}
          />
        </Form.Group>
      </Row>

      <Stack direction="horizontal" gap={2} className="mt-5">
        <Button variant="outline-light" className="w-100" onClick={back}>
          {t("wizard.back")}
        </Button>
        <Button
          className="w-100"
          onClick={() => {
            handleLocalSubmit((data) => {
              setMainFormValues(data);
              next();
            })();
          }}
        >
          {t("wizard.next")}
        </Button>
      </Stack>
    </div>
  );
};

export default PersonStep;
