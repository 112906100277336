import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ActionsDropdown from "@components/ListOptionsDropdown/ActionsDropdown";
import { toCurrencyString } from "@helpers/currencyUtils";
import { formatDateToString } from "@helpers/date-utils";
import IXchangeTerm from "@interfaces/terms/IXchangeTerm";
import "./TermRow.scss";

type Props = {
  term: IXchangeTerm;
  isProductTerm?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
  isLockedByEpics?: boolean;
  infoText?: string;
};

const TermRow = ({
  term,
  isProductTerm = false,
  onEdit,
  onDelete,
  isLockedByEpics,
}: Props) => {
  const { t } = useTranslation();

  return (
    <tr key={term.id} className={`Term-Row ${isProductTerm ? "indented" : ""}`}>
      <td className="Term-Row__Icon text-center align-middle">
        <i className="fa-regular fa-circle" />
      </td>
      <td className={`${isProductTerm ? "ps-8" : "ps-7"} `}>
        <div className="Term-Row__Name" title={term.title}>
          {term.title}
        </div>
        <div
          className="Term-Row__Label fw-light"
          title={term.xpert_involved_name}
        >
          {term.xpert_involved_name}
        </div>
      </td>
      <td className={`${isProductTerm ? "ps-6" : ""}`}>
        {term.sub_domain?.name}
      </td>
      <td className={`${isProductTerm ? "ps-6" : ""}`}>
        <span>{term.currency + " "}</span>
        {toCurrencyString(term.cost_per_sprint! * term.multiplier!)}
      </td>
      <td className={`${isProductTerm ? "ps-6" : ""}`}>
        {term.duration! * term.multiplier!}
        {` ${t("durations.weeks", {
          count: term.duration! * term.multiplier!,
        })}`}
      </td>
      <td className={`${isProductTerm ? "ps-6" : ""}`}>
        {term.start_date
          ? formatDateToString(
              new Date(term.start_date),
              {
                dateStyle: "short",
              },
              "en-CA"
            )
          : ""}
      </td>
      <td className="table-actions">
        {!isProductTerm && (
          <ActionsDropdown
            actions={[
              {
                label: t("actions.edit"),
                onClick: () => {
                  onEdit?.();
                },
              },
              {
                label: t("actions.delete"),
                onClick: () => {
                  onDelete?.();
                },
                disabled: isLockedByEpics,
                infoText: isLockedByEpics
                  ? t("form.deliverables.info.term_epic_locked")
                  : undefined,
              },
            ]}
          >
            <Button variant="outline-light" className="p-2 pt-1 pb-1">
              <i className="fa-regular fa-ellipsis-vertical"></i>
            </Button>
          </ActionsDropdown>
        )}
      </td>
    </tr>
  );
};

export default TermRow;
