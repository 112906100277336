import { useState } from "react";
import { Button, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDocuments } from "@hooks/document/useDocuments";
import IClient from "@interfaces/IClient";
import IXpert from "@interfaces/IXpert";
import IXchange from "@interfaces/IXchange";
import DocumentList from "./DocumentList/DocumentList";
import UploadDocumentModal from "./UploadDocumentModal/UploadDocumentModal";
import "./Documents.scss";

const Documents = ({
  xpert,
  client,
  xchange,
}: {
  xpert?: IXpert;
  client?: IClient;
  xchange?: IXchange;
}) => {
  const { t } = useTranslation();

  const { data: documents } = useDocuments(
    {
      client: client?.id,
      xpert: xpert?.id,
      xchange: xchange?.id,
    },
    {
      enabled: !!(client || xpert || xchange),
    }
  );
  const [showUploadModal, setShowUploadModal] = useState(false);

  return (
    <>
      <Stack direction="horizontal">
        <div className="Documents__Google-State">
          {client?.folder_id || xpert?.folder_id || xchange?.folder_id ? (
            <>
              <i className="fa-solid fa-circle-check me-2 text-success-default"></i>
              <a
                href={`https://drive.google.com/drive/folders/${
                  client?.folder_id || xpert?.folder_id || xchange?.folder_id
                }`}
                target="_blank"
                rel="noreferrer"
              >
                {t("companies.documents.drive_connected")}
              </a>
            </>
          ) : (
            <>
              <i className="fa-solid fa-triangle-exclamation me-2 text-warning-default"></i>
              {t("companies.documents.drive_not_connected")}
            </>
          )}
        </div>
        <Button className="ms-auto" onClick={() => setShowUploadModal(true)}>
          <i className="fa-regular fa-cloud-arrow-up me-2"></i>
          {t("companies.documents.upload")}
        </Button>
      </Stack>
      <DocumentList documents={documents || []} />
      <UploadDocumentModal
        client={client}
        xpert={xpert}
        xchange={xchange}
        show={showUploadModal}
        onClose={() => setShowUploadModal(false)}
      />
    </>
  );
};

export default Documents;
