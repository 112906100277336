import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IXpertTermCreateUpdate } from "@interfaces/terms/IXpertTerm";
import XpertTermService from "@services/XpertTermService";

interface IMutationVars {
  xpertTerm: IXpertTermCreateUpdate;
}

export const useXpertTermUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ xpertTerm }: IMutationVars) =>
      XpertTermService.updateXpertTerm(xpertTerm),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["xpert-terms"]);
      },
    }
  );
};
