import { FormControl } from "react-bootstrap";
import { Control, useController } from "react-hook-form";
import { InputAttributes, NumericFormat } from "react-number-format";

const FormControlWithProps = (props: InputAttributes) => {
  return <FormControl {...props} size={undefined} />;
};

const CurrencyFormControl = (
  props: {
    control: Control<any, any>;
    name: string;
  } & InputAttributes
) => {
  const { field } = useController({
    name: props.name,
    control: props.control,
  });

  return (
    <NumericFormat
      value={field.value}
      customInput={FormControlWithProps}
      onValueChange={(value) => {
        field.onChange(value.floatValue ?? 0);
      }}
      decimalScale={2}
      prefix={"$"}
      thousandsGroupStyle="thousand"
      thousandSeparator=","
      {...{ ...props, type: "text" }}
    />
  );
};

export default CurrencyFormControl;
