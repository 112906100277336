import { Button, FormControl, Stack, Table } from "react-bootstrap";
import ActionsDropdown from "@components/ListOptionsDropdown/ActionsDropdown";
import "./InvoiceBillLineItem.scss";
import { useFormContext } from "react-hook-form";
import CustomSelect from "@components/CustomSelect/CustomSelect";
import { useTranslation } from "react-i18next";
import CurrencyFormControl from "@components/CurrencyFormControl/CurrencyFormControl";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import GenericModal from "@components/GenericModal/GenericModal";
import { useState } from "react";
import { InvoiceFormValues } from "@pages/Xchange/XchangeInvoicePage/XchangeInvoicePage";
import PercentageFormControl from "@components/PercentageFormControl/PercentageFormControl";

const InvoiceBillLineItem = ({
  billIndex,
  lineItemIndex,
  onInvoiceChange,
  onRemove,
  disabled = false,
}: {
  billIndex: number;
  lineItemIndex: number;
  onInvoiceChange: (data: InvoiceFormValues) => void;
  onRemove: () => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();

  const extraTypes = [
    { label: "%", value: "percentage" },
    { label: "$", value: "fixed" },
  ];

  const {
    control,
    setValue,
    getValues,
    watch,
    register,
    formState: { errors },
    handleSubmit,
  } = useFormContext<InvoiceFormValues>();

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);

  return (
    <>
      <Table className="Invoice-Bill-Line-Item">
        <tbody>
          <tr>
            <td>
              <FormControl
                as="textarea"
                {...register(
                  `bills.${billIndex}.line_items.${lineItemIndex}.description`
                )}
                isInvalid={
                  !!errors.bills?.[billIndex]?.line_items?.[lineItemIndex]
                    ?.description
                }
                disabled={disabled}
              />
              <FieldErrorMessage
                field={
                  errors.bills?.[billIndex]?.line_items?.[lineItemIndex]
                    ?.description
                }
                fieldName={t("billing.invoice.line_item.description")}
              />
            </td>
            <td className="snug">
              <FormControl
                className="Invoice-Bill-Line-Item__Quantity"
                {...register(
                  `bills.${billIndex}.line_items.${lineItemIndex}.quantity`
                )}
                isInvalid={
                  !!errors.bills?.[billIndex]?.line_items?.[lineItemIndex]
                    ?.quantity
                }
                disabled={disabled}
              />
              <FieldErrorMessage
                field={
                  errors.bills?.[billIndex]?.line_items?.[lineItemIndex]
                    ?.quantity
                }
                fieldName={t("billing.invoice.line_item.quantity")}
              />
            </td>
            <td className="snug">
              <CurrencyFormControl
                name={`bills.${billIndex}.line_items.${lineItemIndex}.rate`}
                control={control}
                className={`Invoice-Bill-Line-Item__Rate ${
                  errors.bills?.[billIndex]?.line_items?.[lineItemIndex]
                    ?.rate && "is-invalid"
                }`}
                disabled={disabled}
              />
              <FieldErrorMessage
                field={
                  errors.bills?.[billIndex]?.line_items?.[lineItemIndex]?.rate
                }
                fieldName={t("billing.invoice.line_item.rate")}
              />
            </td>
            <td className="snug">
              <CurrencyFormControl
                name={`bills.${billIndex}.line_items.${lineItemIndex}.total`}
                control={control}
                className={`Invoice-Bill-Line-Item__Total ${
                  errors.bills?.[billIndex]?.line_items?.[lineItemIndex]
                    ?.total && "is-invalid"
                }`}
                readOnly
                disabled={disabled}
              />
              <FieldErrorMessage
                field={
                  errors.bills?.[billIndex]?.line_items?.[lineItemIndex]?.total
                }
                fieldName={t("billing.invoice.line_item.total")}
              />
            </td>
            <td className="snug">
              <ActionsDropdown
                actions={[
                  {
                    label: t("billing.invoice.line_item.markup"),
                    onClick: () => {
                      setValue(
                        `bills.${billIndex}.line_items.${lineItemIndex}.has_markup`,
                        getValues(
                          `bills.${billIndex}.line_items.${lineItemIndex}.has_markup`
                        )
                          ? false
                          : true
                      );
                      handleSubmit(onInvoiceChange)();
                    },
                    isToggle: true,
                    isSelected: getValues(
                      `bills.${billIndex}.line_items.${lineItemIndex}.has_markup`
                    ),
                    disabled: disabled,
                  },
                  {
                    label: t("billing.invoice.line_item.discount"),
                    onClick: () => {
                      setValue(
                        `bills.${billIndex}.line_items.${lineItemIndex}.has_discount`,
                        getValues(
                          `bills.${billIndex}.line_items.${lineItemIndex}.has_discount`
                        )
                          ? false
                          : true
                      );
                      handleSubmit(onInvoiceChange)();
                    },
                    isToggle: true,
                    isSelected: getValues(
                      `bills.${billIndex}.line_items.${lineItemIndex}.has_discount`
                    ),
                    disabled: disabled,
                  },
                  {
                    label: t("billing.invoice.line_item.delete"),
                    onClick: () => {
                      setShowDeleteConfirmationModal(true);
                    },
                    disabled: disabled,
                  },
                ]}
              >
                <Button
                  variant="outline-light"
                  className="p-3 pt-1 pb-1"
                  disabled={disabled}
                >
                  <i className="fa-regular fa-ellipsis-vertical"></i>
                </Button>
              </ActionsDropdown>
            </td>
          </tr>
        </tbody>
      </Table>
      {watch(`bills.${billIndex}.line_items.${lineItemIndex}.has_markup`) ||
      watch(`bills.${billIndex}.line_items.${lineItemIndex}.has_discount`) ? (
        <div className="Invoice-Bill-Line-Item__Extra">
          {getValues(
            `bills.${billIndex}.line_items.${lineItemIndex}.has_markup`
          ) && (
            <Stack direction="horizontal" className="align-items-start">
              <div className="mt-2">
                {t("billing.invoice.line_item.markup")}
              </div>

              <div className="ms-auto me-2">
                <CustomSelect
                  className="Invoice-Bill-Line-Item__Extra__Type "
                  control={control}
                  name={`bills.${billIndex}.line_items.${lineItemIndex}.markup_type`}
                  options={extraTypes}
                  onAfterChange={() => {
                    handleSubmit(onInvoiceChange)();
                  }}
                  isDisabled={disabled}
                />
              </div>

              <div>
                {watch(
                  `bills.${billIndex}.line_items.${lineItemIndex}.markup_type`
                ) === "fixed" ? (
                  <CurrencyFormControl
                    name={`bills.${billIndex}.line_items.${lineItemIndex}.markup`}
                    control={control}
                    className={`Invoice-Bill-Line-Item__Extra__Value ${
                      errors.bills?.[billIndex]?.line_items?.[lineItemIndex]
                        ?.markup && "is-invalid"
                    }`}
                    disabled={disabled}
                  />
                ) : (
                  <PercentageFormControl
                    name={`bills.${billIndex}.line_items.${lineItemIndex}.markup`}
                    control={control}
                    className={`Invoice-Bill-Line-Item__Extra__Value ${
                      errors.bills?.[billIndex]?.line_items?.[lineItemIndex]
                        ?.markup && "is-invalid"
                    }`}
                    disabled={disabled}
                  />
                )}
                <FieldErrorMessage
                  field={
                    errors.bills?.[billIndex]?.line_items?.[lineItemIndex]
                      ?.markup
                  }
                  fieldName={t("billing.invoice.line_item.markup")}
                  className="Invoice-Bill-Line-Item__Extra__Value__Error"
                />
              </div>
            </Stack>
          )}
          {getValues(
            `bills.${billIndex}.line_items.${lineItemIndex}.has_discount`
          ) && (
            <Stack direction="horizontal" className="align-items-start">
              <div className="mt-2">
                {t("billing.invoice.line_item.discount")}
              </div>

              <div className="ms-auto me-2">
                <CustomSelect
                  className="Invoice-Bill-Line-Item__Extra__Type ms-auto"
                  control={control}
                  name={`bills.${billIndex}.line_items.${lineItemIndex}.discount_type`}
                  options={extraTypes}
                  onAfterChange={() => {
                    handleSubmit(onInvoiceChange)();
                  }}
                  isDisabled={disabled}
                />
              </div>

              <div>
                {watch(
                  `bills.${billIndex}.line_items.${lineItemIndex}.discount_type`
                ) === "fixed" ? (
                  <CurrencyFormControl
                    name={`bills.${billIndex}.line_items.${lineItemIndex}.discount`}
                    control={control}
                    className={`Invoice-Bill-Line-Item__Extra__Value ${
                      errors.bills?.[billIndex]?.line_items?.[lineItemIndex]
                        ?.discount && "is-invalid"
                    }`}
                    disabled={disabled}
                  />
                ) : (
                  <PercentageFormControl
                    name={`bills.${billIndex}.line_items.${lineItemIndex}.discount`}
                    control={control}
                    className={`Invoice-Bill-Line-Item__Extra__Value ${
                      errors.bills?.[billIndex]?.line_items?.[lineItemIndex]
                        ?.discount && "is-invalid"
                    }`}
                    disabled={disabled}
                  />
                )}
                <FieldErrorMessage
                  field={
                    errors.bills?.[billIndex]?.line_items?.[lineItemIndex]
                      ?.discount
                  }
                  fieldName={t("billing.invoice.line_item.discount")}
                  className="Invoice-Bill-Line-Item__Extra__Value__Error"
                />
              </div>
            </Stack>
          )}
        </div>
      ) : null}

      <GenericModal
        title={t("billing.invoice.line_item.delete_modal.title")}
        show={showDeleteConfirmationModal}
        onClose={() => {
          setShowDeleteConfirmationModal(false);
        }}
        onFinish={() => {
          setShowDeleteConfirmationModal(false);
          onRemove();
        }}
        closeText={t("actions.cancel")}
        finishText={t("actions.confirm")}
      >
        <h2 className="mb-6">
          {t("billing.invoice.line_item.delete_modal.header")}
        </h2>
        {t("billing.invoice.line_item.delete_modal.description")}
      </GenericModal>
    </>
  );
};

export default InvoiceBillLineItem;
