import IStage from "@interfaces/IStage";
import IXpert from "@interfaces/IXpert";
import IXpertTerm from "@interfaces/terms/IXpertTerm";

export const getXpertLink = (xpert: IXpert): string => {
  if (xpert.company_person?.includes("companies")) {
    return `/companies/${xpert.object_id}/xpert`;
  } else {
    return `/people/${xpert.object_id}/xpert`;
  }
};

export const getXpertTermsStageNames = (xpertTerms: IXpertTerm[]): string[] => {
  const stages = xpertTerms.reduce((acc, term) => {
    if (term.stages) {
      for (const stage of term.stages) {
        if (!acc.some((s) => s.id === stage.id)) {
          acc.push(stage);
        }
      }
    }
    return acc;
  }, [] as IStage[]);

  return stages.map((stage) => stage.name!);
};
