import { useTranslation } from "react-i18next";
import "./LoginPage.scss";
import GalleryPage from "@components/GalleryPage/GalleryPage";
import GoogleLogin from "@components/GoogleLogin/GoogleLogin";

const LoginPage = () => {
  const { t } = useTranslation();

  return (
    <GalleryPage
      galleryAnimation={false}
      galleryImageUri="/images/login_bg.jpg"
      galleryClassName="Login-Page__Gallery"
      logoOverlay={false}
    >
      <h3 className="mb-1">{t("login.title")}</h3>
      <p className="mb-5">{t("login.subtitle")}</p>

      <div className="Login-Page__Button">
        <GoogleLogin className="w-100" />
      </div>
    </GalleryPage>
  );
};

export default LoginPage;
