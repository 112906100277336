import { Spinner, Table } from "react-bootstrap";
import BillTableItem from "./BillTableItem/BillTableItem";
import { useTranslation } from "react-i18next";
import CardPlaceholder from "@components/CardPlaceholder/CardPlaceholder";
import IXchange from "@interfaces/IXchange";
import IXpert from "@interfaces/IXpert";
import { useEffect, useState } from "react";
import IClient from "@interfaces/IClient";
import "./BillTable.scss";
import { useBillGroups } from "@hooks/bill/useBillGroups";
import { IGetBillsParams } from "@services/BillService";
import { values } from "lodash";

const BillTable = ({
  xchange,
  xpert,
  client,
  areExtraFixedGetBillsParamsDebouncing = false,
  extraFixedGetBillsParams,
  expandBillGroupsByDefault = true,
  maxExpandedGroupCount,
}: {
  xchange?: IXchange;
  xpert?: IXpert;
  client?: IClient;
  areExtraFixedGetBillsParamsDebouncing?: boolean;
  extraFixedGetBillsParams?: IGetBillsParams;
  expandBillGroupsByDefault?: boolean;
  maxExpandedGroupCount?: number;
}) => {
  const { t } = useTranslation();

  const translationKey = (() => {
    if (xchange) return "xchange";
    if (xpert) return "xpert";
    if (client) return "client";
  })();

  const { data: billGroups, isFetching: areBillGroupsFetching } = useBillGroups(
    {
      xchange: xchange?.id,
      xpert: xpert?.id,
      client: client?.id,
      ...extraFixedGetBillsParams,
    },
    {
      enabled: !!xchange || !!xpert || !!client,
      refetchOnWindowFocus: false,
    }
  );

  const [expandedBillGroups, setExpandedBillGroups] = useState<number[]>([]);

  useEffect(() => {
    if (expandBillGroupsByDefault && billGroups) {
      setExpandedBillGroups(
        billGroups.map(
          (billGroup) => billGroup.xchange?.id! ?? billGroup.xpert?.id!
        )
      );
    }
  }, [billGroups, expandBillGroupsByDefault]);

  const toggleGroupExpansion = (groupToToggle: number) => {
    if (expandedBillGroups.includes(groupToToggle)) {
      setExpandedBillGroups(
        expandedBillGroups.filter((id) => id !== groupToToggle)
      );
    } else {
      const newExpandedBillGroups = [...expandedBillGroups];

      if (
        maxExpandedGroupCount &&
        newExpandedBillGroups.length + 1 > maxExpandedGroupCount
      ) {
        newExpandedBillGroups.shift();
      }

      newExpandedBillGroups.push(groupToToggle);

      setExpandedBillGroups(newExpandedBillGroups);
    }
  };

  return (
    <Table className="Bill-Table">
      <thead>
        <tr>
          <th>{t("billing.history.details")}</th>
          <th>{t("billing.history.payables")}</th>
          <th>{t("billing.history.invoiced")}</th>
          <th className="table-actions"></th>
        </tr>
      </thead>

      {billGroups && billGroups.length > 0 ? (
        billGroups?.map((billGroup, index) => {
          return (
            <BillTableItem
              key={`bill_group_${index}`}
              billGroup={billGroup}
              xchange={xchange}
              xpert={xpert}
              extraFixedGetBillsParams={extraFixedGetBillsParams}
              isExpanded={
                expandedBillGroups.includes(billGroup.xchange?.id!) ||
                expandedBillGroups.includes(billGroup.xpert?.id!)
              }
              toggleExpansion={() => {
                toggleGroupExpansion(
                  billGroup.xchange?.id ?? billGroup.xpert?.id!
                );
              }}
            />
          );
        })
      ) : (
        <tbody>
          <tr>
            <td colSpan={3} className="table--disabled">
              {areExtraFixedGetBillsParamsDebouncing ||
              areBillGroupsFetching ? (
                <div className="d-flex justify-content-center mt-4 mb-1">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : values(extraFixedGetBillsParams).filter(
                  (value) =>
                    value !== undefined && value !== "" && value !== null
                ).length > 0 ? (
                <div className="text-muted text-center mt-4 mb-4 small">
                  {t("filters.no_results")}
                </div>
              ) : (
                <CardPlaceholder
                  iconClassName={"fa-solid fa-rectangle-vertical-history"}
                  title={t(`billing.history.${translationKey}.none`)}
                  description={t(`billing.history.${translationKey}.none_desc`)}
                />
              )}
              {}
            </td>
            <td className="table-actions table--disabled"></td>
          </tr>
        </tbody>
      )}
    </Table>
  );
};

export default BillTable;
