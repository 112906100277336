import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import XchangeService, {
  IGetXchangeBillsSummary,
} from "@services/XchangeService";

export const useXchangeBillsSummary = (
  id: number,
  options?: Pick<
    UseQueryOptions<IGetXchangeBillsSummary[], Error>,
    "enabled" | "onSuccess" | "retry"
  > & {
    refetchOnWindowFocus?: boolean;
  }
) =>
  useQuery(
    ["xchanges", { id }, "bills", "summary"],
    () => XchangeService.getXchangeBillsSummary(id),
    options
  );
