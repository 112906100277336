import { useMutation, useQueryClient } from "@tanstack/react-query";
import ISignatory from "@interfaces/ISignatory";
import SignatoryService from "@services/SignatoryService";

interface IMutationVars {
  signatory: ISignatory;
}

export const useDeleteSignatory = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ["deleteSignatory"],
    ({ signatory }: IMutationVars) =>
      SignatoryService.deleteSignatory(signatory),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["signatories"]);
        queryClient.invalidateQueries(["signatures"]);
      },
    }
  );
};
