import {
  FormProvider,
  useController,
  useForm,
  useFormContext,
} from "react-hook-form";
import BadgeSelector from "@components/BadgeSelector/BadgeSelector";
import "./DomainSelector.scss";
import IconCheckbox from "@components/IconCheckbox/IconCheckbox";
import { Button, Spinner, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDomains } from "@hooks/domain/useDomains";
import { useSubDomains } from "@hooks/subdomain/useSubDomains";
import SearchFilter from "@components/SearchFilter/SearchFilter";
import { useEffect, useState } from "react";
import { useDebounce } from "@hooks/useDebounce";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";

const DomainSelector = () => {
  const { t } = useTranslation();

  const { control } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({
    name: "sub_domains",
    control,
  });

  const [getSubDomainsSearchParam, setSubDomainsSearchParam] =
    useState<string>("");
  const {
    debouncedValue: debouncedGetSubDomainsSearchParam,
    isDebouncing: isGetSubDomainsSearchParamsDeboucing,
  } = useDebounce(getSubDomainsSearchParam, 500);

  const localFiltersMethods = useForm<{
    domains: string[];
    search: string;
  }>({
    defaultValues: {
      domains: [],
      search: "",
    },
  });

  const {
    watch: watchLocalFiltersValues,
    setValue: setLocalFiltersValue,
    control: localFiltersControl,
  } = localFiltersMethods;

  useEffect(() => {
    const subscription = watchLocalFiltersValues((data) => {
      setSubDomainsSearchParam(data.search ?? "");
    });
    return () => subscription.unsubscribe();
  }, [watchLocalFiltersValues]);

  const { data: domains } = useDomains(
    {},
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setLocalFiltersValue(
          "domains",
          data.map((domain) => domain.id.toString())
        );
      },
    }
  );
  const { data: subDomains, isFetching: areSubDomainsFetching } = useSubDomains(
    {
      domain__in: watchLocalFiltersValues("domains").join(","),
      search: debouncedGetSubDomainsSearchParam,
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div className="Domain-Selector second-half-fade-in">
      <div className="Domain-Selector__Filters">
        <FormProvider {...localFiltersMethods}>
          <div className="Domain-Selector__Filters__Domains">
            <BadgeSelector
              name="domains"
              control={localFiltersControl}
              options={(domains ?? []).map((domain) => ({
                label: domain.name,
                value: domain.id.toString(),
              }))}
            />
          </div>

          <SearchFilter
            name="search"
            className="mt-4"
            placeholder={t("filters.search")}
          />
        </FormProvider>
      </div>
      <div className="Domain-Selector__List">
        {isGetSubDomainsSearchParamsDeboucing || areSubDomainsFetching ? (
          <div className="w-100 text-center mt-4 mb-4">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (subDomains ?? []).length > 0 ? (
          subDomains!.map((subDomain) => (
            <IconCheckbox
              key={subDomain.id}
              className="Domain-Selector__List__Item"
              label={subDomain.name}
              subLabel={subDomain.domain.name}
              iconClasses={subDomain.icon_classes}
              color={subDomain.domain.color}
              checked={(field.value ?? []).includes(subDomain.id)}
              onClick={() => {
                const currentSubDomains = field.value ?? [];
                if (currentSubDomains.includes(subDomain.id)) {
                  field.onChange(
                    currentSubDomains.filter(
                      (subDomainValue: number) =>
                        subDomainValue !== subDomain.id
                    )
                  );
                } else {
                  field.onChange([...currentSubDomains, subDomain.id]);
                }
              }}
            />
          ))
        ) : (
          <div className="w-100 text-center mt-4 mb-4 text-muted small">
            {t("filters.no_results")}
          </div>
        )}
      </div>

      <div className="Domain-Selector__Footer">
        <Stack direction="horizontal" gap={4}>
          <FieldErrorMessage field={error} fieldName="Domains" />

          <span className="ms-auto">
            {(field.value ?? []).length}{" "}
            {t("onboardings.xpert.domains.count", {
              count: (field.value ?? []).length,
            })}
          </span>
          <Button
            disabled={(field.value ?? []).length === 0}
            onClick={() => {
              field.onChange([]);
            }}
          >
            <i className="fa-solid fa-square-xmark me-2" />
            {t("actions.deselected_all")}
          </Button>
        </Stack>
      </div>
    </div>
  );
};

export default DomainSelector;
