import { Badge, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./TermCard.scss";
import ICatalogTerm from "@interfaces/terms/ICatalogTerm";
import CostRange from "@components/ProductCard/CostRange/CostRange";
import { toDurationRangeString } from "@helpers/date-utils";

const TermCard = ({ term }: { term: ICatalogTerm }) => {
  const { t } = useTranslation();

  return (
    <Link to={`/terms/${term.id}`}>
      <Card className="Term-Card p-1 border-0">
        <Card.Body>
          <Row>
            <Col xl={4} md={6} sm={12}>
              <div>
                {term.sub_domain && (
                  <Badge className="Term-Card__Badges__Badge__Domain mb-2">
                    {term.sub_domain.name}
                  </Badge>
                )}
              </div>
              <h6>{term.title}</h6>
            </Col>
            <Col xl={2} md={6} sm={12} className="mt-xl-0 mb-xl-0 mt-6 mb-3">
              <div className="text-table-header">{t("term.card.xperts")}</div>
              {term.related_xperts && term.related_xperts.length > 0 ? (
                <div className="Term-Card__Badges">
                  {term.related_xperts.map(
                    (related_xpert, index) =>
                      index < 3 && (
                        <Badge
                          key={related_xpert.id}
                          pill
                          bg="light"
                          className="Term-Card__Badges__Badge me-2 mb-2 border"
                        >
                          {related_xpert.name}
                        </Badge>
                      )
                  )}
                  {term.related_xperts.length > 3 && (
                    <small className="Term-Card__Badges__ExtraBadges">
                      {"+ " + (term.related_xperts.length - 3)}
                    </small>
                  )}
                </div>
              ) : null}
            </Col>
            <Col xl={2} md={6} sm={12} className="mt-xl-0 mb-xl-0 mt-6 mb-3">
              <div className="text-table-header">
                {t("term.card.cost_range")}
              </div>
              <h6>
                {term.cost_ranges?.map((costRange, index) => (
                  <CostRange
                    key={`${index}-${costRange.currency}`}
                    costRange={costRange}
                    className={
                      term.cost_ranges?.length &&
                      index !== term.cost_ranges.length - 1
                        ? "mb-1"
                        : ""
                    }
                  />
                ))}
              </h6>
            </Col>
            <Col xl={2} md={6} sm={12} className="mt-xl-0 mb-xl-0 mt-6 mb-3">
              <div className="text-table-header">{t("term.card.duration")}</div>
              <h6>
                {term.duration_range?.min &&
                  toDurationRangeString(term.duration_range)}
              </h6>
            </Col>
            <Col xl={2} md={6} sm={12} className="mt-xl-0 mb-xl-0 mt-6 mb-3">
              <div className="text-table-header">{t("term.card.stages")}</div>
              {term.stages && term.stages.length > 0 ? (
                <div className="Term-Card__Badges">
                  {term.stages.slice(0, 3).map((stage) => (
                    <Badge
                      key={stage.id}
                      pill
                      bg="light"
                      className="Term-Card__Badges__Badge me-2 mb-2 border"
                    >
                      {stage.name}
                    </Badge>
                  ))}
                  {term.stages.length > 3 && (
                    <small className="Term-Card__Badges__ExtraBadges">
                      {"+ " + (term.stages.length - 3)}
                    </small>
                  )}
                </div>
              ) : null}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Link>
  );
};

export default TermCard;
