import { useMutation, useQueryClient } from "@tanstack/react-query";
import InvoiceService from "@services/InvoiceService";

interface MutationVars {
  invoiceId: number;
  lineItemId: number;
}

export const useInvoiceLineItemDelete = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ invoiceId, lineItemId }: MutationVars) =>
      InvoiceService.deleteLineItem(invoiceId, lineItemId),
    {
      onSuccess: (_, { invoiceId }) => {
        queryClient.invalidateQueries(["invoices", { id: invoiceId }]);
      },
    }
  );
};
