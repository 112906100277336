import Joi from "joi";
import { debounce } from "lodash";
import { useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import { isFieldRequired } from "@helpers/joi-utils";
import { useSubDomains } from "@hooks/subdomain/useSubDomains";
import CatalogTermService from "@services/CatalogTermService";
import CustomSelect from "@components/CustomSelect/CustomSelect";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import InfoInput from "@components/InfoInput/InfoInput";

export interface CatalogTermFormValues {
  title?: string;
  description?: string;
  sub_domain?: number;
}

export const CatalogTermSchema = Joi.object({
  title: Joi.string().required(),
  description: Joi.string().allow(""),
  sub_domain: Joi.number(),
});

const CatalogTermForm = () => {
  const { t } = useTranslation();

  const { data: subDomains } = useSubDomains();
  const [isTitleDuplicate, setIsTitleDuplicate] = useState<boolean>(false);

  const formMethods = useFormContext<CatalogTermFormValues>();

  const {
    register,
    formState: { errors },
    control,
  } = formMethods;

  const validateUniqueTermTitle = useMemo(
    () =>
      debounce(async (value) => {
        if (!value) {
          setIsTitleDuplicate(false);
          return;
        }

        const duplicates = await CatalogTermService.getCatalogTerms({
          title: value.trim().replace(/  +/g, " "),
        });
        setIsTitleDuplicate(duplicates.count > 0);
      }, 500),
    []
  );

  return (
    <Form>
      <Form.Group className="mb-4">
        <Form.Label aria-required={isFieldRequired(CatalogTermSchema, "title")}>
          {t("companies.details.xpert.terms.fields.title")}
        </Form.Label>
        <InfoInput
          type="text"
          {...register("title")}
          isInvalid={!!errors.title || isTitleDuplicate}
          onChange={(e) => {
            validateUniqueTermTitle(e.target.value);
            register("title").onChange(e);
          }}
        />
        <FieldErrorMessage
          field={
            errors.title ||
            (isTitleDuplicate
              ? ({ type: "custom.term_title_not_unique" } as FieldError)
              : undefined)
          }
          fieldName={t("companies.details.xpert.terms.fields.title")}
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label
          aria-required={isFieldRequired(CatalogTermSchema, "description")}
        >
          {t("companies.details.xpert.terms.fields.description")}
        </Form.Label>
        <Controller
          control={control}
          name="description"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <>
              <ReactQuill
                className={`${error && "ql-invalid"}`}
                value={value}
                onChange={(value) => {
                  if (value.replace(/(<([^>]+)>)/gi, "")) {
                    onChange(value);
                  } else {
                    onChange(undefined);
                  }
                }}
                placeholder={t(
                  "companies.details.xpert.terms.fields.description_help"
                )}
              />
              <FieldErrorMessage
                field={error}
                fieldName={t(
                  "companies.details.xpert.terms.fields.description"
                )}
              />
            </>
          )}
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label
          aria-required={isFieldRequired(CatalogTermSchema, "sub_domain")}
        >
          {t("companies.details.xpert.terms.fields.sub_domain")}
        </Form.Label>
        <CustomSelect
          control={control}
          name="sub_domain"
          options={subDomains?.map((subDomain) => ({
            value: subDomain.id,
            label: subDomain.name,
          }))}
        />
        <FieldErrorMessage
          field={errors.sub_domain}
          fieldName={t("companies.details.xpert.terms.fields.sub_domain")}
        />
      </Form.Group>
    </Form>
  );
};

export default CatalogTermForm;
