import {
  Badge,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import IProduct from "@interfaces/products/IProduct";
import CostRange from "./CostRange/CostRange";
import "./ProductCard.scss";
import { hasNullCostRanges } from "@helpers/product-utils";

const ProductCard = ({ product }: { product: IProduct }) => {
  const { t } = useTranslation();

  return (
    <Link to={`/products/${product.id}`}>
      <Card className="Product-Card p-1 border-0">
        <Card.Body>
          <Row>
            <Col xl={3} md={6} sm={12}>
              {product.status ? (
                <Badge
                  className={`Product-Card__Status--${product.status} me-2 mb-2`}
                >
                  {t(`product.statuses.${product.status}`)}
                </Badge>
              ) : null}
              <h6>{product.title}</h6>
            </Col>
            <Col xl={2} md={3} sm={3} className="mt-xl-0 mb-xl-0 mt-6 mb-3">
              <div className="text-table-header">{t("product.card.terms")}</div>
              <h6>{product.terms_count}</h6>
            </Col>
            <Col xl={3} md={6} sm={12} className="mt-xl-0 mb-xl-0 mt-6 mb-3">
              <div className="text-table-header">
                {t("product.card.stages")}
              </div>
              <h6>{product.stages?.map((stage) => stage.name).join(", ")}</h6>
            </Col>
            <Col xl={3} md={6} sm={12} className="mt-xl-0 mb-xl-0 mt-6 mb-3">
              <div className="text-table-header">
                <div className="text-table-header">
                  {t("product.card.cost_range")}
                  {hasNullCostRanges(product) && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          {t("product.details.terms.no_cost_term")}
                        </Tooltip>
                      }
                    >
                      <i className="fa-regular fa-circle-info ms-2"></i>
                    </OverlayTrigger>
                  )}
                </div>
                <h6>
                  {product.cost_ranges?.map((costRange, index) => (
                    <CostRange
                      key={`${index}-${costRange.currency}`}
                      costRange={costRange}
                      className={
                        product.cost_ranges?.length &&
                        index !== product.cost_ranges.length - 1
                          ? "mb-1"
                          : ""
                      }
                    />
                  ))}
                </h6>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Link>
  );
};

export default ProductCard;
