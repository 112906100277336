import ISignNowSignature from "@interfaces/ISignNowSignature";
import Service from "@services/Service";

export interface IGetSignaturesParams {
  signatory?: number;
  signatory__in?: string;
  is_completed?: boolean;
  is_seen?: boolean;
  role?: string;
  document_id?: string;
}

export default class SignNowService extends Service {
  public static async getSignatures(params?: IGetSignaturesParams) {
    const response = await this.axios.get<ISignNowSignature[]>(
      "/signnow/signatures",
      { params }
    );
    return response.data;
  }
  public static async createSignature(signature: ISignNowSignature) {
    const response = await this.axios.post<ISignNowSignature>(
      "/signnow/signatures",
      signature
    );
    return response.data;
  }

  public static async updateSignature(signature: ISignNowSignature) {
    const response = await this.axios.patch<ISignNowSignature>(
      `/signnow/signatures/${signature.id}`,
      signature
    );
    return response.data;
  }

  public static async resendSignatureInvite(signature: ISignNowSignature) {
    const response = await this.axios.post<ISignNowSignature>(
      `/signnow/signatures/${signature.id}/resend`
    );
    return response.data;
  }

  public static async revokeSignNow(contractId: number) {
    const response = await this.axios.post(`/contracts/${contractId}/revoke`);
    return response.data;
  }
}
