import { ReactNode, useState } from "react";
import { Alert } from "react-bootstrap";
import { Variant } from "react-bootstrap/esm/types";
import "./AlertWithIcon.scss";

const AlertWithIcon = ({
  variant = "info",
  iconClasses,
  closable = false,
  children,
  className,
}: {
  variant?: Variant;
  iconClasses: string;
  closable?: boolean;
  children: ReactNode;
  className?: string;
}) => {
  const [isClosed, setIsClosed] = useState(false);

  return !isClosed ? (
    <Alert variant={variant} className={`${className} mb-4`}>
      <div className="d-flex">
        <div>
          <i className={`${iconClasses} me-4`} />
        </div>
        <div>{children}</div>
        {closable && (
          <div className="ms-auto ps-4">
            <i
              className="Alert-With-Icon__Close-Button fa-regular fa-xmark"
              onClick={() => {
                setIsClosed(true);
              }}
            />
          </div>
        )}
      </div>
    </Alert>
  ) : null;
};

export default AlertWithIcon;
