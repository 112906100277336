import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import ISignature from "@interfaces/ISignature";
import SignNowService, { IGetSignaturesParams } from "@services/SignNowService";

export const useSignatures = (
  params?: IGetSignaturesParams,
  options?: Pick<
    UseQueryOptions<ISignature[], Error>,
    "enabled" | "onSuccess" | "staleTime"
  >
) =>
  useQuery(
    ["signatures", { params }],
    () => SignNowService.getSignatures(params),
    options
  );
