import IDomain from "@interfaces/IDomain";

export enum Pronouns {
  SHE = "she",
  HE = "he",
  THEM = "them",
  ZE = "ze",
  NONE = "none",
  OTHER = "other",
}

export default interface IPerson {
  id?: number;
  first_name?: string;
  last_name?: string;
  pronouns?: string;
  email?: string;
  phone?: string;
  alternate_email?: string;
  linkedin_url?: string;
  country?: string;
  region?: string;
  domains?: IDomain[];
  title?: string;
  role_description?: string;
  connected_by?: string;
  creation_date?: Date;
  current_company?: string;
  rate?: string;
  currency?: string;
  roles?: number[];
}

export interface IPersonWrite extends IPerson {
  context?: string;
}
