import { useInfiniteQuery } from "@tanstack/react-query";
import CompanyService, { IGetCompaniesParams } from "@services/CompanyService";
import { IPageParams } from "@interfaces/IPage";

export const useCompanies = (
  params?: IGetCompaniesParams & IPageParams,
  options?: {
    enabled: boolean;
    refetchOnWindowFocus?: boolean;
  }
) =>
  useInfiniteQuery(
    ["companies", { params }],
    (ctx) => {
      return CompanyService.getCompanies({
        page: ctx.pageParam ? ctx.pageParam : undefined,
        ...params,
      });
    },
    {
      getPreviousPageParam: (firstGroup) => firstGroup.previous,
      getNextPageParam: (lastGroup) => lastGroup.next,
      ...options,
    }
  );
