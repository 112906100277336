import { useState } from "react";
import { Button } from "react-bootstrap";
import "./AcknowledgementSection.scss";

const AcknowledgementSection = ({
  title,
  subtitle,
  children,
  isCollapsible = false,
}: {
  title: string;
  subtitle: string;
  children: React.ReactNode;
  isCollapsible?: boolean;
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <div className="Acknowledgement-Section">
      <div
        className={`Acknowledgement-Section__Header ${
          isCollapsed && "Acknowledgement-Section__Header--collapsed"
        }`}
      >
        <div>
          <div className="Acknowledgement-Section__Header__Subtitle">
            {subtitle}
          </div>
          <div className="Acknowledgement-Section__Header__Title">{title}</div>
        </div>

        {isCollapsible && (
          <Button
            variant="outline-light"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? (
              <i className="fa-regular fa-chevron-down" />
            ) : (
              <i className="fa-regular fa-chevron-up" />
            )}
          </Button>
        )}
      </div>
      {!isCollapsed && children}
    </div>
  );
};

export default AcknowledgementSection;
