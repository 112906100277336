import IPerson, { IPersonWrite } from "@interfaces/IPerson";
import Service from "@services/Service";

export interface IGetPeopleParams {
  search?: string;
  ordering?: string;
  id__in?: string;
  current_company?: number;
  contact_companies?: number;
  contact_companies__not__in?: string;
  xpert_team_members__xpert?: number;
  xpert_team_members__xpert__in?: string;
  xpert_team_members__xpert__not__in?: string;
  client_team_members__client?: number;
  client_team_members__client__in?: string;
  client_team_members__client__not__in?: string;
  xchange_team_members__xchange?: number;
  xchange_team_members__xchange__in?: string;
  xchange_team_members__xchange__not__in?: string;
}

export default class PersonService extends Service {
  public static async getPeople(params?: IGetPeopleParams) {
    const response = await this.axios.get<IPerson[]>("/people", {
      params,
    });
    return response.data;
  }

  public static async getPerson(id: number) {
    const response = await this.axios.get<IPerson>(`/people/${id}`);
    return response.data;
  }

  public static async createPerson(person: IPersonWrite) {
    const response = await this.axios.post<IPersonWrite>("/people", person);
    return response.data;
  }

  public static async updatePerson(person: IPersonWrite) {
    const response = await this.axios.patch<IPersonWrite>(
      `/people/${person.id}`,
      person
    );
    return response.data;
  }
}
