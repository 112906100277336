import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ICatalogProductCreateUpdate } from "@interfaces/products/ICatalogProduct";
import CatalogProductService from "@services/CatalogProductService";

export const useCatalogProductCreate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (catalogProduct: ICatalogProductCreateUpdate) =>
      CatalogProductService.createCatalogProduct(catalogProduct),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["catalog-products"]);
      },
    }
  );
};
