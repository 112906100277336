import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IXchangeTermCreateUpdate } from "@interfaces/terms/IXchangeTerm";
import XchangeTermService from "@services/XchangeTermService";

interface IMutationVars {
  xchangeTerm: IXchangeTermCreateUpdate;
}

export const useXchangeTermCreate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ xchangeTerm }: IMutationVars) =>
      XchangeTermService.createXchangeTerm(xchangeTerm),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([
          "xchanges",
          { id: variables.xchangeTerm.xchange },
        ]);
        queryClient.invalidateQueries(["xchange-terms"]);
      },
    }
  );
};
