import React from "react";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "./FieldErrorMessage.scss";

const FieldErrorMessage = ({
  field,
  fieldName,
  className,
}: {
  field:
    | FieldError
    | Merge<FieldError, (FieldError | undefined)[]>
    | Merge<FieldError, FieldErrorsImpl<any>>
    | Merge<FieldError, (Merge<FieldError, FieldErrorsImpl<any>> | undefined)[]>
    | undefined;
  fieldName: string;
  className?: string;
}) => {
  const { t, i18n } = useTranslation();

  if (field) {
    let keyToTranslate = `errors.${field.type}${
      field.type === "custom" ? `.${field.message}` : ""
    }`;

    if (i18n.exists(field?.message as string)) {
      keyToTranslate = field?.message as string;
    }

    return (
      <div className={`Field-Error-Message ${className}`}>
        {fieldName} {t(keyToTranslate)}
      </div>
    );
  }
  return <></>;
};

export default FieldErrorMessage;
