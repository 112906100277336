import CompanyStatusbadge from "@components/CompanyStatusBadge/CompanyStatusBadge";
import ICompany from "@interfaces/ICompany";
import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./CompanyDetailsRead.scss";
import {
  getCompanyAddress,
  getCompanyLegalJurisdictionWithCountry,
} from "@helpers/company-utils";
import InfoCard from "@components/InfoCard/InfoCard";
import InfoCardSection from "@components/InfoCard/InfoCardSection/InfoCardSection";
import { useXpertTerms } from "@hooks/term/useXpertTerms";
import { useMemo } from "react";
import { getXpertTermsStageNames } from "@helpers/xpert-utils";
import { useClient } from "@hooks/client/useClient";

const CompanyDetailsRead = ({ company }: { company: ICompany }) => {
  const { t } = useTranslation();

  const { data: xpertTerms } = useXpertTerms(
    {
      xpert_involved: company.xpert_id,
    },
    {
      enabled: !!company.xpert_id,
    }
  );

  const xpertTermStageNames = useMemo(() => {
    return xpertTerms ? getXpertTermsStageNames(xpertTerms) : [];
  }, [xpertTerms]);

  const { data: client } = useClient(company.client_id!, {
    enabled: !!company.client_id,
  });

  const stages = useMemo(() => {
    const stages: string[] = [];

    if (xpertTermStageNames.length > 0) {
      stages.push(...xpertTermStageNames);
    }

    if (client?.stage_number && !stages.includes(client.stage_number.name!)) {
      stages.push(client.stage_number.name!);
    }

    return stages;
  }, [xpertTermStageNames, client]);

  return (
    <div className="pe-4 pb-5 ps-4">
      <div className="Company-Details-Read__Header">
        <img
          className="Company-Details-Read__Header__Logo"
          src={company.company_logo ?? "/logo-light.png"}
          alt={company.company_name}
        />

        <div>
          <span className="Company-Details-Read__Header__Legal-Name">
            {company.company_legal_name}
          </span>
          <span className="Company-Details-Read__Header__Common-Name">
            ({company.company_name})
          </span>

          <div className="Company-Details-Read__Header__Info">
            <CompanyStatusbadge status={company.status!} />
            <Badge bg="info" text="info">
              {t("companies.details.tag")}
            </Badge>
            {company.xpert_id && (
              <Badge bg="info" text="info">
                {t("companies.details.xpert.tag")}
              </Badge>
            )}
            {company.client_id && (
              <Badge bg="info" text="info">
                {t("companies.details.client.tag")}
              </Badge>
            )}
          </div>
        </div>
      </div>

      <div className="Company-Details-Read__Body">
        <InfoCard
          title={`${t(
            "companies.details.overview.general_details.labels.sub_domains"
          )} & ${t("companies.details.xpert.terms.fields.stages")}`}
        >
          <InfoCardSection
            title={t(
              "companies.details.overview.general_details.labels.sub_domains"
            )}
          >
            {company.sub_domains && company.sub_domains.length > 0
              ? company.sub_domains.map((domain) => domain.name).join(", ")
              : "-"}
          </InfoCardSection>

          {(company.xpert_id || company.client_id) && (
            <InfoCardSection
              title={t("companies.details.xpert.terms.fields.stages")}
            >
              {stages.length > 0 ? stages.join(", ") : "-"}
            </InfoCardSection>
          )}
        </InfoCard>

        <InfoCard title={t("companies.details.overview.location.subtitle")}>
          <InfoCardSection
            title={t("companies.details.overview.location.address")}
          >
            {getCompanyAddress(company).length > 0
              ? getCompanyAddress(company)
              : "-"}
          </InfoCardSection>

          <InfoCardSection
            title={t(
              "companies.details.overview.general_details.labels.legal_entity_type"
            )}
          >
            {company.legal_entity_type
              ? t(`companies.legal_entity_types.${company.legal_entity_type}`)
              : "-"}
          </InfoCardSection>

          <InfoCardSection
            title={t(
              "companies.details.overview.location.labels.legal_entity_jurisdiction"
            )}
          >
            {company.legal_entity_jurisdiction
              ? getCompanyLegalJurisdictionWithCountry(company)
              : "-"}
          </InfoCardSection>
        </InfoCard>
      </div>
    </div>
  );
};

export default CompanyDetailsRead;
