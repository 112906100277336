import { ProtectedContractTypeCodes } from "@interfaces/IContractType";
import useContractGeneration from "@hooks/contract/useContractGeneration";
import ContractDashboard from "@components/ContractDashboard/ContractDashboard";
import IClient from "@interfaces/IClient";

const CompanyClientMSA = ({ client }: { client?: IClient }) => {
  const contractSubject = {
    subjectId: client?.id!,
    contractType: ProtectedContractTypeCodes.CLIENT_MSA,
  };
  const contractGenerationHook = useContractGeneration([contractSubject]);

  return client ? (
    <ContractDashboard
      contractGenerationHook={contractGenerationHook}
      contractTranslationRootKey="contracts.msa"
    />
  ) : null;
};

export default CompanyClientMSA;
