import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ICatalogTermCreateUpdate } from "@interfaces/terms/ICatalogTerm";
import CatalogTermService from "@services/CatalogTermService";

export const useCatalogTermUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, term }: { id: number; term: ICatalogTermCreateUpdate }) =>
      CatalogTermService.updateCatalogTerm(id, term),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["terms"]);
      },
    }
  );
};
