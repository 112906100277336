import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import IContract from "@interfaces/IContract";
import ContractService, {
  IGetContractsParams,
} from "@services/ContractService";

export const useContracts = (
  params?: IGetContractsParams,
  options?: Pick<
    UseQueryOptions<IContract[], Error>,
    "enabled" | "onSuccess" | "staleTime"
  >
) =>
  useQuery(
    ["contracts", { params }],
    () => ContractService.getContracts(params),
    options
  );
