import { useMutation, useQueryClient } from "@tanstack/react-query";
import InvoiceService from "@services/InvoiceService";

interface MutationVars {
  xchangeId: number;
  externalBills: number[];
}

export const useInvoiceCreate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ xchangeId, externalBills }: MutationVars) =>
      InvoiceService.createInvoice(xchangeId, externalBills),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["invoices"]);
      },
    }
  );
};
