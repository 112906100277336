import { useMutation, useQueryClient } from "@tanstack/react-query";
import InvoiceService from "@services/InvoiceService";
import { IInvoiceWrite } from "@interfaces/IInvoice";

interface MutationVars {
  invoiceId: number;
  data: IInvoiceWrite;
}

export const useInvoiceUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ invoiceId, data }: MutationVars) =>
      InvoiceService.updateInvoice(invoiceId, data),
    {
      onSuccess: (_, { invoiceId }) => {
        queryClient.invalidateQueries(["invoices", { id: invoiceId }]);
      },
    }
  );
};
