import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import React, { useState } from "react";
import { Button, Stack, Tab } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import GenericModal from "@components/GenericModal/GenericModal";
import IXpert from "@interfaces/IXpert";
import { useDocumentCreate } from "@hooks/document/useDocumentCreate";
import IDocument from "@interfaces/IDocument";
import { useDocumentUpdate } from "@hooks/document/useDocumentUpdate";
import IClient from "@interfaces/IClient";
import AddLinkDocumentForm from "./AddLinkForm/AddLinkDocumentForm";
import AddDocumentForm from "./AddDocumentForm/AddDocumentForm";
import AxiosErrorAlert from "@components/AxiosErrorAlert/AxiosErrorAlert";
import "./UploadDocumentModal.scss";
import IXchange from "@interfaces/IXchange";

export interface DocumentLinkFormValues {
  xpert?: number;
  client?: number;
  xchange?: number;
  name?: string;
  type?: number;
  url?: string;
  is_cloud_file?: boolean;
  file?: File;
}

export const DocumentLinkValidationSchema = Joi.object({
  xpert: Joi.number().optional(),
  client: Joi.number().optional(),
  xchange: Joi.number().optional(),
  name: Joi.string().max(255).required(),
  type: Joi.number().required(),
  url: Joi.when("is_cloud_file", {
    is: true,
    then: Joi.string().uri().allow(""),
    otherwise: Joi.string().uri().required(),
  }),
  is_cloud_file: Joi.boolean().optional(),
  file: Joi.when("is_cloud_file", {
    is: true,
    then: Joi.when("url", {
      is: Joi.string().valid(null, ""),
      then: Joi.any().required(),
      otherwise: Joi.any().optional(),
    }),
    otherwise: Joi.any().optional(),
  }),
});

const UploadDocumentModal = ({
  xpert,
  client,
  xchange,
  document,
  show,
  onClose,
}: {
  xpert?: IXpert;
  client?: IClient;
  xchange?: IXchange;
  document?: IDocument;
  show: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState<"upload" | "link">(
    document ? "link" : "upload"
  );

  const formMethods = useForm<DocumentLinkFormValues>({
    mode: "onChange",
    resolver: joiResolver(DocumentLinkValidationSchema),
    defaultValues: {
      xpert: xpert?.id || document?.xpert || undefined,
      client: client?.id || document?.client || undefined,
      xchange: xchange?.id || document?.xchange || undefined,
      name: document?.name,
      type: document?.type,
      url: document?.url,
      is_cloud_file: document?.is_cloud_file || currentTab === "upload",
      file: undefined,
    },
  });
  const { handleSubmit, reset, setValue } = formMethods;

  const closeModal = () => {
    reset();
    onClose();
    setCurrentTab("upload");
  };

  const createDocumentMutation = useDocumentCreate();
  const updateDocumentMutation = useDocumentUpdate();

  const formSubmit = (data: DocumentLinkFormValues) => {
    if (document) {
      updateDocumentMutation.mutate(
        {
          id: document.id!,
          ...data,
        },
        {
          onSuccess: () => {
            closeModal();
          },
        }
      );
    } else {
      createDocumentMutation.mutate(
        {
          ...data,
        },
        {
          onSuccess: () => {
            closeModal();
          },
        }
      );
    }
  };

  return (
    <GenericModal
      title={
        document
          ? t("companies.documents.update")
          : currentTab === "upload"
          ? t("companies.documents.upload")
          : t("companies.documents.add_link")
      }
      show={show}
      onClose={closeModal}
      onFinish={handleSubmit(formSubmit)}
      finishText={
        document
          ? t("companies.documents.update")
          : currentTab === "upload"
          ? t("companies.documents.upload")
          : t("companies.documents.add_link")
      }
      isFinishLoading={
        updateDocumentMutation.isLoading || createDocumentMutation.isLoading
      }
    >
      <div className="Upload-Document-Modal__Title mt-4 mb-6">
        {document
          ? t("companies.documents.update")
          : currentTab === "upload"
          ? t("companies.documents.upload")
          : t("companies.documents.add_link")}
      </div>
      <div className="Upload-Document-Modal__Tab-Choices p-2 mb-4">
        <Stack direction="horizontal" gap={2}>
          <Button
            variant={currentTab === "upload" ? "primary" : "outline"}
            onClick={() => {
              setValue("is_cloud_file", true);
              setCurrentTab("upload");
            }}
            disabled={!!document}
          >
            {t("companies.documents.upload")}
          </Button>

          <Button
            variant={currentTab === "link" ? "primary" : "outline"}
            onClick={() => {
              setValue("is_cloud_file", false);
              setCurrentTab("link");
            }}
            disabled={!!document}
          >
            {t("companies.documents.link")}
          </Button>
        </Stack>
      </div>

      <Tab.Content>
        <FormProvider {...formMethods}>
          <Tab.Pane active={currentTab === "upload"}>
            {currentTab === "upload" && (
              <AddDocumentForm
                disabled={
                  updateDocumentMutation.isLoading ||
                  createDocumentMutation.isLoading
                }
              />
            )}
          </Tab.Pane>
          <Tab.Pane active={currentTab === "link"}>
            {currentTab === "link" && (
              <AddLinkDocumentForm
                disabled={
                  updateDocumentMutation.isLoading ||
                  createDocumentMutation.isLoading
                }
              />
            )}
          </Tab.Pane>
        </FormProvider>

        <AxiosErrorAlert
          response={
            createDocumentMutation.error || updateDocumentMutation.error
          }
          translationPrefix="companies.documents.fields."
        />
      </Tab.Content>
    </GenericModal>
  );
};

export default UploadDocumentModal;
