import { useMutation, useQueryClient } from "@tanstack/react-query";
import XchangeService from "@services/XchangeService";
import {
  IXchangeTeamMember,
  IXchangeTeamMemberCreateUpdate,
} from "@interfaces/IXchangeTeamMember";

export const useXchangeTeamMemberUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (member: IXchangeTeamMemberCreateUpdate) =>
      XchangeService.updateXchangeTeamMember(member),
    {
      onSuccess: (data: IXchangeTeamMember) => {
        queryClient.invalidateQueries([
          "xchanges",
          { id: data.xchange },
          "team-members",
        ]);
      },
    }
  );
};
