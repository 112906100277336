import IBill, { IBillWrite } from "@interfaces/IBill";
import IBillGroup from "@interfaces/IBillGroup";
import IPage from "@interfaces/IPage";
import Service from "@services/Service";

export interface IGetBillGroupsParams {
  xpert?: number;
  xchange?: number;
  client?: number;
}

export interface IGetBillsParams {
  id__in?: string;
  attachments?: boolean;
  ordering?: "xpert__name" | "-xpert__name" | "bill_status" | "-bill_status";
  search?: string;
  status__in?: string;
  xchange__in?: string;
  xpert__in?: string;
  xchange__client__in?: string;
  billing_period_start?: string;
  billing_period_start__gte?: string;
  billing_period_start__lte?: string;
  billing_period_start__gt?: string;
  billing_period_start__lt?: string;
  billing_period_end?: string;
  billing_period_end__gte?: string;
  billing_period_end__lte?: string;
  billing_period_end__gt?: string;
  billing_period_end__lt?: string;
  billing_period__occurs_during_start?: string;
  billing_period__occurs_during_end?: string;
  outbound_invoice__isnull?: boolean;
  populate_xpert?: boolean;
  populate_xchange?: boolean;
}

export default class BillService extends Service {
  public static async getBillGroups(
    params: IGetBillGroupsParams & IGetBillsParams
  ) {
    const response = await this.axios.get<IBillGroup[]>("/bill-groups", {
      params,
    });
    return response.data;
  }

  public static async getBills(params: IGetBillsParams) {
    const response = await this.axios.get<IPage<IBill>>("/bills", {
      params,
    });
    return response.data;
  }

  public static async getBill(id: number) {
    const response = await this.axios.get<IBill>(`/bills/${id}`);
    return response.data;
  }

  public static async updateBill(bill: IBillWrite) {
    const response = await this.axios.patch<IBill>(`/bills/${bill.id}`, bill);
    return response.data;
  }

  public static async getBillAttachment(billId: number, attachmentId: string) {
    const response = await this.axios.get(
      `/bills/${billId}/attachment/${attachmentId}`,
      {
        responseType: "blob",
      }
    );
    return response.data;
  }
}
