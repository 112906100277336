import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import ICompany from "@interfaces/ICompany";
import CompanyService from "@services/CompanyService";

export const useCompany = (
  id: number,
  options?: Pick<
    UseQueryOptions<() => ICompany, Error>,
    "enabled" | "onError" | "retry"
  > & {
    refetchOnWindowFocus?: boolean;
  }
) =>
  useQuery(["companies", { id }], () => CompanyService.getCompany(id), {
    ...options,
  });
