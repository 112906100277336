const brandingIcons: {
  name: string;
  iconClasses?: string;
  iconURL?: string;
  domain: string;
  backgroundColor?: string;
  color?: string;
}[] = [
  {
    name: "Facebook",
    iconClasses: "fa-brands fa-facebook",
    domain: "facebook.com",
    backgroundColor: "#1B77F3",
    color: "#f9f9f9",
  },
  {
    name: "Intagram",
    iconClasses: "fa-brands fa-instagram",
    domain: "instagram.com",
    backgroundColor: "#DC3354",
    color: "#f9f9f9",
  },
  {
    name: "Twitter",
    iconClasses: "fa-brands fa-twitter",
    domain: "twitter.com",
    backgroundColor: "#1C96E8",
    color: "#f9f9f9",
  },
  {
    name: "Jira",
    iconClasses: "fa-brands fa-jira",
    domain: "atlassian.net/browse",
    backgroundColor: "#0051CC",
    color: "#f9f9f9",
  },
  {
    name: "Jira",
    iconClasses: "fa-brands fa-jira",
    domain: "atlassian.net/jira",
    backgroundColor: "#0051CC",
    color: "#f9f9f9",
  },
  {
    name: "Confluence",
    iconClasses: "fa-brands fa-confluence",
    domain: "atlassian.net/wiki",
    backgroundColor: "#0051CC",
    color: "#f9f9f9",
  },
  {
    name: "Trello",
    iconClasses: "fa-brands fa-trello",
    domain: "trello.com",
    backgroundColor: "#0076BD",
    color: "#f9f9f9",
  },
  {
    name: "SourceTree",
    iconClasses: "fa-brands fa-sourcetree",
    domain: "sourcetreeapp.com",
    backgroundColor: "#0076BD",
    color: "#f9f9f9",
  },
  {
    name: "Figma",
    iconClasses: "fa-brands fa-figma",
    domain: "figma.com",
    backgroundColor: "black",
    color: "white",
  },
  {
    name: "Google Doc",
    iconClasses: "fa-brands fa-google-drive",
    domain: "docs.google.com/document",
    backgroundColor: "#4185F4",
    color: "#f9f9f9",
  },
  {
    name: "Google Spreadsheet",
    iconClasses: "fa-brands fa-google-drive",
    domain: "docs.google.com/spreadsheets",
    backgroundColor: "#33A853",
    color: "#f9f9f9",
  },
  {
    name: "Google Slides",
    iconClasses: "fa-brands fa-google-drive",
    domain: "docs.google.com/presentation",
    backgroundColor: "#FBBC04",
    color: "#f9f9f9",
  },
  {
    name: "Google Drive",
    iconClasses: "fa-brands fa-google-drive",
    domain: "drive.google.com",
  },
  {
    name: "Google Search",
    iconClasses: "fa-brands fa-google",
    domain: "google.com/search",
  },
  {
    name: "Google",
    iconClasses: "fa-brands fa-google",
    domain: "google.com",
  },
  {
    name: "YouTube",
    iconClasses: "fa-brands fa-youtube",
    domain: "youtube.com",
    backgroundColor: "#FF000F",
    color: "#f9f9f9",
  },
  {
    name: "LinkedIn",
    iconClasses: "fa-brands fa-linkedin",
    domain: "linkedin.com",
    backgroundColor: "#0076B5",
    color: "#f9f9f9",
  },
  {
    name: "Dropbox",
    iconClasses: "fa-brands fa-dropbox",
    domain: "dropbox.com",
    backgroundColor: "#0063FF",
    color: "#f9f9f9",
  },
  {
    name: "GitHub",
    iconClasses: "fa-brands fa-github",
    domain: "github.com",
    backgroundColor: "black",
    color: "white",
  },
  {
    name: "Bitbucket",
    iconClasses: "fa-brands fa-bitbucket",
    domain: "bitbucket.org",
    backgroundColor: "#0052CC",
    color: "#f9f9f9",
  },
  {
    name: "Slack",
    iconClasses: "fa-brands fa-slack",
    domain: "slack.com",
    backgroundColor: "#4A154B",
    color: "#f9f9f9",
  },
  {
    name: "Stripe",
    iconClasses: "fa-brands fa-stripe",
    domain: "stripe.com",
    backgroundColor: "#6772E5",
    color: "#f9f9f9",
  },
  {
    name: "Apple",
    iconClasses: "fa-brands fa-apple",
    domain: "apple.com",
    color: "black",
  },
  {
    name: "Docker",
    iconClasses: "fa-brands fa-docker",
    domain: "docker.com",
    backgroundColor: "#2496ED",
    color: "#f9f9f9",
  },
  {
    name: "Stack Overflow",
    iconClasses: "fa-brands fa-stack-overflow",
    domain: "stackoverflow.com",
    backgroundColor: "#F48024",
    color: "#f9f9f9",
  },
  {
    name: "AWS",
    iconClasses: "fa-brands fa-aws",
    domain: "aws.amazon.com",
    backgroundColor: "#232F3E",
    color: "#f9f9f9",
  },
  {
    name: "Amazon",
    iconClasses: "fa-brands fa-amazon",
    domain: "amazon.com",
    backgroundColor: "#FF9900",
    color: "#f9f9f9",
  },
  {
    name: "Amazon",
    iconClasses: "fa-brands fa-amazon",
    domain: "amazon.ca",
    backgroundColor: "#FF9900",
    color: "#f9f9f9",
  },
  {
    name: "Salesforce",
    iconClasses: "fa-brands fa-salesforce",
    domain: "salesforce.com",
    backgroundColor: "#1798C1",
    color: "#f9f9f9",
  },
  {
    name: "PayPal",
    iconClasses: "fa-brands fa-paypal",
    domain: "paypal.com",
    backgroundColor: "#003087",
    color: "#f9f9f9",
  },
  {
    name: "Behance",
    iconClasses: "fa-brands fa-behance",
    domain: "behance.net",
    backgroundColor: "black",
    color: "white",
  },
  {
    name: "Kickstarter",
    iconClasses: "fa-brands fa-kickstarter-k",
    domain: "kickstarter.com",
    backgroundColor: "#07CE78",
    color: "#f9f9f9",
  },
  {
    name: "EverNote",
    iconClasses: "fa-brands fa-evernote",
    domain: "evernote.com",
    backgroundColor: "#00A82D",
    color: "#f9f9f9",
  },
  {
    name: "FedEx",
    iconClasses: "fa-brands fa-fedex",
    domain: "fedex.com",
    backgroundColor: "#660099",
    color: "#f9f9f9",
  },
  {
    name: "dhl",
    iconClasses: "fa-brands fa-dhl",
    domain: "dhl.com",
    backgroundColor: "#FFCB00",
    color: "#D40411",
  },
  {
    name: "dhl",
    iconClasses: "fa-brands fa-dhl",
    domain: "dhl.ca",
    backgroundColor: "#FFCB00",
    color: "#D40411",
  },
  {
    name: "USPS",
    iconClasses: "fa-brands fa-usps",
    domain: "usps.com",
    backgroundColor: "#343266",
    color: "#f9f9f9",
  },
  {
    name: "Cloudflare",
    iconClasses: "fa-brands fa-cloudflare",
    domain: "cloudflare.com",
    backgroundColor: "#F38020",
    color: "#f9f9f9",
  },
  {
    name: "SignNow",
    iconURL: "/images/signnow.png",
    domain: "signnow.com",
    backgroundColor: "#0a7acc",
    color: "white",
  },
  {
    name: "The Xchange Platform",
    iconURL: "/images/logo_transparent.png",
    domain: "xchanges",
    backgroundColor: "#62629C",
    color: "#ffffff",
  },
  {
    name: "PDF",
    iconClasses: "fa-solid fa-file-pdf",
    domain: "pdf",
    backgroundColor: "#597CFF",
    color: "#EBEFFF",
  },
  {
    name: "PNG",
    iconClasses: "fa-solid fa-image",
    domain: "png",
    backgroundColor: "#597CFF",
    color: "#EBEFFF",
  },
  {
    name: "JPEG",
    iconClasses: "fa-solid fa-image",
    domain: "jpeg",
    backgroundColor: "#597CFF",
    color: "#EBEFFF",
  },
  {
    name: "JPG",
    iconClasses: "fa-solid fa-image",
    domain: "jpg",
    backgroundColor: "#597CFF",
    color: "#EBEFFF",
  },
];

export const getBrandingIconFromURL = (url: string) => {
  return brandingIcons.find((brandingIcon) => {
    return url.includes(brandingIcon.domain);
  });
};
