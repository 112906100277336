import { ReactNode } from "react";
import "./IconCard.scss";

const IconCard = ({
  iconClasses,
  iconColor,
  title,
  children,
  className,
}: {
  iconClasses: string;
  iconColor?: string;
  title: string;
  children: ReactNode;
  className?: string;
}) => (
  <div className={`Icon-Card ${className}`}>
    <div className="Icon-Card__Header">
      <div
        className="Icon-Card__Header__Icon"
        style={{
          backgroundColor: `${iconColor}20`,
        }}
      >
        <i className={iconClasses} style={{ color: iconColor }} />
      </div>
      <div>{title}</div>
    </div>
    <div className="Icon-Card__Content">{children}</div>
  </div>
);

export default IconCard;
