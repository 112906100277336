import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IBillWrite } from "@interfaces/IBill";
import BillService from "@services/BillService";

interface IMutationVars {
  bill: IBillWrite;
}

export const useBillUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ["updateBill"],
    ({ bill }: IMutationVars) => BillService.updateBill(bill),
    {
      onSuccess: (bill) => {
        queryClient.invalidateQueries(["bills", { id: bill.id }]);
      },
    }
  );
};
