import DatePicker, {
  CalendarContainer,
  CalendarContainerProps,
} from "react-datepicker";
import "./CustomDatePicker.scss";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { formatDateToString, resetHours } from "@helpers/date-utils";
import CustomSelect, {
  ICustomSelectOption,
} from "@components/CustomSelect/CustomSelect";

export interface CustomDatePickerType {
  label: string;
  value: string;
}

export interface CustomDatePickerProps {
  name: string;
  selectsRange?: boolean;
  startDate?: Date | null;
  endDate?: Date | null;
  minDate?: Date | null;
  maxDate?: Date | null;
  placeholderText?: string;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  onAfterChange?: (date: Date | [Date | null, Date | null] | null) => void;
  popperModifiers?: any;
  hideInput?: boolean;
  disabled?: boolean;
  monthsShown?: number;
  typeName?: string;
  typeOptions?: CustomDatePickerType[];
  onAfterTypeChange?: (type: string) => void;
}

const CustomDatePicker = ({
  name,
  selectsRange = false,
  startDate = null,
  endDate = null,
  minDate = null,
  maxDate = null,
  placeholderText = "",
  selectsStart = false,
  selectsEnd = false,
  popperModifiers = {},
  onAfterChange = () => {},
  hideInput = false,
  disabled = false,
  monthsShown = 1,
  typeName,
  typeOptions,
  onAfterTypeChange,
}: CustomDatePickerProps) => {
  const { setValue, watch, control } = useFormContext();

  if (selectsRange) {
    watch(name);
  }

  const CustomDatePickerContainer = ({
    className,
    children,
  }: CalendarContainerProps) => {
    return (
      <CalendarContainer
        className={`Custom-DatePicker__Container ${className}`}
      >
        {children}
        {typeName && typeOptions && typeOptions.length > 0 && (
          <section className="Custom-DatePicker__Container__Footer">
            <CustomSelect
              name={typeName}
              control={control}
              options={typeOptions?.map((typeOption) => ({
                label: typeOption.label,
                value: typeOption.value,
              }))}
              onAfterChange={(option) => {
                if (onAfterTypeChange) {
                  onAfterTypeChange((option as ICustomSelectOption).value);
                }
              }}
            />
          </section>
        )}
      </CalendarContainer>
    );
  };

  return (
    <DatePicker
      selected={!selectsRange && watch(name)}
      disabled={disabled}
      onChange={(date) => {
        setValue(name, date);
        onAfterChange(date);
      }}
      selectsRange={selectsRange}
      startDate={startDate}
      endDate={endDate}
      minDate={minDate}
      maxDate={maxDate}
      selectsStart={selectsStart}
      selectsEnd={selectsEnd}
      monthsShown={monthsShown}
      placeholderText={placeholderText}
      className={`Custom-DatePicker ${
        hideInput && "Custom-DatePicker--hide-input"
      }`}
      wrapperClassName="Custom-DatePicker__Wrapper"
      calendarClassName="Custom-DatePicker__Calendar"
      dayClassName={(currentDate) => {
        let classes = "Custom-DatePicker__Calendar__Day";
        if (minDate && currentDate < resetHours(minDate)) {
          classes += " Custom-DatePicker__Calendar__Day--Disabled";
        }
        if (maxDate && currentDate > resetHours(maxDate)) {
          classes += " Custom-DatePicker__Calendar__Day--Disabled";
        }
        if (
          startDate &&
          endDate &&
          currentDate >= startDate &&
          currentDate <= endDate
        ) {
          classes += " Custom-DatePicker__Calendar__Day--Selected";
        }

        return classes;
      }}
      weekDayClassName={() => "Custom-DatePicker__Calendar__Week-Day"}
      dateFormat="dd-MM-yy"
      calendarContainer={CustomDatePickerContainer}
      renderCustomHeader={({
        monthDate,
        customHeaderCount,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => {
        return (
          <div className="Custom-DatePicker__Calendar__Header">
            <>
              <Button
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                variant="outline-light"
                size="sm"
                style={{
                  visibility:
                    monthsShown === 1 ||
                    (monthsShown > 1 && customHeaderCount === 0)
                      ? "visible"
                      : "hidden",
                }}
              >
                <i className="fa-light fa-arrow-left-long" />
              </Button>

              <div className="Custom-DatePicker__Calendar__Header__Date">
                {formatDateToString(monthDate, {
                  month: "short",
                  year: "numeric",
                })}
              </div>

              <Button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                variant="outline-light"
                size="sm"
                style={{
                  visibility:
                    monthsShown === 1 ||
                    (monthsShown > 1 && customHeaderCount === monthsShown - 1)
                      ? "visible"
                      : "hidden",
                }}
              >
                <i className="fa-light fa-arrow-right-long" />
              </Button>
            </>
          </div>
        );
      }}
      popperModifiers={
        popperModifiers ?? [
          {
            name: "flip",
            enabled: false,
          },
        ]
      }
    />
  );
};

export default CustomDatePicker;
