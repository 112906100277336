import { useMutation, useQueryClient } from "@tanstack/react-query";
import XchangeTermService from "@services/XchangeTermService";

interface IMutationVars {
  xchangeId: number;
  xchangeTermId: number;
}

export const useXchangeTermDelete = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ xchangeId, xchangeTermId }: IMutationVars) =>
      XchangeTermService.deleteXchangeTerm(xchangeId, xchangeTermId),
    {
      onSuccess: (_, { xchangeId }) => {
        queryClient.invalidateQueries(["xchanges", { id: xchangeId }]);
        queryClient.invalidateQueries(["xchange-terms"]);
      },
    }
  );
};
