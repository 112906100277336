import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IXpertTermCreateUpdate } from "@interfaces/terms/IXpertTerm";
import XpertTermService from "@services/XpertTermService";

export const useXpertTermCreate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (term: IXpertTermCreateUpdate) => XpertTermService.createXpertTerm(term),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["xpert-terms"]);
      },
    }
  );
};
