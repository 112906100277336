import IOnboardingInvitation from "@interfaces/IOnboardingInvitation";
import {
  XpertOnboardingAccountCreationFormValues,
  XpertOnboardingFormValues,
} from "@pages/Onboardings/XpertOnboardingPage/XpertOnboardingConfigs";
import Service from "@services/Service";

export default class OnboardingInvitationService extends Service {
  public static async getOnboardingInvitation(token: string) {
    const response = await this.axios.get<IOnboardingInvitation>(
      `/invitations/onboarding/${token}`
    );
    return response.data;
  }

  public static async updateOnboardingInvitation(
    token: string,
    data: XpertOnboardingFormValues | XpertOnboardingAccountCreationFormValues
  ) {
    const response = await this.axios.patch<any>(
      `/invitations/onboarding/${token}`,
      {
        ...data,
      }
    );
    return response.data;
  }

  public static async validateOnboardingInvitationData(
    token: string,
    data: {
      email?: string;
      company_name?: string;
      password?: string;
    }
  ) {
    const response = await this.axios.post<{}>(
      `/invitations/onboarding/${token}`,
      {
        ...data,
      }
    );
    return response.data;
  }
}
