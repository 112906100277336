import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Page from "@components/Page/Page";
import { useXchange } from "@hooks/xchange/useXchange";
import XchangeOverview from "./XchangeOverview/XchangeOverview";
import { XchangeTypeSelect } from "./XchangeTypeSelect/XchangeTypeSelect";
import PageTab from "@components/PageTab/PageTab";
import NotFoundPage from "@pages/NotFoundPage";
import XchangeDetails from "./XchangeDetails/XchangeDetails";
import XchangeBilling from "./XchangeBilling/XchangeBilling";
import XchangeSOW from "@pages/Xchange/XchangeEdit/XchangeSOW/XchangeSOW";
import { formatDateToString } from "@helpers/date-utils";
import InvoiceCreationModal from "@pages/Xchange/XchangeEdit/XchangeBilling/InvoiceCreationModal/InvoiceCreationModal";

const XchangeEditPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams<{
    id: string;
    tab: string;
  }>();
  const { id: xchangeId, tab: activeTab } = params;

  const creationMode = xchangeId === "create";

  useEffect(() => {
    if (!activeTab) {
      navigate(`/xchanges/${xchangeId}/overview`, { replace: true });
    }
  }, [xchangeId, activeTab, navigate]);

  const [xchangeType, setXchangeType] = useState<string>();

  const {
    isLoading: isXchangeLoading,
    data: xchange,
    isError: xchangeError,
  } = useXchange(parseInt(xchangeId!), {
    enabled: !!xchangeId && !creationMode,
    retry: false,
  });

  const [showInvoiceCreationModal, setShowInvoiceCreationModal] =
    useState(false);

  return xchangeError ? (
    <NotFoundPage />
  ) : (
    <Page
      title={creationMode ? t("form.xchange_edit.title.create") : undefined}
      subTitle={`${t("last_updated")}: ${
        xchange?.updated_at
          ? formatDateToString(new Date(xchange?.updated_at))
          : "--"
      }`}
      browserTabTitle={
        creationMode ? t("form.xchange_edit.title.create") : xchange?.name
      }
      defaultTabKey={activeTab}
      tabs={[
        {
          tabKey: "overview",
          label: t("nav.xchange.details.overview"),
        },
        {
          tabKey: "billing",
          label: t("nav.xchange.details.billing"),
        },
        {
          tabKey: "sow",
          label: t("nav.xchange.admin.sow"),
        },
      ]}
      onTabChange={(tabKey) => {
        navigate(`/xchanges/${xchangeId}/${tabKey}`);
      }}
      sideBarContent={<XchangeOverview xchange={xchange} tab={activeTab} />}
      rightHeaderContent={
        activeTab === "billing" ? (
          <>
            <Button
              type="button"
              disabled={!xchange || !xchange.owner?.has_xero_integration}
              onClick={() => setShowInvoiceCreationModal(true)}
            >
              <i className="fa-regular fa-file-invoice me-2"></i>
              {t("billing.invoice.generate")}
            </Button>

            {showInvoiceCreationModal && xchange && (
              <InvoiceCreationModal
                xchange={xchange}
                show={showInvoiceCreationModal}
                onClose={() => setShowInvoiceCreationModal(false)}
              />
            )}
          </>
        ) : undefined
      }
    >
      {(!xchangeId && !xchangeType) || (creationMode && !xchangeType) ? (
        <XchangeTypeSelect onChangeType={(type) => setXchangeType(type)} />
      ) : xchangeId && isXchangeLoading && !creationMode ? (
        <div className="text-center mt-4 mb-4">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          {(activeTab === "overview" || (creationMode && xchangeType)) && (
            <PageTab tabKey={"overview"}>
              <XchangeDetails xchange={xchange} xchangeType={xchangeType} />
            </PageTab>
          )}
          {activeTab === "billing" && (
            <PageTab tabKey={"billing"}>
              <XchangeBilling xchange={xchange} />
            </PageTab>
          )}
          {activeTab === "sow" && (
            <PageTab tabKey={"sow"}>
              <XchangeSOW xchange={xchange} />
            </PageTab>
          )}
        </>
      )}
    </Page>
  );
};

export default XchangeEditPage;
