import React from "react";
import { Card, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./NavigationBar.scss";
import NavigationBarItem from "@components/NavigationBarItem/NavigationBarItem";
import NavigationBarGroup from "@components/NavigationBarGroup/NavigationBarGroup";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import AuthService from "@services/AuthService";

const NavigationBar = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return (
    <Card className="Navigation-Bar border-0">
      <div className="Navigation-Bar__Inner">
        <Card.Header className="Navigation-Bar__Header p-0">
          <div className="mb-2">
            <Link to="/">
              <Image
                className="Navigation-Bar__Header__Logo"
                src="/logo-light.svg"
              />
            </Link>
          </div>
        </Card.Header>
        <Card.Body>
          <NavigationBarGroup label={t("nav.execution")}>
            <NavigationBarItem
              label={t("nav.xchanges")}
              icon="fa-solid fa-right-left"
              path="/xchanges"
            />
            <NavigationBarItem
              label={t("nav.products")}
              icon={"fa-regular fa-layer-group"}
              path="/products"
            />
            <NavigationBarItem
              label={t("nav.terms")}
              icon={"far fa-clipboard"}
              path="/terms"
            />
          </NavigationBarGroup>
          <NavigationBarGroup label={t("nav.relationships")}>
            <NavigationBarItem
              label={t("nav.compagnies")}
              icon="fa-regular fa-building"
              path="/companies"
            />
            <NavigationBarItem
              label={t("nav.people")}
              icon="fa-regular fa-user-group"
              path="/people"
            />
          </NavigationBarGroup>
        </Card.Body>
        <Card.Footer className="Navigation-Bar__Footer">
          <NavigationBarGroup>
            <NavigationBarItem
              label={t("nav.resources")}
              icon="fa-regular fa-book"
              path="/resources"
            />

            <NavigationBarItem
              label={t("nav.settings")}
              icon="fa-regular fa-gear"
              path="/settings"
            />

            <NavigationBarItem
              label={t("nav.my_account")}
              icon="fa-regular fa-circle-user"
              path="/my-account"
            />

            <NavigationBarItem
              label={t("nav.logout")}
              icon="fa-light fa-right-from-bracket"
              path="/login"
              onClick={() => {
                queryClient.clear();
                AuthService.logout();
              }}
            />
          </NavigationBarGroup>
        </Card.Footer>
      </div>
    </Card>
  );
};

export default NavigationBar;
