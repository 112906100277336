import { useMutation, useQueryClient } from "@tanstack/react-query";
import IEpic from "@interfaces/IEpic";
import XchangeService from "@services/XchangeService";

interface IMutationVars {
  xchangeId: number;
  epic: IEpic;
}

export const useEpicCreate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ["updateXchange"],
    ({ xchangeId, epic }: IMutationVars) =>
      XchangeService.createEpic(xchangeId, epic),
    {
      onSuccess: (_, { xchangeId }) => {
        queryClient.invalidateQueries(["xchanges", { xchangeId }, "epics"]);
      },
    }
  );
};
