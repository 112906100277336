import { Button } from "react-bootstrap";
import WizardModal from "@components/WizardModal/WizardModal";
import { useTranslation } from "react-i18next";
import {
  IContractGenerationHook,
  IContractGenerationSubject,
} from "@hooks/contract/useContractGeneration";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { FormProvider, useForm } from "react-hook-form";
import ContractPartySignatorySelection, {
  IPartySignatory,
  PartySignatoryValidationSchema,
} from "./ContractPartySignatorySelection/ContractPartySignatorySelection";
import useGetSubjectParties from "@hooks/contract/useGetSubjectContractParties";
import AxiosErrorAlert from "@components/AxiosErrorAlert/AxiosErrorAlert";

export interface IContractSignatoriesWizardStringsProps {
  selectSignatoriesHelp?: string;
  sentForSigning?: string;
  sentForSigningHelp?: string;
}

export interface ContractSignatoriesFormValues {
  partySignatories: IPartySignatory[];
}

const SignatoriesValidationSchema = Joi.object({
  partySignatories: Joi.array().items(PartySignatoryValidationSchema),
});

const ContractSignatoriesWizard = ({
  show,
  contractGenerationHook,
  subject,
  onClose,
  onFinish,
  onSignatorieSelectionFinish,
  areSignatoriesCreationLoading,
  contractTranslationRootKey = "contracts",
}: {
  show: boolean;
  contractGenerationHook: IContractGenerationHook;
  subject: IContractGenerationSubject;
  onClose: () => void;
  onFinish: () => void;
  onSignatorieSelectionFinish: (
    data: ContractSignatoriesFormValues,
    callback: Function
  ) => void;
  areSignatoriesCreationLoading: boolean;
  contractTranslationRootKey?: string;
}) => {
  const { t } = useTranslation();

  const { error } = contractGenerationHook;

  const parties = useGetSubjectParties(subject, contractGenerationHook);

  const formMethods = useForm<ContractSignatoriesFormValues>({
    mode: "onChange",
    resolver: joiResolver(SignatoriesValidationSchema),
    defaultValues: {
      partySignatories: [],
    },
  });

  const {
    getValues,
    formState: { isValid },
  } = formMethods;

  return (
    <WizardModal
      show={show}
      onClose={() => onClose()}
      onCloseText={t("actions.cancel")}
      onFinish={() => onFinish()}
      onFinishText={t("actions.close")}
      steps={[
        {
          title: t(
            `${contractTranslationRootKey}.dashboard.signnow.wizard.title`
          ),
          content: (
            <>
              <h3 className="mb-6">
                {t(
                  `${contractTranslationRootKey}.dashboard.signnow.wizard.select_signatories`
                )}
              </h3>
              <p>
                {t(
                  `${contractTranslationRootKey}.dashboard.signnow.wizard.select_signatories_help`
                )}
              </p>
              <FormProvider {...formMethods}>
                {parties?.map((party, index) => (
                  <ContractPartySignatorySelection
                    key={`party_${party.id!}`}
                    party={party}
                    index={index}
                    disabled={areSignatoriesCreationLoading}
                  />
                ))}
              </FormProvider>

              <AxiosErrorAlert
                response={error}
                translationPrefix="errors.codes."
              />
            </>
          ),
          onFinishText: t(
            `${contractTranslationRootKey}.dashboard.signnow.wizard.send_for_signing`
          ),
          onFinish: (callback) => {
            onSignatorieSelectionFinish(getValues(), callback!);
          },
          disableGoBack: areSignatoriesCreationLoading,
          isLoading: areSignatoriesCreationLoading,
          isCompleted:
            parties?.length === getValues("partySignatories").length && isValid,
        },
        {
          title: t(
            `${contractTranslationRootKey}.dashboard.signnow.wizard.title`
          ),
          hideStepNumber: true,
          disableGoBack: true,
          isCompleted: true,
          content: (
            <>
              <h3 className="mt-4 mb-6">
                <i className="fa-solid fa-circle-check me-3 text-success-default" />
                {t(
                  `${contractTranslationRootKey}.dashboard.signnow.wizard.sent_for_signing`
                )}
              </h3>
              <p>
                {t(
                  `${contractTranslationRootKey}.dashboard.signnow.wizard.sent_for_signing_help`
                )}
              </p>
            </>
          ),
          extraButtons: (
            <>
              <Button variant="outline-light" disabled>
                {t(
                  `${contractTranslationRootKey}.dashboard.signnow.wizard.edit_recipients`
                )}
              </Button>
            </>
          ),
        },
      ]}
    />
  );
};

export default ContractSignatoriesWizard;
