import { useState } from "react";
import {
  Button,
  OverlayTrigger,
  Spinner,
  Stack,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ProductRow from "./ProductRow/ProductRow";
import TermRow from "./TermRow/TermRow";
import "./XchangeDeliverables.scss";
import XchangeProductDetailModal from "./XchangeProductDetailModal";
import CardPlaceholder from "@components/CardPlaceholder/CardPlaceholder";
import IXchange from "@interfaces/IXchange";
import { useXchangeProducts } from "@hooks/product/useXchangeProducts";
import { useXchangeTerms } from "@hooks/term/useXchangeTerms";
import { useXchangeTermDelete } from "@hooks/term/useXchangeTermDelete";
import { useXchangeProductDelete } from "@hooks/product/useXchangeProductDelete";
import IXchangeTerm from "@interfaces/terms/IXchangeTerm";
import IXchangeProduct from "@interfaces/products/IXchangeProduct";
import { useEpics } from "@hooks/xchange/useEpics";
import IEpic from "@interfaces/IEpic";
import useXchangeSowStatus from "@hooks/xchange/useXchangeSowStatus";
import { ProtectedContractTypeCodes } from "@interfaces/IContractType";
import useContractGeneration from "@hooks/contract/useContractGeneration";
import {
  getXchangeContractSubjects,
  getXchangeXpertStats,
} from "@helpers/xchange-utils";
import XchangeSowStatus from "@enum/XchangeSowStatus";
import XchangeTermModal from "@pages/Xchange/XchangeEdit/XchangeDeliverables/XchangeTermModal";

const XchangeDeliverables = ({ xchange }: { xchange?: IXchange }) => {
  const { t } = useTranslation();

  const [showProductDetailsModal, setShowProductDetailsModal] = useState(false);
  const [showTermDetailsModal, setShowTermDetailsModal] = useState(false);
  const [termToEdit, setTermToEdit] = useState<IXchangeTerm | undefined>(
    undefined
  );
  const [productToEdit, setProductToEdit] = useState<IXchangeProduct | null>(
    null
  );

  const { isLoading: areProductsLoading, data: products } = useXchangeProducts(
    xchange?.id!,
    {
      enabled: !!xchange,
    }
  );
  const { isLoading: areTermsLoading, data: terms } = useXchangeTerms(
    {
      xchange: xchange?.id!,
    },
    {
      enabled: !!xchange,
    }
  );

  const { data: epics } = useEpics(xchange?.id!, {
    enabled: !!xchange?.id!,
  });

  const deleteXchangeTermMutation = useXchangeTermDelete();
  const deleteTerm = (termToDelete: number) => {
    deleteXchangeTermMutation.mutate({
      xchangeId: xchange?.id!,
      xchangeTermId: termToDelete,
    });
  };

  const deleteXchangeProductMutation = useXchangeProductDelete();
  const deleteProduct = (productToDelete: number) => {
    deleteXchangeProductMutation.mutate({
      xchangeId: xchange?.id!,
      xchangeProductId: productToDelete,
    });
  };

  const contractGenerationHook = useContractGeneration(
    getXchangeContractSubjects(xchange),
    [
      ProtectedContractTypeCodes.XPERT_SOW,
      ProtectedContractTypeCodes.CLIENT_SOW,
    ]
  );

  const xchangeSowStatus = useXchangeSowStatus(contractGenerationHook);

  return (
    <>
      <Stack direction="horizontal" gap={3} className="mb-5">
        <h5 className="mb-0">
          {t("form.xchange_edit.sub_titles.products_terms")}
        </h5>
        <Button
          className="ms-auto Xchange-Edit__Products__icon"
          variant="outline-light"
          size="sm"
          onClick={() => setShowTermDetailsModal(true)}
          disabled={xchangeSowStatus === XchangeSowStatus.SIGNED}
        >
          {t("form.xchange_edit.sub_titles.add_term")}
        </Button>
        <Button
          size="sm"
          onClick={() => setShowProductDetailsModal(true)}
          disabled={xchangeSowStatus === XchangeSowStatus.SIGNED}
        >
          <i className="fa-regular fa-plus me-2"></i>
          {t("form.xchange_edit.sub_titles.add_product")}
        </Button>
      </Stack>

      {xchange && (areProductsLoading || areTermsLoading) ? (
        <div className="text-center mt-4 mb-4">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : !terms?.length && !products?.length ? (
        <CardPlaceholder
          iconClassName={"fa-solid fa-rectangle-vertical-history"}
          title={t("form.xchange_edit.info.no_products")}
          description={t("form.xchange_edit.info.no_products_desc")}
        />
      ) : (
        <Table className="Xchange-Edit__Products__Table">
          <thead>
            <tr>
              <th className="Xchange-Edit__Products__Table__Icons"></th>
              <th
                className="pe-1"
                title={t("form.xchange_edit.deliverable_headers.product_terms")}
              >
                {t("form.xchange_edit.deliverable_headers.product_terms")}
                <div className="fw-normal">
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        {t("form.xchange_edit.info.products_terms")}
                      </Tooltip>
                    }
                  >
                    <i className="fa-regular fa-circle-info me-2"></i>
                  </OverlayTrigger>
                  {t("info")}
                </div>
              </th>
              <th>
                {t("form.xchange_edit.deliverable_headers.sub_domains")}
                <div className="fw-normal">
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        {t("form.xchange_edit.info.sub_domains")}
                      </Tooltip>
                    }
                  >
                    <i className="fa-regular fa-circle-info me-2"></i>
                  </OverlayTrigger>
                  {t("info")}
                </div>
              </th>
              <th>
                {t("form.xchange_edit.deliverable_headers.cost")}
                <div className="fw-normal">
                  <OverlayTrigger
                    overlay={
                      <Tooltip>{t("form.xchange_edit.info.cost")}</Tooltip>
                    }
                  >
                    <i className="fa-regular fa-circle-info me-2"></i>
                  </OverlayTrigger>
                  {t("info")}
                </div>
              </th>
              <th>
                {t("form.xchange_edit.deliverable_headers.duration")}
                <div className="fw-normal">
                  <OverlayTrigger
                    overlay={
                      <Tooltip>{t("form.xchange_edit.info.duration")}</Tooltip>
                    }
                  >
                    <i className="fa-regular fa-circle-info me-2"></i>
                  </OverlayTrigger>
                  {t("info")}
                </div>
              </th>
              <th>
                {t("form.xchange_edit.deliverable_headers.start_date")}
                <div className="fw-normal">
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        {t("form.xchange_edit.info.start_data")}
                      </Tooltip>
                    }
                  >
                    <i className="fa-regular fa-circle-info me-2"></i>
                  </OverlayTrigger>
                  {t("info")}
                </div>
              </th>
              <th className="Xchange-Edit__Products__Table__Actions table-actions"></th>
            </tr>
          </thead>

          <tbody className="term-rows">
            {terms?.map((term) => (
              <TermRow
                key={term.id!}
                term={term}
                onDelete={() => deleteTerm(term.id!)}
                onEdit={() => {
                  setTermToEdit(term);
                  setShowTermDetailsModal(true);
                }}
                isLockedByEpics={
                  epics?.some((epic) =>
                    epic.xperts?.includes(term.xpert_involved!)
                  ) &&
                  getXchangeXpertStats(xchange!, term.xpert_involved!)
                    .totalInvolvementCount === 1
                }
              />
            ))}
          </tbody>
          {products?.map((product) => (
            <ProductRow
              key={product.id!}
              product={product}
              onEdit={() => {
                setProductToEdit(product);
                setShowProductDetailsModal(true);
              }}
              onDelete={() => deleteProduct(product.id!)}
              isLockedByEpics={epics?.some((epic: IEpic) =>
                epic.xperts?.some(
                  (xpert) =>
                    product.terms?.some(
                      (term) => term.xpert_involved === xpert
                    ) &&
                    getXchangeXpertStats(xchange!, xpert)
                      .totalInvolvementCount === 1
                )
              )}
            />
          ))}
        </Table>
      )}

      <XchangeProductDetailModal
        show={showProductDetailsModal}
        xchange={xchange}
        productToEdit={productToEdit}
        epics={epics}
        onClose={() => {
          setShowProductDetailsModal(false);
          setProductToEdit(null);
        }}
      />

      <XchangeTermModal
        show={showTermDetailsModal}
        xchange={xchange}
        xchangeTermToEdit={termToEdit}
        epics={epics}
        onClose={() => {
          setShowTermDetailsModal(false);
          setTermToEdit(undefined);
        }}
      />
    </>
  );
};

export default XchangeDeliverables;
