import { useTranslation } from "react-i18next";
import StepCard from "@components/StepCard/StepCard";
import IXchange from "@interfaces/IXchange";
import Documents from "@components/Documents/Documents";
import XchangeDetailsForm from "@pages/Xchange/XchangeEdit/XchangeDetailsForm";
import XchangeDeliverables from "@pages/Xchange/XchangeEdit/XchangeDeliverables/XchangeDeliverables";
import XchangeEpics from "@pages/Xchange/XchangeEdit/XchangeEpics/XchangeEpics";
import XchangeMembers from "@pages/Xchange/XchangeEdit/XchangeMembers/XchangeMembers";
import XchangeStats from "@pages/Xchange/XchangeEdit/XchangeStats/XchangeStats";

const XchangeDetails = ({
  xchange,
  xchangeType,
}: {
  xchange?: IXchange;
  xchangeType?: string;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <XchangeStats xchange={xchange} className="mb-5" />

      <StepCard title={t("form.xchange_edit.sub_titles.xchange_details")}>
        <XchangeDetailsForm type={xchangeType} xchange={xchange} />
        <div className="p-4">
          <h5 className="mb-5">
            {t("form.xchange_edit.sub_titles.documents")}
          </h5>
          <Documents xchange={xchange} />
        </div>
      </StepCard>

      <StepCard title={t("form.xchange_edit.sub_titles.products")}>
        <XchangeDeliverables xchange={xchange} />
      </StepCard>

      <StepCard title={t("form.xchange_edit.sub_titles.epics_schedule")}>
        <XchangeEpics xchange={xchange} />
      </StepCard>

      <StepCard title={t("xchanges.team.title")}>
        <XchangeMembers xchange={xchange} />
      </StepCard>
    </>
  );
};

export default XchangeDetails;
