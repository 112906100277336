import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IClientCreateUpdate } from "@interfaces/IClient";
import ClientService from "@services/ClientService";
import { parseModelHyperLink } from "@helpers/model-utils";

interface IMutationVars {
  client: IClientCreateUpdate;
}

export const useClientCreate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ client }: IMutationVars) => ClientService.createClient(client),
    {
      onSuccess: (_, variables) => {
        if (variables.client.company_person) {
          const companyPersoInfos = parseModelHyperLink(
            variables.client.company_person
          );

          if (companyPersoInfos) {
            queryClient.invalidateQueries([
              companyPersoInfos.model,
              { id: companyPersoInfos.id },
            ]);
          }
        }

        queryClient.invalidateQueries(["clients"]);
      },
    }
  );
};
