import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import IXchangeProduct from "@interfaces/products/IXchangeProduct";
import XchangeProductService from "@services/XchangeProductService";

export const useXchangeProducts = (
  xchangeId: number,
  options?: Pick<
    UseQueryOptions<IXchangeProduct[], Error>,
    "enabled" | "onSuccess"
  >
) =>
  useQuery(
    ["xchanges", { id: xchangeId }, "products"],
    () => XchangeProductService.getXchangeProducts(xchangeId),
    options
  );
