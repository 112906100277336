import { AxiosError } from "axios";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const AxiosErrorAlert = ({
  response,
  translationPrefix = "",
  className,
}: {
  response: unknown;
  translationPrefix?: string;
  className?: string;
}) => {
  const {
    t,
    i18n: { exists },
  } = useTranslation();

  if (
    response instanceof AxiosError &&
    response.code === "ERR_BAD_REQUEST" &&
    response?.response?.data.code
  ) {
    return (
      <Alert variant="danger" className={`${className || "mt-6 mb-0"}`}>
        <i className="fa-solid fa-exclamation-triangle me-4"></i>
        {exists(`${translationPrefix}${response?.response?.data.code}`) ? (
          t(`${translationPrefix}${response?.response?.data.code}`, {
            ...response?.response?.data,
          })
        ) : (
          <>
            {t("errors.generic_with_code", {
              code: response.response.data.code,
            })}
          </>
        )}
      </Alert>
    );
  } else if (
    response instanceof AxiosError &&
    response.code === "ERR_BAD_REQUEST"
  ) {
    return (
      <Alert variant="danger" className={`${className || "mt-6 mb-0"}`}>
        <i className="fa-solid fa-exclamation-triangle me-4"></i>
        {Object.keys(response?.response?.data).map((key) => (
          <span key={key}>
            {exists(`${translationPrefix}${key}`) &&
              `${t(`${translationPrefix}${key}`)} : `}
            {response?.response?.data[key]}
          </span>
        ))}
      </Alert>
    );
  } else if (
    response instanceof AxiosError &&
    response.code === "ERR_BAD_RESPONSE"
  ) {
    return (
      <Alert variant="danger" className={`${className || "mt-6 mb-0"}`}>
        <i className="fa-solid fa-exclamation-triangle me-4"></i>
        {response?.response?.data.code
          ? t("errors.generic_with_code", { code: response.response.data.code })
          : t("errors.generic")}
      </Alert>
    );
  } else {
    return <> </>;
  }
};

export default AxiosErrorAlert;
