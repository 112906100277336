import { IContractGenerationHook } from "@hooks/contract/useContractGeneration";
import AxiosErrorAlert from "@components/AxiosErrorAlert/AxiosErrorAlert";
import ContractReviewDashboard from "./ContractReviewDashboard/ContractReviewDashboard";
import ContractSignatureDashboard from "./ContractSignatureDashboard/ContractSignatureDashboard";

const ContractDashboard = ({
  contractGenerationHook,
  contractTranslationRootKey = "contracts",
}: {
  contractGenerationHook: IContractGenerationHook;
  contractTranslationRootKey?: string;
}) => {
  const { subjects, error } = contractGenerationHook;

  return (
    <div>
      <ContractReviewDashboard
        subjects={subjects}
        contractGenerationHook={contractGenerationHook}
        contractTranslationRootKey={contractTranslationRootKey}
      />

      <ContractSignatureDashboard
        subjects={subjects}
        contractGenerationHook={contractGenerationHook}
        contractTranslationRootKey={contractTranslationRootKey}
      />

      <AxiosErrorAlert response={error} translationPrefix="errors.codes." />
    </div>
  );
};

export default ContractDashboard;
