import { getXchangeXpertIds } from "@helpers/xchange-utils";
import { usePersonCreate } from "@hooks/person/usePersonCreation";
import { usePersonUpdate } from "@hooks/person/usePersonUpdate";
import { useXchangeTeamMemberCreate } from "@hooks/xchange/useXchangeTeamMemberCreate";
import { useXchangeTeamMemberUpdate } from "@hooks/xchange/useXchangeTeamMemberUpdate";
import { useXpertTeamMemberCreate } from "@hooks/xpert/useXpertTeamMemberCreate";
import { useXpertTeamMembers } from "@hooks/xpert/useXpertTeamMembers";
import { useXperts } from "@hooks/xpert/useXperts";
import IPerson from "@interfaces/IPerson";
import IXchange from "@interfaces/IXchange";
import { IXchangeTeamMember } from "@interfaces/IXchangeTeamMember";
import MemberModal, {
  MemberFormValues,
} from "@pages/Company/CompanyEditPage/MemberModal/MemberModal";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const XchangeMemberModal = ({
  xchange,
  memberToEdit,
  currentMembers,
  show,
  onClose,
}: {
  xchange: IXchange;
  memberToEdit?: IXchangeTeamMember;
  currentMembers: IXchangeTeamMember[];
  show: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  const xchangeXpertIds = useMemo(() => {
    return getXchangeXpertIds(xchange);
  }, [xchange]);

  const [selectedXpert, setSelectedXpert] = useState<number>();

  const [people, setPeople] = useState<IPerson[]>([]);

  const { data: xpertTeamMembers } = useXpertTeamMembers(
    selectedXpert!,
    {
      person__not__in: currentMembers
        .map((member) => member.person?.id)
        .join(","),
    },
    {
      enabled: show && !!selectedXpert,
      onSuccess: (xpertTeamMembers) => {
        setPeople(
          xpertTeamMembers.map((member) => {
            return member.person!;
          })
        );
      },
    }
  );
  const { data: xperts } = useXperts(
    {
      id__in: xchangeXpertIds?.join(","),
    },
    { enabled: show }
  );

  const createXchangeTeamMemberMutation = useXchangeTeamMemberCreate();
  const updateXchangeTeamMemberMutation = useXchangeTeamMemberUpdate();
  const createPersonMutation = usePersonCreate();
  const updatePersonMutation = usePersonUpdate();
  const createXpertTeamMemberMutation = useXpertTeamMemberCreate();

  const saveWithPersonSelection = (
    selectedPeople: number[],
    selectedXpert?: number | null,
    onSuccessCallBack?: Function
  ) => {
    for (const personId of selectedPeople) {
      const person = people!.find((p) => p.id === personId);
      const xpertTeamMember = xpertTeamMembers?.find(
        (member) => member.person?.id === person?.id
      );

      if (person && selectedXpert && xpertTeamMember) {
        createXchangeTeamMemberMutation.mutate(
          {
            teamMember: {
              xchange: xchange.id!,
              xpert: selectedXpert,
              person: person.id!,
              title: person.title,
              email: person.email,
              rate: xpertTeamMember.rate,
              currency: xpertTeamMember.currency,
              roles: xpertTeamMember.roles!,
              xchange_start_date: xchange.start_date ?? undefined,
              xchange_goal_end_date: xchange.goal_end_date ?? undefined,
            },
          },
          {
            onSuccess: () => {
              onSuccessCallBack?.();
            },
          }
        );
      }
    }
  };

  const saveWithNewPerson = async ({
    id,
    title,
    email,
    roles,
    rate,
    currency,
    ...member
  }: MemberFormValues) => {
    if (memberToEdit) {
      await Promise.all([
        updatePersonMutation.mutate({
          id: memberToEdit.person!.id,
          context: memberToEdit.xpert!.company_person,
          ...member,
        }),
        updateXchangeTeamMemberMutation.mutate({
          id: memberToEdit.id,
          xchange: xchange.id!,
          title: title,
          email: email,
          rate: rate,
          currency: currency,
          roles: roles,
        }),
      ]);

      onModalClose();
    } else {
      createPersonMutation.mutate(
        {
          title,
          email,
          roles,
          rate,
          currency,
          ...member,
        },
        {
          onSuccess: (person) => {
            createXpertTeamMemberMutation.mutate(
              {
                xpertId: member.xpert!,
                xpertTeamMember: {
                  person: person.id,
                  xpert: member.xpert!,
                  rate: person.rate,
                  currency: person.currency,
                  roles: person.roles,
                },
              },
              {
                onSuccess: () => {
                  createXchangeTeamMemberMutation.mutate(
                    {
                      teamMember: {
                        xchange: xchange.id!,
                        xpert: member.xpert!,
                        person: person.id!,
                        title: person.title,
                        email: person.email,
                        rate: person.rate,
                        currency: person.currency,
                        roles: person.roles ?? [],
                        xchange_start_date: xchange.start_date ?? undefined,
                        xchange_goal_end_date:
                          xchange.goal_end_date ?? undefined,
                      },
                    },
                    {
                      onSuccess: () => {
                        onModalClose();
                      },
                    }
                  );
                },
              }
            );
          },
        }
      );
    }
  };

  const isSaving = () => {
    return (
      createXchangeTeamMemberMutation.isLoading ||
      createPersonMutation.isLoading ||
      createXpertTeamMemberMutation.isLoading
    );
  };

  const getSavingError = () => {
    return (
      createXchangeTeamMemberMutation.error ||
      createPersonMutation.error ||
      createXpertTeamMemberMutation.error
    );
  };

  const onModalClose = () => {
    createXchangeTeamMemberMutation.reset();
    createPersonMutation.reset();
    createXpertTeamMemberMutation.reset();
    onClose();
  };

  return (
    <>
      <MemberModal
        people={people}
        xperts={!memberToEdit ? xperts : undefined}
        member={
          memberToEdit
            ? {
                id: memberToEdit.id,
                xpert: memberToEdit.xpert!.id,
                first_name: memberToEdit.person!.first_name,
                last_name: memberToEdit.person!.last_name,
                title: memberToEdit.title,
                pronouns: memberToEdit.person!.pronouns,
                email: memberToEdit.email,
                phone: memberToEdit.person!.phone,
                country: memberToEdit.person!.country,
                region: memberToEdit.person!.region,
                currency: memberToEdit.currency,
                rate: memberToEdit.rate,
                roles: (memberToEdit.roles ?? []).map((role) => role.id!),
              }
            : undefined
        }
        show={show}
        onXpertSelection={(xpertId) => {
          setSelectedXpert(xpertId);
        }}
        onClose={onModalClose}
        onSaveExisting={saveWithPersonSelection}
        onSaveNew={saveWithNewPerson}
        isFinishLoading={isSaving()}
        axiosError={getSavingError()}
        subtitle={
          memberToEdit
            ? t("xchanges.team.edit_member_modal_subtitle")
            : t("xchanges.team.add_member_modal_subtitle")
        }
        defaultTab="personSelection"
      />
    </>
  );
};

export default XchangeMemberModal;
