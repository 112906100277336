import AxiosErrorAlert from "@components/AxiosErrorAlert/AxiosErrorAlert";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import AuthService from "@services/AuthService";
import { AxiosError } from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./GoogleLoginText.scss";

const GoogleLoginLink = ({
  linkText,
  suffixText,
  disabled = false,
}: {
  linkText: string;
  suffixText?: string;
  disabled?: boolean;
}) => {
  const navigate = useNavigate();

  const [isLogin, setIsLogin] = useState(false);
  const [loginError, setLoginError] = useState<AxiosError>();

  const login = useGoogleLogin({
    flow: "auth-code",
    scope: process.env.REACT_APP_GOOGLE_AUTH_SCOPES,
    onSuccess: async (codeResponse) => {
      setIsLogin(true);
      try {
        await AuthService.googleLogin(codeResponse.code);
        navigate("/");
      } catch (error) {
        if (error instanceof AxiosError) {
          setLoginError(error);
          setIsLogin(false);
        }
      }
    },
    onError: () => {
      setIsLogin(false);
    },
  });

  return (
    <>
      {isLogin ? (
        <i className="fa-regular fa-spinner fa-spin me-2" />
      ) : (
        <>
          <a
            className="Google-Login-Text"
            onClick={() => {
              if (!disabled) {
                login();
              }
            }}
          >
            {linkText}
          </a>
          {suffixText}
        </>
      )}

      <AxiosErrorAlert
        response={loginError}
        translationPrefix="errors.codes."
        className="mt-4 mb-0"
      />
    </>
  );
};

const GoogleLoginText = ({
  linkText,
  suffixText,
  disabled = false,
}: {
  linkText: string;
  suffixText?: string;
  disabled?: boolean;
}) => {
  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID || ""}
    >
      <GoogleLoginLink
        linkText={linkText}
        suffixText={suffixText}
        disabled={disabled}
      />
    </GoogleOAuthProvider>
  );
};

export default GoogleLoginText;
