import { Badge } from "react-bootstrap";
import ISignNowSignature from "@interfaces/ISignNowSignature";
import { useTranslation } from "react-i18next";

enum SignatureStatus {
  SENT = "sent",
  VIEWED = "viewed",
  SIGNED = "signed",
}

const SignatureStatusBadge = ({
  signature,
}: {
  signature: ISignNowSignature;
}) => {
  const { t } = useTranslation();

  const status = (() => {
    if (signature.is_completed) return SignatureStatus.SIGNED;
    if (signature.is_seen) return SignatureStatus.VIEWED;
    return SignatureStatus.SENT;
  })();

  const color = (() => {
    switch (status) {
      case SignatureStatus.SENT:
        return "info";
      case SignatureStatus.VIEWED:
        return "warning";
      case SignatureStatus.SIGNED:
        return "success";
    }
  })();

  const icon = (() => {
    switch (status) {
      case SignatureStatus.SENT:
        return "fa-inbox-out";
      case SignatureStatus.VIEWED:
        return "fa-eye";
      case SignatureStatus.SIGNED:
        return "fa-check";
    }
  })();

  return (
    <Badge pill bg={color} text={color}>
      <i className={`fa-solid ${icon} me-1`} />
      <span className="Contract-Reviewer-List-Item__Badge--status">
        {t(`contracts.signature.status.${status}`)}
      </span>
    </Badge>
  );
};

export default SignatureStatusBadge;
