import { useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  IContractGenerationHook,
  IContractGenerationSubject,
} from "@hooks/contract/useContractGeneration";
import ContractReviewersWizard, {
  ContractReviewersFormValues,
} from "@components/ContractReviewersWizard/ContractReviewersWizard";
import { ContractStatus } from "@interfaces/IContract";
import useGetSubjectContract from "@hooks/contract/useGetSubjectContract";
import useIsSubjectContractGenerating from "@hooks/contract/useIsSubjectContractGenerating";
import useCanSubjectContractReviewersBeEdited from "@hooks/contract/useCanSubjectContractReviewersBeEdited";
import useCanSubjectContractBeRegenerated from "@hooks/contract/useCanSubjectContractBeRegenerated";

const ContractGenerationButtons = ({
  subject,
  contractGenerationHook,
  viewContractDisabled = false,
  className,
  contractTranslationRootKey = "contracts",
}: {
  subject: IContractGenerationSubject;
  contractGenerationHook: IContractGenerationHook;
  viewContractDisabled?: boolean;
  className?: string;
  contractTranslationRootKey?: string;
}) => {
  const { t } = useTranslation();

  const {
    generatePreview,
    generateFinal,
    areContractsLoading,
    isContractGenerating,
    updateReviewers,
  } = contractGenerationHook;
  const contract = useGetSubjectContract(subject, contractGenerationHook, true);

  const isSubjectContractGenerating = useIsSubjectContractGenerating(
    subject,
    contractGenerationHook
  );

  const canSubjectContractReviewersBeEdited =
    useCanSubjectContractReviewersBeEdited(subject, contractGenerationHook);
  const canSubjectContractBeRegenerated = useCanSubjectContractBeRegenerated(
    subject,
    contractGenerationHook
  );

  const [isReviewModalInEditMode, setIsReviewModalInEditMode] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);

  const generateNewPreview = async () => {
    setIsReviewModalInEditMode(false);
    if (subject) {
      await generatePreview(subject);
      setShowReviewModal(true);
    }
  };

  const onReviewerSelectionFinish = async (
    data: ContractReviewersFormValues,
    callback: Function
  ) => {
    if (subject && !isReviewModalInEditMode) {
      await generateFinal(data.reviewers, subject);
    } else {
      await updateReviewers(data.reviewers, subject);
    }
    callback();
  };

  return (
    <div className={className}>
      {contract?.status! >= ContractStatus.SENT_FOR_REVIEW && (
        <>
          <OverlayTrigger
            delay={{ show: 350, hide: 0 }}
            overlay={
              <Tooltip>
                {canSubjectContractReviewersBeEdited
                  ? t(
                      `${contractTranslationRootKey}.dashboard.contract.edit_reviewers`
                    )
                  : t(
                      `${contractTranslationRootKey}.dashboard.contract.cannot_edit_reviewers`
                    )}
              </Tooltip>
            }
          >
            <span className="me-2">
              <Button
                variant="outline-light"
                size="sm"
                onClick={() => {
                  setIsReviewModalInEditMode(true);
                  setShowReviewModal(true);
                }}
                disabled={!canSubjectContractReviewersBeEdited}
              >
                <i className="fa-solid fa-user-pen" />
              </Button>
            </span>
          </OverlayTrigger>

          <OverlayTrigger
            delay={{ show: 350, hide: 0 }}
            overlay={
              !isSubjectContractGenerating ? (
                <Tooltip>
                  {canSubjectContractBeRegenerated
                    ? t(
                        `${contractTranslationRootKey}.dashboard.contract.regenerate`
                      )
                    : t(
                        `${contractTranslationRootKey}.dashboard.contract.cannot_regenerate`
                      )}
                </Tooltip>
              ) : (
                <></>
              )
            }
          >
            <span className="me-2">
              <Button
                variant="outline-light"
                size="sm"
                onClick={() => generateNewPreview()}
                disabled={!canSubjectContractBeRegenerated}
              >
                <i
                  className={`fa-solid fa-arrows-rotate ${
                    isSubjectContractGenerating && "fa-spin"
                  }`}
                />
              </Button>
            </span>
          </OverlayTrigger>
        </>
      )}

      {(!contract ||
        (contract?.status === ContractStatus.IN_PROGRESS &&
          contract.last_generation_is_preview)) && (
        <Button
          disabled={
            !subject ||
            viewContractDisabled ||
            areContractsLoading ||
            isContractGenerating ||
            contract?.last_generation_is_preview === false
          }
          onClick={() => generateNewPreview()}
          size="sm"
        >
          {subject && isSubjectContractGenerating ? (
            <i className="fa-regular fa-spinner-third fa-spin me-2"></i>
          ) : (
            <i className="fa-solid fa-file-plus me-2"></i>
          )}
          {subject && isSubjectContractGenerating
            ? t(`${contractTranslationRootKey}.dashboard.contract.generating`)
            : t(`${contractTranslationRootKey}.dashboard.contract.generate`)}
        </Button>
      )}

      {showReviewModal && (
        <ContractReviewersWizard
          show={showReviewModal}
          contractGenerationHook={contractGenerationHook}
          subject={subject}
          editMode={isReviewModalInEditMode}
          onClose={() => {
            setShowReviewModal(false);
          }}
          onFinish={() => {
            setShowReviewModal(false);
          }}
          onReviewerSelectionFinish={(data, callback) => {
            onReviewerSelectionFinish(data, callback);
          }}
          onEditRecipientsClick={() => {
            setIsReviewModalInEditMode(true);
            setShowReviewModal(false);

            // Instruction has to be in a seperated event, due to React 18 Automatic Batching
            setTimeout(() => {
              setShowReviewModal(true);
            }, 1);
          }}
          areReviewersCreationLoading={isContractGenerating}
          contractTranslationRootKey={contractTranslationRootKey}
        />
      )}
    </div>
  );
};

export default ContractGenerationButtons;
