import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import GalleryPage from "@components/GalleryPage/GalleryPage";
import XchangeSplashScreen from "@components/XchangeSplashScreen/XchangeSplashScreen";
import IntroStep from "@pages/Onboardings/XpertOnboardingPage/IntroStep/IntroStep";
import DottedProgressBar from "@components/DottedProgressBar/DottedProgressBar";
import { useTranslation } from "react-i18next";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useOnboardingInvitation } from "@hooks/onboardingInvitation/useOnboardingInvitation";
import { Spinner } from "react-bootstrap";
import PersonStep from "@pages/Onboardings/XpertOnboardingPage/PersonStep/PersonStep";
import { FieldErrorsImpl, FormProvider, useForm } from "react-hook-form";
import {
  XpertOnboardingCommitmentStepFields,
  XpertOnboardingCompanyStepFields,
  XpertOnboardingDomainsStepFields,
  XpertOnboardingFormValues,
  XpertOnboardingPersonStepFields,
  XpertOnboardingValidationSchema,
} from "@pages/Onboardings/XpertOnboardingPage/XpertOnboardingConfigs";
import { joiResolver } from "@hookform/resolvers/joi";
import useLocalStorage from "@hooks/useLocalStorage";
import { debounce } from "lodash";
import CompanyStep from "@pages/Onboardings/XpertOnboardingPage/CompanyStep/CompanyStep";
import { useStaticMedias } from "@hooks/staticmedia/useStaticMedias";
import StaticMediaCodes from "@enum/StaticMediaCodes";
import DomainsStep from "@pages/Onboardings/XpertOnboardingPage/DomainsStep/DomainsStep";
import DomainSelector from "@pages/Onboardings/XpertOnboardingPage/DomainsStep/DomainSelector/DomainSelector";
import CommitmentStep from "@pages/Onboardings/XpertOnboardingPage/CommitmentStep/CommitmentStep";
import { useOnboardingInvitationUpdate } from "@hooks/onboardingInvitation/useOnboardingInvitationUpdate";
import CompleteStep from "@pages/Onboardings/XpertOnboardingPage/CompleteStep/CompleteStep";
import { useQueryClient } from "@tanstack/react-query";
import OnboardingInvitationStatus from "@enum/OnboardingInvitationStatus";

const XpertOnboardingPage = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { step: urlStep } = useParams<{
    step: string;
  }>();
  const [searchParams] = useSearchParams();

  const { getFromLocalStorage, saveToLocalStorage, removeFromLocalStorage } =
    useLocalStorage();

  const { data: onboardingStaticMedias } = useStaticMedias({
    code__in: [
      StaticMediaCodes.ONBOARDING_STEP_ONE_INTRO,
      StaticMediaCodes.ONBOARDING_STEP_TWO_PERSON,
      StaticMediaCodes.ONBOARDING_STEP_THREE_COMPANY,
      StaticMediaCodes.ONBOARDING_STEP_FIVE_COMMITMENT,
      StaticMediaCodes.ONBOARDING_STEP_SIX_COMPLETE,
    ].join(","),
  });

  const getOnboardingStaticMediaStepData = useCallback(
    (code: string) => {
      const static_media = onboardingStaticMedias?.find(
        (sm) => sm.code === code
      );

      if (static_media?.media?.endsWith(".mp4")) {
        return {
          videoUri: static_media.media,
          auto_play: static_media.auto_play_video,
          allow_sound: static_media.allow_sound,
        };
      } else {
        return {
          imageUri: static_media?.media,
        };
      }
    },
    [onboardingStaticMedias]
  );

  const formMethods = useForm<XpertOnboardingFormValues>({
    mode: "onChange",
    resolver: joiResolver(XpertOnboardingValidationSchema),
  });

  const {
    getValues,
    reset,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = formMethods;

  const getStepStates = useCallback(
    (errors: Partial<FieldErrorsImpl<XpertOnboardingFormValues>>) => {
      return {
        isPersonStepValid: !XpertOnboardingPersonStepFields.some(
          (field) => errors[field as keyof XpertOnboardingFormValues]
        ),
        isCompanyStepValid: !XpertOnboardingCompanyStepFields.some(
          (field) => errors[field as keyof XpertOnboardingFormValues]
        ),
        isDomainsStepValid: !XpertOnboardingDomainsStepFields.some(
          (field) => errors[field as keyof XpertOnboardingFormValues]
        ),
        isCommitmentStepValid: !XpertOnboardingCommitmentStepFields.some(
          (field) => errors[field as keyof XpertOnboardingFormValues]
        ),
      };
    },
    []
  );

  useEffect(() => {
    const subscription = watch(
      debounce(() => {
        saveToLocalStorage("onboarding", {
          token: searchParams.get("token"),
          formValues: getValues(),
        });
      }, 500)
    );
    return () => subscription.unsubscribe();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: onboardingInvitation,
    isFetching,
    isError,
  } = useOnboardingInvitation(searchParams.get("token") as string, {
    enabled: !!searchParams.get("token"),
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (onboardingInvitation) => {
      const savedOnboardingData = getFromLocalStorage("onboarding");
      if (savedOnboardingData?.token === searchParams.get("token")) {
        reset(savedOnboardingData.formValues);
      } else {
        removeFromLocalStorage("onboarding");
        reset({
          first_name: onboardingInvitation.first_name,
          last_name: onboardingInvitation.last_name,
          title: onboardingInvitation.title,
          email: onboardingInvitation.email,

          company_name: onboardingInvitation.company_name,
          company_website_url: onboardingInvitation.company_website_url,
          company_size: onboardingInvitation.company_size,
          country: onboardingInvitation.country,
          sub_domains: onboardingInvitation.sub_domains,
        });
      }

      if (
        [
          OnboardingInvitationStatus.AWAITING_FOR_REVIEW,
          OnboardingInvitationStatus.APPROVED,
        ].includes(onboardingInvitation.status)
      ) {
        setIsSubmissionSuccessful(true);
        changeStep(5);
      }
    },
    onError: () => {
      removeFromLocalStorage("onboarding");
    },
  });

  const [isSplashScreenActive, setIsSplashScreenActive] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);

  const changeStep = useCallback(
    (step: number) => {
      setIsSplashScreenActive(true);
      setCurrentStep(step);
      navigate(
        `/onboarding/${steps[step].code}?token=${searchParams.get("token")}`
      );
      window.scrollTo({ top: 0, behavior: "auto" });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, searchParams]
  );

  const goToPreviousStep = useCallback(() => {
    if (currentStep !== 0) {
      changeStep(currentStep - 1);
    }
  }, [changeStep, currentStep]);

  const goToNextStep = useCallback(() => {
    changeStep(currentStep + 1);
  }, [changeStep, currentStep]);

  const updateOnboardingInvitationMutation = useOnboardingInvitationUpdate();
  const [isSubmissionSuccessful, setIsSubmissionSuccessful] = useState(false);
  const submit = useCallback(() => {
    handleSubmit(
      (data) => {
        updateOnboardingInvitationMutation.mutate(
          {
            token: searchParams.get("token") as string,
            data,
            options: {
              invalidateInvitationQuery: true,
            },
          },
          {
            onSuccess: () => {
              setIsSubmissionSuccessful(true);
              goToNextStep();
            },
          }
        );
      },
      (errors) => {
        const {
          isPersonStepValid,
          isCompanyStepValid,
          isDomainsStepValid,
          isCommitmentStepValid,
        } = getStepStates(errors);

        if (!isPersonStepValid) {
          changeStep(1);
        } else if (!isCompanyStepValid) {
          changeStep(2);
        } else if (!isDomainsStepValid) {
          changeStep(3);
        } else if (!isCommitmentStepValid) {
          changeStep(4);
        }
      }
    )();
  }, [
    changeStep,
    getStepStates,
    goToNextStep,
    handleSubmit,
    searchParams,
    updateOnboardingInvitationMutation,
  ]);

  const steps: {
    name: string;
    code: string;
    content: ReactNode;
    videoUri?: string;
    auto_play?: boolean;
    allow_sound?: boolean;
    imageUri?: string;
    galleryContent?: ReactNode;
  }[] = useMemo(() => {
    return [
      {
        name: t("onboardings.xpert.intro.label"),
        code: "intro",
        content: <IntroStep next={goToNextStep} />,
        ...getOnboardingStaticMediaStepData(
          StaticMediaCodes.ONBOARDING_STEP_ONE_INTRO
        ),
      },
      {
        name: t("onboardings.xpert.person.label"),
        code: "person",
        content: <PersonStep back={goToPreviousStep} next={goToNextStep} />,
        ...getOnboardingStaticMediaStepData(
          StaticMediaCodes.ONBOARDING_STEP_TWO_PERSON
        ),
      },
      {
        name: t("onboardings.xpert.company.label"),
        code: "company",
        content: <CompanyStep back={goToPreviousStep} next={goToNextStep} />,
        ...getOnboardingStaticMediaStepData(
          StaticMediaCodes.ONBOARDING_STEP_THREE_COMPANY
        ),
      },
      {
        name: t("onboardings.xpert.domains.label"),
        code: "domains",
        content: <DomainsStep back={goToPreviousStep} next={goToNextStep} />,
        galleryContent: <DomainSelector />,
      },
      {
        name: t("onboardings.xpert.commitment.label"),
        code: "commitment",
        content: (
          <CommitmentStep
            back={goToPreviousStep}
            submit={submit}
            isSubmitting={
              updateOnboardingInvitationMutation.isLoading || isSubmitting
            }
          />
        ),
        ...getOnboardingStaticMediaStepData(
          StaticMediaCodes.ONBOARDING_STEP_FIVE_COMMITMENT
        ),
      },
      {
        name:
          onboardingInvitation?.is_review_required &&
          onboardingInvitation.status !== OnboardingInvitationStatus.APPROVED
            ? t("onboardings.xpert.complete.review.label")
            : t("onboardings.xpert.complete.account_creation.label"),
        code: "complete",
        content: <CompleteStep onboardingInvitation={onboardingInvitation} />,
        ...getOnboardingStaticMediaStepData(
          StaticMediaCodes.ONBOARDING_STEP_SIX_COMPLETE
        ),
      },
    ];
  }, [
    getOnboardingStaticMediaStepData,
    goToNextStep,
    goToPreviousStep,
    onboardingInvitation,
    isSubmitting,
    submit,
    t,
    updateOnboardingInvitationMutation.isLoading,
  ]);

  useEffect(() => {
    if (steps) {
      const stepToFind = urlStep ?? "intro";
      let stepToLoad = steps.findIndex((s) => s.code === stepToFind);

      if (stepToLoad !== -1) {
        if (steps[stepToLoad].code !== "complete") {
          if (isSubmissionSuccessful && !isError) {
            queryClient.invalidateQueries(["onboarding-invitation"]);
          }
        } else if (!isSubmissionSuccessful) {
          changeStep(0);
        }

        setCurrentStep(stepToLoad);
      } else {
        changeStep(0);
      }
    }
  }, [
    urlStep,
    steps,
    isSubmissionSuccessful,
    isError,
    changeStep,
    queryClient,
  ]);

  return (
    <FormProvider {...formMethods}>
      {isFetching || isError || !searchParams.get("token") ? (
        <div className="d-flex flex-column justify-content-center align-items-center  h-100">
          {isFetching && <Spinner animation="border" variant="primary" />}
          {(isError || !searchParams.get("token")) && (
            <>
              <div className="text-muted">{t("errors.invitation")}</div>
              <Link to="/">{t("login.go_to")}</Link>
            </>
          )}
        </div>
      ) : (
        <GalleryPage
          headerContent={
            <div className="pt-4 ps-8 pe-8">
              <DottedProgressBar
                currentStep={currentStep}
                steps={steps.map((s) => s.name)}
              />
            </div>
          }
          galleryVideoUri={steps[currentStep].videoUri}
          galleryVideoAutoPlay={steps[currentStep].auto_play}
          galleryVideoAllowSound={steps[currentStep].allow_sound}
          galleryImageUri={steps[currentStep].imageUri}
          customGalleryContent={steps[currentStep].galleryContent}
          hideVersionNumber={true}
        >
          {isSplashScreenActive ? (
            <XchangeSplashScreen
              callback={() => {
                setIsSplashScreenActive(false);
              }}
              className="mt-8 mb-8"
            />
          ) : (
            steps[currentStep].content
          )}
        </GalleryPage>
      )}
    </FormProvider>
  );
};

export default XpertOnboardingPage;
