import { Button, Form, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./CompleteStep.scss";
import IOnboardingInvitation from "@interfaces/IOnboardingInvitation";
import OnboardingInvitationStatus from "@enum/OnboardingInvitationStatus";
import {
  XpertOnboardingAccountCreationFormValues,
  XpertOnboardingAccountCreationValidationSchema,
} from "@pages/Onboardings/XpertOnboardingPage/XpertOnboardingConfigs";
import { useForm } from "react-hook-form";
import { useCallback, useState } from "react";
import { useOnboardingInvitationUpdate } from "@hooks/onboardingInvitation/useOnboardingInvitationUpdate";
import { Link, useNavigate } from "react-router-dom";
import { joiResolver } from "@hookform/resolvers/joi";
import AxiosErrorAlert from "@components/AxiosErrorAlert/AxiosErrorAlert";
import PasswordStrengthBar from "@components/PasswordStrengthBar/PasswordStrengthBar";
import { debounce } from "lodash";
import GoogleLoginText from "@components/GoogleLoginText/GoogleLoginText";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import PasswordInput from "@components/PasswordInput/PasswordInput";

const CompleteStep = ({
  onboardingInvitation,
}: {
  onboardingInvitation?: IOnboardingInvitation;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isPasswordTriggerDebouncing, setIsPasswordTriggerDebouncing] =
    useState(false);
  const [isPasswordValidContext, setIsPasswordValidContext] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncePasswordTrigger = useCallback(
    debounce(async () => {
      const isPasswordValid = await trigger("password");
      setIsPasswordValidContext(isPasswordValid);
      setIsPasswordTriggerDebouncing(false);
    }, 500),
    []
  );

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors, dirtyFields, isValidating },
  } = useForm<XpertOnboardingAccountCreationFormValues>({
    mode: "onChange",
    context: {
      isPasswordValid: isPasswordValidContext,
    },
    defaultValues: {
      with_password: true,
    },
    resolver: joiResolver(XpertOnboardingAccountCreationValidationSchema),
  });

  const updateOnboardingInvitationMutation = useOnboardingInvitationUpdate();
  const createAccountWithPassword = () => {
    handleSubmit((data) => {
      updateOnboardingInvitationMutation.mutate(
        {
          token: onboardingInvitation!.token!,
          data: {
            ...data,
          },
          options: {
            invalidateInvitationQuery: true,
          },
        },
        {
          onSuccess: (data) => {
            localStorage.setItem("user", JSON.stringify(data));
            localStorage.removeItem("onboarding");
            window.dispatchEvent(new Event("storage"));
            navigate("/");
          },
        }
      );
    })();
  };

  const acceptTerms = () => {
    return updateOnboardingInvitationMutation.mutate({
      token: onboardingInvitation!.token!,
      data: {
        accepted_terms: true,
      },
    });
  };

  return (
    <div className="Complete-Step fade-in">
      {onboardingInvitation?.is_review_required &&
      onboardingInvitation.status !== OnboardingInvitationStatus.APPROVED ? (
        <>
          <h3>{t("onboardings.xpert.complete.review.title")}</h3>
          <p>{t("onboardings.xpert.complete.review.description")}</p>
          <a href="https://xc.ventures/">
            <Button className="w-100">
              {t("onboardings.xpert.complete.review.return_to_site")}
            </Button>
          </a>
        </>
      ) : (
        <>
          <h3>{t("onboardings.xpert.complete.account_creation.title")}</h3>
          <Form.Group className="mb-4">
            <Form.Label>{t("login.email")}</Form.Label>
            <Form.Control
              type="text"
              value={onboardingInvitation?.email}
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>{t("login.password")}</Form.Label>

            <PasswordInput
              {...register("password")}
              onChange={(e) => {
                setIsPasswordValidContext(false);
                setIsPasswordTriggerDebouncing(true);
                debouncePasswordTrigger();
                setValue("with_password", true);
                setValue("password", e.target.value, {
                  shouldDirty: e.target.value.length > 0,
                });
                setValue("password_confirmation", "", {
                  shouldDirty: false,
                });
              }}
            />
            <PasswordStrengthBar
              error={errors.password}
              isDirty={!!dirtyFields.password}
              isValidating={isValidating || isPasswordTriggerDebouncing}
              className="mt-4 mb-4"
            />
            <Form.Label>{t("password.confirmation")}</Form.Label>
            <PasswordInput
              {...register("password_confirmation")}
              isInvalid={!!errors.password_confirmation}
              disabled={!getValues("password") || !!errors.password}
            />
            <AxiosErrorAlert
              response={updateOnboardingInvitationMutation.error}
              translationPrefix="errors.codes."
            />
            <FieldErrorMessage
              field={errors.password_confirmation}
              fieldName={t("password.confirmation")}
            />
          </Form.Group>

          <Form.Group className=" mb-4">
            <Stack direction="horizontal" gap={2}>
              <Form.Check
                id="accepted_terms"
                type="checkbox"
                {...register("accepted_terms")}
                isInvalid={!!errors.accepted_terms}
              />
              <label htmlFor="accepted_terms">
                {t(
                  "onboardings.xpert.complete.account_creation.terms_acknowledgement_part_one"
                )}
              </label>
              <Link to="/">
                {t(
                  "onboardings.xpert.complete.account_creation.terms_acknowledgement_part_two"
                )}
              </Link>
            </Stack>
            <FieldErrorMessage
              field={errors.accepted_terms}
              fieldName={t(
                "onboardings.xpert.complete.account_creation.terms_acknowledgement"
              )}
            />
          </Form.Group>

          <Button
            className="w-100 mt-5"
            onClick={() => createAccountWithPassword()}
          >
            {t("onboardings.xpert.complete.account_creation.finish")}
          </Button>
          <AxiosErrorAlert
            response={updateOnboardingInvitationMutation.error}
            translationPrefix="errors.codes."
          />

          <div
            className="Complete-Step__Login-Google"
            onClick={() => {
              setValue("with_password", false);
              trigger("accepted_terms");

              if (getValues("accepted_terms")) {
                acceptTerms();
              }
            }}
          >
            <GoogleLoginText
              linkText={t("login.with_google")}
              suffixText={t("login.instead")}
              disabled={!getValues("accepted_terms")}
            ></GoogleLoginText>
          </div>
        </>
      )}
    </div>
  );
};

export default CompleteStep;
