import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import IAcknowledgement from "@interfaces/IAcknowledgement";
import AcknowledgementService from "@services/AcknowledgementService";

export const useAcknowledgements = (
  options?: Pick<
    UseQueryOptions<IAcknowledgement[], Error>,
    "enabled" | "onSuccess" | "retry"
  > & {
    refetchOnWindowFocus?: boolean;
  }
) =>
  useQuery(
    ["acknowledgements"],
    () => AcknowledgementService.getAcknowledgements(),
    {
      ...options,
    }
  );
