import { Spinner } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import BillsSelector from "./BillsSelector/BillsSelector";
import IXchange from "@interfaces/IXchange";
import { useState } from "react";
import { useDebounce } from "@hooks/useDebounce";
import "./BillsSelectionForm.scss";
import BillFilters from "@components/BillFilters/BillFilters";
import { XeroInvoiceStatus } from "@interfaces/IXeroInvoice";
import { useBills } from "@hooks/bill/useBills";
import { IGetBillsParams } from "@services/BillService";

const BillsSelectionForm = ({
  xchange,
  disabled = false,
}: {
  xchange?: IXchange;
  disabled?: boolean;
}) => {
  const availableStatuses = [
    XeroInvoiceStatus.DRAFT,
    XeroInvoiceStatus.SUBMITTED,
    XeroInvoiceStatus.AUTHORISED,
    XeroInvoiceStatus.PAID,
  ];

  const [getBillsParams, setGetBillsParams] = useState<IGetBillsParams>({
    outbound_invoice__isnull: true,
    status__in: availableStatuses.join(","),
  });
  const {
    debouncedValue: debouncedGetBillsParams,
    isDebouncing: isGetBillsParamsDeboucing,
  } = useDebounce(getBillsParams, 400);

  const { data: billsInfiniteData, isLoading: arePossibleBillsLoading } =
    useBills(
      {
        ...debouncedGetBillsParams,
        xchange__in: xchange!.id!.toString(),
        populate_xpert: true,
        page_size: 50,
      },
      {
        enabled: !!xchange,
      }
    );
  const possibleBills = billsInfiniteData
    ? billsInfiniteData.pages.flatMap((p) => p.results)
    : [];

  const { control } = useFormContext<{
    bills: number[];
  }>();

  const handleFilterChange = (data: IGetBillsParams) => {
    setGetBillsParams({
      outbound_invoice__isnull: true,
      status__in: availableStatuses.join(","),
      ...data,
    });
  };

  return (
    <div>
      <BillFilters
        xchange={xchange}
        handleFilterChange={handleFilterChange}
        className="pb-5"
        availableStatuses={availableStatuses}
        disabled={disabled}
      />

      <div className="Bills-Selection-Form__List">
        {arePossibleBillsLoading || isGetBillsParamsDeboucing ? (
          <div className="d-flex justify-content-center pt-4">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <BillsSelector
            name="bills"
            control={control}
            possibleBills={possibleBills}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};

export default BillsSelectionForm;
