import { Badge } from "react-bootstrap";
import IXeroInvoice, { XeroInvoiceStatus } from "@interfaces/IXeroInvoice";
import { useTranslation } from "react-i18next";

enum CustomInvoiceStatus {
  DRAFT = "DRAFT",
  AWAITING_APPROVAL = "AWAITING_APPROVAL",
  AWAITING_PAYMENT = "AWAITING_PAYMENT",
  SENT = "SENT",
  OVERDUE = "OVERDUE",
  PAID = "PAID",
  VOIDED = "VOIDED",
  DELETED = "DELETED",
}

const InvoiceStatusBadge = ({
  invoice,
  className,
}: {
  invoice: IXeroInvoice;
  className?: string;
}) => {
  const { t } = useTranslation();

  const getCustomInvoiceStatus = (): CustomInvoiceStatus => {
    switch (invoice?.status) {
      case XeroInvoiceStatus.SUBMITTED:
        return CustomInvoiceStatus.AWAITING_APPROVAL;
      case XeroInvoiceStatus.AUTHORISED:
        if (invoice.sent_to_contact) {
          if (invoice.due_date && new Date(invoice.due_date) < new Date()) {
            return CustomInvoiceStatus.OVERDUE;
          }
          return CustomInvoiceStatus.SENT;
        }
        return CustomInvoiceStatus.AWAITING_PAYMENT;
      case XeroInvoiceStatus.PAID:
        return CustomInvoiceStatus.PAID;
      case XeroInvoiceStatus.VOIDED:
        return CustomInvoiceStatus.VOIDED;
      case XeroInvoiceStatus.DELETED:
        return CustomInvoiceStatus.DELETED;
      default:
        return CustomInvoiceStatus.DRAFT;
    }
  };

  const getColor = (status: CustomInvoiceStatus) => {
    switch (status) {
      case CustomInvoiceStatus.DRAFT:
        return "warning";
      case CustomInvoiceStatus.AWAITING_APPROVAL:
        return "secondary";
      case CustomInvoiceStatus.AWAITING_PAYMENT:
      case CustomInvoiceStatus.SENT:
        return "info";
      case CustomInvoiceStatus.OVERDUE:
        return "danger";
      case CustomInvoiceStatus.PAID:
        return "success";
      case CustomInvoiceStatus.VOIDED:
      case CustomInvoiceStatus.DELETED:
        return "light";
    }
  };

  return (
    <Badge
      bg={getColor(getCustomInvoiceStatus())}
      text={getColor(getCustomInvoiceStatus())}
      className={className}
    >
      {t(`billing.xero.invoice.statuses.${getCustomInvoiceStatus()}`)}
    </Badge>
  );
};

export default InvoiceStatusBadge;
