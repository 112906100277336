import React, { useState } from "react";
import {
  Button,
  Form,
  FormControlProps,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import "./PasswordInput.scss";

type Props = {
  className?: string;
  infoText?: string;
} & FormControlProps;

const PasswordInput = React.forwardRef(
  ({ className, infoText, ...props }: Props, ref) => {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);

    return (
      <div className={`Password-Input d-flex ${className ?? ""}`}>
        <Form.Control
          {...props}
          type={showPassword ? "text" : "password"}
          ref={ref}
          className={classNames(className, "Password-Input__Input")}
        />
        <OverlayTrigger
          overlay={
            <Tooltip>
              {showPassword ? t("password.hide") : t("password.show")}
            </Tooltip>
          }
        >
          <Button
            className={`Password-Input__Button text-center p-0 ${
              props.isInvalid && "invalid"
            }`}
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          >
            {showPassword ? (
              <i className="fa-regular fa-eye-slash"></i>
            ) : (
              <i className="fa-regular fa-eye"></i>
            )}
          </Button>
        </OverlayTrigger>
      </div>
    );
  }
);

export default PasswordInput;
