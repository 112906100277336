import ICostRange from "@interfaces/ICostRange";

export const toCurrencyString = (
  value: string | number | undefined,
  currency = "USD",
  maximumFractionDigits = 0
) => {
  if (value == null || value === "") return "$ --";
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    maximumFractionDigits,
  }).format(+value);
};

export const toCurrencyShortString = (value: number) => {
  return `$${new Intl.NumberFormat("en-US", {
    notation: "compact",
    compactDisplay: "short",
  }).format(value)}`;
};

export const toCostRangeString = ({ min, max }: ICostRange) => {
  if (min === max) {
    return toCurrencyString(min);
  }
  return `${toCurrencyString(min)} - ${toCurrencyString(max)}`;
};
