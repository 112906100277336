import {
  InfiniteData,
  UseQueryOptions,
  useInfiniteQuery,
} from "@tanstack/react-query";
import CatalogTermService, {
  IGetCatalogTermsParams,
} from "@services/CatalogTermService";
import ICatalogTerm from "@interfaces/terms/ICatalogTerm";
import IPage, { IPageParams } from "@interfaces/IPage";

export const useCatalogTerms = (
  params?: IGetCatalogTermsParams & IPageParams,
  options?: Pick<
    UseQueryOptions<InfiniteData<IPage<ICatalogTerm>>, Error>,
    "enabled" | "onSuccess"
  > & {
    refetchOnWindowFocus?: boolean;
  }
) =>
  useInfiniteQuery(
    ["terms", { params }],
    (ctx) => {
      return CatalogTermService.getCatalogTerms({
        page: ctx.pageParam ? ctx.pageParam : undefined,
        ...params,
      });
    },
    {
      getPreviousPageParam: (firstGroup) => firstGroup.previous,
      getNextPageParam: (lastGroup) => lastGroup.next,
      ...options,
    }
  );
