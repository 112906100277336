import React from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import XchangeSowStatus from "@enum/XchangeSowStatus";

const XchangeSowStatusBadge = ({
  status,
  className,
  showIcon = false,
  showText = true,
}: {
  status?: XchangeSowStatus;
  className?: string;
  showIcon?: boolean;
  showText?: boolean;
}) => {
  const { t } = useTranslation();

  const color = (() => {
    switch (status) {
      case XchangeSowStatus.NOT_STARTED:
        return "warning";

      case XchangeSowStatus.IN_PROGRESS:
        return "danger";

      case XchangeSowStatus.SIGNED:
        return "success";
    }
  })();

  const iconClasses = (() => {
    switch (status) {
      case XchangeSowStatus.NOT_STARTED:
        return "fa-solid fa-circle-minus";

      case XchangeSowStatus.IN_PROGRESS:
        return "fa-regular fa-arrows-rotate";

      case XchangeSowStatus.SIGNED:
        return "fa-regular fa-signature-lock";
    }
  })();

  return (
    <OverlayTrigger
      delay={{ show: 350, hide: 0 }}
      overlay={
        !showText ? (
          <Tooltip>{t(`contracts.sow.statuses.${status}`)}</Tooltip>
        ) : (
          <></>
        )
      }
    >
      <Badge bg={color} text={color} className={className}>
        {showIcon && <i className={`${iconClasses} ${showText && "me-2"}`} />}
        {showText && t(`contracts.sow.statuses.${status}`)}
      </Badge>
    </OverlayTrigger>
  );
};

export default XchangeSowStatusBadge;
