import { useMutation, useQueryClient } from "@tanstack/react-query";
import ISignatory from "@interfaces/ISignatory";
import SignatoryService from "@services/SignatoryService";

interface IMutationVars {
  signatory: ISignatory;
}

export const useCreateSignatory = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ["createSignatory"],
    ({ signatory }: IMutationVars) =>
      SignatoryService.createSignatory(signatory),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["signatories"]);
      },
    }
  );
};
