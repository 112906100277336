import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import IInvoice from "@interfaces/IInvoice";
import InvoiceService from "@services/InvoiceService";

export const useInvoice = (
  id: number,
  options?: Pick<
    UseQueryOptions<IInvoice, Error>,
    "enabled" | "onSuccess" | "retry"
  > & {
    refetchOnWindowFocus?: boolean;
  }
) =>
  useQuery(["invoices", { id }], () => InvoiceService.getInvoice(id), options);
