import InfoSnippet from "@components/InfoSnippet/InfoSnippet";
import ProgressBoard, {
  IProgressBoardStep,
} from "@components/ProgressBoard/ProgressBoard";
import StepCard from "@components/StepCard/StepCard";
import ICompany from "@interfaces/ICompany";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const CompanyOnboardingProgress = ({ company }: { company?: ICompany }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const steps = useMemo((): IProgressBoardStep[] => {
    return [
      {
        title: t("onboardings.xpert.progress.steps.general.title"),
        description: t("onboardings.xpert.progress.steps.general.description"),
        iconClasses: "fa-regular fa-clipboard",
        isCompleted:
          company?.onboarding_completed_steps?.includes("general") ?? false,
        buttonLabel: t("actions.continue"),
        onClick: () => {
          navigate(`/companies/${company?.id}/overview#company-general`);
        },
      },
      {
        title: t("onboardings.xpert.progress.steps.location.title"),
        description: t("onboardings.xpert.progress.steps.location.description"),
        iconClasses: "fa-regular fa-location-dot",
        isCompleted:
          company?.onboarding_completed_steps?.includes("location") ?? false,
        buttonLabel: t("actions.continue"),
        onClick: () => {
          navigate(`/companies/${company?.id}/overview#company-location`);
        },
      },
      {
        title: t("onboardings.xpert.progress.steps.profile.title"),
        description: t("onboardings.xpert.progress.steps.profile.description"),
        iconClasses: "fa-regular fa-building",
        isCompleted:
          company?.onboarding_completed_steps?.includes("profile") ?? false,
      },
      {
        title: t("onboardings.xpert.progress.steps.finance.title"),
        description: t("onboardings.xpert.progress.steps.finance.description"),
        iconClasses: "fa-regular fa-coins",
        isCompleted:
          company?.onboarding_completed_steps?.includes("finance") ?? false,
        buttonLabel: t("actions.continue"),
        onClick: () => {
          navigate(
            `/companies/${company?.id}/xpert-billing#xpert-finance-insurance`
          );
        },
      },
      {
        title: t("onboardings.xpert.progress.steps.team.title"),
        description: t("onboardings.xpert.progress.steps.team.description"),
        iconClasses: "fa-regular fa-users",
        isCompleted:
          company?.onboarding_completed_steps?.includes("team") ?? false,
        buttonLabel: t("actions.continue"),
        onClick: () => {
          navigate(`/companies/${company?.id}/xpert#xpert-team`);
        },
      },
      {
        title: t("onboardings.xpert.progress.steps.terms.title"),
        description: t("onboardings.xpert.progress.steps.terms.description"),
        iconClasses: "fa-regular fa-file-signature",
        isCompleted:
          company?.onboarding_completed_steps?.includes("terms") ?? false,
        buttonLabel: t("actions.continue"),
        onClick: () => {
          navigate(`/companies/${company?.id}/xpert#xpert-terms`);
        },
      },
    ];
  }, [company?.id, company?.onboarding_completed_steps, navigate, t]);

  return (
    <StepCard title={t("onboardings.xpert.progress.title")}>
      <div className="pe-4 pb-5 ps-4">
        <InfoSnippet iconClasses="fa-regular fa-address-card">
          <b>{t("onboardings.xpert.progress.description_part_one")}</b> —{" "}
          {t("onboardings.xpert.progress.description_part_two")}
        </InfoSnippet>
        <ProgressBoard className="mt-6" steps={steps} />
      </div>
    </StepCard>
  );
};

export default CompanyOnboardingProgress;
