import { round } from "lodash";
import { InvoiceBillLineItemFormValues } from "@pages/Xchange/XchangeInvoicePage/XchangeInvoicePage";
import IInvoice, {
  IInvoiceLineItem,
  IInvoiceLineItemWrite,
} from "@interfaces/IInvoice";
import { XchangeInvoiceOverviewFormValues } from "@pages/Xchange/XchangeInvoicePage/XchangeInvoiceOverview/XchangeInvoiceOverview";
import { addDays, resetHours } from "@helpers/date-utils";

export const calcInvoiceGrandTotal = (invoice: IInvoice): number => {
  let total = 0;
  for (const lineItem of invoice.invoice_line_items ?? []) {
    total += calcInvoiceLineItemGrandTotal(lineItem);
  }
  return total;
};

export const calcInvoiceBillSubTotal = (
  invoice: IInvoice,
  bill: number
): number => {
  if (invoice.xero_invoice) {
    return invoice.xero_invoice.sub_total!;
  }

  let total = 0;
  for (const lineItem of invoice.invoice_line_items?.filter((lineItem) => {
    return lineItem.external_bill === bill;
  }) ?? []) {
    total += calcInvoiceLineItemGrandTotal(lineItem);
  }
  return total;
};

export const calcInvoiceLineItemGrandTotal = (
  invoiceLineItem: IInvoiceLineItem
): number => {
  const lineItem = processInvoiceLineItemForForm(invoiceLineItem);

  let grandTotal = 0;
  const total = calcLineItemTotal(lineItem);

  let markup = 0;
  if (lineItem.has_markup) {
    markup = calcLineItemMarkup(lineItem);
  }

  let discount = 0;
  if (lineItem.has_discount) {
    discount = calcLineItemDiscount(lineItem);
  }

  grandTotal = total + markup - discount;

  return grandTotal;
};

export const calcLineItemTotal = (
  lineItem: InvoiceBillLineItemFormValues
): number => {
  return round(lineItem.quantity * (lineItem.rate ?? 0), 2);
};

export const calcLineItemMarkup = (
  lineItem: InvoiceBillLineItemFormValues
): number => {
  if (lineItem.has_markup) {
    const total = calcLineItemTotal(lineItem);
    if (lineItem.markup_type === "percentage") {
      return round(total * (lineItem.markup / 100), 2);
    }
    return lineItem.markup;
  } else {
    return 0;
  }
};

export const calcLineItemDiscount = (
  lineItem: InvoiceBillLineItemFormValues
): number => {
  if (lineItem.has_discount) {
    const total = calcLineItemTotal(lineItem);
    const markup = calcLineItemMarkup(lineItem);
    if (lineItem.discount_type === "percentage") {
      return round((total + markup) * (lineItem.discount / 100), 2);
    }
    return lineItem.discount;
  } else {
    return 0;
  }
};

export const processInvoiceLineItemMarkupForBackend = (
  lineItem: InvoiceBillLineItemFormValues
): number | null => {
  if (lineItem.has_markup && lineItem.markup > 0) {
    if (lineItem.markup_type === "fixed") {
      return lineItem.markup;
    } else {
      return lineItem.markup / 100;
    }
  } else {
    return null;
  }
};

export const processInvoiceLineItemDiscountForBackend = (
  lineItem: InvoiceBillLineItemFormValues
): number | null => {
  if (lineItem.has_discount && lineItem.discount > 0) {
    if (lineItem.discount_type === "fixed") {
      return lineItem.discount;
    } else {
      return lineItem.discount / 100;
    }
  } else {
    return null;
  }
};

export const processLineItemForBackend = (
  lineItem: InvoiceBillLineItemFormValues
): IInvoiceLineItemWrite => {
  return {
    description: lineItem.description ?? undefined,
    quantity: lineItem.quantity ?? undefined,
    rate: lineItem.rate ?? undefined,
    markup_type: lineItem.markup_type ?? undefined,
    markup: lineItem.markup
      ? processInvoiceLineItemMarkupForBackend(lineItem)
      : undefined,
    discount_type: lineItem.discount_type ?? undefined,
    discount: lineItem.discount
      ? processInvoiceLineItemDiscountForBackend(lineItem)
      : undefined,
  };
};

export const processInvoiceLineItemTotalForForm = (
  lineItem: IInvoiceLineItem
): number => {
  if (lineItem.quantity && lineItem.rate) {
    return parseFloat(
      (lineItem.quantity * parseFloat(lineItem.rate)).toFixed(2)
    );
  }
  return 0;
};

export const processInvoiceLineItemForForm = (
  lineItem: IInvoiceLineItem
): InvoiceBillLineItemFormValues => {
  return {
    id: lineItem.id,
    description: lineItem.description || "",
    quantity: lineItem.quantity!,
    rate: parseFloat(lineItem.rate!),
    total: processInvoiceLineItemTotalForForm(lineItem),
    has_markup: !!lineItem.markup,
    markup_type: lineItem.markup_type!,
    markup:
      lineItem.markup_type === "percentage"
        ? parseFloat(lineItem.markup ?? "0") * 100
        : parseFloat(lineItem.markup ?? "0"),
    has_discount: !!lineItem.discount,
    discount_type: lineItem.discount_type!,
    discount:
      lineItem.discount_type === "percentage"
        ? parseFloat(lineItem.discount ?? "0") * 100
        : parseFloat(lineItem.discount ?? "0") ?? 0,
  };
};

export const processInvoiceForOverviewForm = (
  invoice: IInvoice
): XchangeInvoiceOverviewFormValues => {
  return {
    due_date: invoice.due_date
      ? resetHours(new Date(invoice.due_date))
      : undefined,
    was_due_date_set_by_user: invoice.was_due_date_set_by_user,
    billing_period_start: invoice.billing_period_start
      ? resetHours(new Date(invoice.billing_period_start))
      : undefined,
    billing_period_end: invoice.billing_period_end
      ? resetHours(new Date(invoice.billing_period_end))
      : undefined,
  };
};

export const getInvoiceDefaultDueDate = (invoice?: IInvoice): Date => {
  if (invoice && invoice.was_due_date_set_by_user && invoice.due_date) {
    return resetHours(new Date(invoice.due_date));
  } else {
    return resetHours(addDays(new Date(), 14));
  }
};
