import { useTranslation } from "react-i18next";
import { formatDateToString } from "@helpers/date-utils";
import IContract from "@interfaces/IContract";
import DocumentCard from "@components/DocumentCard/DocumentCard";
import { useUser } from "@hooks/user/useUser";

const ContractListItem = ({
  contract,
  showSignedVersion = false,
}: {
  contract: IContract;
  showSignedVersion?: boolean;
}) => {
  const { t } = useTranslation();

  const { isLoading: isUserLoading, data: user } = useUser(
    contract.generated_by!,
    {
      enabled: !!contract.generated_by,
    }
  );

  const generationByName =
    user && user.first_name
      ? `${user.first_name} ${user.last_name ?? ""}`
      : t("unknown");

  const generationDate =
    contract?.last_generation_date &&
    formatDateToString(new Date(contract.last_generation_date));

  const completionDate =
    contract.completion_date &&
    formatDateToString(new Date(contract.completion_date));

  const getInfotext = () => {
    if (showSignedVersion && completionDate) {
      return `${t("contracts.completed_on")} ${completionDate}`;
    }

    if (contract.last_generation_date && !isUserLoading) {
      return `${t("contracts.generated")} ${generationDate} ${t(
        "contracts.by"
      )} ${generationByName}`;
    } else {
      return "";
    }
  };

  const url = (() => {
    if (showSignedVersion && contract.signed_document_id) {
      return `https://drive.google.com/file/d/${contract.signed_document_id}`;
    } else if (contract.document_id) {
      return `https://docs.google.com/document/d/${contract.document_id}`;
    }
  })();

  return (
    <DocumentCard
      key={`contract-${contract.id}`}
      name={contract?.name!}
      url={url}
      info={getInfotext()}
    />
  );
};

export default ContractListItem;
