import { ReactNode } from "react";
import "./InfoCardSection.scss";

const InfoCardSection = ({
  title,
  direction = "column",
  children,
}: {
  title: string;
  direction?: "row" | "column";
  children: ReactNode;
}) => {
  return (
    <div className={`Info-Card-Section Info-Card-Section--${direction}`}>
      <div className="Info-Card-Section__Title">{title}</div>
      <div className="Info-Card-Section__Content">{children}</div>
    </div>
  );
};

export default InfoCardSection;
