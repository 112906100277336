import HeaderedPage from "@components/HeaderedPage/HeaderedPage";
import { useTranslation } from "react-i18next";
import AuthService from "@services/AuthService";
import { useQueryClient } from "@tanstack/react-query";
import { useUserUpdate } from "@hooks/user/useUserUpdate";
import useLocalStorage from "@hooks/useLocalStorage";
import { Button, Spinner } from "react-bootstrap";
import "./AcknowledgementPage.scss";
import AcknowledgementSection from "@pages/Acknowledgement/AcknowledgementSection/AcknowledgementSection";
import { useAcknowledgements } from "@hooks/acknowledgement/useAcknowledgements";
import AxiosErrorAlert from "@components/AxiosErrorAlert/AxiosErrorAlert";

const AcknowledgementPage = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { user } = useLocalStorage();

  const {
    data: acknowledgements,
    isFetching: areAcknowledgementsFetching,
    error: acknowledgementsError,
  } = useAcknowledgements({
    refetchOnWindowFocus: false,
  });

  const updateUserMutation = useUserUpdate();
  const acknowledge = () => {
    updateUserMutation.mutate({ id: user.id, acknowledged_terms: true });
  };

  return (
    <HeaderedPage>
      <div className="Acknowledgement-Page__Header">
        <div className="Acknowledgement-Page__Header__Title">
          {t("acknowledgement.title")}
        </div>
        <div className="Acknowledgement-Page__Header__Subtitle">
          {t("acknowledgement.subtitle")}
        </div>
      </div>
      <AcknowledgementSection
        title={t("acknowledgement.intro_title")}
        subtitle={t("acknowledgement.intro")}
      >
        {t("acknowledgement.intro_content")}
      </AcknowledgementSection>

      {areAcknowledgementsFetching && (
        <div className="d-flex justify-content-center mt-8 mb-8">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {acknowledgements?.map((acknowledgement, index) => {
        const sectionNumber = index + 1;
        return (
          <AcknowledgementSection
            key={acknowledgement.id}
            title={acknowledgement.title}
            subtitle={`${t("acknowledgement.section")} ${
              sectionNumber > 9 ? sectionNumber : `0${sectionNumber}`
            }`}
            isCollapsible
          >
            {acknowledgement.description ?? ""}
          </AcknowledgementSection>
        );
      })}

      <div className="Acknowledgement-Page__Footer">
        <AxiosErrorAlert
          response={acknowledgementsError || updateUserMutation.error}
          className="mb-4"
        />

        <Button
          onClick={acknowledge}
          className="w-100 mb-4"
          disabled={
            areAcknowledgementsFetching ||
            !!acknowledgementsError ||
            updateUserMutation.isLoading
          }
        >
          {updateUserMutation.isLoading ? (
            <i className="fa-solid fa-spinner-third fa-spin" />
          ) : (
            t("acknowledgement.complete")
          )}
        </Button>
        <Button
          variant="outline-light"
          className="w-100"
          onClick={() => {
            queryClient.clear();
            AuthService.logout();
          }}
        >
          {t("nav.logout")}
        </Button>
      </div>
    </HeaderedPage>
  );
};

export default AcknowledgementPage;
