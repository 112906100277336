import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IXchangeCreateUpdate } from "@interfaces/IXchange";
import XchangeService from "@services/XchangeService";

interface IMutationVars {
  updatedXchange: IXchangeCreateUpdate;
}

export const useXchangeUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ["updateXchange"],
    ({ updatedXchange }: IMutationVars) =>
      XchangeService.updateXchange(updatedXchange),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["xchanges", { id: data.id }]);
      },
    }
  );
};
