import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Stack,
  Tooltip,
} from "react-bootstrap";
import { OverviewSection } from "@components/GenericOverview/OverviewSection/OverviewSection";
import { Overview } from "@components/GenericOverview/Overview/Overview";
import { Link } from "react-router-dom";
import IBill from "@interfaces/IBill";
import DocumentCard from "@components/DocumentCard/DocumentCard";
import IXchange from "@interfaces/IXchange";
import { formatDateToString, toRangeString } from "@helpers/date-utils";
import {
  IXeroInvoiceAttachment,
  XeroInvoiceStatus,
} from "@interfaces/IXeroInvoice";
import { useState } from "react";
import {
  donwloadBillAttachment,
  openBillAttachment,
} from "@helpers/bill-utilts";
import { toCurrencyString } from "@helpers/currencyUtils";
import BillStatusBadge from "@components/BillStatusBadge/BillStatusBadge";
import "./XchangeBillOverview.scss";
import { useTranslation } from "react-i18next";
import { useBillUpdate } from "@hooks/bill/useBillUpdate";
import { useIsFetching } from "@tanstack/react-query";
import CustomSelect from "@components/CustomSelect/CustomSelect";
import { useForm } from "react-hook-form";
import AxiosErrorAlert from "@components/AxiosErrorAlert/AxiosErrorAlert";

const XchangeBillOverview = ({ bill }: { bill: IBill }) => {
  const { t } = useTranslation();
  const xchange = bill.xchange as IXchange;

  const formMethods = useForm<{
    currency?: string;
  }>({
    mode: "onChange",
    defaultValues: {
      currency: bill.xero_invoice?.currency_code,
    },
  });

  const { control } = formMethods;

  const [attachmentCurrentlyDownloading, setAttachmentCurrentlyDownloading] =
    useState<string[]>([]);

  const openAttachment = async (
    billId: number,
    attachment: IXeroInvoiceAttachment
  ) => {
    setAttachmentCurrentlyDownloading([
      ...attachmentCurrentlyDownloading,
      attachment.attachment_id!,
    ]);

    await openBillAttachment(billId, attachment);

    setAttachmentCurrentlyDownloading(
      attachmentCurrentlyDownloading.filter(
        (id) => id !== attachment.attachment_id
      )
    );
  };

  const donwloadAttachment = async (
    billId: number,
    attachment: IXeroInvoiceAttachment
  ) => {
    setAttachmentCurrentlyDownloading([
      ...attachmentCurrentlyDownloading,
      attachment.attachment_id!,
    ]);

    await donwloadBillAttachment(billId, attachment);

    setAttachmentCurrentlyDownloading(
      attachmentCurrentlyDownloading.filter(
        (id) => id !== attachment.attachment_id
      )
    );
  };

  const isBillFetching =
    useIsFetching({ queryKey: ["bills", { id: bill.id }] }) > 0;
  const updateBillMutation = useBillUpdate();

  const isBillValidForSubmission = (): boolean => {
    return !!bill.xero_invoice?.date && !!bill.xero_invoice?.due_date;
  };

  const approveBill = () => {
    updateBillMutation.mutate({
      bill: {
        id: bill.id,
        status: XeroInvoiceStatus.AUTHORISED,
      },
    });
  };

  return (
    <Overview
      goBackText={`${t("form.xchange_edit.tabs.billing")} | ${xchange.name}`}
      goBackUri={`/xchanges/${xchange.id}/billing`}
    >
      <OverviewSection>
        <Form.Label className="w-auto d-inline-block me-2 mb-0">
          {t("billing.bill.overview.payment_status")}
        </Form.Label>
        <BillStatusBadge
          status={bill.xero_invoice?.status as XeroInvoiceStatus}
          className="ms-1"
        />
      </OverviewSection>
      <OverviewSection>
        <Form.Label>{t("billing.bill.overview.xchange")}</Form.Label>
        <Link to={`/xchanges/${xchange.id}`} target="_blank">
          <DocumentCard
            name={xchange.name!}
            url={`/xchanges/${xchange.id}`}
            subName={xchange.client?.name}
            variant="secondary"
          />
        </Link>
      </OverviewSection>
      <OverviewSection>
        <Form.Label>{t("billing.bill.overview.original_bill")}</Form.Label>
        <div className="Xchange-Bill-Overview__Documents">
          {bill.xero_invoice?.attachments &&
          bill.xero_invoice.attachments.length > 0 ? (
            bill.xero_invoice?.attachments
              .map((attachment: IXeroInvoiceAttachment) => {
                return {
                  ...attachment,
                  bill: bill.id,
                };
              })
              .map((attachment) => {
                return (
                  <DocumentCard
                    key={attachment.attachment_id!}
                    variant="secondary"
                    name={attachment.file_name!}
                    buttons={[
                      {
                        label: t("actions.open"),
                        iconClasses: "fa-solid fa-external-link-alt ",
                        disabled: attachmentCurrentlyDownloading.length > 0,
                        isLoading: attachmentCurrentlyDownloading.includes(
                          attachment.attachment_id!
                        ),
                        onClick: () => {
                          openAttachment(attachment.bill!, attachment);
                        },
                      },
                      {
                        label: t("actions.download"),
                        iconClasses: "fa-solid fa-file-download",
                        disabled: attachmentCurrentlyDownloading.length > 0,
                        isLoading: attachmentCurrentlyDownloading.includes(
                          attachment.attachment_id!
                        ),
                        onClick: () => {
                          donwloadAttachment(attachment.bill!, attachment);
                        },
                      },
                    ]}
                  />
                );
              })
          ) : (
            <div className="text-center small text-muted mt-2 mb-2">
              {t("billing.bill.overview.original_bill_none")}
            </div>
          )}
        </div>
      </OverviewSection>
      <OverviewSection>
        <Stack direction="horizontal" className="align-items-start">
          <Col className="pe-2">
            <Form.Label> {t("billing.bill.overview.total_due")}</Form.Label>
            <div className="fs-5 mt-2">
              {toCurrencyString(bill.xero_invoice?.sub_total, "USD", 2)}
            </div>
          </Col>
          <Col className="ps-2">
            <Form.Label> {t("billing.bill.overview.currency")}</Form.Label>
            <div className="mt-2">
              <CustomSelect
                name="currency"
                control={control}
                options={[
                  {
                    label: "USD",
                    value: "USD",
                  },
                  {
                    label: "CAD",
                    value: "CAD",
                  },
                ]}
                isDisabled={true}
              />
            </div>
          </Col>
        </Stack>
      </OverviewSection>
      <OverviewSection>
        <Stack direction="horizontal" className="align-items-start">
          <Col xs={6} className="pe-2">
            <Form.Label> {t("billing.bill.overview.due_by")}</Form.Label>
            <div className="fs-5 mt-2">
              {bill.xero_invoice?.due_date
                ? formatDateToString(new Date(bill.xero_invoice?.due_date), {
                    dateStyle: "medium",
                  })
                : "-"}
            </div>
          </Col>
          <Col xs={6} className="ps-2">
            <Form.Label>{t("billing.bill.overview.billing_period")}</Form.Label>
            <div className="fs-5 mt-2">
              {(bill.billing_period_start &&
                bill.billing_period_end &&
                toRangeString(
                  new Date(bill.billing_period_start),
                  new Date(bill.billing_period_end)
                )) ??
                "-"}
            </div>
          </Col>
        </Stack>
      </OverviewSection>
      <OverviewSection>
        <OverlayTrigger
          overlay={
            !isBillValidForSubmission() ? (
              <Tooltip>{t("billing.bill.overview.cannot_submit")}</Tooltip>
            ) : (
              <></>
            )
          }
        >
          <div>
            <Button
              type="button"
              className="w-100 mt-2"
              disabled={
                ![
                  XeroInvoiceStatus.DRAFT,
                  XeroInvoiceStatus.SUBMITTED,
                ].includes(bill.xero_invoice?.status as XeroInvoiceStatus) ||
                updateBillMutation.isLoading ||
                isBillFetching ||
                !isBillValidForSubmission()
              }
              onClick={() => approveBill()}
            >
              {updateBillMutation.isLoading ? (
                <i className="fa-regular fa-spinner-third fa-spin me-2"></i>
              ) : (
                <i className="fa-solid fa-check-to-slot me-2"></i>
              )}
              {t("billing.bill.overview.approve")}
            </Button>
          </div>
        </OverlayTrigger>
        <AxiosErrorAlert response={updateBillMutation.error} />
      </OverviewSection>
    </Overview>
  );
};

export default XchangeBillOverview;
