import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import BillService, {
  IGetBillGroupsParams,
  IGetBillsParams,
} from "@services/BillService";
import IBillGroup from "@interfaces/IBillGroup";

export const useBillGroups = (
  params: IGetBillGroupsParams & IGetBillsParams,
  options?: Pick<
    UseQueryOptions<IBillGroup[], Error>,
    "enabled" | "onSuccess" | "retry"
  > & {
    refetchOnWindowFocus?: boolean;
  }
) =>
  useQuery(
    ["bill-groups", { params }],
    () => BillService.getBillGroups(params),
    options
  );
