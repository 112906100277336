import {
  IContractGenerationHook,
  IContractGenerationSubject,
} from "./useContractGeneration";
import useGetSubjectSignatories from "./useGetSubjectSignatories";

const useGetSubjectSignatures = (
  subject: IContractGenerationSubject,
  contractGenerationHook: IContractGenerationHook
) => {
  const { signatures } = contractGenerationHook;
  const signatories = useGetSubjectSignatories(subject, contractGenerationHook);
  return signatures?.filter((signature) =>
    signatories?.some((signatory) => signatory.id === signature.signatory)
  );
};

export default useGetSubjectSignatures;
