import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./IntroStep.scss";
import { useFormContext } from "react-hook-form";

const IntroStep = ({ next }: { next: () => void }) => {
  const { t } = useTranslation();

  const { getValues } = useFormContext();

  return (
    <div className="Intro-Step fade-in">
      <h3>{t("onboardings.xpert.intro.title")}</h3>
      <p>{t("onboardings.xpert.intro.paragraph_one")}</p>
      <p>
        {getValues("first_name")
          ? t("onboardings.xpert.intro.paragraph_two_personalized", {
              name: getValues("first_name"),
            })
          : t("onboardings.xpert.intro.paragraph_two")}
        {}
      </p>
      <Button className="w-100" onClick={next}>
        {t("wizard.next")}
      </Button>
    </div>
  );
};

export default IntroStep;
