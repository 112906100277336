import { useMutation, useQueryClient } from "@tanstack/react-query";
import CompanyService from "@services/CompanyService";

interface IMutationVars {
  companyId: number;
  companyLogo: File;
}

export const useCompanyLogoUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ companyId, companyLogo }: IMutationVars) =>
      CompanyService.uploadCompanyLogo(companyId, companyLogo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["companies"]);
      },
    }
  );
};
