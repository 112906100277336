import { ContractStatus } from "@interfaces/IContract";
import {
  IContractGenerationHook,
  IContractGenerationSubject,
} from "./useContractGeneration";
import useGetSubjectContract from "./useGetSubjectContract";
import useIsSubjectContractGenerating from "./useIsSubjectContractGenerating";
import useIsSubjectSignNowGenerating from "./useIsSubjectSignNowGenerating";

const useCanSignNowBeRevoked = (
  subject: IContractGenerationSubject,
  contractGenerationHook: IContractGenerationHook
) => {
  const contract = useGetSubjectContract(subject, contractGenerationHook, true);
  const isContractGenerating = useIsSubjectContractGenerating(
    subject,
    contractGenerationHook
  );
  const isSignNowGenerating = useIsSubjectSignNowGenerating(
    subject,
    contractGenerationHook
  );

  return (
    !isContractGenerating &&
    !isSignNowGenerating &&
    contract?.status &&
    contract.status === ContractStatus.SENT_FOR_SIGNING
  );
};

export default useCanSignNowBeRevoked;
