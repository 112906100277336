import {
  IContractGenerationHook,
  IContractGenerationSubject,
} from "./useContractGeneration";
import useGetSubjectContract from "./useGetSubjectContract";
import { ContractStatus } from "@interfaces/IContract";

const useCanSubjectContractBeRegenerated = (
  subject: IContractGenerationSubject,
  contractGenerationHook: IContractGenerationHook
): boolean => {
  const { isContractGenerating } = contractGenerationHook;
  const contract = useGetSubjectContract(subject, contractGenerationHook, true);

  return (
    !isContractGenerating &&
    contract?.status! >= ContractStatus.SENT_FOR_REVIEW &&
    contract?.status! < ContractStatus.SENT_FOR_SIGNING
  );
};

export default useCanSubjectContractBeRegenerated;
