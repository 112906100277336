import { useMutation, useQueryClient } from "@tanstack/react-query";
import ISignNowSignature from "@interfaces/ISignNowSignature";
import SignNowService from "@services/SignNowService";

export const useResendSignNowSignature = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ["resendSignNowSignature"],
    (signature: ISignNowSignature) =>
      SignNowService.resendSignatureInvite(signature),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["signatures"]);
      },
    }
  );
};
