import "./StatBanner.scss";

const StatBanner = ({
  label,
  value,
  children,
}: {
  label: string;
  value: string | string[];
  children?: React.ReactNode;
}) => {
  return (
    <div className="Stat-Banner">
      <div className="Stat-Banner__Title">{label}</div>
      <div className="Stat-Banner__Content">
        {value}
        {children}
      </div>
    </div>
  );
};

export default StatBanner;
