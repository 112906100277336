import IXpertTerm, {
  IXpertTermCreateUpdate,
} from "@interfaces/terms/IXpertTerm";
import Service from "@services/Service";

export interface IGetXpertTermsParams {
  search?: string;
  id__in?: string;
  catalog_term?: string;
  catalog_term__in?: string;
  xpert_involved?: number;
  xpert_involved__in?: string;
}

export default class XpertTermService extends Service {
  public static async getXpertTerms(params?: IGetXpertTermsParams) {
    const response = await this.axios.get<IXpertTerm[]>("/xpert-terms", {
      params,
    });
    return response.data;
  }

  public static async createXpertTerm(term: IXpertTermCreateUpdate) {
    const response = await this.axios.post<IXpertTerm>("/xpert-terms", term);
    return response;
  }

  public static async updateXpertTerm(term: IXpertTermCreateUpdate) {
    const response = await this.axios.patch<IXpertTerm>(
      `/xpert-terms/${term.id}`,
      term
    );
    return response;
  }

  public static async deleteXpertTerm(xpertTermId: number) {
    const response = await this.axios.delete<IXpertTerm>(
      `/xpert-terms/${xpertTermId}`
    );
    return response;
  }
}
