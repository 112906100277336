import {
  useInfiniteQuery,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import ICatalogProduct from "@interfaces/products/ICatalogProduct";
import CatalogProductService, {
  IGetCatalogProductsParams,
} from "@services/CatalogProductService";
import IPage, { IPageParams } from "@interfaces/IPage";

export const useCatalogProducts = (
  params?: IGetCatalogProductsParams & IPageParams,
  options?: Pick<UseQueryOptions<IPage<ICatalogProduct>, Error>, "enabled">
) =>
  useInfiniteQuery(
    ["catalog-products", { params }],
    (ctx) => {
      return CatalogProductService.getCatalogProducts({
        page: ctx.pageParam ? ctx.pageParam : undefined,
        ...params,
      });
    },
    {
      getPreviousPageParam: (firstGroup) => firstGroup.previous,
      getNextPageParam: (lastGroup) => lastGroup.next,
      ...options,
    }
  );
