/* eslint-disable jsx-a11y/alt-text */
import { Badge, Form, FormCheckProps } from "react-bootstrap";
import "./BadgeCheckbox.scss";

const BadgeCheckbox = (
  props: {
    label: string;
  } & FormCheckProps
) => {
  return (
    <Badge
      className={`Badge-Checkbox ${props.checked && "Badge-Checkbox--checked"}`}
      onClick={props.onClick}
    >
      <Form.Check type="checkbox" {...props} className="d-none" readOnly />
      {props.label}
    </Badge>
  );
};

export default BadgeCheckbox;
