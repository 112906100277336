import { getSubjectType } from "@helpers/contract-utils";
import {
  IContractGenerationHook,
  IContractGenerationSubject,
} from "./useContractGeneration";

const useGetSubjectParty = (
  subject: IContractGenerationSubject,
  contractGenerationHook: IContractGenerationHook
) => {
  const { parties } = contractGenerationHook;
  const subjectType = getSubjectType(subject);

  return parties?.find(
    (party) =>
      party.subject_id === subject.subjectId &&
      party.subject_type === subjectType
  );
};

export default useGetSubjectParty;
