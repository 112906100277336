import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AxiosErrorAlert from "@components/AxiosErrorAlert/AxiosErrorAlert";
import CustomSelect, {
  ICustomSelectOption,
} from "@components/CustomSelect/CustomSelect";
import GenericModal from "@components/GenericModal/GenericModal";
import ModalTabs from "@components/GenericModal/ModalTabs/ModalTabs";
import IPerson from "@interfaces/IPerson";
import MemberForm from "@pages/Company/CompanyEditPage/CompanyGeneralOverview/CompanyMembers/CompanyMemberModal/MemberForm/MemberForm";
import IXpert from "@interfaces/IXpert";
import AlertWithIcon from "@components/AlertWithIcon/AlertWithIcon";
import { money } from "@helpers/joi-utils";

export interface MemberFormValues {
  id?: number;
  is_for_xchange?: boolean;
  xpert?: number | null;
  first_name?: string;
  last_name?: string;
  title?: string;
  pronouns?: string;
  email?: string;
  phone?: string;
  country?: string;
  region?: string;
  currency?: string;
  rate?: string;
  roles?: number[];
  primary_contact?: boolean;
  finance_contact?: boolean;
}

export const MemberFormValidationSchema = Joi.object({
  id: Joi.number().allow(""),
  is_for_xchange: Joi.boolean().optional(),
  xpert: Joi.when("is_for_xchange", {
    is: true,
    then: Joi.number().required(),
    otherwise: Joi.number().allow("", null),
  }),
  first_name: Joi.string().required(),
  last_name: Joi.string().required(),
  title: Joi.string().allow(""),
  pronouns: Joi.string().allow("", null),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .pattern(new RegExp("^[^A-Z]+$"))
    .messages({
      "string.pattern.base": "errors.custom.email_capitalized",
    })
    .required(),
  phone: Joi.string()
    .pattern(new RegExp("^[+][0-9]{11}$"))
    .messages({
      "string.pattern.base": "errors.custom.phone",
    })
    .allow("", null),
  country: Joi.string().allow("", null),
  region: Joi.string().allow("", null),
  currency: Joi.string().allow("", null),
  rate: money().allow("", null),
  roles: Joi.array().items(Joi.number()),
  primary_contact: Joi.boolean(),
  finance_contact: Joi.boolean(),
});

const MemberModal = ({
  people,
  xperts,
  member,
  show,
  showPrimaryContactToggle = false,
  showFinanceContactToggle = false,
  onXpertSelection,
  onClose,
  onSaveExisting,
  onSaveNew,
  isFinishLoading,
  axiosError,
  subtitle,
  defaultTab = "personDetails",
  disableNewMember = false,
}: {
  people?: IPerson[];
  xperts?: IXpert[];
  member?: MemberFormValues;
  show: boolean;
  showPrimaryContactToggle?: boolean;
  showFinanceContactToggle?: boolean;
  onXpertSelection?: (xpertId: number) => void;
  onClose: () => void;
  onSaveExisting: (
    selectedPeople: number[],
    selectedXpert?: number | null,
    onSuccessCallBack?: Function
  ) => void;
  onSaveNew: (newMember: MemberFormValues) => void;
  isFinishLoading: boolean;
  axiosError?: any;
  subtitle?: string;
  defaultTab?: "personSelection" | "personDetails";
  disableNewMember?: boolean;
}) => {
  const formMethods = useForm<MemberFormValues>({
    defaultValues: {
      is_for_xchange: !!xperts,
    },
    mode: "onChange",
    resolver: joiResolver(MemberFormValidationSchema),
  });
  const { handleSubmit, reset, getValues } = formMethods;

  useEffect(() => {
    if (show && (!member?.id || getValues("id") !== member?.id)) {
      if (member) {
        setActiveTab("personDetails");
        reset({
          id: member?.id,
          is_for_xchange: !!xperts,
          xpert: member?.xpert,
          first_name: member?.first_name,
          last_name: member?.last_name,
          title: member?.title,
          pronouns: member?.pronouns,
          email: member?.email,
          phone: member?.phone,
          country: member?.country,
          region: member?.region,
          currency: member?.currency,
          rate: member?.rate,
          roles: member?.roles,
          primary_contact: member?.primary_contact,
          finance_contact: member?.finance_contact,
        });
      } else {
        reset({
          is_for_xchange: !!xperts,
          xpert: null,
          first_name: "",
          last_name: "",
          title: "",
          pronouns: "",
          email: "",
          phone: "",
          country: "",
          region: "",
          currency: "",
          rate: "",
          roles: [],
          primary_contact: false,
          finance_contact: false,
        });
      }
    }
  }, [show, member, reset, getValues, xperts]);

  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(defaultTab);

  const {
    getValues: getPeopleSelectionValues,
    setValue: setPeopleSelectionValue,
    watch: watchPeopleSelection,
    control: peopleSelectionControl,
    reset: resetPeopleSelection,
  } = useForm<{
    xpert?: number | null;
    people: number[];
  }>();

  const handleOnFinish = () => {
    if (activeTab === "personSelection") {
      onSaveExisting(
        getPeopleSelectionValues("people"),
        getPeopleSelectionValues("xpert"),
        () => {
          handleOnClose();
        }
      );
    } else if (activeTab === "personDetails") {
      handleSubmit(onSaveNew)();
    }
  };

  const handleOnClose = () => {
    reset();
    resetPeopleSelection({
      xpert: null,
      people: [],
    });
    onClose();
  };

  useEffect(() => {
    const currentPeople = getPeopleSelectionValues("people");

    if (currentPeople) {
      const filteredPeople = currentPeople.filter((personId) => {
        return people?.find((person) => person.id === personId);
      });

      setPeopleSelectionValue("people", filteredPeople);
    }
  }, [getPeopleSelectionValues, people, setPeopleSelectionValue]);

  watchPeopleSelection();

  return (
    <GenericModal
      title={t(`teams.${member ? "edit" : "create"}.title`)}
      show={show}
      onClose={handleOnClose}
      onFinish={handleOnFinish}
      finishText={t(
        `teams.${
          member
            ? "edit"
            : activeTab === "personSelection"
            ? "select"
            : "create"
        }.finish`
      )}
      closeText={t("actions.cancel")}
      isFinishLoading={isFinishLoading}
      canBeFinished={
        activeTab === "personSelection"
          ? getPeopleSelectionValues("people")?.length > 0
          : undefined
      }
    >
      <h3 className="pb-6 pt-5 mb-2 ps-0">
        {subtitle ?? t(`teams.${member ? "edit" : "create"}.title`)}
      </h3>
      <ModalTabs
        currentTabKey={activeTab}
        onTabChange={(key) =>
          setActiveTab(key as "personSelection" | "personDetails")
        }
        tabs={[
          {
            tabKey: "personSelection",
            tabText: t("teams.select.tab_name"),
            content: (
              <>
                {!disableNewMember && (
                  <AlertWithIcon
                    iconClasses="fa-solid fa-circle-info"
                    className="mb-6"
                    closable
                  >
                    {t("teams.select.help")}
                  </AlertWithIcon>
                )}

                {xperts && (
                  <>
                    <Form.Label aria-required>
                      {t("teams.select.xpert")}
                    </Form.Label>
                    <CustomSelect
                      className="mb-5"
                      name="xpert"
                      control={peopleSelectionControl}
                      options={xperts?.map((xpert) => {
                        return {
                          value: xpert.id,
                          label: xpert.name,
                        };
                      })}
                      onAfterChange={(option) => {
                        onXpertSelection?.(
                          (option as ICustomSelectOption).value
                        );
                      }}
                    />
                  </>
                )}

                <Form.Label aria-required>
                  {t("teams.select.members")}
                </Form.Label>
                <CustomSelect
                  name="people"
                  control={peopleSelectionControl}
                  isMulti
                  isClearable
                  options={people?.map((person) => {
                    return {
                      value: person.id,
                      label: `${person.first_name} ${person.last_name}`,
                    };
                  })}
                  isDisabled={
                    xperts &&
                    xperts.length > 0 &&
                    !getPeopleSelectionValues("xpert")
                  }
                />
              </>
            ),
            disabled: !!member,
          },
          {
            tabKey: "personDetails",
            tabText: t("teams.create.tab_name"),
            content: (
              <FormProvider {...formMethods}>
                <MemberForm
                  xperts={xperts}
                  showPrimaryContactToggle={showPrimaryContactToggle}
                  showFinanceContactToggle={showFinanceContactToggle}
                />
              </FormProvider>
            ),
            disabled: disableNewMember,
          },
        ]}
        hideTabs={!!member}
      />

      <AxiosErrorAlert
        response={axiosError}
        translationPrefix="companies.details.overview.people.add.fields."
      />
    </GenericModal>
  );
};

export default MemberModal;
