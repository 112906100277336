import IParty from "@interfaces/IParty";
import { useXpert } from "@hooks/xpert/useXpert";
import { useClient } from "@hooks/client/useClient";
import { useCompany } from "@hooks/company/useCompany";
import { useTranslation } from "react-i18next";

export const usePartyPersonTitles = (
  party?: IParty,
  showSecondaryPartyName = false
) => {
  const { t } = useTranslation();

  const { data: xpert } = useXpert(party?.subject_id!, {
    enabled: party?.subject_type === "xpert",
  });

  const { data: client } = useClient(party?.subject_id!, {
    enabled: party?.subject_type === "client",
  });

  const { data: company } = useCompany(party?.subject_id!, {
    enabled: party?.subject_type === "company",
  });

  const getPersonTitles = (personId: Number) => {
    const titles = [];
    if (party) {
      switch (party.subject_type) {
        case "xpert":
          if (xpert?.primary_contact === personId) {
            titles.push(t("companies.details.overview.people.primary_contact"));
          }
          if (xpert?.finance_contact === personId) {
            titles.push(t("companies.details.overview.people.finance_contact"));
          }
          break;
        case "client":
          if (client?.primary_contact === personId) {
            titles.push(t("companies.details.overview.people.primary_contact"));
          }
          if (client?.finance_contact === personId) {
            titles.push(t("companies.details.overview.people.finance_contact"));
          }
          break;
        case "company":
          if (showSecondaryPartyName && !party.main) {
            titles.push(company?.company_name);
            break;
          }

          if (company?.company_key_contact === personId) {
            titles.push(t("companies.details.overview.people.primary_contact"));
          }
      }
    }
    return titles.join(", ");
  };

  return {
    getPersonTitles,
  };
};
