import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import IOnboardingInvitation from "@interfaces/IOnboardingInvitation";
import OnboardingInvitationService from "@services/OnboardingInvitationService";

export const useOnboardingInvitation = (
  token: string,
  options?: Pick<
    UseQueryOptions<IOnboardingInvitation, Error>,
    "enabled" | "onSuccess" | "onError" | "retry"
  > & {
    refetchOnWindowFocus?: boolean;
  }
) =>
  useQuery(
    ["onboarding-invitation", { token }],
    () => OnboardingInvitationService.getOnboardingInvitation(token),
    {
      ...options,
    }
  );
