import "./OverviewSectionStatus.scss";

export const OverviewSectionStatus = (props: {
  index: number;
  label: string;
  active?: boolean;
}) => {
  return (
    <div className="Overview-Item d-flex align-items-center p-2 mb-1">
      <p className="Overview-Item__Index p-2 mb-0">{props.index}</p>
      <p className="Overview-Item__Label ms-3 mb-0">{props.label}</p>
      {props.active == null ? null : props.active ? (
        <i className="Overview-Item__Status--complete fa-solid fa-check-circle ms-auto me-2" />
      ) : (
        <i className="fa-regular fa-circle ms-auto me-2" />
      )}
    </div>
  );
};
