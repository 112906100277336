import ITemplate from "@interfaces/ITemplate";
import IContractType, {
  ProtectedContractTypeCodes,
} from "@interfaces/IContractType";

export enum ContractStatus {
  IN_PROGRESS = "in_progress",
  SENT_FOR_REVIEW = "sent_for_review",
  SENT_FOR_SIGNING = "sent_for_signing",
  SIGNED = "signed",
  ARCHIVED = "archived",
}

interface IContract {
  id?: number;
  name?: string;
  template?: string | ITemplate;
  last_generation_date?: Date;
  last_generation_is_preview?: boolean;
  generated_by?: number;
  contract_type?: string | IContractType;
  contract_type__code?: string | ProtectedContractTypeCodes;
  data?: object;
  xchange?: number;
  folder_id?: string;
  document_id?: string;
  status?: ContractStatus;
  signed_document_id?: string;
  completion_date?: Date;
  subject_id?: number;
}

export default IContract;
