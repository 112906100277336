import { useEffect, useMemo, useState } from "react";
import { Col, Form, Placeholder, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import { useDebounce } from "@hooks/useDebounce";
import { useXpertTerms } from "@hooks/term/useXpertTerms";
import { useXperts } from "@hooks/xpert/useXperts";
import { XpertStatus } from "@interfaces/IXpert";
import ICatalogTerm from "@interfaces/terms/ICatalogTerm";
import IXpertTerm from "@interfaces/terms/IXpertTerm";
import { IGetXpertsParams } from "@services/XpertService";
import CustomSelect, {
  ICustomSelectOption,
} from "@components/CustomSelect/CustomSelect";
import CustomDateInput from "@components/CustomDateInput/CustomDateInput";
import Joi from "joi";
import { ProductFormValues } from "./XchangeProductDetailModal";
import IXchangeTerm from "@interfaces/terms/IXchangeTerm";
import { useSubDomains } from "@hooks/subdomain/useSubDomains";
import { toCurrencyString } from "@helpers/currencyUtils";
import ReactQuill from "react-quill";
import { money } from "@helpers/joi-utils";

export interface TermFormValues {
  id?: number;
  catalog_term?: number | null;
  xpert_term?: number | null;
  start_date?: Date;
  multiplier?: number;
  duration?: number;
  cost_per_sprint?: number;
  currency?: string;
  sub_domain?: number;
  stages?: number[];
  title?: string;
  description?: string;
  status?: string;
  xpert_involved?: number | null;
}

export const xchangeTermSchema = Joi.object({
  id: Joi.number(),
  catalog_term: Joi.number().required(),
  xpert_term: Joi.number().allow(null),
  start_date: Joi.date().raw().required(),
  multiplier: Joi.number().required(),
  duration: Joi.number().required(),
  cost_per_sprint: money().required(),
  currency: Joi.string(),
  sub_domain: Joi.number().allow(null),
  stages: Joi.array().items(Joi.number()),
  title: Joi.string(),
  description: Joi.string().allow(""),
  status: Joi.string(),
  xpert_involved: Joi.number(),
}).unknown(true);

const XchangeTermDetailsForm = ({
  catalogTerm,
  editingXchangeTerm,
  onXpertTermChange = () => {},
  field_index,
  minStartDate,
  disableXpertInvolved = false,
}: {
  catalogTerm?: ICatalogTerm;
  editingXchangeTerm?: IXchangeTerm | null;
  onXpertTermChange?: (xpertTerm?: IXpertTerm) => void;
  field_index?: number;
  minStartDate: string;
  disableXpertInvolved?: boolean;
}) => {
  const { t } = useTranslation();

  const {
    control,
    watch,
    getValues,
    setValue,
    register,
    formState: { errors },
  } = useFormContext<ProductFormValues | TermFormValues>();

  const getFormValuePath = (property: any) => {
    return field_index !== undefined
      ? `terms.${field_index}.${property}`
      : property;
  };

  watch(getFormValuePath("duration"));
  watch(getFormValuePath("multiplier"));

  const getMultiplierOptions = () => {
    const options = [];
    for (let i = 1; i <= 24; i++) {
      options.push({
        value: i,
        label: i.toString(),
      });
    }
    return options;
  };

  const { isLoading: areXpertTermsLoading, data: xpertTerms } = useXpertTerms(
    { catalog_term__in: catalogTerm?.id!.toString() },
    {
      enabled: !!catalogTerm,
      onSuccess: (xpertTerms) => {
        let xpertsToFetch: number[] = [];

        if (xpertTerms?.length) {
          xpertsToFetch = xpertsToFetch.concat(
            xpertTerms.map((xpertTerm) => xpertTerm.xpert_involved!)
          );
        }

        if (editingXchangeTerm) {
          xpertsToFetch.push(editingXchangeTerm.xpert_involved!);
        }

        if (xpertsToFetch.length > 0) {
          setGetXpertsParams({
            id__in: xpertsToFetch.flat().join(","),
            status__in: [XpertStatus.ACTIVE].join(","),
          });
        } else {
          setXpertOptions([]);
        }
      },
    }
  );

  const [getXpertsParams, setGetXpertsParams] = useState<IGetXpertsParams>({});
  const {
    debouncedValue: debouncedGetXpertsParams,
    isDebouncing: isGetXpertsParamsDebouncing,
  } = useDebounce(getXpertsParams, 500);

  const { isLoading: areXpertsLoading } = useXperts(debouncedGetXpertsParams, {
    enabled:
      !!xpertTerms?.length &&
      xpertTerms.some((xpertTerm) => xpertTerm.xpert_involved) &&
      Object.keys(debouncedGetXpertsParams).length > 0,
    onSuccess: (xperts) => {
      setXpertOptions(
        xperts.map((xpert) => ({
          value: xpert.id!,
          label: xpert.name!,
        }))
      );
    },
  });

  const [xpertOptions, setXpertOptions] = useState<
    { value: number; label: string }[]
  >([]);

  useEffect(() => {
    if (!areXpertsLoading) {
      const selectedXpert = getValues(
        field_index !== undefined
          ? `terms.${field_index}.xpert_involved`
          : "xpert_involved"
      );

      if (
        !xpertOptions.some((xpertOption) => xpertOption.value === selectedXpert)
      ) {
        setValue(
          field_index !== undefined
            ? `terms.${field_index}.xpert_involved`
            : "xpert_involved",
          null
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field_index, getValues, setValue, xpertOptions]);

  const onChangeXpert = (xpertId: number) => {
    const newSelectedXpertTerm = xpertTerms?.find(
      (xpertTerm) => xpertTerm.xpert_involved === xpertId
    );

    if (editingXchangeTerm && editingXchangeTerm.xpert_involved === xpertId) {
      setValue(getFormValuePath("currency"), editingXchangeTerm?.currency);
      setValue(
        getFormValuePath("cost_per_sprint"),
        editingXchangeTerm?.cost_per_sprint
      );
      setValue(getFormValuePath("duration"), editingXchangeTerm?.duration);
      setValue(
        getFormValuePath("description"),
        editingXchangeTerm?.description
      );
      return;
    }

    if (
      !!newSelectedXpertTerm &&
      editingXchangeTerm &&
      editingXchangeTerm.xpert_involved !== xpertId &&
      !editingXchangeTerm.xpert_term
    ) {
      setXpertOptions(
        xpertOptions.filter(
          (xpertOption) =>
            xpertOption.value !== editingXchangeTerm.xpert_involved
        )
      );
    }

    onXpertTermChange(newSelectedXpertTerm);
    setValue(getFormValuePath("duration"), newSelectedXpertTerm?.duration!);
    setValue(
      getFormValuePath("cost_per_sprint"),
      newSelectedXpertTerm?.cost_per_sprint
    );
    setValue(getFormValuePath("currency"), newSelectedXpertTerm?.currency);
    setValue(
      getFormValuePath("sub_domain"),
      newSelectedXpertTerm?.sub_domain?.id ?? catalogTerm?.sub_domain?.id
    );
    setValue(
      getFormValuePath("stages"),
      newSelectedXpertTerm?.stages?.map((s) => s.id!)
    );
    setValue(getFormValuePath("title"), newSelectedXpertTerm?.title);
    setValue(getFormValuePath("xpert_term"), newSelectedXpertTerm?.id);
    setValue(
      getFormValuePath("description"),
      newSelectedXpertTerm?.description
    );
  };

  const { data: subDomains } = useSubDomains();

  const showSubDomainField = useMemo(() => {
    return (
      !!getValues(getFormValuePath("xpert_involved")) &&
      getValues(getFormValuePath("sub_domain")) === undefined
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues(getFormValuePath("xpert_involved"))]);

  return (
    <>
      {!!editingXchangeTerm && (areXpertsLoading || areXpertTermsLoading) ? (
        <>
          <Placeholder animation="glow">
            <Form.Label>{t("form.deliverables.term.fields.xpert")}</Form.Label>
            <Placeholder xs={12} className="mb-4 placeholder--input" />
            <Form.Label>
              {t("form.deliverables.term.fields.start_date")}
            </Form.Label>
            <Placeholder xs={12} className="mb-4 placeholder--input" />
            <Form.Label>
              {t("form.deliverables.term.fields.description")}
            </Form.Label>
            <Placeholder xs={12} className="mb-4 placeholder--quill" />
            <Form.Label>
              {t("form.deliverables.term.fields.cost_per_sprint")}
            </Form.Label>
            <Placeholder xs={12} className="mb-4 placeholder--input" />
          </Placeholder>
          <Placeholder animation="glow">
            <Row className="gx-5">
              <Col lg={4} className="mb-4">
                <Form.Label>
                  {t("form.deliverables.term.fields.multiplier")}
                </Form.Label>
                <Placeholder xs={12} className="placeholder--input" />
              </Col>
              <Col lg={4} className="mb-4">
                <Form.Label>
                  {t("form.deliverables.term.fields.duration")}
                </Form.Label>
                <Placeholder xs={12} className="placeholder--input" />
              </Col>
              <Col lg={4} className="mb-4">
                <Form.Label>
                  {t("form.deliverables.term.fields.total_cost")}
                </Form.Label>
                <Placeholder xs={12} className="placeholder--input" />
              </Col>
            </Row>
          </Placeholder>
        </>
      ) : (
        <>
          <Form.Group className="mb-4">
            <Form.Label>{t("form.deliverables.term.fields.xpert")}</Form.Label>
            <CustomSelect
              control={control}
              name={getFormValuePath("xpert_involved")}
              isLoading={
                (!!catalogTerm && areXpertTermsLoading) ||
                (!!xpertTerms?.length && areXpertsLoading) ||
                isGetXpertsParamsDebouncing
              }
              isClearable
              isDisabled={!catalogTerm || disableXpertInvolved}
              options={xpertOptions}
              onAfterChange={(option) => {
                onChangeXpert((option as ICustomSelectOption)?.value);
              }}
            />
            <FieldErrorMessage
              field={
                field_index !== undefined
                  ? (errors as any).terms?.[field_index]?.xpert_involved
                  : (errors as any).xpert_involved
              }
              fieldName={t("form.deliverables.term.fields.xpert")}
            />
          </Form.Group>

          {showSubDomainField && (
            <Form.Group className="mb-4">
              <Form.Label>
                {t("form.deliverables.term.fields.sub_domain")}
              </Form.Label>
              <CustomSelect
                control={control}
                name={getFormValuePath("sub_domain")}
                options={(subDomains || []).map((subDomain) => ({
                  value: subDomain.id,
                  label: subDomain.name,
                }))}
                isClearable
              />
              <FieldErrorMessage
                field={
                  field_index !== undefined
                    ? (errors as any).terms?.[field_index]?.sub_domain
                    : (errors as any).sub_domain
                }
                fieldName={t("form.deliverables.term.fields.sub_domain")}
              />
            </Form.Group>
          )}

          <Form.Group className="mb-4">
            <Form.Label>
              {t("form.deliverables.term.fields.start_date")}
            </Form.Label>

            <CustomDateInput
              name={getFormValuePath("start_date")}
              minDate={new Date(minStartDate)}
              isInvalid={
                field_index !== undefined
                  ? !!(errors as any).terms?.[field_index]?.start_date
                  : !!(errors as any).start_date
              }
            />
            <FieldErrorMessage
              field={
                field_index !== undefined
                  ? (errors as any).terms?.[field_index]?.start_date
                  : (errors as any).start_date
              }
              fieldName={t("form.deliverables.term.fields.start_date")}
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>
              {t("form.deliverables.term.fields.description")}
            </Form.Label>
            <Controller
              control={control}
              name={getFormValuePath("description")}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <>
                  <ReactQuill
                    className={`${error && "ql-invalid"} ${
                      !getValues(getFormValuePath("xpert_involved")) &&
                      "ql-disabled"
                    }`}
                    value={value}
                    onChange={(value) => {
                      if (value.replace(/(<([^>]+)>)/gi, "")) {
                        onChange(value);
                      } else {
                        onChange(undefined);
                      }
                    }}
                    readOnly={!getValues(getFormValuePath("xpert_involved"))}
                  />
                  <FieldErrorMessage
                    field={error}
                    fieldName={t("form.deliverables.term.fields.description")}
                  />
                </>
              )}
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>
              {t("form.deliverables.term.fields.cost_per_sprint")}
            </Form.Label>

            <Form.Control
              type="text"
              {...register(getFormValuePath("cost_per_sprint"))}
              isInvalid={
                field_index !== undefined
                  ? !!(errors as any).terms?.[field_index]?.cost_per_sprint
                  : !!(errors as any).cost_per_sprint
              }
              disabled={!getValues(getFormValuePath("xpert_involved"))}
            />

            <FieldErrorMessage
              field={
                field_index !== undefined
                  ? (errors as any).terms?.[field_index]?.cost_per_sprint
                  : (errors as any).cost_per_sprint
              }
              fieldName={t("form.deliverables.term.fields.cost_per_sprint")}
            />
          </Form.Group>

          <Row className="gx-5">
            <Col lg={4} className="mb-4">
              <Form.Label>
                {t("form.deliverables.term.fields.multiplier")}
              </Form.Label>
              <Form.Group>
                <CustomSelect
                  control={control}
                  name={getFormValuePath("multiplier")}
                  options={getMultiplierOptions()}
                />

                <FieldErrorMessage
                  field={
                    field_index !== undefined
                      ? (errors as any).terms?.[field_index]?.multiplier
                      : (errors as any).multiplier
                  }
                  fieldName={t("form.deliverables.term.fields.multiplier")}
                />
              </Form.Group>
            </Col>
            <Col lg={4} className="mb-4">
              <Form.Label>
                {t("form.deliverables.term.fields.duration")}
              </Form.Label>
              <Form.Control
                disabled
                value={`${
                  (getValues(getFormValuePath("duration")) || 0) *
                  (getValues(getFormValuePath("multiplier")) || 0)
                } ${t("durations.weeks", {
                  count:
                    (getValues(getFormValuePath("duration")) || 0) *
                    (getValues(getFormValuePath("multiplier")) || 0),
                })}`}
              />
            </Col>
            <Col lg={4} className="mb-4">
              <Form.Label>
                {t("form.deliverables.term.fields.total_cost")}
              </Form.Label>
              <Form.Control
                type="text"
                value={`${getValues(
                  getFormValuePath("currency")
                )} ${toCurrencyString(
                  (watch(getFormValuePath("cost_per_sprint")) || 0) *
                    (getValues(getFormValuePath("multiplier")) || 0),
                  "USD",
                  2
                )}`}
                disabled
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default XchangeTermDetailsForm;
