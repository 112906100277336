import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IXchangeProductCreateUpdate } from "@interfaces/products/IXchangeProduct";
import XchangeProductService from "@services/XchangeProductService";

interface IMutationVars {
  xchangeId: number;
  xchangeProduct: IXchangeProductCreateUpdate;
}

export const useXchangeProductCreate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ xchangeId, xchangeProduct }: IMutationVars) =>
      XchangeProductService.createXchangeProduct(xchangeId, xchangeProduct),
    {
      onSuccess: (_, { xchangeId }) => {
        queryClient.invalidateQueries(["xchanges", { id: xchangeId }]);
      },
    }
  );
};
