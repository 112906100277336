import { Stack } from "react-bootstrap";
import BreakdownSection, {
  IBreakdownSectionProps,
} from "./BreakdownSection/BreakdownSection";
import "./Breakdown.scss";

const Breakdown = ({ sections }: { sections: IBreakdownSectionProps[] }) => {
  return (
    <div className="Breakdown p-2 justify-content-between bg-primary text-light">
      <Stack direction="horizontal" className="p-3">
        {sections.map((section, index) => (
          <BreakdownSection key={`breakdown_section_${index}`} {...section} />
        ))}
      </Stack>
    </div>
  );
};

export default Breakdown;
