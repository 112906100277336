import { RefAttributes, useState } from "react";
import SelectComponent, {
  components,
  ControlProps,
  IndicatorsContainerProps,
  InputProps,
  MenuProps,
  ValueContainerProps,
  SingleValueProps,
  MultiValueProps,
  MultiValueRemoveProps,
  PlaceholderProps,
} from "react-select";
import Select from "react-select/dist/declarations/src/Select";
import { GroupBase } from "react-select/dist/declarations/src/types";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";
import "./SelectBadges.scss";

const ValueContainer = (props: ValueContainerProps<any, any, any>) => {
  return (
    <components.ValueContainer
      {...props}
      className="Select-Badges__Value-Container"
    />
  );
};

const SingleValue = (props: SingleValueProps<any, any, any>) => {
  return (
    <components.SingleValue className="Select-Badges__Value" {...props}>
      {props.getValue()[0].label}
      {props.selectProps.isClearable && (
        <span
          className="Select-Badges__Value-Remove ms-2"
          onClick={() => {
            props.selectProps.onChange(null, {
              action: "clear",
              removedValues: props.getValue(),
            });
          }}
        >
          <i className="fa-solid fa-circle-xmark" />
        </span>
      )}
    </components.SingleValue>
  );
};

const MultiValue = (props: MultiValueProps<any, any, any>) => {
  return <components.MultiValue className="Select-Badges__Value" {...props} />;
};

const MultiValueRemove = (props: MultiValueRemoveProps<any, any, any>) => {
  return (
    <div className="Select-Badges__Value-Remove">
      <components.MultiValueRemove {...props}>
        <i className="fa-solid fa-circle-xmark" />
      </components.MultiValueRemove>
    </div>
  );
};

const Control = (props: ControlProps<any, any, any>) => {
  return (
    <components.Control
      {...props}
      className={`Select-Badges__Control ${
        props.isDisabled && "Select-Badges__Control--disabled"
      }`}
    ></components.Control>
  );
};

const IndicatorsContainer = (
  props: IndicatorsContainerProps<any, any, any>
) => {
  return <></>;
};

const Input = (props: InputProps<any, any, any>) => {
  return (props.isMulti && props.options.length !== props.getValue().length) ||
    props.getValue().length === 0 ? (
    <components.Input
      {...props}
      className="Select-Badges__Input"
      placeholder="+ Add"
      innerRef={props.innerRef}
    ></components.Input>
  ) : (
    <></>
  );
};

const Placeholder = (props: PlaceholderProps<any, any, any>) => {
  return <></>;
};
const Menu = (props: MenuProps<any, any, any> & { leftOffset: number }) => {
  return (
    <div
      style={{
        marginLeft: props.leftOffset,
      }}
    >
      <components.Menu
        {...props}
        className="Select-Badges__Menu"
      ></components.Menu>
    </div>
  );
};

const SelectBadges = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: StateManagerProps<Option, IsMulti, Group> &
    RefAttributes<Select<Option, IsMulti, Group>>
) => {
  const [menuLeftOffset, setMenuLeftOffset] = useState(0);

  const inputRef = (instance: HTMLInputElement | null) => {
    setMenuLeftOffset(
      (instance?.parentElement?.getBoundingClientRect()?.left || 0) -
        (instance?.parentElement?.parentElement?.getBoundingClientRect()
          ?.left || 0)
    );
  };

  return (
    <div>
      <SelectComponent
        {...props}
        className="Select-Badges"
        components={{
          ValueContainer,
          SingleValue,
          MultiValue,
          MultiValueRemove,
          Control,
          IndicatorsContainer,
          Input: (inputProps: InputProps<any, any, any>) => (
            <Input {...inputProps} innerRef={inputRef} />
          ),
          Placeholder,
          Menu: (menuProps: MenuProps<any, any, any>) => (
            <Menu {...menuProps} leftOffset={menuLeftOffset} />
          ),
        }}
      />
    </div>
  );
};

export default SelectBadges;
