import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  IXpertTeamMember,
  IXpertTeamMemberCreateUpdate,
} from "@interfaces/IXpert";
import XpertService from "@services/XpertService";

interface IMutationVars {
  xpertId: number;
  xpertTeamMember: IXpertTeamMemberCreateUpdate;
}

export const useXpertTeamMemberUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ xpertId, xpertTeamMember }: IMutationVars) =>
      XpertService.updateXpertTeamMember(xpertId, xpertTeamMember),
    {
      onSuccess: (data: IXpertTeamMember) => {
        queryClient.invalidateQueries([
          "xperts",
          { xpertId: data.xpert },
          "teams",
        ]);
      },
    }
  );
};
