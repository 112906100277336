/* eslint-disable jsx-a11y/alt-text */
import { Button, Form, FormCheckProps } from "react-bootstrap";
import "./ImageCheckbox.scss";

const ImageCheckbox = (
  props: {
    img: string;
    icon?: string;
  } & FormCheckProps
) => {
  return (
    <div
      className={`
    Image-Checkbox ${props.checked && " Image-Checkbox--selected"} p-4 ${
        props.className
      }`}
      onClick={props.onClick}
    >
      <Form.Check type="checkbox" {...props} className="d-none" readOnly />
      <img
        src={props.img}
        className="Image-Checkbox__Image mb-4 w-100"
        placeholder={props.placeholder}
      />
      <Button className="Image-Checkbox__Button w-100 p-4">
        {props.icon && (
          <div className="Image-Checkbox__Button__Icon">
            <i
              className={`fa-regular ${
                props.checked ? "fa-check" : props.icon
              }`}
            />
          </div>
        )}
        {props.placeholder}
      </Button>
    </div>
  );
};

export default ImageCheckbox;
