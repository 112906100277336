import IDomain from "@interfaces/IDomain";
import Service from "@services/Service";

export interface IGetDomainsParams {
  id__in?: string;
}

export default class DomainService extends Service {
  public static async getDomains(params?: IGetDomainsParams) {
    const response = await this.axios.get<IDomain[]>("/domains", {
      params,
    });
    return response.data;
  }
}
