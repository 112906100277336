import { useQuery } from "@tanstack/react-query";
import IXpert from "@interfaces/IXpert";
import XpertService from "@services/XpertService";

export const useXpert = (
  id: number,
  options?: {
    enabled?: boolean;
    onSuccess?: (data: IXpert) => void;
  }
) =>
  useQuery(["xperts", { id }], () => XpertService.getXpert(id), {
    enabled: options?.enabled,
    onSuccess: options?.onSuccess,
  });
