import Joi from "joi";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isFieldRequired } from "@helpers/joi-utils";
import { allCountries } from "country-region-data";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import CustomSelect, {
  ICustomSelectOption,
} from "@components/CustomSelect/CustomSelect";

const CountrySelect = ({
  label,
  FormValidationSchema,
  disabled = false,
  isClearable = false,
  showRequiredAsterisk = true,
  affectsRegion = true,
}: {
  label?: string;
  FormValidationSchema: Joi.ObjectSchema;
  disabled?: boolean;
  isClearable?: boolean;
  showRequiredAsterisk?: boolean;
  affectsRegion?: boolean;
}) => {
  const { t } = useTranslation();

  const {
    control,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Form.Label
        aria-required={
          showRequiredAsterisk &&
          isFieldRequired(FormValidationSchema, "country")
        }
      >
        {label ?? t("form.fields.country")}
      </Form.Label>
      <CustomSelect
        control={control}
        name="country"
        options={allCountries.map(([name]) => ({
          value: name,
          label: name,
        }))}
        onBeforeChange={(option) => {
          if (
            affectsRegion &&
            (option as ICustomSelectOption)?.value !== getValues("country")
          ) {
            setValue("region", null);
            trigger("region");
          }
        }}
        isDisabled={disabled}
        isClearable={isClearable}
      />
      <FieldErrorMessage
        field={errors.country}
        fieldName={label ?? t("form.fields.country")}
      />
    </>
  );
};

export default CountrySelect;
