import "./BreakdownSection.scss";

export interface IBreakdownSectionProps {
  title: string;
  values: {
    value?: string;
    valueType?: string;
  }[];
  highlight?: boolean;
}

const BreakdownSection = ({
  title,
  values = [],
  highlight = false,
}: IBreakdownSectionProps) => {
  return (
    <div
      className={`Breakdown-Section ${
        highlight && "Breakdown-Section--highlight ms-auto"
      }`}
    >
      <div className="Breakdown-Section__Title">{title}</div>

      <div>
        {values.map(({ value, valueType }) => (
          <div
            key={`breakdown_${valueType}`}
            className="Breakdown-Section__Value"
          >
            {value}
            <span className="Breakdown-Section__Value__Type ms-1">
              {valueType}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BreakdownSection;
