import CompanyService from "@services/CompanyService";
import Joi, { ExternalHelpers } from "joi";

export const isFieldRequired = (schema: Joi.ObjectSchema, field: string) => {
  return schema?.extract(field)?._flags?.presence === "required";
};

export const money = () => {
  return Joi.string().pattern(new RegExp("^\\d+(\\.\\d{1,2})?$")).messages({
    "string.pattern.base": "errors.custom.money",
  });
};

// export const password = () => {
//   return (
//     Joi.string()
//       // min 12 chars total
//       // min 1 uppsercase
//       // min 1 lowercase
//       // min 1 number
//       // min 1 special char
//       .pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{12,}$/)
//       .messages({
//         "string.pattern.base": "errors.custom.password",
//       })
//   );
// };

/**
 * Validates if the `company_name` value is a unique Company Name in the database.
 * Can be used using `.external()`
 * Form must provide a context with:
 *    `id` being the ID of the Company, if any
 *    `default_company_name` being the Company Name when the form was init, if any. Validation is skipped if the value don't change.
 */
export const isCompanyNameUnique = async (
  company_name: string,
  helpers: ExternalHelpers
) => {
  const default_company_name = helpers.prefs.context?.default_company_name;

  if (default_company_name !== company_name) {
    const companyId = helpers.prefs.context?.id;
    const companies = await CompanyService.getCompanies({
      company_name__iexact: company_name,
      id__not__in: companyId,
    });

    if (companies.count > 0) {
      throw new Joi.ValidationError(
        "custom.company_name_not_unique",
        [
          {
            message: "custom.company_name_not_unique",
            path: ["company_name"],
            type: "custom.company_name_not_unique",
          },
        ],
        company_name
      );
    }
  }
};

/**
 * Validates if the `company_legal_name` value is a unique Company Legal Name in the database.
 * Can be used using `.external()`
 * Form must provide a context with:
 *    `id` being the ID of the Company, if any
 *    `default_company_legal_name` being the Company Legal Name when the form was init, if any. Validation is skipped if the value don't change.
 */
export const isCompanyLegalNameUnique = async (
  company_legal_name: string,
  helpers: ExternalHelpers
) => {
  if (!company_legal_name) {
    return;
  }

  const default_company_legal_name =
    helpers.prefs.context?.default_company_legal_name;
  if (default_company_legal_name !== company_legal_name) {
    const companyId = helpers.prefs.context?.id;
    const companies = await CompanyService.getCompanies({
      company_legal_name__iexact: company_legal_name,
      id__not__in: companyId,
    });

    if (companies.count > 0) {
      throw new Joi.ValidationError(
        "custom.company_name_not_unique",
        [
          {
            message: "custom.company_name_not_unique",
            path: ["company_legal_name"],
            type: "custom.company_name_not_unique",
          },
        ],
        company_legal_name
      );
    }
  }
};
