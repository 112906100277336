import IStage from "@interfaces/IStage";
import Service from "@services/Service";

export default class StageService extends Service {
  public static async getStages() {
    const response = await this.axios.get<IStage[]>("/stages");
    return response.data;
  }

  public static async createStage(stage: IStage) {
    const response = await this.axios.post<IStage>("/stages", stage);
    return response.data;
  }
}
