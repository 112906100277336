import { useMutation, useQueryClient } from "@tanstack/react-query";
import InvoiceService from "@services/InvoiceService";
import { IInvoiceLineItemWrite } from "@interfaces/IInvoice";

interface MutationVars {
  invoiceId: number;
  lineItemId: number;
  data: IInvoiceLineItemWrite;
}

export const useInvoiceLineItemUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ invoiceId, lineItemId, data }: MutationVars) =>
      InvoiceService.updateLineItem(invoiceId, lineItemId, data),
    {
      onSuccess: (_, { invoiceId }) => {
        queryClient.invalidateQueries(["invoices", { id: invoiceId }]);
      },
    }
  );
};
