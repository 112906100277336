import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import IStaticMedia from "@interfaces/IStaticMedia";
import StaticMediaService, {
  IGetStaticMediasParams,
} from "@services/StaticMediaService";

export const useStaticMedias = (
  params?: IGetStaticMediasParams,
  options?: Pick<
    UseQueryOptions<IStaticMedia[], Error>,
    "enabled" | "onSuccess"
  >
) =>
  useQuery(
    ["static-medias", { params }],
    () => StaticMediaService.getStaticMedias(params),
    {
      ...options,
    }
  );
