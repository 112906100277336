import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import IClient from "@interfaces/IClient";
import ClientService, { IGetClientsParams } from "@services/ClientService";

export const useClients = (
  params?: IGetClientsParams,
  options?: Pick<UseQueryOptions<IClient[], Error>, "enabled" | "onSuccess">
) =>
  useQuery(
    ["clients", { params }],
    () => ClientService.getClients(params),
    options
  );
