import IContract from "@interfaces/IContract";
import Service from "@services/Service";

export interface IGetContractsParams {
  name?: string;
  template?: number;
  contract_type__code__in?: string;
  xchange__id__in?: string;
  document_id?: string;
  party__subject_id__in?: string;
  party__subject_type__model__in?: string;
  party__main?: boolean;
}

export interface IGenerateContractBodyParams {
  is_preview?: boolean;
  name?: string;
  data?: object;
}

export default class ContractService extends Service {
  public static async getContracts(params?: IGetContractsParams) {
    const response = await this.axios.get<IContract[]>("/contracts", {
      params,
    });
    return response.data;
  }

  public static async getContract(id: number) {
    const response = await this.axios.get<IContract>(`/contracts/${id}`);
    return response.data;
  }

  public static async createContract(contract: IContract) {
    const response = await this.axios.post<IContract>("/contracts", contract);
    return response.data;
  }

  public static async updateContract(contract: IContract) {
    const response = await this.axios.put<IContract>(
      `/contracts/${contract.id}`,
      contract
    );
    return response.data;
  }

  public static async generateContract(
    contractId: number,
    params?: IGenerateContractBodyParams
  ) {
    const response = await this.axios.post<IContract>(
      `/contracts/${contractId}/generate`,
      params
    );
    return response.data;
  }

  public static async downloadContractPreview(contractId: number) {
    const response = await this.axios.get(`/contracts/${contractId}/preview`, {
      responseType: "blob",
    });
    return response.data;
  }
}
