import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { isEqual, set } from "lodash";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import SelectBadges from "@components/SelectBadges/SelectBadges";
import { isFieldRequired } from "@helpers/joi-utils";
import { useSubDomains } from "@hooks/subdomain/useSubDomains";
import { useCatalogProductCreate } from "@hooks/product/useCatalogProductCreate";
import { useCatalogProductUpdate } from "@hooks/product/useCatalogProductUpdate";
import { useStages } from "@hooks/stage/useStages";
import ICatalogProduct from "@interfaces/products/ICatalogProduct";

export interface ProductDetailsFormValues {
  title?: string;
  description?: string;
  sub_domains?: number[];
  stages?: number[];
}

export const productDetailsValidationSchema = Joi.object({
  title: Joi.string().required(),
  description: Joi.string().allow("").allow(null),
  sub_domains: Joi.array(),
  stages: Joi.array(),
});

const ProductDetailsForm = ({ product }: { product: ICatalogProduct }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const formMethods = useForm<ProductDetailsFormValues>({
    mode: "onChange",
    resolver: joiResolver(productDetailsValidationSchema),
    defaultValues: {
      title: product?.title,
      description: product?.description,
      sub_domains: (product?.sub_domains ?? []).map((d) => d.id) || [],
      stages: (product?.stages ?? []).map((s) => s.id) || [],
    },
  });
  const {
    control,
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const { data: subDomains } = useSubDomains();
  const { data: stages } = useStages();

  const [pastOnBlurData, setPastOnBlurData] =
    useState<ProductDetailsFormValues>(getValues());

  const createCatalogProductMutation = useCatalogProductCreate();
  const updateCatalogProductMutation = useCatalogProductUpdate();

  const formSubmit = (data: ProductDetailsFormValues) => {
    if (!product) {
      createCatalogProductMutation.mutate(
        {
          title: data.title,
        },
        {
          onSuccess: (newCatalogProduct) => {
            navigate(`/products/${newCatalogProduct.id}`, {
              replace: true,
            });
          },
        }
      );
    } else {
      const changedValues = Object.keys(data).reduce((acc, key) => {
        if (
          !isEqual(
            data[key as keyof ProductDetailsFormValues],
            pastOnBlurData[key as keyof ProductDetailsFormValues]
          )
        ) {
          set(acc, key, data[key as keyof ProductDetailsFormValues]);
        }
        return acc;
      }, {} as ProductDetailsFormValues);

      if (Object.keys(changedValues).length > 0) {
        updateCatalogProductMutation.mutate({
          id: product.id!,
          catalogProduct: { ...changedValues },
        });
      }
    }

    setPastOnBlurData(data);
  };

  return (
    <FormProvider {...formMethods}>
      <Form className="ps-4 pe-4 pb-4">
        <h5 className="mb-5">{t("product.details.details.subtitle")}</h5>
        <Form.Group className="mb-3">
          <Form.Label
            aria-required={isFieldRequired(
              productDetailsValidationSchema,
              "title"
            )}
          >
            {t("product.details.details.labels.title")}
          </Form.Label>
          <Form.Control
            type="text"
            {...register("title")}
            isInvalid={!!errors.title}
            onBlur={handleSubmit(formSubmit)}
          />
          <FieldErrorMessage
            field={errors.title}
            fieldName={t("product.details.details.labels.title")}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label
            aria-required={isFieldRequired(
              productDetailsValidationSchema,
              "description"
            )}
          >
            {t("product.details.details.labels.description")}
          </Form.Label>
          <Controller
            control={control}
            name="description"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <>
                <ReactQuill
                  className={`${error && "ql-invalid"} ${
                    !product && "ql-disabled"
                  }`}
                  value={value}
                  onChange={(value) => {
                    if (value.replace(/(<([^>]+)>)/gi, "")) {
                      onChange(value);
                    } else {
                      onChange(null);
                    }
                  }}
                  readOnly={!product}
                  onBlur={(_, source) => {
                    if (source === "silent") {
                      return;
                    }
                    handleSubmit(formSubmit)();
                  }}
                />
                <FieldErrorMessage
                  field={error}
                  fieldName={t("product.details.details.labels.description")}
                />
              </>
            )}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label
            aria-required={isFieldRequired(
              productDetailsValidationSchema,
              "sub_domains"
            )}
          >
            {t("product.details.details.labels.sub_domains")}
          </Form.Label>
          <Controller
            control={control}
            name="sub_domains"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <>
                <SelectBadges
                  className={`select ${!!error ? "select-invalid" : ""}`}
                  name="sub_domains"
                  value={
                    value
                      ? value.map((v) => {
                          return {
                            value: v,
                            label: subDomains?.find((sd) => sd.id === v)?.name,
                          };
                        })
                      : []
                  }
                  options={
                    subDomains?.map((sd) => ({
                      value: sd.id,
                      label: sd.name,
                    })) ?? []
                  }
                  isMulti
                  isClearable={false}
                  onChange={(values) => {
                    onChange(values.map((value) => value.value));
                    handleSubmit(formSubmit)();
                  }}
                  isDisabled={!product}
                />
                <FieldErrorMessage
                  field={error}
                  fieldName={t("product.details.details.labels.sub_domains")}
                />
              </>
            )}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label
            aria-required={isFieldRequired(
              productDetailsValidationSchema,
              "stages"
            )}
          >
            {t("product.details.details.labels.stages")}
          </Form.Label>
          <Controller
            control={control}
            name="stages"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <>
                <SelectBadges
                  className={`select ${!!error ? "select-invalid" : ""}`}
                  name="stages"
                  value={
                    value
                      ? value.map((v) => {
                          return {
                            value: v,
                            label: stages?.find((d) => d.id === v)?.name,
                          };
                        })
                      : []
                  }
                  options={
                    stages?.map((d) => ({ value: d.id!, label: d.name })) ?? []
                  }
                  isMulti
                  isClearable={false}
                  onChange={(values) => {
                    onChange(values.map((value) => value.value));
                    handleSubmit(formSubmit)();
                  }}
                  isDisabled={!product}
                />
                <FieldErrorMessage
                  field={error}
                  fieldName={t("product.details.details.labels.stages")}
                />
              </>
            )}
          />
        </Form.Group>
      </Form>
    </FormProvider>
  );
};

export default ProductDetailsForm;
