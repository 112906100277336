import IXchange from "@interfaces/IXchange";
import XchangeService from "@services/XchangeService";
import { useXchangeUpdate } from "./useXchangeUpdate";

const useXchangeGoalEndDateAutoUpdate = (xchange?: IXchange) => {
  const updateXchangeMutation = useXchangeUpdate();

  const mutate = async (xchange?: IXchange) => {
    if (xchange?.id) {
      const epics = await XchangeService.getEpics(xchange?.id);

      const epicsTotalDuration = epics.reduce(
        (acc, epic) => acc + epic.duration!,
        0
      );

      if (
        xchange &&
        !xchange.was_goal_end_date_set_by_user &&
        xchange.start_date
      ) {
        const endGoalDateForDuration = new Date(xchange.start_date);
        endGoalDateForDuration.setDate(
          endGoalDateForDuration.getDate() + epicsTotalDuration
        );

        if (
          !xchange.goal_end_date ||
          new Date(xchange.goal_end_date).getTime() !==
            endGoalDateForDuration.getTime()
        ) {
          updateXchangeMutation.mutate({
            updatedXchange: {
              id: xchange.id!,
              goal_end_date: endGoalDateForDuration.toISOString(),
            },
          });
        }
      }
    }
  };

  return { mutate };
};

export default useXchangeGoalEndDateAutoUpdate;
