import AxiosErrorAlert from "@components/AxiosErrorAlert/AxiosErrorAlert";
import CustomSelect, {
  ICustomSelectOption,
} from "@components/CustomSelect/CustomSelect";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import GenericModal from "@components/GenericModal/GenericModal";
import XpertTermForm, {
  MultipleXpertTermsFormValues,
  MultipleXpertTermsSchema,
  XpertTermSchema,
} from "@components/XpertTermForm/XpertTermForm";
import { isFieldRequired } from "@helpers/joi-utils";
import { joiResolver } from "@hookform/resolvers/joi";
import { useXpertTermCreate } from "@hooks/term/useXpertTermCreate";
import { useXpertTermUpdate } from "@hooks/term/useXpertTermUpdate";
import { useXperts } from "@hooks/xpert/useXperts";
import ICatalogTerm from "@interfaces/terms/ICatalogTerm";
import IXpertTerm from "@interfaces/terms/IXpertTerm";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const AddXpertModal = ({
  show,
  catalogTerm,
  xpertTermToEdit,
  onClose,
  onFinish,
}: {
  show: boolean;
  catalogTerm: ICatalogTerm;
  xpertTermToEdit?: IXpertTerm;
  onClose: () => void;
  onFinish: () => void;
}) => {
  const { t } = useTranslation();

  const [xpertOptions, setXpertOptions] = useState<
    { value: number; label: string }[]
  >([]);

  const { isFetching: areXpertsFetching } = useXperts(
    {
      xpert_terms__catalog_term__not__in: catalogTerm.id!.toString(),
    },
    {
      enabled: show,
      onSuccess: (xperts) => {
        setXpertOptions(
          xperts.map((xpert) => ({
            value: xpert.id!,
            label: xpert.name!,
          }))
        );
      },
    }
  );

  const formMethods = useForm<MultipleXpertTermsFormValues>({
    resolver: joiResolver(MultipleXpertTermsSchema),
    mode: "onChange",
  });

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const { fields: xpert_terms, replace: replaceXpertTerms } = useFieldArray({
    control,
    name: "xpert_terms",
  });

  useEffect(() => {
    if (show) {
      if (xpertTermToEdit) {
        replaceXpertTerms([
          {
            xpert_involved: xpertTermToEdit?.xpert_involved,
            stages: xpertTermToEdit?.stages?.map((stage) => stage.id!),
            duration: xpertTermToEdit?.duration,
            cost_per_sprint: xpertTermToEdit?.cost_per_sprint,
            currency: xpertTermToEdit?.currency,
            catalog_term: xpertTermToEdit?.catalog_term,
            sub_domain: xpertTermToEdit?.sub_domain?.id,
            description: xpertTermToEdit?.description,
          },
        ]);
      }

      setValue("catalog_terms", [catalogTerm.id!]);
    }
  }, [catalogTerm, xpertTermToEdit, show, setValue, replaceXpertTerms]);

  const updateXpertTermMutation = useXpertTermUpdate();
  const createXpertTermMutation = useXpertTermCreate();
  const saveXpertTerm = (data: MultipleXpertTermsFormValues) => {
    const xpertTerm = data.xpert_terms[0];
    if (xpertTermToEdit) {
      updateXpertTermMutation.mutate(
        {
          xpertTerm: {
            id: xpertTermToEdit!.id!,
            stages: xpertTerm.stages!,
            duration: xpertTerm.duration!,
            cost_per_sprint: xpertTerm.cost_per_sprint!,
            currency: xpertTerm.currency!,
            sub_domain: xpertTerm.sub_domain!,
            description: xpertTerm.description,
          },
        },
        {
          onSuccess: () => {
            handleClose();
            onFinish();
          },
        }
      );
    } else {
      createXpertTermMutation.mutate(
        {
          xpert_involved: xpertTerm.xpert_involved!,
          stages: xpertTerm.stages!,
          duration: xpertTerm.duration!,
          cost_per_sprint: xpertTerm.cost_per_sprint!,
          currency: xpertTerm.currency!,
          title: catalogTerm.title,
          description: xpertTerm.description,
          sub_domain: xpertTerm.sub_domain,
          catalog_term: catalogTerm.id,
        },
        {
          onSuccess: () => {
            handleClose();
            onFinish();
          },
        }
      );
    }
  };

  const handleClose = () => {
    replaceXpertTerms([]);
    onClose();
  };

  return (
    <GenericModal
      title={t(`term.details.xperts.${xpertTermToEdit ? "edit" : "add"}.title`)}
      show={show}
      onClose={handleClose}
      onFinish={() => {
        handleSubmit(saveXpertTerm)();
      }}
      closeText={t("actions.cancel")}
      finishText={t(
        `term.details.xperts.${xpertTermToEdit ? "edit" : "add"}.button`
      )}
    >
      <FormProvider {...formMethods}>
        <h3 className="mt-4 mb-6">
          {t(
            `term.details.xperts.${xpertTermToEdit ? "edit" : "add"}.subtitle`
          )}
        </h3>

        {!xpertTermToEdit && (
          <Form.Group className="mb-4">
            <Form.Label
              aria-required={isFieldRequired(XpertTermSchema, "xpert_involved")}
            >
              {t(
                `term.details.xperts.${
                  xpertTermToEdit ? "edit" : "add"
                }.xpert_involved`
              )}
            </Form.Label>
            <CustomSelect
              control={control}
              name="xpert_terms.0.xpert_involved"
              isLoading={areXpertsFetching}
              options={xpertOptions}
              onAfterChange={(option) => {
                replaceXpertTerms([
                  {
                    xpert_involved: (option as ICustomSelectOption)?.value,
                    stages: [],
                    duration: null,
                    cost_per_sprint: null,
                    currency: null,
                    catalog_term: catalogTerm.id!,
                    sub_domain: catalogTerm.sub_domain?.id!,
                    description: catalogTerm.description!,
                  },
                ]);
              }}
            />
            <FieldErrorMessage
              field={errors.xpert_terms?.[0]?.xpert_involved}
              fieldName={t(
                `term.details.xperts.${
                  xpertTermToEdit ? "edit" : "add"
                }.xpert_involved`
              )}
            />
          </Form.Group>
        )}

        {xpert_terms.map((xpert_term, index) => (
          <XpertTermForm
            key={`xpert_term_for_${xpert_term.catalog_term}`}
            fieldIndex={index}
          />
        ))}

        <AxiosErrorAlert
          response={
            createXpertTermMutation.error || updateXpertTermMutation.error
          }
        />
      </FormProvider>
    </GenericModal>
  );
};

export default AddXpertModal;
