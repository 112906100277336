import { Button } from "react-bootstrap";
import "./ProgressBoard.scss";

export interface IProgressBoardStep {
  title: string;
  description: string;
  iconClasses: string;
  isCompleted: boolean;
  onClick?: () => void;
  buttonLabel?: string;
}

const ProgressBoard = ({
  steps,
  className,
}: {
  steps: IProgressBoardStep[];
  className?: string;
}) => {
  return (
    <div className={`Progress-Board ${className}`}>
      {steps.map((step, index) => (
        <div
          className={`Progress-Board__Step ${
            step.isCompleted && "Progress-Board__Step--completed"
          }`}
          key={`progres_board_step_${index}`}
        >
          <div className="Progress-Board__Step__Icon">
            <i className={step.iconClasses} />
          </div>
          <div className="Progress-Board__Step__Info">
            <div className="Progress-Board__Step__Info__Title">
              {index + 1}. {step.title}
            </div>
            <div className="Progress-Board__Step__Info__Description">
              {step.description}
            </div>
          </div>

          <div className="Progress-Board__Step__Status ms-auto">
            {step.isCompleted ? (
              <i className="fa-solid fa-circle-check" />
            ) : (
              !!step.onClick &&
              !!step.buttonLabel && (
                <Button size="sm" onClick={step.onClick}>
                  {step.buttonLabel}
                </Button>
              )
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProgressBoard;
