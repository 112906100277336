import { PartySubjectType } from "@interfaces/IParty";

export const getModelHyperLink = (subject_type: PartySubjectType) => {
  switch (subject_type) {
    case PartySubjectType.Xpert:
      return "xperts";
    case PartySubjectType.Client:
      return "clients";
    case PartySubjectType.Company:
      return "companies";
    case PartySubjectType.Person:
      return "people";
  }
};

export const parseModelHyperLink = (
  hyperlink: string
): {
  model: string;
  id: number;
} => {
  const splitted = hyperlink.split("/").filter((item) => item !== "");

  return {
    model: splitted[0],
    id: parseInt(splitted[1]),
  };
};
