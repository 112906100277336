import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ICatalogTermCreateUpdate } from "@interfaces/terms/ICatalogTerm";
import CatalogTermService from "@services/CatalogTermService";

export const useCatalogTermCreate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (term: ICatalogTermCreateUpdate) =>
      CatalogTermService.createCatalogTerm(term),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["terms"]);
      },
    }
  );
};
