import { useEffect, useState } from "react";
import { Stack, Button, Tab } from "react-bootstrap";
import "./ModalTabs.scss";

type Props = {
  defaultTabKey?: string;
  currentTabKey?: string;
  tabs: {
    tabKey: string;
    tabText: string;
    content: JSX.Element;
    disabled?: boolean;
  }[];
  hideTabs?: boolean;
  onTabChange: (tabKey: string) => void;
};

const ModalTabs = ({
  defaultTabKey,
  currentTabKey,
  tabs,
  onTabChange,
  hideTabs = false,
}: Props) => {
  const isControlled = currentTabKey !== undefined;

  const [activeTabKey, setActiveTabKey] = useState(
    isControlled ? currentTabKey : defaultTabKey
  );

  useEffect(() => {
    if (currentTabKey) {
      setActiveTabKey(currentTabKey);
    }
  }, [currentTabKey]);

  const handleTabChange = (tabKey: string) =>
    isControlled ? onTabChange(tabKey) : setActiveTabKey(tabKey);

  return (
    <>
      {!hideTabs && (
        <Stack
          className="Modal-Tabs w-100 p-2 mb-5"
          direction="horizontal"
          gap={2}
        >
          {tabs.map((tab) => (
            <Button
              key={tab.tabKey}
              className="w-100"
              variant={
                activeTabKey === tab.tabKey ? "primary" : "outline-light"
              }
              onClick={() => handleTabChange(tab.tabKey)}
              disabled={tab.disabled}
            >
              {tab.tabText}
            </Button>
          ))}
        </Stack>
      )}

      <Tab.Content>
        {tabs.map((tab) => (
          <Tab.Pane key={tab.tabKey} active={activeTabKey === tab.tabKey}>
            {tab.content}
          </Tab.Pane>
        ))}
      </Tab.Content>
    </>
  );
};

export default ModalTabs;
