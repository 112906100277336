import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import IEpic from "@interfaces/IEpic";
import XchangeService from "@services/XchangeService";

export const useEpics = (
  xchangeId: number,
  options?: Pick<UseQueryOptions<IEpic[], Error>, "enabled" | "onSuccess">
) =>
  useQuery(
    ["xchanges", { xchangeId }, "epics"],
    () => XchangeService.getEpics(xchangeId),
    options
  );
