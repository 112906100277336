import { UseQueryOptions, useInfiniteQuery } from "@tanstack/react-query";
import XchangeService, { IGetXchangesParams } from "@services/XchangeService";
import IXchange from "@interfaces/IXchange";
import IPage, { IPageParams } from "@interfaces/IPage";

export const useXchanges = (
  params?: IGetXchangesParams & IPageParams,
  options?: Pick<UseQueryOptions<IPage<IXchange>, Error>, "enabled"> & {
    refetchOnWindowFocus?: boolean;
  }
) =>
  useInfiniteQuery(
    ["xchanges", { params }],
    ({ pageParam, signal }) => {
      return XchangeService.getXchanges(
        {
          page: pageParam ?? undefined,
          ...params,
        },
        signal
      );
    },
    {
      getPreviousPageParam: (firstGroup) => firstGroup.previous,
      getNextPageParam: (lastGroup) => lastGroup.next,
      ...options,
    }
  );
