import IContract from "@interfaces/IContract";
import IReviewer from "@interfaces/IReviewer";
import Service from "@services/Service";

export interface IGetReviewersParams {
  contract?: string;
  contract__in?: string;
}

export default class ReviewerService extends Service {
  public static async getReviewers(params?: IGetReviewersParams) {
    const response = await this.axios.get<IContract[]>("/reviewers", {
      params,
    });
    return response.data;
  }

  public static async getReviewer(id: number) {
    const response = await this.axios.get<IContract>(`/reviewers/${id}`);
    return response.data;
  }

  public static async createReviewer(reviewer: IReviewer) {
    const response = await this.axios.post<IContract>("/reviewers", reviewer);
    return response.data;
  }

  public static async deleteReviewer(id: number) {
    const response = await this.axios.delete<{}>(`/reviewers/${id}`);
    return response.data;
  }
}
