import { useMutation, useQueryClient } from "@tanstack/react-query";
import ContractService, {
  IGenerateContractBodyParams,
} from "@services/ContractService";

interface IMutationVars {
  contractId: number;
  params?: IGenerateContractBodyParams;
}

export const useContractGenerate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ["generateContract"],
    ({ contractId, params }: IMutationVars) =>
      ContractService.generateContract(contractId, params),
    {
      onSuccess: (_, { contractId }: IMutationVars) => {
        queryClient.invalidateQueries(["contracts"]);
      },
    }
  );
};
