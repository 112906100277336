import { useMutation, useQueryClient } from "@tanstack/react-query";
import IStage from "@interfaces/IStage";
import StageService from "@services/StageService";

export const useStageCreate = () => {
  const queryClient = useQueryClient();
  return useMutation((stage: IStage) => StageService.createStage(stage), {
    onSuccess: () => {
      queryClient.invalidateQueries(["stages"]);
    },
  });
};
