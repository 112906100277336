import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Badge, Col, Row, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ActionsDropdown from "@components/ListOptionsDropdown/ActionsDropdown";
import { useXperts } from "@hooks/xpert/useXperts";
import IEpic from "@interfaces/IEpic";
import IXpert from "@interfaces/IXpert";
import "./XchangeEpicCard.scss";

type Props = {
  epic?: IEpic;
  index: number;
  dragId: number;
  clientName: string;
  onEdit?: () => void;
  onDelete?: () => void;
};

const XchangeEpicCard = ({
  epic,
  index,
  dragId,
  clientName,
  onEdit,
  onDelete,
}: Props) => {
  const { t } = useTranslation();

  const { data: xperts } = useXperts(
    {
      id__in: epic?.xperts?.join(","),
    },
    {
      enabled: epic?.xperts && epic.xperts.length > 0,
    }
  );

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: dragId, data: epic });

  const cleanDescription =
    epic?.description?.replace(/(<([^>]+)>)/gi, " ") || "";

  return (
    <Row
      className="Xchange-Epic-Card justify-content-end flex-no-wrap bg-light border my-5 mx-0 py-3 px-2 align-items-stretch"
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? "0.3" : "1",
      }}
    >
      <Col
        className="Xchange-Epic-Card__Grip align-items-center d-flex flex-grow-0 p-2"
        {...attributes}
        {...listeners}
      >
        <i className="fa-solid fa-grip-vertical"></i>
      </Col>
      <Col className="flex-grow-0 ps-6 pe-4">
        <div className="Xchange-Epic-Card__Number">{index}</div>
      </Col>
      <Col className="ps-0 pe-0 flex-grow-1">
        <Stack direction="horizontal">
          <Stack>
            <div className="Xchange-Epic-Card__Title pt-1 pb-3">
              {epic?.title}
            </div>
            <div className="Xchange-Epic-Card__Description">
              {cleanDescription.length > 120
                ? `${cleanDescription?.substring(0, 120)}...`
                : cleanDescription}
            </div>
          </Stack>
        </Stack>
      </Col>
      <Col className="ps-0">
        {epic?.client_involved ? (
          <Badge
            pill
            text="light"
            className="Xchange-Epic-Card__Badge ms-2 mb-2 badge-small"
          >
            {clientName}
          </Badge>
        ) : null}
        {(xperts as IXpert[])?.map((xpert) => (
          <Badge
            key={xpert.id}
            pill
            bg="light"
            className="Xchange-Epic-Card__Badge ms-2 mb-2 border badge-small"
          >
            {xpert.name}
          </Badge>
        ))}
      </Col>
      <Col className="text-nowrap text-end flex-grow-0 ps-0 pe-1">
        <div className="Xchange-Epic-Card__Duration">
          {epic?.duration! / 7}{" "}
          {t("durations.weeks", { count: epic?.duration! / 7 })}
        </div>
      </Col>
      <Col className="text-end flex-grow-0 pe-3">
        <ActionsDropdown
          actions={[
            {
              label: t("actions.edit"),
              onClick: () => {
                onEdit?.();
              },
            },
            {
              label: t("actions.delete"),
              onClick: () => {
                onDelete?.();
              },
            },
          ]}
        >
          <i className="fa-regular fa-ellipsis"></i>
        </ActionsDropdown>
      </Col>
    </Row>
  );
};

export default XchangeEpicCard;
