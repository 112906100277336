import { useState } from "react";
import { Button, Spinner, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useCompanyUpdate } from "@hooks/company/useCompanyUpdate";
import { usePeople } from "@hooks/person/usePeople";
import ICompany from "@interfaces/ICompany";
import IPerson from "@interfaces/IPerson";
import MembersTable from "@pages/Company/CompanyEditPage/MembersTable/MembersTable";
import CompanyMemberModal from "./CompanyMemberModal/CompanyMemberModal";

const CompanyMembers = ({ company }: { company: ICompany | undefined }) => {
  const { t } = useTranslation();

  const { isLoading: areMembersLoading, data: memberPeople } = usePeople(
    { contact_companies: company?.id },
    { enabled: !!company?.id }
  );

  const updateCompanyMutation = useCompanyUpdate();
  const removeMember = (personToRemoveFromTeam: IPerson) => {
    const newMemberPeopleList = [...(company?.company_contacts ?? [])];

    const index = newMemberPeopleList.findIndex(
      (person) => person.id === personToRemoveFromTeam.id
    );
    newMemberPeopleList.splice(index, 1);

    updateCompanyMutation.mutate({
      updatedCompany: {
        id: company?.id,
        company_contacts: newMemberPeopleList.map((p) => p.id!),
      },
    });
  };

  const [personToEdit, setPersonToEdit] = useState<IPerson>();
  const [showMemberModal, setShowMemberModal] = useState(false);

  return (
    <>
      <div className="p-3">
        <Stack direction="horizontal" gap={3} className="mb-5">
          <h5 className="mb-0">
            {t("companies.details.overview.people.title")}
          </h5>
          <Button
            className="ms-auto"
            size="sm"
            onClick={() => {
              setPersonToEdit(undefined);
              setShowMemberModal(true);
            }}
            disabled={!company}
          >
            <i className="fa-regular fa-plus me-2"></i>
            {t("companies.details.overview.people.add_member")}
          </Button>
        </Stack>
        {!!company?.id && areMembersLoading ? (
          <div className="d-flex justify-content-center mb-4">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <MembersTable
            members={(!!company?.id ? memberPeople ?? [] : []).map((p) => ({
              person: p,
              isPrimary: p.id === company?.company_key_contact,
            }))}
            onEdit={(person) => {
              setPersonToEdit(person);
              setShowMemberModal(true);
            }}
            onDelete={(person) => {
              removeMember(person);
            }}
          />
        )}
      </div>

      <CompanyMemberModal
        company={company}
        show={showMemberModal}
        onClose={() => {
          setShowMemberModal(false);
        }}
        person={personToEdit}
      />
    </>
  );
};

export default CompanyMembers;
