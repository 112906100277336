import { useAddPeopleToClientTeam } from "@hooks/client/useAddPeopleToClientTeam";
import { useCompanyUpdate } from "@hooks/company/useCompanyUpdate";
import { useAddPeopleToXpertTeam } from "@hooks/xpert/useAddPeopleToXpertTeam";
import ICompany from "@interfaces/ICompany";
import IPerson from "@interfaces/IPerson";

export const useAddPeopleToCompanyTeam = (company: ICompany | undefined) => {
  const updateCompanyMutation = useCompanyUpdate();

  const addPeopleToXpertTeamMutation = useAddPeopleToXpertTeam(
    company?.xpert_id
  );
  const addPeopleToClientTeamMutation = useAddPeopleToClientTeam(
    company?.client_id
  );

  const mutate = async (people: IPerson[]) => {
    if (!company) {
      return;
    }

    return Promise.all([
      updateCompanyMutation.mutate({
        updatedCompany: {
          id: company?.id,
          company_contacts: [
            ...(company?.company_contacts?.map((contact) => contact.id!) ?? []),
            ...people.map((person) => person.id!),
          ],
        },
      }),
      addPeopleToXpertTeamMutation.mutate(people),
      addPeopleToClientTeamMutation.mutate(people),
    ]);
  };

  return {
    mutate,
  };
};
