import { useMutation, useQueryClient } from "@tanstack/react-query";
import ReviewerService from "@services/ReviewerService";

export const useReviewerDelete = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ["deleteReviewer"],
    (id: number) => ReviewerService.deleteReviewer(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["reviewers"]);
      },
    }
  );
};
