import { useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CustomSelect from "@components/CustomSelect/CustomSelect";
import Page from "@components/Page/Page";
import SearchFilter from "@components/SearchFilter/SearchFilter";
import { IGetCatalogTermsParams } from "@services/CatalogTermService";
import "./TermListPage.scss";
import { useSubDomains } from "@hooks/subdomain/useSubDomains";
import { useXperts } from "@hooks/xpert/useXperts";
import { useCatalogTerms } from "@hooks/term/useCatalogTerms";
import { useDebounce } from "@hooks/useDebounce";
import TermCard from "@components/TermCard/TermCard";
import { Virtuoso } from "react-virtuoso";

interface TermListFilters {
  xperts: number[];
  sub_domains: number[];
  search: string;
}

const TermListPage = () => {
  const { t } = useTranslation();

  const formMethods = useForm<TermListFilters>();
  const { watch, control } = formMethods;
  const { data: subDomains } = useSubDomains();
  const { data: xperts } = useXperts();

  const [getTermsParams, setGetTermsParams] = useState<IGetCatalogTermsParams>({
    ordering: "title",
  });
  const {
    debouncedValue: debouncedGetTermsParams,
    isDebouncing: isGetTermsParamsDebouncing,
  } = useDebounce(getTermsParams, 400);

  const {
    data: catalogTermInfiniteData,
    isLoading: areCatalogTermsLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useCatalogTerms(debouncedGetTermsParams);
  const catalogTerms = catalogTermInfiniteData
    ? catalogTermInfiniteData.pages.flatMap((p) => p.results)
    : [];

  useEffect(() => {
    const subscription = watch((data) => {
      setGetTermsParams({
        ...getTermsParams,
        search: data.search,
        sub_domain__in:
          data.sub_domains && data.sub_domains.length > 0
            ? data.sub_domains.join(",")
            : undefined,
        xpert_terms__xpert_involved__in:
          data.xperts && data.xperts.length > 0
            ? data.xperts.join(",")
            : undefined,
      });
    });

    return () => subscription.unsubscribe();
  }, [getTermsParams, watch]);

  return (
    <Page title={t("term.listing.title")}>
      <Card>
        <Card.Header className="Term-List-Page__Header">
          <FormProvider {...formMethods}>
            <Row>
              <Col lg="2">
                <Link to="/terms/create">
                  <Button className="fw-bold">
                    <i className="fas fa-plus me-2"></i>
                    {t("term.listing.new")}
                  </Button>
                </Link>
              </Col>
              <Col
                lg="10"
                className="Term-List-Page__Header__Filters d-flex align-items-center justify-content-end"
              >
                <CustomSelect
                  name="xperts"
                  control={control}
                  className="ps-3"
                  placeholder={t("term.listing.filters.xperts")}
                  isClearable
                  options={(xperts || []).map((xpert) => ({
                    value: xpert.id,
                    label: xpert.name,
                  }))}
                  isMulti
                />
                <CustomSelect
                  name="sub_domains"
                  control={control}
                  className="ps-3 me-4"
                  placeholder={t("term.listing.filters.sub_domains")}
                  isClearable
                  options={(subDomains || []).map((subDomain) => ({
                    value: subDomain.id,
                    label: subDomain.name,
                  }))}
                  isMulti
                />
                <SearchFilter
                  name="search"
                  className="Term-List-Page__Header__Filters__Search ps-4"
                  placeholder={t("filters.search")}
                  variant="primary"
                />
              </Col>
            </Row>
          </FormProvider>
        </Card.Header>
        <Card.Body>
          {areCatalogTermsLoading || isGetTermsParamsDebouncing ? (
            <div className="text-center mt-4 mb-4">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <Virtuoso
              className="Term-List-Page__List"
              data={catalogTerms}
              atBottomThreshold={420}
              atBottomStateChange={(isAtBottom) => {
                if (isAtBottom && hasNextPage) {
                  fetchNextPage();
                }
              }}
              components={{
                Footer: () => {
                  return (
                    <div className="d-flex justify-content-center pt-4 pb-4">
                      {isFetchingNextPage && (
                        <Spinner
                          animation="border"
                          variant="primary"
                          size="sm"
                        />
                      )}
                    </div>
                  );
                },
              }}
              itemContent={(index, catalogTerm) => (
                <div key={catalogTerm.id}>
                  {index !== 0 && <hr />}
                  <TermCard term={catalogTerm} />
                </div>
              )}
            />
          )}
        </Card.Body>
      </Card>
    </Page>
  );
};

export default TermListPage;
