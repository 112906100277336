import { useState } from "react";
import { Button, Spinner, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useClientTeamMemberDelete } from "@hooks/client/useClientTeamMemberDelete";
import { useClientTeamMembers } from "@hooks/client/useClientTeamMembers";
import IClient from "@interfaces/IClient";
import IPerson from "@interfaces/IPerson";
import MembersTable from "@pages/Company/CompanyEditPage/MembersTable/MembersTable";
import ClientMemberModal from "./ClientMemberModal";

const ClientMembers = ({ client }: { client: IClient }) => {
  const { t } = useTranslation();

  const { data: clientTeamMembers, isLoading: areMembersLoading } =
    useClientTeamMembers(client.id!, {
      refetchOnWindowFocus: false,
    });

  const deleteClientTeamMemberMutation = useClientTeamMemberDelete();
  const removeMember = (memberToRemove: IPerson) => {
    const clientMemberToRemove = clientTeamMembers?.find(
      (member) => member.person!.id === memberToRemove.id
    );

    deleteClientTeamMemberMutation.mutate({
      clientId: client.id!,
      clientTeamMemberId: clientMemberToRemove?.id!,
    });
  };

  const [memberToEdit, setMemberToEdit] = useState<IPerson>();
  const [showMemberModal, setShowMemberModal] = useState(false);

  return (
    <>
      <div className="p-3">
        <Stack direction="horizontal" gap={3} className="mb-5">
          <h5 className="mb-0">
            {" "}
            {t("companies.details.client.members.subtitle")}
          </h5>
          <Button
            className="ms-auto"
            size="sm"
            onClick={() => {
              setMemberToEdit(undefined);
              setShowMemberModal(true);
            }}
          >
            <i className="fa-regular fa-plus me-2"></i>
            {t("companies.details.client.members.add_member")}
          </Button>
        </Stack>
        {areMembersLoading ? (
          <div className="d-flex justify-content-center mb-4">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <MembersTable
            members={(clientTeamMembers || []).map((m) => ({
              person: {
                ...m.person!,
                rate: m.rate,
                currency: m.currency,
                roles: m.roles,
              },
              isPrimary: m.person!.id === client.primary_contact,
              isFinance: m.person!.id === client.finance_contact,
            }))}
            onEdit={(member) => {
              setMemberToEdit(member);
              setShowMemberModal(true);
            }}
            onDelete={(member) => {
              removeMember(member);
            }}
          />
        )}
        <ClientMemberModal
          client={client}
          show={showMemberModal}
          onClose={() => {
            setShowMemberModal(false);
          }}
          memberToEdit={
            memberToEdit && {
              person: memberToEdit,
              clientMemberId: clientTeamMembers?.find(
                (member) => member.person!.id === memberToEdit?.id
              )?.id,
            }
          }
        />
      </div>
    </>
  );
};

export default ClientMembers;
