import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Page from "@components/Page/Page";
import PageTab from "@components/PageTab/PageTab";
import { useClients } from "@hooks/client/useClients";
import { useCompany } from "@hooks/company/useCompany";
import { useXperts } from "@hooks/xpert/useXperts";
import CompanyGeneralOverview from "./CompanyGeneralOverview/CompanyGeneralOverview";
import { useNavigate } from "react-router-dom";
import CompanyXpertDetails from "./CompanyXpertDetails/CompanyXpertDetails";
import CompanyOverview from "./CompanyOverview/CompanyOverview";
import NotFoundPage from "@pages/NotFoundPage";
import CompanyClientDetails from "./CompanyClientDetails/CompanyClientDetails";
import CompanyXpertBilling from "@pages/Company/CompanyEditPage/CompanyXpertBilling/CompanyXpertBilling";
import CompanyXpertMSA from "@pages/Company/CompanyEditPage/CompanyXpertMSA/CompanyXpertMSA";
import CompanyClientBilling from "@pages/Company/CompanyEditPage/CompanyClientBilling/CompanyClientBilling";
import CompanyClientMSA from "@pages/Company/CompanyEditPage/CompanyClientMSA/CompanyClientMSA";
import { formatDateToString } from "@helpers/date-utils";
import CompanyProfile from "@pages/Company/CompanyEditPage/CompanyProfile/CompanyProfile";

const CompanyEditPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id: companyId, tab: activeTab } = useParams<{
    id: string;
    tab: string;
  }>();

  const createMode = companyId === "create";

  useEffect(() => {
    if (!activeTab) {
      navigate(`/companies/${companyId}/overview`, { replace: true });
    }
  }, [companyId, activeTab, navigate]);

  const {
    isLoading: isCompanyLoading,
    isSuccess: companyLoaded,
    isError: companyError,
    data: company,
  } = useCompany(parseInt(companyId!), {
    enabled: !!companyId && !createMode,
    retry: false,
  });

  const { data: clients } = useClients(
    { company__id: company?.id },
    {
      enabled: companyLoaded,
    }
  );

  const { data: xperts } = useXperts(
    { company__id: company?.id },
    {
      enabled: companyLoaded,
    }
  );

  return companyError ? (
    <NotFoundPage />
  ) : (
    <>
      <Page
        title={createMode ? t("companies.details.new") : undefined}
        subTitle={`${t("last_updated")}: ${
          company?.updated_at
            ? formatDateToString(new Date(company?.updated_at))
            : "--"
        }`}
        browserTabTitle={
          createMode ? t("companies.details.new") : company?.company_name
        }
        defaultTabKey={activeTab}
        tabs={[
          {
            tabKey: "overview",
            label: t("nav.company.overview"),
          },
          {
            tabKey: "profile",
            label: t("nav.company.profile"),
          },
          {
            tabKey: "xpert",
            label: t("nav.company.xpert.overview"),
          },
          {
            tabKey: "xpert-billing",
            label: t("nav.company.xpert.billing"),
          },
          {
            tabKey: "xpert-msa",
            label: t("nav.company.xpert.msa"),
          },
          {
            tabKey: "client",
            label: t("nav.company.client.overview"),
          },
          {
            tabKey: "client-billing",
            label: t("nav.company.client.billing"),
          },
          {
            tabKey: "client-msa",
            label: t("nav.company.client.msa"),
          },
        ]}
        sideBarContent={
          <CompanyOverview
            company={company}
            tab={activeTab}
            createMode={createMode}
          />
        }
      >
        {!createMode && isCompanyLoading ? (
          <>
            <i className="fa-regular fa-spinner fa-spin me-2" />
            {t("loading")}
          </>
        ) : (
          <>
            {activeTab === "overview" && (
              <PageTab tabKey={"overview"}>
                <CompanyGeneralOverview
                  company={company}
                  clientId={!createMode ? clients?.[0]?.id : undefined}
                  xpertId={!createMode ? xperts?.[0]?.id : undefined}
                />
              </PageTab>
            )}
            {activeTab === "profile" && (
              <PageTab tabKey={"profile"}>
                <CompanyProfile company={company} />
              </PageTab>
            )}

            {activeTab === "xpert" && (
              <PageTab tabKey={"xpert"}>
                <CompanyXpertDetails
                  clientId={!createMode ? clients?.[0]?.id : undefined}
                  xpert={!createMode ? xperts?.[0] : undefined}
                  company={company}
                />
              </PageTab>
            )}
            {activeTab === "xpert-billing" && (
              <PageTab tabKey={"xpert-billing"}>
                <CompanyXpertBilling
                  xpert={!createMode ? xperts?.[0] : undefined}
                />
              </PageTab>
            )}
            {activeTab === "xpert-msa" && (
              <PageTab tabKey={"xpert-msa"}>
                <CompanyXpertMSA
                  xpert={!createMode ? xperts?.[0] : undefined}
                />
              </PageTab>
            )}

            {activeTab === "client" && (
              <PageTab tabKey={"client"}>
                <CompanyClientDetails
                  clientId={!createMode ? clients?.[0]?.id : undefined}
                  xpertId={!createMode ? xperts?.[0]?.id : undefined}
                  company={company}
                />
              </PageTab>
            )}
            {activeTab === "client-billing" && (
              <PageTab tabKey={"client-billing"}>
                <CompanyClientBilling
                  client={!createMode ? clients?.[0] : undefined}
                />
              </PageTab>
            )}
            {activeTab === "client-msa" && (
              <PageTab tabKey={"client-msa"}>
                <CompanyClientMSA
                  client={!createMode ? clients?.[0] : undefined}
                />
              </PageTab>
            )}
          </>
        )}
      </Page>
    </>
  );
};

export default CompanyEditPage;
