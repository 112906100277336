import { useMutation, useQueryClient } from "@tanstack/react-query";
import CatalogTermService from "@services/CatalogTermService";

export const useCatalogTermAddProduct = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      catalogTermId,
      productId,
    }: {
      catalogTermId: number;
      productId: number;
    }) => CatalogTermService.addProductToCatalogTerm(catalogTermId, productId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["terms"]);
      },
    }
  );
};
