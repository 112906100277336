import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ICompanyCreateUpdate } from "@interfaces/ICompany";
import CompanyService from "@services/CompanyService";

interface IMutationVars {
  updatedCompany: ICompanyCreateUpdate;
}

export const useCompanyUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ["updateCompany"],
    ({ updatedCompany }: IMutationVars) =>
      CompanyService.updateCompany(updatedCompany),
    {
      onSuccess: (data, { updatedCompany }) => {
        queryClient.invalidateQueries(["companies"]);
        queryClient.invalidateQueries(["people"]);
      },
    }
  );
};
