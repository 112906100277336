import { useXpertTeamMemberCreate } from "@hooks/xpert/useXpertTeamMemberCreate";
import IPerson from "@interfaces/IPerson";

export const useAddPeopleToXpertTeam = (xpertId: number | undefined) => {
  const createXpertTeamMemberMutation = useXpertTeamMemberCreate();

  const mutate = async (people: IPerson[]) => {
    if (!xpertId) {
      return;
    }

    const promises = people.map((person) =>
      createXpertTeamMemberMutation.mutate({
        xpertId: xpertId,
        xpertTeamMember: {
          person: person.id,
          xpert: xpertId,
          rate: person.rate,
          currency: person.currency,
          roles: person.roles,
        },
      })
    );

    return Promise.all(promises);
  };

  return {
    mutate,
  };
};
