import BillFilters from "@components/BillFilters/BillFilters";
import BillTable from "@components/BillTable/BillTable";
import { useDebounce } from "@hooks/useDebounce";
import IClient from "@interfaces/IClient";
import { IGetBillsParams } from "@services/BillService";
import { useState } from "react";

const ClientBills = ({ client }: { client?: IClient }) => {
  const [getBillsParams, setGetBillsParams] = useState<IGetBillsParams>({});
  const [getBillsSearchParam, setBillsSearchParam] = useState<string>();
  const {
    debouncedValue: debouncedGetBillsSearchParam,
    isDebouncing: isGetBillsSearchParamDebouncing,
  } = useDebounce(getBillsSearchParam, 500);

  const handleFilterChange = (filters: IGetBillsParams) => {
    setGetBillsParams({
      ...filters,
      search: undefined,
    });
    setBillsSearchParam(filters.search);
  };

  return (
    <div className="ps-4 pe-4">
      <BillFilters handleFilterChange={handleFilterChange} className="pb-6" />

      <BillTable
        client={client}
        areExtraFixedGetBillsParamsDebouncing={isGetBillsSearchParamDebouncing}
        extraFixedGetBillsParams={{
          ...getBillsParams,
          search: debouncedGetBillsSearchParam,
        }}
        expandBillGroupsByDefault={false}
        maxExpandedGroupCount={1}
      />
    </div>
  );
};

export default ClientBills;
