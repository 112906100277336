import IParty, { IPartyWrite } from "@interfaces/IParty";
import Service from "@services/Service";

export interface IGetPartiesParams {
  contract?: number;
  contract__in?: string;
  subject?: number;
  main?: boolean;
}

export default class PartyService extends Service {
  public static async getParties(params: IGetPartiesParams = {}) {
    const response = await this.axios.get<IParty[]>("/parties", {
      params,
    });
    return response.data;
  }

  public static async createParty(party: IPartyWrite) {
    const response = await this.axios.post<IParty>("/parties", party);
    return response.data;
  }
}
