import { Form, Stack } from "react-bootstrap";
import CustomSelect from "@components/CustomSelect/CustomSelect";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "./TermFilters.scss";
import SearchFilter from "@components/SearchFilter/SearchFilter";
import { useState } from "react";
import { IGetXpertsParams } from "@services/XpertService";
import { useDebounce } from "@hooks/useDebounce";
import { useXperts } from "@hooks/xpert/useXperts";
import { useSubDomains } from "@hooks/subdomain/useSubDomains";
import { IGetCatalogTermsParams } from "@services/CatalogTermService";

interface ICatalogTermFilters {
  filters: any[];
  ordering: "title" | "-title" | "date_added" | "-date_added";
  search: string;
}

const TermFilters = ({
  handleFilterChange,
  className,
  disabled = false,
  extraFixedGetXpertsParams,
}: {
  handleFilterChange: (data: IGetCatalogTermsParams) => void;
  className?: string;
  disabled?: boolean;
  extraFixedGetXpertsParams?: IGetXpertsParams;
}) => {
  const { t } = useTranslation();

  const formMethods = useForm<ICatalogTermFilters>();
  const { control, handleSubmit } = formMethods;

  const fixedGetXpertsParams = {
    ...extraFixedGetXpertsParams,
    xpert_terms__isnull: false,
  };

  const [getXpertsParams, setGetXpertsParams] = useState<IGetXpertsParams>({});
  const { debouncedValue: debouncedGetXpertsParams } = useDebounce(
    getXpertsParams,
    400
  );
  const { isLoading: areXpertsLoading, data: xperts } = useXperts({
    ...fixedGetXpertsParams,
    ...debouncedGetXpertsParams,
  });

  const { data: subDomains } = useSubDomains();

  const handleFormChange = (data: ICatalogTermFilters) => {
    const filters: IGetCatalogTermsParams = {
      ordering: data.ordering,
      search: data.search,
    };

    if (data.filters) {
      filters.xpert_terms__xpert_involved__in = data.filters
        .filter((filter) => filter.xpert)
        .map((filter) => filter.xpert)
        .join(",");
    }

    if (data.filters) {
      filters.sub_domain__in = data.filters
        .filter((filter) => filter.sub_domain)
        .map((filter) => filter.sub_domain)
        .join(",");
    }
    if (data.filters) {
      const costRangeCategories = data.filters
        .filter((filter) => filter.costRangeCategory)
        .map((filter) => filter.costRangeCategory);

      const ranges: string[] = [];

      if (costRangeCategories.includes(1)) {
        ranges.push("0-999");
      }
      if (costRangeCategories.includes(2)) {
        ranges.push("1000-5000");
      }
      if (costRangeCategories.includes(3)) {
        ranges.push("5000-9999");
      }
      if (costRangeCategories.includes(4)) {
        filters.xpert_terms__cost_per_sprint__gte = 10000;
      }

      if (ranges.length > 0) {
        filters.cost_ranges = ranges.join(",");
      }
    }

    Object.keys(filters).forEach((key) => {
      if (
        filters[key as keyof IGetCatalogTermsParams] === undefined ||
        filters[key as keyof IGetCatalogTermsParams] === null ||
        filters[key as keyof IGetCatalogTermsParams] === ""
      ) {
        delete filters[key as keyof IGetCatalogTermsParams];
      }
    });

    handleFilterChange(filters);
  };

  return (
    <FormProvider {...formMethods}>
      <Form onChange={handleSubmit(handleFormChange)}>
        <Stack
          direction="horizontal"
          className={`Term-Filters ${className}`}
          gap={4}
        >
          <CustomSelect
            control={control}
            name="filters"
            options={[
              {
                label: t("companies.details.xpert.terms.filters.xpert"),
                value: (xperts ?? []).map((xpert) => ({
                  label: xpert.name,
                  value: { xpert: xpert.id },
                })),
                defaultSearchValue: getXpertsParams.search,
                onSearch: (search: string) => {
                  if (search.length > 0) {
                    setGetXpertsParams({
                      search,
                    });
                  } else {
                    setGetXpertsParams({
                      search: undefined,
                    });
                  }
                },
                isSearchLoading: areXpertsLoading,
              },
              {
                label: t("companies.details.xpert.terms.filters.cost"),
                value: [
                  {
                    label: "<$1,000",
                    value: { costRangeCategory: 1 },
                  },
                  {
                    label: "$1,000 - $5,000",
                    value: { costRangeCategory: 2 },
                  },
                  {
                    label: "$5,000 - $10,000",
                    value: { costRangeCategory: 3 },
                  },
                  {
                    label: "$10,000+",
                    value: { costRangeCategory: 4 },
                  },
                ],
              },
              {
                label: t("companies.details.xpert.terms.filters.sub_domain"),
                value: (subDomains ?? []).map((subDomain) => ({
                  label: subDomain.name,
                  value: { sub_domain: subDomain.id },
                })),
              },
            ]}
            onAfterChange={() => {
              handleSubmit(handleFormChange)();
            }}
            placeholder={t("companies.details.xpert.terms.filters.placeholder")}
            isMulti
            isDisabled={disabled}
          />
          <CustomSelect
            control={control}
            name="ordering"
            options={[
              {
                label: t("companies.details.xpert.terms.ordering.title.name"),
                value: [
                  {
                    label: t(
                      "companies.details.xpert.terms.ordering.title.asc"
                    ),
                    value: "title",
                  },
                  {
                    label: t(
                      "companies.details.xpert.terms.ordering.title.desc"
                    ),
                    value: "-title",
                  },
                ],
              },
              {
                label: t(
                  "companies.details.xpert.terms.ordering.date_added.name"
                ),
                value: [
                  {
                    label: t(
                      "companies.details.xpert.terms.ordering.date_added.desc"
                    ),
                    value: "-date_added",
                  },
                  {
                    label: t(
                      "companies.details.xpert.terms.ordering.date_added.asc"
                    ),
                    value: "date_added",
                  },
                ],
              },
            ]}
            onAfterChange={() => {
              handleSubmit(handleFormChange)();
            }}
            placeholder={t(
              "companies.details.xpert.terms.ordering.placeholder"
            )}
            isClearable
            isDisabled={disabled}
          />
          <SearchFilter
            name="search"
            placeholder={t("filters.search")}
            disabled={disabled}
          />
        </Stack>
      </Form>
    </FormProvider>
  );
};

export default TermFilters;
