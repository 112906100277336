import { useMutation, useQueryClient } from "@tanstack/react-query";
import IXpert from "@interfaces/IXpert";
import XpertService from "@services/XpertService";
import { parseModelHyperLink } from "@helpers/model-utils";

interface IMutationVars {
  xpert: IXpert;
}

export const useXpertCreate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ xpert }: IMutationVars) => XpertService.createXpert(xpert),
    {
      onSuccess: (_, variables) => {
        if (variables.xpert.company_person) {
          const companyPersoInfos = parseModelHyperLink(
            variables.xpert.company_person
          );

          if (companyPersoInfos) {
            queryClient.invalidateQueries([
              companyPersoInfos.model,
              { id: companyPersoInfos.id },
            ]);
          }
        }

        queryClient.invalidateQueries(["xperts"]);
      },
    }
  );
};
