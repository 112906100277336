import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { isEqual, set } from "lodash";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import { isFieldRequired } from "@helpers/joi-utils";
import { useSubDomains } from "@hooks/subdomain/useSubDomains";
import ICatalogTerm from "@interfaces/terms/ICatalogTerm";
import { useCatalogTermCreate } from "@hooks/term/useCatalogTermCreate";
import { useCatalogTermUpdate } from "@hooks/term/useCatalogTermUpdate";
import AxiosErrorAlert from "@components/AxiosErrorAlert/AxiosErrorAlert";
import CustomSelect from "@components/CustomSelect/CustomSelect";

export interface TermDetailsFormValues {
  title?: string;
  description?: string;
  sub_domain?: number;
}

export const termDetailsValidationSchema = Joi.object({
  title: Joi.string().required(),
  description: Joi.string().allow("").allow(null),
  sub_domain: Joi.number(),
});

const TermDetailsForm = ({ term }: { term: ICatalogTerm }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const formMethods = useForm<TermDetailsFormValues>({
    mode: "onChange",
    resolver: joiResolver(termDetailsValidationSchema),
    defaultValues: {
      title: term?.title,
      description: term?.description,
      sub_domain: term?.sub_domain?.id,
    },
  });
  const {
    control,
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const { data: subDomains } = useSubDomains();

  const [pastOnBlurData, setPastOnBlurData] = useState<TermDetailsFormValues>(
    getValues()
  );

  useEffect(() => {
    formMethods.reset({
      title: term?.title,
      description: term?.description,
      sub_domain: term?.sub_domain?.id,
    });
    setPastOnBlurData(formMethods.getValues());
  }, [term, formMethods]);

  const createCatalogTermMutation = useCatalogTermCreate();
  const updateCatalogTermMutation = useCatalogTermUpdate();

  const formSubmit = (data: TermDetailsFormValues) => {
    if (!term) {
      createCatalogTermMutation.mutate(
        {
          title: data.title,
        },
        {
          onSuccess: (response) => {
            const newCatalogTerm = response.data;
            navigate(`/terms/${newCatalogTerm.id}`, {
              replace: true,
            });
          },
        }
      );
    } else {
      const changedValues = Object.keys(data).reduce((acc, key) => {
        if (
          !isEqual(
            data[key as keyof TermDetailsFormValues],
            pastOnBlurData[key as keyof TermDetailsFormValues]
          )
        ) {
          set(acc, key, data[key as keyof TermDetailsFormValues]);
        }
        return acc;
      }, {} as TermDetailsFormValues);

      if (Object.keys(changedValues).length > 0) {
        updateCatalogTermMutation.mutate({
          id: term.id!,
          term: { ...changedValues },
        });
      }
    }

    setPastOnBlurData(data);
  };

  return (
    <FormProvider {...formMethods}>
      <Form className="ps-4 pe-4 pb-4">
        <h5 className="mb-5">{t("term.details.details.subtitle")}</h5>
        <Form.Group className="mb-3">
          <Form.Label
            aria-required={isFieldRequired(
              termDetailsValidationSchema,
              "title"
            )}
          >
            {t("term.details.details.labels.title")}
          </Form.Label>
          <Form.Control
            type="text"
            {...register("title")}
            isInvalid={!!errors.title}
            onBlur={handleSubmit(formSubmit)}
          />
          <FieldErrorMessage
            field={errors.title}
            fieldName={t("term.details.details.labels.title")}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label
            aria-required={isFieldRequired(
              termDetailsValidationSchema,
              "description"
            )}
          >
            {t("term.details.details.labels.description")}
          </Form.Label>
          <Controller
            control={control}
            name="description"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <>
                <ReactQuill
                  className={`${error && "ql-invalid"} ${
                    !term && "ql-disabled"
                  }`}
                  value={value}
                  onChange={(value) => {
                    if (value.replace(/(<([^>]+)>)/gi, "")) {
                      onChange(value);
                    } else {
                      onChange("");
                    }
                  }}
                  readOnly={!term}
                  onBlur={(_, source) => {
                    if (source === "silent") {
                      return;
                    }
                    handleSubmit(formSubmit)();
                  }}
                />
                <FieldErrorMessage
                  field={error}
                  fieldName={t("term.details.details.labels.description")}
                />
              </>
            )}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label
            aria-required={isFieldRequired(
              termDetailsValidationSchema,
              "sub_domain"
            )}
          >
            {t("term.details.details.labels.sub_domain")}
          </Form.Label>
          <CustomSelect
            control={control}
            name="sub_domain"
            options={subDomains?.map((subDomain) => ({
              value: subDomain.id,
              label: subDomain.name,
            }))}
            isDisabled={!term}
            onAfterChange={() => {
              handleSubmit(formSubmit)();
            }}
          />
        </Form.Group>
      </Form>
      <AxiosErrorAlert
        response={
          createCatalogTermMutation.error || updateCatalogTermMutation.error
        }
      />
    </FormProvider>
  );
};

export default TermDetailsForm;
