import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import IUser from "@interfaces/IUser";
import UserService from "@services/UserService";

export const useUser = (
  id: number,
  options?: Pick<UseQueryOptions<IUser, Error>, "enabled" | "onSuccess"> & {
    refetchOnWindowFocus?: boolean;
  }
) => useQuery(["users", { id }], () => UserService.getUser(id), options);
