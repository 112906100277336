import IXpert, {
  IXpertTeamMember,
  IXpertTeamMemberCreateUpdate,
} from "@interfaces/IXpert";
import Service from "@services/Service";

export interface IGetXpertsParams {
  id__in?: string;
  id__not__in?: string;
  person__id?: number;
  company__id?: number;
  status__in?: string;
  xpert_terms__isnull?: boolean;
  xpert_terms__catalog_term__not__in?: string;
  search?: string;
}

export interface IGetXpertTeamMembersParams {
  person__not__in?: string;
}

export default class XpertService extends Service {
  public static async getXperts(
    params?: IGetXpertsParams,
    signal?: AbortSignal
  ) {
    const response = await this.axios.get<IXpert[]>("/xperts", {
      params,
      signal,
    });
    return response.data;
  }

  public static async getXpert(id: number) {
    const response = await this.axios.get<IXpert>(`/xperts/${id}`);
    return response.data;
  }

  public static async createXpert(xpert: IXpert) {
    const response = await this.axios.post<IXpert>("/xperts", xpert);
    return response.data;
  }

  public static async updateXpert(xpert: IXpert) {
    const response = await this.axios.patch<IXpert>(
      `/xperts/${xpert.id}`,
      xpert
    );
    return response.data;
  }

  public static async getXpertTeamMembers(
    xpertId: number,
    params?: IGetXpertTeamMembersParams
  ) {
    const response = await this.axios.get<IXpert>(
      `/xperts/${xpertId}/team-members`,
      {
        params,
      }
    );
    return response.data;
  }

  public static async createXpertTeamMember(
    xpertId: number,
    xpertTeamMember: IXpertTeamMemberCreateUpdate
  ) {
    const response = await this.axios.post<IXpertTeamMember>(
      `/xperts/${xpertId}/team-members`,
      xpertTeamMember
    );
    return response.data;
  }

  public static async updateXpertTeamMember(
    xpertId: number,
    xpertTeamMember: IXpertTeamMemberCreateUpdate
  ) {
    const response = await this.axios.patch<IXpertTeamMember>(
      `/xperts/${xpertId}/team-members/${xpertTeamMember.id}`,
      xpertTeamMember
    );
    return response.data;
  }

  public static async deleteXpertTeamMember(
    xpertId: number,
    xpertTeamMemberId: number
  ) {
    const response = await this.axios.delete<{}>(
      `/xperts/${xpertId}/team-members/${xpertTeamMemberId}`
    );
    return response.data;
  }
}
