import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ICatalogProductCreateUpdate } from "@interfaces/products/ICatalogProduct";
import CatalogProductService from "@services/CatalogProductService";

interface IMutationVars {
  id: number;
  catalogProduct: ICatalogProductCreateUpdate;
}

export const useCatalogProductUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, catalogProduct }: IMutationVars) =>
      CatalogProductService.updateCatalogProduct(id, catalogProduct),
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries(["catalog-products", { id }]);
      },
    }
  );
};
