import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import "./ToggleTextInput.scss";

const ToggleTextInput = ({
  name,
  switchLabel,
  inputLabel,
  onSwitchOff,
}: {
  name: string;
  switchLabel: string;
  inputLabel: string;
  onSwitchOff?: () => void;
}) => {
  const {
    setValue,
    getValues,
    register,

    formState: { errors },
  } = useFormContext();

  const [showInput, setShowInput] = useState<boolean>(!!getValues(name));
  const toggleInput = () => {
    if (showInput) {
      setValue(name, "");
      onSwitchOff && onSwitchOff();
    }
    setShowInput(!showInput);
  };

  return (
    <div className="Toggle-Text-Input">
      <div className="Toggle-Text-Input__Switch">
        <Form.Check
          type="switch"
          className="me-3"
          checked={showInput}
          readOnly
          onClick={toggleInput}
        />
        {switchLabel}
      </div>

      {showInput && (
        <div className="Toggle-Text-Input__Input">
          <Form.Label>{inputLabel}</Form.Label>
          <Form.Control
            type="text"
            {...register(name)}
            isInvalid={!!errors[name]}
          />
          <FieldErrorMessage field={errors[name]} fieldName={inputLabel} />
        </div>
      )}
    </div>
  );
};

export default ToggleTextInput;
