import { useQuery } from "@tanstack/react-query";
import IRole from "@interfaces/IRole";
import RoleService, { IGetRolesParams } from "@services/RoleService";

export const useRoles = (
  params?: IGetRolesParams,
  options?: {
    enabled?: boolean;
    onSuccess?: (data: IRole[]) => void;
  }
) =>
  useQuery(["roles", { params }], () => RoleService.getRoles(params), {
    enabled: options?.enabled,
    onSuccess: options?.onSuccess,
  });
