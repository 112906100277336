import { IContractGenerationSubject } from "@hooks/contract/useContractGeneration";
import { ProtectedContractTypeCodes } from "@interfaces/IContractType";
import IXchange from "@interfaces/IXchange";
import IXchangeTerm from "@interfaces/terms/IXchangeTerm";
import { IGetXchangeBillsSummary } from "@services/XchangeService";
import IXchangeProduct from "@interfaces/products/IXchangeProduct";

export const getXchangeContractSubjects = (
  xchange?: IXchange
): IContractGenerationSubject[] => {
  const subjectIds = xchange ? getXchangeXpertIds(xchange) : [];

  const subjects = [
    ...(subjectIds || []).map((subjectId) => ({
      subjectId: subjectId,
      contractType: ProtectedContractTypeCodes.XPERT_SOW,
      filters: {
        xchange: xchange?.id!,
      },
      folderId: xchange?.folder_id,
    })),
  ];

  if (xchange?.client?.id) {
    subjects.push({
      subjectId: xchange?.client?.id!,
      contractType: ProtectedContractTypeCodes.CLIENT_SOW,
      filters: {
        xchange: xchange?.id!,
      },
      folderId: xchange?.folder_id,
    });
  }

  return subjects;
};

export const getXchangeDurationInweeks = (xchange: IXchange) => {
  if (!xchange.goal_end_date || !xchange.start_date) {
    return 0;
  }

  return Math.ceil(
    (new Date(xchange.goal_end_date).getTime() -
      new Date(xchange.start_date).getTime()) /
      (1000 * 60 * 60 * 24 * 7)
  );
};

export const doesXchangeHaveXperts = (xchange: IXchange) => {
  return getXchangeTerms(xchange).length > 0;
};

export const getXchangeXpertIds = (xchange: IXchange): number[] => {
  const xperts = new Set<number>();

  xchange.xchange_terms?.forEach((term) => {
    if (term.xpert_involved) {
      xperts.add(term.xpert_involved);
    }
  });

  xchange.xchange_products?.forEach((product) => {
    product.terms?.forEach((term) => {
      if (term.xpert_involved) {
        xperts.add(term.xpert_involved);
      }
    });
  });

  return Array.from(xperts);
};

export const getXchangeXpertStats = (
  xchange: IXchange,
  xpertId: number
): {
  xpertId: number;
  termInvolvementCount: number;
  productInvolvementCount: number;
  totalInvolvementCount: number;
} => {
  const termInvolvementCount = xchange.xchange_terms!.reduce(
    (count, term) => count + (term.xpert_involved === xpertId ? 1 : 0),
    0
  );

  const productInvolvementCount = xchange.xchange_products!.reduce(
    (count, product) =>
      count +
      ((product.terms ?? []).some((term) => term.xpert_involved === xpertId)
        ? 1
        : 0),
    0
  );

  return {
    xpertId,
    termInvolvementCount,
    productInvolvementCount,
    totalInvolvementCount: termInvolvementCount + productInvolvementCount,
  };
};

export const getXchangeCurrencies = (xchange: IXchange) => {
  const terms = getXchangeTerms(xchange);
  const currencies = terms.map((term) => term.currency!);
  return Array.from(new Set(currencies));
};

export const getXchangeCurrenciesString = (xchange: IXchange) => {
  const currencies = getXchangeCurrencies(xchange);
  return currencies.join(", ");
};

export const getXchangeTerms = (xchange: IXchange) => {
  const terms = [
    ...(xchange.xchange_terms ?? []),
    ...(xchange.xchange_products ?? []).reduce(
      (terms, product) => [...terms, ...(product.terms ?? [])],
      [] as IXchangeTerm[]
    ),
  ];

  return terms.sort((a, b) => {
    if (a.start_date && b.start_date) {
      return (
        new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
      );
    }
    return 0;
  });
};

export const getXpertXchangeSubDomains = (
  xchange: IXchange,
  xpertId: number
) => {
  const terms = getXpertXchangeTerms(xchange, xpertId);

  const uniqueDomains = terms.filter(
    (term, index, self) =>
      index === self.findIndex((t) => t.sub_domain?.id === term.sub_domain?.id)
  );

  return uniqueDomains.map((term) => term.sub_domain?.name).join(", ");
};

export const getXpertXchangeTerms = (xchange: IXchange, xpertId: number) => {
  return getXchangeTerms(xchange).filter(
    (term) => term.xpert_involved === xpertId
  );
};

export const getXpertXchangeTermTitles = (
  xchange: IXchange,
  xpertId: number
) => {
  const allXpertTerms = getXpertXchangeTerms(xchange, xpertId);

  const uniqueTerms = allXpertTerms.filter(
    (term, index, self) =>
      index === self.findIndex((t) => t.title === term.title)
  );

  return uniqueTerms.map((term) => term.title).join(", ");
};

export const getXpertXchangeStartDate = (
  xchange: IXchange,
  xpertId: number
): Date | undefined => {
  const terms = getXpertXchangeTerms(xchange, xpertId);

  const xpertStartDate = terms[0]?.start_date
    ? new Date(terms[0].start_date)
    : undefined;
  if (xpertStartDate) {
    xpertStartDate.setHours(0, 0, 0, 0);
  }

  return xpertStartDate;
};

export const getXpertXchangeDurationInWeeks = (
  xchange: IXchange,
  xpertId: number
): number => {
  const terms = getXpertXchangeTerms(xchange, xpertId);
  const xpertStartDate = getXpertXchangeStartDate(xchange, xpertId);

  const lastStartDate = terms[terms.length - 1].start_date;
  const lastTermDuration =
    terms[terms.length - 1].duration! * terms[terms.length - 1].multiplier!;

  const xpertEndDate = lastStartDate ? new Date(lastStartDate) : undefined;
  if (xpertEndDate) {
    xpertEndDate.setHours(0, 0, 0, 0);
  }

  if (lastStartDate) {
    xpertEndDate?.setDate(xpertEndDate?.getDate() + lastTermDuration * 7);
  }

  const diffInDays =
    xpertEndDate && xpertStartDate
      ? Math.floor(
          (xpertEndDate.getTime() - xpertStartDate.getTime()) /
            (1000 * 3600 * 24)
        )
      : 0;

  return Math.floor(diffInDays / 7);
};

export const getProductCurrencies = (product: IXchangeProduct): string[] => {
  const currencies: string[] = [];

  if (product.terms) {
    for (const term of product.terms) {
      if (term.currency && !currencies.includes(term.currency)) {
        currencies.push(term.currency);
      }
    }
  }

  return currencies;
};

export const calcXchangeTotalBudgetsByCurrency = (xchange: IXchange) => {
  const budgetsWithCurrency: {
    total: number;
    currency: string;
  }[] = [];
  for (const currency of getXchangeCurrencies(xchange)) {
    budgetsWithCurrency.push({
      total: calcXchangeTotalBudgetByCurrency(xchange, currency),
      currency,
    });
  }

  return budgetsWithCurrency;
};

export const calcXchangeTotalBudgetByCurrency = (
  xchange: IXchange,
  currency: string
) => {
  const terms = getXchangeTerms(xchange).filter(
    (term) => term.currency === currency
  );
  return calcXchangeTermsBudget(terms);
};

export const calcProductTotalBudgetByCurrency = (
  product: IXchangeProduct,
  currency: string
): number => {
  return calcXchangeTermsBudget(
    (product.terms ?? []).filter((term) => term.currency === currency)
  );
};

export const calcXchangeProductBudgetsByCurrency = (
  product: IXchangeProduct
) => {
  const budgetsWithCurrency: {
    total: number;
    currency: string;
  }[] = [];

  const currencies = getProductCurrencies(product);

  for (const currency of currencies) {
    budgetsWithCurrency.push({
      total: calcProductTotalBudgetByCurrency(product, currency),
      currency,
    });
  }

  return budgetsWithCurrency;
};

export const calcXchangeTotalBudget = (xchange: IXchange) => {
  return (
    calcXchangeTermsBudget(xchange.xchange_terms ?? []) +
    calcXchangeTermsBudget(
      xchange.xchange_products?.reduce(
        (terms, product) => [...terms, ...(product.terms ?? [])],
        [] as IXchangeTerm[]
      ) ?? []
    )
  );
};

const calcXchangeTermsBudget = (terms: IXchangeTerm[]): number => {
  return terms.reduce(
    (sum, term) => sum + (term.cost_per_sprint ?? 0) * (term.multiplier ?? 1),
    0
  );
};

export const calcXchangeXpertBudget = (xchange: IXchange, xpertId: number) => {
  return (
    calcXchangeXpertTermsBudget(xchange.xchange_terms ?? [], xpertId) +
    calcXchangeXpertTermsBudget(
      xchange.xchange_products?.reduce(
        (terms, product) => [...terms, ...(product.terms ?? [])],
        [] as IXchangeTerm[]
      ) ?? [],
      xpertId
    )
  );
};

const calcXchangeXpertTermsBudget = (
  terms: IXchangeTerm[],
  xpertId: number
): number => {
  return calcXchangeTermsBudget(
    terms.filter((term) => term.xpert_involved === xpertId)
  );
};

export const calcXchangeTotalSpendingByCurrency = (
  xchange: IXchange,
  spendings: IGetXchangeBillsSummary[]
): { total: number; currency: string }[] => {
  const currencies = getXchangeCurrencies(xchange);

  return currencies.map((currency) => {
    return {
      total: spendings.reduce(
        (sum, spending) =>
          sum +
          (spending.currency_code === currency ? spending.total_spend : 0),
        0
      ),
      currency,
    };
  });
};

export const calcXchangeTotalSpendingPercentageForCurrency = (
  xchange: IXchange,
  spendings: IGetXchangeBillsSummary[],
  currency: string
) => {
  const totalBudget = calcXchangeTotalBudgetByCurrency(xchange, currency);
  const spendingsByCurrency = calcXchangeTotalSpendingByCurrency(
    xchange,
    spendings
  );

  if (totalBudget === 0) {
    return undefined;
  }

  const totalSpentForCurrency = spendingsByCurrency.find(
    (spending) => spending.currency === currency
  )?.total;

  if (!totalSpentForCurrency) {
    return 0;
  }

  return parseFloat(((totalSpentForCurrency / totalBudget) * 100).toFixed(1));
};

export const calcXchangeRemainingBudgetByCurrency = (
  xchange: IXchange,
  spendings: IGetXchangeBillsSummary[]
): { total: number; currency: string }[] => {
  const budgets = calcXchangeTotalBudgetsByCurrency(xchange);
  const spendingsByCurrency = calcXchangeTotalSpendingByCurrency(
    xchange,
    spendings
  );

  return spendingsByCurrency.map((spending) => {
    const budget = budgets.find(
      (budget) => budget.currency === spending.currency
    );

    if (!budget) {
      return {
        total: 0,
        currency: spending.currency,
      };
    } else {
      return {
        total: budget.total - spending.total,
        currency: spending.currency,
      };
    }
  });
};

export const calcXchangeRemainingBudgetPercentageForCurrency = (
  xchange: IXchange,
  spendings: IGetXchangeBillsSummary[],
  currency: string
) => {
  const totalBudget = calcXchangeTotalBudgetByCurrency(xchange, currency);
  const spendingsByCurrency = calcXchangeTotalSpendingByCurrency(
    xchange,
    spendings
  );

  if (totalBudget === 0) {
    return undefined;
  }

  const totalSpentForCurrency = spendingsByCurrency.find(
    (spending) => spending.currency === currency
  )?.total;

  if (!totalSpentForCurrency) {
    return 100;
  }

  return parseFloat(
    (((totalBudget - totalSpentForCurrency) / totalBudget) * 100).toFixed(1)
  );
};

export const calcXchangeTotalRelativeSpending = (
  xchange: IXchange,
  spendings: IGetXchangeBillsSummary[]
): {
  percentage: number;
  isOver: boolean;
} | null => {
  const totalBudget = calcXchangeTotalBudget(xchange);

  if (totalBudget === 0) {
    return null;
  }

  const totalSpent = spendings.reduce(
    (sum, spending) => sum + spending.total_spend,
    0
  );

  const percentage = parseFloat(
    (((totalSpent - totalBudget) / totalBudget) * 100).toFixed(1)
  );

  return {
    percentage,
    isOver: percentage > 0,
  };
};

export const getXchangeGoalEndDateStat = (
  xchange: IXchange
): {
  days: number;
  isLate: boolean;
} | null => {
  if (!xchange.goal_end_date) {
    return null;
  }

  const goalEndDate = new Date(xchange.goal_end_date ?? "");
  goalEndDate.setHours(0, 0, 0, 0);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const diffInDays = Math.floor(
    (goalEndDate.getTime() - today.getTime()) / (1000 * 3600 * 24)
  );

  return {
    days: diffInDays,
    isLate: diffInDays < 0,
  };
};
