import "./CollapseButton.scss";

const CollapseButton = ({
  isCollapsed,
  onClick,
  className,
}: {
  isCollapsed: boolean;
  onClick: Function;
  className?: string;
}) => {
  return (
    <div className={`Collapse-Button ${className}`} onClick={() => onClick()}>
      {isCollapsed ? (
        <i className="fa-solid fa-caret-down" />
      ) : (
        <i className="fa-regular fa-caret-up" />
      )}
    </div>
  );
};

export default CollapseButton;
