import { Stack } from "react-bootstrap";
import "./OverviewMenuItem.scss";

const OverviewMenuItem = ({
  title,
  iconClasses,
  active = false,
  disabled = false,
  onClick,
  children,
}: {
  title: string;
  iconClasses: string;
  active?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
}) => {
  return (
    <div
      className={`Overview-Menu-Item ${
        active && "Overview-Menu-Item--active"
      } ${!!onClick && !disabled && "Overview-Menu-Item--clickable"} ${
        disabled && "Overview-Menu-Item--disabled"
      }`}
      onClick={() => {
        if (!disabled && onClick) {
          onClick();
        }
      }}
    >
      <Stack direction="horizontal">
        <div className="Overview-Menu-Item__Icon">
          <i className={iconClasses} />
        </div>
        {title}

        <span className="ms-auto">{children}</span>
      </Stack>
    </div>
  );
};

export default OverviewMenuItem;
