import { Control, useController } from "react-hook-form";
import IBill from "@interfaces/IBill";
import BillsSelectorItem from "./BillsSelectorItem/BillsSelectorItem";

const BillsSelector = ({
  possibleBills,
  control,
  name,
  disabled = false,
}: {
  possibleBills?: IBill[];
} & {
  control: Control<any, any>;
  name: string;
  disabled?: boolean;
}) => {
  const { field } = useController({
    name,
    control,
  });

  const onBillClick = (billId: number) => {
    let newBillsSelection = field.value || [];

    if (newBillsSelection.includes(billId)) {
      newBillsSelection = newBillsSelection.filter(
        (id: number) => id !== billId
      );
    } else {
      newBillsSelection = newBillsSelection.concat(billId);
    }

    field.onChange(newBillsSelection);
  };

  return (
    <>
      {(possibleBills || []).map((bill: IBill) => (
        <BillsSelectorItem
          key={bill.id}
          bill={bill}
          onClick={() => onBillClick(bill.id!)}
          checked={(field.value || []).includes(bill.id!)}
          disabled={disabled}
        />
      ))}
    </>
  );
};

export default BillsSelector;
