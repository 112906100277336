import IContract from "@interfaces/IContract";
import ContractListItem from "./ContractListItem/ContractListItem";

const ContractList = ({
  contracts,
  showSignedVersion = false,
}: {
  contracts?: IContract[];
  showSignedVersion?: boolean;
}) => {
  return (
    <>
      {contracts?.map((contract) => (
        <ContractListItem
          key={`contract-${contract.id}`}
          contract={contract}
          showSignedVersion={showSignedVersion}
        />
      ))}
    </>
  );
};

export default ContractList;
