import { Image } from "react-bootstrap";
import { ReactNode, useEffect, useRef, useState } from "react";
import "./GalleryPage.scss";
import { useDebounce } from "@hooks/useDebounce";

const GalleryPage = ({
  headerContent,
  galleryImageUri,
  galleryVideoUri,
  galleryVideoAutoPlay = true,
  galleryVideoAllowSound = false,
  galleryAnimation = true,
  galleryClassName,
  logoOverlay = true,
  customGalleryContent,
  hideVersionNumber = false,
  children,
}: {
  headerContent?: ReactNode;
  galleryImageUri?: string;
  galleryVideoUri?: string;
  galleryVideoAutoPlay?: boolean;
  galleryVideoAllowSound?: boolean;
  galleryAnimation?: boolean;
  galleryClassName?: string;
  logoOverlay?: boolean;
  customGalleryContent?: ReactNode;
  hideVersionNumber?: boolean;
  children: ReactNode;
}) => {
  const {
    debouncedValue: debouncedGalleryVideoUri,
    isDebouncing: isGalleryVideoUriDebouncing,
  } = useDebounce(galleryVideoUri, 1);

  const video = useRef<HTMLVideoElement>(null);

  const [isVideoPlaying, setIsVideoPlaying] = useState(galleryVideoAutoPlay);

  useEffect(() => {
    setIsVideoPlaying(galleryVideoAutoPlay);
  }, [galleryVideoAutoPlay, debouncedGalleryVideoUri]);

  const toggleVideoPlaying = () => {
    if (video.current) {
      if (isVideoPlaying) {
        video.current.pause();
        setIsVideoPlaying(false);
      } else {
        video.current.play();
        setIsVideoPlaying(true);
      }
    }
  };

  return (
    <div className="Gallery-Page">
      <div
        className={`Gallery-Page__Left ${
          customGalleryContent && "Gallery-Page__Left--auto-min-height"
        }`}
      >
        <div className="Gallery-Page__Header">
          <Image src="/logo.svg" className="Gallery-Page__Header__Logo" />
          <div className="Gallery-Page__Header__Content">{headerContent}</div>
        </div>

        <div className="Gallery-Page__Content">{children}</div>

        {!hideVersionNumber && (
          <div className="Gallery-Page__Footer">
            <small className="text-muted">
              {process.env.REACT_APP_VERSION || "local"}
            </small>
          </div>
        )}
      </div>

      <div
        className={`Gallery-Page__Gallery ${
          !customGalleryContent && "Gallery-Page__Gallery--auto-hide"
        }`}
      >
        <div className="Gallery-Page__Gallery__Background">
          {customGalleryContent ? (
            customGalleryContent
          ) : (
            <>
              {logoOverlay && (
                <div className="Gallery-Page__Gallery__Overlay">
                  <Image
                    src="/logo_x_gallery.svg"
                    className="Gallery-Page__Gallery__Overlay__Image"
                  />
                </div>
              )}

              {debouncedGalleryVideoUri && !isGalleryVideoUriDebouncing && (
                <>
                  <div
                    className="Gallery-Page__Gallery__Content__Video-Overlay"
                    onClick={toggleVideoPlaying}
                  >
                    {!isVideoPlaying && (
                      <div className="Gallery-Page__Gallery__Content__Video-Overlay__Play-Button">
                        <i className="fa-solid fa-play" />
                      </div>
                    )}
                  </div>
                  <video
                    ref={video}
                    className={`Gallery-Page__Gallery__Content ${galleryClassName} ${
                      galleryAnimation && "second-half-fade-in"
                    }`}
                    autoPlay={galleryVideoAutoPlay}
                    loop={galleryVideoAutoPlay}
                    muted={!galleryVideoAllowSound}
                    onEnded={() => setIsVideoPlaying(false)}
                  >
                    <source src={debouncedGalleryVideoUri} type="video/mp4" />
                  </video>
                </>
              )}

              {!debouncedGalleryVideoUri && galleryImageUri && (
                <Image
                  className={`Gallery-Page__Gallery__Content ${galleryClassName} ${
                    galleryAnimation && "second-half-fade-in"
                  }`}
                  src={galleryImageUri}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default GalleryPage;
