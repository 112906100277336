import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import ReviewerService, {
  IGetReviewersParams,
} from "@services/ReviewerService";
import IReviewer from "@interfaces/IReviewer";

export const useReviewers = (
  params?: IGetReviewersParams,
  options?: Pick<
    UseQueryOptions<IReviewer[], Error>,
    "enabled" | "onSuccess" | "staleTime"
  >
) =>
  useQuery(
    ["reviewers", { params }],
    () => ReviewerService.getReviewers(params),
    options
  );
