import PersonCard from "@components/PersonCard/PersonCard";
import IReviewer from "@interfaces/IReviewer";
import IParty from "@interfaces/IParty";
import { usePartyPersonTitles } from "@hooks/party/usePartyPersonTitles";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { formatDateToString } from "@helpers/date-utils";
import { useTranslation } from "react-i18next";
import "./ContractReviewerListItem.scss";

const ContractReviewerListItem = ({
  reviewer,
  party,
}: {
  reviewer: IReviewer;
  party?: IParty;
}) => {
  const { t } = useTranslation();
  const { getPersonTitles } = usePartyPersonTitles(party);

  return (
    <PersonCard
      personId={reviewer.person!}
      title={getPersonTitles(reviewer.person!)}
      isDisabled={true}
    >
      {reviewer.drive_share?.shared_at && (
        <OverlayTrigger
          overlay={
            <Tooltip>
              {formatDateToString(new Date(reviewer.drive_share.shared_at))}
            </Tooltip>
          }
        >
          <Badge
            pill
            bg="light"
            text="info"
            className="Contract-Reviewer-List-Item__Badge--date me-2 border"
          >
            {formatDateToString(new Date(reviewer.drive_share.shared_at), {
              dateStyle: "medium",
            })}
          </Badge>
        </OverlayTrigger>
      )}

      {reviewer.drive_share?.is_shared && (
        <Badge pill bg="info" text="info">
          <i className="fa-solid fa-inbox-out me-1" />
          <span className="Contract-Reviewer-List-Item__Badge--status">
            {t("contracts.review.status.sent")}
          </span>
        </Badge>
      )}
    </PersonCard>
  );
};

export default ContractReviewerListItem;
