import { ReactNode } from "react";
import "./InfoCard.scss";

const InfoCard = ({
  id,
  title,
  children,
}: {
  id?: string;
  title?: string;
  children: ReactNode;
}) => {
  return (
    <div id={id} className="Info-Card">
      {title && <div className="Info-Card__Title">{title}</div>}
      {children}
    </div>
  );
};

export default InfoCard;
