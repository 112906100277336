import { useQuery } from "@tanstack/react-query";
import IDocument from "@interfaces/IDocument";
import DocumentService, {
  IGetDocumentsParams,
} from "@services/DocumentService";

export const useDocuments = (
  params?: IGetDocumentsParams,
  options?: {
    enabled?: boolean;
    onSuccess?: (data: IDocument[]) => void;
  }
) =>
  useQuery(
    ["documents", { params }],
    () => DocumentService.getDocuments(params),
    { enabled: options?.enabled, onSuccess: options?.onSuccess }
  );