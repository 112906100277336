import { ReactNode } from "react";
import { Stack } from "react-bootstrap";
import "./InfoHeader.scss";

interface InfoHeaderBasicInfo {
  label: string;
  value: string | ReactNode;
}

const InfoHeader = ({
  title,
  leftInfo,
  rightInfo,
}: {
  title: string;
  leftInfo: InfoHeaderBasicInfo[];
  rightInfo: InfoHeaderBasicInfo[];
}) => {
  return (
    <div className="Info-Header">
      <Stack direction="horizontal" className="align-items-stretch">
        <Stack>
          <div className="Info-Header__Title">{title}</div>
          <div className="mt-auto">
            {leftInfo.map((info, index) => (
              <div
                key={`left_info_${index}`}
                className="Info-Header__Basic-Info"
              >
                <div className="Info-Header__Basic-Info__Label">
                  {info.label}:
                </div>
                <div className="Info-Header__Basic-Info__Value">
                  {info.value}
                </div>
              </div>
            ))}
          </div>
        </Stack>

        <div className="ms-auto text-end mt-3">
          {rightInfo.map((info, index) => (
            <div
              key={`right_info_${index}`}
              className="Info-Header__Basic-Info"
            >
              <div className="Info-Header__Basic-Info__Label Info-Header__Basic-Info__Label--right">
                {info.label}:
              </div>
              <div className="Info-Header__Basic-Info__Value">{info.value}</div>
            </div>
          ))}
        </div>
      </Stack>
    </div>
  );
};

export default InfoHeader;
