import { useTranslation } from "react-i18next";
import StepCard from "@components/StepCard/StepCard";
import ICompany from "@interfaces/ICompany";
import CompanyMembers from "./CompanyMembers/CompanyMembers";
import XchangeTable from "@pages/Company/CompanyEditPage/XchangeTable/XchangeTable";
import CompanyDetails from "@pages/Company/CompanyEditPage/CompanyGeneralOverview/CompanyDetails/CompanyDetails";
import CompanyOnboardingProgress from "@pages/Company/CompanyEditPage/CompanyGeneralOverview/CompanyOnboardingProgress/CompanyOnboardingProgress";

const CompanyGeneralOverview = ({
  company,
  clientId,
  xpertId,
}: {
  company?: ICompany;
  clientId?: number;
  xpertId?: number;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {company?.xpert_id && <CompanyOnboardingProgress company={company} />}

      <CompanyDetails company={company} />

      <StepCard title={t("companies.details.overview.title")}>
        <XchangeTable clientId={clientId} xpertId={xpertId} />
      </StepCard>

      <StepCard title={t("companies.details.overview.people.title")}>
        <CompanyMembers company={company} />
      </StepCard>
    </>
  );
};

export default CompanyGeneralOverview;
