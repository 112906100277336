import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AxiosErrorAlert from "@components/AxiosErrorAlert/AxiosErrorAlert";
import CustomSelect from "@components/CustomSelect/CustomSelect";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import WizardModal from "@components/WizardModal/WizardModal";
import ProductStatuses from "@enum/ProductStatuses";
import { useCatalogProducts } from "@hooks/product/useCatalogProducts";
import { useXchangeProductCreate } from "@hooks/product/useXchangeProductCreate";
import { useXchangeProductUpdate } from "@hooks/product/useXchangeProductUpdate";
import { useCatalogTerms } from "@hooks/term/useCatalogTerms";
import IXchange from "@interfaces/IXchange";
import ICatalogProduct from "@interfaces/products/ICatalogProduct";
import IXchangeProduct from "@interfaces/products/IXchangeProduct";
import IXpertTerm from "@interfaces/terms/IXpertTerm";
import XchangeTermDetailsForm, {
  TermFormValues,
  xchangeTermSchema,
} from "./XchangeTermDetailsForm";
import IXchangeTerm from "@interfaces/terms/IXchangeTerm";
import IEpic from "@interfaces/IEpic";
import { getXchangeXpertStats } from "@helpers/xchange-utils";

export interface ProductFormValues {
  product: number | null;
  terms: TermFormValues[];
}

export const xchangeProductSchema = Joi.object({
  product: Joi.number().required(),
  terms: Joi.array().items(xchangeTermSchema).required(),
});

const XchangeProductDetailModal = ({
  show,
  xchange,
  productToEdit,
  epics,
  onClose,
}: {
  show: boolean;
  xchange?: IXchange;
  productToEdit?: IXchangeProduct | null;
  epics?: IEpic[];
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  const formMethods = useForm<ProductFormValues>({
    resolver: joiResolver(xchangeProductSchema),
    mode: "onChange",
  });
  const {
    control,
    watch,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = formMethods;
  const { fields, replace } = useFieldArray({
    control,
    name: "terms",
  });

  const {
    isLoading: areCatalogProductsLoading,
    data: catalogProductsInfiniteData,
  } = useCatalogProducts(
    {
      status: ProductStatuses.ACTIVE,
      terms__gte: 1,
      page_size: 1000,
    },
    {
      enabled: show,
    }
  );
  const catalogProducts = useMemo(
    () =>
      catalogProductsInfiniteData
        ? catalogProductsInfiniteData.pages.flatMap((p) => p.results)
        : [],
    [catalogProductsInfiniteData]
  );

  useEffect(() => {
    setProductOptions(
      catalogProducts.map((product) => ({
        value: product.id!,
        label: product.title!,
      }))
    );
    if (productToEdit) {
      setSelectedProduct(
        catalogProducts.find((p) => p.id === productToEdit.catalog_product_id)
      );
    }
  }, [catalogProducts, catalogProductsInfiniteData, productToEdit]);

  const [productOptions, setProductOptions] = useState<
    { value: number; label: string }[]
  >([]);
  const [selectedProduct, setSelectedProduct] = useState<ICatalogProduct>();

  const { data: catalogTermInfiniteData } = useCatalogTerms(
    { products: selectedProduct?.id, page_size: 1000 },
    {
      enabled: show && !!selectedProduct?.id,
      onSuccess: (infiniteData) => {
        const results = infiniteData.pages.flatMap((p) => p.results);
        if (productToEdit) {
          const formTerms = getValues("terms");

          if (formTerms) {
            for (const [index, term] of results.entries()) {
              formTerms[index] = {
                ...formTerms[index],
                catalog_term: term.id,
              };
            }

            replace(formTerms);
          }
        } else {
          replace(
            results.map((term) => ({
              catalog_term: term.id,
              multiplier: 1,
            }))
          );
        }
      },
      refetchOnWindowFocus: false,
    }
  );
  const catalogTerms = catalogTermInfiniteData
    ? catalogTermInfiniteData.pages.flatMap((p) => p.results)
    : [];

  useEffect(() => {
    if (productToEdit) {
      reset({
        product: productToEdit.catalog_product_id,
        terms: productToEdit.terms?.map((term, index) => {
          return {
            ...term,
            xpert: term.xpert_involved,
            start_date: term.start_date ? new Date(term.start_date) : undefined,
            multiplier: +term.multiplier!,
            duration: term.duration,
            sub_domain: term.sub_domain?.id,
            stages: term.stages?.map((s) => s.id!),
          };
        }),
      });
    } else {
      reset({
        product: null,
        terms: undefined,
      });
      setSelectedProduct(undefined);
    }
  }, [catalogProducts, getValues, productToEdit, reset, show]);

  const onModalClose = () => {
    setSelectedProduct(undefined);
    reset();
    onClose();
  };

  const [xpertTerms, setXpertTerms] = useState<IXpertTerm[]>([]);

  const xchangeProductCreateMutation = useXchangeProductCreate();
  const xchangeProductUpdateMutation = useXchangeProductUpdate();

  const formSubmit = (data: ProductFormValues) => {
    if (productToEdit) {
      xchangeProductUpdateMutation.mutate(
        {
          xchangeId: xchange?.id!,
          xchangeProduct: {
            ...productToEdit,
            sub_domains: productToEdit?.sub_domains?.map((d) => d.id),
            stages: productToEdit?.stages?.map((s) => s.id!),
            terms: data.terms.map((term) => ({
              ...term,
              xchange: undefined,
              xpert_involved: term.xpert_involved
                ? term.xpert_involved
                : undefined,
              start_date: term.start_date
                ? new Date(term.start_date).toISOString()
                : undefined,
            })),
          },
        },
        {
          onSuccess: () => {
            reset();
            onClose();
          },
        }
      );
    } else {
      xchangeProductCreateMutation.mutate(
        {
          xchangeId: xchange?.id!,
          xchangeProduct: {
            ...selectedProduct,
            catalog_product_id: selectedProduct?.id!,
            sub_domains: selectedProduct?.sub_domains?.map((d) => d.id),
            stages: selectedProduct?.stages?.map((s) => s.id!),
            terms: data.terms.map((term) => ({
              ...term,
              xchange: undefined,
              xpert_involved: term.xpert_involved
                ? term.xpert_involved
                : undefined,
              start_date: term.start_date
                ? new Date(term.start_date).toISOString()
                : undefined,
            })),
          },
        },
        {
          onSuccess: () => {
            reset();
            onClose();
          },
        }
      );
    }
  };

  watch("terms");

  return (
    <WizardModal
      show={show}
      startingStep={productToEdit ? 1 : 0}
      onClose={onModalClose}
      onFinish={handleSubmit(formSubmit)}
      onFinishText={t(
        `form.deliverables.product.${productToEdit ? "edit" : "add"}.finish`
      )}
      steps={[
        {
          title: t(
            `form.deliverables.product.${productToEdit ? "edit" : "add"}.title`
          ),
          content: (
            <>
              <FormProvider {...formMethods}>
                <h3 className="mb-6">
                  {t(
                    `form.deliverables.product.${
                      productToEdit ? "edit" : "add"
                    }.header`
                  )}
                </h3>
                <Form.Group className="mb-4">
                  <Form.Label>
                    {t("form.deliverables.product.fields.product")}
                  </Form.Label>
                  <CustomSelect
                    control={control}
                    name="product"
                    isLoading={areCatalogProductsLoading}
                    options={areCatalogProductsLoading ? [] : productOptions}
                    onAfterChange={() => {
                      setSelectedProduct(
                        catalogProducts?.find(
                          (product) => product.id === getValues("product")
                        )
                      );
                    }}
                    isDisabled={!!productToEdit}
                  />
                  <FieldErrorMessage
                    field={errors.product}
                    fieldName={t("form.deliverables.product.fields.product")}
                  />
                </Form.Group>
              </FormProvider>
            </>
          ),
          isCompleted: !!selectedProduct,
        },
        {
          title: t(
            `form.deliverables.product.${productToEdit ? "edit" : "add"}.title`
          ),
          content: (
            <FormProvider {...formMethods}>
              <h3 className="mb-6">{selectedProduct?.title}</h3>
              {fields.length > 0
                ? fields?.map((field, index) => (
                    <div key={field.id}>
                      <h5 className="mt-6 mb-6">
                        {
                          catalogTerms?.find(
                            (term) => term.id! === field.catalog_term
                          )?.title
                        }
                      </h5>
                      <XchangeTermDetailsForm
                        editingXchangeTerm={field as IXchangeTerm}
                        catalogTerm={catalogTerms?.find(
                          (term) => term.id! === field.catalog_term
                        )}
                        field_index={index}
                        onXpertTermChange={(xpertTerm) => {
                          if (xpertTerm) {
                            if (
                              !xpertTerms.find((t) => t.id === xpertTerm.id)
                            ) {
                              setXpertTerms([...xpertTerms, xpertTerm]);
                            }
                          }
                        }}
                        disableXpertInvolved={(() => {
                          const current_selected_xpert_involved = getValues(
                            `terms.${index}.xpert_involved`
                          )!;

                          return (
                            !!productToEdit &&
                            epics?.some((epic) =>
                              epic.xperts?.includes(
                                current_selected_xpert_involved
                              )
                            ) &&
                            getXchangeXpertStats(
                              xchange!,
                              current_selected_xpert_involved
                            ).totalInvolvementCount === 1 &&
                            getValues("terms")?.filter(
                              (f) =>
                                f.xpert_involved ===
                                current_selected_xpert_involved
                            ).length === 1
                          );
                        })()}
                        minStartDate={xchange?.start_date ?? ""}
                      />
                    </div>
                  ))
                : t("form.deliverables.info.no_term_error")}
              <AxiosErrorAlert
                response={
                  xchangeProductCreateMutation.error ||
                  xchangeProductUpdateMutation
                }
                translationPrefix="form.deliverables.product.fields."
              />
            </FormProvider>
          ),
          isCompleted: fields.length > 0,
        },
      ]}
    />
  );
};

export default XchangeProductDetailModal;
