import "./OverviewMenuGroup.scss";

const OverviewMenuGroup = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="Overview-Menu-Group">
      <div className="Overview-Menu-Group__Title">{title}</div>
      {children}
    </div>
  );
};

export default OverviewMenuGroup;
