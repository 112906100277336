import { useMutation, useQueryClient } from "@tanstack/react-query";
import IDocument from "@interfaces/IDocument";
import DocumentService from "@services/DocumentService";

export const useDocumentCreate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ ...document }: IDocument) => DocumentService.createDocument(document),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["documents"]);
      },
    }
  );
};
