import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import StepCard from "@components/StepCard/StepCard";
import IXpert from "@interfaces/IXpert";
import XpertBills from "@pages/Company/CompanyEditPage/CompanyXpertBilling/XpertBills/XpertBills";
import XpertFinanceInsuranceForm from "@pages/Company/CompanyEditPage/CompanyXpertBilling/XpertFinanceInsuranceForm/XpertFinanceInsuranceForm";
import useElementAnchoring from "@hooks/useElementAnchoring";

const CompanyXpertBilling = ({ xpert }: { xpert?: IXpert }) => {
  const { t } = useTranslation();
  useElementAnchoring();

  return (
    <>
      <StepCard title={t("billing.history.title")}>
        <XpertBills xpert={xpert} />
      </StepCard>

      {!xpert ? (
        <div className="d-flex justify-content-center mb-4">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <StepCard
          id="xpert-finance-insurance"
          title={t("companies.details.xpert.finance_insurance.title")}
        >
          {xpert && <XpertFinanceInsuranceForm xpert={xpert} />}
        </StepCard>
      )}
    </>
  );
};

export default CompanyXpertBilling;
