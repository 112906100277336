import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import ICatalogProduct from "@interfaces/products/ICatalogProduct";
import CatalogProductService from "@services/CatalogProductService";

export const useCatalogProduct = (
  id: number,
  options?: Pick<
    UseQueryOptions<ICatalogProduct, Error>,
    "enabled" | "onSuccess" | "retry"
  >
) =>
  useQuery(
    ["catalog-products", { id }],
    () => CatalogProductService.getCatalogProduct(id),
    options
  );
