import BadgeCheckbox from "@components/BadgeCheckbox/BadgeCheckbox";
import { Control, useController } from "react-hook-form";
import { useTranslation } from "react-i18next";

const BadgeSelector = ({
  name,
  control,
  options,
}: {
  name: string;
  control: Control<any, any>;
  options: {
    label: string;
    value: string;
  }[];
}) => {
  const { t } = useTranslation();

  const { field } = useController({
    name,
    control,
  });

  const areAllOptionsSelected =
    field.value.length === options.length || field.value.length === 0;

  return (
    <div>
      <BadgeCheckbox
        label={t("filters.all")}
        checked={areAllOptionsSelected}
        onClick={() => {
          const currentValues = field.value || [];
          if (currentValues.length === options.length) {
            field.onChange([]);
          } else {
            field.onChange(options.map((option) => option.value));
          }
        }}
      />

      {options.map((option) => {
        return (
          <BadgeCheckbox
            key={option.value}
            label={option.label}
            checked={
              field.value && !areAllOptionsSelected
                ? field.value.includes(option.value)
                : false
            }
            onClick={() => {
              const currentValues = field.value || [];

              if (areAllOptionsSelected) {
                field.onChange([option.value]);
              } else {
                if (currentValues.includes(option.value)) {
                  field.onChange(
                    currentValues.filter(
                      (value: string) => value !== option.value
                    )
                  );
                } else {
                  field.onChange([...currentValues, option.value]);
                }
              }
            }}
          />
        );
      })}
    </div>
  );
};

export default BadgeSelector;
