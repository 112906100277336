import { usePeople } from "@hooks/person/usePeople";
import { usePersonCreate } from "@hooks/person/usePersonCreation";
import { usePersonUpdate } from "@hooks/person/usePersonUpdate";
import { useAddPeopleToXpertTeam } from "@hooks/xpert/useAddPeopleToXpertTeam";

import { useXpertTeamMemberCreate } from "@hooks/xpert/useXpertTeamMemberCreate";
import { useXpertTeamMemberUpdate } from "@hooks/xpert/useXpertTeamMemberUpdate";
import { useXpertUpdate } from "@hooks/xpert/useXpertUpdate";
import IPerson from "@interfaces/IPerson";
import IXpert from "@interfaces/IXpert";
import MemberModal, {
  MemberFormValues,
} from "@pages/Company/CompanyEditPage/MemberModal/MemberModal";

const XpertMemberModal = ({
  xpert,
  memberToEdit,
  show,
  onClose,
}: {
  xpert: IXpert;
  memberToEdit?: {
    person?: IPerson;
    xpertMemberId?: number;
  };
  show: boolean;
  onClose: () => void;
}) => {
  const { data: people } = usePeople(
    {
      xpert_team_members__xpert__not__in: xpert.id!.toString(),
    },
    { enabled: show }
  );

  const createXpertTeamMemberMutation = useXpertTeamMemberCreate();
  const personCreateMutation = usePersonCreate();
  const personUpdateMutation = usePersonUpdate();
  const updateXpertMutation = useXpertUpdate();
  const updateXpertMemberMutation = useXpertTeamMemberUpdate();

  const saveWithMember = ({
    roles,
    rate,
    currency,
    ...member
  }: MemberFormValues) => {
    if (memberToEdit?.person) {
      personUpdateMutation.mutate(
        {
          id: memberToEdit.person.id,
          context: xpert.company_person,
          ...member,
        },
        {
          onSuccess(person) {
            const primaryContactChanged =
              member.primary_contact !==
              (person?.id === xpert?.primary_contact);

            const financeContactChanged =
              member.finance_contact !==
              (person?.id === xpert?.finance_contact);

            if (primaryContactChanged || financeContactChanged) {
              updateXpertMutation.mutate({
                updatedXpert: {
                  id: xpert.id!,
                  ...(primaryContactChanged && {
                    primary_contact: member.primary_contact ? person.id! : null,
                  }),
                  ...(financeContactChanged && {
                    finance_contact: member.finance_contact ? person.id! : null,
                  }),
                },
              });
            }
            onModalClose();
          },
        }
      );

      updateXpertMemberMutation.mutate({
        xpertId: xpert.id!,
        xpertTeamMember: {
          id: memberToEdit.xpertMemberId,
          rate: rate,
          currency: currency,
          roles: roles,
        },
      });
    } else {
      personCreateMutation.mutate(
        {
          rate: rate,
          currency: currency,
          roles: roles,
          ...member,
        },
        {
          onSuccess(person) {
            createXpertTeamMemberMutation.mutate({
              xpertId: xpert.id!,
              xpertTeamMember: {
                person: person.id,
                xpert: xpert.id,
                rate: person.rate,
                currency: person.currency,
                roles: person.roles,
              },
            });
            updateXpertMutation.mutate({
              updatedXpert: {
                id: xpert.id!,
                primary_contact: member.primary_contact
                  ? person.id!
                  : xpert.primary_contact,
                finance_contact: member.finance_contact
                  ? person.id!
                  : xpert.finance_contact,
              },
            });
            onModalClose();
          },
        }
      );
    }
  };

  const addPeopleToXpertTeamMutation = useAddPeopleToXpertTeam(xpert!.id!);
  const saveWithExistingMember = async (selectedPeople: number[]) => {
    const peopleToAdd = people!.filter((p) => selectedPeople.includes(p.id!));

    await addPeopleToXpertTeamMutation.mutate(peopleToAdd);
    onModalClose();
  };

  const isSaving = () => {
    return (
      personCreateMutation.isLoading ||
      createXpertTeamMemberMutation.isLoading ||
      personUpdateMutation.isLoading ||
      updateXpertMutation.isLoading ||
      updateXpertMemberMutation.isLoading
    );
  };

  const getSavingError = () => {
    return memberToEdit
      ? personUpdateMutation.error ||
          updateXpertMemberMutation.error ||
          updateXpertMutation.error
      : personCreateMutation.error ||
          createXpertTeamMemberMutation.error ||
          updateXpertMutation.error;
  };

  const onModalClose = () => {
    personCreateMutation.reset();
    createXpertTeamMemberMutation.reset();
    personUpdateMutation.reset();
    updateXpertMutation.reset();
    updateXpertMemberMutation.reset();
    onClose();
  };

  return (
    <>
      <MemberModal
        people={people}
        member={
          memberToEdit
            ? {
                ...memberToEdit.person,
                primary_contact:
                  memberToEdit.person?.id === xpert?.primary_contact,
                finance_contact:
                  memberToEdit.person?.id === xpert?.finance_contact,
              }
            : undefined
        }
        show={show}
        showPrimaryContactToggle
        showFinanceContactToggle
        onClose={onModalClose}
        onSaveExisting={saveWithExistingMember}
        onSaveNew={saveWithMember}
        isFinishLoading={isSaving()}
        axiosError={getSavingError()}
      />
    </>
  );
};

export default XpertMemberModal;
