import Joi from "joi";
import { Col, Form, Row } from "react-bootstrap";
import { Control, Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomSelect from "@components/CustomSelect/CustomSelect";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import SelectWithCreation from "@components/SelectWithCreation/SelectWithCreation";
import { isFieldRequired, money } from "@helpers/joi-utils";
import { useStageCreate } from "@hooks/stage/useStageCreate";
import { useStages } from "@hooks/stage/useStages";
import Currencies from "@interfaces/Currencies";
import { useSubDomains } from "@hooks/subdomain/useSubDomains";
import { useMemo } from "react";
import ReactQuill from "react-quill";

interface XpertTermFormValues {
  stages?: number[];
  xpert_involved?: number | null;
  catalog_term?: number | null;
  duration?: number | null;
  cost_per_sprint?: number | null;
  currency?: string | null;
  sub_domain?: number | null;
  description?: string;
}

export const XpertTermSchema = Joi.object({
  stages: Joi.array().items(Joi.number()).min(1).required(),
  xpert_involved: Joi.number().required(),
  catalog_term: Joi.number().required(),
  duration: Joi.number().positive().required(),
  cost_per_sprint: money().required(),
  currency: Joi.string().required(),
  sub_domain: Joi.number().optional().allow(null),
  description: Joi.string().allow(""),
}).unknown(true);

export interface MultipleXpertTermsFormValues {
  catalog_terms: number[];
  xpert_terms: XpertTermFormValues[];
}

export const MultipleXpertTermsSchema = Joi.object({
  catalog_terms: Joi.array().items(Joi.number()).required(),
  xpert_terms: Joi.array().items(XpertTermSchema).required(),
});

const XpertTermForm = ({
  title,
  fieldIndex,
  className,
}: {
  title?: string;
  fieldIndex: number;
  className?: string;
}) => {
  const { t } = useTranslation();

  const formMethods = useFormContext<MultipleXpertTermsFormValues>();

  const {
    control,
    getValues,
    setValue,
    register,
    formState: { errors },
  } = formMethods;

  const { data: stages, isLoading: areStagesLoading } = useStages();

  const createStageMutation = useStageCreate();

  const createStageAndAdd = (value: string) => {
    createStageMutation.mutate(
      {
        name: `Stage ${value}`,
        value: value,
      },
      {
        onSuccess: (stage) => {
          setValue(
            `xpert_terms.${fieldIndex}.stages`,
            (getValues(`xpert_terms.${fieldIndex}.stages`) || []).concat([
              stage.id!,
            ])
          );
        },
      }
    );
  };

  const isSubDomainAlreadySpecified = useMemo(() => {
    return getValues(`xpert_terms.${fieldIndex}.sub_domain`) !== undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: subDomains } = useSubDomains();

  return (
    <Form className={className}>
      {title && <h3 className="mb-6">{title}</h3>}

      <Form.Group className="mb-4">
        <Form.Label>
          {t("companies.details.xpert.terms.fields.description")}
        </Form.Label>
        <Controller
          control={control}
          name={`xpert_terms.${fieldIndex}.description`}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <>
              <ReactQuill
                className={`${error && "ql-invalid"}`}
                value={value}
                onChange={(value) => {
                  if (value.replace(/(<([^>]+)>)/gi, "")) {
                    onChange(value);
                  } else {
                    onChange(undefined);
                  }
                }}
              />
              <FieldErrorMessage
                field={error}
                fieldName={t(
                  "companies.details.xpert.terms.fields.description"
                )}
              />
            </>
          )}
        />
      </Form.Group>

      {!isSubDomainAlreadySpecified && (
        <Form.Group className="mb-4">
          <Form.Label
            aria-required={isFieldRequired(XpertTermSchema, "sub_domain")}
          >
            {t("companies.details.xpert.terms.fields.sub_domain")}
          </Form.Label>
          <CustomSelect
            control={control}
            name={`xpert_terms.${fieldIndex}.sub_domain`}
            options={(subDomains || []).map((subDomain) => ({
              value: subDomain.id,
              label: subDomain.name,
            }))}
            isClearable
          />
          <FieldErrorMessage
            field={errors.xpert_terms?.[fieldIndex]?.sub_domain}
            fieldName={t("companies.details.xpert.terms.fields.sub_domain")}
          />
        </Form.Group>
      )}

      <Form.Group className="mb-4">
        <Form.Label aria-required={isFieldRequired(XpertTermSchema, "stages")}>
          {t("companies.details.xpert.terms.fields.stages")}
        </Form.Label>
        <SelectWithCreation
          name={`xpert_terms.${fieldIndex}.stages`}
          control={control as Control<any, any>}
          isLoading={areStagesLoading}
          options={
            stages?.map((stage) => ({
              value: stage.id,
              label: stage.name,
            })) || []
          }
          onEnterWithoutOptions={(value) => {
            createStageAndAdd(value);
          }}
          inputType="number"
        />
        <FieldErrorMessage
          field={errors.xpert_terms?.[fieldIndex]?.stages}
          fieldName={t("companies.details.xpert.terms.fields.stages")}
        />
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label
          aria-required={isFieldRequired(XpertTermSchema, "duration")}
        >
          {t("companies.details.xpert.terms.fields.weeks")}
        </Form.Label>
        <Form.Control
          type="number"
          {...register(`xpert_terms.${fieldIndex}.duration`)}
          isInvalid={!!errors.xpert_terms?.[fieldIndex]?.duration}
        />
        <FieldErrorMessage
          field={errors.xpert_terms?.[fieldIndex]?.duration}
          fieldName={t("companies.details.xpert.terms.fields.weeks")}
        />
      </Form.Group>
      <Row>
        <Form.Group className="mb-4" as={Col} md={3}>
          <Form.Label
            aria-required={isFieldRequired(XpertTermSchema, "currency")}
          >
            {t("companies.details.xpert.terms.fields.currency")}
          </Form.Label>
          <CustomSelect
            control={control}
            name={`xpert_terms.${fieldIndex}.currency`}
            options={Object.values(Currencies).map((type) => ({
              value: type,
              label: t(`currencies.${type}`) as string,
            }))}
          />
          <FieldErrorMessage
            field={errors.xpert_terms?.[fieldIndex]?.currency}
            fieldName={t("companies.details.xpert.terms.fields.currency")}
          />
        </Form.Group>
        <Form.Group className="mb-4 ps-0" as={Col} md={9}>
          <Form.Label
            aria-required={isFieldRequired(XpertTermSchema, "cost_per_sprint")}
          >
            {t("companies.details.xpert.terms.fields.rate")}
          </Form.Label>
          <Form.Control
            type="number"
            {...register(`xpert_terms.${fieldIndex}.cost_per_sprint`)}
            isInvalid={!!errors.xpert_terms?.[fieldIndex]?.cost_per_sprint}
          />
          <FieldErrorMessage
            field={errors.xpert_terms?.[fieldIndex]?.cost_per_sprint}
            fieldName={t("companies.details.xpert.terms.fields.rate")}
          />
        </Form.Group>
      </Row>
    </Form>
  );
};

export default XpertTermForm;
