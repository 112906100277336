import BillStatusBadge from "@components/BillStatusBadge/BillStatusBadge";
import InvoiceStatusBadge from "@components/InvoiceStatusBadge/InvoiceStatusBadge";
import { toCurrencyString } from "@helpers/currencyUtils";
import { toRangeString } from "@helpers/date-utils";
import { calcInvoiceBillSubTotal } from "@helpers/invoice-utils";
import { useInvoice } from "@hooks/invoice/useInvoice";
import IBill from "@interfaces/IBill";
import { ReactNode } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./BillTableSubItem.scss";
import ActionsDropdown from "@components/ListOptionsDropdown/ActionsDropdown";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import IXchange from "@interfaces/IXchange";

const BillTableSubItem = ({ bill }: { bill: IBill }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: invoice } = useInvoice(bill.outbound_invoice!, {
    enabled: !!bill.outbound_invoice,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const InvoiceLink = ({ children }: { children: ReactNode }) => {
    if (invoice?.online_invoice_url) {
      return (
        <a href={invoice?.online_invoice_url} target="_bank">
          {children}
          <i className="fa-solid fa-external-link-alt ms-1 small"></i>
        </a>
      );
    } else {
      return (
        <Link
          to={`/xchanges/${invoice?.xchange}/invoicing/${bill.outbound_invoice}`}
        >
          {children}
        </Link>
      );
    }
  };

  const dropdownActions = (() => {
    const actions = [
      {
        label: t("billing.history.actions.view_bill"),
        onClick: () => {
          navigate(
            `/xchanges/${
              (bill.xchange as IXchange)?.id || bill.xchange
            }/billing/${bill.id}`
          );
        },
      },
    ];

    if (invoice) {
      actions.push({
        label: t("billing.history.actions.view_invoice"),
        onClick: () => {
          if (invoice?.online_invoice_url) {
            window.open(invoice?.online_invoice_url, "_blank");
          } else {
            return navigate(
              `/xchanges/${invoice?.xchange}/invoicing/${bill.outbound_invoice}`
            );
          }
        },
      });
    }

    return actions;
  })();

  return (
    <table>
      <tbody>
        <tr className="Bill-Table-Sub-Item">
          <td>
            <div className="ms-6">
              {bill.billing_period_start && bill.billing_period_end
                ? toRangeString(
                    new Date(bill.billing_period_start),
                    new Date(bill.billing_period_end)
                  )
                : "--"}
              <div className="Bill-Table-Sub-Item__Description">
                {bill.invoice_number}
              </div>
            </div>
          </td>
          <td>
            <BillStatusBadge
              status={bill.xero_invoice?.status!}
              className="me-1"
            />
            <Link
              to={`/xchanges/${
                (bill.xchange as IXchange)?.id || bill.xchange
              }/billing/${bill.id}`}
            >
              <span className="Bill-Table-Sub-Item__Currency">
                {bill?.xero_invoice?.currency_code}
              </span>
              {toCurrencyString(bill?.xero_invoice?.sub_total, "USD", 2)}
            </Link>
            <div className="Bill-Table-Sub-Item__Description">
              {bill?.xero_invoice?.currency_code}{" "}
              {toCurrencyString(bill?.xero_invoice?.total, "USD", 2)}
            </div>
          </td>
          <td>
            {invoice ? (
              <>
                <InvoiceStatusBadge
                  invoice={invoice.xero_invoice!}
                  className="me-1"
                />
                <InvoiceLink>
                  <span className="Bill-Table-Sub-Item__Currency">
                    {invoice.xero_invoice
                      ? invoice.xero_invoice.currency_code
                      : invoice?.currency_code}
                  </span>
                  {toCurrencyString(
                    calcInvoiceBillSubTotal(invoice, bill.id!),
                    "USD",
                    2
                  )}
                </InvoiceLink>
                <div className="Bill-Table-Sub-Item__Description">
                  {invoice.xero_invoice && (
                    <>
                      {bill?.xero_invoice?.currency_code}{" "}
                      {toCurrencyString(invoice.xero_invoice?.total, "USD", 2)}
                      <span className="ms-3">
                        {invoice.xero_invoice?.invoice_number}
                      </span>
                    </>
                  )}
                </div>
              </>
            ) : (
              "--"
            )}
          </td>
          <td className="table-actions">
            <ActionsDropdown actions={dropdownActions}>
              <Button variant="outline-light" className="p-2 pt-1 pb-1">
                <i className="fa-regular fa-ellipsis-vertical"></i>
              </Button>
            </ActionsDropdown>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default BillTableSubItem;
