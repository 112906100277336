import { useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CompanyCard from "@components/CompanyCard/CompanyCard";
import Page from "@components/Page/Page";
import SearchFilter from "@components/SearchFilter/SearchFilter";
import CustomSelect from "@components/CustomSelect/CustomSelect";
import { useCompanies } from "@hooks/company/useCompanies";
import { useDebounce } from "@hooks/useDebounce";
import { CompanyStatuses, CompanyTypes } from "@interfaces/ICompany";
import { IGetCompaniesParams } from "@services/CompanyService";
import "./CompanyListPage.scss";
import { FormProvider, useForm } from "react-hook-form";
import { Virtuoso } from "react-virtuoso";

interface CompanyListFilters {
  status: string[];
  type: string[];
  search: string;
}

const CompanyListPage = () => {
  const { t } = useTranslation();

  const [getCompaniesParams, setGetCompaniesParams] =
    useState<IGetCompaniesParams>({
      ordering: "company_name",
    });
  const {
    debouncedValue: debouncedGetCompaniesParams,
    isDebouncing: isGetCompaniesParamsDeboucing,
  } = useDebounce(getCompaniesParams, 500);
  const {
    isLoading: areCompaniesLoading,
    data: companyInfiniteData,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useCompanies(debouncedGetCompaniesParams);
  const companies = companyInfiniteData
    ? companyInfiniteData.pages.flatMap((p) => p.results)
    : [];

  const formMethods = useForm<CompanyListFilters>();
  const { watch, control } = formMethods;

  useEffect(() => {
    const subscription = watch((data) => {
      setGetCompaniesParams({
        ...getCompaniesParams,
        status__in: data.status?.join(","),
        type__in: data.type?.join(","),
        search: data.search,
      });
    });
    return () => subscription.unsubscribe();
  }, [getCompaniesParams, watch]);

  return (
    <Page
      title={t("companies.list.title")}
      subTitle={t("companies.list.subtitle")}
    >
      <Card>
        <Card.Header className="Company-List-Page__Header">
          <FormProvider {...formMethods}>
            <Row>
              <Col>
                <Link to={"/companies/create"}>
                  <Button className="Company-List-Page__Header__Create-Button">
                    <i className="fa-solid fa-plus me-1"></i>{" "}
                    {t("companies.list.new_company")}
                  </Button>
                </Link>
              </Col>
              <Col lg={8}>
                <Row className="Company-List-Page__Header__Filters d-flex align-items-center justify-content-end">
                  <CustomSelect
                    name="status"
                    control={control}
                    className="p-0"
                    placeholder={t("form.xperts_edit.form_labels.status")}
                    isClearable={true}
                    options={Object.values(CompanyStatuses).map((status) => ({
                      value: status,
                      label: t(`companies.statuses.${status}`),
                    }))}
                    isMulti
                  />
                  <CustomSelect
                    name="type"
                    control={control}
                    className="p-0 ps-3 me-5"
                    placeholder={t("companies.list.card.type")}
                    isClearable={true}
                    options={Object.values(CompanyTypes).map((type) => ({
                      value: type,
                      label: t(`companies.list.types.${type}`),
                    }))}
                    isMulti={true}
                  />
                  <div>
                    <SearchFilter
                      className="Company-List-Page__Header__Filters__Search ps-5"
                      placeholder={t("filters.search")}
                      name="search"
                      variant="primary"
                    />
                  </div>
                </Row>
              </Col>
            </Row>
          </FormProvider>
        </Card.Header>
        <Card.Body>
          {areCompaniesLoading || isGetCompaniesParamsDeboucing ? (
            <div className="text-center mt-4 mb-4">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <Virtuoso
              className="Company-List-Page__List"
              data={companies}
              atBottomThreshold={420}
              atBottomStateChange={(isAtBottom) => {
                if (isAtBottom && hasNextPage) {
                  fetchNextPage();
                }
              }}
              components={{
                Footer: () => {
                  return (
                    <div className="d-flex justify-content-center">
                      {isFetchingNextPage && (
                        <Spinner
                          animation="border"
                          variant="primary"
                          size="sm"
                        />
                      )}
                    </div>
                  );
                },
              }}
              itemContent={(index, company) => (
                <div key={company.id}>
                  {index !== 0 && <hr />}
                  <CompanyCard key={company.id} company={company} />
                </div>
              )}
            />
          )}
        </Card.Body>
      </Card>
    </Page>
  );
};

export default CompanyListPage;
