import {
  IContractGenerationHook,
  IContractGenerationSubject,
} from "./useContractGeneration";
import useGetSubjectContract from "./useGetSubjectContract";

const useGetSubjectContractParties = (
  subject: IContractGenerationSubject,
  contractGenerationHook: IContractGenerationHook
) => {
  const { parties } = contractGenerationHook;
  const contract = useGetSubjectContract(subject, contractGenerationHook, true);
  return parties?.filter((party) => party.contract === contract?.id);
};

export default useGetSubjectContractParties;
