import { useQueryClient } from "@tanstack/react-query";
import { getModelHyperLink } from "@helpers/model-utils";
import {
  IContractGenerationHook,
  IContractGenerationSubject,
} from "./useContractGeneration";
import useGetSubjectContract from "./useGetSubjectContract";
import { getSubjectType } from "@helpers/contract-utils";

const useIsSubjectContractGenerating = (
  subject: IContractGenerationSubject,
  contractGenerationHook: IContractGenerationHook
): boolean => {
  const queryClient = useQueryClient();
  const contract = useGetSubjectContract(subject, contractGenerationHook);

  const isContractGenerating =
    queryClient.isMutating({
      mutationKey: ["generateContract"],
      exact: true,
      predicate: (mutation) => {
        return mutation.options.variables["contractId"] === contract?.id;
      },
    }) > 0;

  const isPartyCreating =
    queryClient.isMutating({
      mutationKey: ["createParty"],
      exact: true,
      predicate: (mutation) => {
        return (
          mutation.options.variables["party"]?.["subject"] ===
          `/${getModelHyperLink(getSubjectType(subject))}/${subject.subjectId}`
        );
      },
    }) > 0;

  const isReviewerCreating =
    queryClient.isMutating({
      mutationKey: ["createReviewer"],
      exact: true,
      predicate: (mutation) => {
        return (
          mutation.options.variables["reviewer"]?.["contract"] === contract?.id
        );
      },
    }) > 0;

  return isContractGenerating || isPartyCreating || isReviewerCreating;
};

export default useIsSubjectContractGenerating;
