import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import IBill from "@interfaces/IBill";
import BillService from "@services/BillService";

export const useBill = (
  id: number,
  options?: Pick<
    UseQueryOptions<IBill, Error>,
    "enabled" | "onSuccess" | "retry"
  > & {
    refetchOnWindowFocus?: boolean;
  }
) => useQuery(["bills", { id }], () => BillService.getBill(id), options);
