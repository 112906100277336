import { useTranslation } from "react-i18next";
import IClient, { ClientStatus } from "@interfaces/IClient";
import Documents from "@components/Documents/Documents";
import { Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { isFieldRequired } from "@helpers/joi-utils";
import SelectBadges from "@components/SelectBadges/SelectBadges";
import { useStages } from "@hooks/stage/useStages";
import { useClientUpdate } from "@hooks/client/useClientUpdate";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import CustomSelect from "@components/CustomSelect/CustomSelect";

export interface ClientDetailsFormValues {
  stage: number;
  status: ClientStatus;
}

export const clientDetailsValidationSchema = Joi.object({
  stage: Joi.number().allow(null),
  status: Joi.string().required(),
});

const ClientDetailsForm = ({ client }: { client: IClient }) => {
  const { t } = useTranslation();

  const updateClientMutation = useClientUpdate();

  const formMethods = useForm<ClientDetailsFormValues>({
    mode: "onChange",
    resolver: joiResolver(clientDetailsValidationSchema),
    defaultValues: {
      stage: client.stage_number?.id,
      status: client.status,
    },
  });
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const formSubmit = (data: ClientDetailsFormValues) => {
    updateClientMutation.mutate({
      updatedClient: {
        id: client?.id!,
        stage_number: data.stage ?? null,
        status: data.status,
      },
    });
  };

  const { data: stages } = useStages();
  const stageOptions =
    stages
      ?.filter((s) => s.id! !== getValues("stage"))
      .map((s) => ({ value: s.id!, label: s.name! })) ?? [];
  const stageSelectedOption = getValues("stage")
    ? {
        value: getValues("stage"),
        label: stages?.find((s) => s.id === getValues("stage"))?.name,
      }
    : null;

  return (
    <>
      <div className="ps-4 pe-4 pb-4">
        <div className="fw-bold mb-5">
          {t("companies.details.client.details.general")}
        </div>

        <Form.Group className="mb-5">
          <Form.Label>{t("companies.overview.status")}</Form.Label>
          <CustomSelect
            name="status"
            control={control}
            options={Object.values(ClientStatus).map((status) => ({
              value: status,
              label: t(`companies.details.client.statuses.${status}`),
            }))}
            onAfterChange={() => {
              handleSubmit(formSubmit)();
            }}
            isDisabled={!client}
          />
          <FieldErrorMessage
            field={errors.status}
            fieldName={t("companies.overview.status")}
          />
        </Form.Group>

        <Form.Label
          aria-required={isFieldRequired(
            clientDetailsValidationSchema,
            "stage"
          )}
          className="mb-3"
        >
          {t("companies.details.client.details.stage")}
        </Form.Label>
        <Controller
          control={control}
          name="stage"
          render={({ field: { onChange }, fieldState: { error } }) => (
            <>
              <SelectBadges
                className={`select ${!!error ? "select-invalid" : ""}`}
                name="stage"
                value={stageSelectedOption}
                options={stageOptions}
                isClearable={true}
                onChange={(option) => {
                  onChange(option?.value ?? null);
                  handleSubmit(formSubmit)();
                }}
                isDisabled={!client}
              />
              <FieldErrorMessage
                field={error}
                fieldName={t("companies.details.client.details.stage")}
              />
            </>
          )}
        />

        <div className="fw-bold mb-5 mt-6">
          {t("companies.documents.title")}
        </div>
        <Documents client={client} />
      </div>
    </>
  );
};

export default ClientDetailsForm;
