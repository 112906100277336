import { useMutation, useQueryClient } from "@tanstack/react-query";
import SignNowService from "@services/SignNowService";

export const useSignNowRevoke = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ["revokeSignNow"],
    (contractId: number) => SignNowService.revokeSignNow(contractId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["contracts"]);
        queryClient.invalidateQueries(["signatories"]);
        queryClient.invalidateQueries(["signatures"]);
      },
    }
  );
};
