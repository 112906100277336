import { joiResolver } from "@hookform/resolvers/joi";
import { useCallback, useEffect } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AxiosErrorAlert from "@components/AxiosErrorAlert/AxiosErrorAlert";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import WizardModal from "@components/WizardModal/WizardModal";
import { useXpertTermCreate } from "@hooks/term/useXpertTermCreate";
import { useXpertTermUpdate } from "@hooks/term/useXpertTermUpdate";
import IXpertTerm from "@interfaces/terms/IXpertTerm";
import CatalogTermSelectionForm from "@components/CatalogTermSelectionForm/CatalogTermSelectionForm";
import { useCatalogTerms } from "@hooks/term/useCatalogTerms";
import XpertTermForm, {
  MultipleXpertTermsFormValues,
  MultipleXpertTermsSchema,
} from "@components/XpertTermForm/XpertTermForm";
import ICatalogTerm from "@interfaces/terms/ICatalogTerm";

const XpertTermModal = ({
  show,
  xpertId,
  catalogTermToAdd,
  xpertTermToEdit,
  onClose,
}: {
  show: boolean;
  xpertId: number;
  catalogTermToAdd?: ICatalogTerm;
  xpertTermToEdit?: IXpertTerm;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  const createXpertTermMutation = useXpertTermCreate();
  const updateXpertTermMutation = useXpertTermUpdate();

  const formMethods = useForm<MultipleXpertTermsFormValues>({
    resolver: joiResolver(MultipleXpertTermsSchema),
    mode: "onChange",
  });

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = formMethods;

  const {
    fields,
    remove: removeFromXpertTerms,
    append: appendToXpertTerms,
    replace: replaceXpertTerms,
  } = useFieldArray({
    control,
    name: "xpert_terms",
  });

  const saveXpertTerm = (data: MultipleXpertTermsFormValues) => {
    if (xpertTermToEdit) {
      const xpertTerm = data.xpert_terms[0];
      updateXpertTermMutation.mutate(
        {
          xpertTerm: {
            id: xpertTermToEdit!.id!,
            sub_domain: xpertTerm.sub_domain,
            stages: xpertTerm.stages,
            duration: xpertTerm.duration!,
            cost_per_sprint: xpertTerm.cost_per_sprint!,
            currency: xpertTerm.currency!,
            description: xpertTerm.description,
          },
        },
        {
          onSuccess: () => {
            handleClose();
          },
        }
      );
    } else {
      Promise.all(
        data.xpert_terms.map((xpertTerm) => {
          const catalogTerm = catalogTerms.find(
            (catalogTerm) => catalogTerm.id === xpertTerm.catalog_term
          );

          return createXpertTermMutation.mutate({
            xpert_involved: xpertId,
            stages: xpertTerm.stages!,
            duration: xpertTerm.duration!,
            cost_per_sprint: xpertTerm.cost_per_sprint!,
            currency: xpertTerm.currency!,
            title: catalogTerm?.title,
            description: xpertTerm?.description,
            sub_domain: xpertTerm?.sub_domain,
            catalog_term: xpertTerm.catalog_term,
          });
        })
      );

      handleClose();
    }
  };

  const handleClose = () => {
    createXpertTermMutation.reset();
    reset();
    onClose();
  };

  const handleFinish = () => {
    handleSubmit(saveXpertTerm)();
  };

  const { data: catalogTermInfiniteData } = useCatalogTerms(
    {
      id__in: (getValues("xpert_terms") || [])
        .map((xpertTerm) => xpertTerm.catalog_term!)
        .join(","),
      page_size: (getValues("xpert_terms") || []).length,
    },
    {
      enabled:
        (getValues("xpert_terms") || []).map(
          (xpertTerm) => xpertTerm.catalog_term!
        ).length > 0,
    }
  );
  const catalogTerms = catalogTermInfiniteData
    ? catalogTermInfiniteData.pages.flatMap((p) => p.results)
    : [];

  const resetForm = useCallback(() => {
    reset({
      xpert_terms: [],
      catalog_terms: [],
    });
  }, [reset]);

  useEffect(() => {
    if (show) resetForm();
    if (xpertTermToEdit) {
      setValue("catalog_terms", [xpertTermToEdit.catalog_term!]);
      replaceXpertTerms([
        {
          xpert_involved: xpertTermToEdit?.xpert_involved,
          stages: xpertTermToEdit?.stages?.map((stage) => stage.id!),
          duration: xpertTermToEdit?.duration,
          cost_per_sprint: xpertTermToEdit?.cost_per_sprint,
          currency: xpertTermToEdit?.currency,
          catalog_term: xpertTermToEdit?.catalog_term,
          sub_domain: xpertTermToEdit?.sub_domain?.id,
          description: xpertTermToEdit?.description,
        },
      ]);
    }
    if (catalogTermToAdd) {
      setValue("catalog_terms", [catalogTermToAdd.id!]);
      replaceXpertTerms([
        {
          xpert_involved: xpertId,
          stages: [],
          duration: null,
          cost_per_sprint: null,
          currency: null,
          catalog_term: catalogTermToAdd?.id,
          sub_domain: catalogTermToAdd?.sub_domain?.id,
          description: catalogTermToAdd?.description,
        },
      ]);
    }
  }, [
    show,
    xpertTermToEdit,
    resetForm,
    setValue,
    replaceXpertTerms,
    catalogTermToAdd,
    xpertId,
  ]);

  const updateXpertTerms = (catalog_terms: ICatalogTerm[]) => {
    const xpertTermsToRemove = getValues("xpert_terms").reduce(
      (acc, curr, index) => {
        if (!catalog_terms.map((ct) => ct.id).includes(curr.catalog_term!)) {
          acc.push(index);
        }
        return acc;
      },
      [] as number[]
    );

    for (const xpertTermToRemove of xpertTermsToRemove) {
      removeFromXpertTerms(xpertTermToRemove);
    }

    const catalogTermsToAdd = catalog_terms.filter((catalog_term) => {
      return !getValues("xpert_terms").some(
        (xpert_term) => xpert_term.catalog_term === catalog_term.id
      );
    });

    for (const catalogTermToAdd of catalogTermsToAdd) {
      appendToXpertTerms({
        xpert_involved: xpertId,
        stages: [],
        duration: null,
        cost_per_sprint: null,
        currency: null,
        catalog_term: catalogTermToAdd.id,
        sub_domain: catalogTermToAdd.sub_domain?.id,
        description: catalogTermToAdd.description,
      });
    }
  };

  return (
    <FormProvider {...formMethods}>
      <WizardModal
        show={show}
        startingStep={catalogTermToAdd || xpertTermToEdit ? 1 : 0}
        onClose={handleClose}
        onFinish={handleFinish}
        steps={[
          {
            title: t(
              "companies.details.xpert.terms.existing_term.action_label"
            ),
            content: (
              <>
                <h3 className="mb-6">
                  {t("companies.details.xpert.terms.existing_term.title")}
                </h3>

                <CatalogTermSelectionForm
                  name="catalog_terms"
                  control={control}
                  fixedGetCatalogTermsParams={{
                    xpert_terms__xpert_involved__not__in: xpertId.toString(),
                  }}
                  extraFixedGetXpertsParams={{
                    id__not__in: xpertId.toString(),
                  }}
                  onChange={(catalog_terms) => {
                    updateXpertTerms(catalog_terms);
                  }}
                />
                <FieldErrorMessage
                  field={errors?.xpert_terms}
                  className="mt-6"
                  fieldName={t("companies.details.xpert.terms.fields.terms")}
                />
              </>
            ),
            isCompleted:
              (getValues("catalog_terms") || []).length > 0 &&
              !errors.xpert_terms,
          },
          {
            title: t(
              "companies.details.xpert.terms.existing_term.action_label"
            ),
            content: (
              <>
                {fields.map((xpertTerm, index) => {
                  const catalogTerm = catalogTerms.find(
                    (catalogTerm) => catalogTerm.id === xpertTerm.catalog_term
                  );

                  return (
                    <XpertTermForm
                      key={`xpert_term_for_${xpertTerm.catalog_term}`}
                      className={index > 0 ? "mt-6" : ""}
                      title={catalogTerm?.title}
                      fieldIndex={index}
                    />
                  );
                })}

                <AxiosErrorAlert
                  response={
                    createXpertTermMutation.error ||
                    updateXpertTermMutation.error
                  }
                  translationPrefix="companies.details.xpert.terms.fields."
                />
              </>
            ),
            disableGoBack: !!xpertTermToEdit,
            isCompleted: true,
          },
        ]}
      />
    </FormProvider>
  );
};

export default XpertTermModal;
