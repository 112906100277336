import StepCard from "@components/StepCard/StepCard";
import IXchange from "@interfaces/IXchange";
import BillTable from "@components/BillTable/BillTable";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import BillFilters from "@components/BillFilters/BillFilters";
import { useDebounce } from "@hooks/useDebounce";
import { IGetBillsParams } from "@services/BillService";

const XchangeBillingHistory = ({ xchange }: { xchange?: IXchange }) => {
  const { t } = useTranslation();

  const [getBillsParams, setGetBillsParams] = useState<IGetBillsParams>({});
  const [getBillsSearchParam, setBillsSearchParam] = useState<string>();
  const {
    debouncedValue: debouncedGetBillsSearchParam,
    isDebouncing: isGetBillsSearchParamDebouncing,
  } = useDebounce(getBillsSearchParam, 400);

  const handleFilterChange = (filters: IGetBillsParams) => {
    setGetBillsParams({
      ...filters,
      search: undefined,
    });
    setBillsSearchParam(filters.search);
  };

  return (
    <StepCard
      title={`${t("billing.history.title")} ${t("billing.history.of")} ${
        xchange?.name
      }`}
    >
      <BillFilters
        xchange={xchange}
        handleFilterChange={handleFilterChange}
        className="ps-4 pe-4 pb-6"
      />

      <div className="ps-4 pe-4">
        <BillTable
          xchange={xchange}
          areExtraFixedGetBillsParamsDebouncing={
            isGetBillsSearchParamDebouncing
          }
          extraFixedGetBillsParams={{
            ...getBillsParams,
            search: debouncedGetBillsSearchParam,
          }}
        />
      </div>
    </StepCard>
  );
};

export default XchangeBillingHistory;
