import React, { useEffect, useState } from "react";
import { Modal, Button, Container, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface IWizardModalSteps {
  title: string;
  content: React.ReactNode;
  isCompleted?: boolean;
  isLoading?: boolean;
  onEnter?: Function;
  onFinish?: (callback?: Function) => void;
  onFinishText?: string;
  onCloseShouldBeOnFinish?: boolean;
  hideStepNumber?: boolean;
  disableGoBack?: boolean;
  extraButtons?: React.ReactNode;
  skip?: boolean;
}

const WizardModal = (props: {
  show: boolean;
  startingStep?: number;
  onClose: (activeStep?: number) => void;
  onCloseText?: string;
  onFinish: Function;
  onFinishText?: string;
  steps: IWizardModalSteps[];
}) => {
  const { t } = useTranslation();

  const [activeStep, setActiveStep] = useState(0);

  const curratedSteps = props.steps.filter((step) => !step.skip);

  useEffect(() => {
    setActiveStep(props.startingStep ?? 0);
    curratedSteps[props.startingStep ?? 0].onEnter?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.startingStep, props.show]);

  const previousStep = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
      curratedSteps[activeStep - 1].onEnter?.();
    }
  };

  const nextStep = () => {
    if (activeStep < curratedSteps.length - 1) {
      if (curratedSteps[activeStep].onFinish?.length === 1) {
        curratedSteps[activeStep].onFinish?.(() => {
          setActiveStep(activeStep + 1);
          curratedSteps[activeStep + 1].onEnter?.();
        });
      } else {
        curratedSteps[activeStep].onFinish?.();
        setActiveStep(activeStep + 1);
        curratedSteps[activeStep + 1].onEnter?.();
      }
    }
  };

  const finish = () => {
    curratedSteps[activeStep].onFinish?.();
    props.onFinish();
  };

  const close = (activeStep?: number) => {
    props.onClose(activeStep);
    setActiveStep(0);
  };

  return (
    <Modal show={props.show} size="lg" centered enforceFocus={false}>
      <Modal.Header className="text-uppercase">
        <div>
          {curratedSteps[activeStep].title}
          {!curratedSteps[activeStep].hideStepNumber && (
            <span className="ms-2 text-muted">
              {activeStep + 1} / {curratedSteps.length}
            </span>
          )}
        </div>

        <Button
          variant="outline-light"
          size="sm"
          onClick={() =>
            curratedSteps[activeStep].onCloseShouldBeOnFinish
              ? finish()
              : close(activeStep)
          }
        >
          <i className="fa-regular fa-times" />
        </Button>
      </Modal.Header>
      <Modal.Body>
        {activeStep !== 0 && !curratedSteps[activeStep].disableGoBack && (
          <Button
            onClick={() => previousStep()}
            variant="outline-light"
            disabled={activeStep === 0}
            size="sm"
            className="mb-5"
          >
            <i className="fa-light fa-arrow-left me-2" />
            {t("wizard.back")}
          </Button>
        )}
        {curratedSteps[activeStep].content}
      </Modal.Body>
      <Modal.Footer>
        <Container className="gx-0">
          <Stack direction="horizontal" gap={3}>
            {!curratedSteps[activeStep].disableGoBack && (
              <Button onClick={() => close(activeStep)} variant="outline-light">
                {props.onCloseText ?? t("wizard.close")}
              </Button>
            )}

            {curratedSteps[activeStep].extraButtons}

            {activeStep !== curratedSteps.length - 1 ? (
              <Button
                onClick={() => nextStep()}
                className="ms-auto"
                disabled={
                  !curratedSteps[activeStep].isCompleted ||
                  curratedSteps[activeStep].isLoading
                }
              >
                {curratedSteps[activeStep].isLoading ? (
                  <i className="fa-regular fa-spinner-third fa-spin" />
                ) : (
                  <>
                    {curratedSteps[activeStep].onFinishText ??
                      t("wizard.continue")}
                    <i className="fa-solid fa-arrow-right ms-2" />
                  </>
                )}
              </Button>
            ) : (
              <Button
                onClick={() => finish()}
                className="ms-auto"
                disabled={!curratedSteps[activeStep].isCompleted}
              >
                {props.onFinishText ?? t("wizard.finish")}
              </Button>
            )}
          </Stack>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};

export default WizardModal;
