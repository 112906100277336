import { useClientTeamMemberCreate } from "@hooks/client/useClientTeamMemberCreate";
import IPerson from "@interfaces/IPerson";

export const useAddPeopleToClientTeam = (clientId: number | undefined) => {
  const createClientTeamMemberMutation = useClientTeamMemberCreate();

  const mutate = (people: IPerson[]) => {
    if (!clientId) {
      return;
    }

    const promises = people.map((person) =>
      createClientTeamMemberMutation.mutate({
        clientId: clientId,
        clientTeamMember: {
          person: person.id,
          client: clientId,
          rate: person.rate,
          currency: person.currency,
          roles: person.roles,
        },
      })
    );

    return Promise.all(promises);
  };

  return {
    mutate,
  };
};
