import { useMutation, useQueryClient } from "@tanstack/react-query";
import InvoiceService from "@services/InvoiceService";
import { IInvoiceLineItemWrite } from "@interfaces/IInvoice";

interface MutationVars {
  invoiceId: number;
  external_bill: number;
  data: IInvoiceLineItemWrite;
}

export const useInvoiceLineItemCreate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ invoiceId, external_bill, data }: MutationVars) =>
      InvoiceService.createLineItem(invoiceId, external_bill, data),
    {
      onSuccess: (_, { invoiceId }) => {
        queryClient.invalidateQueries(["invoices", { id: invoiceId }]);
      },
    }
  );
};
