import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useElementAnchoring = (beforeScrollInto?: (hash: string) => void) => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      if (beforeScrollInto) {
        beforeScrollInto(hash);
      }

      setTimeout(() => {
        const elementId = hash.replace("#", "");

        if (elementId) {
          const element = document.getElementById(elementId);
          if (element) {
            element.classList.add("anchored");
            element.scrollIntoView({ behavior: "smooth" });

            setTimeout(() => {
              element.classList.remove("anchored");
            }, 1150);
          }
        }
      }, 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);
};

export default useElementAnchoring;
