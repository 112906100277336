import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { IClientTeamMember } from "@interfaces/IClient";
import ClientService from "@services/ClientService";

export const useClientTeamMembers = (
  clientId: number,
  options?: Pick<
    UseQueryOptions<IClientTeamMember[], Error>,
    "enabled" | "onSuccess"
  > & {
    refetchOnWindowFocus?: boolean;
  }
) =>
  useQuery(
    ["clients", { clientId }, "teams"],
    () => ClientService.getClientTeamMembers(clientId),
    options
  );
