import React from "react";
import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import XchangeStatusBadge from "@components/XchangeStatusBadge/XchangeStatusBadge";
import { formatDateToString } from "@helpers/date-utils";
import IXchange from "@interfaces/IXchange";
import IXchangeProduct from "@interfaces/products/IXchangeProduct";
import IXchangeTerm from "@interfaces/terms/IXchangeTerm";
import "./XchangeTableRow.scss";

const XchangeTableRow = ({
  companyClientId,
  companyXpertId,
  xchange,
}: {
  companyClientId?: number;
  companyXpertId?: number;
  xchange: IXchange;
}) => {
  const { t } = useTranslation();

  const xchangeXperts =
    xchange.xchange_terms
      ?.map((term: IXchangeTerm) => term.xpert_involved)
      .concat(
        xchange.xchange_products
          ?.map((product: IXchangeProduct) =>
            product.terms?.map((term: IXchangeTerm) => term.xpert_involved)
          )
          .flat()
      ) || [];

  return (
    <tr key={xchange.id}>
      <td>{xchange.name}</td>
      <td>
        <>
          {companyClientId === xchange.client?.id && (
            <Badge
              pill
              className="Xchange-Table-Row__Client-Badge me-2 mb-2 border"
              bg="light"
            >
              {t("companies.details.overview.xchanges.types.client")}
            </Badge>
          )}

          {xchangeXperts.includes(companyXpertId) && (
            <Badge
              pill
              className="Xchange-Table-Row__Xpert-Badge me-2 mb-2 border"
              bg="light"
            >
              {t("companies.details.overview.xchanges.types.xpert")}
            </Badge>
          )}
        </>
      </td>
      <td>
        {(xchange.start_date || xchange.goal_end_date) && (
          <>
            {xchange.start_date
              ? formatDateToString(new Date(xchange.start_date), {
                  dateStyle: "medium",
                })
              : t("unknown")}
            {" - "}
            {xchange.goal_end_date
              ? formatDateToString(new Date(xchange.goal_end_date), {
                  dateStyle: "medium",
                })
              : t("unknown")}
          </>
        )}
      </td>
      <td>
        <XchangeStatusBadge status={xchange.status} />
      </td>
    </tr>
  );
};

export default XchangeTableRow;
