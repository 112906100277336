import { useTranslation } from "react-i18next";
import XchangeStatusBadge from "@components/XchangeStatusBadge/XchangeStatusBadge";
import IXchangeTerm from "@interfaces/terms/IXchangeTerm";
import { toCurrencyString } from "@helpers/currencyUtils";
import { Link } from "react-router-dom";
import "./XchangeTermTableRow.scss";

const XchangeTermTableRow = ({
  xchangeTerm,
}: {
  xchangeTerm: IXchangeTerm;
}) => {
  const { t } = useTranslation();

  return (
    <tr>
      <td>
        <Link to={`/xchanges/${xchangeTerm.xchange?.id}`}>
          {xchangeTerm.xchange?.name}
        </Link>
      </td>
      <td>
        {xchangeTerm.duration! * xchangeTerm.multiplier!}
        {` ${t("durations.weeks", {
          count: xchangeTerm.duration! * xchangeTerm.multiplier!,
        })}`}
      </td>
      <td>
        <XchangeStatusBadge status={xchangeTerm.xchange?.status} />
      </td>
      <td>{xchangeTerm.xpert_involved_name}</td>
      <td>
        <span className="Xchange-Term-Table-Row__Currency">
          {xchangeTerm.currency + " "}
        </span>
        {toCurrencyString(
          xchangeTerm.cost_per_sprint! * xchangeTerm.multiplier!
        )}
      </td>
    </tr>
  );
};

export default XchangeTermTableRow;
