import { useCallback, useState } from "react";
import { Button, Stack } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { bytesToString } from "@helpers/bytes-utils";
import "./FileDropInput.scss";

const FileDropInput = ({
  className = "",
  maxFiles = 0,
  onChange,
  variant = "file",
  disabled = false,
  imageSrc,

  removeBtnLabel,
  removeBtnOnClick,
  removeBtnDisabled,
}: {
  className?: string;
  maxFiles?: number;
  onChange: (files: File[]) => void;
  variant?: "image" | "file";
  disabled?: boolean;
  imageSrc?: string;

  removeBtnLabel?: string;
  removeBtnOnClick?: () => void;
  removeBtnDisabled?: boolean;
}) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<File[]>([]);

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      if (variant === "image") {
        setFiles([acceptedFiles[0]]);
        onChange([acceptedFiles[0]]);
      } else {
        setFiles([...files, ...acceptedFiles]);
        onChange([...files, ...acceptedFiles]);
      }
    },
    [files, variant, onChange]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles,
    multiple: maxFiles !== 1,
    disabled,
    accept: variant === "image" ? { "image/*": [] } : undefined,
  });

  return (
    <div className="File-Drop-Input">
      {variant === "image" && (
        <div className="File-Drop-Input__Image">
          {imageSrc && (
            <img
              src={imageSrc}
              alt={t(
                "companies.details.overview.general_details.labels.company_logo"
              )}
            />
          )}
          {!imageSrc && (
            <div className="text-center">
              <i className="fa-regular fa-image"></i>
            </div>
          )}
        </div>
      )}

      <div className="File-Drop-Input__Form">
        {files.length === 0 ||
        files.length < maxFiles ||
        variant === "image" ? (
          <div
            className={`File-Drop-Input__Zone w-100 ${
              (files.length > 0 || variant === "image") &&
              "File-Drop-Input__Zone--small"
            } ${disabled && "File-Drop-Input__Zone--disabled"} ${className}`}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <div>
              <span className="File-Drop-Input__Zone--bold">
                {t("file.drag_here")}
              </span>
              {t("file.or")}
              <Button className="ms-4" disabled={disabled}>
                <i className="fa-regular fa-cloud-arrow-up me-2"></i>
                {t("file.upload")}
              </Button>
            </div>
          </div>
        ) : (
          <></>
        )}

        {files.length > 0 && variant === "file" && (
          <div className="mt-5">
            {files.map((file, index) => (
              <div className="File-Drop-Input__File" key={index}>
                <Stack direction="horizontal" gap={3}>
                  {variant === "file" && (
                    <i className="fa-solid fa-file File-Drop-Input__File__Icon"></i>
                  )}
                  <div className="File-Drop-Input__File__Name">{file.name}</div>
                  <div className="File-Drop-Input__File__Size">
                    {bytesToString(file.size)}
                  </div>
                  {!disabled && (
                    <i
                      className="fa-regular fa-circle-xmark ms-auto File-Drop-Input__File__Remove"
                      onClick={() => {
                        setFiles(files.filter((_, i) => i !== index));
                        onChange(files.filter((_, i) => i !== index));
                      }}
                    ></i>
                  )}
                </Stack>
              </div>
            ))}
          </div>
        )}

        {removeBtnOnClick && (
          <Button
            variant="outline-light"
            size="sm"
            className="w-100 mt-2"
            disabled={removeBtnDisabled}
            onClick={removeBtnOnClick}
          >
            <i className="fa-solid fa-trash me-2" />
            {removeBtnLabel}
          </Button>
        )}
      </div>
    </div>
  );
};

export default FileDropInput;
