import { Form, FormCheckProps } from "react-bootstrap";
import "./IconCheckbox.scss";

const IconCheckbox = (
  props: {
    label: string;
    subLabel?: string;
    iconClasses: string;
    color: string;
    className?: string;
  } & FormCheckProps
) => {
  return (
    <div
      className={`Icon-Checkbox ${props.checked && "Icon-Checkbox--checked"} ${
        props.className
      }`}
      onClick={props.onClick}
    >
      <Form.Check type="checkbox" {...props} className="d-none" readOnly />
      <div
        className={`Icon-Checkbox__Checkmark ${
          props.checked && "Icon-Checkbox__Checkmark--checked"
        }`}
      >
        {props.checked ? (
          <i className="fa-solid fa-circle-check" />
        ) : (
          <i className="fa-light fa-circle" />
        )}
      </div>

      <div
        className="Icon-Checkbox__Icon"
        style={{
          backgroundColor: `${props.color}20`,
        }}
      >
        <i className={props.iconClasses} style={{ color: props.color }} />
      </div>
      <div className="Icon-Checkbox__Label">
        {props.label}
        {props.subLabel && (
          <div className="Icon-Checkbox__Label__Sub">{props.subLabel}</div>
        )}
      </div>
    </div>
  );
};

export default IconCheckbox;
