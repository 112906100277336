import IInvoice, {
  IInvoiceLineItem,
  IInvoiceLineItemWrite,
  IInvoiceWrite,
} from "@interfaces/IInvoice";
import Service from "@services/Service";

export default class InvoiceService extends Service {
  public static async getInvoice(invoiceId: number) {
    const response = await this.axios.get<IInvoice>(`/invoices/${invoiceId}`);
    return response.data;
  }

  public static async createInvoice(
    xchangeId: number,
    externalBills: number[]
  ) {
    const response = await this.axios.post<IInvoice>(`/invoices`, {
      external_bills: externalBills,
      xchange: xchangeId,
    });
    return response.data;
  }

  public static async updateInvoice(invoiceId: number, data: IInvoiceWrite) {
    const response = await this.axios.patch<IInvoice>(
      `/invoices/${invoiceId}`,
      data
    );
    return response.data;
  }

  public static async createLineItem(
    invoiceId: number,
    external_bill: number,
    data: IInvoiceLineItemWrite
  ) {
    const response = await this.axios.post<IInvoiceLineItem>(
      `/invoices/${invoiceId}/line-items`,
      {
        external_bill,
        ...data,
      }
    );
    return response.data;
  }

  public static async exportInvoice(
    invoiceId: number,
    type: "simple" | "detailed"
  ) {
    const response = await this.axios.post(
      `/invoices/${invoiceId}/external-invoice`,
      {
        type: type,
      }
    );
    return response.data;
  }

  public static async updateLineItem(
    invoiceId: number,
    lineItemId: number,
    data: IInvoiceLineItemWrite
  ) {
    const response = await this.axios.patch<IInvoiceLineItem>(
      `/invoices/${invoiceId}/line-items/${lineItemId}`,
      data
    );
    return response.data;
  }

  public static async deleteLineItem(invoiceId: number, lineItemId: number) {
    await this.axios.delete<{}>(
      `/invoices/${invoiceId}/line-items/${lineItemId}`
    );
  }
}
