import { useMutation, useQueryClient } from "@tanstack/react-query";
import InvoiceService from "@services/InvoiceService";

interface MutationVars {
  invoiceId: number;
  type: "simple" | "detailed";
}

export const useInvoiceExport = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ invoiceId, type }: MutationVars) =>
      InvoiceService.exportInvoice(invoiceId, type),
    {
      onSuccess: (_, { invoiceId }) => {
        queryClient.invalidateQueries(["invoices", { id: invoiceId }]);
      },
    }
  );
};
