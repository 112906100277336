import Joi from "joi";
import { useCallback, useEffect, useState } from "react";
import { Col, Form, Row, Stack } from "react-bootstrap";
import { FieldError, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomDateInput from "@components/CustomDateInput/CustomDateInput";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import { useEpics } from "@hooks/xchange/useEpics";
import useXchangeGoalEndDateAutoUpdate from "@hooks/xchange/useXchangeGoalEndDateAutoUpdate";
import { useXchangeUpdate } from "@hooks/xchange/useXchangeUpdate";
import IEpic from "@interfaces/IEpic";
import IXchange from "@interfaces/IXchange";

export interface FormValues {
  start_date?: Date | null;
  end_date?: Date | null;
  was_goal_end_date_set_by_user?: boolean;
}

export const xchangeScheduleSchema = Joi.object({
  start_date: Joi.date().raw().allow("").allow(null),
  end_date: Joi.date().raw().allow("").allow(null),
  was_goal_end_date_set_by_user: Joi.boolean(),
});

const XchangeSprintSchedule = ({ xchange }: { xchange?: IXchange }) => {
  const { t } = useTranslation();

  const formMethods = useForm<FormValues>({
    defaultValues: {
      start_date: xchange?.start_date ? new Date(xchange.start_date) : null,
      end_date: xchange?.goal_end_date ? new Date(xchange.goal_end_date) : null,
      was_goal_end_date_set_by_user: xchange?.was_goal_end_date_set_by_user,
    },
  });
  const {
    getValues,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = formMethods;

  const { data: epics } = useEpics(xchange?.id!, { enabled: !!xchange?.id });

  const [isEndDateTooSoon, setIsEndDateTooSoon] = useState(false);

  const updateXchangeMutation = useXchangeUpdate();
  const xchangeGoalEndDateAutoUpdate = useXchangeGoalEndDateAutoUpdate();
  const formSubmit = (data: FormValues) => {
    updateXchangeMutation.mutate(
      {
        updatedXchange: {
          id: xchange?.id!,
          start_date: data.start_date?.toISOString(),
          goal_end_date: data.end_date?.toISOString(),
          was_goal_end_date_set_by_user: data.was_goal_end_date_set_by_user,
        },
      },
      {
        onSuccess: (updatedXchange) => {
          xchangeGoalEndDateAutoUpdate.mutate(updatedXchange);
        },
      }
    );
  };

  const checkEndDateValidity = useCallback(() => {
    if (!epics || !getValues("end_date")) {
      return false;
    }
    const minimumEndDate = new Date(getValues("start_date")!);
    const epicsDuration = epics.reduce(
      (acc: number, curr: IEpic) => acc + curr.duration!,
      0
    );
    minimumEndDate.setDate(minimumEndDate.getDate() + epicsDuration);
    const isTooSoon = getValues("end_date")! < minimumEndDate;
    return isTooSoon;
  }, [epics, getValues]);

  useEffect(() => {
    setIsEndDateTooSoon(checkEndDateValidity());
  }, [checkEndDateValidity]);

  useEffect(() => {
    if (
      xchange?.goal_end_date &&
      new Date(xchange.goal_end_date) !== watch("end_date")
    ) {
      setValue("end_date", new Date(xchange.goal_end_date));
      setIsEndDateTooSoon(checkEndDateValidity());
    }
  }, [xchange, watch, setValue, checkEndDateValidity]);

  return (
    <>
      <FormProvider {...formMethods}>
        <Form className="pt-7 pb-7">
          <Stack direction="horizontal" gap={3} className="mb-5">
            <h5 className="mb-0">
              {t("form.xchange_edit.sub_titles.timeline")}
            </h5>
          </Stack>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  {t("form.xchange_edit.form_labels.start_date")}
                </Form.Label>
                <CustomDateInput
                  name="start_date"
                  placeholderText={t(
                    "form.xchange_edit.form_labels.start_date"
                  )}
                  maxDate={watch("end_date") ?? undefined}
                  isInvalid={!!errors.start_date}
                  onAfterChange={() => {
                    checkEndDateValidity();
                    handleSubmit(formSubmit)();
                  }}
                />
                <FieldErrorMessage
                  field={errors.start_date}
                  fieldName={t("form.xchange_edit.form_labels.start_date")}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  {t("form.xchange_edit.form_labels.est_end_date")}
                </Form.Label>
                <CustomDateInput
                  name="end_date"
                  placeholderText={t(
                    "form.xchange_edit.form_labels.est_end_date"
                  )}
                  minDate={watch("start_date") ?? undefined}
                  isInvalid={!!errors.end_date || isEndDateTooSoon}
                  onAfterChange={() => {
                    setValue("was_goal_end_date_set_by_user", true);
                    const isTooSoon = checkEndDateValidity();
                    setIsEndDateTooSoon(isTooSoon);
                    if (!isTooSoon) handleSubmit(formSubmit)();
                  }}
                />
                <FieldErrorMessage
                  field={
                    errors.end_date ||
                    (isEndDateTooSoon
                      ? ({
                          type: "custom.end_date_epics_invalid",
                        } as FieldError)
                      : undefined)
                  }
                  fieldName={t("form.xchange_edit.form_labels.est_end_date")}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </>
  );
};

export default XchangeSprintSchedule;
