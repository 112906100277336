import IXeroInvoice, {
  IXeroInvoiceAttachment,
  IXeroInvoiceLineItem,
} from "@interfaces/IXeroInvoice";
import BillService from "@services/BillService";
import { downloadBlobFile, openBlobFile } from "@helpers/file-utilts";
import IBill from "@interfaces/IBill";

export const donwloadBillAttachment = async (
  billId: number,
  attachment: IXeroInvoiceAttachment
) => {
  const bytes = await BillService.getBillAttachment(
    billId,
    attachment.attachment_id!
  );

  downloadBlobFile(bytes, attachment.file_name!, attachment.content_type!);
};

export const openBillAttachment = async (
  billId: number,
  attachment: IXeroInvoiceAttachment
) => {
  const bytes = await BillService.getBillAttachment(
    billId,
    attachment.attachment_id!
  );

  openBlobFile(bytes, attachment.content_type!);
};

export const getBillLineItemRate = (
  xeroInvoice: IXeroInvoice,
  lineItem: IXeroInvoiceLineItem
) => {
  if (xeroInvoice.line_amount_types === "Inclusive") {
    return lineItem.unit_amount! - lineItem.tax_amount!;
  } else {
    return lineItem.unit_amount!;
  }
};

export const getBillLineItemTotal = (
  xeroInvoice: IXeroInvoice,
  lineItem: IXeroInvoiceLineItem
) => {
  if (xeroInvoice.line_amount_types === "Inclusive") {
    return lineItem.line_amount! - lineItem.tax_amount!;
  } else {
    return lineItem.line_amount!;
  }
};

export const getBillsCurrencies = (bills: IBill[]) => {
  const currencies = bills.map((bill) => bill.xero_invoice!.currency_code!);
  return [...new Set(currencies)];
};
