import { IXchangeTeamMember } from "@interfaces/IXchangeTeamMember";
import "./XchangeMember.scss";
import { Badge, Button } from "react-bootstrap";
import { useMemo, useState } from "react";
import ActionsDropdown from "@components/ListOptionsDropdown/ActionsDropdown";
import { useTranslation } from "react-i18next";

const XchangeMember = ({
  xchangeTeamMember,
  onEdit,
  onDelete,
}: {
  xchangeTeamMember: IXchangeTeamMember;
  onEdit: () => void;
  onDelete: () => void;
}) => {
  const { t } = useTranslation();

  const isMemberEmailValid = useMemo(() => {
    return xchangeTeamMember.email === xchangeTeamMember.person?.email;
  }, [xchangeTeamMember]);

  const [contextualMenuOpened, setContextualMenuOpened] = useState(false);

  return (
    <>
      <td>{xchangeTeamMember.xpert!.name}</td>
      <td>
        {xchangeTeamMember.roles?.map((role) => (
          <Badge
            key={`xchange_member_role_${role.id}`}
            pill
            bg="light"
            className="Xchange-Member__Badge me-2 border"
          >
            {role.name}
          </Badge>
        ))}
      </td>
      <td>
        <div>
          {`${xchangeTeamMember.person?.first_name} ${xchangeTeamMember.person?.last_name}`}
        </div>
        <p className="helper-text mb-0">{xchangeTeamMember.title}</p>
      </td>
      <td>
        <div
          className={`${!isMemberEmailValid && "text-decoration-line-through"}`}
        >
          {xchangeTeamMember.email}
        </div>
        {!isMemberEmailValid && <div>{xchangeTeamMember.person?.email!}</div>}
      </td>
      <td
        className="table-actions"
        style={{
          zIndex: contextualMenuOpened ? 1 : 0,
        }}
      >
        <ActionsDropdown
          actions={[
            {
              label: t("teams.edit.button"),
              onClick: () => onEdit(),
            },
            {
              label: t("teams.remove.button"),
              onClick: () => onDelete(),
            },
          ]}
          drop="down"
          boundary={document.querySelector("#xchange-members-table")}
          popperStrategy="absolute"
          onToggle={() => {
            setContextualMenuOpened(!contextualMenuOpened);
          }}
        >
          <Button variant="outline-light" className="p-2 pt-1 pb-1">
            <i className="fa-regular fa-ellipsis-vertical"></i>
          </Button>
        </ActionsDropdown>
      </td>
    </>
  );
};

export default XchangeMember;
