import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Bar,
  Tooltip,
} from "recharts";
import "./XchangeBillingSummaryChart.scss";
import {
  toCurrencyShortString,
  toCurrencyString,
} from "@helpers/currencyUtils";
import { useTranslation } from "react-i18next";

const CustomTooltip = ({
  payload,
  label,
  active,
}: {
  payload?: any[];
  label?: string;
  active?: boolean;
}) => {
  if (active) {
    return (
      <div className="Xchange-Billing-Summary-Chart__Tooltip">
        <div className="Xchange-Billing-Summary-Chart__Tooltip__Label">
          {label}
        </div>
        {(payload || []).map((payload, index) => (
          <div
            className="Xchange-Billing-Summary-Chart__Tooltip__Value"
            key={`billing_summary_chart_tooltip_${index}`}
          >
            <div
              className="Xchange-Billing-Summary-Chart__Legends__Legend__Color"
              style={{
                backgroundColor: payload.color,
              }}
            />
            <span>
              {payload.name}: {toCurrencyString(payload.value, "USD", 2)}
            </span>
          </div>
        ))}
      </div>
    );
  }

  return null;
};

const CustomLegend = (props: any) => {
  const { payload } = props;

  return (
    <div className="Xchange-Billing-Summary-Chart__Legends">
      {payload.map(
        (
          entry: {
            color: string;
            value: string;
          },
          index: number
        ) => (
          <div
            key={`item-${index}`}
            className="Xchange-Billing-Summary-Chart__Legends__Legend"
          >
            <div
              className="Xchange-Billing-Summary-Chart__Legends__Legend__Color"
              style={{
                backgroundColor: entry.color,
              }}
            />
            {entry.value}
          </div>
        )
      )}
    </div>
  );
};

export interface IXchangeBillingSummaryChartData {
  name: string;
  spent: number;
  remaining: number;
  over: number;
}

const XchangeBillingSummaryChart = ({
  data,
  className,
}: {
  data: IXchangeBillingSummaryChartData[];
  className?: string;
}) => {
  const { t } = useTranslation();

  return (
    <div className={`Xchange-Billing-Summary-Chart ${className}`}>
      <div className="Xchange-Billing-Summary-Chart__Container">
        <ResponsiveContainer>
          <BarChart
            data={data}
            margin={{
              top: 40,
              right: 30,
              left: 30,
              bottom: 20,
            }}
          >
            <CartesianGrid vertical={false} stroke="#E6E3EE" />
            <XAxis
              dataKey="name"
              tickLine={false}
              axisLine={{ stroke: "#E6E3EE" }}
              tick={{ fontSize: 12, fontWeight: 700, fill: "#6F6C83" }}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              tickFormatter={(value) => {
                return toCurrencyShortString(value);
              }}
              tick={{ fontSize: 12, fontWeight: 700, fill: "#6F6C83" }}
              tickCount={8}
            />
            <Tooltip
              cursor={{ fill: "transparent" }}
              content={<CustomTooltip />}
            />
            <Legend content={CustomLegend} />
            <Bar
              dataKey="spent"
              stackId="a"
              fill="#597CFF"
              name={t("billing.summary.budget_spent")}
              isAnimationActive={false}
              maxBarSize={100}
            />
            <Bar
              dataKey="remaining"
              stackId="a"
              fill="#BECCFF"
              name={t("billing.summary.budget_remaining")}
              radius={[8, 8, 0, 0]}
              isAnimationActive={false}
              maxBarSize={100}
            />
            <Bar
              dataKey="over"
              stackId="a"
              fill="#FFCA8B"
              name={t("billing.summary.over_budget")}
              radius={[8, 8, 0, 0]}
              isAnimationActive={false}
              maxBarSize={100}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default XchangeBillingSummaryChart;
