export const bytesToString = (bytes: number) => {
  const sizes = ["b", "kb", "mb", "gb", "tb"];
  if (bytes === 0) return "0 Byte";
  const i = parseInt(
    Math.floor(Math.log(bytes) / Math.log(1024)).toString(),
    10
  );
  if (i === 0) return `${bytes} ${sizes[i]})`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};
