import { Button, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import {
  XpertOnboardingDomainsStepFields,
  XpertOnboardingDomainsStepValidationSchema,
} from "@pages/Onboardings/XpertOnboardingPage/XpertOnboardingConfigs";
import "./DomainsStep.scss";

const DomainsStep = ({
  back,
  next,
}: {
  back: () => void;
  next: () => void;
}) => {
  const { t } = useTranslation();

  const { trigger, getValues } = useFormContext();

  return (
    <div className="Domains-Step fade-in">
      <h3 className="mb-2">{t("onboardings.xpert.domains.title")}</h3>
      <p>{t("onboardings.xpert.domains.description")}</p>
      <p className="Domains-Step__Info">
        {t("onboardings.xpert.domains.info_part_one")}
        <span className="Domains-Step__Info__Details">
          {t("onboardings.xpert.domains.info_part_two")}
        </span>
      </p>

      <Stack direction="horizontal" gap={2} className="mt-5">
        <Button variant="outline-light" className="w-100" onClick={back}>
          {t("wizard.back")}
        </Button>
        <Button
          className="w-100"
          onClick={() => {
            trigger(XpertOnboardingDomainsStepFields);
            const { error } =
              XpertOnboardingDomainsStepValidationSchema.validate(getValues(), {
                allowUnknown: true,
              });

            if (!error) {
              next();
            }
          }}
        >
          {t("wizard.next")}
        </Button>
      </Stack>
    </div>
  );
};

export default DomainsStep;
