import { useTranslation } from "react-i18next";
import StepCard from "@components/StepCard/StepCard";
import ClientBills from "@pages/Company/CompanyEditPage/CompanyClientBilling/ClientBills/ClientBills";
import IClient from "@interfaces/IClient";
import ClientFinanceForm from "@pages/Company/CompanyEditPage/CompanyClientBilling/ClientFinanceForm";

const CompanyClientBilling = ({ client }: { client?: IClient }) => {
  const { t } = useTranslation();

  return (
    <>
      <StepCard title={t("billing.history.title")}>
        <ClientBills client={client} />
      </StepCard>

      <StepCard title={t("companies.details.client.finance.title")}>
        {client && <ClientFinanceForm client={client} />}
      </StepCard>
    </>
  );
};

export default CompanyClientBilling;
