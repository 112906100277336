import { ReactNode } from "react";
import { Image } from "react-bootstrap";
import "./HeaderedPage.scss";

const HeaderedPage = ({
  children,
  headerContent,
}: {
  children: ReactNode;
  headerContent?: ReactNode;
}) => {
  return (
    <div className="Headered-Page">
      <div className="Headered-Page__Header">
        <Image className="Headered-Page__Header__Logo" src="/logo-light.svg" />
        <div className="Headered-Page__Header__Content">{headerContent}</div>
      </div>

      <div className="Headered-Page__Content">{children}</div>
    </div>
  );
};

export default HeaderedPage;
