import { Spinner, Table } from "react-bootstrap";
import { TableVirtuoso } from "react-virtuoso";
import { useTranslation } from "react-i18next";
import { forwardRef, ReactNode, useEffect, useState } from "react";
import "./PaginatedTable.scss";

const PaginatedTable = ({
  id,
  data,
  header,
  renderRow,
  isDataLoading,
  hasNextPage,
  fetchNextPage,
  fixedItemHeight,
  maxHeight = 350,
}: {
  id?: string;
  data: object[];
  header: ReactNode;
  renderRow: (data: object) => ReactNode;
  isDataLoading: boolean;
  hasNextPage?: boolean;
  fetchNextPage: Function;
  fixedItemHeight?: number;
  maxHeight?: number;
}) => {
  const { t } = useTranslation();

  const [calculatedHeight, setCalculatedHeight] = useState(maxHeight);
  useEffect(() => {
    const headerHeight = document.querySelector(
      ".Paginated-Table__Table thead tr"
    )?.clientHeight;

    if (fixedItemHeight && headerHeight) {
      const estimatedTotalRowHeight = fixedItemHeight * data.length;
      let estimatedTotalHeight = estimatedTotalRowHeight + headerHeight + 2;

      if (estimatedTotalHeight < maxHeight) {
        setCalculatedHeight(estimatedTotalHeight);
      } else {
        setCalculatedHeight(maxHeight);
      }
    }
  }, [fixedItemHeight, data.length, maxHeight]);

  return (
    <TableVirtuoso
      id={id}
      className="Paginated-Table"
      increaseViewportBy={100}
      style={{
        height: `${calculatedHeight}px`,
      }}
      data={data}
      atBottomThreshold={100}
      fixedItemHeight={fixedItemHeight}
      components={{
        Table: ({ style, ...props }) => (
          <Table
            {...props}
            style={{ ...style }}
            className="Paginated-Table__Table"
          />
        ),
        TableRow: ({ style, ...props }) => (
          <tr {...props} style={{ ...style, height: fixedItemHeight }} />
        ),
        TableBody: forwardRef(
          (
            { style, ...props },
            ref: React.ForwardedRef<HTMLTableSectionElement>
          ) => (
            <tbody ref={ref} {...props} style={{ ...style }}>
              {props.children}

              {calculatedHeight === maxHeight && (
                <tr>
                  <td colSpan={5} className="p-4 small text-muted text-center">
                    {isDataLoading && (
                      <Spinner animation="border" variant="primary" size="sm" />
                    )}
                    {!isDataLoading && !hasNextPage && t("filters.end_reached")}
                  </td>
                </tr>
              )}
            </tbody>
          )
        ),
      }}
      fixedHeaderContent={() => <tr>{header}</tr>}
      atBottomStateChange={(isAtBottom) => {
        if (isAtBottom && hasNextPage) {
          fetchNextPage();
        }
      }}
      itemContent={(_, rowData) => renderRow(rowData)}
    />
  );
};

export default PaginatedTable;
