import {
  InfiniteData,
  UseQueryOptions,
  useInfiniteQuery,
} from "@tanstack/react-query";

import IPage, { IPageParams } from "@interfaces/IPage";
import { IXchangeTeamMember } from "@interfaces/IXchangeTeamMember";
import XchangeService from "@services/XchangeService";

export const useXchangeTeamMembers = (
  xchangeId: number,
  params?: IPageParams,
  options?: Pick<
    UseQueryOptions<InfiniteData<IPage<IXchangeTeamMember>>, Error>,
    "enabled" | "onSuccess"
  > & {
    refetchOnWindowFocus?: boolean;
  }
) =>
  useInfiniteQuery(
    ["xchanges", { id: xchangeId }, "team-members"],
    (ctx) => {
      return XchangeService.getXchangeTeamMembers(xchangeId, {
        page: ctx.pageParam ? ctx.pageParam : undefined,
        ...params,
      });
    },
    {
      getPreviousPageParam: (firstGroup) => firstGroup.previous,
      getNextPageParam: (lastGroup) => lastGroup.next,
      ...options,
    }
  );
