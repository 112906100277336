import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import IXpertTerm from "@interfaces/terms/IXpertTerm";
import XpertTermService, {
  IGetXpertTermsParams,
} from "@services/XpertTermService";

export const useXpertTerms = (
  params?: IGetXpertTermsParams,
  options?: Pick<UseQueryOptions<IXpertTerm[], Error>, "enabled" | "onSuccess">
) =>
  useQuery(
    ["xpert-terms", { params }],
    () => XpertTermService.getXpertTerms(params),
    options
  );
