import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useCallback, useEffect, useState } from "react";
import { Button, Form, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import IEpic from "@interfaces/IEpic";
import { IGetXpertsParams } from "@services/XpertService";
import XchangeSprintSchedule from "@pages/Xchange/XchangeEdit/XchangeSprintSchedule";
import XchangeEpicCard from "./XchangeEpicCard";
import XchangeEpicDetailsModal from "./XchangeEpicDetailsModal";
import XchangeEpicBreakdown from "./XchangeEpicBreakdown/XchangeEpicBreakdown";
import CardPlaceholder from "@components/CardPlaceholder/CardPlaceholder";
import { useEpics } from "@hooks/xchange/useEpics";
import IXchange from "@interfaces/IXchange";
import Joi from "joi";
import { useEpicDelete } from "@hooks/xchange/useEpicDelete";
import { useEpicUpdate } from "@hooks/xchange/useEpicUpdate";
import useXchangeGoalEndDateAutoUpdate from "@hooks/xchange/useXchangeGoalEndDateAutoUpdate";
export interface EpicFormValues {
  title?: string;
  duration?: number;
  xperts?: number[];
  description?: string;
  client_involved?: boolean;
  order_index?: number;
}

export const xchangeEpicSchema = Joi.object({
  id: Joi.number(),
  title: Joi.string().required(),
  duration: Joi.number().integer().positive().required(),
  xperts: Joi.array().items(Joi.number()).min(1).required(),
  description: Joi.string().required(),
  client_involved: Joi.boolean().required(),
  order_index: Joi.number(),
}).unknown(true);

type DragData = {
  epic: IEpic;
};

const XchangeEpics = ({ xchange }: { xchange?: IXchange }) => {
  const { t } = useTranslation();

  const xchangeGoalEndDateAutoUpdate = useXchangeGoalEndDateAutoUpdate();

  const { data: epics } = useEpics(xchange?.id!, {
    enabled: !!xchange?.id!,
    onSuccess: (data) => {
      setSortedEpics(data);
      xchangeGoalEndDateAutoUpdate.mutate(xchange);
    },
  });

  const [showAddEpicModal, setShowAddEpicModal] = useState(false);
  const [epicBeingEdited, setEpicBeingEdited] = useState<IEpic | null>(null);
  const [getXpertsParams, setGetXpertsParams] = useState<IGetXpertsParams>({
    id__in: Array.from(
      new Set((epics || []).map((epic: EpicFormValues) => epic.xperts).flat())
    ).join(","),
  });

  const [sortedEpics, setSortedEpics] = useState<IEpic[]>(epics ?? []);
  const [draggingData, setDraggingData] = useState<DragData | null>(null);

  const handleDragStart = (event: DragStartEvent) => {
    const epic = event.active.data.current!;
    setDraggingData({
      epic,
    });
  };

  const updateEpicMutation = useEpicUpdate();
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active && over && active.id !== over!.id) {
      setSortedEpics((epics) => {
        const oldIndex = epics.findIndex((epic) => epic.id === active.id);
        const newIndex = epics.findIndex((epic) => epic.id === over!.id);

        const newSortedEpics = arrayMove(epics, oldIndex, newIndex);
        return newSortedEpics;
      });
      updateEpicMutation.mutate({
        xchangeId: xchange?.id!,
        epic: {
          id: active.data.current!.id,
          order_index: over.data.current!.order_index,
        },
      });
    }

    setDraggingData(null);
  };

  const addXpertsToListing = useCallback(
    (xpertIds: number[]) => {
      const currentXperts = getXpertsParams.id__in?.split(",") || [];
      const newXpertsToFetch = [];
      for (const xpertId of xpertIds) {
        if (!currentXperts.includes(xpertId.toString())) {
          newXpertsToFetch.push(xpertId.toString());
        }
      }
      setGetXpertsParams({
        id__in: [...currentXperts, ...newXpertsToFetch].join(","),
      });
    },
    [getXpertsParams.id__in]
  );

  const deleteEpicMutation = useEpicDelete();
  const deleteEpic = (epicId: number) => {
    deleteEpicMutation.mutate({ xchangeId: xchange!.id!, epicId });
  };

  useEffect(() => {
    addXpertsToListing(
      (epics || []).map((epic: EpicFormValues) => epic.xperts!).flat()
    );
  }, [addXpertsToListing, epics]);

  const closeModal = () => {
    setShowAddEpicModal(false);
    setEpicBeingEdited(null);
  };

  return (
    <>
      <Form.Group className="ps-4 pe-4 pb-4">
        <XchangeEpicBreakdown xchange={xchange} />

        <XchangeSprintSchedule xchange={xchange} />

        <Stack direction="horizontal" gap={3} className="mb-5">
          <h5 className="mb-0">{t("form.xchange_edit.sub_titles.epics")}</h5>
          <Button
            className="ms-auto btn-sm"
            variant="outline-light"
            onClick={() => setShowAddEpicModal(true)}
          >
            {t("form.xchange_edit.sub_titles.add_epic")}
          </Button>
        </Stack>

        {epics?.length === 0 ? (
          <CardPlaceholder
            iconClassName={"fa-solid fa-rectangle-vertical-history"}
            title={t("form.epic.empty.title")}
            description={t("form.epic.empty.desc")}
          />
        ) : (
          <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
          >
            <SortableContext
              items={sortedEpics.map((epic) => epic.id!)}
              strategy={verticalListSortingStrategy}
            >
              {sortedEpics?.map((epic, index) => (
                <XchangeEpicCard
                  epic={epic}
                  key={epic.id}
                  index={+epic.order_index!}
                  dragId={epic.id!}
                  clientName={xchange?.client?.name!}
                  onDelete={() => deleteEpic(epic.id!)}
                  onEdit={() => {
                    setEpicBeingEdited(epic);
                    setShowAddEpicModal(true);
                  }}
                />
              ))}
              <DragOverlay>
                {draggingData !== null ? (
                  <XchangeEpicCard
                    epic={draggingData.epic}
                    index={+draggingData.epic.order_index!}
                    dragId={draggingData.epic.id!}
                    clientName={xchange?.client?.name!}
                  />
                ) : null}
              </DragOverlay>
            </SortableContext>
          </DndContext>
        )}
      </Form.Group>
      <XchangeEpicDetailsModal
        show={showAddEpicModal}
        epicToEdit={epicBeingEdited}
        onClose={() => {
          closeModal();
        }}
        xchangeId={xchange?.id!}
      />
    </>
  );
};

export default XchangeEpics;
