export const downloadBlobFile = (
  bytes: any,
  fileName: string,
  contentType: string
) => {
  const blob = new Blob([bytes], { type: contentType });
  const url = window.URL.createObjectURL(blob);
  const tempElement = document.createElement("a");
  document.body.appendChild(tempElement);
  tempElement.href = url;
  tempElement.download = fileName;
  tempElement.click();
  window.URL.revokeObjectURL(url);
};

export const openBlobFile = (bytes: any, contentType: string) => {
  const blob = new Blob([bytes], { type: contentType });
  const url = window.URL.createObjectURL(blob);
  window.open(url);
  window.URL.revokeObjectURL(url);
};
