import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import i18n from "i18next";
import i18nLanguageDetector from "i18next-browser-languagedetector";
import i18nBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { BrowserRouter } from "react-router-dom";

window.onUsersnapCXLoad = function (api: {
  init: () => void;
  show: (key: string) => void;
  on: (
    eventName: string,
    handler: (event: {
      api: { setValue: (key: string, value: string) => void };
    }) => void
  ) => void;
}) {
  if (window.location.hostname === "localhost") {
    return;
  }
  api.init();
  api.show("dbc2e0ae-4f82-4ca5-ad75-981c4213258e");
  api.on(
    "open",
    function (event: {
      api: { setValue: (key: string, value: string) => void };
    }) {
      // Set Assignee. A User with this email has to exist in your Usersnap account
      let email = "";
      if (localStorage.getItem("user")) {
        email = JSON.parse(localStorage.getItem("user") ?? "")?.email ?? "";
      }
      // Set email
      event.api.setValue("visitor", email);
    }
  );
};

const script = document.createElement("script");
script.async = Boolean(1);
script.src =
  "https://widget.usersnap.com/global/load/c860068e-d285-400f-a77a-f1bd7171694f?onload=onUsersnapCXLoad";
document.getElementsByTagName("head")[0].appendChild(script);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

i18n
  .use(i18nBackend)
  .use(i18nLanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?v=${
        process.env.REACT_APP_VERSION || "local"
      }`,
    },
  });

const queryClient = new QueryClient({});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
