import { Col, Form, Row, Stack } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import { isFieldRequired } from "@helpers/joi-utils";
import { Pronouns } from "@interfaces/IPerson";
import Currencies from "@interfaces/Currencies";
import { useState } from "react";
import { useRoles } from "@hooks/role/useRoles";
import SelectWithCreation from "@components/SelectWithCreation/SelectWithCreation";
import { useRoleCreate } from "@hooks/role/useRoleCreate";
import CountrySelect from "@components/CountrySelect/CountrySelect";
import RegionSelect from "@components/RegionSelect/RegionSelect";
import CustomSelect from "@components/CustomSelect/CustomSelect";
import {
  MemberFormValidationSchema,
  MemberFormValues,
} from "@pages/Company/CompanyEditPage/MemberModal/MemberModal";
import IXpert from "@interfaces/IXpert";

const MemberForm = ({
  xperts,
  showPrimaryContactToggle,
  showFinanceContactToggle,
}: {
  xperts?: IXpert[];
  showPrimaryContactToggle: boolean;
  showFinanceContactToggle: boolean;
}) => {
  const { t } = useTranslation();

  const formMethods = useFormContext<MemberFormValues>();

  const {
    register,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = formMethods;

  const [roleOptions, setRoleOptions] = useState<
    { value: number; label: string }[]
  >([]);
  const { isLoading: areRolesLoading } = useRoles(
    {},
    {
      onSuccess: (roles) => {
        setRoleOptions(
          roles.map((role) => ({
            value: role.id!,
            label: role.name!,
          }))
        );
      },
    }
  );

  const createRoleMutation = useRoleCreate();

  const createRoleAndAdd = (name: string) => {
    createRoleMutation.mutate(
      {
        name,
      },
      {
        onSuccess: (role) => {
          setValue("roles", (getValues("roles") || []).concat([role.id!]));
        },
      }
    );
  };

  return (
    <Form>
      <Row>
        {xperts && (
          <Form.Group className="mb-4">
            <Form.Label aria-required>{t("teams.create.xpert")}</Form.Label>
            <CustomSelect
              name="xpert"
              control={control}
              options={xperts?.map((xpert) => {
                return {
                  value: xpert.id,
                  label: xpert.name,
                };
              })}
            />
            <FieldErrorMessage
              field={errors.xpert}
              fieldName={t("teams.create.xpert")}
            />
          </Form.Group>
        )}
        <Form.Group className="mb-4" as={Col} sm={6}>
          <Form.Label
            aria-required={isFieldRequired(
              MemberFormValidationSchema,
              "first_name"
            )}
          >
            {t("companies.details.overview.people.add.fields.first_name")}
          </Form.Label>
          <Form.Control
            type="text"
            {...register("first_name")}
            isInvalid={!!errors.first_name}
          />
          <FieldErrorMessage
            field={errors.first_name}
            fieldName={t(
              "companies.details.overview.people.add.fields.first_name"
            )}
          />
        </Form.Group>
        <Form.Group className="mb-4" as={Col} sm={6}>
          <Form.Label
            aria-required={isFieldRequired(
              MemberFormValidationSchema,
              "last_name"
            )}
          >
            {t("companies.details.overview.people.add.fields.last_name")}
          </Form.Label>
          <Form.Control
            type="text"
            {...register("last_name")}
            isInvalid={!!errors.last_name}
          />
          <FieldErrorMessage
            field={errors.last_name}
            fieldName={t(
              "companies.details.overview.people.add.fields.last_name"
            )}
          />
        </Form.Group>
      </Row>
      <Form.Group className="mb-4">
        <Form.Label
          aria-required={isFieldRequired(MemberFormValidationSchema, "title")}
        >
          {t("companies.details.overview.people.add.fields.title")}
        </Form.Label>
        <Form.Control
          type="text"
          {...register("title")}
          isInvalid={!!errors.title}
        />
        <FieldErrorMessage
          field={errors.title}
          fieldName={t("companies.details.overview.people.add.fields.title")}
        />
      </Form.Group>

      {showPrimaryContactToggle || showFinanceContactToggle ? (
        <Form.Group className="mb-4" as={Col} sm={6}>
          <Form.Label
            aria-required={isFieldRequired(
              MemberFormValidationSchema,
              "primary_contact"
            )}
          >
            {t("companies.details.overview.people.add.fields.contact_type")}
          </Form.Label>
          <Stack direction="horizontal">
            {showPrimaryContactToggle && (
              <Form.Group className="mb-4 me-5">
                <div className="d-flex align-items-center">
                  <Form.Check
                    type="switch"
                    {...register("primary_contact")}
                    className="me-3"
                  />
                  {t("companies.details.overview.people.primary_contact")}
                </div>
                <FieldErrorMessage
                  field={errors.primary_contact}
                  fieldName={t(
                    "companies.details.overview.people.primary_contact"
                  )}
                />
              </Form.Group>
            )}
            {showFinanceContactToggle && (
              <Form.Group className="mb-4">
                <div className="d-flex align-items-center">
                  <Form.Check
                    type="switch"
                    {...register("finance_contact")}
                    className="me-3"
                  />
                  {t("companies.details.overview.people.finance_contact")}
                </div>
                <FieldErrorMessage
                  field={errors.finance_contact}
                  fieldName={t(
                    "companies.details.overview.people.finance_contact"
                  )}
                />
              </Form.Group>
            )}
          </Stack>
        </Form.Group>
      ) : null}

      <Form.Group className="mb-4">
        <Form.Label>
          {t("companies.details.overview.people.add.fields.pronouns")}
        </Form.Label>
        <CustomSelect
          control={control}
          name="pronouns"
          options={Object.values(Pronouns).map((type) => ({
            value: type,
            label: t(`pronouns.${type}`) as string,
          }))}
          isClearable
        />
        <FieldErrorMessage
          field={errors.pronouns}
          fieldName={t("companies.details.overview.people.add.fields.pronouns")}
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label
          aria-required={isFieldRequired(MemberFormValidationSchema, "email")}
        >
          {t("companies.details.overview.people.add.fields.email")}
        </Form.Label>
        <Form.Control
          type="text"
          {...register("email")}
          isInvalid={!!errors.email}
        />
        <FieldErrorMessage
          field={errors.email}
          fieldName={t("companies.details.overview.people.add.fields.email")}
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label
          aria-required={isFieldRequired(MemberFormValidationSchema, "phone")}
        >
          {t("companies.details.overview.people.add.fields.phone")}
        </Form.Label>
        <Form.Control
          type="text"
          {...register("phone")}
          isInvalid={!!errors.phone}
        />
        <FieldErrorMessage
          field={errors.phone}
          fieldName={t("companies.details.overview.people.add.fields.phone")}
        />
      </Form.Group>
      <Row>
        <Form.Group className="mb-4" as={Col} sm={6}>
          <CountrySelect
            FormValidationSchema={MemberFormValidationSchema}
            isClearable
          />
        </Form.Group>

        <Form.Group className="mb-4" as={Col} sm={6}>
          <RegionSelect
            FormValidationSchema={MemberFormValidationSchema}
            isClearable
          />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group className="mb-4" as={Col} sm={4}>
          <Form.Label>
            {t("companies.details.overview.people.add.fields.currency")}
          </Form.Label>
          <CustomSelect
            control={control}
            name="currency"
            options={Object.values(Currencies).map((type) => ({
              value: type,
              label: t(`currencies.${type}`) as string,
            }))}
            isClearable
          />
          <FieldErrorMessage
            field={errors.currency}
            fieldName={t(
              "companies.details.overview.people.add.fields.currency"
            )}
          />
        </Form.Group>

        <Form.Group className="mb-4" as={Col} sm={8}>
          <Form.Label
            aria-required={isFieldRequired(MemberFormValidationSchema, "rate")}
          >
            {t("companies.details.overview.people.add.fields.rate")}
          </Form.Label>
          <Form.Control {...register("rate")} isInvalid={!!errors.rate} />
          <FieldErrorMessage
            field={errors.rate}
            fieldName={t("companies.details.overview.people.add.fields.rate")}
          />
        </Form.Group>

        <Form.Group className="mb-4">
          <Form.Label
            aria-required={isFieldRequired(MemberFormValidationSchema, "roles")}
          >
            {t("companies.details.overview.people.add.fields.roles")}
          </Form.Label>
          <SelectWithCreation
            name="roles"
            control={control}
            isLoading={areRolesLoading}
            options={areRolesLoading ? [] : roleOptions}
            onEnterWithoutOptions={(value) => {
              createRoleAndAdd(value);
            }}
          />
          <FieldErrorMessage
            field={errors.roles}
            fieldName={t("companies.details.overview.people.add.fields.roles")}
          />
        </Form.Group>
      </Row>
    </Form>
  );
};

export default MemberForm;
