import { joiResolver } from "@hookform/resolvers/joi";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AxiosErrorAlert from "@components/AxiosErrorAlert/AxiosErrorAlert";
import CatalogTermForm, {
  CatalogTermFormValues,
  CatalogTermSchema,
} from "@components/CatalogTerm/CatalogTermForm/CatalogTermForm";
import GenericModal from "@components/GenericModal/GenericModal";
import ModalTabs from "@components/GenericModal/ModalTabs/ModalTabs";
import ICatalogProduct from "@interfaces/products/ICatalogProduct";
import CatalogTermSelectionForm from "@components/CatalogTermSelectionForm/CatalogTermSelectionForm";

const CatalogTermModal = ({
  show,
  product,
  onClose,
  onExistingSelected,
  onSaveCatalogTerm,
  isFinishLoading,
  axiosError,
}: {
  show: boolean;
  product?: ICatalogProduct;
  onClose: () => void;
  onExistingSelected: (catalogTerms: number[]) => void;
  onSaveCatalogTerm: (catalogTerm: any) => void;
  isFinishLoading: boolean;
  axiosError?: any;
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("selection");

  const termsSelectionFormMethods = useForm<{
    catalogTerms: number[];
  }>({
    defaultValues: {
      catalogTerms: [],
    },
  });

  const {
    control,
    getValues: getCatalogTermsSelectionValues,
    setValue: setCatalogTermsSelectionValue,
    watch: watchCatalogTermsSelection,
  } = termsSelectionFormMethods;

  const newTermFormMethods = useForm<CatalogTermFormValues>({
    resolver: joiResolver(CatalogTermSchema),
    mode: "onChange",
  });

  const { handleSubmit: handleNewTermSubmit } = newTermFormMethods;

  const handleOnFinish = () => {
    if (activeTab === "selection") {
      onExistingSelected(getCatalogTermsSelectionValues("catalogTerms"));
    } else if (activeTab === "details") {
      handleNewTermSubmit(onSaveCatalogTerm)();
    }
  };

  return (
    <GenericModal
      title={t("product.details.terms.add.title")}
      show={show}
      onClose={onClose}
      onFinish={handleOnFinish}
      finishText={
        activeTab === "selection"
          ? t("product.details.terms.add.finish")
          : t("product.details.terms.add.new_finish")
      }
      closeText={t("actions.cancel")}
      isFinishLoading={isFinishLoading}
      canBeFinished={
        activeTab === "selection"
          ? watchCatalogTermsSelection("catalogTerms").length > 0
          : true
      }
      extraButtons={
        activeTab === "selection" && (
          <>
            <Button
              variant="outline-light"
              onClick={() => {
                setCatalogTermsSelectionValue("catalogTerms", []);
              }}
              disabled={watchCatalogTermsSelection("catalogTerms").length === 0}
            >
              {t("product.details.terms.add.clear_all")}
            </Button>
          </>
        )
      }
    >
      <h3 className="pb-6 pt-5 mb-2 ps-0">
        {t("product.details.terms.add.title")}
      </h3>
      <ModalTabs
        currentTabKey={activeTab}
        onTabChange={(key) => setActiveTab(key)}
        tabs={[
          {
            tabKey: "selection",
            tabText: t("product.details.terms.add.existing"),
            content: (
              <FormProvider {...termsSelectionFormMethods}>
                <CatalogTermSelectionForm
                  name="catalogTerms"
                  control={control}
                  fixedGetCatalogTermsParams={{
                    products__not__in: product?.id?.toString(),
                  }}
                />
              </FormProvider>
            ),
          },
          {
            tabKey: "details",
            tabText: t("product.details.terms.add.new"),
            content: (
              <>
                <FormProvider {...newTermFormMethods}>
                  <CatalogTermForm />
                </FormProvider>
              </>
            ),
          },
        ]}
      />

      <AxiosErrorAlert
        response={axiosError}
        translationPrefix="product.details.terms.add.fields."
      />
    </GenericModal>
  );
};

export default CatalogTermModal;
