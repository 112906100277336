import { Control, useController } from "react-hook-form";
import ListItemCheckbox from "@components/ListItemCheckbox/ListItemCheckbox";
import { toCostRangeString } from "@helpers/currencyUtils";
import ICostRange from "@interfaces/ICostRange";
import ICatalogTerm, { IRelatedXpert } from "@interfaces/terms/ICatalogTerm";
import { cleanRichText } from "@helpers/string-utils";
import { Virtuoso } from "react-virtuoso";
import { Spinner } from "react-bootstrap";
import "./CatalogTermsSelector.scss";

const CatalogTermsSelector = ({
  possibleCatalogTerms,
  onBottomReached,
  isLoadingNewCatalogTerms = false,
  control,
  name,
  onChange,
}: {
  possibleCatalogTerms?: ICatalogTerm[];
  onBottomReached: () => void;
  isLoadingNewCatalogTerms: boolean;
  control: Control<any, any>;
  name: string;
  onChange?: (value: ICatalogTerm[]) => void;
}) => {
  const { field } = useController({
    name,
    control,
  });

  const getXpertBadges = (relatedXperts?: IRelatedXpert[]) => {
    return (relatedXperts || []).map((relatedXpert) => relatedXpert.name);
  };

  const getCostRangeFacts = (costRanges?: ICostRange[]) => {
    const costRangeStrings = [];
    for (const costRange of costRanges || []) {
      if (costRange.min && costRange.max) {
        costRangeStrings.push({
          prefix: costRange.currency,
          label: toCostRangeString(costRange),
        });
      }
    }
    return costRangeStrings;
  };

  return (
    <>
      <Virtuoso
        className="Catalog-Terms-Selector"
        data={possibleCatalogTerms}
        atBottomThreshold={240}
        atBottomStateChange={(isAtBottom) => {
          if (isAtBottom) {
            onBottomReached();
          }
        }}
        components={{
          Footer: () => {
            return (
              <div className="d-flex justify-content-center pt-4 pb-4">
                {isLoadingNewCatalogTerms && (
                  <Spinner animation="border" variant="primary" size="sm" />
                )}
              </div>
            );
          },
        }}
        itemContent={(_index, catalogTerm) => (
          <div className="ps-5 pe-5 pt-1 pb-3">
            <ListItemCheckbox
              key={catalogTerm.id}
              title={catalogTerm.title!}
              subtitle={catalogTerm.sub_domain?.name}
              description={cleanRichText(catalogTerm.description!)}
              badges={getXpertBadges(catalogTerm.related_xperts)}
              facts={getCostRangeFacts(catalogTerm.cost_ranges)}
              onClick={() => {
                let newCatalogTermsSelection = (
                  possibleCatalogTerms || []
                ).filter((ct) => field.value.includes(ct.id));

                if (newCatalogTermsSelection.includes(catalogTerm)) {
                  newCatalogTermsSelection = newCatalogTermsSelection.filter(
                    (ct: ICatalogTerm) => ct !== catalogTerm
                  );
                } else {
                  newCatalogTermsSelection =
                    newCatalogTermsSelection.concat(catalogTerm);
                }

                field.onChange(
                  newCatalogTermsSelection.map((ct: ICatalogTerm) => ct.id)
                );
                onChange?.(newCatalogTermsSelection);
              }}
              checked={(field.value || []).includes(catalogTerm.id!)}
            />
          </div>
        )}
      />
    </>
  );
};

export default CatalogTermsSelector;
