import { joiResolver } from "@hookform/resolvers/joi";
import { allCountries } from "country-region-data";
import { isEqual } from "lodash";
import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { FormProvider, set, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CountrySelect from "@components/CountrySelect/CountrySelect";
import CustomSelect from "@components/CustomSelect/CustomSelect";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import RegionSelect from "@components/RegionSelect/RegionSelect";
import { isFieldRequired } from "@helpers/joi-utils";
import { useCompanyUpdate } from "@hooks/company/useCompanyUpdate";
import ICompany from "@interfaces/ICompany";
import {
  CompanyLocationFormValues,
  companyLocationValidationSchema,
} from "@pages/Company/CompanyEditPage/CompanyEditConfig";
import InfoCard from "@components/InfoCard/InfoCard";
import InfoCardSection from "@components/InfoCard/InfoCardSection/InfoCardSection";

const CompanyLocationForm = ({
  company,
}: {
  company: ICompany | undefined;
}) => {
  const formMethods = useForm<CompanyLocationFormValues>({
    mode: "onChange",
    resolver: joiResolver(companyLocationValidationSchema),
    defaultValues: {
      legal_entity_jurisdiction: company?.legal_entity_jurisdiction,
      street: company?.street || "",
      unit_number: company?.unit_number || "",
      country: company?.country || "",
      city: company?.city || "",
      region: company?.region || "",
      postal_code: company?.postal_code || "",
    },
  });
  const {
    control,
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const { t } = useTranslation();
  const updateCompanyMutation = useCompanyUpdate();

  const [pastFormSubmitData, setPastFormSubmitData] =
    useState<CompanyLocationFormValues>(getValues());

  const formSubmit = (data: CompanyLocationFormValues) => {
    const changedValues = Object.keys(data).reduce((acc, key) => {
      if (
        !isEqual(
          data[key as keyof CompanyLocationFormValues],
          pastFormSubmitData[key as keyof CompanyLocationFormValues]
        )
      ) {
        set(acc, key, data[key as keyof CompanyLocationFormValues]);
      }
      return acc;
    }, {} as CompanyLocationFormValues);

    if (Object.keys(changedValues).length > 0) {
      updateCompanyMutation.mutate({
        updatedCompany: {
          id: company?.id!,
          ...changedValues,
        },
      });
    }

    setPastFormSubmitData(data);
  };

  const getLegalJurisdictionOptions = () => {
    const options = allCountries
      .filter((c) => ["CA", "US"].includes(c[1]))
      .map((c) => c[2])
      .flat()
      .map((region) => ({
        value: region[0],
        label: region[0],
      }))
      .filter((region) => !region.label.includes("Armed Forces"));

    options.push({
      value: "International",
      label: t("companies.details.overview.location.international"),
    });

    return options;
  };

  return (
    <FormProvider {...formMethods}>
      <Form onBlur={handleSubmit(formSubmit)}>
        <InfoCard
          id="company-location"
          title={t("companies.details.overview.location.subtitle")}
        >
          <InfoCardSection
            direction="row"
            title={t(
              "companies.details.overview.location.labels.legal_entity_jurisdiction"
            )}
          >
            <CustomSelect
              control={control}
              name="legal_entity_jurisdiction"
              options={getLegalJurisdictionOptions()}
              onAfterChange={() => {
                handleSubmit(formSubmit)();
              }}
              isDisabled={!company}
              isClearable
            />
            <FieldErrorMessage
              field={errors.legal_entity_jurisdiction}
              fieldName={t(
                "companies.details.overview.location.labels.legal_entity_jurisdiction"
              )}
            />
          </InfoCardSection>

          <InfoCardSection
            direction="row"
            title={t("companies.details.overview.location.address")}
          >
            <InfoCard>
              <Row className="gx-4">
                <Form.Group className="mb-4" as={Col} xl={12}>
                  <Form.Label
                    aria-required={isFieldRequired(
                      companyLocationValidationSchema,
                      "street"
                    )}
                  >
                    {t("companies.details.overview.location.labels.street")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    {...register("street")}
                    isInvalid={!!errors.street}
                    disabled={!company}
                  />
                  <FieldErrorMessage
                    field={errors.street}
                    fieldName={t(
                      "companies.details.overview.location.labels.street"
                    )}
                  />
                </Form.Group>

                <Form.Group className="mb-4" as={Col} xl={12}>
                  <Form.Label
                    aria-required={isFieldRequired(
                      companyLocationValidationSchema,
                      "unit_number"
                    )}
                  >
                    {t(
                      "companies.details.overview.location.labels.unit_number"
                    )}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    {...register("unit_number")}
                    isInvalid={!!errors.unit_number}
                    disabled={!company}
                  />
                  <FieldErrorMessage
                    field={errors.unit_number}
                    fieldName={t(
                      "companies.details.overview.location.labels.unit_number"
                    )}
                  />
                </Form.Group>

                <Form.Group className="mb-4" as={Col} xs={12} xl={8}>
                  <Form.Label
                    aria-required={isFieldRequired(
                      companyLocationValidationSchema,
                      "city"
                    )}
                  >
                    {t("companies.details.overview.location.labels.city")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    {...register("city")}
                    isInvalid={!!errors.city}
                    disabled={!company}
                  />
                  <FieldErrorMessage
                    field={errors.city}
                    fieldName={t(
                      "companies.details.overview.location.labels.city"
                    )}
                  />
                </Form.Group>

                <Form.Group className="mb-4" as={Col} xs={12} xl={4}>
                  <Form.Label
                    aria-required={isFieldRequired(
                      companyLocationValidationSchema,
                      "postal_code"
                    )}
                  >
                    {t(
                      "companies.details.overview.location.labels.postal_code"
                    )}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    {...register("postal_code")}
                    isInvalid={!!errors.postal_code}
                    disabled={!company}
                  />
                  <FieldErrorMessage
                    field={errors.postal_code}
                    fieldName={t(
                      "companies.details.overview.location.labels.postal_code"
                    )}
                  />
                </Form.Group>

                <Form.Group className="mb-4" as={Col} xs={12} xl={7}>
                  <CountrySelect
                    FormValidationSchema={companyLocationValidationSchema}
                    disabled={!company}
                    isClearable
                  />
                </Form.Group>

                <Form.Group className="mb-4" as={Col} xs={12} xl={5}>
                  <RegionSelect
                    FormValidationSchema={companyLocationValidationSchema}
                    disabled={!company}
                    isClearable
                  />
                </Form.Group>
              </Row>
            </InfoCard>
          </InfoCardSection>
        </InfoCard>
      </Form>
    </FormProvider>
  );
};

export default CompanyLocationForm;
