import { useState } from "react";
import { Button, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ActionsDropdown from "@components/ListOptionsDropdown/ActionsDropdown";
import { toCurrencyString } from "@helpers/currencyUtils";
import IXchangeProduct from "@interfaces/products/IXchangeProduct";
import TermRow from "@pages/Xchange/XchangeEdit/XchangeDeliverables/TermRow/TermRow";
import "./ProductRow.scss";
import { calcXchangeProductBudgetsByCurrency } from "@helpers/xchange-utils";

interface Props {
  product: IXchangeProduct;
  onEdit?: () => void;
  onDelete?: () => void;
  isLockedByEpics?: boolean;
}

const ProductRow = ({ product, onEdit, onDelete, isLockedByEpics }: Props) => {
  const { t } = useTranslation();

  const [isExpanded, setExpanded] = useState<boolean>(false);

  return (
    <>
      <tbody className="Product-Row">
        <tr
          aria-controls={`collapsible-row-${product.id}`}
          aria-expanded={isExpanded}
        >
          <td className="Product-Row__Icon text-center align-middle">
            <i className="fa-regular fa-layer-group" />
          </td>
          <td>
            <Stack
              direction="horizontal"
              className="align-items-center flex-nowrap pe-1"
              onClick={() => setExpanded(!isExpanded)}
              role="button"
            >
              <div>
                {
                  <i
                    className={`fa-regular ${
                      isExpanded ? "fa-chevron-up" : "fa-chevron-down"
                    }`}
                  />
                }
              </div>
              <div className="ps-4">
                <div className="Product-Row__Name" title={product.title}>
                  {product.title}
                </div>
                <div className="Product-Row__Label fw-light">
                  {t("form.xchange_edit.info.product")}
                </div>
              </div>
            </Stack>
          </td>
          <td>{product.sub_domains?.map((sd) => sd.name).join(", ")}</td>
          <td>
            {calcXchangeProductBudgetsByCurrency(product).map((budget) => (
              <div key={budget.currency}>
                {budget.currency + " " + toCurrencyString(budget.total)}
              </div>
            ))}
          </td>
          <td></td>
          <td></td>
          <td className="table-actions">
            <ActionsDropdown
              actions={[
                {
                  label: t("actions.edit"),
                  onClick: (e) => {
                    onEdit?.();
                  },
                },
                {
                  label: t("actions.delete"),
                  onClick: () => {
                    onDelete?.();
                  },
                  disabled: isLockedByEpics,
                  infoText: isLockedByEpics
                    ? t("form.deliverables.info.product_epic_locked")
                    : undefined,
                },
              ]}
            >
              <Button variant="outline-light" className="p-2 pt-1 pb-1">
                <i className="fa-regular fa-ellipsis-vertical"></i>
              </Button>
            </ActionsDropdown>
          </td>
        </tr>
      </tbody>
      {isExpanded && (
        <tbody id={`collapsible-row-${product.id}`}>
          <>
            {product.terms?.map((term, index) => (
              <TermRow key={index} term={term} isProductTerm></TermRow>
            ))}
          </>
        </tbody>
      )}
    </>
  );
};

export default ProductRow;
