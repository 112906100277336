import XchangeSowStatus from "@enum/XchangeSowStatus";
import { ContractStatus } from "@interfaces/IContract";
import { IContractGenerationHook } from "@hooks/contract/useContractGeneration";

const useXchangeSowStatus = (
  contractGenerationHook: IContractGenerationHook
) => {
  const { subjects, contracts } = contractGenerationHook;
  const finalContracts = contracts?.filter(
    (contract) =>
      contract.last_generation_is_preview === false &&
      contract.status !== ContractStatus.ARCHIVED
  );

  if (finalContracts?.length === 0) {
    return XchangeSowStatus.NOT_STARTED;
  } else if (
    subjects.length === finalContracts?.length &&
    finalContracts?.every(
      (contract) => contract.status === ContractStatus.SIGNED
    )
  ) {
    return XchangeSowStatus.SIGNED;
  } else {
    return XchangeSowStatus.IN_PROGRESS;
  }
};

export default useXchangeSowStatus;
