import IRole from "@interfaces/IRole";
import Service from "@services/Service";

export interface IGetRolesParams {
  id__in?: string;
  search?: string;
}

export default class RoleService extends Service {
  public static async getRoles(params?: IGetRolesParams) {
    const response = await this.axios.get<IRole[]>("/roles", {
      params,
    });
    return response.data;
  }

  public static async createRole(role: IRole) {
    const response = await this.axios.post<IRole>("/roles", role);
    return response.data;
  }

  public static async getRole(id: number) {
    const response = await this.axios.get<IRole>(`/roles/${id}`);
    return response.data;
  }
}
