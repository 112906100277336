import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useOnClickOutside from "@hooks/useOnClickOutside";
import { Form, Stack } from "react-bootstrap";
import "./SelectSearch.scss";
import { Control, FormProvider, useController, useForm } from "react-hook-form";
import SearchFilter from "@components/SearchFilter/SearchFilter";

interface SelectSearchFormValues {
  search: string;
}

const SelectSearch = ({
  name,
  control,
  onFormChange,
  options,
  disabled = false,
  isMultiple = false,
  closeOnChange = true,
}: {
  control: Control<any, any>;
  name: string;
  onFormChange: (data: SelectSearchFormValues) => void;
  options: {
    value: number;
    label: string;
    subLabel?: string;
  }[];
  disabled?: boolean;
  isMultiple?: boolean;
  closeOnChange?: boolean;
}) => {
  const { t } = useTranslation();

  const { field } = useController({
    name: name,
    control: control,
  });

  const [showList, setShowList] = useState(false);
  const selectSearchRef = useRef(null);
  useOnClickOutside(selectSearchRef, () => setShowList(false));

  const formMethods = useForm<SelectSearchFormValues>();
  const { handleSubmit } = formMethods;

  return (
    <div ref={selectSearchRef} className="Select-Search">
      <FormProvider {...formMethods}>
        <Form onChange={handleSubmit(onFormChange)}>
          <SearchFilter
            name="search"
            onClick={() => {
              setShowList(true);
            }}
            placeholder={t("filters.search")}
            disabled={disabled}
          />
        </Form>
      </FormProvider>

      {showList && (
        <div className="Select-Search__List">
          {options.length > 0 ? (
            options?.map((option) => (
              <div
                key={`reviewer_${option.value}`}
                className={`Select-Search__List__Option
                ${
                  ((isMultiple && field.value.includes(option.value)) ||
                    field.value === option.value) &&
                  "Select-Search__List__Option--selected"
                }`}
                onClick={() => {
                  if (isMultiple) {
                    if (field.value.includes(option.value)) {
                      field.onChange(
                        field.value.filter((v: number) => v !== option.value)
                      );
                    } else {
                      field.onChange([...field.value, option.value]);
                    }
                  } else {
                    field.onChange(option.value);
                  }
                  closeOnChange && setShowList(false);
                }}
              >
                <Stack direction="horizontal" gap={2}>
                  <div className="Select-Search__List__Option__Title">
                    {option.label}
                  </div>
                  <div className="Select-Search__List__Option__Subtitle ms-auto">
                    {option.subLabel}
                  </div>
                </Stack>
              </div>
            ))
          ) : (
            <div className="Select-Search__List__Empty">
              {t("filters.no_results")}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectSearch;
