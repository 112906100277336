import { Col, Form, OverlayTrigger, Stack, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Overview } from "@components/GenericOverview/Overview/Overview";
import { OverviewSectionStatus } from "@components/GenericOverview/OverviewSectionStatus/OverviewSectionStatus";
import { OverviewSection } from "@components/GenericOverview/OverviewSection/OverviewSection";
import CustomSelect from "@components/CustomSelect/CustomSelect";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { useCatalogProductUpdate } from "@hooks/product/useCatalogProductUpdate";
import ProductStatuses from "@enum/ProductStatuses";
import ICatalogProduct from "@interfaces/products/ICatalogProduct";
import CostRange from "@components/ProductCard/CostRange/CostRange";
import { useCatalogTerms } from "@hooks/term/useCatalogTerms";
import { hasNullCostRanges } from "@helpers/term-utils";
import "./ProductOverview.scss";

const ProductOverview = ({ product }: { product?: ICatalogProduct }) => {
  const updateCatalogProductMutation = useCatalogProductUpdate();
  const { t } = useTranslation();

  const { control, watch, setValue } = useForm<{
    status: ProductStatuses;
  }>();

  useEffect(() => {
    setValue("status", product?.status!);
  }, [product?.status, setValue]);

  useEffect(() => {
    const subscription = watch((data) => {
      updateCatalogProductMutation.mutate({
        id: product?.id!,
        catalogProduct: {
          status: data.status,
        },
      });
    });

    return () => subscription.unsubscribe();
  }, [product?.id, updateCatalogProductMutation, watch]);

  const { data: catalogTermInfiniteData, isLoading: areTermsLoading } =
    useCatalogTerms(
      {
        products: product?.id,
        page_size: 1000,
      },
      {
        enabled: !!product?.id,
      }
    );
  const terms = catalogTermInfiniteData
    ? catalogTermInfiniteData.pages.flatMap((p) => p.results)
    : [];

  return (
    <Overview>
      <OverviewSection>
        <Form.Label>{t("product.overview.status")}</Form.Label>
        <CustomSelect
          name="status"
          control={control}
          options={Object.values(ProductStatuses).map((status) => ({
            value: status,
            label: t(`product.statuses.${status}`),
          }))}
          isDisabled={!product}
        />
      </OverviewSection>
      <OverviewSection>
        <Stack direction="horizontal" className="align-items-start">
          <Col xs={3}>
            <Form.Label>{t("product.overview.terms")}</Form.Label>
            <div className="Product-Overview__Summary">
              {product && areTermsLoading ? (
                <i className="fa-regular fa-spinner fa-spin" />
              ) : (
                terms?.length || 0
              )}
            </div>
          </Col>
          <Col xs={9}>
            <Form.Label>
              {t("product.overview.cost_range")}
              {hasNullCostRanges(terms) && (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>{t("product.details.terms.no_cost_term")}</Tooltip>
                  }
                >
                  <i className="fa-regular fa-circle-info ms-2"></i>
                </OverlayTrigger>
              )}
            </Form.Label>
            <div className="Product-Overview__Summary">
              {product?.cost_ranges?.map((costRange, index) => (
                <CostRange
                  key={`${index}-${costRange.currency}`}
                  costRange={costRange}
                  className={
                    product.cost_ranges?.length &&
                    index !== product.cost_ranges.length - 1
                      ? "mb-1"
                      : ""
                  }
                />
              ))}
            </div>
          </Col>
        </Stack>
      </OverviewSection>
      <OverviewSection>
        <Stack direction="vertical">
          <OverviewSectionStatus
            index={1}
            label={t("product.details.details.title")}
          />
          <OverviewSectionStatus
            index={2}
            label={t("product.details.terms.title")}
          />
        </Stack>
      </OverviewSection>
    </Overview>
  );
};

export default ProductOverview;
