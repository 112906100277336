import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ICompanyCreateUpdate } from "@interfaces/ICompany";
import CompanyService from "@services/CompanyService";

export const useCompanyCreate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (company: ICompanyCreateUpdate) => CompanyService.createCompany(company),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["companies"]);
      },
    }
  );
};
