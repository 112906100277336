import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Page from "@components/Page/Page";
import StepCard from "@components/StepCard/StepCard";
import { useCatalogProduct } from "@hooks/product/useCatalogProduct";
import NotFoundPage from "@pages/NotFoundPage";
import ProductDetailsForm from "./ProductDetailsForm/ProductDetailsForm";
import ProductTerms from "./ProductTerms/ProductTerms";
import ProductOverview from "./ProductOverview/ProductOverview";

const ProductEditPage = () => {
  const { t } = useTranslation();
  const params = useParams<{ id: string }>();
  const { id: productId } = params;
  const createMode = productId === "create";

  const {
    isLoading: isProductLoading,
    data: product,
    isError: productError,
  } = useCatalogProduct(parseInt(productId!), {
    enabled: !!productId && !createMode,
    retry: false,
  });

  return productError ? (
    <NotFoundPage />
  ) : (
    <Page
      variant="primary"
      title={product?.title || t("product.details.create")}
      breadcrumbs={[
        { label: t("nav.products"), path: "/products" },
        {
          label: product?.title || t("nav.create"),
          path: `/products/${productId}`,
        },
      ]}
      sideBarContent={<ProductOverview product={product} />}
    >
      {!createMode && isProductLoading ? (
        <div className="text-center mt-4 mb-4">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          <StepCard
            subtitle={`${t("form.step")} 1`}
            title={t("product.details.details.title")}
          >
            <ProductDetailsForm product={product!} />
          </StepCard>
          <StepCard
            subtitle={`${t("form.step")} 2`}
            title={t("product.details.terms.title")}
          >
            <ProductTerms product={product} />
          </StepCard>
        </>
      )}
    </Page>
  );
};

export default ProductEditPage;
