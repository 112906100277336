import React, { useMemo } from "react";
import { Dropdown, FormCheck, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./ActionsDropdown.scss";
import { DropDirection } from "react-bootstrap/esm/DropdownContext";

type ToggleProps = {
  children: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const ListOptionsToggle = React.forwardRef<HTMLInputElement, ToggleProps>(
  ({ children, onClick }, ref) => (
    <div
      ref={ref}
      role="button"
      className="d-inline-block"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  )
);

export interface IActionsDropdownAction {
  label: string;
  onClick: React.MouseEventHandler<HTMLElement>;
  isToggle?: boolean;
  isSelected?: boolean;
  disabled?: boolean;
  infoText?: string;
}

const ActionsDropdown = ({
  children,
  actions,
  drop = "up",
  boundary,
  popperStrategy = "fixed",
  onToggle,
}: {
  children: React.ReactNode;
  actions: IActionsDropdownAction[];
  drop?: DropDirection;
  boundary?: Element | null;
  popperStrategy?: "fixed" | "absolute";
  onToggle?: () => void;
}) => {
  const handleActionClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    action: IActionsDropdownAction
  ) => {
    if (action.disabled) {
      event.stopPropagation();
      return;
    }

    if (action.isToggle) {
      event.stopPropagation();
    }

    action.onClick(event);
  };

  const modifiers = useMemo(() => {
    const modifiers = [];

    if (boundary) {
      modifiers.push({
        name: "preventOverflow",
        options: {
          boundary,
        },
      });
    }

    return modifiers;
  }, [boundary]);

  return (
    <Dropdown className="Actions-Dropdown" drop={drop} onToggle={onToggle}>
      <Dropdown.Toggle as={ListOptionsToggle}>{children}</Dropdown.Toggle>
      <Dropdown.Menu
        popperConfig={{
          strategy: popperStrategy,
          onFirstUpdate: () => window.dispatchEvent(new CustomEvent("scroll")),
          modifiers,
        }}
      >
        {actions.map((action, index) => (
          <React.Fragment key={index}>
            <Dropdown.Item
              onClick={(event) => {
                handleActionClick(event, action);
              }}
              className={`${
                action.disabled ? "Actions-Dropdown__Disabled" : ""
              } ${action.isSelected ? "Actions-Dropdown--selected" : ""}`}
            >
              {action.isToggle && (
                <FormCheck
                  className="Actions-Dropdown__Toggle"
                  checked={action.isSelected}
                  readOnly
                />
              )}
              {action.label}
              {action.infoText && (
                <OverlayTrigger overlay={<Tooltip>{action.infoText}</Tooltip>}>
                  <i className="fa-solid fa-circle-question ms-2"></i>
                </OverlayTrigger>
              )}
            </Dropdown.Item>
          </React.Fragment>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ActionsDropdown;
