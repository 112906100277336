import { getSubjectType } from "@helpers/contract-utils";
import { getXpertXchangeTermTitles } from "@helpers/xchange-utils";
import { PartySubjectType } from "@interfaces/IParty";
import { useXchange } from "@hooks/xchange/useXchange";
import { IContractGenerationSubject } from "./useContractGeneration";

const useGetSubjectTitle = (subject: IContractGenerationSubject) => {
  const subjectType = getSubjectType(subject);

  const { data: xchange } = useXchange(subject.filters?.xchange!, {
    enabled: !!subject.filters?.xchange,
  });

  switch (subjectType) {
    case PartySubjectType.Xpert:
      return xchange
        ? getXpertXchangeTermTitles(xchange, subject.subjectId)
        : "";
    case PartySubjectType.Client:
      return xchange ? xchange.name : "";
    default:
      return "";
  }
};

export default useGetSubjectTitle;
