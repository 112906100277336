import ContractDashboard from "@components/ContractDashboard/ContractDashboard";
import { getXchangeContractSubjects } from "@helpers/xchange-utils";
import useContractGeneration from "@hooks/contract/useContractGeneration";
import { ProtectedContractTypeCodes } from "@interfaces/IContractType";
import IXchange from "@interfaces/IXchange";

const XchangeSOW = ({ xchange }: { xchange?: IXchange }) => {
  const contractGenerationHook = useContractGeneration(
    getXchangeContractSubjects(xchange),
    [
      ProtectedContractTypeCodes.XPERT_SOW,
      ProtectedContractTypeCodes.CLIENT_SOW,
    ]
  );

  return (
    <ContractDashboard
      contractGenerationHook={contractGenerationHook}
      contractTranslationRootKey="contracts.sow"
    />
  );
};

export default XchangeSOW;
