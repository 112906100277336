import { Stack } from "react-bootstrap";
import { usePerson } from "@hooks/person/usePerson";
import "./PersonCard.scss";
import { useTranslation } from "react-i18next";

const PersonCard = ({
  personId,
  title,
  isDisabled,
  children,
  onDelete,
}: {
  personId: number;
  title?: string;
  isDisabled?: boolean;
  children?: React.ReactNode;
  onDelete?: () => void;
}) => {
  const { t } = useTranslation();

  const { isLoading: isPersonLoading, data: person } = usePerson(personId);

  return (
    <Stack
      direction="horizontal"
      gap={1}
      className={`Person-Card ${isDisabled && "Person-Card--disabled"}`}
    >
      <div className="Person-Card__Name">
        {isPersonLoading
          ? t("loading")
          : `${person?.first_name} ${person?.last_name}`}

        {title && <span className="Person-Card__Title">| {title}</span>}
      </div>

      <div className="Person-Card__Actions ms-auto">
        {children && <div>{children}</div>}

        {onDelete && (
          <div className="Person-Card__Actions__Close" onClick={onDelete}>
            <i className="fa-solid fa-times" />
          </div>
        )}
      </div>
    </Stack>
  );
};

export default PersonCard;
