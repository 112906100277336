import { CompanyStatuses } from "@interfaces/ICompany";
import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./CompanyStatusBadge.scss";

const CompanyStatusbadge = ({
  status,
  className,
}: {
  status: CompanyStatuses;
  className?: string;
}) => {
  const { t } = useTranslation();

  return (
    <Badge className={`Company-Status-Badge--${status} ${className}`}>
      {t(`companies.statuses.${status}`)}
    </Badge>
  );
};

export default CompanyStatusbadge;
