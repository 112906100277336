import CustomSelect from "@components/CustomSelect/CustomSelect";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import { joiResolver } from "@hookform/resolvers/joi";
import { useCompanyUpdate } from "@hooks/company/useCompanyUpdate";
import ICompany, { CompanySizes } from "@interfaces/ICompany";
import {
  CompanyProfileDetailsFormValues,
  companyProfileDetailsValidationSchema,
} from "@pages/Company/CompanyEditPage/CompanyEditConfig";
import { isEqual } from "lodash";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, FormProvider, set, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";

const CompanyProfileDetails = ({ company }: { company?: ICompany }) => {
  const { t } = useTranslation();
  const updateCompanyMutation = useCompanyUpdate();

  const formMethods = useForm<CompanyProfileDetailsFormValues>({
    mode: "onChange",
    resolver: joiResolver(companyProfileDetailsValidationSchema),
    defaultValues: {
      company_size: company?.company_size,
      company_description: company?.company_description || "",
    },
  });
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const [pastOnBlurData, setPastOnBlurData] =
    useState<CompanyProfileDetailsFormValues>(getValues());

  const formSubmit = (data: CompanyProfileDetailsFormValues) => {
    const changedValues = Object.keys(data).reduce((acc, key) => {
      if (
        !isEqual(
          data[key as keyof CompanyProfileDetailsFormValues],
          pastOnBlurData[key as keyof CompanyProfileDetailsFormValues]
        )
      ) {
        set(acc, key, data[key as keyof CompanyProfileDetailsFormValues]);
      }
      return acc;
    }, {} as CompanyProfileDetailsFormValues);

    if (Object.keys(changedValues).length > 0) {
      updateCompanyMutation.mutate({
        updatedCompany: {
          id: company?.id!,
          ...changedValues,
        },
      });
    }

    setPastOnBlurData(data);
  };

  return (
    <FormProvider {...formMethods}>
      <Form onBlur={handleSubmit(formSubmit)}>
        <Row className="pe-4 ps-4">
          <Form.Group className="mb-4" as={Col} xs={12}>
            <Form.Label>
              {t("companies.profile.details.fields.company_size")}
            </Form.Label>
            <CustomSelect
              control={control}
              name="company_size"
              options={Object.keys(CompanySizes).map((key) => ({
                value: CompanySizes[key as keyof typeof CompanySizes],
                label: t(
                  `onboardings.xpert.company.company_size_options.${key}`
                ),
              }))}
              onAfterChange={() => {
                handleSubmit(formSubmit)();
              }}
              isDisabled={!company}
            />
            <FieldErrorMessage
              field={errors.company_size}
              fieldName={t("companies.profile.details.fields.company_size")}
            />
          </Form.Group>

          <Form.Group className="mb-4" as={Col} xs={12}>
            <Form.Label>
              {t("companies.profile.details.fields.company_description")}
            </Form.Label>

            <Controller
              control={control}
              name="company_description"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <>
                  <ReactQuill
                    className={`${error && "ql-invalid"} ${
                      !company && "ql-disabled"
                    }`}
                    value={value}
                    onChange={(value) => {
                      if (value.replace(/(<([^>]+)>)/gi, "")) {
                        onChange(value);
                      } else {
                        onChange(null);
                      }
                    }}
                    readOnly={!company}
                  />
                  <FieldErrorMessage
                    field={error}
                    fieldName={t(
                      "companies.profile.details.fields.company_description"
                    )}
                  />
                </>
              )}
            />
          </Form.Group>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default CompanyProfileDetails;
