import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./CompanyStep.scss";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import {
  XpertOnboardingCompanyStepFields,
  XpertOnboardingCompanyStepFormValues,
  XpertOnboardingCompanyStepValidationSchema,
} from "@pages/Onboardings/XpertOnboardingPage/XpertOnboardingConfigs";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import CustomSelect from "@components/CustomSelect/CustomSelect";
import { CompanySizes } from "@interfaces/ICompany";
import CountrySelect from "@components/CountrySelect/CountrySelect";
import { joiResolver } from "@hookform/resolvers/joi";
import { useCallback, useEffect } from "react";
import { debounce } from "lodash";

const CompanyStep = ({
  back,
  next,
}: {
  back: () => void;
  next: () => void;
}) => {
  const { t } = useTranslation();

  const {
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  // This step instanciates its own form, to be able to run external validations
  // without requirering the main form to be completely valid.
  const localFormMethods = useForm<XpertOnboardingCompanyStepFormValues>({
    mode: "onChange",
    resolver: joiResolver(XpertOnboardingCompanyStepValidationSchema),
    defaultValues: XpertOnboardingCompanyStepFields.reduce(
      (
        acc: {
          [key: string]: any;
        },
        key
      ) => {
        acc[key] = getValues(key);
        return acc;
      },
      {}
    ),
  });
  const {
    watch: watchLocal,
    register: registerLocal,
    handleSubmit: handleLocalSubmit,
    trigger: triggerLocal,
    setValue: setValueLocal,
    control: controlLocal,
    formState: {
      errors: localErrors,
      isDirty: isDirtyLocal,
      submitCount: submitCountLocal,
    },
  } = localFormMethods;

  const debouncedTriggerLocal = debounce(
    (field: keyof XpertOnboardingCompanyStepFormValues) => {
      triggerLocal(field);
    },
    500
  );

  const getFieldError = (field: keyof XpertOnboardingCompanyStepFormValues) => {
    return submitCountLocal > 0 || isDirtyLocal
      ? localErrors[field]
      : errors[field];
  };

  const setMainFormValues = useCallback(
    (data: XpertOnboardingCompanyStepFormValues) => {
      Object.keys(data).forEach((key) => {
        setValue(
          key as keyof XpertOnboardingCompanyStepFormValues,
          data[key as keyof XpertOnboardingCompanyStepFormValues]
        );
      });
    },
    [setValue]
  );

  useEffect(() => {
    const subscription = watchLocal((data) => {
      setMainFormValues(data);
    });
    return () => subscription.unsubscribe();
  }, [setMainFormValues, watchLocal]);

  return (
    <div className="Company-Step fade-in">
      <h3 className="mb-2">{t("onboardings.xpert.company.title")}</h3>
      <p>{t("onboardings.xpert.company.description")}</p>

      <Row>
        <Form.Group className="mb-4" as={Col} xs={12}>
          <Form.Label>
            {t("onboardings.xpert.company.fields.company_name")}
          </Form.Label>
          <Form.Control
            type="text"
            {...registerLocal("company_name")}
            isInvalid={!!getFieldError("company_name")}
            onChange={(e) => {
              setValueLocal("company_name", e.target.value, {
                shouldDirty: true,
              });
              debouncedTriggerLocal("company_name");
            }}
          />
          <FieldErrorMessage
            field={getFieldError("company_name")}
            fieldName={t("onboardings.xpert.company.fields.company_name")}
          />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group className="mb-4" as={Col} xs={12}>
          <Stack direction="horizontal">
            <Form.Label>
              {t("onboardings.xpert.company.fields.company_website_url")}
            </Form.Label>
            <div className="Company-Step__Optional-Field-Label">
              *{t("form.optional")}
            </div>
          </Stack>
          <Form.Control
            type="text"
            {...registerLocal("company_website_url")}
            isInvalid={!!getFieldError("company_website_url")}
          />
          <FieldErrorMessage
            field={getFieldError("company_website_url")}
            fieldName={t(
              "onboardings.xpert.company.fields.company_website_url"
            )}
          />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group className="mb-4" as={Col} xs={12}>
          <Form.Label>
            {t("onboardings.xpert.company.fields.company_size")}
          </Form.Label>
          <CustomSelect
            control={controlLocal}
            name="company_size"
            options={Object.keys(CompanySizes).map((key) => ({
              value: CompanySizes[key as keyof typeof CompanySizes],
              label: t(`onboardings.xpert.company.company_size_options.${key}`),
            }))}
          />
          <FieldErrorMessage
            field={getFieldError("company_size")}
            fieldName={t("onboardings.xpert.company.fields.company_size")}
          />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group className="mb-4" as={Col} xs={12}>
          <FormProvider {...localFormMethods}>
            <CountrySelect
              label={t("onboardings.xpert.company.fields.country")}
              FormValidationSchema={XpertOnboardingCompanyStepValidationSchema}
              showRequiredAsterisk={false}
              affectsRegion={false}
            />
          </FormProvider>
        </Form.Group>
      </Row>

      <Stack direction="horizontal" gap={2} className="mt-5">
        <Button variant="outline-light" className="w-100" onClick={back}>
          {t("wizard.back")}
        </Button>
        <Button
          className="w-100"
          onClick={() => {
            handleLocalSubmit((data) => {
              setMainFormValues(data);
              next();
            })();
          }}
        >
          {t("wizard.next")}
        </Button>
      </Stack>
    </div>
  );
};

export default CompanyStep;
