import { joiResolver } from "@hookform/resolvers/joi";
import { CA, US } from "country-region-data";
import Joi from "joi";
import { useState } from "react";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomSelect from "@components/CustomSelect/CustomSelect";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import InfoInput from "@components/InfoInput/InfoInput";
import { useXpertUpdate } from "@hooks/xpert/useXpertUpdate";
import IXpert from "@interfaces/IXpert";

export interface XpertFinanceInsuranceFormValues {
  insurance_coverage?: number | null;
  insurance_policy_link?: string;
  bank_details?: {
    country?: string;
    canada?: {
      transit?: string;
      institution?: string;
      account?: string;
      taxId?: string;
      recipient?: string;
    };
    unitedStates?: {
      name?: string;
      accountType?: string;
      account?: string;
      routing?: string;
      swift?: string;
    };
  };
}

export const xpertFinanceInsuranceValidationSchema = Joi.object({
  bank_details: Joi.object({
    country: Joi.string().allow("", null),
    canada: Joi.object({
      transit: Joi.string().allow(""),
      institution: Joi.string().allow(""),
      account: Joi.string().allow(""),
      taxId: Joi.string().allow(""),
      recipient: Joi.string().allow(""),
    }),
    unitedStates: Joi.object({
      name: Joi.string().allow(""),
      accountType: Joi.string().allow(""),
      account: Joi.string().allow(""),
      routing: Joi.string().allow(""),
      swift: Joi.string().allow(""),
    }),
  }),
  insurance_coverage: Joi.number().integer().allow(null).allow(""),
  insurance_policy_link: Joi.string().uri().allow(""),
});

const XpertFinanceInsuranceForm = ({ xpert }: { xpert: IXpert }) => {
  const { country, ...bankDetails } = xpert?.bank_details ?? {};

  const { t } = useTranslation();
  const [showBankingInfo, setShowBankingInfo] = useState(!xpert?.bank_details);
  const updateXpertMutation = useXpertUpdate();

  const formMethods = useForm<XpertFinanceInsuranceFormValues>({
    mode: "onChange",
    resolver: joiResolver(xpertFinanceInsuranceValidationSchema),
    defaultValues: {
      bank_details: {
        country: country,
        ...(country === CA[0].toString() && {
          canada: bankDetails,
        }),
        ...(country === US[0].toString() && {
          unitedStates: bankDetails,
        }),
      },
      insurance_coverage: xpert?.insurance_coverage,
      insurance_policy_link: xpert?.insurance_policy_link ?? "",
    },
  });
  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = formMethods;

  const formSubmit = (data: XpertFinanceInsuranceFormValues) => {
    updateXpertMutation.mutate({
      updatedXpert: {
        id: xpert?.id!,
        ...data,
        insurance_coverage: data.insurance_coverage
          ? data.insurance_coverage
          : null,
        bank_details:
          data.bank_details?.country === CA[0].toString()
            ? {
                country: data.bank_details?.country,
                ...data.bank_details.canada,
              }
            : {
                country: data.bank_details?.country,
                ...data.bank_details?.unitedStates,
              },
      },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <Form onBlur={handleSubmit(formSubmit)} className="ps-4 pe-4 pb-4">
        <Stack direction="horizontal" gap={3} className="mb-5">
          <h5 className="mb-5">
            {t("companies.details.xpert.finance_insurance.finance")}
          </h5>
          <Button
            className="ms-auto"
            size="sm"
            onClick={() => {
              setShowBankingInfo(!showBankingInfo);
            }}
          >
            {showBankingInfo ? (
              <>
                <i className="fa-regular fa-eye-slash"></i>{" "}
                {t(
                  "companies.details.xpert.finance_insurance.hide_banking_info"
                )}
              </>
            ) : (
              <>
                <i className="fa-regular fa-eye"></i>{" "}
                {t(
                  "companies.details.xpert.finance_insurance.show_banking_info"
                )}
              </>
            )}
          </Button>
        </Stack>
        <Form.Group className="mb-3">
          <Form.Label>
            {t("companies.details.xpert.finance_insurance.fields.country")}
          </Form.Label>
          <CustomSelect
            control={control}
            name="bank_details.country"
            options={[CA, US].map((country) => ({
              value: country[0].toString()!,
              label: country[0],
            }))}
            isClearable
          />
          <FieldErrorMessage
            field={errors.bank_details?.country}
            fieldName={t(
              "companies.details.xpert.finance_insurance.fields.country"
            )}
          />
        </Form.Group>
        {watch("bank_details.country") === CA[0].toString() && (
          <>
            <Row>
              <Form.Group className="mb-3 pe-0" as={Col} lg={3}>
                <Form.Label>
                  {t(
                    "companies.details.xpert.finance_insurance.fields.ca.institution_number"
                  )}
                </Form.Label>
                <InfoInput
                  type={showBankingInfo ? "text" : "password"}
                  infoText={t(
                    "companies.details.xpert.finance_insurance.fields.ca.institution_number"
                  )}
                  {...register("bank_details.canada.institution")}
                  disabled={!showBankingInfo}
                />
              </Form.Group>
              <Form.Group className="mb-3 pe-0" as={Col} lg={3}>
                <Form.Label>
                  {t(
                    "companies.details.xpert.finance_insurance.fields.ca.transit_number"
                  )}
                </Form.Label>
                <InfoInput
                  type={showBankingInfo ? "text" : "password"}
                  infoText={t(
                    "companies.details.xpert.finance_insurance.fields.ca.transit_number"
                  )}
                  {...register("bank_details.canada.transit")}
                  disabled={!showBankingInfo}
                />
                <FieldErrorMessage
                  field={errors.bank_details?.canada?.transit}
                  fieldName={t(
                    "companies.details.xpert.finance_insurance.fields.ca.transit_number"
                  )}
                />
              </Form.Group>
              <Form.Group className="mb-3" as={Col} lg={6}>
                <Form.Label>
                  {t(
                    "companies.details.xpert.finance_insurance.fields.ca.account_number"
                  )}
                </Form.Label>
                <InfoInput
                  type={showBankingInfo ? "text" : "password"}
                  infoText={t(
                    "companies.details.xpert.finance_insurance.fields.ca.account_number"
                  )}
                  {...register("bank_details.canada.account")}
                  disabled={!showBankingInfo}
                />
                <FieldErrorMessage
                  field={errors.bank_details?.canada?.account}
                  fieldName={t(
                    "companies.details.xpert.finance_insurance.fields.ca.account_number"
                  )}
                />
              </Form.Group>
            </Row>
            <Form.Group className="mb-3">
              <Form.Label>
                {t(
                  "companies.details.xpert.finance_insurance.fields.ca.gst_hst"
                )}
              </Form.Label>
              <InfoInput
                type="text"
                infoText={t(
                  "companies.details.xpert.finance_insurance.fields.ca.gst_hst"
                )}
                {...register("bank_details.canada.taxId")}
              />
              <FieldErrorMessage
                field={errors.bank_details?.canada?.taxId}
                fieldName={t(
                  "companies.details.xpert.finance_insurance.fields.ca.gst_hst"
                )}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                {t(
                  "companies.details.xpert.finance_insurance.fields.ca.recipient"
                )}
              </Form.Label>
              <InfoInput
                type="text"
                infoText={t(
                  "companies.details.xpert.finance_insurance.fields.ca.recipient"
                )}
                {...register("bank_details.canada.recipient")}
              />
              <FieldErrorMessage
                field={errors.bank_details?.canada?.recipient}
                fieldName={t(
                  "companies.details.xpert.finance_insurance.fields.ca.recipient"
                )}
              />
            </Form.Group>
          </>
        )}
        {watch("bank_details.country") === US[0].toString() && (
          <>
            <Form.Group className="mb-3">
              <Form.Label>
                {t("companies.details.xpert.finance_insurance.fields.us.name")}
              </Form.Label>
              <InfoInput
                type="text"
                infoText={t(
                  "companies.details.xpert.finance_insurance.fields.us.name"
                )}
                {...register("bank_details.unitedStates.name")}
              />
              <FieldErrorMessage
                field={errors.bank_details?.unitedStates?.name}
                fieldName={t(
                  "companies.details.xpert.finance_insurance.fields.us.name"
                )}
              />
            </Form.Group>
            <Row>
              <Form.Group className="mb-3" as={Col}>
                <Form.Label>
                  {t(
                    "companies.details.xpert.finance_insurance.fields.us.account_type"
                  )}
                </Form.Label>

                <InfoInput
                  type={showBankingInfo ? "text" : "password"}
                  infoText={t(
                    "companies.details.xpert.finance_insurance.fields.us.account_type"
                  )}
                  {...register("bank_details.unitedStates.accountType")}
                  disabled={!showBankingInfo}
                />
                <FieldErrorMessage
                  field={errors.bank_details?.unitedStates?.accountType}
                  fieldName={t(
                    "companies.details.xpert.finance_insurance.fields.us.account_type"
                  )}
                />
              </Form.Group>
              <Form.Group className="mb-3" as={Col}>
                <Form.Label>
                  {t(
                    "companies.details.xpert.finance_insurance.fields.us.account_number"
                  )}
                </Form.Label>
                <InfoInput
                  type={showBankingInfo ? "text" : "password"}
                  infoText={t(
                    "companies.details.xpert.finance_insurance.fields.us.account_number"
                  )}
                  {...register("bank_details.unitedStates.account")}
                  disabled={!showBankingInfo}
                />
                <FieldErrorMessage
                  field={errors.bank_details?.unitedStates?.account}
                  fieldName={t(
                    "companies.details.xpert.finance_insurance.fields.us.account_number"
                  )}
                />
              </Form.Group>
              <Form.Group className="mb-3" as={Col}>
                <Form.Label>
                  {t(
                    "companies.details.xpert.finance_insurance.fields.us.routing_number"
                  )}
                </Form.Label>
                <InfoInput
                  type={showBankingInfo ? "text" : "password"}
                  infoText={t(
                    "companies.details.xpert.finance_insurance.fields.us.routing_number"
                  )}
                  {...register("bank_details.unitedStates.routing")}
                  disabled={!showBankingInfo}
                />
                <FieldErrorMessage
                  field={errors.bank_details?.unitedStates?.routing}
                  fieldName={t(
                    "companies.details.xpert.finance_insurance.fields.us.routing_number"
                  )}
                />
              </Form.Group>
            </Row>
            <Form.Group className="mb-3">
              <Form.Label>
                {t(
                  "companies.details.xpert.finance_insurance.fields.us.swift_number"
                )}
              </Form.Label>
              <InfoInput
                type={showBankingInfo ? "text" : "password"}
                infoText={t(
                  "companies.details.xpert.finance_insurance.fields.us.swift_number"
                )}
                {...register("bank_details.unitedStates.swift")}
                disabled={!showBankingInfo}
              />
              <FieldErrorMessage
                field={errors.bank_details?.unitedStates?.swift}
                fieldName={t(
                  "companies.details.xpert.finance_insurance.fields.us.swift_number"
                )}
              />
            </Form.Group>
          </>
        )}
        <h5 className="mt-6 mb-5">
          {t("companies.details.xpert.finance_insurance.insurance")}
        </h5>
        <Form.Group className="mb-3">
          <Form.Label>
            {t("companies.details.xpert.finance_insurance.fields.liability")}
          </Form.Label>
          <InfoInput
            infoText={t(
              "companies.details.xpert.finance_insurance.fields.liability"
            )}
            type="number"
            {...register("insurance_coverage")}
            isInvalid={!!errors.insurance_coverage}
          />
          <FieldErrorMessage
            field={errors.insurance_coverage}
            fieldName={t(
              "companies.details.xpert.finance_insurance.fields.liability"
            )}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {t("companies.details.xpert.finance_insurance.fields.policy_link")}
          </Form.Label>
          <Form.Control
            type="text"
            {...register("insurance_policy_link")}
            isInvalid={!!errors.insurance_policy_link}
          ></Form.Control>
          <FieldErrorMessage
            field={errors.insurance_policy_link}
            fieldName={t(
              "companies.details.xpert.finance_insurance.fields.policy_link"
            )}
          />
        </Form.Group>
      </Form>
    </FormProvider>
  );
};

export default XpertFinanceInsuranceForm;
