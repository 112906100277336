import { RefAttributes, useEffect, useRef, useState } from "react";
import { Control, useController } from "react-hook-form";
import SelectComponent, {
  components,
  ControlProps,
  IndicatorsContainerProps,
  InputProps,
  MenuProps,
  MultiValueProps,
  MultiValueRemoveProps,
  PlaceholderProps,
  ValueContainerProps,
} from "react-select";
import Select from "react-select/dist/declarations/src/Select";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";
import "./SelectWithCreation.scss";

const ValueContainer = ({
  children,
  ...props
}: ValueContainerProps<any, any, any>) => (
  <components.ValueContainer
    {...props}
    className="Select-With-Creation__ValueContainer"
  >
    {children}
  </components.ValueContainer>
);

const MultiValue = (props: MultiValueProps<any, any, any>) => {
  return (
    <components.MultiValue
      className="Select-With-Creation__MultiValue"
      {...props}
    />
  );
};

const MultiValueRemove = (props: MultiValueRemoveProps<any, any, any>) => {
  return (
    <div className="Select-With-Creation__MultiValueRemove">
      <components.MultiValueRemove {...props}></components.MultiValueRemove>
    </div>
  );
};

const ControlComponent = (props: ControlProps<any, any, any>) => {
  return (
    <components.Control
      {...props}
      className="Select-With-Creation__Control"
    ></components.Control>
  );
};

const IndicatorsContainer = (
  props: IndicatorsContainerProps<any, any, any>
) => {
  return <></>;
};

const Input = (
  props: InputProps<any, any, any> & {
    inputOnKeyUpWithoutOptions?: () => void;
    inputType?: "text" | "number";
  }
) => {
  const inputProps = { ...props };
  delete inputProps.inputOnKeyUpWithoutOptions;
  delete inputProps.inputType;

  return (
    <components.Input
      {...inputProps}
      className="Select-With-Creation__Input"
      placeholder="+ Add"
      innerRef={props.innerRef}
      onKeyUp={(e) => {
        if (e.key === "Enter") {
          // This can only be reached if there is no options in the menu
          props.inputOnKeyUpWithoutOptions?.();
        }
      }}
      type={props.inputType ?? "text"}
    ></components.Input>
  );
};

const Placeholder = (props: PlaceholderProps<any, any, any>) => {
  return <></>;
};
const Menu = (props: MenuProps<any, any, any> & { leftOffset: number }) => {
  return (
    <div
      style={{
        marginLeft: props.leftOffset,
      }}
    >
      <components.Menu
        {...props}
        className="Select-With-Creation__Menu"
      ></components.Menu>
    </div>
  );
};

const SelectWithCreation = (
  props: StateManagerProps<{ value: any; label: string }[], true, any> &
    RefAttributes<Select<{ value: any; label: string }[], true, any>> & {
      onEnterWithoutOptions?: (value: string) => void;
    } & { control: Control; name: string; inputType?: "text" | "number" }
) => {
  const {
    field,
    formState: { errors },
  } = useController({
    name: props.name,
    control: props.control,
  });

  const [menuLeftOffset, setMenuLeftOffset] = useState(0);

  const inputInstance = useRef<HTMLInputElement | null>();
  const inputRef = (instance: HTMLInputElement | null) => {
    inputInstance.current = instance;
    setMenuLeftOffset(
      (instance?.parentElement?.getBoundingClientRect()?.left || 0) -
        (instance?.parentElement?.parentElement?.getBoundingClientRect()
          ?.left || 0)
    );
  };

  const [isMenuOpen, setIsMenuOpen] = useState(props.menuIsOpen);
  const inputOnKeyUpWithoutOptions = () => {
    if (props.onEnterWithoutOptions) {
      if (inputInstance.current) {
        props.onEnterWithoutOptions(inputInstance.current.value);

        // Alternative way to change value because React library overrides input value setter and it provokes rerender
        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
          window.HTMLInputElement.prototype,
          "value"
        )!.set;
        nativeInputValueSetter!.call(inputInstance.current, "");
        const customValueChangeEvent = new Event("input", { bubbles: true });
        inputInstance.current.dispatchEvent(customValueChangeEvent);
      }

      setIsMenuOpen(false);
    }
  };

  // Focus behaviour override to fix having to double click on input to start writing text
  useEffect(() => {
    if (isMenuOpen) {
      setTimeout(() => {
        inputInstance.current?.focus();
      }, 1);
    } else {
      setTimeout(() => {
        inputInstance.current?.blur();
      }, 1);
    }
  }, [isMenuOpen]);

  return (
    <div>
      <SelectComponent
        {...props}
        className={`Select-With-Creation select ${
          !!errors[field.name] ? "Select-With-Creation--invalid" : ""
        }`}
        menuIsOpen={
          props.value?.length !== props.options?.length ? isMenuOpen : false
        }
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        components={{
          ValueContainer,
          MultiValue,
          MultiValueRemove,
          Control: ControlComponent,
          IndicatorsContainer,
          Input: (inputProps: InputProps<any, any, any>) => (
            <Input
              {...inputProps}
              innerRef={inputRef}
              inputOnKeyUpWithoutOptions={
                props.onEnterWithoutOptions
                  ? inputOnKeyUpWithoutOptions
                  : undefined
              }
              inputType={props.inputType}
            />
          ),
          Placeholder,
          Menu: (menuProps: MenuProps<any, any, any>) => (
            <Menu {...menuProps} leftOffset={menuLeftOffset} />
          ),
        }}
        isMulti
        isClearable={false}
        onChange={(options) => {
          field.onChange(options.map((option) => option.value));
        }}
        value={
          props.options?.filter((option) =>
            field.value?.includes(option.value)
          ) || null
        }
        name={field.name}
      />
    </div>
  );
};

export default SelectWithCreation;
