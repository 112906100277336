import StepCard from "@components/StepCard/StepCard";
import useElementAnchoring from "@hooks/useElementAnchoring";
import ICompany from "@interfaces/ICompany";
import CompanyDetailsRead from "@pages/Company/CompanyEditPage/CompanyGeneralOverview/CompanyDetails/ComapnyDetailsRead/ComapnyDetailsRead";
import CompanyDetailsForm from "@pages/Company/CompanyEditPage/CompanyGeneralOverview/CompanyDetailsForm/CompanyDetailsForm";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const CompanyDetails = ({ company }: { company?: ICompany }) => {
  const { t } = useTranslation();

  const [defaultView, setDefaultView] = useState<"read" | "write">(
    !company ? "write" : "read"
  );

  useElementAnchoring((hash) => {
    if (["#company-general", "#company-location"]?.includes(hash)) {
      setDefaultView("write");
    }
  });

  return (
    <StepCard
      title={t("companies.details.overview.general_details.title")}
      readView={<CompanyDetailsRead company={company!} />}
      writeView={<CompanyDetailsForm company={company} />}
      defaultView={defaultView}
      onViewChange={(view) => setDefaultView(view)}
      disableViewChange={!company}
    />
  );
};

export default CompanyDetails;
