import { MouseEventHandler } from "react";
import ListItemCheckbox from "@components/ListItemCheckbox/ListItemCheckbox";
import IBill from "@interfaces/IBill";
import { useCompany } from "@hooks/company/useCompany";
import { toCurrencyString } from "@helpers/currencyUtils";
import { useTranslation } from "react-i18next";
import BillStatusBadge from "@components/BillStatusBadge/BillStatusBadge";
import { toRangeString } from "@helpers/date-utils";

const BillsSelectorItem = ({
  bill,
  onClick,
  checked,
  disabled = false,
}: {
  bill: IBill;
  onClick: MouseEventHandler<HTMLInputElement>;
  checked: boolean;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();

  const { data: company } = useCompany(bill.xpert?.object_id!, {
    enabled:
      !!bill.xpert?.company_person &&
      bill.xpert?.company_person.includes("companies"),
  });

  return (
    <ListItemCheckbox
      key={bill.id}
      title={bill.xpert?.name!}
      description={bill.xero_invoice?.invoice_number}
      src={company?.company_logo ?? "/logo-light.png"}
      statistics={[
        {
          label: t("billing.history.payables"),
          value: `${bill.xero_invoice?.currency_code} ${toCurrencyString(
            bill?.xero_invoice?.sub_total
          )}`,
          badge: bill.status ? (
            <BillStatusBadge status={bill.status} />
          ) : undefined,
        },
        {
          label: t("billing.history.billing_period"),
          value:
            bill.billing_period_start && bill.billing_period_end
              ? toRangeString(
                  new Date(bill.billing_period_start),
                  new Date(bill.billing_period_end)
                )
              : "-",
        },
      ]}
      onClick={onClick}
      checked={checked}
      disabled={disabled}
    />
  );
};

export default BillsSelectorItem;
