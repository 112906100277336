import { Badge, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./CompanyCard.scss";
import ICompany, { ICompanyXchangeStats } from "@interfaces/ICompany";
import { useTranslation } from "react-i18next";
import ContractStatusBadge from "@components/ContractStatusBadge/ContractStatusBadge";
import CompanyStatusbadge from "@components/CompanyStatusBadge/CompanyStatusBadge";

const CompanyCard = ({ company }: { company: ICompany }) => {
  const { t } = useTranslation();

  return (
    <Link className="Company-Card" to={`/companies/${company.id}`}>
      <Card className="p-2 pt-3 border-0">
        <Card.Body>
          <Row>
            <Col xl={5} md={6} sm={12} className="d-flex">
              <img
                className="Company-Card__Logo me-4"
                src={company.company_logo ?? "/logo-light.png"}
                alt={company.company_name}
              />
              <div>
                {company.status ? (
                  <CompanyStatusbadge
                    status={company.status}
                    className="me-2 mb-2"
                  />
                ) : null}
                {company.xpert_msa_status && (
                  <ContractStatusBadge
                    status={company.xpert_msa_status}
                    prefix={t("companies.list.msa_status.xpert")}
                    className="me-2 mb-2"
                  />
                )}
                {company.client_msa_status && (
                  <ContractStatusBadge
                    status={company.client_msa_status}
                    prefix={t("companies.list.msa_status.client")}
                    className="mb-2"
                  />
                )}
                <h6>{company.company_name}</h6>
              </div>
            </Col>
            <Col
              xl={2}
              md={3}
              sm={3}
              className="Company-Card__Type mt-xl-0 mb-xl-0 mt-6 mb-3"
            >
              <div className="text-table-header">
                {t("companies.list.card.type")}
              </div>
              {company.types?.map((type) => (
                <Badge
                  key={type}
                  pill
                  text="info"
                  bg="light"
                  className={`me-2 mb-2 border Company-Card__Type--${type} badge-small`}
                >
                  {t(`companies.list.types.${type}`)}
                </Badge>
              ))}
            </Col>
            <Col
              xl={2}
              md={6}
              sm={12}
              className="Company-Card__Xchanges mt-xl-0 mb-xl-0 mt-6 mb-3"
            >
              <div className="text-table-header">
                {t("companies.list.card.xchanges")}
              </div>
              {Object.keys(company.xchange_stats || []).map(
                (key) =>
                  company.xchange_stats![key as keyof ICompanyXchangeStats] >
                    0 && (
                    <Badge
                      key={`xchange_stats_${key}`}
                      pill
                      className={`me-2 mb-2 Company-Card__Xchange-Status--${key} badge-small`}
                    >
                      {`${
                        company.xchange_stats![
                          key as keyof ICompanyXchangeStats
                        ]
                      } ${t(`companies.list.xchange_statuses.${key}`)}`}
                    </Badge>
                  )
              )}
            </Col>
            <Col
              xl={3}
              md={6}
              sm={12}
              className="Company-Card__Domains mt-xl-0 mb-xl-0 mt-6 mb-3"
            >
              <div className="text-table-header">
                {t("companies.list.card.sub_domains")}
              </div>
              {company.sub_domains?.map(
                (subdomain, index) =>
                  index < 3 && (
                    <Badge
                      key={subdomain.name}
                      pill
                      text="info"
                      bg="light"
                      className="me-2 mb-2 Company-Card__Domain border badge-small"
                    >
                      {subdomain.name}
                    </Badge>
                  )
              )}
              {(company.sub_domains?.length ?? 0) > 3 && (
                <small className="Company-Card__Overflow">
                  {"+ " + ((company.sub_domains?.length ?? 0) - 3)}
                </small>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Link>
  );
};

export default CompanyCard;
