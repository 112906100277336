import Joi from "joi";
import { useTranslation } from "react-i18next";
import Page from "@components/Page/Page";
import { useXchange } from "@hooks/xchange/useXchange";
import NotFoundPage from "@pages/NotFoundPage";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Form, Spinner, Stack, Table } from "react-bootstrap";
import { useInvoice } from "@hooks/invoice/useInvoice";
import InvoiceBill from "./InvoiceBill/InvoiceBill";
import { useCallback, useState } from "react";
import IXpert from "@interfaces/IXpert";
import { toCurrencyString } from "@helpers/currencyUtils";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import {
  calcLineItemDiscount,
  calcLineItemMarkup,
  calcLineItemTotal,
  getInvoiceDefaultDueDate,
  processInvoiceLineItemForForm,
  processLineItemForBackend,
} from "@helpers/invoice-utils";
import "./XchangeInvoicePage.scss";
import { useInvoiceLineItemUpdate } from "@hooks/invoice/useInvoiceLineItemUpdate";
import { cloneDeep, isEqual, set } from "lodash";
import InvoiceInvoiceOverview from "./XchangeInvoiceOverview/XchangeInvoiceOverview";
import InfoHeader from "@components/InfoHeader/InfoHeader";
import { formatDateToString } from "@helpers/date-utils";
import { useCompany } from "@hooks/company/useCompany";
import { usePerson } from "@hooks/person/usePerson";
import { useBills } from "@hooks/bill/useBills";
export interface InvoiceBillLineItemFormValues {
  id?: number;
  description: string;
  quantity: number;
  rate: number;
  total: number;
  has_markup: boolean;
  markup_type: "percentage" | "fixed";
  markup: number;
  has_discount: boolean;
  discount_type: "percentage" | "fixed";
  discount: number;
}

export interface InvoiceFormValues {
  bills: {
    external_bills?: number[];
    xpert: IXpert;
    line_items: InvoiceBillLineItemFormValues[];
    subTotal: number;
    totalMarkup: number;
    totalDiscount: number;
    total: number;
  }[];
}

const InvoiceFormValuesValidationSchema = Joi.object({
  bills: Joi.array().items(
    Joi.object({
      external_bills: Joi.array().items(Joi.number()).optional(),
      xpert: Joi.object().required(),
      line_items: Joi.array().items(
        Joi.object({
          id: Joi.number().optional(),
          description: Joi.string().optional().allow(""),
          quantity: Joi.number().required().precision(2).min(0),
          rate: Joi.number().required().precision(2),
          total: Joi.number().required().precision(2),
          has_markup: Joi.boolean().required(),
          markup_type: Joi.string().valid("percentage", "fixed").required(),
          markup: Joi.when("markup_type", {
            is: "percentage",
            then: Joi.when("has_markup", {
              is: true,
              then: Joi.number().min(0).max(100).precision(0).required(),
              otherwise: Joi.number()
                .min(0)
                .max(100)
                .precision(0)
                .optional()
                .allow(""),
            }),
            otherwise: Joi.when("has_markup", {
              is: true,
              then: Joi.number().min(0).precision(2).required(),
              otherwise: Joi.number().min(0).precision(2).optional().allow(""),
            }),
          }),
          has_discount: Joi.boolean().required(),
          discount_type: Joi.string().valid("percentage", "fixed").required(),
          discount: Joi.when("discount_type", {
            is: "percentage",
            then: Joi.when("has_discount", {
              is: true,
              then: Joi.number().min(0).max(100).precision(0).required(),
              otherwise: Joi.number()
                .min(0)
                .max(100)
                .precision(0)
                .optional()
                .allow(""),
            }),
            otherwise: Joi.when("has_discount", {
              is: true,
              then: Joi.number().min(0).precision(2).required(),
              otherwise: Joi.number().min(0).precision(2).optional().allow(""),
            }),
          }),
        })
      ),
      subTotal: Joi.number().required().precision(2),
      totalMarkup: Joi.number().required().precision(2),
      totalDiscount: Joi.number().required().precision(2),
      total: Joi.number().required().precision(2),
    })
  ),
});

const XchangeInvoicePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams<{
    id: string;
    invoiceId: string;
  }>();
  const { id: xchangeId, invoiceId } = params;

  const {
    isLoading: isXchangeLoading,
    data: xchange,
    isError: xchangeError,
  } = useXchange(parseInt(xchangeId!), {
    enabled: !!xchangeId,
    retry: false,
  });

  const { data: company } = useCompany(xchange?.client?.object_id!, {
    enabled: !!xchange?.client?.company_person?.includes("companies"),
  });

  const { data: companyContact } = usePerson(
    xchange?.client?.finance_contact! || company?.company_key_contact!,
    {
      enabled:
        !!xchange?.client?.finance_contact || !!company?.company_key_contact,
    }
  );

  const {
    data: invoice,
    isLoading: isInvoiceLoading,
    isError: invoiceError,
  } = useInvoice(parseInt(invoiceId!), {
    enabled: !!invoiceId,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const updateInvoiceLineItemMutation = useInvoiceLineItemUpdate();

  const [grandSubTotal, setGrandSubTotal] = useState(0);
  const [grandTotalMarkup, setGrandTotalMarkup] = useState(0);
  const [grandTotalDiscount, setGrandTotalDiscount] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  const formMethods = useForm<InvoiceFormValues>({
    mode: "onChange",
    resolver: joiResolver(InvoiceFormValuesValidationSchema),
    shouldFocusError: false,
    defaultValues: {
      bills: [],
    },
  });

  const { control, handleSubmit, setValue, getValues } = formMethods;

  const onInvoiceChange = useCallback(
    (data: InvoiceFormValues) => {
      let grandSubTotal = 0;
      let grandTotalMarkup = 0;
      let grandTotalDiscount = 0;
      let grandTotal = 0;

      for (const [billIndex, bill] of data.bills.entries()) {
        for (const [lineItemIndex, lineItem] of bill.line_items.entries()) {
          const newTotal = calcLineItemTotal(lineItem);
          setValue(
            `bills.${billIndex}.line_items.${lineItemIndex}.total`,
            newTotal
          );
        }

        const subTotal = bill.line_items.reduce(
          (acc, lineItem) => acc + calcLineItemTotal(lineItem),
          0
        );
        const totalMarkup = bill.line_items.reduce(
          (acc, lineItem) => acc + calcLineItemMarkup(lineItem),
          0
        );
        const totalDiscount = bill.line_items.reduce(
          (acc, lineItem) => acc + calcLineItemDiscount(lineItem),
          0
        );
        const total = subTotal - totalDiscount + totalMarkup;

        setValue(`bills.${billIndex}.subTotal`, subTotal);
        setValue(`bills.${billIndex}.totalMarkup`, totalMarkup);
        setValue(`bills.${billIndex}.totalDiscount`, totalDiscount);
        setValue(`bills.${billIndex}.total`, total);

        grandSubTotal += subTotal;
        grandTotalMarkup += totalMarkup;
        grandTotalDiscount += totalDiscount;
        grandTotal += total;
      }

      setGrandSubTotal(grandSubTotal);
      setGrandTotalMarkup(grandTotalMarkup);
      setGrandTotalDiscount(grandTotalDiscount);
      setGrandTotal(grandTotal);
    },
    [setValue]
  );

  const [pastOnBlurData, setPastOnBlurData] = useState<InvoiceFormValues>();

  const onInvoiceBlur = (data: InvoiceFormValues) => {
    for (const [billIndex, bill] of data.bills.entries()) {
      for (const [lineItemIndex, lineItem] of bill.line_items.entries()) {
        const changedValues = Object.keys(lineItem).reduce((acc, key) => {
          if (["id", "total"].includes(key)) return acc;

          if (
            !isEqual(
              lineItem[key as keyof InvoiceBillLineItemFormValues],
              pastOnBlurData?.bills[billIndex]?.line_items?.[lineItemIndex]?.[
                key as keyof InvoiceBillLineItemFormValues
              ]
            )
          ) {
            set(acc, key, lineItem[key as keyof InvoiceBillLineItemFormValues]);
          }
          return acc;
        }, {} as InvoiceBillLineItemFormValues);

        if (Object.keys(changedValues).length > 0) {
          updateInvoiceLineItemMutation.mutate({
            invoiceId: parseInt(invoiceId!),
            lineItemId: lineItem.id!,
            data: {
              ...processLineItemForBackend(lineItem),
            },
          });
        }
      }
    }

    setPastOnBlurData(data);
  };

  const { fields: bills, replace: replaceBills } = useFieldArray({
    control,
    name: "bills",
  });

  const {
    data: billsInfiniteData,
    isLoading: areBillsLoading,
    isError: billsError,
  } = useBills(
    {
      xchange__in: xchangeId,
      populate_xpert: true,
      attachments: true,
      id__in: invoice?.external_bills?.join(","),
      page_size: 50,
    },
    {
      enabled: !!xchangeId && !!invoice?.external_bills,
      refetchOnWindowFocus: false,
      onSuccess(billsInfiniteData) {
        const data = billsInfiniteData.pages.flatMap((p) => p.results);

        const xpertIds = Array.from(
          new Set(data.map((bill) => bill.xpert?.id))
        );

        replaceBills(
          xpertIds.map((xpertId) => {
            const external_bill_ids = data
              .filter((bill) => bill.xpert?.id === xpertId)
              .map((bill) => bill.id!);

            return {
              external_bills: external_bill_ids,
              xpert: data.find((bill) => bill.xpert?.id === xpertId)?.xpert!,
              line_items: (invoice?.invoice_line_items || [])
                .filter((lineItem) =>
                  external_bill_ids.includes(lineItem.external_bill!)
                )
                .map((lineItem) => processInvoiceLineItemForForm(lineItem))
                .sort(
                  (a, b) => a.id! - b.id!
                ) as InvoiceBillLineItemFormValues[],
              subTotal: 0,
              totalMarkup: 0,
              totalDiscount: 0,
              total: 0,
            };
          })
        );

        handleSubmit(onInvoiceChange)();
        setPastOnBlurData(cloneDeep(getValues()));
      },
    }
  );
  const xchangeBills = billsInfiniteData
    ? billsInfiniteData.pages.flatMap((p) => p.results)
    : [];

  return !(isXchangeLoading || isInvoiceLoading) &&
    (xchangeError ||
      invoiceError ||
      billsError ||
      xchange?.id !== invoice?.xchange) ? (
    <NotFoundPage />
  ) : (
    <Page
      title={`${xchange?.name} Invoicing`}
      showHeader={false}
      sideBarContent={
        xchange && invoice && xchangeBills ? (
          <InvoiceInvoiceOverview
            xchange={xchange}
            invoice={invoice}
            bills={xchangeBills}
          />
        ) : null
      }
      aboveHeaderContent={
        <Button variant="outline-light" onClick={() => navigate(-1)}>
          <i className="fa-light fa-chevron-left me-2" />
          {t("wizard.back")}
        </Button>
      }
    >
      <Card className="mt-4 mb-4 p-4">
        {isXchangeLoading || isInvoiceLoading || areBillsLoading ? (
          <div className="d-flex justify-content-center mb-4 mt-4">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <>
            <InfoHeader
              title={`${t("billing.invoice.header.invoice")} ${
                invoice?.xero_invoice?.invoice_number ?? ""
              }`}
              leftInfo={[
                {
                  label: t("billing.invoice.header.due_date"),
                  value: formatDateToString(getInvoiceDefaultDueDate(invoice), {
                    dateStyle: "medium",
                  }),
                },
                {
                  label: t("billing.invoice.header.payment_terms"),
                  value: "NET 14",
                },
              ]}
              rightInfo={
                companyContact
                  ? [
                      {
                        label: t("billing.invoice.header.billed_to"),
                        value: (
                          <>
                            <div>{`${companyContact.first_name!} ${companyContact.last_name!}`}</div>
                            <div>{`${[
                              xchange?.client?.name,
                              company?.city,
                              company?.region,
                            ]
                              .filter((value) => value && value.length > 0)
                              .join(", ")}`}</div>
                            <div>{companyContact.email}</div>
                            <div>{companyContact.phone}</div>
                          </>
                        ),
                      },
                    ]
                  : [
                      {
                        label: t("billing.invoice.header.billed_to"),
                        value: (
                          <>
                            <div>{company?.company_legal_name}</div>
                            <div>{`${[
                              xchange?.client?.name,
                              company?.city,
                              company?.region,
                            ]
                              .filter((value) => value && value.length > 0)
                              .join(", ")}`}</div>
                          </>
                        ),
                      },
                    ]
              }
            />

            <div className="Xchange-Invoice-Page__Table-Header">
              <Table className="Xchange-Invoice-Page__Table-Header__Table">
                <thead>
                  <tr>
                    <td>{t("billing.invoice.line_item.item")}</td>
                    <td className="Xchange-Invoice-Page__Table-Header__Table__Quantity">
                      {t("billing.invoice.line_item.qty")}
                    </td>
                    <td className="Xchange-Invoice-Page__Table-Header__Table__Rate">
                      {t("billing.invoice.line_item.rate")}
                    </td>
                    <td className="Xchange-Invoice-Page__Table-Header__Table__Total">
                      {t("billing.invoice.line_item.total")}
                    </td>
                    <td className="Xchange-Invoice-Page__Table-Header__Table__Actions"></td>
                  </tr>
                </thead>
              </Table>
            </div>

            <FormProvider {...formMethods}>
              <Form
                onChange={handleSubmit(onInvoiceChange)}
                onBlur={handleSubmit(onInvoiceBlur)}
              >
                {bills?.map((bill, index) => {
                  return (
                    <InvoiceBill
                      key={bill.xpert.id}
                      index={index}
                      onInvoiceChange={onInvoiceChange}
                      disabled={!!invoice?.xero_invoice}
                    />
                  );
                })}
              </Form>
            </FormProvider>

            <div className="Xchange-Invoice-Page__Summary">
              <Stack direction="horizontal">
                <div className="Xchange-Invoice-Page__Summary__Item">
                  {t("billing.invoice.subtotal")}
                </div>
                <div className="Xchange-Invoice-Page__Summary__Amount ms-auto">
                  {toCurrencyString(grandSubTotal, "USD", 2)}
                </div>
              </Stack>
              <Stack direction="horizontal">
                <div className="Xchange-Invoice-Page__Summary__Item">
                  {t("billing.invoice.discount")}
                </div>
                <div className="Xchange-Invoice-Page__Summary__Amount ms-auto">
                  {toCurrencyString(-grandTotalDiscount, "USD", 2)}
                </div>
              </Stack>
              <Stack direction="horizontal" className="align-items-start">
                <div className="Xchange-Invoice-Page__Summary__Item">
                  {t("billing.invoice.markup")}
                  <div className="Xchange-Invoice-Page__Summary__Item__Description">
                    {t("billing.invoice.markup_desc")}
                  </div>
                </div>
                <div className="Xchange-Invoice-Page__Summary__Amount ms-auto">
                  {toCurrencyString(grandTotalMarkup, "USD", 2)}
                </div>
              </Stack>
              <Stack direction="horizontal">
                <div className="Xchange-Invoice-Page__Summary__Item">
                  {t("billing.invoice.total_tax")}
                  <div className="Xchange-Invoice-Page__Summary__Item__Description">
                    {t("billing.invoice.tax_desc")}
                  </div>
                </div>
                <div className="Xchange-Invoice-Page__Summary__Amount ms-auto">
                  -
                </div>
              </Stack>
            </div>
            <div className="Xchange-Invoice-Page__Summary Xchange-Invoice-Page__Summary--total">
              <Stack direction="horizontal">
                <div className="Xchange-Invoice-Page__Summary__Item">
                  {t("billing.invoice.grand_estimate")}
                  <div className="Xchange-Invoice-Page__Summary__Item__Description">
                    {t("billing.invoice.grand_estimate_desc")}
                  </div>
                </div>
                <div className="Xchange-Invoice-Page__Summary__Amount ms-auto">
                  {toCurrencyString(grandTotal, "USD", 2)}
                </div>
              </Stack>
            </div>
          </>
        )}
      </Card>
    </Page>
  );
};

export default XchangeInvoicePage;
