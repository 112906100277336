import React, { useContext } from "react";
import { Tab } from "react-bootstrap";
import { ActiveKeyContext } from "@components/Page/Page";

const PageTab = ({ children, tabKey }: { children: any; tabKey: string }) => {
  const activeKey = useContext(ActiveKeyContext);

  return <Tab.Pane active={tabKey === activeKey}>{children}</Tab.Pane>;
};

export default PageTab;
