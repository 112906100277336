import { useNavigate } from "react-router-dom";
import { useInvoiceCreate } from "@hooks/invoice/useInvoiceCreate";
import IXchange from "@interfaces/IXchange";
import BillsSelectionModal from "./BillsSelectionModal/BillsSelectionModal";

const InvoiceCreationModal = ({
  xchange,
  show,
  onClose,
}: {
  xchange: IXchange;
  show: boolean;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  const createInvoiceMutation = useInvoiceCreate();

  const createInvoice = (bills: number[]) => {
    createInvoiceMutation.mutate(
      {
        xchangeId: xchange.id!,
        externalBills: bills,
      },
      {
        onSuccess: (invoice) => {
          navigate(`/xchanges/${xchange.id}/invoicing/${invoice.id}`, {
            replace: true,
          });
        },
      }
    );
  };

  const isSaving = (): boolean => {
    return createInvoiceMutation.isLoading;
  };

  const getSavingError = () => {
    return createInvoiceMutation.error;
  };

  const onModalClose = () => {
    createInvoiceMutation.reset();
    onClose();
  };

  return (
    <BillsSelectionModal
      show={show}
      xchange={xchange}
      onClose={onModalClose}
      onBillsSelected={createInvoice}
      isFinishLoading={isSaving()}
      axiosError={getSavingError()}
    />
  );
};

export default InvoiceCreationModal;
