import { pull } from "lodash";
import React from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import StepCard from "@components/StepCard/StepCard";
import { useXpertUpdate } from "@hooks/xpert/useXpertUpdate";
import XchangeTable from "@pages/Company/CompanyEditPage/XchangeTable/XchangeTable";
import XpertDetailsForm from "./XpertDetailsForm/XpertDetailsForm";
import XpertMembers from "./XpertMembers/XpertMembers";
import XpertTerms from "./XpertTerms/XpertTerms";
import ICompany from "@interfaces/ICompany";
import IXpert from "@interfaces/IXpert";
import useElementAnchoring from "@hooks/useElementAnchoring";

const CompanyXpertDetails = ({
  clientId,
  xpert,
  company,
}: {
  clientId?: number;
  xpert?: IXpert;
  company?: ICompany;
}) => {
  const { t } = useTranslation();
  useElementAnchoring();

  const updateXpertMutation = useXpertUpdate();
  const toggleCompletedSection = (isCompleted: boolean, section: string) => {
    const updatedCompletedSections = xpert?.completed_sections || [];
    if (isCompleted) {
      updatedCompletedSections.push(section);
    } else {
      pull(updatedCompletedSections, section);
    }

    updateXpertMutation.mutate({
      updatedXpert: {
        id: xpert?.id!,
        completed_sections: updatedCompletedSections,
      },
    });
  };

  return (
    <>
      <StepCard title={t("companies.details.xpert.xchanges")}>
        <XchangeTable
          clientId={clientId}
          xpertId={xpert?.id}
          fetchClientXchanges={false}
        />
      </StepCard>

      {!xpert ? (
        <div className="d-flex justify-content-center mb-4">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <StepCard
          title={t("companies.details.xpert.details.title")}
          defaultMarkedAsCompleted={
            !!xpert?.completed_sections?.includes("details")
          }
          onCompletionStatusChange={(isCompleted) =>
            toggleCompletedSection(isCompleted, "details")
          }
        >
          {xpert && <XpertDetailsForm xpert={xpert} />}
        </StepCard>
      )}

      <StepCard
        id="xpert-team"
        title={t("companies.details.xpert.members.title")}
      >
        {xpert && <XpertMembers xpert={xpert} />}
      </StepCard>

      <StepCard
        id="xpert-terms"
        title={t("companies.details.xpert.terms.title")}
      >
        {xpert && <XpertTerms xpert={xpert} />}
      </StepCard>
    </>
  );
};

export default CompanyXpertDetails;
