import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { Control } from "react-hook-form";
import { useCatalogTerms } from "@hooks/term/useCatalogTerms";
import { useDebounce } from "@hooks/useDebounce";
import { IGetCatalogTermsParams } from "@services/CatalogTermService";
import CatalogTermsSelector from "./CatalogTermsSelector/CatalogTermsSelector";
import TermFilters from "@components/TermFilters/TermFilters";
import "./CatalogTermSelectionForm.scss";
import { IGetXpertsParams } from "@services/XpertService";
import ICatalogTerm from "@interfaces/terms/ICatalogTerm";

const CatalogTermSelectionForm = ({
  name,
  control,
  fixedGetCatalogTermsParams,
  extraFixedGetXpertsParams,
  onChange,
}: {
  name: string;
  control: Control<any, any>;
  fixedGetCatalogTermsParams?: IGetCatalogTermsParams;
  extraFixedGetXpertsParams?: IGetXpertsParams;
  onChange?: (value: ICatalogTerm[]) => void;
}) => {
  const fixedCatalogTermsParams: IGetCatalogTermsParams = {
    ...fixedGetCatalogTermsParams,
    ordering: "title",
  };

  const [getCatalogTermsParams, setGetCatalogTermsParams] =
    useState<IGetCatalogTermsParams>(fixedCatalogTermsParams);
  const {
    debouncedValue: debouncedGetCatalogTermsParams,
    isDebouncing: isGetCatalogTermsParamsDeboucing,
  } = useDebounce(getCatalogTermsParams, 400);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading: arePossibleCatalogTermsLoading,
    isFetchingNextPage,
  } = useCatalogTerms(debouncedGetCatalogTermsParams);

  const possibleCatalogTerms = data ? data.pages.flatMap((d) => d.results) : [];

  const handleFilterChange = (data: IGetCatalogTermsParams) => {
    setGetCatalogTermsParams({
      ...fixedCatalogTermsParams,
      ...data,
    });
  };

  return (
    <>
      <TermFilters
        handleFilterChange={handleFilterChange}
        extraFixedGetXpertsParams={extraFixedGetXpertsParams}
      />

      <div className="Catalog-Term-Selecion-Form__List">
        {arePossibleCatalogTermsLoading || isGetCatalogTermsParamsDeboucing ? (
          <div className="d-flex justify-content-center pt-4">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <CatalogTermsSelector
            name={name}
            control={control}
            possibleCatalogTerms={possibleCatalogTerms}
            onChange={onChange}
            onBottomReached={() => {
              if (hasNextPage) {
                fetchNextPage();
              }
            }}
            isLoadingNewCatalogTerms={isFetchingNextPage}
          />
        )}
      </div>
    </>
  );
};

export default CatalogTermSelectionForm;
