import IDocument from "@interfaces/IDocument";
import IDocumentType from "@interfaces/IDocumentType";
import Service from "@services/Service";

export interface IGetDocumentsParams {
  xpert?: number;
  client?: number;
  xchange?: number;
}

export default class DocumentService extends Service {
  public static async getDocumentTypes() {
    const response = await this.axios.get<IDocumentType[]>("/documents/types");
    return response.data;
  }

  public static async getDocumentType(id: number) {
    const response = await this.axios.get<IDocumentType>(
      `/documents/types/${id}`
    );
    return response.data;
  }

  public static async getDocuments(params?: IGetDocumentsParams) {
    const response = await this.axios.get<IDocument[]>("/documents", {
      params,
    });
    return response.data;
  }

  public static async createDocument(document: IDocument & { file?: File }) {
    const response = await this.axios.post<IDocument>("/documents", document, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  }

  public static async deleteDocument(id: number) {
    const response = await this.axios.delete(`/documents/${id}`);
    return response.data;
  }

  public static async updateDocument(document: IDocument) {
    const response = await this.axios.put<IDocument>(
      `/documents/${document.id}`,
      document
    );
    return response.data;
  }
}
