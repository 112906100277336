import { Button, Card, Spinner, Stack, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Page from "@components/Page/Page";
import { useBill } from "@hooks/bill/useBill";
import NotFoundPage from "@pages/NotFoundPage";
import IXchange from "@interfaces/IXchange";
import { toCurrencyString } from "@helpers/currencyUtils";
import "./XchangeBillPage.scss";
import InfoHeader from "@components/InfoHeader/InfoHeader";
import { formatDateToString } from "@helpers/date-utils";
import XchangeBillOverview from "./XchangeBillOverview/XchangeBillOverview";
import {
  getBillLineItemRate,
  getBillLineItemTotal,
} from "@helpers/bill-utilts";

const XchangeBillPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams<{
    id: string;
    billId: string;
  }>();
  const { id: xchangeId, billId } = params;

  const {
    data: bill,
    isLoading: isBillLoading,
    isError: billError,
  } = useBill(parseInt(billId!), {
    enabled: !!billId,
    retry: false,
  });

  return !isBillLoading &&
    (billError ||
      parseInt(xchangeId ?? "") !== (bill?.xchange as IXchange)?.id!) ? (
    <NotFoundPage />
  ) : (
    <Page
      title={`${t("billing.bill.title")} ${
        bill?.invoice_number ?? t("loading")
      }`}
      showHeader={false}
      sideBarContent={bill ? <XchangeBillOverview bill={bill} /> : <></>}
      aboveHeaderContent={
        <Button variant="outline-light" onClick={() => navigate(-1)}>
          <i className="fa-light fa-chevron-left me-2" />
          {t("wizard.back")}
        </Button>
      }
    >
      <Card className="mt-4 mb-4 p-4">
        {isBillLoading ? (
          <div className="d-flex justify-content-center mb-4 mt-4">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <>
            <InfoHeader
              title={`${t("billing.bill.title")} ${
                bill?.xero_invoice?.invoice_number ?? ""
              }`}
              leftInfo={[
                {
                  label: t("billing.bill.header.issued"),
                  value: bill.xero_invoice?.date
                    ? formatDateToString(new Date(bill.xero_invoice?.date), {
                        dateStyle: "medium",
                      })
                    : "-",
                },
                {
                  label: t("billing.bill.header.due_date"),
                  value: bill.xero_invoice?.due_date
                    ? formatDateToString(
                        new Date(bill.xero_invoice?.due_date),
                        {
                          dateStyle: "medium",
                        }
                      )
                    : "-",
                },
              ]}
              rightInfo={[
                {
                  label: t("billing.bill.header.from"),
                  value: (
                    <>
                      <div>
                        {bill.xero_invoice?.contact?.first_name}{" "}
                        {bill.xero_invoice?.contact?.last_name}
                      </div>
                      <div>{bill.xero_invoice?.contact?.name}</div>
                      <div>{bill.xero_invoice?.contact?.email_address}</div>
                    </>
                  ),
                },
              ]}
            />

            <div className="Xchange-Bill-Page__Table-Header">
              <Table className="Xchange-Bill-Page__Table-Header__Table">
                <thead>
                  <tr>
                    <td>{t("billing.invoice.line_item.item")}</td>
                    <td className="Xchange-Bill-Page__Table-Header__Table__Quantity">
                      {t("billing.bill.line_item.qty")}
                    </td>
                    <td className="Xchange-Bill-Page__Table-Header__Table__Rate">
                      {t("billing.bill.line_item.rate")}
                    </td>
                    <td className="Xchange-Bill-Page__Table-Header__Table__Total">
                      {t("billing.bill.line_item.total")}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {bill?.xero_invoice?.line_items?.map((lineItem, index) => (
                    <tr key={index}>
                      <td>{lineItem.description}</td>
                      <td>{lineItem.quantity}</td>
                      <td>
                        {toCurrencyString(
                          getBillLineItemRate(bill.xero_invoice!, lineItem),
                          "USD",
                          2
                        )}
                      </td>
                      <td>
                        {toCurrencyString(
                          getBillLineItemTotal(bill.xero_invoice!, lineItem),
                          "USD",
                          2
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

            <div className="Xchange-Bill-Page__Summary">
              <Stack direction="horizontal">
                <div className="Xchange-Bill-Page__Summary__Item">
                  {t("billing.bill.summary.subtotal")}
                </div>
                <div className="Xchange-Bill-Page__Summary__Amount ms-auto">
                  {toCurrencyString(bill.xero_invoice?.sub_total, "USD", 2)}
                </div>
              </Stack>
              <Stack direction="horizontal">
                <div className="Xchange-Bill-Page__Summary__Item">
                  {t("billing.bill.summary.discounts")}
                </div>
                <div className="Xchange-Bill-Page__Summary__Amount ms-auto">
                  {toCurrencyString(
                    -bill.xero_invoice?.total_discount!,
                    "USD",
                    2
                  )}
                </div>
              </Stack>
              <Stack direction="horizontal">
                <div className="Xchange-Bill-Page__Summary__Item">
                  {t("billing.bill.summary.tax")}
                </div>
                <div className="Xchange-Bill-Page__Summary__Amount ms-auto">
                  {toCurrencyString(bill.xero_invoice?.total_tax, "USD", 2)}
                </div>
              </Stack>
            </div>
            <div className="Xchange-Bill-Page__Summary Xchange-Bill-Page__Summary--total">
              <Stack direction="horizontal">
                <div className="Xchange-Bill-Page__Summary__Item">
                  {t("billing.bill.summary.total")}
                </div>
                <div className="Xchange-Bill-Page__Summary__Amount ms-auto">
                  {toCurrencyString(bill.xero_invoice?.total, "USD", 2)}
                </div>
              </Stack>
            </div>
          </>
        )}
      </Card>
    </Page>
  );
};

export default XchangeBillPage;
