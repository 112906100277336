import React from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ContractStatus } from "@interfaces/IContract";

const ContractStatusBadge = ({
  status,
  prefix,
  className,
  showIcon = false,
  showText = true,
}: {
  status?: ContractStatus;
  prefix?: string;
  className?: string;
  showIcon?: boolean;
  showText?: boolean;
}) => {
  const { t } = useTranslation();

  const color = (() => {
    switch (status) {
      case ContractStatus.IN_PROGRESS:
      case ContractStatus.SENT_FOR_REVIEW:
      case ContractStatus.SENT_FOR_SIGNING:
        return "warning";
      case ContractStatus.SIGNED:
        return "success";
      default:
        return "light";
    }
  })();

  const iconClasses = (() => {
    switch (status) {
      case ContractStatus.IN_PROGRESS:
        return "fa-regular fa-arrows-rotate";

      case ContractStatus.SENT_FOR_REVIEW:
        return "fa-solid fa-user-magnifying-glass";

      case ContractStatus.SENT_FOR_SIGNING:
        return "fa-regular fa-signature";

      case ContractStatus.SIGNED:
        return "fa-regular fa-signature-lock";

      case ContractStatus.ARCHIVED:
        return "fa-regular fa-box-archive";
    }
  })();

  return (
    <OverlayTrigger
      delay={{ show: 350, hide: 0 }}
      overlay={
        !showText ? (
          <Tooltip>{t(`contracts.statuses.${status}`)}</Tooltip>
        ) : (
          <></>
        )
      }
    >
      <Badge bg={color} text={color} className={className}>
        {showIcon && <i className={`${iconClasses} ${showText && "me-2"}`} />}
        {showText && (
          <>
            {prefix ? `${prefix} ` : null}
            {t(`contracts.statuses.${status}`)}
          </>
        )}
      </Badge>
    </OverlayTrigger>
  );
};

export default ContractStatusBadge;
