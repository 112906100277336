import i18n from "i18next";
import IDurationRange from "@interfaces/IDurationRange";

export const addDays = (date: Date, days: number) => {
  date.setDate(date.getDate() + days);
  return date;
};

export const subtractDays = (date: Date, days: number) => {
  date.setDate(date.getDate() - days);
  return date;
};

export const resetHours = (date: Date) => {
  date.setHours(0, 0, 0, 0);
  return date;
};

export const formatDateToString = (
  date: Date,
  options: Intl.DateTimeFormatOptions = {
    dateStyle: "medium",
    timeStyle: "short",
  },
  locale = "en-US"
) => {
  return new Intl.DateTimeFormat(locale, options).format(date);
};

export const toRangeString = (
  startDate: Date,
  endDate: Date,
  hideYear = false
) => {
  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();

  return `${formatDateToString(startDate, {
    month: "short",
    day: "numeric",
    year: !hideYear && startYear !== endYear ? "numeric" : undefined,
  })} - ${formatDateToString(endDate, {
    month: "short",
    day: "numeric",
    year: hideYear ? undefined : "numeric",
  })}`;
};

export const toDurationRangeString = ({ min, max }: IDurationRange) => {
  if (min === max) {
    return `${min} ${i18n.t("durations.weeks", { count: max })}`;
  }
  return `${min} - ${max} ${i18n.t("durations.weeks", { count: max })}`;
};
