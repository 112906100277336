import { ReactNode, useState } from "react";
import "./InfoSnippet.scss";

const InfoSnippet = ({
  iconClasses,
  closable = false,
  children,
  className,
}: {
  iconClasses: string;
  closable?: boolean;
  children: ReactNode;
  className?: string;
}) => {
  const [isClosed, setIsClosed] = useState(false);

  return !isClosed ? (
    <div className={`Info-Snippet ${className} mb-4`}>
      <div>
        <div className="Info-Snippet__Icon">
          <i className={`${iconClasses}`} />
        </div>
      </div>
      <div>{children}</div>
      {closable && (
        <div className="ms-auto ps-4">
          <i
            className="Info-Snippet__Close-Button fa-regular fa-xmark"
            onClick={() => {
              setIsClosed(true);
            }}
          />
        </div>
      )}
    </div>
  ) : null;
};

export default InfoSnippet;
