import IXchange from "@interfaces/IXchange";
import XchangeBillingHistory from "./XchangeBillingHistory/XchangeBillingHistory";
import XchangeBillingSummary from "./XchangeBillingSummary/XchangeBillingSummary";

const XchangeBilling = ({ xchange }: { xchange?: IXchange }) => {
  return (
    <>
      {xchange && (
        <>
          <XchangeBillingSummary xchange={xchange} />
          {xchange.owner?.has_xero_integration && (
            <XchangeBillingHistory xchange={xchange} />
          )}
        </>
      )}
    </>
  );
};

export default XchangeBilling;
