import { Alert, Button, Spinner } from "react-bootstrap";
import WizardModal from "@components/WizardModal/WizardModal";
import { useTranslation } from "react-i18next";
import "./ContractReviewersWizard.scss";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { FormProvider, useForm } from "react-hook-form";
import { useMemo, useState } from "react";
import { IGetPeopleParams } from "@services/PersonService";
import { useDebounce } from "@hooks/useDebounce";
import { usePeople } from "@hooks/person/usePeople";
import SelectSearch from "@components/SelectSearch/SelectSearch";
import PersonCard from "@components/PersonCard/PersonCard";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import { useContractPreviewDownload } from "@hooks/contract/useContractPreviewDownload";
import { usePartyPersonTitles } from "@hooks/party/usePartyPersonTitles";
import AxiosErrorAlert from "@components/AxiosErrorAlert/AxiosErrorAlert";
import {
  IContractGenerationHook,
  IContractGenerationSubject,
} from "@hooks/contract/useContractGeneration";
import useGetSubjectContract from "@hooks/contract/useGetSubjectContract";
import useGetSubjectParty from "@hooks/contract/useGetSubjectParty";
import useGetSubjectReviewers from "@hooks/contract/useGetSubjectReviewers";

export interface IContractReviewersWizardStringsProps {
  title?: string;
  preview?: string;
  selectReviewersHelp?: string;
  sentForReview?: string;
  sentForReviewHelp?: string;
}

export interface ContractReviewersFormValues {
  reviewers: number[];
}

export const contractReviewersValidationSchema = Joi.object({
  reviewers: Joi.array(),
});

const ContractReviewersWizard = ({
  show,
  contractGenerationHook,
  subject,
  onClose,
  onFinish,
  onReviewerSelectionFinish,
  onEditRecipientsClick,
  areReviewersCreationLoading,
  contractTranslationRootKey = "contracts",
  editMode = false,
}: {
  show: boolean;
  contractGenerationHook: IContractGenerationHook;
  subject: IContractGenerationSubject;
  onClose: () => void;
  onFinish: () => void;
  onReviewerSelectionFinish: (
    data: ContractReviewersFormValues,
    callback: Function
  ) => void;
  onEditRecipientsClick: () => void;
  areReviewersCreationLoading: boolean;
  contractTranslationRootKey?: string;
  editMode?: boolean;
}) => {
  const { t } = useTranslation();

  const { error } = contractGenerationHook;
  const contract = useGetSubjectContract(
    subject,
    contractGenerationHook,
    editMode
  );

  const doesSubjectHaveNonPreviewContract = !!useGetSubjectContract(
    subject,
    contractGenerationHook,
    true
  );

  const subjectParty = useGetSubjectParty(subject, contractGenerationHook);
  const reviewers = useGetSubjectReviewers(subject, contractGenerationHook);

  const [isShowingPDF, setIsShowingPDF] = useState(false);
  const { isLoading: isPDFLoading, data: pdf } = useContractPreviewDownload(
    contract?.id!,
    isShowingPDF && !editMode
  );

  const pdfURL = useMemo(() => {
    if (pdf) {
      const blob = new Blob([pdf], { type: "application/pdf" });
      return window.URL.createObjectURL(blob);
    }
  }, [pdf]);

  const [getPeopleParams, setGetPeopleParams] = useState<IGetPeopleParams>({});

  useMemo(() => {
    if (subjectParty) {
      switch (subjectParty.subject_type) {
        case "xpert":
          setGetPeopleParams({
            ...getPeopleParams,
            xpert_team_members__xpert: subjectParty.subject_id,
          });
          break;
        case "client":
          setGetPeopleParams({
            ...getPeopleParams,
            client_team_members__client: subjectParty.subject_id,
          });
          break;
        case "company":
          setGetPeopleParams({
            ...getPeopleParams,
            contact_companies: subjectParty.subject_id,
          });
          break;
        case "person":
          setGetPeopleParams({
            ...getPeopleParams,
            id__in: `${subjectParty.subject_id}`,
          });
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectParty]);

  const { debouncedValue: debouncedGetPeopleParams } = useDebounce(
    getPeopleParams,
    400
  );
  const { data: people } = usePeople(debouncedGetPeopleParams);

  const formMethods = useForm<ContractReviewersFormValues>({
    mode: "onChange",
    resolver: joiResolver(contractReviewersValidationSchema),
    defaultValues: {
      reviewers: [...(reviewers?.map((r) => r.person) || [])],
    },
  });
  const {
    getValues,
    setValue,
    watch,
    control,
    formState: { errors, isValid },
  } = formMethods;

  const toggleReviewer = (reviewer: number) => {
    if (!areReviewersCreationLoading) {
      const currentReviewers = getValues("reviewers");
      if (currentReviewers.includes(reviewer)) {
        setValue(
          "reviewers",
          currentReviewers.filter((r) => r !== reviewer),
          {
            shouldValidate: true,
          }
        );
      } else {
        setValue("reviewers", [...currentReviewers, reviewer], {
          shouldValidate: true,
        });
      }
    }
  };

  const { getPersonTitles } = usePartyPersonTitles(subjectParty);

  const translationLastKeyPrefix = useMemo(() => {
    if (editMode) {
      return "edit_";
    } else if (doesSubjectHaveNonPreviewContract) {
      return "regenerate_";
    }
    return "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  return (
    <WizardModal
      show={show}
      onClose={() => onClose()}
      onCloseText={t("actions.cancel")}
      onFinish={() => onFinish()}
      onFinishText={t("actions.close")}
      steps={[
        {
          title: t(
            `${contractTranslationRootKey}.dashboard.contract.wizard.${translationLastKeyPrefix}title`
          ),
          onEnter: () => {
            setIsShowingPDF(true);
          },
          onFinish: () => {
            setIsShowingPDF(false);
          },
          content: (
            <div>
              <h3 className="mb-6">
                {t(
                  `${contractTranslationRootKey}.dashboard.contract.wizard.${translationLastKeyPrefix}preview`
                )}
              </h3>

              {doesSubjectHaveNonPreviewContract && (
                <Alert variant="warning">
                  <i className="fa-solid fa-triangle-exclamation me-2" />
                  {t(
                    `${contractTranslationRootKey}.dashboard.contract.regenerate_disclamer`
                  )}
                </Alert>
              )}

              {isPDFLoading && (
                <div className="text-center mt-4 mb-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}

              <object
                className="Contract-Reviewer-Wizard__PDF"
                data={pdfURL}
                type="application/pdf"
                aria-label="PDF"
              />
            </div>
          ),
          onFinishText: t(
            `${contractTranslationRootKey}.dashboard.contract.wizard.select_recipients`
          ),
          isCompleted: true,
          skip: editMode,
        },
        {
          title: t(
            `${contractTranslationRootKey}.dashboard.contract.wizard.${translationLastKeyPrefix}title`
          ),
          content: (
            <FormProvider {...formMethods}>
              <h3 className="mb-6">
                {t(
                  `${contractTranslationRootKey}.dashboard.contract.wizard.${translationLastKeyPrefix}select_reviewers`
                )}
              </h3>
              <p>
                {t(
                  `${contractTranslationRootKey}.dashboard.contract.wizard.${translationLastKeyPrefix}select_reviewers_help`
                )}
              </p>
              <SelectSearch
                name="reviewers"
                control={control}
                onFormChange={(data) => {
                  setGetPeopleParams({
                    ...getPeopleParams,
                    search: data.search,
                  });
                }}
                options={(people || []).map((p) => ({
                  value: p.id!,
                  label: `${p.first_name} ${p.last_name}`,
                  subLabel: getPersonTitles(p.id!),
                }))}
                isMultiple
                disabled={areReviewersCreationLoading}
                closeOnChange={false}
              />
              <FieldErrorMessage
                field={errors.reviewers}
                fieldName={t(
                  `${contractTranslationRootKey}.dashboard.contract.wizard.reviewers`
                )}
              />
              <div className="mt-5">
                {watch("reviewers").map((reviewer) => (
                  <PersonCard
                    key={reviewer}
                    personId={reviewer}
                    title={getPersonTitles(reviewer)}
                    isDisabled={areReviewersCreationLoading}
                    onDelete={() => toggleReviewer(reviewer)}
                  />
                ))}
              </div>
              <AxiosErrorAlert
                response={error}
                translationPrefix="errors.codes."
              />
            </FormProvider>
          ),
          onFinishText: t(
            `${contractTranslationRootKey}.dashboard.contract.wizard.${
              editMode ? "save_changes" : "send_for_review"
            }`
          ),
          onFinish: (callback) => {
            onReviewerSelectionFinish(getValues(), callback!);
          },
          isLoading: areReviewersCreationLoading,
          isCompleted: isValid,
        },
        {
          title: t(
            `${contractTranslationRootKey}.dashboard.contract.wizard.${translationLastKeyPrefix}title`
          ),
          hideStepNumber: true,
          disableGoBack: true,
          isCompleted: true,
          content: (
            <>
              <h3 className="mt-4 mb-6">
                <i className="fa-solid fa-circle-check me-3 text-success-default" />
                {t(
                  `${contractTranslationRootKey}.dashboard.contract.wizard.${
                    editMode ? "changes_saved" : "sent_for_review"
                  }`
                )}
              </h3>
              <p>
                {t(
                  `${contractTranslationRootKey}.dashboard.contract.wizard.${
                    editMode ? "changes_saved_help" : "sent_for_review_help"
                  }`
                )}
              </p>
            </>
          ),
          extraButtons: !editMode && (
            <>
              <Button variant="outline-light" onClick={onEditRecipientsClick}>
                {t(
                  `${contractTranslationRootKey}.dashboard.contract.wizard.edit_recipients`
                )}
              </Button>
            </>
          ),
        },
      ]}
    />
  );
};

export default ContractReviewersWizard;
