import AxiosErrorAlert from "@components/AxiosErrorAlert/AxiosErrorAlert";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import AuthService from "@services/AuthService";
import { AxiosError } from "axios";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const GoogleLoginButton = ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLogin, setIsLogin] = useState(false);
  const [loginError, setLoginError] = useState<AxiosError>();

  const login = useGoogleLogin({
    flow: "auth-code",
    scope: process.env.REACT_APP_GOOGLE_AUTH_SCOPES,
    onSuccess: async (codeResponse) => {
      setIsLogin(true);
      try {
        await AuthService.googleLogin(codeResponse.code);
        navigate("/");
      } catch (error) {
        if (error instanceof AxiosError) {
          setLoginError(error);
          setIsLogin(false);
        }
      }
    },
    onError: () => {
      setIsLogin(false);
    },
  });

  return (
    <>
      <Button className={className} onClick={() => login()} disabled={isLogin}>
        {isLogin ? (
          <i className="fa-regular fa-spinner fa-spin me-2" />
        ) : (
          <>
            <i className="fa-brands fa-google me-2" /> {t("login.google")}
          </>
        )}
      </Button>
      <AxiosErrorAlert
        response={loginError}
        translationPrefix="errors.codes."
        className="mt-4 mb-0"
      />
    </>
  );
};

const GoogleLogin = ({ className }: { className?: string }) => {
  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID || ""}
    >
      <GoogleLoginButton className={className} />
    </GoogleOAuthProvider>
  );
};

export default GoogleLogin;
