import { joiResolver } from "@hookform/resolvers/joi";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import AxiosErrorAlert from "@components/AxiosErrorAlert/AxiosErrorAlert";
import CustomSelect from "@components/CustomSelect/CustomSelect";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import GenericModal from "@components/GenericModal/GenericModal";
import { useXchangeProducts } from "@hooks/product/useXchangeProducts";
import { useXchangeTerms } from "@hooks/term/useXchangeTerms";
import { useEpicCreate } from "@hooks/xchange/useEpicCreate";
import { useEpicUpdate } from "@hooks/xchange/useEpicUpdate";
import { useXperts } from "@hooks/xpert/useXperts";
import IEpic from "@interfaces/IEpic";
import { EpicFormValues, xchangeEpicSchema } from "./XchangeEpics";

const XchangeEpicDetailsModal = ({
  show,
  onClose,
  epicToEdit,
  xchangeId,
}: {
  show: boolean;
  onClose: () => void;
  epicToEdit?: IEpic | null;
  xchangeId?: number;
}) => {
  const { t } = useTranslation();

  const { data: terms } = useXchangeTerms(
    {
      xchange: xchangeId!,
    },
    {
      enabled: !!xchangeId,
    }
  );
  const { data: products } = useXchangeProducts(xchangeId!, {
    enabled: !!xchangeId,
  });

  const formMethods = useForm<EpicFormValues>({
    resolver: joiResolver(xchangeEpicSchema),
    mode: "onChange",
  });
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    register,
    formState: { errors },
  } = formMethods;

  useEffect(() => {
    if (epicToEdit) {
      reset({
        ...epicToEdit,
        duration: epicToEdit.duration! / 7,
      });
    } else {
      reset({
        title: "",
        description: "",
        duration: 0,
        xperts: [],
        client_involved: false,
      });
    }
  }, [show, epicToEdit, reset]);

  const xpertIds = (terms || [])
    .map((term) => term.xpert_involved!)
    .concat(
      (products || [])
        .map((product) =>
          (product.terms || []).map((term) => term.xpert_involved!)
        )
        .flat()
    )
    .filter((xpert, index, self) => {
      return index === self.findIndex((p) => p === xpert);
    });

  const { data: xperts } = useXperts(
    {
      id__in: xpertIds.join(","),
    },
    {
      enabled: xpertIds.length > 0,
    }
  );

  const resourceOptions = xperts?.map((xpert) => ({
    value: xpert.id!,
    label: xpert.name,
  }));

  const updateEpicMutation = useEpicUpdate();
  const createEpicMutation = useEpicCreate();
  const formSubmit = (data: EpicFormValues) => {
    if (epicToEdit) {
      updateEpicMutation.mutate(
        {
          xchangeId: xchangeId!,
          epic: {
            ...data,
            duration: data.duration! * 7,
          },
        },
        {
          onSuccess: () => {
            onClose();
          },
        }
      );
    } else {
      createEpicMutation.mutate(
        {
          xchangeId: xchangeId!,
          epic: {
            ...data,
            duration: data.duration! * 7,
          },
        },
        {
          onSuccess: () => {
            onClose();
          },
        }
      );
    }
  };

  return (
    <GenericModal
      title={t(`form.epic.${epicToEdit ? "edit" : "add"}.title`)}
      show={show}
      onClose={onClose}
      onFinish={handleSubmit(formSubmit)}
      finishText={t(`form.epic.${epicToEdit ? "edit" : "add"}.finish`)}
    >
      <FormProvider {...formMethods}>
        <h3 className="pb-6 pt-5 mb-2 ps-0">
          {t(`form.epic.${epicToEdit ? "edit" : "add"}.header`)}
        </h3>
        <Form.Group className="mb-4">
          <Form.Label>{t("form.epic.fields.milestone_title")}</Form.Label>
          <Form.Control isInvalid={!!errors.title} {...register("title")} />
          <FieldErrorMessage
            field={errors.title}
            fieldName={t("form.epic.fields.milestone_title")}
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>{t("form.epic.fields.duration")}</Form.Label>
          <Form.Control
            type="number"
            min="1"
            step="1"
            isInvalid={!!errors.duration}
            {...register("duration")}
          />
          <FieldErrorMessage
            field={errors.duration}
            fieldName={t("form.epic.fields.duration")}
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>{t("form.epic.fields.resources")}</Form.Label>
          <CustomSelect
            control={control}
            name="xperts"
            options={resourceOptions}
            isMulti
          />
          <FieldErrorMessage
            field={errors.xperts}
            fieldName={t("form.epic.fields.resources")}
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>{t("form.epic.fields.description")}</Form.Label>
          <Controller
            control={control}
            name="description"
            render={({ field: { onChange }, fieldState: { error } }) => (
              <>
                <ReactQuill
                  className={`${error ? "ql-invalid" : ""}`}
                  placeholder={t("form.epic.fields.description_help")}
                  value={getValues("description")}
                  onChange={(value) => {
                    if (value.replace(/(<([^>]+)>)/gi, "")) {
                      onChange(value);
                    } else {
                      onChange(undefined);
                    }
                  }}
                />
                <FieldErrorMessage
                  field={error}
                  fieldName={t("form.epic.fields.description")}
                />
              </>
            )}
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Check
            {...register("client_involved")}
            label={t("form.epic.fields.client_involved")}
          />
          <FieldErrorMessage
            field={errors.client_involved}
            fieldName={t("form.epic.fields.client_involved")}
          />
        </Form.Group>
      </FormProvider>
      <AxiosErrorAlert
        response={updateEpicMutation.error || createEpicMutation.error}
        translationPrefix="form.epic.fields"
      />
    </GenericModal>
  );
};

export default XchangeEpicDetailsModal;
