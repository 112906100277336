import { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ImageCheckbox from "@components/ImageCheckbox/ImageCheckbox";
import StepCard from "@components/StepCard/StepCard";
import "./XchangeTypeSelect.scss";

export const XchangeTypeSelect = ({
  onChangeType,
}: {
  onChangeType: (type: string) => void;
}) => {
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState<string>("");

  return (
    <>
      <StepCard
        subtitle={t("form.xchange_edit.form_labels.type")}
        title={t("form.xchange_edit.sub_titles.select_type")}
        isCollapsible={false}
      >
        <div className="p-4">
          <div className="d-flex justify-content-around">
            <ImageCheckbox
              img="/images/xchange.png"
              icon="fa-plus"
              checked={selectedType === "xchange"}
              placeholder="Xchange"
              onClick={() => setSelectedType("xchange")}
              className="me-4"
            />

            <ImageCheckbox
              img="/images/xchange.png"
              icon="fa-plus"
              checked={selectedType === "advisory"}
              placeholder="Advisory"
              onClick={() => setSelectedType("advisory")}
            />
          </div>

          <Button
            disabled={!selectedType}
            variant="primary"
            className="mt-5 w-100 p-2"
            onClick={() => onChangeType(selectedType)}
          >
            {t("form.xchange_edit.buttons.continue")}
            <i className="fa-regular fa-arrow-right ms-2" />
          </Button>
        </div>
      </StepCard>
    </>
  );
};
