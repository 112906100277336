import { useMutation, useQueryClient } from "@tanstack/react-query";
import DocumentService from "@services/DocumentService";

export const useDocumentDelete = () => {
  const queryClient = useQueryClient();
  return useMutation((id: number) => DocumentService.deleteDocument(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["documents"]);
    },
  });
};
