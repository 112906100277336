import CardPlaceholder from "@components/CardPlaceholder/CardPlaceholder";
import { useXchangeTerms } from "@hooks/term/useXchangeTerms";
import ICatalogTerm from "@interfaces/terms/ICatalogTerm";
import IXchangeTerm from "@interfaces/terms/IXchangeTerm";
import XchangeTermTableRow from "@pages/Term/TermEditPage/XchangeTermTable/XchangeTermTableRow/XchangeTermTableRow";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const XchangeTermTable = ({ catalogTerm }: { catalogTerm?: ICatalogTerm }) => {
  const { t } = useTranslation();

  const { data: xchangeTerms } = useXchangeTerms(
    {
      catalog_term: catalogTerm?.id!,
    },
    {
      enabled: !!catalogTerm,
    }
  );

  return (
    <div className="ps-4 pe-4">
      {xchangeTerms && xchangeTerms.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <th>{t("term.details.xchanges.table.xchange")}</th>
              <th>{t("term.details.xchanges.table.duration")}</th>
              <th>{t("term.details.xchanges.table.status")}</th>
              <th>{t("term.details.xchanges.table.xpert")}</th>
              <th>{t("term.details.xchanges.table.cost")}</th>
            </tr>
          </thead>
          <tbody>
            {xchangeTerms?.map((xchangeTerm: IXchangeTerm) => (
              <XchangeTermTableRow
                key={xchangeTerm.id}
                xchangeTerm={xchangeTerm}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <CardPlaceholder
          iconClassName={"fa-solid fa-rectangle-vertical-history"}
          title={t("term.details.xchanges.empty")}
          description={t("term.details.xchanges.empty_desc")}
        />
      )}
    </div>
  );
};

export default XchangeTermTable;
