import { useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CustomSelect from "@components/CustomSelect/CustomSelect";
import Page from "@components/Page/Page";
import ProductCard from "@components/ProductCard/ProductCard";
import SearchFilter from "@components/SearchFilter/SearchFilter";
import ProductStatuses from "@enum/ProductStatuses";
import { useSubDomains } from "@hooks/subdomain/useSubDomains";
import { useCatalogProducts } from "@hooks/product/useCatalogProducts";
import { useDebounce } from "@hooks/useDebounce";
import { IGetCatalogProductsParams } from "@services/CatalogProductService";
import "./ProductListPage.scss";
import { Virtuoso } from "react-virtuoso";

interface ProductListFilters {
  status: ProductStatuses;
  sub_domains: number[];
  search: string;
}

const ProductListPage = () => {
  const { t } = useTranslation();

  const formMethods = useForm<ProductListFilters>();
  const { watch, control } = formMethods;

  const { data: subDomains } = useSubDomains();

  const [getProductsParams, setGetProductsParams] =
    useState<IGetCatalogProductsParams>({
      ordering: "title",
    });
  const {
    debouncedValue: debouncedGetProductsParams,
    isDebouncing: isGetProductsParamsDeboucing,
  } = useDebounce(getProductsParams, 500);
  const {
    isLoading: areProductsLoading,
    data: catalogProductsInfiniteData,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useCatalogProducts(debouncedGetProductsParams);
  const catalogProducts = catalogProductsInfiniteData
    ? catalogProductsInfiniteData.pages.flatMap((p) => p.results)
    : [];

  useEffect(() => {
    const subscription = watch((data) => {
      setGetProductsParams({
        ...getProductsParams,
        status: data.status,
        sub_domains__in:
          data.sub_domains && data.sub_domains.length > 0
            ? data.sub_domains.join(",")
            : undefined,
        search: data.search,
      });
    });
    return () => subscription.unsubscribe();
  }, [getProductsParams, watch]);

  return (
    <Page title={t("product.listing.title")}>
      <Card>
        <Card.Header className="Product-List-Page__Header">
          <FormProvider {...formMethods}>
            <Row>
              <Col lg="2">
                <Link to="/products/create">
                  <Button className="fw-bold">
                    <i className="fas fa-plus me-2"></i>
                    {t("product.listing.new")}
                  </Button>
                </Link>
              </Col>
              <Col
                lg="10"
                className="Product-List-Page__Header__Filters d-flex align-items-center justify-content-end"
              >
                <CustomSelect
                  name="status"
                  control={control}
                  className="ps-3"
                  placeholder={t("product.listing.filters.status")}
                  isClearable
                  options={Object.values(ProductStatuses).map((status) => ({
                    value: status,
                    label: t(`product.statuses.${status}`),
                  }))}
                />
                <CustomSelect
                  name="sub_domains"
                  control={control}
                  className="ps-3 me-4"
                  placeholder={t("product.listing.filters.sub_domains")}
                  isClearable
                  options={(subDomains || []).map((subDomain) => ({
                    value: subDomain.id,
                    label: subDomain.name,
                  }))}
                  isMulti
                />
                <SearchFilter
                  name="search"
                  className="Product-List-Page__Header__Filters__Search ps-4"
                  placeholder={t("filters.search")}
                  variant="primary"
                />
              </Col>
            </Row>
          </FormProvider>
        </Card.Header>
        <Card.Body>
          {areProductsLoading || isGetProductsParamsDeboucing ? (
            <div className="text-center mt-4 mb-4">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <Virtuoso
              className="Product-List-Page__List"
              data={catalogProducts}
              atBottomThreshold={420}
              atBottomStateChange={(isAtBottom) => {
                if (isAtBottom && hasNextPage) {
                  fetchNextPage();
                }
              }}
              components={{
                Footer: () => {
                  return (
                    <div className="d-flex justify-content-center">
                      {isFetchingNextPage && (
                        <Spinner
                          animation="border"
                          variant="primary"
                          size="sm"
                        />
                      )}
                    </div>
                  );
                },
              }}
              itemContent={(index, catalogProduct) => (
                <div key={catalogProduct.id}>
                  {index !== 0 && <hr />}
                  <ProductCard product={catalogProduct} />
                </div>
              )}
            />
          )}
        </Card.Body>
      </Card>
    </Page>
  );
};

export default ProductListPage;
