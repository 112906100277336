import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IClientCreateUpdate } from "@interfaces/IClient";
import ClientService from "@services/ClientService";

interface IMutationVars {
  updatedClient: IClientCreateUpdate;
}

export const useClientUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ["updateClient"],
    ({ updatedClient }: IMutationVars) =>
      ClientService.updateClient(updatedClient),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["clients"]);
      },
    }
  );
};
