import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./NavigationBarItem.scss";

const NavigationBarItem = (props: {
  label: string;
  icon: string;
  path: string;
  onClick?: () => void;
}) => {
  const location = useLocation();
  const isPathActive = location.pathname.includes(props.path);

  return (
    <Link to={props.path} onClick={props.onClick}>
      <div
        className={`Navigation-Bar-Item ${
          isPathActive && "Navigation-Bar-Item--active"
        }`}
      >
        <i
          className={`Navigation-Bar-Item__Icon ${
            isPathActive && "Navigation-Bar-Item__Icon--active"
          } ${props.icon}`}
        ></i>
        <span className="Navigation-Bar-Item__Label">{props.label}</span>
      </div>
    </Link>
  );
};

export default NavigationBarItem;
