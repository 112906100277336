import CustomSelect, {
  ICustomSelectOption,
} from "@components/CustomSelect/CustomSelect";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import { useCompanies } from "@hooks/company/useCompanies";
import { useCompany } from "@hooks/company/useCompany";
import { useDebounce } from "@hooks/useDebounce";
import { CompanyStatuses } from "@interfaces/ICompany";
import { IGetCompaniesParams } from "@services/CompanyService";
import { FocusEventHandler, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface OwnerSelectProps {
  disabled?: boolean;
  isClearable?: boolean;
  className?: string;
  placeholder?: string;
  onAfterChange?: (
    options: ICustomSelectOption | ICustomSelectOption[] | null,
    actionMeta: any
  ) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
}

const OwnerSelect = ({
  disabled = false,
  isClearable = false,
  className,
  placeholder,
  onAfterChange,
  onBlur,
}: OwnerSelectProps) => {
  const { t } = useTranslation();

  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext();

  const { data: company } = useCompany(getValues("owner"), {
    enabled: !!getValues("owner"),
    refetchOnWindowFocus: false,
  });

  const [getCompaniesParams, setGetCompaniesParams] =
    useState<IGetCompaniesParams>({
      code__isnull: false,
      status__in: CompanyStatuses.ACTIVE,
      id__not__in: getValues("owner"),
    });
  const {
    debouncedValue: debouncedGetCompaniesParams,
    isDebouncing: isGetCompaniesParamsDeboucing,
  } = useDebounce(getCompaniesParams, 400);
  const {
    isFetching: arePossibleOwnersFetching,
    data: possibleOwnersInfiniteData,
    hasNextPage: possibleOwnersHasNextPage,
    fetchNextPage: fetchPossibleOwnersNextPage,
  } = useCompanies(
    {
      ...debouncedGetCompaniesParams,
    },
    {
      enabled: !disabled,
      refetchOnWindowFocus: false,
    }
  );

  const possibleOwners = useMemo(() => {
    const possibleOwners = possibleOwnersInfiniteData
      ? possibleOwnersInfiniteData.pages.flatMap((p) => p.results)
      : [];

    if (company && !possibleOwners.find((c) => c.id === company.id)) {
      possibleOwners.push(company);
    }

    return possibleOwners;
  }, [possibleOwnersInfiniteData, company]);

  return (
    <>
      <CustomSelect
        control={control}
        className={className}
        name="owner"
        placeholder={placeholder}
        options={possibleOwners.map((company) => ({
          value: company.id,
          label: company.company_name,
        }))}
        isLoading={isGetCompaniesParamsDeboucing || arePossibleOwnersFetching}
        onInputChange={(value) => {
          setGetCompaniesParams({
            ...getCompaniesParams,
            search: value,
          });
        }}
        isDisabled={disabled}
        isClearable={isClearable}
        onAfterChange={onAfterChange}
        onBlur={onBlur}
        onMenuScrollToBottom={() => {
          if (possibleOwnersHasNextPage) {
            fetchPossibleOwnersNextPage();
          }
        }}
      />
      <FieldErrorMessage
        field={errors.owner}
        fieldName={t("form.xchange_edit.form_labels.owner")}
      />
    </>
  );
};

export default OwnerSelect;
