import React from "react";
import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { XchangeStatuses } from "@interfaces/IXchange";
import "./XchangeStatusBadge.scss";

const XchangeStatusBadge = ({
  status,
  className = "",
  size = "normal",
}: {
  status?: XchangeStatuses;
  className?: string;
  size?: "normal" | "small";
}) => {
  const { t } = useTranslation();

  return (
    <>
      {status === XchangeStatuses.PLANNING && (
        <Badge
          bg="info"
          text="info"
          className={`Xchange-Status-Badge--${size} ${className}`}
        >
          {t("form.xchange_edit.statuses.planning")}
        </Badge>
      )}
      {status === XchangeStatuses.COMPLETED && (
        <Badge
          bg="success"
          text="success"
          className={`Xchange-Status-Badge--${size} ${className}`}
        >
          {t("form.xchange_edit.statuses.completed")}
        </Badge>
      )}
      {status === XchangeStatuses.IN_PROGRESS && (
        <Badge
          bg="secondary"
          text="secondary"
          className={`Xchange-Status-Badge--${size} ${className}`}
        >
          {t("form.xchange_edit.statuses.in_progress")}
        </Badge>
      )}
      {status === XchangeStatuses.ARCHIVED && (
        <Badge
          bg="info"
          text="info"
          className={`Xchange-Status-Badge--${size} ${className}`}
        >
          {t("form.xchange_edit.statuses.archived")}
        </Badge>
      )}
      {status === XchangeStatuses.UNFULFILLED && (
        <Badge
          bg="warning"
          text="warning"
          className={`Xchange-Status-Badge--${size} ${className}`}
        >
          {t("form.xchange_edit.statuses.unfulfilled")}
        </Badge>
      )}
    </>
  );
};

export default XchangeStatusBadge;
