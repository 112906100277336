import "./NavigationBarGroup.scss";

const NavigationBarGroup = (props: {
  children: any;
  className?: string;
  label?: string;
}) => {
  return (
    <div className={`Navigation-Bar-Group ${props.className}`}>
      {props.label && (
        <div className="Navigation-Bar-Group__Label">{props.label}</div>
      )}
      {props.children}
    </div>
  );
};

export default NavigationBarGroup;
