import { useQuery } from "@tanstack/react-query";
import IDocumentType from "@interfaces/IDocumentType";
import DocumentService from "@services/DocumentService";

export const useDocumentTypes = ({
  onSuccess,
}: {
  onSuccess: (data: IDocumentType[]) => void;
}) =>
  useQuery(["document-types"], () => DocumentService.getDocumentTypes(), {
    onSuccess,
  });
