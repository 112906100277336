import ActionsDropdown from "@components/ListOptionsDropdown/ActionsDropdown";
import { toCurrencyString } from "@helpers/currencyUtils";
import { useXpert } from "@hooks/xpert/useXpert";
import IXpertTerm from "@interfaces/terms/IXpertTerm";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getXpertLink } from "@helpers/xpert-utils";

const XpertTableRow = ({
  xpertTerm,
  onEdit,
  onDelete,
}: {
  xpertTerm: IXpertTerm;
  onEdit?: (xpertTerm: IXpertTerm) => void;
  onDelete?: (xpertTerm: IXpertTerm) => void;
}) => {
  const { t } = useTranslation();

  const { data: xpert } = useXpert(xpertTerm.xpert_involved!);

  return (
    <tr key={xpertTerm.id}>
      <td>{xpert && <Link to={getXpertLink(xpert)}>{xpert.name}</Link>}</td>
      <td>{`${xpertTerm.duration} ${t("durations.weeks", {
        count: xpertTerm.duration,
      })}`}</td>
      <td>
        <span className="Term-Table__Currency ms-1">{xpertTerm.currency} </span>
        {toCurrencyString(xpertTerm.cost_per_sprint)}
      </td>
      <td className="table-actions">
        <ActionsDropdown
          actions={[
            {
              label: t("term.details.xperts.table.actions.edit"),
              onClick: () => onEdit?.(xpertTerm),
            },
            {
              label: t("term.details.xperts.table.actions.delete"),
              onClick: () => onDelete?.(xpertTerm),
            },
          ]}
        >
          <Button variant="outline-light" className="p-2 pt-1 pb-1">
            <i className="fa-regular fa-ellipsis-vertical"></i>
          </Button>
        </ActionsDropdown>
      </td>
    </tr>
  );
};

export default XpertTableRow;
