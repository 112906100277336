import { Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useXperts } from "@hooks/xpert/useXperts";
import IXchange from "@interfaces/IXchange";
import { EpicFormValues } from "@pages/Xchange/XchangeEdit/XchangeEpics/XchangeEpics";
import { useEpics } from "@hooks/xchange/useEpics";
import IEpic from "@interfaces/IEpic";
import { useState } from "react";
import Breakdown from "@components/Breakdown/Breakdown";
import { IBreakdownSectionProps } from "@components/Breakdown/BreakdownSection/BreakdownSection";

export type XpertsCount = {
  [key: string]: { id: number; count: number };
};

export type PartiesCount = {
  client: number;
  xperts: XpertsCount;
};

const XchangeEpicBreakdown = ({ xchange }: { xchange?: IXchange }) => {
  const { t } = useTranslation();

  const [partiesBreakdown, setPartiesBreakdown] = useState<PartiesCount>({
    client: 0,
    xperts: {},
  });

  const { data: epics } = useEpics(xchange?.id!, {
    enabled: !!xchange?.id!,
    onSuccess: (epics) => {
      setPartiesBreakdown(calcBreakDownCounts(epics));
    },
  });

  const { data: xperts } = useXperts(
    { id__in: Object.keys(partiesBreakdown.xperts).join(",") },
    {
      enabled: Object.keys(partiesBreakdown.xperts).length > 0,
    }
  );

  const calcBreakDownCounts = (epics: IEpic[]) => {
    // Aggregate the number of epics that the client/xperts are involved in
    const clientCount = (epics || []).filter(
      (epic: EpicFormValues) => epic.client_involved
    ).length;
    const xpertsCount = epics
      .flatMap((epic) => epic.xperts ?? [])
      .reduce((acc: XpertsCount, xpert) => {
        if (acc[xpert]) {
          acc[xpert].count++;
        } else {
          acc[xpert] = { id: xpert, count: 1 };
        }
        return acc;
      }, {});
    return { client: clientCount, xperts: xpertsCount ?? {} };
  };

  const getBreakdownSections = (): IBreakdownSectionProps[] => {
    const sections = [];
    if (partiesBreakdown.client) {
      sections.push({
        title: t("form.xchange_edit.form_labels.client"),
        values: [
          {
            value: partiesBreakdown.client.toString(),
            valueType: t("form.xchange_edit.info.epics", {
              count: partiesBreakdown.client,
            }),
          },
        ],
      });
    }

    for (const xpert of Object.values(partiesBreakdown.xperts)) {
      sections.push({
        title: xperts?.find((x) => x.id === xpert.id)?.name!,
        values: [
          {
            value: xpert.count.toString(),
            valueType: t("form.xchange_edit.info.epics", {
              count: xpert.count,
            }),
          },
        ],
      });
    }

    sections.push({
      title: t("form.xchange_edit.info.total"),
      values: [
        {
          value: epics?.length.toString(),
          valueType: t("form.xchange_edit.info.epics", {
            count: epics?.length,
          }),
        },
      ],
      highlight: true,
    });

    return sections;
  };

  return (
    <>
      <Stack direction="horizontal" gap={3} className="mb-5">
        <h5 className="mb-0">{t("form.xchange_edit.sub_titles.breakdown")}</h5>
      </Stack>

      <Breakdown sections={getBreakdownSections()} />
    </>
  );
};

export default XchangeEpicBreakdown;
