import IAcknowledgement from "@interfaces/IAcknowledgement";
import Service from "@services/Service";

export default class AcknowledgementService extends Service {
  public static async getAcknowledgements() {
    const response = await this.axios.get<IAcknowledgement[]>(
      "/acknowledgements"
    );
    return response.data;
  }
}
