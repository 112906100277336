import { useTranslation } from "react-i18next";
import Breakdown from "@components/Breakdown/Breakdown";
import StepCard from "@components/StepCard/StepCard";
import { toCurrencyString } from "@helpers/currencyUtils";
import {
  calcXchangeRemainingBudgetByCurrency,
  calcXchangeRemainingBudgetPercentageForCurrency,
  calcXchangeTotalBudgetsByCurrency,
  calcXchangeTotalSpendingByCurrency,
  calcXchangeTotalSpendingPercentageForCurrency,
  calcXchangeXpertBudget,
  getXchangeCurrencies,
  getXchangeCurrenciesString,
  getXchangeXpertIds,
} from "@helpers/xchange-utils";
import IXchange from "@interfaces/IXchange";
import { Spinner } from "react-bootstrap";
import { useXchangeBillsSummary } from "@hooks/xchange/useXchangeBillsSummary";
import XchangeBillingSummaryChart, {
  IXchangeBillingSummaryChartData,
} from "./XchangeBillingSummaryChart/XchangeBillingSummaryChart";
import { useXperts } from "@hooks/xpert/useXperts";

const XchangeBillingSummary = ({ xchange }: { xchange: IXchange }) => {
  const { t } = useTranslation();

  const totalBudgetByCurrency = calcXchangeTotalBudgetsByCurrency(xchange);
  const clientMarkup = xchange.client?.markup || 0;

  const { data: xperts } = useXperts(
    {
      id__in: getXchangeXpertIds(xchange).join(","),
    },
    {
      enabled: getXchangeXpertIds(xchange).length > 0,
    }
  );

  const { data: spendings } = useXchangeBillsSummary(xchange.id!);

  const parseChartData = (): IXchangeBillingSummaryChartData[] => {
    const data = [];

    for (const xpert of xperts || []) {
      const totalXpertSpending = spendings
        ?.filter((spending) => spending.xpert === xpert.id!)
        .reduce((acc, spending) => acc + spending.total_spend, 0);

      const xpertBudget = calcXchangeXpertBudget(xchange, xpert.id!);
      const xpertSpent = Math.min(totalXpertSpending ?? 0, xpertBudget);
      const xpertRemaining = Math.max(xpertBudget - xpertSpent, 0);
      const xpertOver = Math.max((totalXpertSpending ?? 0) - xpertBudget, 0);

      data.push({
        name: xpert.name!,
        spent: xpertSpent,
        remaining: xpertRemaining,
        over: xpertOver,
      });
    }

    return data.sort((a, b) => a.name.localeCompare(b.name));
  };

  return (
    <StepCard title={t("billing.summary.title")}>
      {!spendings ? (
        <div className="d-flex justify-content-center mb-4">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          <Breakdown
            sections={[
              {
                title: t("billing.summary.total_budget"),
                values: totalBudgetByCurrency.map(({ total, currency }) => ({
                  value: toCurrencyString(total),
                  valueType: currency,
                })),
              },
              {
                title: `${clientMarkup * 100}% ${t("billing.summary.markup")}`,
                values: totalBudgetByCurrency.map(({ total, currency }) => ({
                  value: toCurrencyString(clientMarkup * total),
                  valueType: currency,
                })),
              },
              {
                title: t("billing.summary.total_spent"),
                values: calcXchangeTotalSpendingByCurrency(
                  xchange,
                  spendings
                ).map(({ total, currency }) => ({
                  value: toCurrencyString(total),
                  valueType: `${currency} (${
                    calcXchangeTotalSpendingPercentageForCurrency(
                      xchange,
                      spendings,
                      currency
                    ) ?? "N/A"
                  }%)`,
                })),
              },
              {
                title: t("billing.summary.budget_remaining"),
                values: calcXchangeRemainingBudgetByCurrency(
                  xchange,
                  spendings
                ).map(({ total, currency }) => ({
                  value: toCurrencyString(total),
                  valueType: `${currency} (${
                    calcXchangeRemainingBudgetPercentageForCurrency(
                      xchange,
                      spendings,
                      currency
                    ) ?? "N/A"
                  }%)`,
                })),
                highlight: true,
              },
            ]}
          />

          <XchangeBillingSummaryChart
            data={parseChartData()}
            className="mt-5"
          />

          {getXchangeCurrencies(xchange).length > 1 && (
            <div className="ms-1 mt-1 text-muted small">
              {t("billing.summary.multiple_currency_disclaimer", {
                currencies: getXchangeCurrenciesString(xchange),
              })}
            </div>
          )}
        </>
      )}
    </StepCard>
  );
};

export default XchangeBillingSummary;
