import { CatalogTermFormValues } from "@components/CatalogTerm/CatalogTermForm/CatalogTermForm";
import { useCatalogTermAddProduct } from "@hooks/term/useCatalogTermAddProduct";
import { useCatalogTermCreate } from "@hooks/term/useCatalogTermCreate";
import ICatalogProduct from "@interfaces/products/ICatalogProduct";
import CatalogTermModal from "./CatalogTermModal/CatalogTermModal";

const ProductTermModal = ({
  product,
  show,
  onClose,
}: {
  product: ICatalogProduct;
  show: boolean;
  onClose: () => void;
}) => {
  const addProductToCatalogTermMutation = useCatalogTermAddProduct();
  const createCatalogTermMutation = useCatalogTermCreate();

  const saveCatalogTerm = (catalogTerm: CatalogTermFormValues) => {
    createCatalogTermMutation.mutate(
      {
        ...catalogTerm,
        products: [product.id!],
      },
      {
        onSuccess: () => {
          onModalClose();
        },
      }
    );
  };

  const addExistingCatalogTerms = (catalogTerms: number[]) => {
    for (const catalogTerm of catalogTerms) {
      addProductToCatalogTermMutation.mutate({
        catalogTermId: catalogTerm,
        productId: product.id!,
      });
    }
    onModalClose();
  };

  const isSaving = () => {
    return (
      addProductToCatalogTermMutation.isLoading ||
      createCatalogTermMutation.isLoading
    );
  };

  const getSavingError = () => {
    return (
      addProductToCatalogTermMutation.error || createCatalogTermMutation.error
    );
  };

  const onModalClose = () => {
    addProductToCatalogTermMutation.reset();
    createCatalogTermMutation.reset();
    onClose();
  };

  return (
    <CatalogTermModal
      show={show}
      product={product}
      onClose={onModalClose}
      onExistingSelected={addExistingCatalogTerms}
      onSaveCatalogTerm={saveCatalogTerm}
      isFinishLoading={isSaving()}
      axiosError={getSavingError()}
    />
  );
};

export default ProductTermModal;
