import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import IXchange from "@interfaces/IXchange";
import XchangeService from "@services/XchangeService";

export const useXchange = (
  id: number,
  options?: Pick<
    UseQueryOptions<IXchange, Error>,
    "enabled" | "onSuccess" | "retry"
  > & {
    refetchOnWindowFocus?: boolean;
  }
) =>
  useQuery(["xchanges", { id }], () => XchangeService.getXchange(id), options);
