import ToggleTextInput from "@components/ToggleTextInput/ToggleTextInput";
import { joiResolver } from "@hookform/resolvers/joi";
import { useCompanyUpdate } from "@hooks/company/useCompanyUpdate";
import ICompany from "@interfaces/ICompany";
import {
  CompanyProfileSocialsFormValues,
  companyProfileSocialsValidationSchema,
} from "@pages/Company/CompanyEditPage/CompanyEditConfig";
import { isEqual } from "lodash";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, set, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
const CompanyProfileSocials = ({ company }: { company?: ICompany }) => {
  const { t } = useTranslation();
  const updateCompanyMutation = useCompanyUpdate();

  const formMethods = useForm<CompanyProfileSocialsFormValues>({
    mode: "onChange",
    resolver: joiResolver(companyProfileSocialsValidationSchema),
    defaultValues: {
      company_website_url: company?.company_website_url || "",
    },
  });
  const { getValues, handleSubmit } = formMethods;

  const [pastOnBlurData, setPastOnBlurData] =
    useState<CompanyProfileSocialsFormValues>(getValues());

  const formSubmit = (data: CompanyProfileSocialsFormValues) => {
    const changedValues = Object.keys(data).reduce((acc, key) => {
      if (
        !isEqual(
          data[key as keyof CompanyProfileSocialsFormValues],
          pastOnBlurData[key as keyof CompanyProfileSocialsFormValues]
        )
      ) {
        set(acc, key, data[key as keyof CompanyProfileSocialsFormValues]);
      }
      return acc;
    }, {} as CompanyProfileSocialsFormValues);

    if (Object.keys(changedValues).length > 0) {
      updateCompanyMutation.mutate({
        updatedCompany: {
          id: company?.id!,
          ...changedValues,
        },
      });
    }

    setPastOnBlurData(data);
  };

  return (
    <FormProvider {...formMethods}>
      <Form onBlur={handleSubmit(formSubmit)}>
        <Row className="pe-4 ps-4">
          <Form.Group className="mb-4" as={Col} xs={12}>
            <ToggleTextInput
              name="company_website_url"
              switchLabel={t(
                "companies.profile.socials.fields.company_website_url"
              )}
              inputLabel={t(
                "companies.profile.socials.fields.company_website_url_help"
              )}
              onSwitchOff={() => {
                handleSubmit(formSubmit)();
              }}
            />
          </Form.Group>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default CompanyProfileSocials;
