import { Modify } from "@helpers/typing";
import ICompanyPersonType from "@interfaces/ICompanyPersonType";
import IPerson from "@interfaces/IPerson";
import IStage from "@interfaces/IStage";

export enum XpertStatus {
  PROSPECT = "prospect",
  ACTIVE = "active",
  INACTIVE = "inactive",
  DISENGAGED = "disengaged",
}

export default interface IXpert extends ICompanyPersonType {
  id?: number;
  name?: string;
  legal_name?: string;
  status?: XpertStatus;
  xpert_details?: string;
  minimum_lead_time?: number;
  minimum_team_size?: number;
  minimum_xchange_length?: number;
  additional_project_info?: string;
  stage_number_served?: IStage[];
  insurance_coverage?: number | null;
  insurance_policy_link?: string;
  finance_contact?: number | null;
  tax_registration_number?: string;
  xpert_feedback?: string;
  completed_sections?: string[];
  folder_id?: string;
  bank_details?: {
    country?: string;
    transit?: string;
    institution?: string;
    account?: string;
    taxId?: string;
    recipient?: string;
    name?: string;
    accountType?: string;
    routing?: string;
    swift?: string;
  };
  primary_contact?: number | null;
  markup?: number;
  msa_effective_date?: string;
  created_at?: Date;
  updated_at?: Date;
  account_code?: number;
}

export type IXpertCreateUpdate = Modify<
  IXpert,
  {
    stage_number_served?: number[];
  }
>;

export interface IXpertTeamMember {
  id?: number;
  xpert?: number;
  person?: IPerson;
  rate?: string;
  currency?: string;
  roles?: number[];
}

export type IXpertTeamMemberCreateUpdate = Modify<
  IXpertTeamMember,
  {
    person?: number;
  }
>;
