import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useTranslation } from "react-i18next";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import "./App.scss";
import NavigationBar from "@components/NavigationBar/NavigationBar";
import useLocalStorage from "@hooks/useLocalStorage";
import CompanyEditPage from "@pages/Company/CompanyEditPage/CompanyEditPage";
import LoginPage from "@pages/Login/LoginPage";
import NotFoundPage from "@pages/NotFoundPage";
import XchangeEditPage from "@pages/Xchange/XchangeEdit/XchangeEditPage";
import XchangeListPage from "@pages/Xchange/XchangeListPage/XchangeListPage";
import CompanyListPage from "@pages/Company/CompanyListPage/CompanyListPage";
import { Spinner } from "react-bootstrap";
import { useLoginStatus } from "@hooks/auth/useLoginStatus";
import ProductListPage from "@pages/Product/ProductListPage/ProductListPage";
import ProductEditPage from "@pages/Product/ProductEditPage/ProductEditPage";
import XchangeInvoicePage from "@pages/Xchange/XchangeInvoicePage/XchangeInvoicePage";
import XchangeBillPage from "@pages/Xchange/XchangeBillPage/XchangeBillPage";
import TermListPage from "@pages/Term/TermListPage/TermListPage";
import TermEditPage from "@pages/Term/TermEditPage/TermEditPage";
import XpertOnboardingPage from "@pages/Onboardings/XpertOnboardingPage/XpertOnboardingPage";
import AcknowledgementPage from "@pages/Acknowledgement/AcknowledgementPage";

const App = () => {
  const { t } = useTranslation();
  const { isUserLoggedIn, user } = useLocalStorage();
  const { isFetching, isError } = useLoginStatus(user.id, {
    enabled: isUserLoggedIn,
    refetchOnWindowFocus: false,
  });

  const NoAcknowledgementRoutes = () => {
    if (!isUserLoggedIn) {
      return <Navigate to="/login" />;
    }

    if (user.acknowledged_terms) {
      return <Navigate to="/" />;
    } else {
      return <Outlet />;
    }
  };

  const PrivateRoutes = () => {
    if (!isUserLoggedIn) {
      return <Navigate to="/login" />;
    }

    if (!user.acknowledged_terms) {
      return <Navigate to="/acknowledgement" />;
    }

    return <Outlet />;
  };

  const PublicOnlyRoutes = () => {
    return !isUserLoggedIn ? <Outlet /> : <Navigate to="/" />;
  };

  return (
    <>
      {isFetching || isError ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          {isFetching && <Spinner animation="border" variant="primary" />}
          {isError && (
            <div className="text-muted">
              {t("errors.generic")}
              <div className="small text-center text-muted mt-2">
                {process.env.REACT_APP_VERSION || "local"}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="d-flex h-100">
          {isUserLoggedIn && user.acknowledged_terms && (
            <div className="App__NavBar">
              <NavigationBar />
            </div>
          )}
          <div className="App__Content h-100">
            <Routes>
              <Route path="/" element={<PrivateRoutes />}>
                <Route path="/" element={<Navigate to="/xchanges" replace />} />
                <Route path="/xchanges" element={<XchangeListPage />} />
                <Route path="/xchanges/:id" element={<XchangeEditPage />} />
                <Route
                  path="/xchanges/:id/invoicing/:invoiceId"
                  element={<XchangeInvoicePage />}
                />
                <Route
                  path="/xchanges/:id/billing/:billId"
                  element={<XchangeBillPage />}
                />
                <Route
                  path="/xchanges/:id/:tab"
                  element={<XchangeEditPage />}
                />
                <Route path="/products" element={<ProductListPage />} />
                <Route path="/products/:id" element={<ProductEditPage />} />
                <Route path="/terms" element={<TermListPage />} />
                <Route path="/terms/:id" element={<TermEditPage />} />
                <Route path="/companies" element={<CompanyListPage />} />
                <Route path="/companies/:id" element={<CompanyEditPage />} />
                <Route
                  path="/companies/:id/:tab"
                  element={<CompanyEditPage />}
                />
              </Route>

              <Route path="/" element={<NoAcknowledgementRoutes />}>
                <Route
                  path="/"
                  element={<Navigate to="/acknowledgement" replace />}
                />
                <Route
                  path="/acknowledgement"
                  element={<AcknowledgementPage />}
                />
              </Route>

              <Route path="/" element={<PublicOnlyRoutes />}>
                <Route path="/login" element={<LoginPage />} />
                <Route
                  path="/onboarding/:step?"
                  element={<XpertOnboardingPage />}
                />
              </Route>

              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
        </div>
      )}
      <ReactQueryDevtools initialIsOpen={false} position="top-right" />
    </>
  );
};

export default App;
