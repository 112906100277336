import {
  Card,
  Badge,
  Row,
  Col,
  ProgressBar,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import IXchange, { XchangeStatuses, XchangeTypes } from "@interfaces/IXchange";
import "./XchangeCard.scss";
import XchangeStatusBadge from "@components/XchangeStatusBadge/XchangeStatusBadge";
import { formatDateToString } from "@helpers/date-utils";

const XchangeCard = ({ xchange }: { xchange: IXchange }) => {
  const { t } = useTranslation();

  const partner_names: string[] = (xchange.xchange_terms || [])
    .map((term) => term.xpert_involved_name!)
    .concat(
      (xchange.xchange_products || [])
        .map((product) =>
          (product.terms || []).map((term) => term.xpert_involved_name!)
        )
        .flat()
    )
    .filter((partner, index, self) => {
      return index === self.findIndex((p) => p === partner);
    });

  let timeProgress = 0;
  if (xchange.start_date && xchange.goal_end_date) {
    const startDate = new Date(xchange.start_date);
    const endDate = new Date(xchange.goal_end_date);

    const now = new Date();
    const duration = endDate.getTime() - startDate.getTime();
    const elapsed = now.getTime() - startDate.getTime();
    timeProgress = Math.min(
      Math.max(Math.round((elapsed / duration) * 100), 0),
      100
    );
  }

  const productsAndTerms: {
    id: string;
    name: string;
  }[] = [
    ...(xchange?.xchange_products || []).map((product) => ({
      id: `products/${product.id}`,
      name: product.title!,
    })),
    ...(xchange?.xchange_terms || []).map((term) => ({
      id: `terms/${term.id}`,
      name: term.title!,
    })),
  ].filter((productOrTerm, index, self) => {
    return index === self.findIndex((p) => p.name === productOrTerm.name);
  });

  return (
    <Link to={`/xchanges/${xchange.id}`}>
      <Card className="Xchange-Card p-1 mb-4 border-0">
        <Card.Body>
          <Row>
            <Col xl={3} md={6} sm={12} className="d-flex">
              <img
                className="Xchange-Card__Logo me-4"
                src={xchange.client?.company_logo ?? "/logo-light.png"}
                alt={xchange.client?.name}
              />
              <div>
                <XchangeStatusBadge
                  status={xchange.status}
                  className="me-2 mb-2"
                  size="small"
                />
                <div className="Xchange-Card__Owner">
                  {xchange.owner?.company_name}
                </div>
                <div className="Xchange-Card__Title">{xchange.name}</div>
              </div>
            </Col>
            {xchange.type === XchangeTypes.XCHANGE && (
              <Col
                xl={3}
                md={6}
                sm={12}
                className="Xchange-Card__Timeline ps-6 pe-6"
              >
                <div className="Xchange-Card__Timeline__Dates d-flex">
                  <div className="Xchange-Card__Timeline__Dates__Start">
                    <div className="Xchange-Card__Sub-title mb-1">
                      {xchange.start_date
                        ? formatDateToString(
                            new Date(xchange.start_date),
                            {},
                            "en-CA"
                          )
                        : ""}
                    </div>
                    <small>{t("xchanges.card.timeline.start_date")}</small>
                  </div>
                  <div className="Xchange-Card__Timeline__Dates__Seperator">
                    <i className="fa-solid fa-minus" />
                  </div>
                  <div className="Xchange-Card__Timeline__Dates__End">
                    <div className="Xchange-Card__Sub-title mb-1">
                      {xchange.goal_end_date
                        ? formatDateToString(
                            new Date(xchange.goal_end_date),
                            {},
                            "en-CA"
                          )
                        : ""}
                    </div>
                    <small>{t("xchanges.card.timeline.goal_end_date")}</small>
                  </div>
                </div>
                <ProgressBar
                  className="mt-3"
                  now={timeProgress}
                  label={`${Math.round(timeProgress)}%`}
                  variant={
                    xchange.status === XchangeStatuses.COMPLETED
                      ? "primary"
                      : "secondary"
                  }
                />
              </Col>
            )}
            {xchange.type === XchangeTypes.ADVISORY && (
              <Col
                xl={3}
                md={6}
                sm={12}
                className="Xchange-Card__Recuring ps-6 pe-6"
              >
                <div className="Xchange-Card__Sub-title mb-5">
                  {t("xchanges.card.timeline.recurring")}
                </div>
                <div className="Xchange-Card__Recuring__Box">
                  <i className="fa-regular fa-rotate me-1" />
                  {t("xchanges.card.timeline.recurring")}
                </div>
              </Col>
            )}
            <Col xl={2} md={6} sm={12} className="ps-6 pe-6">
              <div className="Xchange-Card__Sub-title mb-5">
                {t("xchanges.card.client")}
              </div>
              <Badge pill className="Xchange-Card__Badge--client me-2 mb-2">
                {xchange.client?.name}
              </Badge>
            </Col>
            <Col xl={2} md={6} sm={12} className=" ps-6 pe-6">
              <div className="Xchange-Card__Sub-title mb-5">
                {t("xchanges.card.partners")}
              </div>
              <div className="Xchange-Card__Partners">
                {partner_names.length > 0 && (
                  <>
                    {partner_names?.map(
                      (partner_name, index) =>
                        index < 2 && (
                          <OverlayTrigger
                            key={`partner-${index}`}
                            delay={{ show: 500, hide: 0 }}
                            overlay={<Tooltip>{partner_name}</Tooltip>}
                          >
                            <Badge
                              pill
                              bg="light"
                              className="Xchange-Card__Badge me-2 mb-2 border"
                            >
                              {partner_name}
                            </Badge>
                          </OverlayTrigger>
                        )
                    )}
                    {partner_names && partner_names.length > 2 && (
                      <small>{"+ " + (partner_names.length - 2)}</small>
                    )}
                  </>
                )}
              </div>
            </Col>
            <Col xl={2} md={6} sm={12} className=" mt-xl-0 mb-xl-0 mt-6 mb-3">
              <div className="Xchange-Card__Sub-title mb-5">
                {t("xchanges.card.productsTerms")}
              </div>
              <div className="Xchange-Card__Products-Terms">
                {productsAndTerms.map(
                  (productOrTerm, index) =>
                    index < 2 && (
                      <OverlayTrigger
                        key={productOrTerm.id}
                        delay={{ show: 500, hide: 0 }}
                        overlay={<Tooltip>{productOrTerm.name}</Tooltip>}
                      >
                        <Badge
                          key={productOrTerm.id}
                          pill
                          bg="light"
                          className="Xchange-Card__Badge me-2 mb-2 border"
                        >
                          {productOrTerm.name}
                        </Badge>
                      </OverlayTrigger>
                    )
                )}
                {productsAndTerms.length > 2 && (
                  <small>{"+ " + (productsAndTerms.length - 2)}</small>
                )}
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Link>
  );
};

export default XchangeCard;
