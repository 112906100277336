import { Button, Form, FormControlProps } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import "./SearchFilter.scss";

type Props = {
  variant?: string;
  className?: string;
  onButtonClick?: () => void;
  name: string;
} & FormControlProps;

const SearchFilter = (props: Props) => {
  const { register } = useFormContext();

  return (
    <div className={`Search-Filter d-flex ${props.className ?? ""}`}>
      <Form.Control
        {...props}
        {...register(props.name)}
        className="Search-Filter__Input"
      />
      <Button
        variant={props.variant}
        className="Search-Filter__Button text-center p-0"
        onClick={props.onButtonClick}
      >
        <i className="fa-solid fa-magnifying-glass"></i>
      </Button>
    </div>
  );
};

export default SearchFilter;
