import { useCallback, useEffect, useState } from "react";
import IUser from "@interfaces/IUser";

export default function useLocalStorage() {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(
    !!localStorage.getItem("user")
  );
  const [user, setUser] = useState<IUser>(
    JSON.parse(localStorage.getItem("user") || "{}")
  );

  const getFromLocalStorage = (key: string) => {
    return JSON.parse(localStorage.getItem(key) || "{}");
  };

  const saveToLocalStorage = (
    key: string,
    data: object | object[] | string | string[] | number | number[]
  ) => {
    localStorage.setItem(key, JSON.stringify(data));
  };

  const removeFromLocalStorage = (key: string) => {
    localStorage.removeItem(key);
  };

  const handleStatusChange = useCallback((event: StorageEvent) => {
    setIsUserLoggedIn(!!localStorage.getItem("user"));
    setUser(JSON.parse(localStorage.getItem("user") || "{}"));
  }, []);

  useEffect(() => {
    window.addEventListener("storage", handleStatusChange, false);

    return () => {
      window.removeEventListener("storage", handleStatusChange);
    };
  });

  return {
    isUserLoggedIn,
    user,
    getFromLocalStorage,
    saveToLocalStorage,
    removeFromLocalStorage,
  };
}
