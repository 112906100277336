import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import "./PasswordStrengthBar.scss";
import { FieldError } from "react-hook-form";
import InfoSnippet from "@components/InfoSnippet/InfoSnippet";

const PasswordStrengthBar = ({
  error,
  isDirty = false,
  isValidating = false,
  className,
}: {
  error?: FieldError;
  isDirty?: boolean;
  isValidating?: boolean;
  className?: string;
}) => {
  const { t } = useTranslation();

  const level = useMemo(() => {
    if (isValidating) {
      return 0;
    }

    const errorCode = error?.type.split(".")[1];

    if (error && errorCode?.startsWith("WEAK_PASSWORD_")) {
      const splittedErrorCode = errorCode.split("_");

      try {
        const level = parseInt(
          splittedErrorCode[splittedErrorCode.length - 1]
        ) as 0 | 1 | 2 | 3;
        return level === 0 ? 1 : level;
      } catch (e) {
        return 0;
      }
    } else if (!error && isDirty) {
      return 4;
    } else {
      return 0;
    }
  }, [error, isDirty, isValidating]);

  const iconClasses = useMemo(() => {
    switch (level) {
      case 0:
      case 1:
        return "fa-solid fa-circle-quarter";

      case 2:
        return "fa-solid fa-circle-half";

      case 3:
        return "fa-solid fa-circle-three-quarters";

      case 4:
        return "fa-solid fa-circle-check";
    }
  }, [level]);

  return (
    <div className={`Password-Strength-Bar ${className}`}>
      <div className="Password-Strength-Bar__Background">
        <div
          className={`Password-Strength-Bar__Progress Password-Strength-Bar__Progress--${level}`}
          style={{
            width: `${level * 25}%`,
          }}
        ></div>
      </div>
      <div className="Password-Strength-Bar__Info">
        <div className="Password-Strength-Bar__Info__Label">
          {t("password.strength")}
        </div>

        <div
          className={`Password-Strength-Bar__Info__Verbal Password-Strength-Bar__Info__Verbal--${level}`}
        >
          {isValidating && (
            <i className="fa-regular fa-spinner-third fa-spin" />
          )}

          {!isValidating && level > 0 && (
            <>
              {t(`password.strengths.${level}`)}
              <i className={`${iconClasses} ms-2`} />
            </>
          )}
        </div>
      </div>

      <InfoSnippet iconClasses="fa-regular fa-lock" className="mt-4">
        {t("password.rules")}
      </InfoSnippet>
    </div>
  );
};

export default PasswordStrengthBar;
