import Joi from "joi";
import {
  CompanySizes,
  CompanyStatuses,
  LegalEntityType,
} from "@interfaces/ICompany";
import {
  isCompanyLegalNameUnique,
  isCompanyNameUnique,
} from "@helpers/joi-utils";

export interface CompanyDetailsFormValues {
  legal_entity_type: LegalEntityType;
  company_name?: string;
  company_legal_name?: string;
  company_logo?: string;
  sub_domains?: number[];
  status?: CompanyStatuses;
}

export const companyDetailsValidationSchema = Joi.object({
  legal_entity_type: Joi.string().allow("", null),
  company_name: Joi.string().required().external(isCompanyNameUnique),
  company_legal_name: Joi.string().allow("").external(isCompanyLegalNameUnique),
  company_logo: Joi.string(),
  sub_domains: Joi.array(),
  status: Joi.string().allow("", null),
});

export interface CompanyLocationFormValues {
  legal_entity_jurisdiction?: string;
  street?: string;
  unit_number?: string;
  country?: string;
  city?: string;
  region?: string;
  postal_code?: string;
}

export const companyLocationValidationSchema = Joi.object({
  legal_entity_jurisdiction: Joi.string().allow("", null),
  street: Joi.string().allow(""),
  unit_number: Joi.string().allow(""),
  country: Joi.string().allow("", null),
  city: Joi.string().allow(""),
  region: Joi.string().allow("", null),
  postal_code: Joi.string().allow(""),
});

export interface CompanyProfileDetailsFormValues {
  company_size?: CompanySizes;
  company_description?: string;
}

export const companyProfileDetailsValidationSchema = Joi.object({
  company_size: Joi.string().required(),
  company_description: Joi.string().allow("").allow(null),
});

export interface CompanyProfileSocialsFormValues {
  company_website_url?: string;
}

export const companyProfileSocialsValidationSchema = Joi.object({
  company_website_url: Joi.string().uri().allow("").allow(null),
});
