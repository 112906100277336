import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import PartyService, { IGetPartiesParams } from "@services/PartyService";
import IParty from "@interfaces/IParty";

export const useParties = (
  params?: IGetPartiesParams,
  options?: Pick<UseQueryOptions<IParty[], Error>, "enabled" | "staleTime">
) =>
  useQuery(
    ["parties", { params }],
    () => PartyService.getParties(params),
    options
  );
