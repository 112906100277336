import IPage, { IPageParams } from "@interfaces/IPage";
import { ICatalogTermCreateUpdate } from "@interfaces/terms/ICatalogTerm";
import ICatalogTerm from "@interfaces/terms/ICatalogTerm";
import Service from "@services/Service";

export interface IGetCatalogTermsParams {
  title?: string;
  search?: string;
  id__in?: string;
  products?: number;
  products__not__in?: string;
  ordering?: "title" | "-title" | "date_added" | "-date_added";
  xpert_terms__gte?: number;
  xpert_terms__xpert_involved?: string;
  xpert_terms__xpert_involved__in?: string;
  xpert_terms__xpert_involved__not__in?: string;
  sub_domain?: string;
  sub_domain__in?: string;
  cost_ranges?: string; // e.g. 1-10,50-100
  xpert_terms__cost_per_sprint__gte?: number; // If cost_ranges is set, results will append the cost_ranges results
}

export default class CatalogTermService extends Service {
  public static async getCatalogTerm(id: number) {
    const response = await this.axios.get<ICatalogTerm>(`/terms/${id}`);
    return response.data;
  }

  public static async getCatalogTerms(
    params?: IGetCatalogTermsParams & IPageParams
  ) {
    const response = await this.axios.get<IPage<ICatalogTerm>>("/terms", {
      params,
    });
    return response.data;
  }

  public static async createCatalogTerm(term: ICatalogTermCreateUpdate) {
    const response = await this.axios.post<ICatalogTerm>("/terms", term);
    return response;
  }

  public static async updateCatalogTerm(
    id: number,
    term: ICatalogTermCreateUpdate
  ) {
    const response = await this.axios.patch<ICatalogTerm>(`/terms/${id}`, term);
    return response;
  }

  public static async addProductToCatalogTerm(
    catalogTermId: number,
    productId: number
  ) {
    const response = await this.axios.post(`/terms/${catalogTermId}/products`, {
      product: productId,
    });
    return response;
  }
}
