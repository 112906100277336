import { Badge } from "react-bootstrap";
import { XeroInvoiceStatus } from "@interfaces/IXeroInvoice";
import { useTranslation } from "react-i18next";

const BillStatusBadge = ({
  status,
  className,
}: {
  status: XeroInvoiceStatus;
  className?: string;
}) => {
  const { t } = useTranslation();

  const getColor = () => {
    switch (status) {
      case XeroInvoiceStatus.DRAFT:
        return "warning";
      case XeroInvoiceStatus.SUBMITTED:
        return "info";
      case XeroInvoiceStatus.AUTHORISED:
        return "danger";
      case XeroInvoiceStatus.PAID:
        return "success";
      case XeroInvoiceStatus.DELETED:
      case XeroInvoiceStatus.VOIDED:
        return "light";
    }
  };

  return (
    <Badge bg={getColor()} text={getColor()} className={className}>
      {t(`billing.xero.bill.statuses.${status}`)}
    </Badge>
  );
};

export default BillStatusBadge;
