import Joi from "joi";
import IParty from "@interfaces/IParty";
import PersonService, { IGetPeopleParams } from "@services/PersonService";
import { ICustomSelectOption } from "@components/CustomSelect/CustomSelect";
import { Form, Placeholder, Stack } from "react-bootstrap";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import { useFieldArray, useFormContext } from "react-hook-form";
import { ContractSignatoriesFormValues } from "@components/ContractSignatoriesWizard/ContractSignatoriesWizard";
import { useTranslation } from "react-i18next";
import IClient from "@interfaces/IClient";
import ICompany from "@interfaces/ICompany";
import IXpert from "@interfaces/IXpert";
import ClientService from "@services/ClientService";
import CompanyService from "@services/CompanyService";
import XpertService from "@services/XpertService";
import { useEffect, useState } from "react";
import "./ContractPartySignatorySelection.scss";
import CollapseButton from "@components/CollapseButton/CollapseButton";
import SelectSearch from "@components/SelectSearch/SelectSearch";
import PersonCard from "@components/PersonCard/PersonCard";
import { usePartyPersonTitles } from "@hooks/party/usePartyPersonTitles";
import AlertWithIcon from "@components/AlertWithIcon/AlertWithIcon";

export interface IPartySignatory {
  party: IParty;
  person?: number;
  filters: IGetPeopleParams;
  options?: ICustomSelectOption[];
}

export const PartySignatoryValidationSchema = Joi.object({
  party: Joi.object().required(),
  person: Joi.number().required(),
  filters: Joi.object(),
  options: Joi.array().items(
    Joi.object({
      value: Joi.number().required(),
      label: Joi.string().required(),
    })
  ),
});

const ContractPartySignatorySelection = ({
  party,
  index,
  disabled = false,
}: {
  party: IParty;
  index: number;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();

  const { getPersonTitles } = usePartyPersonTitles(party);

  const {
    control,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext<ContractSignatoriesFormValues>();

  const {
    fields: partySignatories,
    update: updatePartySignatories,
    remove: removeFromPartySignatories,
  } = useFieldArray({
    control,
    name: "partySignatories",
  });

  const [isLoading, setIsLoading] = useState(false);

  const initPartySignatory = (party: IParty) => {
    let isCanceled = false;

    (async () => {
      setIsLoading(true);
      const newPartySignatory: IPartySignatory = {
        party,
        filters: {},
      };

      let company: ICompany = {};
      let xpert: IXpert = {};
      let client: IClient = {};
      switch (party.subject_type) {
        case "xpert":
          newPartySignatory.filters = {
            xpert_team_members__xpert: party.subject_id,
          };
          xpert = await XpertService.getXpert(party.subject_id!);
          break;
        case "client":
          newPartySignatory.filters = {
            client_team_members__client: party.subject_id,
          };
          client = await ClientService.getClient(party.subject_id!);
          break;
        case "company":
          newPartySignatory.filters = {
            contact_companies: party.subject_id,
          };
          company = await CompanyService.getCompany(party.subject_id!);
          break;
        case "person":
          newPartySignatory.filters = { id__in: `${party.subject_id}` };
          break;
      }

      const people = await PersonService.getPeople(newPartySignatory.filters);

      newPartySignatory.options = people.map((person) => ({
        value: person.id!,
        label: `${person.first_name} ${person.last_name} (${person.email})`,
      }));

      switch (party.subject_type) {
        case "person":
          newPartySignatory.person = newPartySignatory.options[0].value;
          break;
        case "company":
          if (company) {
            newPartySignatory.person = newPartySignatory.options.find(
              (option) => option.value === company.company_key_contact
            )?.value;
          }
          break;
        case "xpert":
          if (xpert) {
            newPartySignatory.person = newPartySignatory.options.find(
              (option) => option.value === xpert.primary_contact
            )?.value;
          }
          break;
        case "client":
          if (client) {
            newPartySignatory.person = newPartySignatory.options.find(
              (option) => option.value === client.primary_contact
            )?.value;
          }
      }

      if (!isCanceled) {
        updatePartySignatories(index, newPartySignatory);
        setIsLoading(false);
      }
    })();

    return {
      cancel() {
        isCanceled = true;
      },
    };
  };

  useEffect(() => {
    const cancellables: { cancel: () => void }[] = [];
    if (
      !partySignatories.some(
        (partySignatory) => partySignatory.party.id === party.id
      )
    ) {
      cancellables.push(initPartySignatory(party));
    }

    return () => {
      removeFromPartySignatories(index);
      for (const cancellable of cancellables) {
        cancellable.cancel();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [party]);

  const [isCollapsed, setIsCollapsed] = useState(false);

  if (!isCollapsed && !isLoading) {
    watch(`partySignatories.${index}.person`);
  }

  return (
    <Form.Group className="Contract-Party-Signatory-Selection">
      <Stack
        className="Contract-Party-Signatory-Selection__Title"
        direction="horizontal"
      >
        <div>{party.subject_name}</div>
        <CollapseButton
          isCollapsed={isCollapsed}
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="ms-auto"
        ></CollapseButton>
      </Stack>

      {!isCollapsed &&
        (isLoading ? (
          <Placeholder animation="glow" className="mt-4">
            <Form.Label>{party.subject_name}</Form.Label>
            <Placeholder xs={12} className="placeholder--input" />
          </Placeholder>
        ) : (
          <div className="mt-4">
            <SelectSearch
              name={`partySignatories.${index}.person`}
              control={control}
              onFormChange={(value) => {
                console.log(value);
              }}
              options={(
                getValues(`partySignatories.${index}.options`) || []
              ).map((option) => ({
                value: option.value,
                label: option.label,
                subLabel: getPersonTitles(option.value),
                isSelected:
                  option.value ===
                  getValues(`partySignatories.${index}.person`),
              }))}
              closeOnChange
              disabled={true}
            />

            {!getValues(`partySignatories.${index}.person`) && (
              <AlertWithIcon
                variant="warning"
                iconClasses="fa-regular fa-triangle-exclamation"
                className="mt-4"
              >
                {t("contracts.signee_restriction", {
                  subject_name: party.subject_name,
                  subject_type: t(
                    `contracts.subject_type.${party.subject_type}`
                  ),
                })}
              </AlertWithIcon>
            )}

            {getValues(`partySignatories.${index}.person`) && (
              <PersonCard
                personId={getValues(`partySignatories.${index}.person`)!}
                title={getPersonTitles(
                  getValues(`partySignatories.${index}.person`)!
                )}
                isDisabled={disabled}
              />
            )}

            <FieldErrorMessage
              field={errors.partySignatories?.[index]?.person}
              fieldName={`${t("contracts.signatory_for")} ${getValues(
                `partySignatories.${index}.party.subject_name`
              )}`}
            />
          </div>
        ))}
    </Form.Group>
  );
};

export default ContractPartySignatorySelection;
