import {
  IContractGenerationHook,
  IContractGenerationSubject,
} from "./useContractGeneration";
import useGetSubjectContract from "./useGetSubjectContract";

const useGetSubjectReviewers = (
  subject: IContractGenerationSubject,
  contractGenerationHook: IContractGenerationHook
) => {
  const contract = useGetSubjectContract(subject, contractGenerationHook, true);
  const { reviewers } = contractGenerationHook;

  return reviewers?.filter((reviewer) => reviewer.contract === contract?.id);
};

export default useGetSubjectReviewers;
