import React, { useEffect, useMemo, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CardPlaceholder from "@components/CardPlaceholder/CardPlaceholder";
import { useXchanges } from "@hooks/xchange/useXchanges";
import IXchange from "@interfaces/IXchange";
import XchangeTableRow from "./XchangeTableRow/XchangeTableRow";

const XchangeTable = ({
  clientId,
  xpertId,
  fetchClientXchanges = true,
  fetchXpertXchanges = true,
}: {
  clientId?: number;
  xpertId?: number;
  fetchClientXchanges?: boolean;
  fetchXpertXchanges?: boolean;
}) => {
  const { t } = useTranslation();

  const {
    isLoading: areXchangesAsClientLoading,
    data: xchangesAsClientInfiniteData,
  } = useXchanges(
    {
      client: clientId,
      page_size: 1000,
    },
    {
      enabled: !!clientId && fetchClientXchanges,
    }
  );
  const xchangesAsClient = useMemo(
    () =>
      xchangesAsClientInfiniteData
        ? xchangesAsClientInfiniteData.pages.flatMap((p) => p.results)
        : [],
    [xchangesAsClientInfiniteData]
  );

  const {
    isLoading: areXchangesAsTermXpertLoading,
    data: xchangesAsTermXpertInfiniteData,
  } = useXchanges(
    {
      xchange_terms__xpert_involved: xpertId,
    },
    {
      enabled: !!xpertId && fetchXpertXchanges,
    }
  );
  const xchangesAsTermXpert = useMemo(
    () =>
      xchangesAsTermXpertInfiniteData
        ? xchangesAsTermXpertInfiniteData.pages.flatMap((p) => p.results)
        : [],
    [xchangesAsTermXpertInfiniteData]
  );

  const {
    isLoading: areXchangesAsProductXpertLoading,
    data: xchangesAsProductXpertInfiniteData,
  } = useXchanges(
    {
      xchange_products__terms__xpert_involved: xpertId,
    },
    {
      enabled: !!xpertId && fetchXpertXchanges,
    }
  );
  const xchangesAsProductXpert = useMemo(
    () =>
      xchangesAsProductXpertInfiniteData
        ? xchangesAsProductXpertInfiniteData.pages.flatMap((p) => p.results)
        : [],
    [xchangesAsProductXpertInfiniteData]
  );

  const [xchanges, setXchanges] = useState<IXchange[]>([]);
  useEffect(() => {
    setXchanges(
      [
        ...(fetchClientXchanges ? xchangesAsClient || [] : []),
        ...(fetchXpertXchanges ? xchangesAsTermXpert || [] : []),
        ...(fetchXpertXchanges ? xchangesAsProductXpert || [] : []),
      ].filter(
        (xchange, index, self) =>
          index === self.findIndex((t) => t.id === xchange.id)
      )
    );
  }, [
    xchangesAsClient,
    xchangesAsTermXpert,
    xchangesAsProductXpert,
    fetchClientXchanges,
    fetchXpertXchanges,
  ]);

  const isLoading = () => {
    return (
      (!!clientId && fetchClientXchanges && areXchangesAsClientLoading) ||
      (!!xpertId && fetchXpertXchanges && areXchangesAsTermXpertLoading) ||
      (!!xpertId && fetchXpertXchanges && areXchangesAsProductXpertLoading)
    );
  };

  return isLoading() ? (
    <div className="d-flex justify-content-center mb-4">
      <Spinner animation="border" variant="primary" />
    </div>
  ) : xchanges.length === 0 ? (
    <CardPlaceholder
      iconClassName={"fa-solid fa-rectangle-vertical-history"}
      title={t("companies.details.overview.xchanges.empty")}
      description={t("companies.details.overview.xchanges.empty_desc")}
    />
  ) : (
    <div className="ps-4 pe-4">
      <Table>
        <thead>
          <tr>
            <th>{t("companies.details.overview.xchanges.title")}</th>
            <th>{t("companies.details.overview.xchanges.type")}</th>
            <th>{t("companies.details.overview.xchanges.timeline")}</th>
            <th>{t("companies.details.overview.xchanges.status")}</th>
          </tr>
        </thead>
        <tbody>
          {xchanges?.map((xchange: IXchange) => (
            <XchangeTableRow
              key={xchange.id}
              companyClientId={clientId}
              companyXpertId={xpertId}
              xchange={xchange}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default XchangeTable;
