import { getModelHyperLink } from "@helpers/model-utils";
import { useAddPeopleToCompanyTeam } from "@hooks/company/useAddPeopleToCompanyTeam";
import { useCompanyUpdate } from "@hooks/company/useCompanyUpdate";
import { usePeople } from "@hooks/person/usePeople";
import { usePersonCreate } from "@hooks/person/usePersonCreation";
import { usePersonUpdate } from "@hooks/person/usePersonUpdate";
import ICompany from "@interfaces/ICompany";
import { PartySubjectType } from "@interfaces/IParty";
import IPerson from "@interfaces/IPerson";
import MemberModal, {
  MemberFormValues,
} from "@pages/Company/CompanyEditPage/MemberModal/MemberModal";

const CompanyMemberModal = ({
  company,
  person,
  show,
  onClose,
}: {
  company?: ICompany;
  person?: IPerson;
  show: boolean;
  onClose: () => void;
}) => {
  const { data: people } = usePeople(
    {
      contact_companies__not__in: company?.id!.toString(),
    },
    { enabled: show && !!company }
  );

  const updateCompanyMutation = useCompanyUpdate();
  const personCreateMutation = usePersonCreate();
  const personUpdateMutation = usePersonUpdate();
  const addPeopleToCompanyTeamMutation = useAddPeopleToCompanyTeam(company);

  const saveWithMember = (member: MemberFormValues) => {
    if (person) {
      personUpdateMutation.mutate(
        {
          id: person.id,
          context: `/${getModelHyperLink(PartySubjectType.Company)}/${
            company?.id
          }`,
          ...member,
        },
        {
          onSuccess(person) {
            if (
              (member.primary_contact &&
                person?.id !== company?.company_key_contact) ||
              (!member.primary_contact &&
                person?.id === company?.company_key_contact)
            ) {
              updateCompanyMutation.mutate(
                {
                  updatedCompany: {
                    id: company?.id,
                    company_key_contact: member.primary_contact
                      ? person.id!
                      : null,
                  },
                },
                {
                  onSuccess: onModalClose,
                }
              );
            } else {
              onModalClose();
            }
          },
        }
      );
    } else {
      personCreateMutation.mutate(
        {
          ...member,
        },
        {
          onSuccess: async (person) => {
            await updateCompanyMutation.mutate({
              updatedCompany: {
                id: company?.id,
                company_contacts: [
                  ...(company?.company_contacts?.map(
                    (contact) => contact.id!
                  ) ?? []),
                  person.id!,
                ],
                company_key_contact: member.primary_contact
                  ? person.id!
                  : company?.company_key_contact,
              },
            });

            await addPeopleToCompanyTeamMutation.mutate([person]);

            onModalClose();
          },
        }
      );
    }
  };

  const saveWithExistingPerson = async (
    newMemberPeople: number[],
    _: any,
    onSuccessCallBack?: Function
  ) => {
    const peopleToAdd = people!.filter((person) =>
      newMemberPeople.includes(person.id!)
    );

    await addPeopleToCompanyTeamMutation.mutate(peopleToAdd);
    if (onSuccessCallBack) {
      onSuccessCallBack();
    } else {
      onModalClose();
    }
  };

  const isSaving = () => {
    return (
      updateCompanyMutation.isLoading ||
      personCreateMutation.isLoading ||
      personUpdateMutation.isLoading
    );
  };

  const getSavingError = () => {
    return person
      ? updateCompanyMutation.error || personUpdateMutation.error
      : updateCompanyMutation.error || personCreateMutation.error;
  };

  const onModalClose = () => {
    updateCompanyMutation.reset();
    personCreateMutation.reset();
    personUpdateMutation.reset();
    onClose();
  };

  return (
    <MemberModal
      people={people}
      member={
        person
          ? {
              ...person,
              primary_contact: person?.id === company?.company_key_contact,
            }
          : undefined
      }
      show={show}
      showPrimaryContactToggle
      onClose={onModalClose}
      onSaveExisting={saveWithExistingPerson}
      onSaveNew={saveWithMember}
      isFinishLoading={isSaving()}
      axiosError={getSavingError()}
    />
  );
};

export default CompanyMemberModal;
