import "./CustomDateInput.scss";
import CustomDatePicker, {
  CustomDatePickerProps,
} from "@components/CustomDatePicker/CustomDatePicker";
import { createRef } from "react";

const CustomDateInput = (
  props: CustomDatePickerProps & {
    isInvalid?: boolean;
  }
) => {
  const customDateInput = createRef<HTMLDivElement>();

  return (
    <div
      ref={customDateInput}
      className={`Custom-Date-Input ${
        props.isInvalid ? "Custom-Date-Input--invalid" : ""
      }`}
      onClick={(event) => {
        if (
          !(event.target as Element).className.includes("react-datepicker__day")
        ) {
          const input = customDateInput.current?.querySelector(
            `input.Custom-DatePicker`
          ) as HTMLInputElement;
          if (input) {
            input.focus();
          }
        }
      }}
    >
      <CustomDatePicker
        {...props}
        popperModifiers={[
          {
            name: "offset",
            options: {
              offset: [-9, 0],
            },
          },
          {
            name: "flip",
            options: {
              fallbackPlacements: ["bottom"],
            },
          },
        ]}
      />
      <div className="Custom-Date-Input__Icon">
        <i className="fa-regular fa-calendar"></i>
      </div>
    </div>
  );
};

export default CustomDateInput;
