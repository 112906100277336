import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IPersonWrite } from "@interfaces/IPerson";
import PersonService from "@services/PersonService";

export const usePersonCreate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (person: IPersonWrite) => PersonService.createPerson(person),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["people"]);
      },
    }
  );
};
